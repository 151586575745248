import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  tap
} from 'rxjs/operators';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from 'src/@vex/animations/fade-in-right.animation';
import { TenantService } from 'src/app/core/services/tenant.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { AgentBlock } from 'src/app/shared/models/agent-block.model';

import { BehaviorSubject, of } from 'rxjs';
import { SearchService } from 'src/app/core/services/search.service';
import { OS } from 'src/app/shared/models/system.model';
import {
  RemoveLicenseComponent,
  RemoveLicenseParams
} from '../../machines/remove-license/remove-license.component';

export class AddBlockParams {
  agentBlock: AgentBlock;
  onSuccess: () => void;
  constructor(init?: Partial<AddBlockParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-add-block',
  templateUrl: './add-block.component.html',
  styleUrls: ['./add-block.component.scss'],
  animations: [fadeInRight400ms, fadeOutRight400ms]
})
export class AddBlockComponent implements OnInit {
  overlayResult = OverlayResult.Unset;
  form: UntypedFormGroup;
  spin = true;
  searchSpin = false;
  err: string;

  _searchTerm = new BehaviorSubject<KeyboardEvent>(null);
  searchTerm$ = this._searchTerm.asObservable().pipe(
    debounceTime(300),
    map(
      () => (!!this._searchInput ? this._searchInput.nativeElement.value : '') as string
    ),
    distinctUntilChanged()
  );

  searchResults$ = this.searchTerm$.pipe(
    tap({ next: () => (this.searchSpin = true) }),
    mergeMap((term) => {
      if (!term) return of([]);

      return this.searchSvc.getAgents(term).pipe(
        map((r) => r.items.sort((a, b) => a.id.localeCompare(b.id))),
        map((agents) => {
          const found = agents.findIndex((a) => a.id === term);
          if (found >= 0) {
            const moveMe = agents.splice(found, 1);
            agents = [moveMe[0], ...agents];
          }

          return agents.filter((a) => a.system.os === OS.WINDOWS);
        })
      );
    }),
    tap({ next: () => (this.searchSpin = false) })
  );

  _searchInput: ElementRef;
  @ViewChild('searchInput') set searchInput(searchInput: ElementRef) {
    this._searchInput = searchInput;
    if (this._searchInput) {
      this._searchInput.nativeElement.focus();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: RemoveLicenseParams,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RemoveLicenseComponent>,
    private searchSvc: SearchService,
    private tenantSvc: TenantService
  ) {
    const now = new Date();

    this.form = this.fb.group({
      agentID: '',
      machineID: '',
      ip: '',
      blockOp: ['0'],
      blockFor: [5],
      blockUntil: new Date(now.setDate(now.getDate() + 1))
    });

    this.form.controls.blockFor.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.updateBlockOp(0);
      }
    });

    this.form.controls.blockUntil.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.updateBlockOp(1);
      }
    });
  }

  ngOnInit(): void {
    this.spin = false;
  }

  addBlock(): void {
    this.spin = true;
    this.closeError();

    const agentID = this.form.controls.agentID.value;
    const machineID = this.form.controls.machineID.value;
    const ip = this.form.controls.ip.value;
    const blockFor =
      this.form.controls.blockOp.value === '0' ? this.form.controls.blockFor.value : 0;
    const blockUntil =
      this.form.controls.blockOp.value === '1'
        ? this.form.controls.blockUntil.value
        : new Date(0);
    const blockIndefinitely = this.form.controls.blockOp.value >= 2;

    if (!agentID && !machineID && !ip) {
      this.err = 'please provide either an agent ID, machine ID or IP';
      this.spin = false;
      return;
    }

    this.tenantSvc
      .addAgentBlock(agentID, machineID, ip, blockFor, blockUntil, blockIndefinitely)
      .pipe(finalize(() => (this.spin = false)))
      .subscribe({
        next: () => {
          this.overlayResult = OverlayResult.Success;
        },
        error: (err: HttpErrorResponse) => {
          this.err = parseError(err);
          this.overlayResult = OverlayResult.Error;
        }
      });
  }

  updateBlockOp(op: number): void {
    this.form.controls.blockOp.setValue(`${op}`);
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.();
      this.dialogRef.close();
    }
  }

  close(): void {
    this.params.onSuccess?.();
    this.dialogRef.close();
  }

  closeError(): void {
    this.err = '';
  }
}
