<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <div @fadeInUp [formGroup]="registrationForm" class="w-80 p-6 pt-0 flex flex-col gap-4" (keyup.enter)="registerTenant()">
      <div [class.fade-out]="registrationDispatched">
        <div *ngIf="!registrationDispatched" @scaleOut class="text-6xl text-primary-500 text-center p-4 m-0">
          <fa-icon [icon]="['fad', 'envelope-open-text']" size="2x" [fixedWidth]="true"> </fa-icon>
        </div>
      </div>

      <div *ngIf="registrationDispatched" @fadeInUp class="text-6xl text-green text-center p-4 m-0">
        <fa-icon [icon]="['fad', 'paper-plane']" size="2x" [fixedWidth]="true"> </fa-icon>
      </div>

      <ng-container *ngIf="!registrationDispatched">
        <div class="flex-auto flex flex-col">
          <mat-form-field class="flex-grow" appearance="fill">
            <mat-label>E-Mail</mat-label>
            <input matInput autocomplete="off" type="email" formControlName="email" required autofocus #email />
            <mat-error *ngIf="registrationForm.controls.email.errors?.required">We need an email address to register you </mat-error>
            <mat-error *ngIf="registrationForm.controls.email.errors?.pattern">We need a proper email address to register you </mat-error>
          </mat-form-field>
        </div>

        <div class="text-xs text-secondary text-center">
          By clicking "REGISTER", you agree to the <br />
          <a class="text-primary" target="_blank" href="https://www.liquidware.com/privacy">Terms and Privacy Policy</a>
        </div>

        <button [disabled]="registrationForm.invalid" (click)="registerTenant()" color="primary" mat-raised-button type="button">
          REGISTER
        </button>

        <table class="w-full">
          <tr>
            <td>
              <hr class="border-gray-light w-full" />
            </td>
          </tr>
        </table>
      </ng-container>

      <ng-container *ngIf="registrationDispatched">
        <div @fadeInUp class="p-6 flex flex-col gap-4">
          <h4 class="text-secondary text-center">CONGRATULATIONS!</h4>
          <h5 class="text-secondary text-center p-0">{{ registrationDispatched }}</h5>
        </div>
      </ng-container>

      <button (click)="goToSignin()" color="primary" mat-raised-button type="button">SIGN IN</button>
    </div>
  </div>
</mon-spinner>
