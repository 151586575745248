<div *ngIf="title" class="hotkeys-help-header">
  <span class="hotkeys-help-header-title">{{ title }}</span>
  <button class="text-secondary -mt-2 -mr-2" (click)="handleDismiss()" mat-icon-button type="button">
    <fa-icon [icon]="['fad', 'times']" size="lg" class="leading-none"></fa-icon>
  </button>
</div>

<table class="hotkeys-table-help" cellspacing="0" width="100%" *ngFor="let hotkeyGroup of hotkeys">
  <thead *ngIf="hotkeyGroup.group">
    <tr>
      <th class="hotkeys-table-help-group" colspan="2">{{ hotkeyGroup.group }}</th>
    </tr>
  </thead>
  <tbody>
    <tr class="hotkeys-table-help-shortcut" *ngFor="let hotkey of hotkeyGroup.hotkeys">
      <td class="hotkeys-table-help-shortcut-description" width="70%">{{ hotkey.description }}</td>
      <td class="hotkeys-table-help-shortcut-keys" width="30%">
        <!-- <kbd [innerHTML]="hotkey.keys | hotkeysShortcut"></kbd> -->
      </td>
    </tr>
  </tbody>
</table>
