import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject, switchMap } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
  selector: 'mon-input-field',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  host: { class: 'someClass1' }
})
export class InputFieldComponent {
  @Input() field: string;
  @Input() label: string;
  @Input() placeholder: string;

  private _showCurrentValueInHint = false;
  @Input() get showCurrentValueInHint(): boolean {
    return this._showCurrentValueInHint;
  }
  set showCurrentValueInHint(v: boolean | '') {
    this._showCurrentValueInHint = v === '' || v;
  }

  private _readonly = false;
  @Input() get readonly(): boolean {
    return this._readonly;
  }
  set readonly(v: boolean | '') {
    this._readonly = v === '' || v;
  }

  private _clearOnSubmit = false;
  @Input() get clearOnSubmit(): boolean {
    return this._clearOnSubmit;
  }
  set clearOnSubmit(v: boolean | '') {
    this._clearOnSubmit = v === '' || v;
  }

  _lock = new BehaviorSubject<boolean>(undefined);
  lock$ = this._lock.asObservable();

  refresher = new BehaviorSubject<void>(undefined);
  fieldValue$ = this.refresher.asObservable().pipe(
    switchMap(() => this.tenantSvc.getSettings(this.field)),
    tap({
      next: () => this._lock.next(false)
    })
  );

  @ViewChild('fieldInput', { static: false }) fieldInput: ElementRef;

  constructor(private tenantSvc: TenantService) {}

  setSetting(value: unknown): void {
    this._lock.next(true);

    this.tenantSvc
      .setSetting(this.field, value)
      .pipe(
        tap({
          next: () => this.refresher.next()
        }),
        tap({
          next: () =>
            this.clearOnSubmit ? (this.fieldInput.nativeElement.value = '') : null
        }),
        finalize(() => this._lock.next(false))
      )
      .subscribe();
  }
}
