import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { fadeOutUp200ms } from 'src/app/shared/animations/fade-out-up.animation';
import { fadeOut200ms } from 'src/app/shared/animations/fade-out.animation';

export enum OverlayResult {
  Unset = 0,
  Success = 1,
  Error = 2
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  selector: 'mon-result-overlay',
  templateUrl: './result-overlay.component.html',
  styleUrls: ['./result-overlay.component.scss'],
  animations: [fadeOut200ms, fadeOutUp200ms, fadeInUp200ms]
})
export class ResultOverlayComponent {
  OverlayResult = OverlayResult;
  _open = new BehaviorSubject<boolean>(false);
  open$ = this._open.asObservable();

  _state = new BehaviorSubject<OverlayResult>(OverlayResult.Unset);
  state$ = this._state.asObservable();

  @Input()
  get state(): OverlayResult {
    return this._state.value;
  }
  set state(result: OverlayResult) {
    this._state.next(result);
  }

  _result = OverlayResult.Unset;
  @Input()
  get result(): OverlayResult {
    return this._result;
  }
  set result(result: OverlayResult) {
    this._result = result;
    if (this._result !== OverlayResult.Unset) {
      this._open.next(true);
      setTimeout(() => {
        this.close();
        this._result = OverlayResult.Unset;
      }, this.duration);
    }
  }

  @Input() duration = 500;
  @Input() opacity = 0.75;
  @Output() closed = new EventEmitter<OverlayResult>();

  constructor() {
    this.state$
      .pipe(
        untilDestroyed(this),
        tap((r) => {
          if (r !== OverlayResult.Unset) {
            this._open.next(true);
            setTimeout(() => {
              this.close();
              this.state = OverlayResult.Unset;
            }, this.duration);
          }
        })
      )
      .subscribe();
  }

  close() {
    this.closed.emit(this._state.value);
    this._open.next(false);
  }
}
