<ng-container *ngIf="settings$ | async as settings"></ng-container>
<ng-container *ngIf="!(dataSource.initialLoading$ | async)">
  <div @fadeInUp class="relative card overflow-x-auto overflow-y-hidden">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
      <h2 class="title my-0 pr-4 mr-4 border-r flex-none">
        <span>User Sessions</span>
      </h2>

      <span class="flex-1"></span>

      <div class="flex gap-2">
        <div class="hidden md:flex justify-start items-center content-center flex-auto bg-card rounded-md border px-2 relative">
          <fa-icon [icon]="['fad', 'search']" size="lg" [fixedWidth]="true"></fa-icon>
          <input
            #filterInput
            [formControl]="searchCtrl"
            class="pl-2 py-2 border-0 outline-none bg-transparent"
            placeholder="Search User Sessions..."
          />

          <button
            mat-icon-button
            [style.visibility]="filterInput.value ? 'visible' : 'hidden'"
            (click)="searchCtrl.setValue(''); filterInput.focus()"
            class="flex items-center justify-center w-6 h-6"
            matSuffix
          >
            <fa-icon [icon]="['fad', 'times']" [fixedWidth]="true"></fa-icon>
          </button>
        </div>

        <div class="bg-card rounded-md border px-2 relative hidden md:flex justify-start items-center content-center h-10 w-60">
          <!-- (valueChange)="userSelectionChange($event)"
        [value]="sessionFilter" -->
          <mat-select disableOptionCentering #columnFilter placeholder="Column" [value]="getFilterColumns()[0].property">
            <mat-option *ngFor="let c of getFilterColumns()" [value]="c.property">{{ c.label }}</mat-option>
            <!-- <mat-option *ngFor="let user of processUsers$ | async | keyvalue" [value]="user.key" [ngClass]="{ italic: !user.value }">{{
            user.value || 'no username'
          }}</mat-option> -->
          </mat-select>
        </div>

        <mon-display-config-button
          matTooltip="Filter Columns"
          *ngIf="displayConfig$ | async as config"
          persistAt="User"
          [config]="config"
          [canHide]="false"
          [icon]="['fad', 'columns']"
          class="ml-2 flex items-center justify-center flex-none"
        ></mon-display-config-button>
      </div>
    </div>

    <div *ngIf="dataSource.loading$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
      <div class="absolute w-full h-full bg-gray opacity-10"></div>
      <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
    </div>

    <table
      [dataSource]="dataSource"
      mat-table
      matSort
      [matSortActive]="this.sortField"
      [matSortDirection]="this.sortDirection"
      [@listStagger]="dataSource.filteredLength"
      class="w-full"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            (change)="masterToggle($event)"
            [checked]="selection.hasValue() && selection.selected.length === pageSize"
            [indeterminate]="selection.hasValue() && selection.selected.length !== pageSize"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox
            (change)="$event ? selection.toggle(row) : null"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td tipOnEllip (click)="setAgentTo(row)" *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <ng-container *ngIf="getValue(row, column.property) !== '<nil>'">
              {{ getValue(row, column.property) }}
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <!-- Date Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td tipOnEllip (click)="setAgentTo(row)" *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <ng-container [ngSwitch]="column.dateFormat">
              <ng-container *ngSwitchCase="'timeAgo'">
                {{ row[column.property] ? (row[column.property] | timeAgo) : '' }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ row[column.property] ? (row[column.property] | date: column.dateFormat || 'medium') : '' }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <!-- Button Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td (click)="setAgentTo(row)" *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <div class="flex float-right">
              <button
                (click)="column.buttonFn(row)"
                class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary"
                mat-icon-button
              >
                <fa-icon [icon]="['fad', column.buttonIconName]" size="lg" [matTooltip]="column.tooltip"> </fa-icon>
              </button>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
        <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: visibleColumns" @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer"></tr>
      </ng-container>
    </table>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [length]="(pageIndex + 1) * pageSize + (dataSource.filteredLength < pageSize ? 0 : 1)"
    >
    </mat-paginator>
  </div>
</ng-container>
