import { Updatable } from '../interfaces/updatable.interface';

export enum RemoteSessionType {
  MSRA = 'msra',
  QuickAssist = 'quickassist',
  Teleconference = 'teleconference'
}

export class RemoteSession implements Updatable<RemoteSession> {
  public id: string;
  public taskID: string;
  public agentID: string;
  public channelID: string;
  public type: string;
  public timestamp: Date;
  public tags: Map<string, string>;

  constructor(init?: Partial<RemoteSession>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);

    const tags = new Map<string, string>();
    if (this.tags) {
      Object.keys(this.tags).map((k) => {
        tags.set(k, this.tags[k]);
      });
      this.tags = tags;
    }
  }

  update(input: Partial<RemoteSession>): this {
    return Object.assign(this, input);
  }
}
