export class Contact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  sendBillingEmail: boolean;
  sendAccountEmail: boolean;

  get displayFullName(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  get displaySend(): string {
    if (this.sendBillingEmail && this.sendAccountEmail) {
      return 'Account and Billing';
    }

    return `${this.sendBillingEmail ? 'Billing' : ''}${
      this.sendAccountEmail ? 'Account' : ''
    }`;
  }

  constructor(init?: Partial<Contact>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }
}
