<ng-container *ngIf="latestResult$ | async"></ng-container>

<mon-spinner [spin]="spin" [overlay]="true">
  <div class="flex flex-col w-full h-full" content>
    <ng-container *ngIf="download || completionErr || (running$ | async); else noResults">
      <div class="flex flex-col w-full h-full">
        <div class="flex-1 pr-2">
          <div class="flex flex-col h-full w-full">
            <div *ngIf="running$ | async" class="flex-1 flex flex-col items-center justify-end relative h-full w-full">
              <ng-container *ngIf="channelListener$ | async"></ng-container>
              <div [@scaleInOut] class="absolute -top-7">
                <div [@fadeIn] class="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                  <div class="p-8 pt-16 z-10">
                    <fa-icon [icon]="['fas', 'wave-pulse']" size="lg" class="text-green animate-pulse"></fa-icon>
                  </div>
                </div>
                <div>
                  <apx-chart
                    [labels]="apexOptions.labels"
                    [tooltip]="apexOptions.tooltip"
                    [grid]="apexOptions.grid"
                    [colors]="apexOptions.colors"
                    [xaxis]="apexOptions.xaxis"
                    [yaxis]="apexOptions.yaxis"
                    [stroke]="apexOptions.stroke"
                    [chart]="apexOptions.chart"
                    [dataLabels]="apexOptions.dataLabels"
                    [plotOptions]="apexOptions.plotOptions"
                    [series]="apexOptions.series"
                  ></apx-chart>
                </div>
              </div>
            </div>

            <div class="flex items-center h-full relative transition-all duration-1000 ease-in-out">
              <div class="flex-1 flex flex-col pr-10">
                <div class="flex-1"></div>
                <div class="flex items-center justify-center">
                  <div class="flex items-center justify-center h-full">
                    <div class="font-bold uppercase" [style.color]="apexOptions.colors[0]">Download</div>
                  </div>
                  <div class="flex items-center justify-center h-full">
                    <div class="text-3xs text-secondary pl-2">Mbps</div>
                  </div>
                </div>
                <div class="flex items-start justify-center text-4xl px-2 relative w-full">
                  <span class="" [ngClass]="finalDownload ? 'animate-blur-in' : 'text-gray-400'">
                    {{ download ? (download / 8 | friendlyBits: 2 : false) : '--' }}
                  </span>
                </div>
              </div>

              <div class="flex-1 flex flex-col pl-10">
                <div class="flex-1"></div>
                <div class="flex items-center justify-center">
                  <div class="flex items-center justify-center h-full">
                    <div class="font-bold uppercase" [style.color]="apexOptions.colors[1]">Upload</div>
                  </div>
                  <div class="flex items-center justify-center h-full">
                    <div class="text-3xs text-secondary pl-2">Mbps</div>
                  </div>
                </div>
                <div class="flex items-start justify-center text-4xl px-2 relative w-full">
                  <span class="" [ngClass]="finalUpload ? 'animate-blur-in' : 'text-gray-400'">
                    {{ upload ? (upload / 8 | friendlyBits: 2 : false) : '--' }}
                  </span>
                </div>
              </div>
            </div>

            <div
              *ngIf="completionErr"
              class="flex py-2 items-center justify-center text-warn font-bold transition-all duration-1000 ease-in-out"
            >
              <div class="truncate">{{ completionErr }}</div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-2">
          <div class="flex flex-col">
            <div class="flex items-center">
              <div class="text-secondary" [style.font-variant]="'small-caps'">Ping</div>
              <div class="flex-1 text-xs text-right truncate">
                <span class="" [ngClass]="finalLatency ? 'animate-blur-in' : ''">{{
                  latency ? (latency | number: '1.0-0') + ' ms' : '-- '
                }}</span>
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-secondary" [style.font-variant]="'small-caps'">Provider</div>
              <div class="flex-1 whitespace-nowrap text-xs text-right truncate">
                <span class="" [ngClass]="finalUser ? 'animate-blur-in' : ''">
                  {{ isp ? isp : '--' }}
                </span>
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-secondary" [style.font-variant]="'small-caps'">Time</div>
              <div class="flex-1 whitespace-nowrap text-xs text-right truncate">
                <span class="" [ngClass]="finalUser ? 'animate-blur-in' : ''">{{ timestamp ? (timestamp | date: 'short') : '--' }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex items-center">
              <div class="text-secondary" [style.font-variant]="'small-caps'">Location</div>
              <div class="flex-1 whitespace-nowrap text-xs text-right truncate">
                <span class="" [ngClass]="finalServer ? 'animate-blur-in' : ''">{{ name ? name : '--' }}</span>
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-secondary" [style.font-variant]="'small-caps'">Country</div>
              <div class="flex-1 whitespace-nowrap text-xs text-right truncate">
                <span class="" [ngClass]="finalServer ? 'animate-blur-in' : ''">{{ country ? country : '--' }}</span>
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-secondary" [style.font-variant]="'small-caps'">Distance</div>
              <div class="flex-1 whitespace-nowrap text-xs text-right truncate">
                <!-- distance ? distance + ' ' + distanceIdentifier : '--' -->
                <span class="" [ngClass]="finalServer ? 'animate-blur-in' : ''">{{
                  distance ? (distance | distance: country.includes('United States')) : '--'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noResults>
      <div class="flex-1 flex justify-center p-12 w-full h-full">
        <div class="italic text-secondary">{{ spin ? '' : 'No results' }}</div>
      </div>
    </ng-template>
    <div class="flex-1"></div>
    <div class="flex w-full items-center justify-center mt-2">
      <button
        *ngLet="agent$ | async as agent"
        (click)="run(agent.id)"
        mat-raised-button
        class="px-8"
        color="primary"
        [disabled]="spin || (running$ | async)"
      >
        Speed Test
      </button>
    </div>
  </div>
</mon-spinner>
