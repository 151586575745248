import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SearchService } from 'src/app/core/services/search.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { AgentUserSession } from 'src/app/shared/models/session.model';

export class UserSessionsDataSource implements DataSource<AgentUserSession> {
  private _userSessions = new BehaviorSubject<AgentUserSession[]>(undefined);
  private _initialLoading = new BehaviorSubject<boolean>(true);
  public initialLoading$ = this._initialLoading.asObservable();
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public serverResultsLength: number;
  public filteredLength: number;
  public get data(): AgentUserSession[] {
    return this._userSessions.value?.slice() || [];
  }

  constructor(private searchSvc: SearchService, private tenantSvc: TenantService) {}

  loadUserSessions(
    filter: string,
    limit: number,
    page: number,
    orderBy: OrderBy[]
  ): void {
    this._loading.next(true);

    this.tenantSvc
      .getUserSessions(filter, limit, page, orderBy)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this._initialLoading.next(false);
          this._loading.next(false);
        })
      )
      .subscribe({
        next: (results: PagedResult<AgentUserSession>) => {
          this._userSessions.next(results.items || []);
          this.filteredLength = results.items.length;
        }
      });
  }

  connect(): Observable<AgentUserSession[]> {
    return this._userSessions.asObservable();
  }

  disconnect(): void {
    this._userSessions.complete();
    this._loading.complete();
  }
}
