import { Injectable } from '@angular/core';
import { ApiService, NoContent, Response } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Contact } from 'src/app/shared/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(
    firstName: string,
    lastName: string,
    email: string,
    sendBillingEmail: boolean = undefined,
    sendAccountEmail: boolean = undefined
  ): Observable<Response> {
    const url = `${this.apiUrl}/tenant/contact`;
    const params = {
      firstName,
      lastName,
      email
    };

    if (sendBillingEmail !== undefined) {
      params['sendBillingEmail'] = sendBillingEmail;
    }
    if (sendAccountEmail !== undefined) {
      params['sendAccountEmail'] = sendAccountEmail;
    }

    return this.http.post<Response>(url, params).pipe(
      catchError((err) => {
        this.handleError<Response>('ContactService.create');
        return throwError(() => err);
      })
    );
  }

  getAll(): Observable<Array<Contact>> {
    const url = `${this.apiUrl}/tenant/contact`;

    return this.http.get<Array<Contact>>(url).pipe(
      map((response) => (response ? response.map((c) => new Contact(c)) : undefined)),
      catchError((err) => {
        this.handleError<Array<Contact>>('ContactService.getAll');
        return throwError(() => err);
      })
    );
  }

  getByID(id: string): Observable<Contact> {
    const url = `${this.apiUrl}/tenant/contact/${id}`;

    return this.http.get<Contact>(url).pipe(
      map((response) => (response ? new Contact(response) : undefined)),
      catchError((err) => {
        this.handleError<Contact>('ContactService.getByID');
        return throwError(() => err);
      })
    );
  }

  update(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    sendBillingEmail: boolean = undefined,
    sendAccountEmail: boolean = undefined
  ): Observable<Response> {
    const url = `${this.apiUrl}/tenant/contact/${id}`;
    const params = {
      id,
      firstName,
      lastName,
      email
    };

    if (sendBillingEmail !== undefined) {
      params['sendBillingEmail'] = sendBillingEmail;
    }
    if (sendAccountEmail !== undefined) {
      params['sendAccountEmail'] = sendAccountEmail;
    }

    return this.http.patch<Response>(url, params).pipe(
      catchError((err) => {
        this.handleError<Response>('ContactService.update');
        return throwError(() => err);
      })
    );
  }

  delete(ids: string[]): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/contact?id=${ids.join('&id=')}`;

    return this.http.delete<NoContent>(url).pipe(
      catchError((err) => {
        this.handleError<NoContent>('ContactService.delete');
        return throwError(() => err);
      })
    );
  }
}
