import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.indexOf(ApiService.BASE_URL) >= 0 ||
      request.url.indexOf(ApiService.BASE_WS_URL) >= 0
    ) {
      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.Forbidden) {
            const perms: string[] = err.error?.required || [];
            if (perms.length > 0) {
              this.snackBar.open(
                `The following permissions are required: ${perms.join(', ')}`,
                'CLOSE',
                {
                  duration: 10000,
                  panelClass: ['error-snack'],
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                }
              );
            }
          }

          throw err;
        })
      );
    }

    return next.handle(request);
  }
}
