import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { CpuSummary } from 'src/app/shared/models/cpu-summary.model';
import { CPU } from 'src/app/shared/models/system.model';
import { Trigger } from 'src/app/shared/models/trigger.model';

import { LiveGraphComponent } from './widget/live-graph/live-graph.component';

const _cpuGraphProps = new BehaviorSubject<string[]>([]);
export const cpuGraphProps$ = _cpuGraphProps.asObservable();

export function updateCPU(
  cpuChart: LiveGraphComponent,
  _dialog: MatDialog,
  summary: CpuSummary,
  cpuGraphOps: GraphOptions,
  cpu: CPU,
  triggers: Array<Trigger> = []
): void {
  const value = [Math.round(summary.utilizationPercent)];

  cpuGraphOps.label = cpu.modelName;
  value.forEach((v, i) => {
    cpuGraphOps.initialSeries[0][i].data.splice(0, 1);
    cpuGraphOps.initialSeries[0][i].data.push(v);
  });

  const highlight = !!triggers.find(
    (t) =>
      !!t.conditions.find(
        (c) =>
          c.field === 'Summary.CPU.UtilizationPercent' &&
          c.isMet(summary.utilizationPercent)
      )
  );

  Object.assign(cpuGraphOps.focusProperties, [
    {
      label: 'Utilization',
      value: `${value}%`,
      highlight: highlight,
      graphColor: 'blue',
      graphLineStyle: 'solid'
    }
  ]);

  Object.assign(cpuGraphOps.properties, {
    Sockets: cpu.sockets,
    Cores: cpu.cores,
    Processors: cpu.logicalProcessors,
    'Base Speed': `${cpu.speed} MHz`
  });

  if (_cpuGraphProps.value.length < 1) {
    _cpuGraphProps.next(
      [...Object.keys(cpuGraphOps.properties || [])].concat(
        cpuGraphOps.focusProperties.map((p) => p.label)
      )
    );
  }

  cpuChart && cpuChart.appendData(value);
}
