import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { RoleService } from 'src/app/core/services/role.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { getEmailRegex } from 'src/app/core/utils/regex';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { Role } from 'src/app/shared/models/role.model';
import { User } from 'src/app/shared/models/user.model';

export class UserEditParams {
  userID: User['id'];
  userEmail: User['email'];
  onSuccess: (result: 'edit' | 'invite') => void;
  constructor(init?: Partial<UserEditParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms]
})
export class UserEditComponent implements OnInit {
  Array = Array;
  err: string;
  spin: boolean;
  overlayResult = OverlayResult.Unset;
  visible = true;
  selectable = true;
  removable = true;

  form = this.fb.group({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(getEmailRegex())
    ]),
    roles: new FormControl(new Map<string, unknown>()),
    keepOpen: new FormControl(false)
  });
  selectableCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  roles: Role[];
  rolesMap = new Map<string, Role>();
  allRoles$ = combineLatest([
    this.roleSvc.getAll().pipe(map((result) => result.items)),
    this.roleSvc.getAllSystem()
  ]).pipe(
    map(([custom, system]) => custom.concat(system)),
    tap((roles) => roles.forEach((role) => this.rolesMap.set(role.id, role))),
    tap(
      (roles) =>
        (this.roles = roles.sort((a, b) =>
          a.system ? (b.system ? 1 : -1) : -1 || a.name.localeCompare(b.name)
        ))
    )
  );
  filteredRoles$: Observable<Role[]>;

  user: User;

  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: UserEditParams,
    private dialogRef: MatDialogRef<UserEditComponent>,
    private fb: FormBuilder,
    private tenantSvc: TenantService,
    private roleSvc: RoleService
  ) {
    this.form.controls.email.valueChanges.subscribe({
      next: (v: string) => (this.user.email = v)
    });
    this.filteredRoles$ = this.selectableCtrl.valueChanges.pipe(
      startWith(''),
      map((role: string | null) => this._filter(role))
    );
  }

  ngOnInit(): void {
    if (this.params.userID) {
      this.spin = true;
      this.tenantSvc
        .getUserByID(this.params.userID)
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: (user) => {
            this.user = user;
            this.form.patchValue(this.user);
            this.selectableCtrl.setValue('');
            this.selectableCtrl.updateValueAndValidity();
          }
        });
      return;
    }

    this.user = new User({
      id: null,
      email: this.params.userEmail || '',
      roles: new Map<string, unknown>()
    });
    this.form.patchValue(this.user);
  }

  save(): void {
    this.spin = true;
    this.closeError();

    this.user.id
      ? this.tenantSvc
          .updateUser(this.user.id, Array.from(this.user.roles.keys()))
          .pipe(finalize(() => (this.spin = false)))
          .subscribe({
            next: () => {
              this.overlayResult = OverlayResult.Success;
            },
            error: (err: HttpErrorResponse) => {
              this.err = parseError(err);
              this.overlayResult = OverlayResult.Error;
            }
          })
      : this.tenantSvc
          .createInvite(this.user.email, Array.from(this.user.roles.keys()))
          .pipe(finalize(() => (this.spin = false)))
          .subscribe({
            next: () => {
              this.overlayResult = OverlayResult.Success;
            },
            error: (err: HttpErrorResponse) => {
              this.err = parseError(err);
              this.overlayResult = OverlayResult.Error;
            }
          });
  }

  delete(): void {
    this.spin = true;
    this.closeError();

    this.tenantSvc
      .deleteUser(this.user.id)
      .pipe(finalize(() => (this.spin = false)))
      .subscribe({
        next: () => {
          this.overlayResult = OverlayResult.Success;
        },
        error: (err: HttpErrorResponse) => {
          this.err = parseError(err);
          this.overlayResult = OverlayResult.Error;
        }
      });
  }

  addRole(event: MatChipInputEvent, allRoles: Role[]): void {
    const input = event.chipInput.inputElement;
    const value = event.value || '';

    if (allRoles.findIndex((r) => r.id === value) < 0) {
      return;
    }

    // Add our role
    if (value) {
      this._addRole(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.selectableCtrl.setValue('');
    this.selectableCtrl.updateValueAndValidity();
    setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  removeRole(role: string): void {
    this.user.roles.delete(role);

    this.selectableCtrl.setValue(null);
    this.selectableCtrl.updateValueAndValidity();
    // setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._addRole(event.option.value);
    this.roleInput.nativeElement.value = '';
    this.selectableCtrl.setValue('');
    this.selectableCtrl.updateValueAndValidity();
  }

  closeError(): void {
    this.err = '';
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.(this.user.id ? 'edit' : 'invite');
      if (this.form.controls.keepOpen.value) {
        this.emailInput.nativeElement.focus();
        // this.form.controls.email.reset();
        this.form.controls.email.setValue('');
        this.form.controls.email.setErrors(null);
        this.form.controls.email.markAsUntouched();
        this.form.controls.email.markAsPristine();
        // this.form.controls.email.updateValueAndValidity();
        return;
      }
      this.dialogRef.close();
    }
  }

  private _addRole(role: string): void {
    this.user.roles.set(role, {});
    setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  private _filter(value: string): Role[] {
    value = value || '';

    return this.roles
      .filter((role) => {
        return !this.user.roles.has(role.id);
      })
      .filter((role) => role.id.indexOf(value) === 0);
  }
}
