<div
  class="h-full"
  [class.drawer-collapsed]="(showDrawer && collapsed) || (isMobile$ | async)"
  [class.drawer-open]="collapsedOpen$ | async"
  [class.show-drawer]="showDrawer"
>
  <div class="bottom-drawer-content h-full">
    <div class="h-full">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>

  <ng-container *ngLet="isMobile$ | async as isMobile">
    <ng-container *ngIf="showDrawer">
      <div
        (mouseenter)="isMobile ? null : onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        data-intro-key="task-panel"
        class="bottom-drawer-drawer"
        (click)="onMouseEnter()"
      >
        <div class="bottom-drawer-drawer-inner">
          <vex-secondary-toolbar [header]="'Tasks'">
            <div class="w-full px-2 flex">
              <button
                mat-icon-button
                type="button"
                matTooltip="Go to Tasks"
                class="sm-icon-button hover:bg-gray-100 hover:bg-opacity-20 transition duration-200 ease-in-out"
                (click)="$event.stopPropagation(); gotoTasks()"
              >
                <fa-icon [icon]="['fad', 'external-link']" class="text-white text-xs"> </fa-icon>
              </button>
              <div class="flex items-center justify-end w-full text-right">
                <button
                  *ngIf="!isMobile"
                  (click)="toggleCollapse(); $event.stopPropagation()"
                  class="sm-icon-button hover:bg-gray-100 hover:bg-opacity-20 transition duration-200 ease-in-out"
                  mat-icon-button
                  type="button"
                >
                  <fa-icon [icon]="['fad', 'thumbtack']" [ngClass]="collapsed ? 'text-gray' : ''"> </fa-icon>
                </button>
              </div>
            </div>
          </vex-secondary-toolbar>
          <div class="bottom-drawer-drawer-wrapper">
            <div class="h-full overflow-auto">
              <ng-content select="[bottom-drawer]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
