import { QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { EthernetInterface } from 'src/app/shared/models/network-summary.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';

const _ethGraphProps = new BehaviorSubject<string[]>([]);
export const ethGraphProps$ = _ethGraphProps.asObservable();
const count = 20;

export function updateEthernet(
  ethernetCharts: QueryList<LiveGraphComponent>,
  ethernetGraphOps: GraphOptions[],
  ethernetAdapters: EthernetInterface[],
  triggers: Array<Trigger> = []
): void {
  ethernetAdapters = ethernetAdapters || [];
  const now = new Date();

  ethernetAdapters
    .filter((e) => e.interfaceState === 1) // only show connected devices
    .forEach((e) => {
      const value = [
        Math.round((e.sendSpeed * 8) / 1000),
        Math.round((e.receiveSpeed * 8) / 1000)
      ];
      let ops: GraphOptions = ethernetGraphOps.find((eg) => eg.id === e.interfaceGUID);

      if (!ops) {
        ops = {
          id: e.interfaceGUID,
          title: `Ethernet`,
          label: e.interfaceDescription,
          icon: 'ethernet',
          iconColor: 'orange',
          seriesColors: [['orange', 'orange']],
          seriesLineStyle: [['dashed', 'solid']],
          maxY: [undefined],
          initialSeries: [
            [
              {
                name: 'Send',
                data: new Array(count).fill(0)
              },
              {
                name: 'Receive',
                data: new Array(count).fill(0)
              }
            ]
          ],
          focusProperties: [],
          properties: {}
        };

        ethernetGraphOps.push(ops);
      } else {
        value.forEach((v, i) => {
          ops.initialSeries[0][i].data.splice(0, 1);
          ops.initialSeries[0][i].data.push(v);
        });
      }

      ops.timestamp = now;

      Object.assign(ops.focusProperties, [
        {
          label: 'Send',
          value: e.sendSpeedD,
          graphColor: 'orange',
          graphLineStyle: 'dashed'
        },
        {
          label: 'Receive',
          value: e.receiveSpeedD,
          graphColor: 'orange',
          graphLineStyle: 'solid'
        },
        {
          label: 'Gateway Latency',
          value: e.gatewayPing.enabled
            ? e.gatewayPing
              ? e.gatewayPing.pingD
              : ''
            : 'Disabled',
          highlight: !!triggers.find(
            (t) =>
              !!t.conditions.find(
                (c) =>
                  c.field === 'Summary.Network.EthernetAdapters.GatewayPing.AvgRtt' &&
                  c.isMet(e.gatewayPing.avgRtt)
              )
          )
        }
      ]);

      Object.assign(ops.properties, {
        IP: e.ipv4,
        'Gateway IP': e.gatewayIP
      });

      if (_ethGraphProps.value.length < 1) {
        _ethGraphProps.next(
          [...Object.keys(ops.properties || [])].concat(
            ops.focusProperties.map((p) => p.label)
          )
        );
      }

      ethernetCharts &&
        ethernetCharts
          .filter((ec) => ec.id === e.interfaceGUID)
          .forEach((ec) => {
            ec.appendData(value); // always send as Kpbs
          });
    });

  for (let i = ethernetGraphOps.length - 1; i >= 0; i--) {
    if (ethernetGraphOps[i].timestamp !== now) {
      ethernetGraphOps.splice(i, 1);
    }
  }
}
