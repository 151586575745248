<mon-spinner [spin]="spin" [overlay]="true">
  <div content #container>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex justify-start items-start content-center" mat-dialog-title>
      <div class="flex flex-col flex-1">
        <div class="headline m-0">Tenant Profile</div>
        <h6 *ngIf="view$ | async as view" class="subtitle text-gray m-0">{{ getViewDescription | callOnce: view }}</h6>
      </div>

      <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
        <fa-icon [icon]="['fad', 'times']" size="lg" class="leading-none"></fa-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <ng-container [ngSwitch]="view$ | async">
      <form *ngSwitchCase="TenantEditView.CompanyName" autocomplete="off" (ngSubmit)="saveTenant()" [formGroup]="companyNameForm">
        <mat-dialog-content class="flex flex-col min-h-1/2">
          <mat-form-field @fadeInRight>
            <mat-label>Company Name</mat-label>
            <input formControlName="companyName" matInput cdkFocusInitial autofocus [readonly]="spin" />
            <fa-icon [icon]="['fad', 'building']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-end">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-raised-button type="submit" [disabled]="spin || currentForm.invalid">SAVE CHANGES</button>
        </mat-dialog-actions>
      </form>

      <form *ngSwitchCase="TenantEditView.OwnerName" autocomplete="off" (ngSubmit)="saveTenant()" [formGroup]="ownerNameForm">
        <mat-dialog-content class="flex flex-col min-h-1/2">
          <div @fadeInRight class="flex-row flex justify-evenly">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input required formControlName="firstName" matInput cdkFocusInitial [readonly]="spin" />
              <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input required formControlName="lastName" matInput cdkFocusInitial [readonly]="spin" />
            </mat-form-field>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-end">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-raised-button type="submit" [disabled]="spin || currentForm.invalid">SAVE CHANGES</button>
        </mat-dialog-actions>
      </form>

      <form *ngSwitchCase="TenantEditView.Email" autocomplete="off" (ngSubmit)="saveTenant()" [formGroup]="ownerEmailForm">
        <mat-dialog-content class="flex flex-col min-h-1/2">
          <mat-form-field @fadeInRight class="flex-1">
            <mat-label>Email</mat-label>
            <input formControlName="ownerEmail" matInput [matAutocomplete]="email" cdkFocusInitial [readonly]="spin" />
            <mat-autocomplete autoActiveFirstOption #email="matAutocomplete">
              <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email">
                {{ user.email }}
              </mat-option>
            </mat-autocomplete>
            <fa-icon [icon]="['fad', 'envelope']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
            <mat-error *ngIf="ownerEmailForm.controls.ownerEmail.errors?.mustExist">We need a current user</mat-error>
            <mat-error *ngIf="ownerEmailForm.controls.ownerEmail.errors?.required">We need a proper email address</mat-error>
            <mat-error *ngIf="ownerEmailForm.controls.ownerEmail.errors?.pattern">We need a proper email address</mat-error>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-end">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-raised-button type="submit" [disabled]="spin || currentForm.invalid">SAVE CHANGES</button>
        </mat-dialog-actions>
      </form>

      <form *ngSwitchCase="TenantEditView.Phone" autocomplete="off" (ngSubmit)="saveTenant()" [formGroup]="phoneForm">
        <mat-dialog-content class="flex flex-col min-h-1/2">
          <mat-form-field @fadeInRight>
            <mat-label>Phone</mat-label>
            <input formControlName="phone" matInput cdkFocusInitial [readonly]="spin" />
            <fa-icon [icon]="['fad', 'phone']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-end">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-raised-button type="submit" [disabled]="spin || currentForm.invalid">SAVE CHANGES</button>
        </mat-dialog-actions>
      </form>

      <form *ngSwitchCase="TenantEditView.BillingAddress" autocomplete="off" (ngSubmit)="saveTenant()" [formGroup]="billingAddressForm">
        <ng-container *ngIf="initialLoad">
          <mat-dialog-content class="flex flex-col min-h-1/2">
            <div @fadeInRight class="flex-row flex justify-evenly">
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input required formControlName="firstName" matInput cdkFocusInitial [readonly]="spin" />
                <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input required formControlName="lastName" matInput cdkFocusInitial [readonly]="spin" />
              </mat-form-field>
            </div>
            <mat-form-field @fadeInRight>
              <mat-label>Company Name</mat-label>
              <input formControlName="company" matInput cdkFocusInitial autofocus [readonly]="spin" />
              <fa-icon [icon]="['fad', 'building']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
            </mat-form-field>
            <div @fadeInRight class="contents">
              <mon-address [formGroup]="billingAddressForm" [readonly]="spin" focusInitial></mon-address>
            </div>
          </mat-dialog-content>
          <mat-dialog-actions class="justify-end">
            <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
            <button color="primary" mat-raised-button type="submit" [disabled]="spin || billingAddressForm.invalid">SAVE CHANGES</button>
          </mat-dialog-actions>
        </ng-container>
      </form>
    </ng-container>
  </div>
</mon-spinner>
