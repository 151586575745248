import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ContextUserTenant } from 'src/app/shared/models/context-user-tenant.model';
import { ContextUser, ContextUserSettings } from 'src/app/shared/models/context-user.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Tenant } from 'src/app/shared/models/tenant.model';

import { ApiService, NoContent } from './api.service';
import { GravatarService } from './gravatar.service';

export interface Invite {
  id: string;
  email: string;
  tenantID: string;
  name: string;
  companyName: string;
  roles: { [key: string]: unknown };
  timestamp: Date;
  rejected: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  constructor(private http: HttpClient, private gravatarSvc: GravatarService) {
    super();
  }

  me(): Observable<ContextUser> {
    const url = `${this.apiUrl}/user/me`;
    const params = {};
    const start = performance.now();
    return this.http.get<ContextUser>(url, { params }).pipe(
      tap(() => {
        this.log(`fetched UserService.me response in ${performance.now() - start}ms`);
      }),
      map((response) => (response ? new ContextUser(response) : undefined)),
      mergeMap((user) => {
        if (user.hasPerms(['godmode'])) {
          user.imageLink = '/assets/img/iddqd.png';
          return of(user);
        }

        return this.gravatarSvc.getImage(user.email).pipe(
          map((imageLink) => {
            user.imageLink = imageLink;
            return user;
          })
        );
      }),
      catchError((err) => {
        this.handleError<ContextUser>('UserService.me');
        return throwError(() => err);
      })
    );
  }

  updateSettings(settings: ContextUserSettings): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me/tags/console-ui-settings`;
    const start = performance.now();
    return this.http.patch<NoContent>(url, settings).pipe(
      tap(() => {
        this.log(
          `fetched UserService.updateSettings response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError('UserService.updateSettings');
        return throwError(() => err);
      })
    );
  }

  tenants(): Observable<Array<ContextUserTenant>> {
    const url = `${this.apiUrl}/user/me/tenant`;
    const params = {};
    const start = performance.now();
    return this.http.get<Array<ContextUserTenant>>(url, { params }).pipe(
      tap(() => {
        this.log(
          `fetched UserService.tenants response in ${performance.now() - start}ms`
        );
      }),
      map((response) =>
        response ? response.map((t) => new ContextUserTenant(t)) : undefined
      ),
      catchError((err) => {
        this.handleError<Array<ContextUserTenant>>('UserService.tenants');
        return throwError(() => err);
      })
    );
  }

  createTenant(name: string): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me/tenant/create`;
    const params = {
      name
    };
    return this.http.post<Tenant>(url, params).pipe(
      catchError((err) => {
        this.handleError('UserService.createTenant');
        return throwError(() => err);
      })
    );
  }

  removeTenant(tenantID: string): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me/tenant/remove/${tenantID}`;
    return this.http.delete<NoContent>(url).pipe(
      catchError((err) => {
        this.handleError<NoContent>('UserService.removeTenant');
        return throwError(() => err);
      })
    );
  }

  leaveTenant(tenantID: string): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me/tenant/leave/${tenantID}`;
    return this.http.delete<NoContent>(url).pipe(
      catchError((err) => {
        this.handleError<NoContent>('UserService.leaveTenant');
        return throwError(() => err);
      })
    );
  }

  getInvites(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<Invite>> {
    const url = `${this.apiUrl}/user/me/invite`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    return this.http.get<Array<Invite>>(url, { observe: 'response', params }).pipe(
      map((response) =>
        response
          ? new PagedResult<Invite>(null, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Invite>>('UserService.getInvites');
        return throwError(() => err);
      })
    );
  }

  joinTenant(tenantID: string): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me/tenant/join/${tenantID}`;
    return this.http.post<NoContent>(url, {}).pipe(
      catchError((err) => {
        this.handleError<NoContent>('UserService.rejectInvite');
        return throwError(() => err);
      })
    );
  }

  rejectTenant(tenantID: string): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me/tenant/reject/${tenantID}`;
    return this.http.delete<NoContent>(url).pipe(
      catchError((err) => {
        this.handleError<NoContent>('UserService.rejectInvite');
        return throwError(() => err);
      })
    );
  }

  deleteAccount(): Observable<NoContent> {
    const url = `${this.apiUrl}/user/me`;
    return this.http.delete<NoContent>(url).pipe(
      catchError((err) => {
        this.handleError<NoContent>('UserService.deleteAccount');
        return throwError(() => err);
      })
    );
  }
}
