<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>
    <form autocomplete="off" (ngSubmit)="edit ? confirm(save.bind(this)) : save()" [formGroup]="form">
      <div class="flex justify-start items-center content-center" mat-dialog-title>
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">{{ form.get('name').value || 'New Role' }}</h2>

        <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border"></mat-divider>

      <mat-dialog-content class="flex flex-col">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input formControlName="id" matInput [readonly]="edit" [autoFocus]="!edit" />
          <fa-icon [icon]="['fad', 'fingerprint']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
          <mat-hint *ngIf="!edit">Only lowercase alphanumeric, dashs and underscores allowed</mat-hint>
          <mat-error *ngIf="form.controls.id.errors?.required">ID can not be empty </mat-error>
          <mat-error *ngIf="form.controls.id.errors?.maxlength">ID can not be more than 50 characters </mat-error>
          <mat-error *ngIf="form.controls.id.errors?.pattern">ID must only contain lowercase alphanumeric, -, _ values</mat-error>
          <mat-error *ngIf="form.controls.id.errors?.pattern">ID must not repeat -, _ or end with these</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput [autoFocus]="edit" />
          <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
          <mat-error *ngIf="form.controls.name.errors?.required">Name can not be empty </mat-error>
          <mat-error *ngIf="form.controls.name.errors?.maxlength">Name can not be more than 50 characters </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Description</mat-label>
          <input formControlName="description" matInput />
          <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
        </mat-form-field>

        <mat-form-field *ngIf="allPermissions$ | async as allPermissions">
          <mat-label>Permissions</mat-label>
          <mat-chip-grid #chipList aria-label="Permission selection">
            <mat-chip-row *ngFor="let perm of role.permissions.sort()" removable (removed)="removePermission(perm)">
              <div class="truncate">{{ perm }}</div>
              <fa-icon matChipRemove [icon]="['fad', 'times-circle']" size="lg" [fixedWidth]> </fa-icon>
            </mat-chip-row>
            <input
              placeholder="assign permissions..."
              #permissionInput
              [formControl]="selectableCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addPermission($event, allPermissions)"
            />
          </mat-chip-grid>
          <mat-autocomplete #auto (optionSelected)="selected($event)" [autoActiveFirstOption]="true" matAutocompleteTrigger>
            <mat-option *ngFor="let perm of filteredPermissions$ | async" [value]="perm" [disabled]="spin">
              <div class="text-xs md:text-base">{{ perm }}</div>
            </mat-option>
          </mat-autocomplete>
          <fa-icon [icon]="['fad', 'check-double']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
        </mat-form-field>

        <div @fadeInRight *ngIf="err" class="card text-white text-opacity-80 bg-red flex justify-between items-center mt-4">
          <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
          <pre class="p-2">
            <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="closeError()"></fa-icon>
          </pre>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="justify-between">
        <div *ngIf="edit" class="flex items-center">
          <button (click)="confirm(delete.bind(this))" color="warn" mat-button type="button" [disabled]="spin">DELETE</button>
        </div>
        <div></div>
        <div class="justify-end">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-button type="submit" [disabled]="spin || form.invalid">
            {{ edit ? 'SAVE CHANGES' : 'CREATE' }}
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
