<ng-container *ngIf="options$ | async as ops">
  <div
    *ngIf="show$ | async"
    (keyup.escape)="ops.loading ? {} : slowClose()"
    tabindex="0"
    class="flex items-center justify-center z-50 absolute top-0 bottom-0 left-0 right-0"
  >
    <div (click)="ops.loading ? {} : slowClose()" class="loader-background"></div>
    <div *ngIf="showCard" @fadeInUp @fadeOutDown (@fadeOutDown.done)="close()" class="loader-wrapper card flex flex-col">
      <div class="loader-message-wrapper z-10">
        <h1 @fadeInUp>{{ ops.header }}</h1>
      </div>
      <ng-template [ngIf]="ops.loading" [ngIfElse]="closeOptions">
        <div class="flex-1 splash-screen">
          <div class="wrapper">
            <div class="ball-scale-multiple">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #closeOptions>
        <div class="flex-1 flex items-center justify-center loader-result px-12 text-secondary">
          <ng-container #dynamic></ng-container>
          <div *ngIf="!ops.component" [innerHTML]="ops.content | sanitizeHtml"></div>
        </div>
        <ng-container *ngIf="!ops.closeButtonHidden">
          <div class="flex flex-col justify-end pb-4">
            <div class="flex items-center justify-center">
              <button #closeButton mat-button (click)="slowClose()" (keyup.escape)="slowClose()" tabindex="1" cdkFocusInitial>
                {{ ops.closeButtonText }}
              </button>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-container>
