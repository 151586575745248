import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ApexAxisChartSeries, ApexOptions, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { BehaviorSubject } from 'rxjs';
import { delay, filter, take, tap } from 'rxjs/operators';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { deepCopy } from 'src/app/utils/clone';
import { tailwindCssColorToRGB } from 'src/app/utils/colors';

@Component({
  selector: 'mon-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnDestroy, AfterViewInit {
  private _charts = new BehaviorSubject<QueryList<ChartComponent>>(undefined);
  charts$ = this._charts.asObservable();

  objectKeys = Object.keys;

  _apexOptions = new BehaviorSubject<ApexOptions>(undefined);
  apexOptions$ = this._apexOptions.asObservable().pipe(delay(250));

  series: ApexAxisChartSeries = [];

  _options: GraphOptions;
  get options(): GraphOptions {
    return this._options;
  }
  @Input() set options(options: GraphOptions) {
    this._options = options;
  }

  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;

  _checkIfRendered: NodeJS.Timeout;
  _chartObjects: QueryList<ChartComponent>;
  get chartObj(): QueryList<ChartComponent> {
    return this._chartObjects;
  }
  @ViewChildren(ChartComponent) set chartObj(value: QueryList<ChartComponent>) {
    this._chartObjects = value;

    this._chartObjects.forEach((_chartObject, index) => {
      if (_chartObject && !_chartObject.colors) {
        (_chartObject.yaxis as ApexYAxis).max = this.options.maxY[index];

        const colors = [];
        const widths = [];
        const dashes = [];
        if ((this.options.seriesColors || []).length > 0) {
          const seriesColors = this.options.seriesColors[index];
          for (let i = 0; i < seriesColors.length; i++) {
            colors.push(tailwindCssColorToRGB(seriesColors[i]));
            widths.push(2.5);
            dashes.push(
              !!this.options.seriesLineStyle &&
                this.options.seriesLineStyle[index][i] === 'dashed'
                ? 5
                : 0
            );
          }
        }

        _chartObject.colors = colors;
        _chartObject.stroke = {
          curve: 'smooth',
          width: widths,
          dashArray: dashes
        };
      }
    });

    // This is an ugly hackish way to address ng-apexchart not letting us know that charts have been rendered and ready to accept data
    this._checkIfRendered = setInterval(() => {
      const ready =
        this._chartObjects.filter((c) => {
          try {
            c.clearAnnotations();
            return false;
          } catch (exception) {
            return true; // we are not ready because chartObj hasn't been created.
          }
        }).length === 0; // all charts have been rendered by ApexCharts

      if (ready) {
        clearInterval(this._checkIfRendered);
        this._chartObjects.forEach((chart, index) => {
          this.init(chart, index);
        });
        this._charts.next(this._chartObjects);
      }
    }, 250);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.charts$
      .pipe(
        filter((charts) => !!charts),
        take(1),
        tap((charts) =>
          charts.forEach((chart) => {
            chart.updateOptions({
              chart: {
                height: 0
              }
            });
          })
        )
      )
      .subscribe();
    setTimeout(() => {
      const h = this.chartContainer.nativeElement.offsetHeight * 0.95;
      this.charts$
        .pipe(
          filter((charts) => !!charts),
          take(1),
          tap((charts) =>
            charts.forEach((chart) => {
              chart.updateOptions({
                chart: {
                  height: h
                }
              });
            })
          )
        )
        .subscribe();
    }, 250);
  }

  ngAfterViewInit(): void {
    const h = this.chartContainer.nativeElement.offsetHeight * 0.95;
    this._apexOptions.next({
      tooltip: {
        x: {
          show: false
        }
      },
      xaxis: {
        labels: {
          show: false
        },
        tooltip: {
          enabled: false
        },

        axisTicks: {
          show: true
        },
        floating: false
      },
      yaxis: {
        labels: {
          show: true
        },
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        min: 0
      },
      stroke: {
        curve: 'smooth',
        width: 5.0
      },
      labels: [],
      dataLabels: {
        enabled: false
      },
      chart: {
        height: h,
        zoom: {
          enabled: false
        },
        type: 'area',
        toolbar: {
          show: false
        },
        animations: {
          enabled: true,
          easing: 'linear',
          dynamicAnimation: {
            enabled: true,
            speed: 500
          }
        }
      },
      grid: {
        show: false
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this._checkIfRendered);
  }

  appendData(...newValues: Array<number[]>): void {
    this.charts$
      .pipe(
        filter((charts) => !!charts),
        take(1)
      )
      .subscribe({
        next: (charts) => {
          charts.forEach((chart, i) => {
            const data = [];
            const chartData = newValues[i];

            for (let i = 0; i < chartData.length; i++) {
              data.push({
                name: this.options.focusProperties
                  ? this.options.focusProperties[i].label
                  : '',
                data: [chartData[i]]
              });
            }

            chart.appendData(data);
          });
        }
      });
  }

  private init(chart: ChartComponent, index: number): void {
    const newSeries: ApexAxisChartSeries = [];
    const initialSeries = this.options.initialSeries[index] || [];

    if (initialSeries.length > 0) {
      initialSeries.forEach((s) => {
        newSeries.push(deepCopy(s)); // make a deep copy so we don't reference the original array
      });
    }

    this.series = newSeries;

    const largestRange = Math.max(...initialSeries.map((s) => s.data.length));

    chart.updateOptions({
      xaxis: {
        range: largestRange
      }
    });

    chart.appendData(this.series);
  }
}
