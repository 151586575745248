<ng-container *ngIf="tenantRegistered$ | async; else register">
  <div @fadeInUp class="flex flex-col px-6 pb-4">
    <div @scaleOut class="text-6xl text-green text-center p-4 m-0">
      <fa-icon [icon]="['fad', 'check-circle']" size="2x" [fixedWidth]="true"> </fa-icon>
    </div>
    <div class="p-6 flex flex-col gap-4">
      <h4 class="text-secondary text-center">CONGRATULATIONS!</h4>
      <h5 class="text-secondary text-center p-0">You are all set! Click below to sign in.</h5>
    </div>

    <button (click)="login()" color="primary" mat-raised-button type="button">SIGN IN</button>
  </div>
</ng-container>
<ng-template #register>
  <mon-spinner [spin]="spin" [overlay]="true">
    <div content>
      <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event, undefined)" [duration]="3000"></mon-result-overlay>
      <ng-container *ngIf="plans$ | async as plans">
        <ng-container *ngIf="selectedPlan$ | async as selectedPlan">
          <ng-container *ngIf="selectedFrequency$ | async as selectedFrequency">
            <div class="flex flex-col gap-6" #component>
              <mat-stepper orientation="vertical" linear (selectionChange)="onStep($event)" #matStepper>
                <ng-template matStepperIcon="edit">
                  <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
                </ng-template>

                <ng-template matStepperIcon="done">
                  <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
                </ng-template>

                <mat-step *ngIf="enablePasswordStep" [stepControl]="passwordForm" label="Password">
                  <form autocomplete="off" [formGroup]="passwordForm">
                    <mat-dialog-content class="flex flex-col">
                      <div class="flex flex-col items-center justify-center pb-4">
                        <h4 class="text-secondary text-center">Please Set Your Account Password</h4>
                        <ul class="fa-ul list-disc text-xs text-secondary">
                          <ng-container *ngIf="{ valid: password.value.length >= 8 } as v">
                            <li [ngClass]="v.valid ? 'text-green list-none' : 'text-secondary'">
                              <span *ngIf="v.valid" class="fa-li">
                                <fa-icon [icon]="['fad', 'check']"></fa-icon>
                              </span>
                              <span>at least 8 characters</span></li
                            >
                          </ng-container>

                          <ng-container *ngIf="{ valid: passwordForm.controls.password['reqs'].variety } as v">
                            <li [ngClass]="v.valid ? 'text-secondary' : 'text-green list-none'">
                              <span *ngIf="!v.valid" class="fa-li">
                                <fa-icon [icon]="['fad', 'check']"></fa-icon>
                              </span>
                              <span>at least 3 of the following</span>
                              <ul class="list-disc pl-4">
                                <ng-container *ngIf="{ valid: passwordForm.controls.password['reqs'].lowerCase } as v">
                                  <li [ngClass]="v.valid ? 'text-secondary' : 'text-green list-none'">
                                    <span *ngIf="!v.valid" class="fa-li pl-6">
                                      <fa-icon [icon]="['fad', 'check']"></fa-icon>
                                    </span>
                                    <span>lower case letters (a-z)</span></li
                                  >
                                </ng-container>
                                <ng-container *ngIf="{ valid: passwordForm.controls.password['reqs'].upperCase } as v">
                                  <li [ngClass]="v.valid ? 'text-secondary' : 'text-green list-none'">
                                    <span *ngIf="!v.valid" class="fa-li pl-6">
                                      <fa-icon [icon]="['fad', 'check']"></fa-icon>
                                    </span>
                                    <span>upper case letters (A-Z)</span></li
                                  >
                                </ng-container>
                                <ng-container *ngIf="{ valid: passwordForm.controls.password['reqs'].digits } as v">
                                  <li [ngClass]="v.valid ? 'text-secondary' : 'text-green list-none'">
                                    <span *ngIf="!v.valid" class="fa-li pl-6">
                                      <fa-icon [icon]="['fad', 'check']"></fa-icon>
                                    </span>
                                    <span>numbers (0-9)</span></li
                                  >
                                </ng-container>
                                <ng-container *ngIf="{ valid: passwordForm.controls.password['reqs'].specialChars } as v">
                                  <li [ngClass]="v.valid ? 'text-secondary' : 'text-green list-none'">
                                    <span *ngIf="!v.valid" class="fa-li pl-6">
                                      <fa-icon [icon]="['fad', 'check']"></fa-icon>
                                    </span>
                                    <span>special characters (e.g. !&#64;#$%^&*)</span></li
                                  >
                                </ng-container>
                              </ul>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                      <mat-form-field @fadeInRight class="w-full">
                        <mat-label>Password</mat-label>
                        <input
                          autofocus
                          autocomplete="off"
                          type="password"
                          formControlName="password"
                          placeholder="New password"
                          required
                          matInput
                          #password
                        />
                        <mat-error *ngIf="passwordForm.controls.password.errors?.required">Password required</mat-error>
                        <mat-error *ngIf="passwordForm.controls.password.errors?.minlength"
                          >Password must be at least 8 characters</mat-error
                        >
                        <mat-error *ngIf="passwordForm.controls.password.errors?.variety">Password must meet requirements</mat-error>
                      </mat-form-field>
                      <mat-form-field @fadeInRight class="w-full">
                        <mat-label>Confirm Password</mat-label>
                        <input
                          autocomplete="off"
                          type="password"
                          formControlName="passwordConfirm"
                          placeholder="Confirm password"
                          [errorStateMatcher]="matcher"
                          required
                          matInput
                        />
                        <mat-error *ngIf="passwordForm.controls.passwordConfirm.errors?.required">Password required</mat-error>
                        <mat-error *ngIf="passwordForm.errors?.mustMatch">Passwords must match</mat-error>
                      </mat-form-field>
                    </mat-dialog-content>

                    <mat-dialog-actions>
                      <div class="w-full flex justify-end gap-2">
                        <button
                          mat-button
                          color="primary"
                          mat-raised-button
                          [disabled]="
                            passwordForm.invalid || passwordForm.controls.password.value !== passwordForm.controls.passwordConfirm.value
                          "
                          matStepperNext
                        >
                          Next
                        </button>
                      </div>
                    </mat-dialog-actions>
                  </form>
                </mat-step>

                <mat-step *ngIf="enableTenantStep" [stepControl]="tenantForm" label="Tenant">
                  <form autocomplete="off" [formGroup]="tenantForm">
                    <mat-dialog-content class="flex flex-col">
                      <div class="flex flex-col items-center justify-center pb-4">
                        <h4 class="text-secondary text-center">Please Set Your Tenant Name</h4>
                        <ul class="list-disc text-xs text-secondary">
                          <li>only lowercase a-z, 0-9 and hypens</li>
                          <li>cannot end or begin with a hypen</li>
                          <li>cannot have consecutive hypens</li>
                        </ul>
                      </div>
                      <mat-form-field @fadeInRight class="w-full">
                        <mat-label>Tenant Name</mat-label>
                        <input
                          (keyup.enter)="tenantNameValidated && tenantForm.valid ? matStepper.next() : null"
                          [readonly]="tenantNameValidating"
                          autocomplete="off"
                          type="text"
                          formControlName="tenantName"
                          placeholder="wayne-enterprises"
                          autofocus
                          required
                          matInput
                          #tenantName
                        />
                        <mat-error *ngIf="tenantForm.controls.tenantName.errors?.required">Name required</mat-error>
                        <mat-error *ngIf="tenantForm.controls.tenantName.errors?.minlength">Name must be at least 3 characters</mat-error>
                        <mat-error *ngIf="tenantForm.controls.tenantName.errors?.maxlength"
                          >Name must be less than or equal to 60 characters</mat-error
                        >
                        <mat-error *ngIf="tenantForm.controls.tenantName.errors?.exp1"
                          >Name can only contain lowercase a-z, 0-9, and hypens</mat-error
                        >
                        <mat-error *ngIf="tenantForm.controls.tenantName.errors?.exp2">Name cannot end or begin with a hypen</mat-error>
                        <mat-error *ngIf="tenantForm.controls.tenantName.errors?.exp3">Name cannot have consecutive hypens</mat-error>
                      </mat-form-field>

                      <div class="flex flex-col items-center justify-center my-8 h-8" [style.font-variant]="'small-caps'">
                        <div *ngIf="tenantNameValidationErr; else validating" class="text-red">❌ {{ tenantNameValidationErr }}</div>
                        <ng-template #validating>
                          <div *ngIf="tenantNameValidating">validating name...</div>
                          <div *ngIf="tenantNameValidated && tenantForm.valid" class="text-green">✔️ tenant name available</div>
                        </ng-template>
                      </div>
                    </mat-dialog-content>

                    <mat-dialog-actions>
                      <div class="w-full flex justify-end gap-2">
                        <button mat-button color="primary" matStepperPrevious>Back</button>
                        <button
                          mat-button
                          color="accent"
                          [disabled]="tenantForm.invalid || tenantNameValidating || tenantNameValidated"
                          (click)="validateTenantName()"
                          >Check Availability</button
                        >
                        <button
                          mat-button
                          color="primary"
                          mat-raised-button
                          [disabled]="tenantForm.invalid || !tenantNameValidated"
                          matStepperNext
                          >Next</button
                        >
                      </div>
                    </mat-dialog-actions>
                  </form>
                </mat-step>

                <mat-step *ngIf="enableDetailStep" [stepControl]="detailForm" label="Details">
                  <form autocomplete="off" [formGroup]="detailForm">
                    <mat-dialog-content class="flex flex-col">
                      <h4 class="pb-4 text-secondary text-center">Please Provide Details About You</h4>
                      <div class="sm:flex sm:justify-evenly sm:gap-2">
                        <mat-form-field class="w-full">
                          <mat-label>First Name</mat-label>
                          <input formControlName="firstName" matInput #firstName />
                          <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                        </mat-form-field>
                        <mat-form-field class="w-full">
                          <mat-label>Last Name</mat-label>
                          <input formControlName="lastName" matInput />
                        </mat-form-field>
                      </div>
                      <mat-form-field>
                        <mat-label>Company Name</mat-label>
                        <input formControlName="companyName" matInput autofocus />
                        <fa-icon [icon]="['fad', 'building']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>Company Size</mat-label>
                        <mat-select formControlName="companySize">
                          <mat-option *ngFor="let t of companySizeType | enumToArray" [value]="companySizeType[t]">{{
                            companySizeType[t]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mon-address [formGroup]="detailForm" type="register" hideStreetAddress></mon-address>
                    </mat-dialog-content>

                    <mat-dialog-actions>
                      <div class="w-full flex justify-end gap-2">
                        <button mat-button color="primary" matStepperPrevious>Back</button>
                        <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="detailForm.invalid"> Next </button>
                      </div>
                    </mat-dialog-actions>
                  </form>
                </mat-step>

                <mat-step *ngIf="enableCouponStep" [stepControl]="couponForm" label="Coupon">
                  <form autocomplete="off" [formGroup]="couponForm">
                    <mat-dialog-content class="flex flex-col">
                      <h4 class="pb-4 text-secondary text-center">Do you have a coupon code you want to apply?</h4>
                      <div class="flex items-center justify-center gap-4">
                        <mat-form-field @fadeInRight class="w-full" appearance="outline" class="w-96">
                          <mat-label>Coupon Code</mat-label>
                          <input matInput autofocus autocomplete="off" type="text" formControlName="coupon" class="uppercase" #coupon />
                          <mat-error *ngIf="couponForm.controls.coupon.errors?.invalid">{{ couponValidationErr }}</mat-error>
                          <button matSuffix mat-icon-button aria-label="Clear" (click)="validateCoupon()" matTooltip="Apply">
                            <mat-icon class="text-secondary">send</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div class="flex flex-col items-center justify-center mb-8" [style.font-variant]="'small-caps'">
                        <div *ngIf="couponValidating">validating coupon...</div>
                        <div *ngIf="selectedCoupon$ | async as coupon" class="flex flex-col items-center justify-center gap-2">
                          <div class="h-16">
                            <div
                              @fadeInRight
                              *ngIf="animateCoupon$ | async"
                              class="card bg-green-light border border-green-500 p-2 flex items-center justify-between gap-4 h-14"
                            >
                              <div class="flex flex-col items-center justify-center">
                                <div class="px-4">{{ coupon.name }}</div>
                                <div class="text-3xs text-secondary">{{ coupon.id }}</div>
                              </div>
                              <div
                                class="flex items-center justify-center hover:cursor-pointer text-secondary hover:text-primary transition-colors"
                              >
                                <fa-icon
                                  class="text-2xs"
                                  [icon]="['fad', 'times']"
                                  [fixedWidth]="true"
                                  (click)="removeCoupon()"
                                  matTooltip="Remove"
                                ></fa-icon>
                              </div>
                            </div>
                          </div>
                          <div class="text-green">✔️ coupon code is valid</div>
                        </div>
                      </div>
                    </mat-dialog-content>

                    <mat-dialog-actions>
                      <div class="w-full flex justify-end gap-2">
                        <button mat-button color="primary" matStepperPrevious>Back</button>
                        <button mat-button color="primary" mat-raised-button matStepperNext>Next</button>
                      </div>
                    </mat-dialog-actions>
                  </form>
                </mat-step>

                <mat-step [stepControl]="planForm" label="Plan">
                  <form autocomplete="off" [formGroup]="planForm">
                    <mat-dialog-content class="flex flex-col">
                      <h4 class="pb-4 text-secondary text-center">Please Select A Subscription Plan</h4>
                      <div class="flex flex-wrap items-stretch justify-center gap-1 md:gap-8">
                        <ng-container *ngFor="let plan of plans">
                          <div
                            matRipple
                            class="flex flex-col gap-2 md:gap-4 items-center justify-start cursor-pointer rounded hover:bg-primary-light transition-all"
                            [ngClass]="selectedPlan.id === plan.id ? 'dark:bg-gray-700 bg-gray-100' : ''"
                            (click)="selectItem(plan); matStepper.next()"
                            #item
                          >
                            <div class="flex-1 flex flex-col md:gap-4 items-center justify-start text-sm md:text-base p-1 md:p-6 pb-0">
                              <ng-container *ngIf="plan.imageLink">
                                <fa-icon
                                  *ngIf="planImageIsFontAwesome(plan)"
                                  [icon]="['fad', planImageAsIconName(plan.imageLink)]"
                                  size="6x"
                                  class="text-gray"
                                ></fa-icon>
                              </ng-container>
                              <div class="w-32 text-center text-secondary uppercase font-bold">{{ plan.name }}</div>
                              <div class="flex flex-col">
                                <div *ngFor="let benefit of plan.benefits" class="w-32 truncate flex gap-2 md:gap-4">
                                  <fa-icon [icon]="['fad', 'check']" class="text-green"></fa-icon>
                                  <div class="truncate text-secondary text-xs" [innerHTML]="benefit"></div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="flex p-2 items-center justify-center w-full"
                              [ngClass]="selectedPlan.id === plan.id ? 'dark:bg-green-700 bg-green-300' : ''"
                            >
                              <div *ngIf="selectedPlan.id === plan.id; else notSelected" class="text-secondary font-bold">SELECTED</div>
                              <ng-template #notSelected>
                                <div class="text-secondary font-bold">&nbsp;</div>
                              </ng-template>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </mat-dialog-content>
                  </form>
                </mat-step>

                <mat-step *ngIf="!selectedPlan.disableFrequency" [stepControl]="frequencyForm" label="Frequency">
                  <form autocomplete="off" [formGroup]="frequencyForm">
                    <mat-dialog-content class="flex flex-col">
                      <div class="grid grid-cols-2 gap-1 md:gap-8">
                        <div
                          *ngFor="let frequency of selectedPlan.frequencies"
                          matRipple
                          class="flex flex-col gap-2 md:gap-4 items-center justify-start text-sm md:text-base cursor-pointer rounded hover:bg-primary-light transition-all"
                          [ngClass]="selectedFrequency.type === frequency.type ? 'dark:bg-gray-700 bg-gray-100' : ''"
                          (click)="selectFrequency(frequency); matStepper.next()"
                        >
                          <div class="flex-1 flex flex-col gap-2 md:gap-4 items-center justify-start p-1 md:p-6 pb-0">
                            <div class="text-secondary uppercase font-bold">{{ frequency.type }}ly</div>
                            <div>${{ frequency.price / 100 | number: '1.2' }} / machine / {{ frequency.type }}</div>
                            <div class="flex flex-col">
                              <div *ngFor="let benefit of frequency.benefits" class="w-32 truncate flex gap-4">
                                <fa-icon [icon]="['fad', 'check']" class="text-green"></fa-icon>
                                <div class="text-secondary text-xs" [innerHTML]="benefit"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="flex p-2 items-center justify-center w-full"
                            [ngClass]="selectedFrequency.type === frequency.type ? 'dark:bg-green-700 bg-green-300' : ''"
                          >
                            <div *ngIf="selectedFrequency.type === frequency.type; else notSelected" class="text-secondary font-bold"
                              >SELECTED</div
                            >
                            <ng-template #notSelected>
                              <div class="text-secondary font-bold">&nbsp;</div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </mat-dialog-content>
                  </form>
                </mat-step>

                <mat-step *ngIf="!selectedPlan.disableMachineCount && enableMachineCountStep" [stepControl]="machineForm" label="Machines">
                  <form autocomplete="off" [formGroup]="machineForm">
                    <mat-dialog-content class="flex flex-col h-40">
                      <div class="py-4">Machine count must be a multiple of {{ machineCountIncrement }}</div>
                      <mat-form-field class="" appearance="fill">
                        <mat-label>Machine Count</mat-label>
                        <input
                          formControlName="machineCount"
                          matInput
                          #machineCount
                          maxlength="5"
                          [step]="machineCountIncrement"
                          [min]="machineCountIncrement"
                          max="5000"
                          type="number"
                        />
                        <mat-error *ngIf="machineForm.controls.machineCount.errors?.min">Machine count must be at least 5</mat-error>
                        <mat-error *ngIf="machineForm.controls.machineCount.errors?.required">Machine count can not be empty</mat-error>
                        <mat-error *ngIf="machineForm.controls.machineCount.errors?.invalidDenominator"
                          >Must be a multiple of {{ machineCountIncrement }}</mat-error
                        >
                      </mat-form-field>
                      <ng-container *ngIf="selectedFrequency$ | async as selectedFrequency">
                        <div *ngIf="!machineForm.invalid" class="flex gap-2 items-center justify-center text-secondary">
                          <div class="">Estimated costs:</div>
                          <div>
                            ${{ (machineForm.controls.machineCount.value / 100) * selectedFrequency.price | number: '1.2' }} /
                            {{ selectedFrequency.type }}
                          </div>
                        </div>
                      </ng-container>
                    </mat-dialog-content>
                    <mat-dialog-actions>
                      <div class="w-full flex justify-end">
                        <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="machineForm.invalid">Next</button>
                      </div>
                    </mat-dialog-actions>
                  </form>
                </mat-step>

                <mat-step [stepControl]="summaryForm" label="Summary">
                  <form autocomplete="off" [formGroup]="summaryForm">
                    <mat-dialog-content class="flex flex-col">
                      <h4 class="text-center py-2 bold border-b-2 mb-4">Summary Of Plan</h4>
                      <div class="grid grid-cols-2 gap-2 pb-20">
                        <div class="text-secondary text-right">Plan</div>
                        <div>{{ selectedPlan.name }}</div>
                        <div class="text-secondary text-right">Frequency</div>
                        <div class="capitalize">{{ selectedFrequency.type }}ly</div>
                        <div class="text-secondary text-right">Machine Count</div>
                        <div>{{ machineForm.controls.machineCount.value }}</div>
                      </div>
                    </mat-dialog-content>
                    <mat-dialog-actions>
                      <div class="w-full flex justify-end gap-2">
                        <button mat-button color="primary" matStepperPrevious>Back</button>
                        <button
                          mat-button
                          color="primary"
                          mat-raised-button
                          (click)="
                            (!selectedPlan.disablePayment && enablePaymentStep) || (!selectedPlan.disableEstimate && enableEstimateStep)
                              ? matStepper.next()
                              : submit()
                          "
                        >
                          {{
                            (!selectedPlan.disablePayment && enablePaymentStep) || (!selectedPlan.disableEstimate && enableEstimateStep)
                              ? 'Next'
                              : 'Submit'
                          }}
                        </button>
                      </div>
                    </mat-dialog-actions>
                  </form>
                </mat-step>

                <mat-step *ngIf="!selectedPlan.disablePayment && enablePaymentStep" [stepControl]="paymentSourceForm" label="Payment">
                  <form autocomplete="off" [formGroup]="paymentSourceForm">
                    <div class="flex flex-col gap-4">
                      <mat-dialog-content class="flex flex-col">
                        <div class="flex items-center justify-center gap-1 md:gap-8">
                          <div
                            matRipple
                            class="flex flex-col gap-2 md:gap-4 items-center justify-center text-sm md:text-base cursor-pointer rounded hover:bg-primary-light transition-all m-4"
                            (click)="updatePaymentSource()"
                          >
                            <div class="flex-1 flex flex-col gap-2 md:gap-4 items-center justify-start p-1 md:p-6 pb-0">
                              <div class="flex flex-col">
                                <ng-container *ngIf="paymentSource$ | async as ps; else noPaymentSource">
                                  <mon-payment-source-view [payment-source]="ps"></mon-payment-source-view>
                                </ng-container>
                                <ng-template #noPaymentSource>
                                  <div class="italic">No Payment Info</div>
                                </ng-template>
                              </div>
                            </div>
                            <div class="flex p-2 bg-primary-light items-center justify-center w-full">
                              <div class="text-secondary font-bold">Update Payment Info</div>
                            </div>
                          </div>
                        </div>
                      </mat-dialog-content>
                      <mat-dialog-actions>
                        <div class="w-full flex justify-end gap-2">
                          <button mat-button color="primary" matStepperPrevious>Back</button>
                          <button
                            mat-button
                            color="primary"
                            mat-raised-button
                            [disabled]="planForm.invalid || frequencyForm.invalid || machineForm.invalid || paymentSourceForm.invalid"
                            matStepperNext
                          >
                            {{ selectedPlan.disableEstimate ? 'Submit' : 'Next' }}
                          </button>
                        </div>
                      </mat-dialog-actions>
                    </div>
                  </form>
                </mat-step>

                <mat-step *ngIf="!selectedPlan.disableEstimate && enableEstimateStep" [stepControl]="estimateForm" label="Estimate">
                  <ng-container *ngIf="loadingEstimate$ | async as loading">
                    <form autocomplete="off" [formGroup]="estimateForm">
                      <mat-dialog-content class="flex items-center justify-center min-h-[8rem]">
                        <div *ngIf="loading.value">
                          <mon-loader-ball></mon-loader-ball>
                        </div>
                        <div
                          *ngIf="estimate$ | async as estimate"
                          class="flex flex-col items-center justify-center w-full"
                          [hidden]="loading.value"
                        >
                          <mon-estimate [estimate]="estimate.immediateEstimate" header="Immediate Payment" class="w-full"></mon-estimate>
                          <mon-estimate [estimate]="estimate.nextEstimate" header="Next Billing Payment" class="w-full"></mon-estimate>
                          <div class="h-4"></div>
                        </div>
                        <ng-container *ngIf="estimateError$ | async as estimateError">
                          <div @fadeInRight class="card text-white text-opacity-80 bg-red flex justify-between items-center mt-2">
                            <pre class="p-4 whitespace-pre-wrap">{{ estimateError }}</pre>
                          </div>
                        </ng-container>
                      </mat-dialog-content>
                      <mat-dialog-actions>
                        <div class="w-full flex justify-end">
                          <button mat-button color="primary" matStepperPrevious>Back</button>
                          <button
                            mat-button
                            color="primary"
                            mat-raised-button
                            matStepperNext
                            [disabled]="loading.value || estimateForm.invalid"
                            (click)="submit()"
                            #estimateNextBtn
                          >
                            Submit
                          </button>
                        </div>
                      </mat-dialog-actions>
                    </form>
                  </ng-container>
                </mat-step>
              </mat-stepper>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </mon-spinner>
</ng-template>
