import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Style {
  light = 'vex-style-light',
  default = 'vex-style-default',
  dark = 'vex-style-dark'
}

@Injectable({
  providedIn: 'root'
})
export class StyleService {
  defaultStyle = Style.default;
  currentStyle: Style;

  private _styleSubject = new BehaviorSubject<Style>(this.defaultStyle);
  readonly style$ = this._styleSubject.asObservable();

  private _onStyleApplied = new BehaviorSubject(undefined);
  readonly onStyleApplied$ = this._onStyleApplied.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.style$.subscribe({ next: (style) => this._updateStyle(style) });
  }

  setStyle(style: Style): void {
    if (this.currentStyle === style) {
      return;
    }

    this._styleSubject.next(style);
  }

  private _updateStyle(style: Style) {
    let notifyOfApplied = false;

    if (this.currentStyle !== undefined) {
      notifyOfApplied = true;
    }

    this.currentStyle = style;

    const body = this.document.body;

    Object.values(Style)
      .filter((s) => s !== style)
      .forEach((value) => {
        if (body.classList.contains(value)) {
          body.classList.remove(value);
        }
      });

    body.classList.add(style);

    if (this.currentStyle === Style.dark) {
      body.classList.add('dark');
      body.classList.remove('light');
    } else {
      body.classList.add('light');
      body.classList.remove('dark');
    }

    if (notifyOfApplied) {
      this._onStyleApplied.next(undefined);
    }
  }
}
