import { formatAddress } from 'src/app/utils/address';
import { License } from './license.model';

export class Address {
  constructor(init?: Partial<Address>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }

  public firstName: string;
  public lastName: string;
  public company: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public locality: string;
  public region: string;
  public regionCode: string;
  public postcode: string;
  public country: string;
  public countryCode: string;
}

export class Tenant {
  public id: string;
  public name: string;
  public registeringIP: string;
  public domain: string;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public description: string;
  public ownerEmail: string;
  public license: License;
  public phone: string;
  public billingAddress: Address;
  public shippingAddress: Address;
  public state: string;
  public timestamp: Date;
  public created: Date;

  get fullnameD(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  get billingAddressD(): string {
    return formatAddress(
      this.billingAddress.firstName,
      this.billingAddress.lastName,
      this.billingAddress.company,
      [
        this.billingAddress.address1,
        this.billingAddress.address2,
        this.billingAddress.address3
      ],
      this.billingAddress.locality,
      this.billingAddress.region || this.billingAddress.regionCode,
      this.billingAddress.postcode,
      this.billingAddress.countryCode
    );
  }

  get createdD(): string {
    return this.created ? this.created.toLocaleDateString() : '';
  }

  constructor(init?: Partial<Tenant>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    if (init.billingAddress) {
      this.billingAddress = new Address(init.billingAddress);
    }

    if (init.shippingAddress) {
      this.shippingAddress = new Address(init.shippingAddress);
    }

    this.timestamp = init.timestamp ? new Date(init.timestamp) : undefined;
    this.created = init.created ? new Date(init.created) : undefined;
    this.license = init.license ? new License(init.license) : undefined;
  }
}
