import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PopoverModule } from '../popover/popover.module';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications.component';

@NgModule({
  declarations: [ToolbarNotificationsComponent, ToolbarNotificationsDropdownComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    PopoverModule,
    MatTabsModule,
    MatMenuModule,
    RouterModule,
    MatRippleModule,
    FontAwesomeModule
  ],
  exports: [ToolbarNotificationsComponent]
})
export class ToolbarNotificationsModule {}
