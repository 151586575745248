import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { ScriptService } from 'src/app/core/services/script.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';
import { CustomValidators } from 'src/app/utils/validators';

export class ScriptFeedbackParams {
  script: Script;
  onSuccess: () => void;
  constructor(init?: Partial<ScriptFeedbackParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-script-feedback',
  templateUrl: './script-feedback.component.html',
  styleUrls: ['./script-feedback.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms]
})
export class ScriptFeedbackComponent {
  err: string;
  spin: boolean;
  overlayResult = OverlayResult.Unset;

  form: UntypedFormGroup;

  script: Script;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: ScriptFeedbackParams,
    private dialogRef: MatDialogRef<ScriptFeedbackComponent>,
    private fb: UntypedFormBuilder,
    private scriptSvc: ScriptService
  ) {
    this.script = params.script;

    this.form = this.fb.group({
      feedback: [
        '',
        [CustomValidators.noWhitespace, Validators.required, Validators.maxLength(2000)]
      ]
    });
  }

  submit(): void {
    this.spin = true;
    this.closeError();

    this.scriptSvc
      .location(ScriptLocation.Public)
      .feedback(this.script.id, this.form.controls.feedback.value)
      .pipe(finalize(() => (this.spin = false)))
      .subscribe({
        next: () => {
          this.overlayResult = OverlayResult.Success;
        },
        error: (err: HttpErrorResponse) => {
          this.err = parseError(err);
          this.overlayResult = OverlayResult.Error;
        }
      });
  }

  closeError(): void {
    this.err = '';
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.();
      this.dialogRef.close();
    }
  }
}
