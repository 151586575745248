<div *ngIf="navMenu$ | async as navMenu" class="grid grid-flow-row grid-cols-12 gap-2 w-full h-full">
  <div class="col-span-3 card h-full perf-nav mb-2">
    <div [@listStagger]="navMenu.navItems.length" class="grid grid-flow-row grid-cols-1">
      <div
        @fadeInRight
        *ngFor="let item of navMenu.navItems"
        class="flex flex-col md:flex-row items-center justify-center p-4 m-1 rounded-md hover:bg-primary-light cursor-pointer"
        [ngClass]="navMenu.selectedNavItem === item ? 'bg-gray bg-opacity-20' : null"
        matRipple
        (click)="setActiveNavItem(item)"
      >
        <div class="shrink flex items-center justify-center pr-2 w-12" [ngClass]="'text-' + item.iconColor">
          <fa-icon [icon]="['fad', item.icon]" size="2x"></fa-icon>
        </div>
        <div class="flex-1 flex flex-col truncate">
          <div>{{ item.title }}</div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="selectedOption$ | async as selectedOption">
    <ng-container *ngIf="!(loadingGraph$ | async)" [ngSwitch]="selectedOption.type">
      <ng-container *ngSwitchCase="'cpu'"
        ><mon-graph @fadeInRight class="col-span-9 h-full perf-content card" [options]="selectedOption.graphOptions" #cpuChart></mon-graph
      ></ng-container>
      <ng-container *ngSwitchCase="'memory'"
        ><mon-graph
          @fadeInRight
          class="col-span-9 h-full perf-content card"
          [options]="selectedOption.graphOptions"
          #memoryChart
        ></mon-graph
      ></ng-container>
      <ng-container *ngSwitchCase="'disk'"
        ><mon-graph @fadeInRight class="col-span-9 h-full perf-content card" [options]="selectedOption.graphOptions" #diskChart></mon-graph
      ></ng-container>
      <ng-container *ngSwitchCase="'ethernet'"
        ><mon-graph
          @fadeInRight
          class="col-span-9 h-full perf-content card"
          [options]="selectedOption.graphOptions"
          #ethernetChart
        ></mon-graph
      ></ng-container>
      <ng-container *ngSwitchCase="'wifi'"
        ><mon-graph @fadeInRight class="col-span-9 h-full perf-content card" [options]="selectedOption.graphOptions" #wifiChart></mon-graph
      ></ng-container>
      <ng-container *ngSwitchCase="'gpu'"
        ><mon-graph @fadeInRight class="col-span-9 h-full perf-content card" [options]="selectedOption.graphOptions" #gpuChart></mon-graph
      ></ng-container>
    </ng-container>
  </ng-container>
</div>
