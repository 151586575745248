import { Component, ViewChild } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { deepEqual } from 'fast-equals';
import { BehaviorSubject, combineLatest, iif } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { LayoutService } from 'src/@vex/services/layout.service';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { StateService } from 'src/app/core/services/state.service';
import { GraphOptions, SeriesData } from 'src/app/shared/interfaces/graph-options.interface';
import { Agent } from 'src/app/shared/models/agent.model';
import { deepCopy } from 'src/app/utils/clone';
import { friendlyDataSizeInBytes } from 'src/app/utils/data-size';

import { GraphComponent } from './graph/graph.component';

type NavType = 'cpu' | 'memory' | 'disk' | 'ethernet' | 'wifi' | 'gpu';

interface PerfNavItem {
  id: string;
  title: string;
  description?: string;
  icon: IconName;
  iconColor: string;
  type: NavType;
}

@Component({
  selector: 'mon-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
  animations: [fadeInUp400ms, stagger80ms, fadeInRight400ms]
})
export class PerformanceComponent {
  private _selectedNavItem = new BehaviorSubject<PerfNavItem>(undefined);
  selectedNavItem$ = this._selectedNavItem.asObservable();

  private _loadingGraph = new BehaviorSubject<boolean>(false);
  loadingGraph$ = this._loadingGraph.asObservable();

  selectedOption$ = this.selectedNavItem$.pipe(
    distinctUntilChanged(),
    tap(() => this._loadingGraph.next(true)),
    map((item) => {
      let graphOptions: GraphOptions;

      if (this.graphOptions.has(item.id)) {
        graphOptions = this.graphOptions.get(item.id);
      }

      return {
        graphOptions: graphOptions,
        type: item.type
      };
    }),
    tap(() => setTimeout(() => this._loadingGraph.next(false), 1)) // this delay is here to cause angular to redraw
  );

  private _selectedGraphOptions = new BehaviorSubject<GraphOptions>(undefined);
  selectedGraphOptions$ = this._selectedGraphOptions.asObservable();

  isMobile$ = this.layoutSvc.isMobile$;
  bottomDrawerCollapsed$ = this.layoutSvc.bottomDrawerCollapsed$;

  navMenu$ = combineLatest([
    this.stateSvc.liveMode$.pipe(
      mergeMap((liveMode) =>
        iif(
          () => liveMode,
          this.agentLivePollerSvc.liveAgent$,
          this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
        )
      ),
      filter((agent) => !!agent),
      map((agent) => {
        const items = new Array<PerfNavItem>();

        this.addCPUItems(items, agent);
        this.addMemoryItems(items, agent);
        this.addDiskItems(items, agent);
        this.addEthernetItems(items, agent);
        this.addWifiItems(items, agent);
        this.addGPUItems(items, agent);

        return items;
      }),
      distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
      tap((navItems) => {
        if (!this._selectedNavItem.value) {
          this.setActiveNavItem(navItems[0]);
        }
      })
    ),
    this.selectedNavItem$.pipe(
      filter((selectedNav) => !!selectedNav),
      distinctUntilChanged((prev, curr) => deepEqual(prev, curr))
    )
  ]).pipe(
    map(([navItems, selectedNavItem]) => {
      return { navItems, selectedNavItem };
    })
  );

  private _cpuChart: GraphComponent;
  @ViewChild('cpuChart') set cpuChart(cpuChart: GraphComponent) {
    this._cpuChart = cpuChart;
  }

  private _memoryChart: GraphComponent;
  @ViewChild('memoryChart') set memoryChart(memoryChart: GraphComponent) {
    this._memoryChart = memoryChart;
  }

  private _diskChart: GraphComponent;
  @ViewChild('diskChart') set diskChart(diskChart: GraphComponent) {
    this._diskChart = diskChart;
  }

  private _ethernetChart: GraphComponent;
  @ViewChild('ethernetChart') set ethernetChart(ethernetChart: GraphComponent) {
    this._ethernetChart = ethernetChart;
  }

  private _wifiChart: GraphComponent;
  @ViewChild('wifiChart') set wifiChart(wifiChart: GraphComponent) {
    this._wifiChart = wifiChart;
  }

  private _gpuChart: GraphComponent;
  @ViewChild('gpuChart') set gpuChart(gpuChart: GraphComponent) {
    this._gpuChart = gpuChart;
  }

  graphOptions = new Map<string, GraphOptions>();
  private count = 60;

  constructor(
    private layoutSvc: LayoutService,
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService
  ) {}

  setActiveNavItem(item: PerfNavItem): void {
    this._selectedNavItem.next(item);
  }

  private addCPUItems(items: PerfNavItem[], agent: Agent): void {
    const cpu = agent.system.cpu;
    const summary = agent.stats.summary.cpu;
    const title = 'CPU';
    const value = [Math.round(summary.utilizationPercent)];
    const cpuNavItem: PerfNavItem = {
      id: title,
      title: title,
      icon: 'microchip',
      iconColor: 'blue',
      type: 'cpu'
    };

    items.push(cpuNavItem);

    let cpuGraphOps: GraphOptions;

    if (this.graphOptions.has(title)) {
      cpuGraphOps = this.graphOptions.get(title);

      if (this._cpuChart?.options.id === title) {
        this._cpuChart?.appendData(value);
      }
    } else {
      const initialSeries: SeriesData[] = [
        {
          name: 'Utilization',
          data: new Array(this.count).fill(0)
        }
      ];

      cpuGraphOps = {
        id: title,
        title: title,
        icon: 'microchip',
        iconColor: 'blue',
        label: agent.system.cpu.modelName,
        maxY: [100],
        seriesColors: [['blue']],
        seriesLineStyle: [['solid']],
        initialSeries: [initialSeries],
        focusProperties: [],
        properties: {}
      };
    }

    value.forEach((v, i) => {
      cpuGraphOps.initialSeries[0][i].data.splice(0, 1);
      cpuGraphOps.initialSeries[0][i].data.push(v);
    });

    Object.assign(cpuGraphOps.focusProperties, [
      {
        label: 'Utilization',
        value: `${value}%`,
        graphColor: 'blue',
        graphLineStyle: 'solid'
      }
    ]);

    Object.assign(cpuGraphOps.properties, {
      Sockets: cpu.sockets,
      Cores: cpu.cores,
      Processors: cpu.logicalProcessors,
      'Base Speed': `${cpu.speed} MHz`
    });

    this.graphOptions.set(title, cpuGraphOps);
  }

  private addMemoryItems(items: PerfNavItem[], agent: Agent): void {
    const systemMemoryTotal = agent.system.memory.total;
    const summary = agent.stats.summary.memory;
    const title = 'Memory';
    const value = [Math.round((summary.inUse / 1024) * 10) / 10];
    const memoryNavItem: PerfNavItem = {
      id: title,
      title: title,
      icon: 'memory',
      iconColor: 'purple',
      type: 'memory'
    };

    items.push(memoryNavItem);

    let memoryGraphOps: GraphOptions;

    if (this.graphOptions.has(title)) {
      memoryGraphOps = this.graphOptions.get(title);

      if (this._memoryChart?.options.id === title) {
        this._memoryChart?.appendData(value);
      }
    } else {
      const initialSeries: SeriesData[] = [
        {
          name: 'In Use',
          data: new Array(this.count).fill(0)
        }
      ];

      memoryGraphOps = {
        id: title,
        title: title,
        label: friendlyDataSizeInBytes(systemMemoryTotal * 1024 * 1024, 0),
        icon: 'memory',
        iconColor: 'purple',
        maxY: [Math.round((systemMemoryTotal / 1024) * 10) / 10],
        seriesColors: [['purple']],
        seriesLineStyle: [['solid']],
        initialSeries: [initialSeries],
        focusProperties: []
      };
    }

    value.forEach((v, i) => {
      memoryGraphOps.initialSeries[0][i].data.splice(0, 1);
      memoryGraphOps.initialSeries[0][i].data.push(v);
    });

    Object.assign(memoryGraphOps.focusProperties, [
      {
        label: 'In Use',
        value: friendlyDataSizeInBytes(summary.inUse * 1024 * 1024, 1),
        graphColor: 'purple',
        graphLineStyle: 'solid'
      },
      {
        label: 'Available',
        value: friendlyDataSizeInBytes(
          (systemMemoryTotal - summary.inUse) * 1024 * 1024,
          1
        )
      }
    ]);

    this.graphOptions.set(title, memoryGraphOps);
  }

  private addDiskItems(items: PerfNavItem[], agent: Agent): void {
    const disks = agent.stats.summary.disk.disks || [];

    disks
      .sort((d) => d.index)
      .forEach((d) => {
        const title = `Disk ${d.index}${
          d.drives && d.drives.length > 0
            ? ` (${d.drives
                .sort((d1, d2) => (d1 < d2 ? -1 : d1 > d2 ? 1 : 0))
                .map((drive) => `${drive}:`)
                .join(' ')})`
            : ''
        }`;
        const value = [Math.round(d.activeTime)];
        const diskNavItem: PerfNavItem = {
          id: d.id,
          title: title,
          icon: 'hdd',
          iconColor: 'green',
          type: 'disk'
        };

        items.push(diskNavItem);

        let diskGraphOps: GraphOptions;

        if (this.graphOptions.has(d.id)) {
          diskGraphOps = this.graphOptions.get(d.id);

          if (this._diskChart?.options.id === d.id) {
            this._diskChart?.appendData(value);
          }
        } else {
          const initialSeries: SeriesData[] = [
            {
              name: 'Active Time',
              data: new Array(this.count).fill(0)
            }
          ];

          diskGraphOps = {
            id: d.id,
            icon: 'hdd',
            iconColor: 'green',
            title: title,
            label: [d.vendorID, d.productID].join(' '),
            maxY: [100],
            seriesColors: [['green']],
            seriesLineStyle: [['solid']],
            initialSeries: [initialSeries],
            focusProperties: [],
            properties: {}
          };
        }

        value.forEach((v, i) => {
          diskGraphOps.initialSeries[0][i].data.splice(0, 1);
          diskGraphOps.initialSeries[0][i].data.push(v);
        });

        Object.assign(diskGraphOps.focusProperties, [
          {
            label: 'Active Time',
            value: `${Math.round(d.activeTime)}%`,
            graphColor: 'green',
            graphLineStyle: 'solid'
          },
          {
            label: 'Avg Response Time',
            value: `${Math.round(d.latency * 10) / 10} ms`
          },
          {
            label: 'Read Speed',
            value: `${friendlyDataSizeInBytes(d.readSpeed, 1)}/s`
          },
          {
            label: 'Write Speed',
            value: `${friendlyDataSizeInBytes(d.writeSpeed, 1)}/s`
          }
        ]);

        Object.assign(diskGraphOps.properties, {
          Capacity: friendlyDataSizeInBytes(d.capacity),
          Formatted: friendlyDataSizeInBytes(d.formatted),
          'System Disk': d.system ? 'Yes' : 'No',
          'Page File': d.pageFile ? 'Yes' : 'No',
          Type: d.type
        });

        this.graphOptions.set(d.id, diskGraphOps);
      });
  }

  private addEthernetItems(items: PerfNavItem[], agent: Agent): void {
    const ethernetAdapters = agent.stats.summary.network.ethernetAdapters || [];

    ethernetAdapters
      .filter((e) => e.interfaceState === 1)
      .forEach((e) => {
        const title = `Ethernet`;
        const value = [
          Math.round((e.sendSpeed * 8) / 1000),
          Math.round((e.receiveSpeed * 8) / 1000)
        ];
        const ethernetNavItem: PerfNavItem = {
          id: e.interfaceGUID,
          title: title,
          icon: 'network-wired',
          iconColor: 'orange',
          type: 'ethernet'
        };

        items.push(ethernetNavItem);

        let ethernetGraphOps: GraphOptions;

        if (this.graphOptions.has(e.interfaceGUID)) {
          ethernetGraphOps = this.graphOptions.get(e.interfaceGUID);

          if (this._ethernetChart?.options.id === e.interfaceGUID) {
            this._ethernetChart?.appendData(value);
          }
        } else {
          const initialSeries: SeriesData[] = [
            {
              name: 'Send',
              data: new Array(this.count).fill(0)
            },
            {
              name: 'Receive',
              data: new Array(this.count).fill(0)
            }
          ];

          ethernetGraphOps = {
            id: e.interfaceGUID,
            title: `Ethernet`,
            label: e.interfaceDescription,
            icon: 'ethernet',
            iconColor: 'orange',
            seriesColors: [['orange', 'orange']],
            seriesLineStyle: [['dashed', 'solid']],
            maxY: [undefined],
            initialSeries: [initialSeries],
            focusProperties: [],
            properties: {}
          };
        }

        value.forEach((v, i) => {
          ethernetGraphOps.initialSeries[0][i].data.splice(0, 1);
          ethernetGraphOps.initialSeries[0][i].data.push(v);
        });

        Object.assign(ethernetGraphOps.focusProperties, [
          {
            label: 'Send',
            value: e.sendSpeedD,
            graphColor: 'orange',
            graphLineStyle: 'dashed'
          },
          {
            label: 'Receive',
            value: e.receiveSpeedD,
            graphColor: 'orange',
            graphLineStyle: 'solid'
          },
          {
            label: 'Gateway Latency',
            value: e.gatewayPing ? e.gatewayPing.pingD : ''
          }
        ]);

        Object.assign(ethernetGraphOps.properties, {
          IP: e.ipv4,
          'Gateway IP': e.gatewayIP
        });

        this.graphOptions.set(e.interfaceGUID, ethernetGraphOps);
      });
  }

  private addWifiItems(items: PerfNavItem[], agent: Agent): void {
    const wifiAdapters = agent.stats.summary.network.wifiAdapters || [];
    wifiAdapters
      .filter((e) => e.interfaceState === 1)
      .forEach((w) => {
        const title = `Wi-Fi`;
        const value = [
          Math.round((w.sendSpeed * 8) / 1000),
          Math.round((w.receiveSpeed * 8) / 1000)
        ];
        const wifiNavItem: PerfNavItem = {
          id: w.interfaceGUID,
          title: title,
          icon: 'wifi',
          iconColor: 'orange',
          type: 'wifi'
        };

        items.push(wifiNavItem);

        let wifiGraphOps: GraphOptions;

        if (this.graphOptions.has(w.interfaceGUID)) {
          wifiGraphOps = this.graphOptions.get(w.interfaceGUID);

          if (this._wifiChart?.options.id === w.interfaceGUID) {
            this._wifiChart?.appendData(value);
          }
        } else {
          const initialSeries: SeriesData[] = [
            {
              name: 'Send',
              data: new Array(this.count).fill(0)
            },
            {
              name: 'Receive',
              data: new Array(this.count).fill(0)
            }
          ];

          wifiGraphOps = {
            id: w.interfaceGUID,
            title: `Wi-Fi`,
            label: w.interfaceDescription,
            icon: 'wifi',
            iconColor: 'orange',
            seriesColors: [['orange', 'orange']],
            seriesLineStyle: [['dashed', 'solid']],
            maxY: [undefined],
            initialSeries: [initialSeries],
            focusProperties: [],
            properties: {}
          };
        }

        value.forEach((v, i) => {
          wifiGraphOps.initialSeries[0][i].data.splice(0, 1);
          wifiGraphOps.initialSeries[0][i].data.push(v);
        });

        Object.assign(wifiGraphOps.focusProperties, [
          {
            label: 'Send',
            value: w.sendSpeedD,
            graphColor: 'orange',
            graphLineStyle: 'dashed'
          },
          {
            label: 'Receive',
            value: w.receiveSpeedD,
            graphColor: 'orange',
            graphLineStyle: 'solid'
          },
          {
            label: 'Strength',
            value: `${w.signalQuality}%`
          },
          {
            label: 'Gateway Latency',
            value: w.gatewayPing ? w.gatewayPing.pingD : ''
          },
          {
            label: 'Link Speed(Rx/Tx)',
            value: `${w.rxRate / 1000}/${w.txRate / 1000}Mbps`
          }
        ]);

        Object.assign(wifiGraphOps.properties, {
          IP: w.ipv4,
          'Gateway IP': w.gatewayIP,
          SSID: w.ssid,
          Protocol: w.protocol,
          Security: `${w.cipher}, ${w.auth}`,
          Band: w.band
        });

        this.graphOptions.set(w.interfaceGUID, wifiGraphOps);
      });
  }

  private addGPUItems(items: PerfNavItem[], agent: Agent): void {
    const gpus = agent.stats.summary.gpu.metrics || [];

    gpus.forEach((g, i) => {
      const title = `GPU ${i}`;
      const gpuDedicated = `${Math.round(
        agent.system.gpu[i].dedicatedVideoMemorySize / (1024 * 1024 * 1024)
      )} GB`;
      const gpuShared = `${Math.round(
        agent.system.gpu[i].sharedSystemMemorySize / (1024 * 1024 * 1024)
      )} GB`;
      const value = [g.usagePercent];
      const gpuNavItem: PerfNavItem = {
        id: title,
        title: title,
        icon: 'microchip',
        iconColor: 'blue',
        type: 'gpu'
      };

      items.push(gpuNavItem);

      let gpuGraphOps: GraphOptions;

      if (this.graphOptions.has(title)) {
        gpuGraphOps = this.graphOptions.get(title);

        if (this._gpuChart?.options.id === title) {
          this._gpuChart?.appendData(value);
        }
      } else {
        const initialSeries: SeriesData[] = [
          {
            name: 'Utilization',
            data: new Array(this.count).fill(0)
          }
        ];

        gpuGraphOps = {
          id: title,
          title: title,
          label: g.adapterName,
          icon: 'monitor-heart-rate',
          iconColor: 'blue',
          maxY: [100],
          seriesColors: [['blue']],
          seriesLineStyle: [['solid']],
          initialSeries: [initialSeries],
          focusProperties: [],
          properties: {}
        };
      }

      value.forEach((v, i) => {
        gpuGraphOps.initialSeries[0][i].data.splice(0, 1);
        gpuGraphOps.initialSeries[0][i].data.push(v);
      });

      Object.assign(gpuGraphOps.focusProperties, [
        {
          label: 'Utilization',
          value: `${g.usagePercent}%`,
          graphColor: 'blue',
          graphLineStyle: 'solid'
        },
        {
          label: 'Shared',
          value: `${(g.sharedUsage / (1024 * 1024 * 1024)).toFixed(1)} / ${gpuShared}`
        }
      ]);

      Object.assign(gpuGraphOps.properties, {
        Dedicated: `${(g.dedicatedUsage / (1024 * 1024 * 1024)).toFixed(
          1
        )} / ${gpuDedicated}`,
        Temp: `${g.temperature / 10} C`
      });

      this.graphOptions.set(title, gpuGraphOps);
    });
  }
}
