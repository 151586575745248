import { TriggerService } from 'src/app/core/services/trigger.service';
import { Updatable } from '../interfaces/updatable.interface';
import { AgentStats } from './agent-stats.model';
import { Remoter } from './session.model';
import { System } from './system.model';
import { Trigger } from './trigger.model';

export class Agent implements Updatable<Agent> {
  public isLive: boolean;
  public version = '';
  public id = '';
  public lastLiveUpdate: Date;
  public lastRemoteeUpdate: Date;
  public lastCacheUpdate: Date;
  public lastUpdate: Date;
  public timestamp: Date;
  public created: Date;
  public licensed = false;
  public system: System;
  public stats: AgentStats;

  _remoteSessions: Array<Remoter>;
  get remoteSessions(): Array<Remoter> {
    if (this._remoteSessions) {
      return this._remoteSessions;
    }

    const remoteSessions = new Array<Remoter>();

    if (this.stats) {
      this.stats.summary.system.sessions.map((s) => {
        const remoteSession = s.isRemoteSession;
        if (remoteSession) {
          remoteSessions.push(remoteSession);
        }
      });

      this._remoteSessions = remoteSessions;

      return this._remoteSessions;
    }

    return new Array<Remoter>();
  }

  constructor(init?: Partial<Agent>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.lastLiveUpdate = new Date(init.lastLiveUpdate);
    this.lastRemoteeUpdate = new Date(init.lastRemoteeUpdate);
    this.lastCacheUpdate = new Date(init.lastCacheUpdate);
    this.lastUpdate = new Date(init.lastUpdate);
    this.timestamp = new Date(init.timestamp);
    this.created = new Date(init.created);
    if (this.stats) {
      this.stats = new AgentStats(this.stats);
    }
    if (this.system) {
      this.system = new System(this.system);
    }
  }

  _triggered: Array<Trigger>;
  getTriggered(): Array<Trigger> {
    if (this._triggered) {
      return this._triggered;
    }

    this._triggered = new Array<Trigger>();

    if (this.isLive) {
      (TriggerService.getTriggers() || []).forEach((t) => {
        this._triggered.push(t);
      });
    } else {
      this.stats.summary.alarms?.forEach((a) => {
        this._triggered.push(a.trigger);
      });
    }

    return this._triggered;
  }

  hasTrigger(value: string): Trigger[] {
    return this.getTriggered().filter(
      (t) => !!t.conditions.find((c) => c.field === value)
    );
  }

  update(input: Partial<Agent>): this {
    return Object.assign(this, input);
  }
}

export class CCAgent {
  ccagent: CCAgentMeta;

  constructor(init?: Partial<CCAgent>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);

    this.ccagent = new CCAgentMeta(this.ccagent);
  }
}

export class CCAgentMeta {
  version: string;
  hash: string;

  constructor(init?: Partial<CCAgentMeta>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }
}
