import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { filter, map, Observable, take } from 'rxjs';

import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseGuard  {
  constructor(private stateSvc: StateService, private router: Router) {}

  canLoad():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate();
  }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.stateSvc.tenant$.pipe(
      filter((tenant) => !!tenant),
      take(1),
      map((tenant) => {
        if (tenant.license.expired) {
          return this.router.createUrlTree(['home', 'expired']);
        }
        return true;
      })
    );
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate();
  }
}
