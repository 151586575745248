import { Updatable } from '../interfaces/updatable.interface';

export class APIKey implements Updatable<APIKey> {
  public apiKey: string;
  public timestamp: Date;

  constructor(init?: Partial<APIKey>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);
  }

  update(input: Partial<APIKey>): this {
    return Object.assign(this, input);
  }
}
