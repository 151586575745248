import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { debounceTime, delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { Link } from 'src/@vex/interfaces/link.interface';
import { ApiService, Response } from 'src/app/core/services/api.service';
import { StateService } from 'src/app/core/services/state.service';
import { UserService } from 'src/app/core/services/user.service';

@UntilDestroy()
@Component({
  selector: 'mon-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [scaleIn400ms, fadeInRight400ms, fadeInUp400ms]
})
export class ProfileComponent {
  hotKeySeq = '';
  gm = 0;
  hideGM = true;

  tenantAndUser$ = combineLatest([
    this.stateSvc.tenant$,
    this.stateSvc.currentUser$
  ]).pipe(
    map(([tenant, user]) => {
      return { tenant: tenant, user: user };
    })
  );

  links: Link[] = [
    {
      label: 'GENERAL',
      route: './general',
      routerLinkActiveOptions: { exact: true }
    },
    {
      label: 'SETTINGS',
      route: './settings'
    }
  ];

  constructor(
    private http: HttpClient,
    private stateSvc: StateService,
    private userSvc: UserService
  ) {
    fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        untilDestroyed(this),
        tap((e) => (this.hotKeySeq = this.hotKeySeq.concat(e.key))),
        debounceTime(250),
        map(() => {
          const seq = this.hotKeySeq;
          this.hotKeySeq = '';
          return seq;
        }),
        filter((seq) => seq === 'lwdqd'),
        mergeMap(() => this.isGm()),
        filter((isGm) => isGm),
        tap(() => {
          this.hideGM = false;
          this.gm = 1;
          window['cc_gm'] = true;
        }),
        mergeMap(() => this.userSvc.me()),
        tap((user) => this.stateSvc.setCurrentUser(user)),
        delay(1200),
        tap(() => (this.gm = 2)),
        delay(500),
        tap(() => {
          this.hideGM = true;
          this.gm = 0;
        })
      )
      .subscribe();
  }

  private isGm(): Observable<boolean> {
    const url = `${ApiService.API_URL}/internal/gm`;
    return this.http
      .get<Response>(url)
      .pipe(map((response) => (response ? !!response.message : false)));
  }
}
