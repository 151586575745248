export function tailwindCssColorToRGB(color: string): string {
  const ctx = document.createElement('div');
  if (color.indexOf('text-') < 0) {
    color = 'text-' + color;
  }
  ctx.classList.add(color);
  document.body.appendChild(ctx);
  color = window.getComputedStyle(ctx).color;
  document.body.removeChild(ctx);
  return color;
}
