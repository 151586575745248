import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  iif,
  map,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { ContactService } from 'src/app/core/services/contact.service';
import { Country, PostalService } from 'src/app/core/services/postal.service';
import { StateService } from 'src/app/core/services/state.service';
import {
  SubscribeService,
  SubscriptionLicenseResult
} from 'src/app/core/services/subscribe.service';
import { TenantPatch, TenantService } from 'src/app/core/services/tenant.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { ConfirmDialog } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { CancelComponent } from 'src/app/shared/components/subscription/cancel/cancel.component';
import { PaymentSourceComponent } from 'src/app/shared/components/subscription/payment-source/payment-source.component';
import { ReactivateComponent } from 'src/app/shared/components/subscription/reactivate/reactivate.component';
import { SubscriptionComponent } from 'src/app/shared/components/subscription/subscription.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { RenderValue } from 'src/app/shared/interfaces/render-value';
import { Contact } from 'src/app/shared/models/contact.model';
import { License, LicenseStatus } from 'src/app/shared/models/license.model';
import { PaymentSource, Subscription } from 'src/app/shared/models/subscription.model';
import { Address, Tenant } from 'src/app/shared/models/tenant.model';
import { formatAddress } from 'src/app/utils/address';
import { UNDEFINED, UNDEFINED_TYPE } from 'src/app/utils/rxjs';
import {
  ContactEditComponent,
  ContactEditParams
} from './contact-edit/contact-edit.component';
import {
  TenantEditComponent,
  TenantEditParams,
  TenantEditView
} from './tenant-edit/tenant-edit.component';
import {
  VerifyComponent,
  VerifyPaymentSourceParams
} from 'src/app/shared/components/subscription/payment-source/verify/verify.component';

class SettingItem {
  public readonly = false;
  public view: TenantEditView;
  public label: string;
  public value: string;
  public requiredPerms: string[];

  constructor(
    readonly: boolean,
    view: TenantEditView,
    label: string,
    value: string,
    requiredPerms: string[]
  ) {
    this.readonly = readonly;
    this.view = view;
    this.label = label;
    this.value = value;
    this.requiredPerms = requiredPerms;
  }

  spaceName(name: string): string {
    return name.replace(/([A-Z])/g, ' $1').trim();
  }
}

@Component({
  selector: 'mon-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
  animations: [fadeInRight400ms, fadeInUp400ms, stagger80ms]
})
export class TenantComponent {
  contractTerm = Subscription.contractTerm;
  licenseStatus = LicenseStatus;
  permission = Permission;
  tenantEditView = TenantEditView;
  formatAddress = formatAddress;
  faPaymentSourceIcon: IconProp;

  private _billingAddress = new BehaviorSubject<Address>(undefined);
  billingAddress$ = combineLatest([
    this._billingAddress.asObservable(),
    this.postalSvc.getCountries().pipe(catchError(() => of(new Array<Country>())))
  ]).pipe(
    map(([address, countries]) => {
      const country =
        countries.find((c) => c.iso2 === address.countryCode)?.name || address.country;

      return formatAddress(
        address.firstName,
        address.lastName,
        address.company,
        [address.address1, address.address2, address.address3],
        address.locality,
        address.region || address.regionCode,
        address.postcode,
        country
      );
    })
  );

  private _shippingAddress = new BehaviorSubject<Address>(undefined);
  shippingAddress$ = combineLatest([
    this._shippingAddress.asObservable(),
    this.postalSvc.getCountries().pipe(catchError(() => of(new Array<Country>())))
  ]).pipe(
    map(([address, countries]) => {
      const country =
        countries.find((c) => c.iso2 === address.countryCode)?.name || address.country;

      return formatAddress(
        address.firstName,
        address.lastName,
        address.company,
        [address.address1, address.address2, address.address3],
        address.locality,
        address.region || address.regionCode,
        address.postcode,
        country
      );
    })
  );

  private _tenant = new BehaviorSubject<Tenant>(undefined);
  tenant$ = this._tenant.asObservable().pipe(
    switchMap((t) =>
      iif(() => !!t, of(t), this.tenantSvc.get().pipe(tap((t) => this._tenant.next(t))))
    ),
    tap((t) => {
      if (t.billingAddress) {
        this._billingAddress.next(t.billingAddress);
      }
    }),
    tap((t) => {
      if (t.shippingAddress) {
        this._shippingAddress.next(t.shippingAddress);
      }
    })
  );

  _license = new BehaviorSubject<RenderValue<License>>({ value: undefined });
  license$ = this._license.asObservable();

  _subscription = new BehaviorSubject<RenderValue<Subscription>>({ value: undefined });
  subscription$ = this._subscription.asObservable().pipe(
    filter((s) => !!s.value),
    switchMap((s) =>
      this.subscribeSvc.getPlans().pipe(
        map((plans) => {
          let foundName: string;
          plans.find((p) => {
            const foundFreq = p.frequencies.find(
              (f) => f.priceID === s.value.itemPriceID
            );
            if (foundFreq) {
              foundName = foundFreq.name;
              return true;
            }
            return false;
          });

          s.value['name'] = foundName;

          return s;
        })
      )
    )
  );

  _paymentSource = new BehaviorSubject<RenderValue<PaymentSource>>({
    value: undefined
  });
  paymentSource$ = this._paymentSource.asObservable();

  data$ = combineLatest([this.tenant$, this.stateSvc.currentUser$]).pipe(
    tap(([tenant]) => this._license.next({ value: tenant.license })),
    concatMap(([tenant, user]) => {
      return iif(
        () => user.hasPerms([Permission.Tenant_ViewLicenseAndBilling]),
        combineLatest([
          of(tenant),
          of(user),
          this.subscribeSvc.getSubscription().pipe(
            filter((s) => !!s),
            catchError(() => UNDEFINED),
            tap((s) => this._subscription.next({ value: s }))
          ),
          this.subscribeSvc.getPaymentSource().pipe(
            catchError(() => UNDEFINED),
            tap((ps) => this._paymentSource.next({ value: ps }))
          )
        ]),
        combineLatest([
          of(tenant),
          of(user),
          UNDEFINED_TYPE<Subscription>(),
          UNDEFINED_TYPE<PaymentSource>()
        ])
      );
    }),
    map(([tenant, user, subscription, paymentSource]) => {
      const canUpdateLicense =
        user.hasPerms([Permission.Tenant_UpdateLicenseAndBilling]) &&
        !subscription?.mustContactSales; // users can't update a reseller subscription
      const canCancel =
        canUpdateLicense &&
        !tenant.license.expired &&
        tenant.license.status === LicenseStatus.Active;
      return {
        tenant,
        user,
        hasPaymentSource: !!paymentSource,
        canUpdateLicense,
        canCancel,
        mustContactSales: subscription?.mustContactSales || true
      };
    })
  );

  contacts: Contact[] = [];
  contacts$ = this.contactSvc
    .getAll()
    .pipe(tap((contacts) => (this.contacts = contacts)));

  exposedFields$ = this.tenant$.pipe(
    map((t) => [
      new SettingItem(true, null, 'Name', t.name, []),
      new SettingItem(
        false,
        TenantEditView.CompanyName,
        `Company Name`,
        t.companyName,
        []
      ),
      new SettingItem(false, TenantEditView.OwnerName, 'Owner Name', t.fullnameD, [
        Permission.Tenant_Read
      ]),
      new SettingItem(false, TenantEditView.Email, 'Email', t.ownerEmail, []),
      new SettingItem(false, TenantEditView.Phone, 'Phone', t.phone, [
        Permission.Tenant_Read
      ]),
      new SettingItem(true, null, 'Created', t.createdD, [Permission.Tenant_Read]),
      new SettingItem(true, null, 'Tenant ID', t.id, [Permission.Tenant_Read])
    ])
  );

  constructor(
    private dialog: MatDialog,
    private confirm: ConfirmDialog,
    private snackBar: MatSnackBar,
    private tenantSvc: TenantService,
    private contactSvc: ContactService,
    private subscribeSvc: SubscribeService,
    private postalSvc: PostalService,
    private stateSvc: StateService
  ) {}

  editField(tenant: Tenant, view: TenantEditView): void {
    this.dialog.open(TenantEditComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new TenantEditParams({
        view,
        tenantID: tenant ? tenant.id : null,
        onSuccess: (patch: TenantPatch) => {
          const t = this._tenant.getValue();
          this._tenant.next(Object.assign(t, patch));

          let p = undefined;
          if (patch.name !== undefined) {
            p = Object.assign(p || {}, { name: patch.name });
          }
          if (patch.companyName !== undefined) {
            p = Object.assign(p || {}, { companyName: patch.companyName });
          }

          p && this.stateSvc.setTenantAndCompanyName(p);
        }
      }),
      width: '600px'
    });
  }

  updateSubscription(): void {
    forkJoin([
      this.subscription$.pipe(take(1)),
      this.paymentSource$.pipe(take(1))
    ]).subscribe({
      next: ([s, ps]) => {
        this.dialog.open(SubscriptionComponent, {
          autoFocus: false,
          restoreFocus: false,
          width: '600px',
          data: {
            subscription: s.value,
            paymentSource: ps.value,
            stepParams: {
              enableMachineCountStep: true,
              enablePaymentStep: true,
              enableEstimateStep: true
            },
            onSubscriptionUpdated: (result: Subscription) => {
              this._subscription.next({ value: result });
            },
            onPaymentSourceUpdated: (result: PaymentSource) => {
              this._paymentSource.next({ value: result });
            }
          }
        });
      }
    });
  }

  updatePaymentSource(ps: PaymentSource): void {
    this.dialog.open(PaymentSourceComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '600px',
      data: {
        paymentSource: ps,
        onPaymentSourceUpdated: (paymentSource: PaymentSource) => {
          this._paymentSource.next({ value: paymentSource });
        }
      }
    });
  }

  cancelSubscription(): void {
    this.dialog.open(CancelComponent, {
      restoreFocus: false,
      data: {
        onConfirm: (result: SubscriptionLicenseResult) => {
          if (result.subscription) {
            this._subscription.next({ value: result.subscription });
          }
          if (result.license) {
            this._license.next({ value: result.license });
          }
        }
      }
    });
  }

  reactivateSubscription(): void {
    this.dialog.open(ReactivateComponent, {
      restoreFocus: false,
      data: {
        onConfirm: (result: SubscriptionLicenseResult) => {
          if (result.subscription) {
            this._subscription.next({ value: result.subscription });
          }
          if (result.license) {
            this._license.next({ value: result.license });
          }
        },
        onCancel: () => {
          this.dialog.closeAll();
        }
      }
    });
  }

  daysLeft(v: Date): string {
    const daysLeft = Math.max(
      Math.floor((v.getTime() - new Date().getTime()) / (1000 * 3600 * 24)),
      0
    );
    return `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;
  }

  verifyBankAccount(event: Event, paymentSourceID: string): void {
    event.stopImmediatePropagation();

    this.dialog.open(VerifyComponent, {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'verify-payment-source',
      data: new VerifyPaymentSourceParams({
        paymentSourceID,
        onVerified: (paymentSource: PaymentSource) => {
          this._paymentSource.next({ value: paymentSource });
        }
      }),
      height: 'auto',
      width: '400px'
    });
  }

  editContact(event: Event, c: Contact): void {
    event.stopImmediatePropagation();

    this.dialog.open(ContactEditComponent, {
      restoreFocus: false,
      panelClass: 'contact-edit',
      data: new ContactEditParams({
        contact: c,
        onSuccess: (contact) => {
          const found = this.contacts.findIndex((c) => c.id === contact.id);
          if (found >= 0) {
            this.contacts[found] = contact;
          } else {
            this.contacts$.subscribe(); //refresh
          }
        }
      }),
      width: '600px'
    });
  }

  removeContact(event: Event, contact: Contact): void {
    event.stopImmediatePropagation();

    this.confirm.open(`Remove ${contact.email}?`, () => {
      this.contactSvc
        .delete([contact.id])
        .pipe(
          tap(() => {
            // toast message
            this.snackBar.open(`${contact.email} removed`, 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            const found = this.contacts.findIndex((c) => c.id === contact.id);
            if (found >= 0) {
              this.contacts.splice(found, 1);
            }
          }),
          catchError((err) => {
            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });

            return UNDEFINED;
          })
        )
        .subscribe();
    });
  }

  getContactFullName(c: Contact): string {
    return c.displayFullName;
  }

  getPlanItemName$(itemPriceID: string): Observable<string> {
    return this.subscribeSvc.getPlans().pipe(
      map((plans) => {
        let found;
        plans.find((p) => {
          const foundName = p.frequencies.find((f) => f.priceID === itemPriceID);
          if (foundName) {
            found = foundName;
            return true;
          }
          return false;
        });

        return found;
      })
    );
  }

  trackByValue(_index: number, item: SettingItem): string {
    return item.value;
  }

  trackByID(_index: number, contact: Contact): string {
    return contact.id;
  }
}
