import { animate, style, transition, trigger } from '@angular/animations';

export function fadeOutDownAnimation(duration: number) {
    return trigger('fadeOutDown', [
        transition(':leave', [
            style({
                transform: 'translateY(0)',
                opacity: 1
            }),
            animate(
                `${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({
                    transform: 'translateY(20px)',
                    opacity: 0
                })
            )
        ])
    ]);
}

export const fadeOutDown400ms = fadeOutDownAnimation(400);
export const fadeOutDown200ms = fadeOutDownAnimation(200);
export const fadeOutDown80ms = fadeOutDownAnimation(80);
