<div class="flex gap-1 items-center justify-center h-full">
  <ng-container *ngIf="!date.alarms || date.alarms.size === 0; else showChart">
    <div class="text-primary px-4 text-lg">
      <fa-icon [icon]="['fad', 'thumbs-up']" class="flex items-center justify-center"></fa-icon>
    </div>
  </ng-container>
  <ng-template #showChart>
    <div class="w-12 h-full">
      <mon-graph-bar [data]="data" [colors]="colors" [tooltips]="tooltips" class="w-full h-full"></mon-graph-bar>
    </div>
  </ng-template>
  <div class="">
    <div class="flex flex-col h-full whitespace-nowrap">
      <div class="flex-1 text-left">{{ date.timestamp | date: 'EEEE' }}</div>
      <div class="flex-1 text-left">{{ date.timestamp | date: 'longDate' }}</div>
    </div>
  </div>
</div>
