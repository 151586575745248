<div *ngIf="tenantAndUser$ | async as data">
  <div @fadeInUp class="card overflow-hidden">
    <div class="h-24 relative overflow-hidden vector-bg">
      <!-- <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div> -->
    </div>

    <div class="z-10 relative -mt-16 px-gutter flex items-center">
      <div
        *ngIf="!data.user.imageLink; else userImage"
        class="hidden sm:flex rounded-full h-24 w-24 items-center justify-center text-primary bg-primary-light"
      >
        <fa-icon [icon]="['fad', 'user']" size="3x"></fa-icon>
      </div>
      <ng-template #userImage>
        <img @scaleIn class="avatar h-24 w-24 flex-none align-start hidden sm:block border-3 border-white" [src]="data.user.imageLink" />
      </ng-template>

      <div class="max-w-full flex-auto sm:ml-6">
        <div class="h-16 flex items-center">
          <h1 @fadeInRight class="headline text-on-accent-500 m-0">PROFILE</h1>
        </div>

        <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar [tabPanel]="tabPanel">
          <a
            #rla="routerLinkActive"
            *ngFor="let link of links"
            [active]="rla.isActive"
            [disabled]="link.disabled"
            [routerLink]="link.route"
            mat-tab-link
            queryParamsHandling="preserve"
            [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
            routerLinkActive
          >
            {{ link.label }}
          </a>
        </nav>
      </div>
    </div>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>

<div
  [hidden]="hideGM"
  class="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center overflow-hidden"
  style="perspective: 600px"
>
  <div
    [ngClass]="gm === 0 ? 'hidden' : gm === 1 ? 'animate-slide-in-bck-center block' : gm === 2 ? 'animate-blur-out block' : ''"
    class="text-center"
  >
    <img src="assets/img/iddqd.png" />
  </div>
</div>
