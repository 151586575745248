import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, combineLatest, iif, throwError, timer } from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  first,
  mergeMap,
  retryWhen,
  take,
  tap
} from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { JitsiOptions, JitsiService } from 'src/app/core/services/jitsi.service';
import { RemoteService } from 'src/app/core/services/remote.service';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';
import { RemoteSession } from 'src/app/shared/models/remote-session.model';
import { Tenant } from 'src/app/shared/models/tenant.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

@Component({
  selector: 'mon-remote',
  templateUrl: './remote.component.html',
  styleUrls: ['./remote.component.scss'],
  animations: [fadeInUp400ms]
})
export class RemoteComponent implements OnInit {
  private _tenant = new BehaviorSubject<Tenant>(undefined);
  tenant$ = this._tenant.asObservable();

  private _roomName = new BehaviorSubject<string>(undefined);
  roomName$ = this._roomName.asObservable();

  private _useToken = new BehaviorSubject<boolean>(undefined);
  useToken$ = this._useToken.asObservable();

  private _connecting = new BehaviorSubject<boolean>(true);
  connecting$ = this._connecting.asObservable();

  private _active = new BehaviorSubject<boolean>(true);
  active$ = this._active.asObservable();

  private _error = new BehaviorSubject<string>(undefined);
  error$ = this._error.asObservable();

  private _closed = new BehaviorSubject<string>(undefined);
  closed$ = this._closed.asObservable();

  validateTenant$ = this.useToken$.pipe(
    mergeMap((useToken) => {
      return iif(
        () => useToken,
        this.authSvc.isAuthenticated$.pipe(filter((isAuthenticated) => isAuthenticated)),
        this.authSvc.isAuthenticated$
      );
    }),
    mergeMap((isAuthenticated) => {
      return iif(
        () => isAuthenticated,
        this.userSvc.me().pipe(
          tap((user) => {
            this.stateSvc.setCurrentUserSettings(user.settings);
            this.stateSvc.setCurrentUser(user);
          }),
          mergeMap(() => this.tenantSvc.get()),
          tap((tenant) => this._tenant.next(tenant))
        ),
        UNDEFINED
      );
    }),
    mergeMap(() =>
      combineLatest([this.tenant$, this.roomName$, this.active$.pipe(take(1))])
    ),
    mergeMap(([tenant, roomName]) => {
      return iif(
        () => !!tenant,
        this.remoteSvc.readConnectionAsHost(roomName),
        this.remoteSvc.readConnectionAsClient(roomName)
      );
    }),
    tap((session) => {
      const agentID = session.agentID.split(':')[0];
      const roomName = `${agentID}-${session.id.split('-')[4]}`;

      const options: JitsiOptions = {
        roomName,
        password: session.tags.get('meetingPwd'),
        width: '100%',
        height: '100%',
        parentNode: this.document.querySelector('#meet'),
        userInfo: {
          email: this.stateSvc.currentUser
            ? this.stateSvc.currentUser.email
            : 'unknown@commandctrl.com',
          displayName: this.stateSvc.currentUser
            ? this.stateSvc.currentUser.email
            : agentID
        },
        onload: () => {
          this._connecting.next(false);
        },
        onParticipantLeft: () => {
          this._active.next(false);
          this._closed.next('This Meeting Has Ended');
          this.jitsiSvc.endMeeting();
        },
        onReadyToClose: () => {
          this._active.next(false);
          this._closed.next('This Meeting Has Ended');
        }
      };

      this.jitsiSvc.createMeeting(options);
    }),
    retryWhen((errs) =>
      errs.pipe(
        delayWhen((err) => {
          if (err.status === 412 && this.agentAttempts < 5) {
            this.agentAttempts++;
            return timer(1000);
          } else {
            return throwError(() => err);
          }
        })
      )
    ),
    catchError((err) => {
      this._connecting.next(false);
      this._active.next(false);
      this._error.next('A Connection Could Not Be Made');
      return throwError(() => err);
    })
  );

  agentAttempts = 0;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private authSvc: AuthService,
    private userSvc: UserService,
    private tenantSvc: TenantService,
    private stateSvc: StateService,
    private remoteSvc: RemoteService,
    private jitsiSvc: JitsiService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).subscribe({
      next: (params: Params) => {
        this._useToken.next(params.useToken || false);

        this.router.navigate([], {
          queryParams: { useToken: null },
          queryParamsHandling: 'merge'
        });
      }
    });

    this.route.params.subscribe({
      next: (params: Params) => {
        this._roomName.next(params.roomName);
      }
    });
  }

  readConnection(): void {
    const callback$ = combineLatest([this.stateSvc.tenant$, this.roomName$]).pipe(
      mergeMap(([tenant, roomName]) => {
        return iif(
          () => !!tenant,
          this.remoteSvc.readConnectionAsHost(roomName),
          this.remoteSvc.readConnectionAsClient(roomName)
        );
      })
    );

    callback$.subscribe({
      next: (session: RemoteSession) => {
        const agentID = session.agentID.split(':')[0];
        const roomName = `${agentID}-${session.id.split('-')[4]}`;

        const options: JitsiOptions = {
          roomName,
          password: session.tags.get('meetingPwd'),
          width: '100%',
          height: '100%',
          parentNode: this.document.querySelector('#meet'),
          userInfo: {
            email: this.stateSvc.currentUser
              ? this.stateSvc.currentUser.email
              : 'unknown@commandctrl.com',
            displayName: this.stateSvc.currentUser
              ? this.stateSvc.currentUser.email
              : agentID
          },
          onload: () => {
            this._connecting.next(false);
          },
          onParticipantLeft: () => {
            this.jitsiSvc.endMeeting();
          },
          onReadyToClose: () => {
            this._active.next(false);
          }
        };

        this.jitsiSvc.createMeeting(options);
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 412 && this.agentAttempts < 5) {
          this.agentAttempts++;
          setTimeout(() => this.readConnection(), 1000);
        } else {
          this._connecting.next(false);
          this._active.next(false);
          this._error.next('A Connection Could Not Be Made');
        }
      }
    });
  }

  closeWindow(): void {
    window.close();
  }
}
