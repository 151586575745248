export const tableNames = {
  TASK_MANAGER_PROCESSES: 'task-manager-processes',
  TOOLS_ALARM: 'tools-alarms',
  INVENTORY_BLOCKED_MACHINES: 'inventory-blocked-machines',
  INVENTORY_MACHINES: 'inventory-machines',
  INVENTORY_USER_SESSIONS: 'inventory-user-sessions',
  BOTTOM_DRAWER_TASKS: 'bottom-drawer-tasks',
  SETTINGS_SITE: 'settings-site',
  SETTINGS_USERS: 'settings-users',
  SETTINGS_INVITES: 'settings-invites',
  SETTINGS_ROLES: 'settings-roles',
  TASK_MANAGER_SERVICES: 'task-manager-services',
  TASK_MANAGER_STARTUP: 'task-manager-startup',
  HISTORY_TASKS: 'history-tasks',
  TOOLS_ACTIONS: 'tools-actions',
  TOOLS_TRIGGERS: 'tools-triggers'
};
