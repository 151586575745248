<div class="flex flex-col h-full">
  <div class="flex flex-col md:flex-row md:tems-center md:justify-between w-full p-2">
    <div class="md:text-3xl font-bold">{{ options.title }}</div>
    <div class="md:text-xl text-secondary truncate">{{ options.label }}</div>
  </div>

  <div class="flex-1" #chartContainer>
    <apx-chart
      *ngIf="apexOptions$ | async as apexOptions"
      [tooltip]="apexOptions.tooltip"
      [grid]="apexOptions.grid"
      [colors]="apexOptions.colors"
      [xaxis]="apexOptions.xaxis"
      [yaxis]="apexOptions.yaxis"
      [stroke]="apexOptions.stroke"
      [chart]="apexOptions.chart"
      [dataLabels]="apexOptions.dataLabels"
      [series]="series"
      #chartObj
    >
    </apx-chart>
  </div>

  <div class="flex flex-row px-2" style="min-height: 100px">
    <div *ngIf="options.focusProperties" class="flex-1">
      <div class="grid grid-flow-row grid-cols-1 gap-0 focus-properties" [ngClass]="{ 'grid-cols-2': options.focusProperties.length > 2 }">
        <div
          *ngFor="let property of options.focusProperties; index as i"
          class="flex flex-col border-l-2 pl-1 border-opacity-50"
          [ngClass]="[
            i >= options.focusProperties.length - 1 && i % 2 == 0 ? 'col-span-2' : '',
            'border-' + (property.graphColor ? property.graphColor : 'transparent'),
            property.graphLineStyle === 'dashed' ? 'border-dashed' : ''
          ]"
        >
          <div class="flex-1 caption text-secondary m-0 truncate">
            {{ property.label }}
          </div>
          <div class="flex-1 text-base m-0 whitespace-nowrap">
            {{ property.value }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="options.properties" class="flex-1">
      <div class="flex flex-col">
        <div class="flex-1" *ngFor="let key of objectKeys(options.properties)">
          <div class="flex flex-row">
            <div class="flex-1 caption text-secondary m-0">{{ key }}</div>
            <div class="shrink-0 caption m-0 pl-1 truncate">{{ options.properties[key] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
