import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription, tap } from 'rxjs';
import { Remotee } from 'src/app/shared/models/session.model';

import { AgentService } from './agent.service';
import { ApiService, ServiceState } from './api.service';
import { SelectedAgentState, StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class RemoterPollerService extends ApiService {
  private _state = new BehaviorSubject<ServiceState>(ServiceState.Stopped);
  readonly state$ = this._state.asObservable();
  get state(): ServiceState {
    return this._state.value;
  }
  set state(value: ServiceState) {
    if (this._state.value === value) {
      return;
    }
    this._state.next(value);
  }

  private _outgoingSessions = new BehaviorSubject<Array<Remotee>>(undefined);
  readonly outgoingSessions$ = this._outgoingSessions.asObservable();

  newerThan: Date;

  private selectedAgentSubscription: Subscription;
  private remoterSubscription: Subscription;

  constructor(private stateSvc: StateService, private agentSvc: AgentService) {
    super();

    combineLatest([this.stateSvc.selectedAgent$, this.stateSvc.tenant$])
      .pipe(
        tap(([selectedAgent, tenant]) => {
          if (selectedAgent.state !== SelectedAgentState.Found || !tenant) {
            this.stop();
          }
        })
      )
      .subscribe();

    combineLatest([this.stateSvc.liveMode$, this.state$])
      .pipe(
        tap(([liveMode, state]) => {
          if (liveMode && state == ServiceState.Started) {
            this.pollRemoter();
          } else if (this.remoterSubscription && !this.remoterSubscription.closed) {
            this.remoterSubscription.unsubscribe();
          }
        })
      )
      .subscribe();
  }

  public start(): void {
    if (this.state !== ServiceState.Stopped) {
      return;
    }

    this.state = ServiceState.Starting;

    if (this.selectedAgentSubscription && !this.selectedAgentSubscription.closed) {
      this.selectedAgentSubscription.unsubscribe();
    }
    if (this.remoterSubscription && !this.remoterSubscription.closed) {
      this.remoterSubscription.unsubscribe();
    }

    this.selectedAgentSubscription = this.stateSvc.selectedAgent$.subscribe({
      next: () => {
        this._outgoingSessions.next(undefined);
        this.newerThan = new Date(0);
      }
    });

    this.newerThan = new Date(0);

    this.state = ServiceState.Started;
  }

  public stop(): void {
    if (this.state === ServiceState.Stopped || this.state === ServiceState.Stopping) {
      return;
    }

    this.state = ServiceState.Stopping;

    if (this.selectedAgentSubscription) {
      this.selectedAgentSubscription.unsubscribe();
    }

    if (this.remoterSubscription) {
      this.remoterSubscription.unsubscribe();
    }

    this._outgoingSessions.next(undefined);
    this.newerThan = new Date(0);

    this.state = ServiceState.Stopped;
  }

  private pollRemoter(): void {
    const agentID = this.stateSvc.selectedAgent.agentID;

    this.remoterSubscription = this.agentSvc
      .getOutgoingRemotees(agentID, this.newerThan)
      .subscribe({
        next: (remoteeCollection) => {
          this.stateSvc.setServerDisconnected(false);
          if (remoteeCollection) {
            const newerThan = remoteeCollection.timestamp;
            // rounding up to the next millisecond because golang precision is microseconds
            newerThan.setMilliseconds(newerThan.getMilliseconds() + 1);

            if (newerThan.getTime() > this.newerThan.getTime()) {
              const filtered = remoteeCollection.remotees.filter(
                (s) => s.remoters && s.remoters.has(agentID)
              );
              this._outgoingSessions.next(filtered.length > 0 ? filtered : undefined);

              this.newerThan = newerThan;
            }
          }
        },
        error: (err) => {
          if (err.status !== 404) {
            this.stateSvc.setServerDisconnected(true);
          }
        },
        complete: () => {
          if (!this.remoterSubscription.closed) {
            this.remoterSubscription.unsubscribe();
          }
          this.pollRemoter();
        }
      });
  }

  public pause(): void {
    if (this.state === ServiceState.Started) {
      this.state = ServiceState.Paused;
    }
  }

  public unpause(): void {
    if (this.state === ServiceState.Paused) {
      this.state = ServiceState.Started;
    }
  }
}
