import { QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import {
  getDisplayProtocolDisplayName,
  Session
} from 'src/app/shared/models/session.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { friendlyDataSizeInBits } from 'src/app/utils/data-size';

import { FocusProperty } from '../../shared/interfaces/graph-options.interface';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';

const _dpGraphProps = new BehaviorSubject<string[]>([]);
export const dpGraphProps$ = _dpGraphProps.asObservable();
const count = 20;

export function updateDisplayProtocols(
  dpCharts: QueryList<LiveGraphComponent>,
  dpGraphOps: GraphOptions[],
  sessions: Session[],
  triggers: Array<Trigger> = [],
  color = 'deep-purple'
): void {
  const now = new Date();
  sessions = sessions || [];

  sessions
    .filter((s) => s.tags.has('displayProtocol')) // only work with sessions that have a displayProtocol
    .forEach((s) => {
      const dp = s.tags.get('displayProtocol');
      const connectedFrom = s.tags.get('connectedFrom') as string;
      const send = parseFocusProperty(dp, 'send');
      const receive = parseFocusProperty(dp, 'receive');
      const dpName = getDisplayProtocolDisplayName(dp['Protocol']);

      let value = [0, 0];
      if (send >= 0 && receive >= 0) {
        value = [Math.round((send * 8) / 1000), Math.round((receive * 8) / 1000)];
      }

      let ops: GraphOptions = dpGraphOps.find((ops) => ops.id === s.id);

      if (!ops) {
        if (send >= 0 && receive >= 0) {
          ops = {
            id: s.id,
            title: 'Display Protocol',
            label: connectedFrom
              ? `${dpName} ➜ ${connectedFrom}`
              : `${s.clientname ? `${s.clientname} ➜ ${dpName}` : `${dpName}`}`,
            icon: 'circle-nodes',
            iconColor: color,
            seriesColors: [[color, color]],
            seriesLineStyle: [['dashed', 'solid']],
            maxY: [undefined],
            initialSeries: [
              [
                {
                  name: 'Send',
                  data: new Array(count).fill(0)
                },
                {
                  name: 'Receive',
                  data: new Array(count).fill(0)
                }
              ]
            ],
            focusProperties: [],
            properties: {}
          };
        } else {
          ops = {
            id: s.id,
            title: 'Display Protocol',
            label: connectedFrom
              ? `${dpName} ➜ ${connectedFrom}`
              : `${s.clientname ? `${s.clientname} ➜ ${dpName}` : `${dpName}`}`,
            icon: 'circle-nodes',
            iconColor: color,
            seriesColors: [[color]],
            seriesLineStyle: [['dashed']],
            maxY: [undefined],
            initialSeries: [],
            focusProperties: [],
            properties: {}
          };
        }
        dpGraphOps.push(ops);
      }

      ops.timestamp = now;

      const btnName = (connectedFrom || s.clientname).toLocaleUpperCase();
      if (!ops.buttons && btnName) {
        ops.buttons = [
          {
            tooltip: `Go To ${btnName}`,
            onClick: () =>
              window.open(
                `/home/dashboard?machineID=${btnName.toLocaleLowerCase()}`,
                '_blank'
              )
          }
        ];
      }

      let fp: FocusProperty[] = [];
      if (send >= 0 && receive >= 0) {
        fp = [
          {
            label: 'Send',
            value: `${friendlyDataSizeInBits(send, 0)}ps`,
            graphColor: color,
            graphLineStyle: 'dashed'
          },
          {
            label: 'Receive',
            value: `${friendlyDataSizeInBits(receive, 0)}ps`,
            graphColor: color,
            graphLineStyle: 'solid'
          }
        ];
      }

      fp = fp.concat(parseFocusProperties(dp, triggers));

      Object.assign(ops.focusProperties, fp);

      Object.assign(
        ops.properties,
        s.clientIP ? { 'Client IP': s.clientIP } : {},
        {
          User: s.username
        },
        parseProperties(dp)
      );

      if (_dpGraphProps.value.length < 1) {
        _dpGraphProps.next(
          [...Object.keys(ops.properties || [])].concat(
            ops.focusProperties.map((p) => p.label)
          )
        );
      }

      dpCharts &&
        dpCharts
          .filter((c) => c.id === s.id)
          .forEach((c) => {
            c.appendData(value); // always send as Kpbs
          });
    });

  for (let i = dpGraphOps.length - 1; i >= 0; i--) {
    if (dpGraphOps[i].timestamp !== now) {
      dpGraphOps.splice(i, 1);
    }
  }
}

function parseFocusProperty(dp: unknown, property: string): number {
  const protocol = dp['Protocol'] as string;

  switch (protocol) {
    case 'rdp-sxs':
    case 'rdp-tcp':
      switch (property) {
        case 'latency':
          return +dp['BaseTCPRtt'];
        case 'receive':
          return +dp['TCPReceivedRate'];
        case 'send':
          return +dp['TCPSentRate'];
      }
      break;
    case 'rdp-udp':
      switch (property) {
        case 'latency':
          return +dp['BaseUDPRtt'];
        case 'receive':
          return +dp['UDPReceivedRate'];
        case 'send':
          return +dp['UDPSentRate'];
      }
      break;
    case 'ica-tcp':
    case 'ica-cgp':
      switch (property) {
        case 'latency':
          return +dp['Latency'];
        case 'receive': // provided in kb/s
          return +dp['InputSessionBandwidth'] / 1000;
        case 'send': // provided in kb/s
          return +dp['OutputSessionBandwidth'] / 1000;
      }
      break;
    case 'frame':
      switch (property) {
        case 'latency':
          return +dp['Latency'];
      }
      break;
    case 'pcoip':
      switch (property) {
        case 'latency':
          return +dp['RoundTripLatencyMS'];
        case 'receive': // provided in kb/s
          return +dp['RXBWKbitPerSec'];
        case 'send': // provided in kb/s
          return +dp['TXBWKbitPerSec'];
      }
      break;
    case 'blast':
      switch (property) {
        case 'latency':
          return +dp['Rtt'];
        case 'receive': // provided in kb/s
          const rx =
            +dp['InstantaneousReceivedBytesOverTCP'] * 8 +
            +dp['InstantaneousReceivedBytesOverUDP'] * 8;
          return rx;
        case 'send': // provided in kb/s
          const tx =
            +dp['InstantaneousTransmittedBytesOverTCP'] * 8 +
            +dp['InstantaneousTransmittedBytesOverUDP'] * 8;
          return tx;
      }
      break;
  }
  return undefined;
}

function parseFocusProperties(dp: unknown, triggers: Array<Trigger>): FocusProperty[] {
  const properties: FocusProperty[] = [];

  // RDP TCP

  if (dp['BaseTCPRtt'] !== undefined) {
    properties.push({
      label: 'Latency',
      value: `${dp['BaseTCPRtt']} ms`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.BaseTCPRtt' &&
              c.isMet(dp['BaseTCPRtt'])
          )
      )
    });
  }

  // RDP UDP

  if (dp['BaseUDPRtt'] !== undefined) {
    properties.push({
      label: 'Latency',
      value: `${dp['BaseUDPRtt']} ms`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.BaseUDPRtt' &&
              c.isMet(dp['BaseUDPRtt'])
          )
      )
    });
  }

  // RDP COMMON

  if (dp['FrameQuality'] !== undefined) {
    properties.push({
      label: 'Frame Quality',
      value: `${+dp['FrameQuality']} %`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.FrameQuality' &&
              c.isMet(dp['FrameQuality'])
          )
      )
    });
  }

  // if (dp['LossRate']) {
  //   properties.push({
  //     label: 'Loss Rate',
  //     value: `${+dp['LossRate']} %`,
  //   });
  // }

  // HDX

  if (dp['Latency'] !== undefined) {
    properties.push({
      label: 'Latency',
      value: `${dp['Latency']} ms`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.Latency' &&
              c.isMet(dp['Latency'])
          )
      )
    });
  }

  // PCoIP

  if (dp['RoundTripLatencyMS'] !== undefined) {
    properties.push({
      label: 'Latency',
      value: `${dp['RoundTripLatencyMS']} ms`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.RoundTripLatencyMS' &&
              c.isMet(dp['RoundTripLatencyMS'])
          )
      )
    });
  }

  if (dp['ImagingEncodedFramesPerSec'] !== undefined) {
    properties.push({
      label: 'Frame/Sec',
      value: `${+dp['ImagingEncodedFramesPerSec']}`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.ImagingEncodedFramesPerSec' &&
              c.isMet(dp['ImagingEncodedFramesPerSec'])
          )
      )
    });
  }

  if (dp['ImagingActiveMinQuality'] !== undefined) {
    properties.push({
      label: 'Image Quality',
      value: `${+dp['ImagingActiveMinQuality']} %`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.ImagingActiveMinQuality' &&
              c.isMet(dp['ImagingActiveMinQuality'])
          )
      )
    });
  }

  // Blast

  if (dp['Rtt'] !== undefined) {
    properties.push({
      label: 'Latency',
      value: `${dp['Rtt']} ms`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) => c.field === 'Sessions.Tags.displayProtocol.Rtt' && c.isMet(dp['Rtt'])
          )
      )
    });
  }

  if (dp['FramesPerSec'] !== undefined) {
    properties.push({
      label: 'Frame/Sec',
      value: `${+dp['FramesPerSec']}`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.FramesPerSec' &&
              c.isMet(dp['FramesPerSec'])
          )
      )
    });
  }

  if (dp['PacketLossUplink'] !== undefined) {
    properties.push({
      label: 'Packet Loss',
      value: `${+dp['PacketLossUplink']} %`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.PacketLossUplink' &&
              c.isMet(dp['PacketLossUplink'])
          )
      )
    });
  }

  // nutanix (frame)
  if (dp['FrameRateDisplay-1'] !== undefined) {
    properties.push({
      label: 'Frame/Sec',
      value: `${+dp['FrameRateDisplay-1']}`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.FrameRateDisplay-1' &&
              c.isMet(dp['FrameRateDisplay-1'])
          )
      )
    });
  }
  if (dp['BandwidthDisplay-1'] !== undefined) {
    properties.push({
      label: 'Bandwidth',
      value: `${dp['BandwidthDisplay-1']} kbps`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.BandwidthDisplay-1' &&
              c.isMet(dp['BandwidthDisplay-1'])
          )
      )
    });
  }
  if (dp['EstimatedBandwidth-1'] !== undefined) {
    properties.push({
      label: 'Estimated Bandwidth',
      value: `${dp['EstimatedBandwidth-1']} kbps`,
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Sessions.Tags.displayProtocol.EstimatedBandwidth-1' &&
              c.isMet(dp['EstimatedBandwidth-1'])
          )
      )
    });
  }
  // if (dp['NumberOfActiveDisplays'] !== undefined) {
  //   properties.push({
  //     label: '# of active displays',
  //     value: `${dp['NumberOfActiveDisplays']}`,
  //     highlight: !!triggers.find(
  //       (t) =>
  //         !!t.conditions.find(
  //           (c) =>
  //             c.field === 'Sessions.Tags.displayProtocol.NumberOfActiveDisplays' &&
  //             c.isMet(dp['NumberOfActiveDisplays'])
  //         )
  //     )
  //   });
  // }

  return properties;
}

function parseProperties(dp: unknown): unknown {
  const protocol = dp['Protocol'] as string;
  const properties = {};

  switch (protocol) {
    case 'rdp-sxs':
    case 'rdp-tcp':
    case 'rdp-udp':
      // if (dp['FrameQuality'] !== undefined) {
      //   properties['Frame Quality'] = `${+dp['FrameQuality']} %`;
      // }
      if (dp['LossRate'] !== undefined) {
        properties['Loss Rate'] = `${+dp['LossRate']} %`;
      }
      break;
    case 'ica-tcp':
    case 'ica-cgp':
      break;
    case 'pcoip':
      // if (dp['ImagingEncodedFramesPerSec'] !== undefined) {
      //   properties['Frame/Sec'] = `${+dp['ImagingEncodedFramesPerSec']}`;
      // }
      // if (dp['ImagingActiveMinQuality'] !== undefined) {
      //   properties['Image Quality'] = `${+dp['ImagingActiveMinQuality']} %`;
      // }
      break;
    case 'blast':
      // if (dp['FramesPerSec'] !== undefined) {
      //   properties['Frame/Sec'] = `${+dp['FramesPerSec']}`;
      // }
      // if (dp['PacketLossUplink'] !== undefined) {
      //   properties['Packet Loss'] = `${+dp['PacketLossUplink']} %`;
      // }
      break;
  }

  return properties;
}
