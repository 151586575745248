import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HotkeysDirective, HotkeysService } from '@ngneat/hotkeys';

@Component({
  standalone: true,
  imports: [CommonModule, HotkeysDirective, FontAwesomeModule],
  selector: 'mon-hotkey-help',
  templateUrl: './hotkey-help.component.html',
  styleUrls: ['./hotkey-help.component.scss']
})
export class HotkeyHelpComponent {
  @Input() title = 'Available Shortcuts';
  @Output() dimiss = new EventEmitter();
  hotkeys = this.hotkeysService.getShortcuts();

  constructor(private hotkeysService: HotkeysService) {}

  handleDismiss() {
    this.dimiss.next(undefined);
  }
}
