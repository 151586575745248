import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared/shared.module';

import { SidenavItemComponent } from './sidenav-item.component';

@NgModule({
  declarations: [SidenavItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatRippleModule,
    MatTooltipModule,
    FontAwesomeModule,
    SharedModule
  ],
  exports: [SidenavItemComponent]
})
export class SidenavItemModule {}
