import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MegaMenuComponent } from './mega-menu.component';

@NgModule({
  declarations: [MegaMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatRippleModule,
    FontAwesomeModule,
    RouterModule
  ],
  exports: [MegaMenuComponent]
})
export class MegaMenuModule {}
