import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TenantInvite } from 'src/app/view/page/login/login.component';

import { ApiService, NoContent, Response } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  registerTenant(email: string, resend = false): Observable<Response> {
    const url = `${this.cfUrl}/register-tenant`;
    const params = {
      email,
      resend
    };
    const start = performance.now();
    return this.http.post<Response>(url, params).pipe(
      tap(() => {
        this.log(
          `fetched RegistrationService.registerTenant response in ${
            performance.now() - start
          }ms`
        );
      }),
      catchError((err) => {
        this.handleError('RegistrationService.registerTenant');
        return throwError(() => err);
      })
    );
  }

  getTenantRegistration(inviteKey: string): Observable<TenantInvite> {
    const url = `${this.cfUrl}/register-tenant-confirm`;
    const params = {
      inviteKey
    };
    return this.http.get<TenantInvite>(url, { params }).pipe(
      map((ti) => (!!ti ? new TenantInvite(ti) : ti)),
      catchError((err) => {
        this.handleError<TenantInvite>('RegistrationService.getTenantRegistration');
        return throwError(() => err);
      })
    );
  }

  confirmTenant(
    inviteKey: string,
    email: string,
    password: string,
    tenantName: string,
    firstName: string,
    lastName: string,
    company: string,
    companySize: string,
    countryCode: string,
    region: string,
    regionCode: string,
    postcode: string,
    itemPriceID: string,
    couponCode: string
  ): Observable<NoContent> {
    const url = `${this.cfUrl}/register-tenant-confirm`;
    const params = {
      inviteKey,
      email,
      password,
      tenantName,
      firstName,
      lastName,
      company,
      companySize,
      countryCode,
      region,
      regionCode,
      postcode,
      itemPriceID,
      couponCode
    };
    return this.http.post<NoContent>(url, params).pipe(
      catchError((err) => {
        this.handleError<NoContent>('RegistrationService.confirmTenant');
        return throwError(() => err);
      })
    );
  }
}
