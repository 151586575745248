<div
  class="flex px-2 rounded-md border-transparent h-full m-1 py-2"
  [ngClass]="selected ? 'pl-0' : 'hover:bg-primary-light cursor-pointer'"
  #item
>
  <div
    *ngIf="selected"
    class="flex items-center rounded text-2xl px-2 mr-1 text-secondary hover:bg-blue cursor-pointer hover:bg-opacity-10"
    matTooltip="Go Back To Results"
    (click)="handleDismiss($event)"
  >
    <fa-icon [icon]="['fad', 'chevron-double-left']"></fa-icon>
  </div>
  <div class="flex-1 flex flex-col truncate" [ngClass]="selected ? 'pl-2 border-l-4 cursor-default' : 'cursor-pointer'">
    <div class="flex items-center">
      <div class="flex-1 text-sm w-full">
        <div class="truncate" [matTooltip]="'Version v' + result.scriptVersion">
          {{ result.scriptName }}
          <span class="text-2xs text-secondary">{{ result.scriptID }}</span>
        </div>
      </div>
    </div>
    <div class="text-xs">
      <span class="italic text-secondary" matTooltip="Created">{{ result.created | date: 'medium' }}</span>
      <ng-container *ngIf="result.isDone">
        <span class="text-primary font-bold pl-4" matTooltip="Duration">
          {{ result.lastUpdate.getTime() - result.created.getTime() | timeSpan: 'short' }}
        </span>
      </ng-container>
    </div>
    <div class="flex justify-between">
      <div class="flex-1 text-secondary text-xs">
        <span matTooltip="Ran By">{{ result.invokedBy }}</span>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-end self-stretch justify-between">
    <div class="text-secondary italic" [style.font-variant]="'small-caps'">
      <fa-icon
        [icon]="['fad', result.state < 2 ? 'loader' : 'flag-checkered']"
        [animation]="result.state < 2 ? 'spin-pulse' : null"
        [matTooltip]="result.state < 2 ? 'Running' : 'Completed'"
      ></fa-icon>
    </div>
    <div
      class="flex text-secondary text-xs italic"
      [ngClass]="selected ? 'cursor-default' : 'cursor-pointer'"
      [style.font-variant]="'small-caps'"
    >
      <div *ngIf="result.targetSuccess" class="flex" [matTooltip]="'Success'">
        <span class="text-green pr-1"><fa-icon [icon]="['fad', 'check']"></fa-icon></span>
        <span>{{ result.targetSuccess }}</span>
      </div>
      <div *ngIf="result.targetError" class="flex" [matTooltip]="'Failed'">
        <span class="ml-2 text-red pr-1"><fa-icon [icon]="['fad', 'times']"></fa-icon></span>
        <span>{{ result.targetError }}</span>
      </div>
      <div *ngIf="result.targetAborted" class="flex" [matTooltip]="'Aborted'">
        <span class="ml-2 pr-1"><fa-icon [icon]="['fad', 'ban']"></fa-icon></span>
        <span>{{ result.targetAborted }}</span>
      </div>
      <div *ngIf="result.targetTimeout" class="flex" [matTooltip]="'Timed out'">
        <span class="ml-2 text-orange pr-1"><fa-icon [icon]="['fad', 'hourglass-end']"></fa-icon></span>
        <span>{{ result.targetTimeout }}</span>
      </div>
    </div>
  </div>
</div>
