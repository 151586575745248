<ng-container *ngIf="paymentSource && paymentSource.card as card">
  <div class="flex flex-col gap-2">
    <div class="text-secondary" [style.font-variant]="'small-caps'">Card</div>
    <div class="flex items-center justify-center gap-2">
      <div class="flex flex-col gap-2 card p-2 bg-blue-light border border-blue border-opacity-50 text-secondary">
        <div class="h-0"></div>
        <div class="flex text-xxxs">XXXX XXXX XXXX {{ card.last4 }}</div>
        <div class="flex items-center justify-end text-xxxs">{{ card.expiryMonth | number: '2.0' }}/{{ card.expiryYear }}</div>
        <div class="flex items-center justify-between">
          <div class="text-xxxs">{{ card.firstName }} {{ card.lastName }}</div>
          <fa-icon [icon]="faPaymentSourceIcon" size="lg" [fixedWidth]="true" class="flex items-center justify-center"></fa-icon>
        </div>
      </div>
      <div class="flex items-center justify-center text-sm whitespace-pre"
        >{{
          formatAddress
            | callOnce
              : card.firstName
              : card.lastName
              : card.company
              : [card.billingAddr1, card.billingAddr2]
              : card.billingCity
              : card.billingState
              : card.billingZip
              : card.billingCountry
        }}
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="paymentSource && paymentSource.bankAccount as bankAccount">
  <div class="flex flex-col gap-2">
    <div class="text-secondary" [style.font-variant]="'small-caps'">Bank Account</div>
    <div class="flex flex-col gap-2 card p-2 pt-0 bg-yellow-light border border-yellow border-opacity-50 text-secondary min-w-15">
      <div class="h-0"></div>
      <div class="w-full flex items-center justify-between">
        <div class="w-full flex items-center justify-start gap-2">
          <fa-icon [icon]="faPaymentSourceIcon" size="lg" [fixedWidth]="true" class="flex items-center justify-center"></fa-icon>
          <div class="text-xxxs">{{ bankAccount.firstName }} {{ bankAccount.lastName }}</div>
        </div>
        <div class="text-xxs">1234</div>
      </div>
      <div class="h-2"></div>
      <div class="flex items-center justify-start text-xxxs">{{ bankAccount.bankName }}</div>
      <div class="flex items-center justify-start gap-4">
        <div class="flex text-xxxs">XXXXXXXXX</div>
        <div class="flex text-xxxs">XXXXXXXXX{{ bankAccount.last4 }}</div>
      </div>
    </div>
  </div>
</ng-container>
