import { Updatable } from '../interfaces/updatable.interface';

export class GatewayPing implements Updatable<GatewayPing> {
  public packetsRecv: number;
  public packetsSent: number;
  public packetsRecvDuplicates: number;
  public packetLoss: number;
  public addr: string;
  public minRtt: number;
  public maxRtt: number;
  public avgRtt: number;
  public stdDevRtt: number;
  public enabled: boolean;

  get pingD(): string {
    if (this.packetLoss !== 0) {
      return 'Packet Loss';
    }

    if (this.avgRtt > 1000 * 1000 * 1000) {
      return `${Math.round(this.avgRtt / 1000 / 1000 / 1000)}s`;
    }

    if (this.avgRtt > 1000 * 1000) {
      return `${Math.round(this.avgRtt / 1000 / 1000)}ms`;
    }

    return '<1ms';

    // if (ping > 1000) {
    //   return `${Math.round(ping / 1000)}μs`;
    // }

    // return `${Math.round(ping)}ns`;
  }

  constructor(init?: Partial<GatewayPing>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<GatewayPing>): this {
    return Object.assign(this, input);
  }
}
