<div class="flex flex-col items-center md:justify-center h-full w-full">
  <div
    (keyup.escape)="_search.next(false)"
    *ngIf="search$ | async"
    class="absolute z-50 top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center w-full p-4"
  >
    <div @fadeInUp class="card w-full md:w-96 flex flex-col">
      <div class="flex justify-end">
        <button mat-icon-button type="button" (click)="_search.next(false)" class="text-xl">
          <fa-icon [icon]="['fad', 'times']"></fa-icon>
        </button>
      </div>
      <div class="flex px-4 gap-4 items-start justify-center w-full">
        <div>
          <mat-form-field>
            <input
              matInput
              #searchInput
              placeholder="Search For Machine"
              cdkFocusInitial
              (keyup.enter)="manualSelect(searchInput.value)"
              (keyup)="_searchTerm.next($event)"
              [matAutocomplete]="auto"
              data-intro-key="shell-connect-input"
            />
            <mat-autocomplete
              #auto
              (optionSelected)="dropdownSelect($event)"
              [autoActiveFirstOption]="true"
              [displayWith]="displayNameOfAgent"
              matAutocompleteTrigger
            >
              <mat-option *ngFor="let searchResult of searchResults$ | async" [value]="searchResult" [disabled]="spin">
                {{ searchResult.id }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="relative w-full">
            <mat-progress-bar *ngIf="spin" mode="query" color="primary" class="absolute" [style.top]="'-25px'"></mat-progress-bar>
          </div>
        </div>
        <div>
          <button mat-raised-button (click)="manualSelect(searchInput.value)">CONNECT</button>
        </div>
      </div>
      <div class="flex px-4 pb-4 gap-4 items-center justify-start">
        <div [ngClass]="{ invisible: !manualErr }" class="text-red text-sm min-h-5">{{ manualErr }}</div></div
      >
    </div>
  </div>

  <div class="flex flex-col flex-1 w-full h-full">
    <div class="flex items-center justify-center gap-2 p-2">
      <div class="card p-2 flex items-center justify-center">
        <div><img class="animate-blur-in max-w-none w-[28px]" src="assets/img/demo/logo.svg" /></div>
        <div class="hidden md:flex items-center justify-center ml-2 pl-2 border-l-2">
          <fa-stack class="text-primary">
            <fa-icon [icon]="['fas', 'square']" stackItemSize="2x" class="item-icon item-color"></fa-icon>
            <fa-icon [icon]="['fad', 'terminal']" [inverse]="true" stackItemSize="1x"></fa-icon>
          </fa-stack>
          <span class="hidden md:block text-lg pl-2">Shell</span>
        </div>
      </div>
      <div
        *ngIf="agent$ | async as agent; else noAgent"
        data-intro-key="shell-toolbar"
        class="flex-1 card h-full w-full flex items-center justify-center p-2 text-sm md:text-lg whitespace-nowrap"
      >
        <div>
          <span class="">{{ agent.id }}</span>
          <span class="text-secondary ml-2 px-2 border-x-2" [style.font-variant]="'small-caps'">{{ shell.shellType }}</span>
          <span class="text-secondary text-xs italic ml-2" [style.font-variant]="'small-caps'">{{
            shell.sessionTime | date: 'H:mm:ss' : 'UTC'
          }}</span>
        </div>
        <div class="flex justify-end flex-1">
          <div class="text-secondary" [style.font-variant]="'small-caps'">{{ shellState$ | async }}</div>
        </div>
      </div>
      <ng-template #noAgent>
        <div class="card h-full w-full items-center justify-center flex p-2 md:text-lg whitespace-nowrap">
          <div>
            <span class="hidden md:block text-lg">Click 'Search For Machine' Below</span>
          </div>
          <div class="w-full flex items-center justify-end flex-1 h-full">
            <div class="text-secondary" [style.font-variant]="'small-caps'">Not Connected</div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="flex-1 p-2 pt-0 h-full">
      <div
        @fadeInUp
        *ngIf="shellState$ | async as state"
        class="bg-contrast-black h-full border-gray-500 border-4 p-2 pr-0 rounded-sm shadow-2 relative"
      >
        <div
          class="absolute top-0 right-0 flex items-center justify-center z-40"
          [ngClass]="{
            'left-0 bottom-0 bg-gray-500 bg-opacity-90': state !== 'connected',
            'top-1 right-1': state === 'connected'
          }"
        >
          <div @fadeInUp *ngIf="!(search$ | async)" class="flex flex-col items-center justify-center">
            <div
              *ngIf="agent$ | async"
              class="opacity-75 transition-opacity flex flex-col items-center justify-center"
              [matTooltip]="state === 'connected' ? state : null"
              [ngClass]="{
                hidden: state === 'connected',
                'opacity-75': state === 'connecting',
                'text-red-400': state === 'disconnected' || state === 'error'
              }"
              (click)="state === 'disconnected' ? _search.next(true) : null"
            >
              <div
                class="flex items-center justify-center"
                [ngClass]="{
                  'text-green p-8': state === 'connecting',
                  'p-0': state === 'connected',
                  'px-8': state !== 'connected'
                }"
              >
                <fa-icon
                  [hidden]="state !== 'connecting'"
                  [icon]="['fad', 'plug']"
                  size="6x"
                  class="inline-flex animate-ping absolute"
                ></fa-icon>
                <fa-stack *ngIf="state !== 'connecting'; else connecting" [size]="'6x'">
                  <fa-icon
                    [ngClass]="{ 'p-0 opacity-60': true }"
                    [icon]="['fas', 'slash']"
                    [mask]="['fad', 'plug']"
                    stackItemSize="1x"
                    transform="down-2.25"
                  ></fa-icon>
                  <fa-icon [icon]="['fas', 'slash']" stackItemSize="1x"></fa-icon>
                </fa-stack>
                <ng-template #connecting>
                  <fa-icon [ngClass]="{ 'p-0 opacity-60': true }" [icon]="['fad', 'plug']" [size]="'6x'"></fa-icon>
                </ng-template>
              </div>
              <div class="pb-6" *ngIf="state !== 'connected'">
                <div class="text-lg text-center text-white" [style.font-variant]="'small-caps'">
                  {{ state }}
                </div>
                <div *ngIf="state === 'error'">{{ shell.lastShellError }}</div>
              </div>
            </div>
            <ng-container *ngIf="state === 'disconnected' || state === 'error'">
              <button mat-raised-button color="primary" (click)="_search.next(true)" data-intro-key="shell-connect-button" #connectBtn
                >Search For Machine</button
              >
              <span class="text-xs text-white opacity-75 italic py-2 text-center w-full hidden md:block" cdkFocusInitial
                >press '/' to search and connect</span
              >
            </ng-container>
          </div>
        </div>
        <div class="bg-black cursor-text absolute top-0 left-0 bottom-0 right-0 p-2 pr-1 overflow-hidden">
          <div (contextmenu)="rightClickPaste($event)" class="w-full h-full" data-intro-key="shell-view" #term></div>
        </div>
      </div>
    </div>
  </div>
</div>
