<div
  *ngIf="data$ | async as data"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="sidenav-toolbar pl-3 flex-none flex items-center">
    <img class="animate-blur-in w-[48px] min-w-[48px] select-none" src="assets/img/demo/logo.png" />
    <div class="sidenav-header flex-1 transition-opacity duration-200 ease-in-out select-none h-full pt-2">
      <button
        data-intro-key="tenant-name-logo"
        class="text-center w-full"
        (click)="openTenantSwitch()"
        [class.cursor-default]="!data.user.canSwitchTenant"
      >
        <h4 class="animate-tracking-in-expand select-none flex-auto leading-6 subheading-2">CommandCTRL</h4>
        <h6 *ngIf="!tenantReload && data.tenant.companyName" class="animate-tracking-in-expand leading-none">{{
          data.tenant.companyName
        }}</h6>
        <h6 *ngIf="!tenantReload" class="animate-tracking-in-expand italic" [ngClass]="{ 'text-xs text-gray': !!data.tenant.companyName }"
          >{{ data.tenant.name || data.tenant.domain }}
          <span *ngIf="sameTenantNames$ | async as sameTenantNames" matTooltip="Last 4 digits of the tenant ID" class="text-gray">
            {{ sameTenantNames.get(data.tenant.name) ? ' - ' + data.tenant.id.substring(0, 4) : '' }}</span
          >
        </h6>
      </button>
    </div>
    <button
      (click)="toggleCollapse()"
      class="sm-icon-button sidenav-pin mr-2 leading-none hover:bg-gray-100 hover:bg-opacity-20 transition duration-200 ease-in-out"
      mat-icon-button
      type="button"
    >
      <fa-icon [icon]="['fad', 'thumbtack']" [class.text-gray]="collapsed"> </fa-icon>
    </button>
  </div>

  <div class="nav-item-wrapper h-full overflow-auto">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="!item.hidden">
        <vex-sidenav-item @fadeInRight *hasPerm="item.requiredPerms" [item]="item" [level]="0"></vex-sidenav-item>
      </ng-container>
    </ng-container>
  </div>
</div>
