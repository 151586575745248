<ng-container *ngIf="fieldValue$ | async as fieldValue">
  <div class="relative flex items-center justify-between gap-2 text-2xs md:text-sm px-2">
    <div
      *ngIf="lock$ | async"
      class="z-20 absolute top-0 left-0 rounded w-full h-full bg-primary-light transition ease-in-out duration-200"
    >
      <div class="py-4 flex h-full items-center justify-center space-x-2">
        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
      </div>
    </div>
    <div class="flex-1 m-0 flex gap-2">
      <mat-form-field *ngIf="readonly; else edit" class="w-full">
        <mat-label>{{ label }}</mat-label>
        <input type="text" matInput [value]="fieldValue.get(field) || defaultValue" [readonly]="readonly" [disabled]="readonly" />
      </mat-form-field>
      <ng-template #edit>
        <mat-form-field class="w-full">
          <mat-select (selectionChange)="onSelectionChange($event)" [value]="fieldValue.get(field) || defaultValue">
            <ng-container *ngFor="let v of options">
              <mat-option [value]="v.value">{{ v.viewValue }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </div>
  </div>
</ng-container>
