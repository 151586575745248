import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToDate'
})
export class NumberToDatePipe implements PipeTransform {
  transform(v: number | string, unit: 's' | 'ms' | 'µs' | 'ns' = 'ms'): Date {
    switch (unit) {
      case 's':
        return new Date(+v * 1_000);
      case 'ms':
        return new Date(+v);
      case 'µs':
        return new Date(+v / 1_000);
      case 'ns':
        return new Date(+v / 1_000_000);
    }

    return undefined;
  }
}
