<div class="flex flex-col h-full">
  <div *ngIf="searchResult$ | async as search" class="grid grid-flow-row grid-cols-12 gap-2 h-full w-full">
    <div [fillHeight]="!(isMobile$ | async)" class="flex flex-col col-span-12 md:col-span-4 md:h-full gap-2">
      <div @fadeInUp class="flex items-stretch justify-stretch gap-2">
        <div *hasPerm="[permission.Search_Invoke]" data-intro-key="script-search-scripts" class="card flex-1">
          <div class="flex flex-col items-center justify-center px-4 h-full">
            <mat-form-field class="w-full">
              <mat-label>Search</mat-label>
              <input matInput autocomplete="off" (keyup)="onFilter(filter.value)" class="" #filter />
              <button
                matRipple
                *ngIf="filter.value"
                (click)="filter.value = ''; onFilter(filter.value)"
                class="rounded-full w-6 h-6"
                matSuffix
              >
                <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
              </button>
            </mat-form-field>
            <div class="relative w-full">
              <mat-progress-bar
                *ngIf="searching$ | async"
                mode="indeterminate"
                color="primary"
                class="absolute"
                [style.height]="'2px'"
                [style.top]="'-25px'"
              ></mat-progress-bar>
            </div>
          </div>
        </div>
        <div *hasPerm="[permission.Script_Create]" data-intro-key="script-upload-script" class="card flex items-center p-2">
          <button
            (click)="editScript()"
            type="button"
            mat-mini-fab
            color="primary"
            class="flex items-center justify-center"
            matTooltip="Upload Script"
          >
            <fa-icon [fixedWidth]="true" [icon]="['fad', 'cloud-upload']" size="lg"></fa-icon>
          </button>
        </div>
      </div>

      <div [fillHeight]="!(isMobile$ | async)" class="flex-1 flex flex-col gap-2 pb-2 md:h-full md:pb-0">
        <ng-container *ngIf="(selected$ | async) || { id: '', location: '' } as selected">
          <ng-container *ngIf="search.searching || search.results; else installed">
            <div class="flex flex-col md:h-full">
              <div @fadeInUp [overflowClasses]="{ 'md:pr-1': true }" class="card md:overflow-auto">
                <div class="flex items-center p-4">
                  <div class="flex-1">
                    <div class="flex">
                      <div class="flex-1 flex flex-col">
                        <div class="text-lg" [style.font-variant]="'small-caps'">Search Results</div>
                        <div class="text-secondary text-xs"></div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div class="items-center justify-end">{{ search.results?.length }}</div>
                </div>
                <div [@listStagger]="search.results?.length" class="flex flex-col">
                  <ng-container *ngIf="search.searching; else reportResults">
                    <div @fadeInRight class="card pb-4 text-secondary flex items-center justify-center">
                      <div class="py-4 flex items-center justify-center space-x-2">
                        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
                        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
                        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #reportResults>
                    <ng-container *ngIf="user$ | async as user">
                      <ng-container *ngIf="search.results.length > 0; else noResults">
                        <mon-script-list-item
                          @fadeInRight
                          *ngFor="let script of search.results; trackBy: trackScriptFn"
                          [script]="script"
                          [selected]="script.id === selected?.id && script.location === selected?.location"
                          [menu]="
                            user.hasPerms([permission.Script_Uninstall, permission.Script_Delete], 'any') ||
                            (user.hasPerms([permission.Script_Update]) && script.userPermission.write)
                              ? installedScriptMenu
                              : null
                          "
                          (click)="select(script)"
                        ></mon-script-list-item>
                      </ng-container>
                    </ng-container>
                    <ng-template #noResults>
                      <div @fadeInRight class="card p-4 text-secondary flex items-center justify-center">
                        <div>No Results :-(</div>
                      </div>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #installed>
            <ng-container *ngIf="categories">
              <div
                *ngFor="let category of categories; trackBy: trackCategoryFn"
                class="card md:overflow-auto"
                [ngClass]="category.expanded ? 'flex-1' : ''"
                infinite-scroll
                [attr.data-intro-key]="category.introKey"
                [scrollWindow]="false"
                [alwaysCallback]="true"
                (scrolled)="onScrollDown($event, category)"
              >
                <mat-accordion multi>
                  <mat-expansion-panel *ngIf="category.pullScripts$ | async; else loading" [(expanded)]="category.expanded">
                    <mat-expansion-panel-header>
                      <div class="flex w-full">
                        <mat-panel-title class="">
                          <div class="flex items-center justify-between w-full">
                            <div class="flex-1 flex flex-col">
                              <div class="text-lg" [style.font-variant]="'small-caps'">
                                {{ category.name }}
                              </div>
                              <div class="text-secondary text-xs">
                                {{ category.description }}
                              </div>
                            </div>
                            <div>{{ category.total }}</div>
                          </div>
                        </mat-panel-title>
                        <!-- <mat-panel-description class="flex items-center justify-end">{{ category.total }}</mat-panel-description> -->
                      </div>
                    </mat-expansion-panel-header>
                    <div [@listStagger]="category.scripts.length" class="flex flex-col">
                      <ng-container *ngIf="user$ | async as user">
                        <ng-container *ngIf="category.scripts.length > 0; else noCategoryResults">
                          <ng-container *ngFor="let s of category.scripts; trackBy: trackScriptFn">
                            <mon-script-list-item
                              @fadeInRight
                              [script]="s"
                              [selected]="s.id === selected.id && s.location === selected.location"
                              [menu]="
                                user.hasPerms([permission.Script_Uninstall, permission.Script_Delete], 'any') ||
                                (user.hasPerms([permission.Script_Update]) && s.userPermission.write)
                                  ? installedScriptMenu
                                  : null
                              "
                              (click)="select(s); scrollTo(target)"
                              #target
                            ></mon-script-list-item>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="category.pulling$ | async">
                          <div class="py-4 flex items-center justify-center space-x-2">
                            <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
                            <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
                            <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-template #noCategoryResults>
                        <div @fadeInRight class="card p-4 text-secondary flex items-center justify-center">
                          <div>Nothing here to see</div>
                        </div>
                      </ng-template>
                    </div>
                  </mat-expansion-panel>

                  <ng-template #loading>
                    <mat-expansion-panel expanded="true" hideToggle="true">
                      <mat-expansion-panel-header>
                        <div class="flex w-full">
                          <mat-panel-title class="">
                            <div class="flex items-center justify-between w-full">
                              <div class="flex-1 flex flex-col">
                                <div class="text-lg" [style.font-variant]="'small-caps'">
                                  {{ category.name }}
                                </div>
                                <div class="text-secondary text-xs">
                                  {{ category.description }}
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </mat-panel-title>
                          <!-- <mat-panel-description class="flex items-center justify-end">{{ category.total }}</mat-panel-description> -->
                        </div>
                      </mat-expansion-panel-header>
                      <div [@listStagger]="2" class="flex flex-col">
                        <div class="animate-pulse flex flex-col p-2">
                          <ng-container *ngFor="let i of [1, 2]">
                            <div @fadeInRight class="py-4">
                              <div class="grid space-y-2 grid-cols-12 gap-0">
                                <div class="h-4 bg-gray rounded col-span-8"></div>
                                <div class="h-2 bg-gray rounded col-span-10"></div>
                                <div class="h-2 bg-gray rounded col-span-5"></div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </ng-template>
                </mat-accordion>
              </div>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <div class="col-span-12 md:col-span-8" #detailPanel>
      <ng-container *ngIf="scriptDetail$ | async as script">
        <div @fadeInRight *ngIf="selectedLoaded$ | async" class="card w-full h-full">
          <mon-script-detail
            [script]="script"
            (run)="runScript($event)"
            (upgrade)="upgradeScript($event)"
            (disable)="disableScript($event)"
            (enable)="enableScript($event)"
            (delete)="deleteScript($event)"
            (uninstall)="uninstallScript($event)"
            (install)="installScript($event)"
            (modify)="editScript($event)"
            (archive)="archiveScript($event)"
            (unlike)="unlikeScript($event)"
            (like)="likeScript($event)"
            (feedback)="giveFeedback($event)"
            (tagSelect)="setFilter('tag:' + $event); onFilter('tag:' + $event)"
            [disableAction]="scriptLock$ | async"
            [disableSocial]="likeLock$ | async"
          ></mon-script-detail>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<mat-menu #installedScriptMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent let-script="script">
    <ng-container *hasPerm="[permission.Script_Update]">
      <ng-container *ngIf="script.userPermission.write">
        <button [disabled]="!script.disabled" mat-menu-item (click)="enableScript(script)">Enable Script</button>
        <button [disabled]="script.disabled" mat-menu-item (click)="disableScript(script)">Disable Script</button>
      </ng-container>
    </ng-container>
    <button
      *hasPerm="[permission.Script_Uninstall]"
      [disabled]="script.location === scriptLocation.Private"
      mat-menu-item
      (click)="uninstallScript(script)"
    >
      Uninstall Script
    </button>
    <button
      *hasPerm="[permission.Script_Delete]"
      [disabled]="script.location === scriptLocation.Public"
      mat-menu-item
      (click)="deleteScript(script)"
    >
      Delete Script
    </button>
  </ng-template>
</mat-menu>
