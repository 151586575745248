import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TenantService } from 'src/app/core/services/tenant.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { User } from 'src/app/shared/models/user.model';

export class UsersDataSource implements DataSource<User> {
  private _users = new BehaviorSubject<User[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._users.value ? this._users.value.length : -1;
  }
  public get data(): User[] {
    return this._users.value?.slice() || [];
  }

  constructor(private tenantSvc: TenantService) {}

  loadUsers(filter: string, limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    this.tenantSvc
      .getAllUsers(filter, limit, page, orderBy)
      .pipe(
        catchError(() => of([])),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (results: PagedResult<User>) => {
          this.total = results.count;
          this._users.next(results.items || []);
        }
      });
  }

  connect(): Observable<User[]> {
    return this._users.asObservable();
  }

  disconnect(): void {
    this._users.complete();
    this._loading.complete();
  }
}
