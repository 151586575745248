import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RemoteSession } from 'src/app/shared/models/remote-session.model';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  createConnection(
    agentID: string,
    sessionID: number,
    username: string,
    remoteType: string
  ): Observable<RemoteSession> {
    const url = `${this.apiUrl}/agent/${agentID}/remote`;
    return this.http.post<RemoteSession>(url, { sessionID, username, remoteType }).pipe(
      tap(() => this.log('fetched RemoteService.createConnection response')),
      map((response) => (response ? new RemoteSession(response) : undefined)),
      catchError((err) => {
        this.handleError<RemoteSession>('RemoteService.createConnection');
        return throwError(() => err);
      })
    );
  }

  readConnectionAsHost(remoteSessionID: string): Observable<RemoteSession> {
    const url = `${this.apiUrl}/agent/remote/${remoteSessionID}`;
    return this.http.get<RemoteSession>(url).pipe(
      tap(() => this.log('fetched RemoteService.readConnectionAsHost response')),
      map((response) => (response ? new RemoteSession(response) : undefined)),
      catchError((err) => {
        this.handleError<RemoteSession>('RemoteService.readConnectionAsHost');
        return throwError(() => err);
      })
    );
  }

  readConnectionAsClient(remoteSessionID: string): Observable<RemoteSession> {
    const url = `${this.baseUrl}/remote/${remoteSessionID}`;
    return this.http.get<RemoteSession>(url).pipe(
      tap(() => this.log('fetched RemoteService.readConnectionAsClient response')),
      map((response) => (response ? new RemoteSession(response) : undefined)),
      catchError((err) => {
        this.handleError<RemoteSession>('RemoteService.readConnectionAsClient');
        return throwError(() => err);
      })
    );
  }
}
