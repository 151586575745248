import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[overflowClasses]'
})
export class OverflowClassesDirective implements AfterViewInit {
  _classes: object;
  @Input('overflowClasses') set classes(c: object) {
    this._classes = c;

    new ResizeObserver(() => {
      this.apply();
    }).observe(this.elRef.nativeElement);

    this.apply();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.apply();
  }

  // @HostListener('click') onClick() {
  //   this.apply();
  // }

  // @HostListener('blur') onBlur() {
  //   this.apply();
  // }

  // @HostListener('focus') onFocus() {
  //   this.apply();
  // }

  // @HostListener('scroll') onOverflow() {
  //   this.apply();
  // }

  // @HostListener('mouseenter') onMouseEnter() {
  //   this.apply();
  // }

  // @HostListener('mouseover') onMouseLeave() {
  //   this.apply();
  // }

  constructor(private renderer: Renderer2, private elRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.apply();
  }

  apply(): void {
    const e = this.elRef.nativeElement;
    const sWidth = e.scrollWidth;
    const cWidth = e.clientWidth;
    const sHeight = e.scrollHeight;
    const cHeight = e.clientHeight;

    const overflowing = sWidth > cWidth || sHeight > cHeight;

    Object.keys(this._classes).forEach((c) => {
      const conditionMet = !!this._classes[c];
      if (conditionMet && overflowing) {
        this.renderer.addClass(e, c);
      } else {
        this.renderer.removeClass(e, c);
      }
    });
  }
}
