import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  Inject,
  Injectable,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

export interface ConfirmDialogData {
  icon: IconName;
  iconClasses: string[];
  iconBgClasses: string[];
  title: string;
  confirmationText: string;
  htmlDialogContent: string;
  confirmWithCheckbox: boolean;
  hideConfirmButton: boolean;
  hideCancelButton: boolean;
  confirmButtonText: string;
  cancelButtonText: string;
  component: unknown;
  onCancel: () => void;
  onConfirm: () => void;
}

@Component({
  selector: 'mon-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    FontAwesomeModule,
    MatDivider,
    MatCheckbox
  ]
})
export class ConfirmDialogComponent implements AfterViewInit {
  @ViewChild('cancelBtn') cancelBtn: MatButton;
  @ViewChild('confirmBtn') confirmBtn: MatButton;
  @ViewChild('viewContainerRef', { read: ViewContainerRef }) vcr!: ViewContainerRef;

  ref!: ComponentRef<typeof this.data.component>;

  confirmed = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    if (data.confirmWithCheckbox) {
      this.confirmed = false;
    }
  }

  ngAfterViewInit(): void {
    this.cancelBtn.focus();
    setTimeout(() => this.addChild(), 0);
  }

  confirm(): void {
    if (this.data.onConfirm) {
      this.data.onConfirm();
    }
    this.dialogRef.close();
  }

  cancel(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }

  addChild() {
    if (this.data.component) {
      this.ref = this.vcr.createComponent(
        this.data.component as Type<typeof this.data.component>
      );
    }
  }

  removeChild() {
    const index = this.vcr.indexOf(this.ref.hostView);
    if (index != -1) this.vcr.remove(index);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialog {
  constructor(private dialog: MatDialog) {}

  open(msg: string, fn: () => void): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      width: '400px',
      data: {
        icon: 'question-circle',
        title: 'Confirm',
        confirmationText: msg,
        onConfirm: () => {
          fn();
        },
        onCancel: () => {}
      }
    });
  }
}
