<div class="card h-full">
  <div class="border-b px-2 py-2 flex justify-start items-center content-center">
    <fa-icon [icon]="['fad', 'desktop']" size="2x" [fixedWidth]="true" class="mr-2 text-primary"> </fa-icon>

    <h2 class="m-0 title flex-auto truncate">{{ _displayConfig.name }}</h2>

    <button
      *ngIf="agent.system.os === OS.WINDOWS"
      mat-icon-button
      type="button"
      (click)="hardwareInfo()"
      class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 sm-icon-button"
      matTooltip="Hardware Information"
    >
      <fa-icon [icon]="['fad', 'computer']" size="lg" class="flex items-center justify-center"> </fa-icon>
    </button>

    <button
      mat-icon-button
      type="button"
      (click)="shutdownSystem()"
      class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 sm-icon-button"
      matTooltip="Shutdown"
    >
      <fa-icon [icon]="['fad', 'power-off']" size="lg" class="flex items-center justify-center"> </fa-icon>
    </button>

    <button
      mat-icon-button
      type="button"
      (click)="shutdownSystem(0, true)"
      class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 sm-icon-button"
      matTooltip="Restart"
    >
      <fa-icon [icon]="['fad', 'sync-alt']" size="lg" class="flex items-center justify-center"> </fa-icon>
    </button>

    <mon-display-config-button
      *ngIf="_displayConfig"
      persistAt="User"
      [config]="_displayConfig"
      class="flex items-center justify-center text-sm w-8 h-8"
    ></mon-display-config-button>
  </div>

  <div *ngIf="agentProperties$ | async as agentProperties" class="grid grid-cols-12 gap-y-1 p-2 text-xs">
    <ng-container *ngFor="let prop of objectKeys(agentProperties)">
      <ng-container *ngIf="_displayConfig.fieldValue(prop)">
        <ng-container *ngIf="agentProperties[prop] as value">
          <div class="col-span-3 pl-3 text-secondary">{{ prop }}</div>
          <div class="col-span-9 whitespace-pre-line">
            {{ value | strictDate: 'medium' }}
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
