import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs/operators';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { IntroService } from 'src/app/core/services/intro.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { StateService } from 'src/app/core/services/state.service';
import { UserService } from 'src/app/core/services/user.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import {
  ConfirmDialog,
  ConfirmDialogComponent
} from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'mon-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [fadeInUp200ms, stagger80ms]
})
export class SettingsComponent {
  canDeleteAccount$ = this.userSvc.tenants().pipe(
    map((tenants) => {
      return (
        tenants
          .filter((t) => t.owned)
          .findIndex(
            (t) =>
              t.licenseStatus !== 'in-trial' &&
              t.licenseStatus !== 'non-renewing' &&
              t.licenseStatus !== 'cancelled'
          ) < 0
      );
    })
  );

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private authSvc: AuthService,
    private dialog: MatDialog,
    private confirm: ConfirmDialog,
    private loaderSvc: LoaderService,
    private stateSvc: StateService,
    private userSvc: UserService,
    private introSvc: IntroService
  ) {}

  resetIntro(): void {
    this.confirm.open('Are you sure you want to reset the introduction wizard?', () => {
      this.stateSvc.updateCurrentUserSettings({
        consoleUI: {
          global: { 'hide-intro-wizard': false },
          intro: undefined
        }
      });
      this.introSvc.resetSteps();
    });
  }

  resetTables(): void {
    this.confirm.open('Are you sure you want to reset the tables?', () =>
      this.stateSvc.updateCurrentUserSettings({
        consoleUI: {
          table: undefined
        }
      })
    );
  }

  resetConfig(): void {
    this.confirm.open('Are you sure you want to reset configuration settings?', () =>
      this.stateSvc.updateCurrentUserSettings({
        consoleUI: {
          config: undefined
        }
      })
    );
  }

  deleteAccount(): void {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      restoreFocus: false,
      width: '400px',
      data: {
        icon: `user`,
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: `Delete Account?`,
        confirmWithCheckbox: true,
        htmlDialogContent: `
        <div class="card text-white text-opacity-80 bg-red flex flex-col justify-between items-center p-4 mb-4 text-center gap-2">
          <h2>WARNING:</h2>
          <p>Deleting your account will remove all your tenants and user account</p>
        </div>
        <div class="flex items-center justify-center">
          Are you sure you want to delete your account?
        </div>`,
        onConfirm: () => {
          this.loaderSvc.open('Deleting Account');

          this.userSvc.deleteAccount().subscribe({
            next: () => {
              this.authSvc.logout({
                logoutParams: { returnTo: this.document.location.origin }
              });
            },
            error: (err) => {
              this.loaderSvc.close({
                header: 'Delete Failed',
                closeButtonHidden: false,
                content: parseError(err) || 'Error Deleting User'
              });
            }
          });
        },
        onCancel: () => {}
      }
    });
  }
}
