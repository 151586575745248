import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize, map, Observable, startWith, tap } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { NewRole, PatchRole, RoleService } from 'src/app/core/services/role.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { getRoleIDRegex } from 'src/app/core/utils/regex';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { Role } from 'src/app/shared/models/role.model';
import { CustomValidators } from 'src/app/utils/validators';

export class RoleEditParams {
  roleID: Role['id'];
  roleName: Role['name'];
  roleDescription: Role['description'];
  rolePermissions: Role['permissions'];
  onSuccess: () => void;
  constructor(init?: Partial<RoleEditParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms]
})
export class RoleEditComponent implements OnInit {
  edit: boolean;
  err: string;
  spin: boolean;
  overlayResult = OverlayResult.Unset;

  form: FormGroup;
  selectableCtrl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  perms: string[];
  allPermissions$ = this.roleSvc
    .getAllPermissions(true)
    .pipe(tap((perms) => (this.perms = perms.sort())));
  filteredPermissions$: Observable<string[]>;

  role: NewRole | PatchRole = {
    id: undefined,
    name: '',
    description: '',
    permissions: new Array<string>()
  };

  @ViewChild('permissionInput') permissionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: RoleEditParams,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RoleEditComponent>,
    private fb: FormBuilder,
    private roleSvc: RoleService
  ) {
    this.edit = !!params.roleID;

    this.form = this.fb.group({
      id: new FormControl({ value: '', disabled: this.edit }, [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(getRoleIDRegex())
      ]),
      name: new FormControl('', [
        CustomValidators.noWhitespace,
        Validators.required,
        Validators.maxLength(50)
      ]),
      description: new FormControl(''),
      permissions: new FormControl(new Array<string>())
    });

    this.form.patchValue(this.role);
    this.form.controls.id.valueChanges.subscribe({
      next: (v: string) => (this.role['id'] = v)
    });
    this.form.controls.name.valueChanges.subscribe({
      next: (v: string) => (this.role.name = v)
    });
    this.form.controls.description.valueChanges.subscribe({
      next: (v: string) => (this.role.description = v)
    });
    this.filteredPermissions$ = this.selectableCtrl.valueChanges.pipe(
      startWith(''),
      map((perm: string | null) => this._filter(perm))
    );
  }

  ngOnInit(): void {
    if (this.params.roleID) {
      this.spin = true;
      this.roleSvc
        .getByID(this.params.roleID)
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: (role) => {
            this.role = role;
            this.form.patchValue(this.role);
            this.selectableCtrl.setValue('');
            this.selectableCtrl.updateValueAndValidity();
          }
        });
    } else if (this.params.roleName) {
      this.role.name = this.params.roleName;
      this.form.patchValue(this.role);
    }
  }

  save(): void {
    this.spin = true;
    this.closeError();

    this.edit
      ? this.roleSvc
          .update(this.role['id'], this.role as PatchRole)
          .pipe(finalize(() => (this.spin = false)))
          .subscribe({
            next: () => {
              this.overlayResult = OverlayResult.Success;
            },
            error: (err: HttpErrorResponse) => {
              this.err = parseError(err);
              this.overlayResult = OverlayResult.Error;
            }
          })
      : this.roleSvc
          .create(this.role as NewRole)
          .pipe(finalize(() => (this.spin = false)))
          .subscribe({
            next: () => {
              this.overlayResult = OverlayResult.Success;
            },
            error: (err: HttpErrorResponse) => {
              this.err = parseError(err);
              this.overlayResult = OverlayResult.Error;
            }
          });
  }

  delete(): void {
    this.spin = true;
    this.closeError();

    this.roleSvc
      .delete(this.role['id'], true)
      .pipe(finalize(() => (this.spin = false)))
      .subscribe({
        next: () => {
          this.overlayResult = OverlayResult.Success;
        },
        error: (err: HttpErrorResponse) => {
          this.err = parseError(err);
          this.overlayResult = OverlayResult.Error;
        }
      });
  }

  addPermission(event: MatChipInputEvent, allPermissions: string[]): void {
    const input = event.chipInput.inputElement;
    const value = (event.value || '').trim().toLowerCase();

    if (allPermissions.findIndex((r) => r.toLowerCase() === value) < 0) {
      return;
    }

    // Add our permission
    if (value) {
      this._addPermission(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.selectableCtrl.setValue('');
    this.selectableCtrl.updateValueAndValidity();
    setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  removePermission(permission: string): void {
    const found = this.role.permissions.findIndex((p) => p === permission);
    if (found >= 0) {
      this.role.permissions.splice(found, 1);

      this.selectableCtrl.setValue(null);
      this.selectableCtrl.updateValueAndValidity();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._addPermission(event.option.viewValue);
    this.permissionInput.nativeElement.value = '';
    this.selectableCtrl.setValue('');
    this.selectableCtrl.updateValueAndValidity();
  }

  closeError(): void {
    this.err = '';
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.();
      this.dialogRef.close();
    }
  }

  confirm(action: () => void): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: true,
      data: {
        icon: 'hand-paper',
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: 'Commit Action?',
        confirmationText: `Doing this may influence users. Please confirm.`,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        onConfirm: action,
        onCancel: () => {}
      }
    });
  }

  private _addPermission(permission: string): void {
    this.role.permissions.push(permission);
    setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  private _filter(value: string): string[] {
    value = value || '';

    return this.perms
      .filter((perm) => !this.role.permissions.find((p) => p === perm))
      .filter((perm) => perm.toLowerCase().indexOf(value) === 0);
  }
}
