import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Alarm } from 'src/app/shared/models/alarm.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AlarmService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(
    limit: number,
    page: number,
    orderBy: OrderBy[]
  ): Observable<PagedResult<Alarm>> {
    const url = `${this.apiUrl}/alarm`;
    const params = {};
    Object.assign(params, {
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Alarm>>(url, { observe: 'response', params }).pipe(
      tap(() =>
        this.log(`fetched AlarmService.getAll response in ${performance.now() - start}ms`)
      ),
      map((response) =>
        response
          ? new PagedResult<Alarm>(Alarm, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Alarm>>('AlarmService.getAll');
        return throwError(() => err);
      })
    );
  }
}
