import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, iif, Observable, switchMap, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Trigger, TriggerCondition } from 'src/app/shared/models/trigger.model';

import { UNDEFINED } from 'src/app/utils/rxjs';
import { ApiService, NoContent, Response } from './api.service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class TriggerService extends ApiService {
  private static _currentTenantID: string = undefined;
  private static _triggers: Trigger[] = undefined;
  static getTriggers(): Trigger[] {
    if (!TriggerService._triggers) {
      // check session storage
      const triggerCacheValue = sessionStorage.getItem(
        `trigger-cache-${TriggerService._currentTenantID}`
      );
      if (!!triggerCacheValue) {
        TriggerService._triggers = JSON.parse(triggerCacheValue).map(
          (t: Trigger) => new Trigger(t)
        );
      }
    }

    return TriggerService._triggers;
  }

  constructor(private http: HttpClient, private stateSvc: StateService) {
    super();
  }

  cacheTriggers() {
    if (!TriggerService.getTriggers()) {
      this.refreshCache();
    }
  }

  private refreshCache() {
    this.stateSvc.tenant$
      .pipe(
        filter((tenant) => !!tenant),
        tap(() => (TriggerService._triggers = undefined)),
        tap((tenant) => (TriggerService._currentTenantID = tenant.id)),
        switchMap((tenant) =>
          iif(
            () => !TriggerService.getTriggers(),
            this.getAll(null, null, null, null, false).pipe(
              tap((r) => {
                TriggerService._triggers = r?.items || [];
                sessionStorage.setItem(
                  `trigger-cache-${tenant.id}`,
                  JSON.stringify(TriggerService._triggers)
                );
              }),
              map(() => TriggerService._triggers)
            ),
            UNDEFINED
          )
        )
      )
      .subscribe();
  }

  create(
    name: string,
    description: string,
    conditions: TriggerCondition[]
  ): Observable<Response> {
    const url = `${this.apiUrl}/trigger`;
    const params = {
      name,
      description,
      conditions
    };

    return this.http.post<Response>(url, params).pipe(
      tap(() => this.refreshCache()),
      catchError((err) => {
        this.handleError<Response>('TriggerService.update');
        return throwError(() => err);
      })
    );
  }

  getByID(id: string): Observable<Trigger> {
    const url = `${this.apiUrl}/trigger/${id}`;
    return this.http.get<Trigger>(url).pipe(
      map((response) => (response ? new Trigger(response) : undefined)),
      catchError((err) => {
        this.handleError<Trigger>('TriggerService.getByID');
        return throwError(() => err);
      })
    );
  }

  getAll(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = [],
    disabled: boolean = undefined
  ): Observable<PagedResult<Trigger>> {
    const url = `${this.apiUrl}/trigger`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    if (disabled !== undefined) {
      params['disabled'] = disabled;
    }

    const start = performance.now();
    return this.http.get<Array<Trigger>>(url, { observe: 'response', params }).pipe(
      tap(() =>
        this.log(
          `fetched TriggerService.getAll response in ${performance.now() - start}ms`
        )
      ),
      map((response) =>
        response
          ? new PagedResult<Trigger>(Trigger, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Trigger>>('TriggerService.getAll');
        return throwError(() => err);
      })
    );
  }

  update(
    id: string,
    name: string,
    description: string,
    conditions: TriggerCondition[]
  ): Observable<Response> {
    const url = `${this.apiUrl}/trigger/${id}`;
    const params = {
      name,
      description,
      conditions
    };

    return this.http.patch<Response>(url, params).pipe(
      tap(() => this.refreshCache()),
      catchError((err) => {
        this.handleError<Response>('TriggerService.update');
        return throwError(() => err);
      })
    );
  }

  enable(ids: string[]): Observable<Response> {
    const url = `${this.apiUrl}/trigger/enable`;
    const params = {
      ids
    };

    return this.http.patch<Response>(url, params).pipe(
      tap(() => this.refreshCache()),
      catchError((err) => {
        this.handleError<Trigger>('TriggerService.enable');
        return throwError(() => err);
      })
    );
  }

  disable(ids: string[]): Observable<Response> {
    const url = `${this.apiUrl}/trigger/disable`;
    const params = {
      ids
    };

    return this.http.patch<Response>(url, params).pipe(
      tap(() => this.refreshCache()),
      catchError((err) => {
        this.handleError<Trigger>('TriggerService.disable');
        return throwError(() => err);
      })
    );
  }

  delete(ids: string[]): Observable<NoContent> {
    const url = `${this.apiUrl}/trigger?id=${ids.join('&id=')}`;

    return this.http.delete<NoContent>(url).pipe(
      tap(() => this.refreshCache()),
      catchError((err) => {
        this.handleError<NoContent>('TriggerService.delete');
        return throwError(() => err);
      })
    );
  }
}
