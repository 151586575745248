<div *ngIf="user$ | async as user" class="border-4 border-opacity-50 border-gray-light dark:border-gray-500 rounded drop-shadow-md">
  <div class="w-80 rounded bg-card">
    <div class="dropdown-content">
      <ng-container *ngFor="let item of items; trackBy: trackById">
        <ng-container *ngIf="item.visible(user)">
          <a
            (click)="close(item)"
            [routerLink]="item.route ? item.route : undefined"
            queryParamsHandling="merge"
            class="notification flex items-center justify-start align-center content-center cursor-pointer"
            matRipple
          >
            <fa-icon
              [icon]="['fad', item.icon]"
              [ngClass]="item.colorClass"
              class="notification-icon flex-none"
              size="2x"
              [fixedWidth]="true"
            >
            </fa-icon>
            <div class="flex-auto">
              <div class="notification-label">{{ item.label }}</div>
              <div class="notification-description">{{ item.description }}</div>
            </div>
            <fa-icon [icon]="['fad', 'chevron-right']" class="flex-none"></fa-icon>
          </a>
        </ng-container>
      </ng-container>
    </div>

    <div class="dropdown-footer flex items-center justify-between">
      <div class="flex flex-col">
        <div class="text-2xs font-bold" [style.font-variant]="'small-caps'">Last Login</div>
        <div class="text-3xs text-secondary">{{user.lastLogin.timestamp | date: 'medium'}}</div>
        <div class="text-3xs text-secondary">{{user.lastLogin.ip}}</div>
        <div class="text-3xs text-secondary">{{user.geoD}}</div>
      </div>
      <a (click)="logout()" color="primary" mat-button>LOGOUT</a>
    </div>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)" *ngFor="let status of statuses; trackBy: trackById" mat-menu-item>
    <fa-icon [icon]="['fad', status.icon]" [ngClass]="status.colorClass" inline="true" size="lg"> </fa-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <fa-icon [icon]="['fad', 'building']"></fa-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <fa-icon [icon]="['fad', 'shield-check']"></fa-icon>
    <span>Change Username</span>
  </button>

  <button mat-menu-item>
    <fa-icon [icon]="['fad', 'lock']"></fa-icon>
    <span>Change Password</span>
  </button>

  <button mat-menu-item>
    <fa-icon [icon]="['fad', 'bell-slash']"></fa-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>
