import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { Task } from 'src/app/shared/models/task.model';

import { ApiService, ServiceState } from './api.service';
import { StateService } from './state.service';
import { TaskService } from './task.service';

@Injectable({ providedIn: 'root' })
export class TaskPollerService extends ApiService {
  private _state = new BehaviorSubject<ServiceState>(ServiceState.Stopped);
  state$ = this._state.asObservable();
  get state(): ServiceState {
    return this._state.value;
  }
  set state(value: ServiceState) {
    if (this._state.value === value) {
      return;
    }
    this._state.next(value);
  }

  private _recentTasks = new BehaviorSubject<Array<Task>>(undefined);
  recentTasks$ = this._recentTasks.asObservable();
  get recentTasks(): Array<Task> {
    return this._recentTasks.value;
  }

  private pollSub: Subscription;
  private newerThanUnixNano = 0;

  constructor(private stateSvc: StateService, private taskSvc: TaskService) {
    super();

    combineLatest([this.stateSvc.stopPolling$, this.state$]).subscribe({
      next: ([stopPolling, state]) => {
        if (state == ServiceState.Started && !stopPolling) {
          this.pollTasks();
        } else {
          if (this.pollSub && !this.pollSub.closed) {
            this.pollSub.unsubscribe();
          }
        }
      }
    });
  }

  public start(): void {
    if (this.state !== ServiceState.Stopped) {
      return;
    }

    this.state = ServiceState.Starting;

    if (this.pollSub && !this.pollSub.closed) {
      this.pollSub.unsubscribe();
    }

    this.state = ServiceState.Started;
  }

  public stop(): void {
    if (this.state === ServiceState.Stopped || this.state === ServiceState.Stopping) {
      return;
    }

    this.state = ServiceState.Stopping;

    if (this.pollSub && !this.pollSub.closed) {
      this.pollSub.unsubscribe();
    }

    this._recentTasks.next(undefined);

    this.state = ServiceState.Stopped;
  }

  public pause(): void {
    if (this.state === ServiceState.Started) {
      this.state = ServiceState.Paused;
    }
  }

  public unpause(): void {
    if (this.state === ServiceState.Paused) {
      this.state = ServiceState.Started;
    }
  }

  public clearRecent(): void {
    this._recentTasks.next(undefined);
  }

  private pollTasks(): void {
    this.pollSub = this.taskSvc.getAllListen(this.newerThanUnixNano).subscribe({
      next: (tasks) => {
        if (tasks && tasks.length > 0) {
          this._recentTasks.next(tasks);
          this.newerThanUnixNano = tasks.reduce((a, b) =>
            a.lastUpdateUnixNano > b.lastUpdateUnixNano ? a : b
          ).lastUpdateUnixNano;
        }
      },
      error: (err) => this.log('ERROR: ', err),
      complete: () => {
        if (!this.pollSub.closed) {
          this.pollSub.unsubscribe();
        }
        this.pollTasks();
      }
    });
  }
}
