import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BehaviorSubject,
  delay,
  filter,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  retry,
  startWith,
  Subject,
  switchMap,
  take,
  tap
} from 'rxjs';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { StateService } from 'src/app/core/services/state.service';
import { TaskService } from 'src/app/core/services/task.service';
import { LogCollection } from 'src/app/shared/interfaces/log-collection.interface';
import { deepCopy } from 'src/app/utils/clone';
import {
  DebugSettingsComponent,
  DebugSettingsParams
} from 'src/app/view/diagnostics/agent-tools/log-report/debug-settings/debug-settings.component';

@Component({
  selector: 'mon-log-report',
  templateUrl: './log-report.component.html',
  styleUrls: ['./log-report.component.scss']
})
export class LogReportComponent {
  spin = true;
  lastRan = new Date();

  private _logCollectionResult = new BehaviorSubject<string>(undefined);
  readonly logCollectionResult$ = this._logCollectionResult.asObservable();

  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running
    .asObservable()
    .pipe(tap((running) => (this.spin = running)));

  constructor(
    private dialog: MatDialog,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private stateSvc: StateService,
    private taskSvc: TaskService,
    private diagnosticsSvc: DiagnosticsService
  ) {}

  agent$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );

  _refreshRecent = new Subject<void>();
  recent$ = this._refreshRecent.asObservable().pipe(
    startWith(undefined),
    tap(() => (this.spin = true)),
    switchMap(() => this.agent$),
    filter((agent) => !!agent),
    switchMap((agent) => this.diagnosticsSvc.getListOfLogCollections(agent.id)),
    map((response) => {
      if (response?.tag) {
        const sorted = response.tag.sort((a, b) => b.time.localeCompare(a.time));
        return sorted.slice(0, 10);
      }
      return new Array<LogCollection>();
    }),
    tap(() => (this.spin = false))
  );

  public downloadLog(log: LogCollection): void {
    this.diagnosticsSvc.downloadLogCollection(log.agent_id, log.name);
  }

  packageLogs(agentID: string): void {
    this.spin = true;
    this._running.next(true);
    this._logCollectionResult.next('');
    this.lastRan = undefined;

    this.diagnosticsSvc
      .runLogCollection(agentID)
      .pipe(
        switchMap((response) => {
          const taskID = response.tag;
          let taskUpdateUnixNano = 0;

          return this.taskSvc.getTask(taskID).pipe(
            tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
            mergeMap(() =>
              of(taskUpdateUnixNano).pipe(
                // on retries, we make sure to use the updated time
                switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
                tap((task) => {
                  if (!task) {
                    throw 'still waiting...';
                  } else if (!task.isDone) {
                    taskUpdateUnixNano = task.lastUpdateUnixNano;
                    throw 'still waiting...';
                  }
                })
              )
            ),
            retry({
              delay: (_error, _retryCount) => of(true)
            }),
            delay(2000),
            tap(() => this._refreshRecent.next()),
            finalize(() => this._running.next(false))
          );
        })
      )
      .subscribe({
        error: (err) => {
          console.warn(err);
          this.spin = false;
        }
      });
  }

  trackByID(_index: number, log: LogCollection): string {
    return log.time;
  }

  setDebugMode(agentID: string): void {
    const ref = this.dialog.open(DebugSettingsComponent, {
      disableClose: true,
      restoreFocus: false,
      minWidth: 300,
      height: 'auto',
      width: 'auto'
    });

    ref.afterClosed().subscribe((params: DebugSettingsParams) => {
      if (params.onSuccess) {
        this.diagnosticsSvc
          .setDebugMode(agentID, params.duration)
          .pipe(
            switchMap((response) => {
              const taskID = response.tag;
              let taskUpdateUnixNano = 0;

              return this.taskSvc.getTask(taskID).pipe(
                tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
                mergeMap(() =>
                  of(taskUpdateUnixNano).pipe(
                    // on retries, we make sure to use the updated time
                    switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
                    tap((task) => {
                      if (!task) {
                        throw 'still waiting...';
                      } else if (!task.isDone) {
                        taskUpdateUnixNano = task.lastUpdateUnixNano;
                        throw 'still waiting...';
                      }
                    })
                  )
                ),
                retry({
                  delay: (_error, _retryCount) => of(true)
                }),
                delay(2000),
                tap(() => this._refreshRecent.next()),
                finalize(() => this._running.next(false))
              );
            })
          )
          .subscribe({
            error: (err) => {
              console.warn(err);
              this.spin = false;
            }
          });
      }
    });
  }
}
