import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'vex-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    class: 'vex-sidebar'
  }
})
export class SidebarComponent {
  @Input() position: 'left' | 'right' = 'left';
  @Input() invisibleBackdrop: boolean;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private _opened: boolean;

  get opened(): boolean {
    return this._opened;
  }

  @Input() set opened(opened: boolean) {
    this._opened = opened;
    opened ? this.enableScrollblock() : this.disableScrollblock();
  }

  get positionLeft(): boolean {
    return this.position === 'left';
  }

  get positionRight(): boolean {
    return this.position === 'right';
  }

  enableScrollblock(): void {
    if (!this.document.body.classList.contains('vex-scrollblock')) {
      this.document.body.classList.add('vex-scrollblock');
    }
  }

  disableScrollblock(): void {
    if (this.document.body.classList.contains('vex-scrollblock')) {
      this.document.body.classList.remove('vex-scrollblock');
    }
  }

  open(): void {
    this.opened = true;
  }

  close(): void {
    this.opened = false;
  }
}
