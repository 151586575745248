import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { filter, map, Observable, take, throwError } from 'rxjs';

import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root'
})
export class PermGuard  {
  constructor(private stateSvc: StateService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
    // state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiresPerm = (route.data.requiresPerm || []) as string[];
    if (requiresPerm.length === 0) {
      return throwError(
        () => '** no permissions set for this route - check your code bro **'
      );
    }

    return this.stateSvc.currentUser$.pipe(
      filter((user) => !!user),
      take(1),
      map((user) => {
        for (let i = 0; i < requiresPerm.length; i++) {
          if (!user.perms.has(requiresPerm[i])) {
            this.router.navigate(['home', 'dashboard'], { queryParamsHandling: 'merge' });
            return false;
          }
        }
        return true;
      })
    );
  }
}
