<ng-container *ngIf="renderData$ | async"></ng-container>
<div @fadeInUp @fadeOutUp class="mt-2">
  <div>
    <h2 class="m-0 title mb-2">Latest Agent</h2>
    <blockquote class="whitespace-normal break-all bg-pink-100 border-pink-500 text-pink-900">
      <div><span class="font-bold">Tenant ID:</span> {{ tenantId }}</div>
      <div><span class="font-bold">API Key:</span> {{ apiKey }}</div>
    </blockquote>
  </div>

  <h2 class="m-0 title mb-2">Install Agent</h2>

  <p class="p-3">Follow the instructions set for your particular operating system</p>

  <h3 class="title mt-0 p-3">Windows</h3>

  <div class="px-8">
    <ng-container *ngIf="windowsAmd64Version$ | async as meta">
      <blockquote class="whitespace-normal break-all bg-blue-100 border-blue-500 text-blue-900">
        <div><span class="font-bold">Windows amd64</span></div>
        <div><span class="font-bold">Version:</span> {{ meta.ccagent.version }}</div>
        <div><span class="font-bold">Hash:</span> {{ meta.ccagent.hash }}</div>
      </blockquote>
    </ng-container>

    <p class="text-secondary"
      >Download the latest agent from the following link:
      <a href="{{ winDownloadLink }}" download>
        <button color="primary" mat-button class="text-secondary -mt-4 -mr-4">Download Windows Agent</button>
      </a>
    </p>

    <blockquote class="whitespace-normal break-all">The below command will install the agent silently</blockquote>

    <div [style.visibility]="visibility" class="relative">
      <markdown [data]="winInstallExample | language: 'powershell'"> </markdown>
      <button
        data-intro-key="copy-text-button"
        #install="matTooltip"
        matTooltipHideDelay="2000"
        matTooltipDisabled
        matTooltip="Copied"
        class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
        (click)="getCopy(install, winInstallExample)"
      >
        copy
      </button>
    </div>
  </div>

  <h3 class="title mt-0 p-3" (click)="ngAfterViewInit()">macOS</h3>

  <div class="px-8">
    <ng-container *ngIf="darwinAmd64Version$ | async as meta">
      <blockquote class="whitespace-normal break-all bg-blue-100 border-blue-500 text-blue-900">
        <div><span class="font-bold">Darwin amd64</span></div>
        <div><span class="font-bold">Version:</span> {{ meta.ccagent.version }}</div>
        <div><span class="font-bold">Hash:</span> {{ meta.ccagent.hash }}</div>
      </blockquote>
    </ng-container>
    <ng-container *ngIf="darwinArm64Version$ | async as meta">
      <blockquote class="whitespace-normal break-all bg-blue-100 border-blue-500 text-blue-900">
        <div><span class="font-bold">Darwin arm64</span></div>
        <div><span class="font-bold">Version:</span> {{ meta.ccagent.version }}</div>
        <div><span class="font-bold">Hash:</span> {{ meta.ccagent.hash }}</div>
      </blockquote>
    </ng-container>

    <ng-container *ngIf="macOSDownloadInfo$ | async as info">
      <p class="text-secondary"
        >Download the universal latest agent from the following link:
        <a href="{{ pkgToDmg | callOnce: info.url }}" download>
          <button color="primary" mat-button class="text-secondary -mt-4 -mr-4">Download OSX Agent</button>
        </a>
      </p>
    </ng-container>
  </div>

  <h3 class="title mt-0 p-3">Linux</h3>

  <div class="px-8">
    <code class="p-4 block">🚧 CHECK BACK SOON 🚧</code>
  </div>
</div>
