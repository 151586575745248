<vex-page-layout *ngIf="mainState$ | async as mainState" class="main-outer-content bg-primary-light/10">
  <ng-container *ngIf="recentMachines$ | async as recentMachines">
    <ng-container *ngIf="mainState.agentRequired; else agentNotRequired">
      <ng-container *ngIf="!mainState.licenseExpired; else licenseExpired">
        <ng-container [ngSwitch]="mainState.selectedAgent.state">
          <!-- AGENT FOUND -->
          <ng-container *ngSwitchCase="SelectedAgentState.Found">
            <div @fadeOutRight @fadeInRight *ngIf="agentToolbarState$ | async as agentToolbarState">
              <mon-agent-toolbar
                [agent]="agentToolbarState.agent"
                [agentBlocks]="agentToolbarState.agentBlocks"
                [agentConnectionState]="agentToolbarState.agentConnectionState"
                [outgoingSessions]="agentToolbarState.outgoingSessions"
                [liveMode]="mainState.liveMode"
              ></mon-agent-toolbar>
            </div>
            <div @fadeOutRight @fadeInRight *ngIf="!mainState.liveMode">
              <mon-timeline-toolbar></mon-timeline-toolbar>
            </div>
            <vex-page-layout-content
              [ngClass]="{ 'show-agent-header transition-all': mainState.liveMode }"
              class="main-inner-content show-agent-header-with-timeline"
            >
              <router-outlet></router-outlet>
            </vex-page-layout-content>
          </ng-container>

          <!-- AGENT NOT FOUND -->
          <ng-container *ngSwitchCase="SelectedAgentState.NotFound"> <ng-container *ngTemplateOutlet="search"></ng-container></ng-container>

          <!-- AGENT UNDEFINED -->
          <ng-container *ngSwitchCase="SelectedAgentState.Undefined">
            <ng-container *ngTemplateOutlet="search"></ng-container
          ></ng-container>

          <ng-template #search>
            <vex-page-layout-content class="main-inner-content">
              <div class="h-full md:h-auto flex flex-col gap-y-2">
                <ng-container *hasPerm="[permission.Agent_List]; else noPerm">
                  <div @fadeInUp class="flex-1 card text-center relative">
                    <div class="flex flex-col justify-center items-center content-center py-6 sm:py-12 h-full">
                      <fa-icon [icon]="['fad', 'search']" size="5x" class="text-primary text-base sm:text-4xl"></fa-icon>
                      <ng-container *ngIf="mainState.selectedAgent.agentID">
                        <div class="text-base sm:text-4xl pt-4">
                          Machine <b>{{ mainState.selectedAgent.agentID }}</b> not found
                        </div>
                      </ng-container>
                      <h2 class="m-0 pt-4 text-secondary text-base sm:text-xl">Search For A Machine</h2>
                      <h2 class="m-0 pt-4 text-secondary hidden sm:block text-xl">By Pressing '/'</h2>
                      <h2 class="m-0 pt-4 text-secondary sm:hidden text-base">By Tapping Here</h2>
                    </div>
                    <div class="h-full w-full absolute left-0 top-0 block sm:hidden" (click)="openSearch()"></div>
                  </div>

                  <ng-container *ngIf="recentMachines.length > 0; else noMachines">
                    <div class="text-base sm:text-xl">Looking for one of these machines?</div>
                    <div
                      *ngIf="recentMachinesLoaded$ | async"
                      [@listStagger]="recentMachines.length"
                      class="grid grid-flow-row grid-cols-1 sm:grid-cols-3 md:grid-cols-6 grid-rows-3 gap-2"
                    >
                      <div
                        *ngFor="let machine of recentMachines"
                        @fadeInUp
                        matRipple
                        data-intro-key="recently-update-machines-list"
                        class="flex flex-col justify-center card text-center p-4 cursor-pointer bg-primary-light dark:bg-opacity-10 hover:bg-primary transition-colors ease-in-out"
                        (click)="gotoMachine(machine)"
                      >
                        <div>{{ machine }}</div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #noMachines>
                    <div
                      @fadeInUp
                      class="card overflow-x-auto overflow-y-hidden p-4 flex flex-col text-center sm:text-left sm:flex-row items-center gap-4"
                    >
                      <div class="text-secondary">
                        That's embarassing. Looks like there are no machines running the agent. Maybe install one?
                      </div>
                      <a
                        data-intro-key="install-agent-button"
                        class="action"
                        color="primary"
                        [routerLink]="'/home/misc/download-agent'"
                        queryParamsHandling="preserve"
                        id="get-agent"
                        mat-raised-button
                      >
                        <fa-icon [icon]="['fad', 'cloud-download']" class="mr-2" inline="true" size="lg"></fa-icon>
                        <span>Install Agent</span>
                      </a>
                    </div>
                  </ng-template>
                </ng-container>

                <ng-template #noPerm>
                  <div @fadeInUp class="flex-1 card text-center relative">
                    <div class="flex flex-col justify-center items-center content-center py-6 sm:py-12 h-full">
                      <fa-icon [icon]="['fad', 'ban']" size="5x" class="text-primary text-base sm:text-4xl"></fa-icon>
                      <div class="text-base sm:text-lg pt-4">
                        You do not have permission to view agents running on machines. If you believe this is a mistake, please talk to your
                        tenant owner
                      </div>
                    </div>
                    <div class="h-full w-full absolute left-0 top-0 block sm:hidden" (click)="openSearch()"></div>
                  </div>
                </ng-template>
              </div>
            </vex-page-layout-content>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #licenseExpired>
        <mon-expired-subscription></mon-expired-subscription>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #agentNotRequired>
    <vex-page-layout-content class="main-inner-content">
      <router-outlet></router-outlet>
    </vex-page-layout-content>
  </ng-template>
</vex-page-layout>
