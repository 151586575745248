export function friendlyDataSizeInBytes(
  byteValue: number,
  fractionDigits?: number,
  showSuffix = true
): string {
  if (byteValue == null) {
    return '';
  }

  if (fractionDigits == null) {
    fractionDigits = 2;
  }

  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;
  const TB = GB * 1024;

  if (byteValue < KB) {
    return `${byteValue.toFixed(fractionDigits)}${showSuffix ? ' B' : ''}`;
  } else if (byteValue < MB) {
    return `${(byteValue / KB).toFixed(fractionDigits)}${showSuffix ? ' KB' : ''}`;
  } else if (byteValue < GB) {
    return `${(byteValue / MB).toFixed(fractionDigits)}${showSuffix ? ' MB' : ''}`;
  } else if (byteValue < TB) {
    return `${(byteValue / GB).toFixed(fractionDigits)}${showSuffix ? ' GB' : ''}`;
  } else {
    return `${(byteValue / TB).toFixed(fractionDigits)}${showSuffix ? ' TB' : ''}`;
  }
}

export function friendlyDataSizeInBits(
  byteValue: number,
  fractionDigits?: number,
  showSuffix = true
): string {
  if (byteValue == null) {
    return '';
  }

  if (fractionDigits == null) {
    fractionDigits = 2;
  }

  const bitValue = byteValue * 8;

  const KB = 1000;
  const MB = KB * 1000;
  const GB = MB * 1000;
  const TB = GB * 1000;

  if (bitValue < KB) {
    return `${bitValue.toFixed(fractionDigits)}${showSuffix ? ' b' : ''}`;
  } else if (bitValue < MB) {
    return `${(bitValue / KB).toFixed(fractionDigits)}${showSuffix ? ' Kb' : ''}`;
  } else if (bitValue < GB) {
    return `${(bitValue / MB).toFixed(fractionDigits)}${showSuffix ? ' Mb' : ''}`;
  } else if (bitValue < TB) {
    return `${(bitValue / GB).toFixed(fractionDigits)}${showSuffix ? ' Gb' : ''}`;
  } else {
    return `${(bitValue / TB).toFixed(fractionDigits)}${showSuffix ? ' Tb' : ''}`;
  }
}
