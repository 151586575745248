import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { User } from '@auth0/auth0-spa-js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { distinctUntilChanged, map, startWith, take, tap } from 'rxjs';
import { fadeInRight40ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { RoleService } from 'src/app/core/services/role.service';
import { StateService } from 'src/app/core/services/state.service';
import { propertyByString } from 'src/app/core/utils/property-by-string';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField,
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { Role } from 'src/app/shared/models/role.model';
import { isEmpty } from 'src/app/utils/condition';

import { RoleEditComponent, RoleEditParams } from './role-edit/role-edit.component';
import { RolesDataSource } from './roles.datasource';

@UntilDestroy()
@Component({
  selector: 'mon-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  animations: [fadeInRight40ms, fadeInUp400ms, stagger20ms]
})
export class RolesComponent {
  readonly DEFAULT_PAGE_SIZE = 20;
  readonly DEFAULT_SORT_FIELD = 'name';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.SETTINGS_ROLES;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize = this.DEFAULT_PAGE_SIZE;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource: RolesDataSource = new RolesDataSource(this.roleSvc);

  columns: TableColumn<User>[] = [
    {
      label: 'ID',
      property: 'id',
      type: 'text',
      visible: false,
      hideable: false,
      cellClasses: ['truncate']
    },
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Permissions',
      property: 'permissionsD',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate', 'max-w-10'],
      sortable: false
    },
    {
      label: 'Updated',
      property: 'timestamp',
      type: 'date',
      dateFormat: 'timeAgo',
      visible: true,
      hideable: true
    },
    {
      label: 'Added',
      property: 'created',
      type: 'date',
      visible: true,
      hideable: true
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: false,
      buttonIconName: 'angle-right',
      tooltip: 'View'
    }
  ];

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        pageSize: found?.pageSize || this.DEFAULT_PAGE_SIZE,
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.pageSize = isEmpty(settings.pageSize)
        ? this.DEFAULT_PAGE_SIZE
        : settings.pageSize;
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    }),
    tap(() => this.loadRoles())
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  initialLoading$ = this.dataSource.loading$.pipe(startWith(true), take(3));

  controlsPopulated = (): boolean => !!this.paginator && !!this.sort;
  paginator: MatPaginator;
  @ViewChild(MatPaginator) set paginatorSetter(paginator: MatPaginator) {
    if (!this.paginator && !!paginator) {
      this.paginator = paginator;
      this.initControls();
    }
  }
  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  constructor(
    private dialog: MatDialog,
    private roleSvc: RoleService,
    private stateSvc: StateService
  ) {}

  initControls(): void {
    if (this.controlsPopulated()) {
      this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.tableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();

      this.paginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            this.pageIndex = page.pageIndex;
            if (this.pageSize !== page.pageSize) {
              const table = {};
              table[this.tableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            } else {
              this.loadRoles();
            }
          })
        )
        .subscribe();
    }
  }

  loadRoles(): void {
    this.dataSource.loadRoles('', this.pageSize, this.pageIndex, [
      OrderBy.SortToOrderBy({
        active: this.sortField,
        direction: this.sortDirection
      })
    ]);
  }

  get hideableColumns(): TableColumn<Role>[] {
    return this.columns.filter((c) => c.hideable);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  getValue(row: Role, property: string): string {
    return propertyByString(row, property);
  }

  modRole(role?: Role): void {
    this.dialog.open(RoleEditComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new RoleEditParams({
        roleID: role ? role.id : null,
        rolePermissions: role ? role.permissions : null,
        onSuccess: () => this.loadRoles()
      }),
      width: '600px',
      height: 'auto',
      maxWidth: '95vw',
      maxHeight: '95vh'
    });
  }
}
