<div class="timeline-toolbar-placeholder">&nbsp;</div>

<div @fadeInRight @fadeOutRight class="timeline-toolbar shadow-b py-1 border-t w-full relative">
  <ng-container [ngSwitch]="zoom$ | async">
    <!-- WEEK VIEW -->
    <ng-container *ngSwitchCase="Zoom.Week">
      <ng-container *ngIf="dates$ | async as dates">
        <div [@listStagger]="dates.length + 1" class="flex flex-row text-xs text-secondary text-right items-center h-full">
          <div *ngIf="dateRange$ | async as dateRange" class="h-full">
            <div class="invisible w-0 h-0">
              <mat-date-range-input [rangePicker]="picker" [dateFilter]="datePickerFilter">
                <input #startDate matStartDate placeholder="Start date" [value]="dateRange.begin" (dateChange)="startDateChange($event)" />
                <input #endDate matEndDate placeholder="End date" [value]="dateRange.end" />
              </mat-date-range-input>
            </div>
            <div
              @fadeInUp
              matRipple
              matTooltip="Go To Specific Date Range"
              data-intro-key="playback-calendar-button"
              class="rounded cursor-pointer flex flex-col justify-center ml-1 p-2 hover:bg-primary-light text-left h-full items-center"
              style="min-width: 40px; max-width: 40px"
              (click)="picker.open()"
            >
              <fa-icon [icon]="['fad', 'calendar-alt']" size="2x"></fa-icon>
            </div>
            <mat-date-range-picker #picker (opened)="datePickerOpened()"></mat-date-range-picker>
          </div>
          <div data-intro-key="playback-day-button" class="flex h-full">
            <div
              [@listStagger]="dates.length"
              *ngFor="let date of dates"
              [ngClass]="{ 'flex-grow': dates.length > 3, 'ml-2 last:mr-2': dates.length <= 3 }"
            >
              <div
                @fadeInUp
                *ngIf="true"
                matRipple
                class="card relative h-full cursor-pointer shrink ml-1 last:mr-1 p-1 hover:bg-primary-light"
                (click)="gotoDate(date)"
              >
                <mon-date-summary [date]="date" class="h-full max-w-xs" (click)="gotoDate(date)"></mon-date-summary>
              </div>
            </div>
          </div>
          <ng-container *ngIf="dates?.length < 1">
            <div class="px-2 text-base text-secondary">No History Data Available - Choose Different Date Range</div>
          </ng-container>
        </div>
        <div></div>
      </ng-container>
    </ng-container>

    <!-- DAY VIEW -->
    <ng-container *ngSwitchCase="Zoom.Day">
      <ng-container *ngIf="selectedDateHours$ | async as selectedDateHours">
        <div
          [@listStagger]="selectedDateHours.items.length + 1"
          class="flex flex-row text-xs text-secondary text-right items-center h-full"
        >
          <div
            @fadeInUp
            matRipple
            matTooltip="Go Back"
            data-intro-key="playback-go-back-button"
            class="rounded cursor-pointer flex flex-col justify-center ml-1 p-2 hover:bg-primary-light text-left h-full items-center"
            style="min-width: 40px; max-width: 40px"
            (click)="gotoWeek()"
          >
            <fa-icon [icon]="['fad', 'chevron-double-left']" size="lg"></fa-icon>
          </div>
          <div data-intro-key="playback-hour-button" class="flex h-full w-full">
            <div
              *ngIf="selectedDateHours.prevPageAvailable()"
              @fadeInUp
              matRipple
              matTooltip="Previous Hours"
              class="rounded cursor-pointer flex flex-col justify-center ml-1 p-2 hover:bg-primary-light text-left h-full items-center"
              style="min-width: 40px; max-width: 40px"
              (click)="prevPageOfHours()"
            >
              <fa-icon [icon]="['fad', 'chevron-left']" size="lg"></fa-icon>
            </div>
            <div
              [@listStagger]="selectedDateHours.items.length"
              *ngFor="let hour of selectedDateHours.items"
              [ngClass]="{
                'flex-1': selectedDateHours.items.length > 3,
                'ml-2 last:mr-2 shrink': selectedDateHours.items.length <= 3
              }"
            >
              <div
                @fadeInUp
                *ngIf="true"
                matRipple
                class="card relative h-full cursor-pointer ml-1 last:mr-1 p-1 hover:bg-primary-light"
                (click)="gotoHour(hour)"
              >
                <mon-hour-summary [hour]="hour" class="h-full"></mon-hour-summary>
              </div>
            </div>
            <div
              *ngIf="selectedDateHours.nextPageAvailable()"
              @fadeInUp
              matRipple
              matTooltip="More Hours"
              class="rounded cursor-pointer flex flex-col justify-center mx-1 p-2 hover:bg-primary-light text-left h-full items-center"
              style="min-width: 40px; max-width: 40px"
              (click)="nextPageOfHours()"
            >
              <fa-icon [icon]="['fad', 'chevron-right']" size="lg"></fa-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!-- HOUR VIEW -->
    <ng-container *ngSwitchCase="Zoom.Hour">
      <ng-container *ngIf="hourDetail$ | async as detail">
        <div class="flex flex-row text-xs text-secondary text-right items-center h-full">
          <div
            @fadeInUp
            matRipple
            matTooltip="Go Back"
            data-intro-key="playback-go-back-button"
            class="rounded cursor-pointer flex flex-col justify-center ml-1 p-2 hover:bg-primary-light text-left h-full items-center"
            style="min-width: 40px; max-width: 40px"
            (click)="gotoHour(undefined)"
          >
            <fa-icon [icon]="['fad', 'chevron-double-left']" size="lg"></fa-icon>
          </div>
          <div class="flex-1">
            <div class="flex flex-col h-full">
              <div class="flex flex-col w-full relative">
                <mon-buffer-timline
                  #bufferTimeline
                  [count]="detail.collectionTimestamps.length"
                  class="absolute"
                  style="left: 8px; width: calc(100% - 16px)"
                ></mon-buffer-timline>
                <mat-slider
                  #timeline
                  [min]="1"
                  [max]="detail.collectionTimestamps.length"
                  data-intro-key="playback-timeline"
                  class="w-full"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                 #ngSlider><input matSliderThumb [value]="playerIndex$ | async" (change)="timerValueChange()" #ngSliderThumb="matSliderThumb" (input)="sliding()" /></mat-slider>
                <mon-incident-timline data-intro-key="playback-timeline-incidents" [detail]="detail"></mon-incident-timline>
              </div>
              <div data-intro-key="playback-timeline-control-button" class="flex flex-row px-4 items-center flex-auto gap-2">
                <fa-icon
                  matTooltip="Speed: Slow"
                  [icon]="['fad', 'turtle']"
                  size="lg"
                  [ngClass]="{ 'text-primary': getSpeed() === Speed.Turtle }"
                  class="rounded p-1 hover:bg-hover transition-all cursor-pointer"
                  (click)="setSpeed(Speed.Turtle)"
                ></fa-icon>
                <fa-icon
                  matTooltip="Speed: Fast"
                  [icon]="['fad', 'rabbit-fast']"
                  size="lg"
                  [ngClass]="{ 'text-primary': getSpeed() === Speed.Rabbit }"
                  class="rounded p-1 hover:bg-hover transition-all cursor-pointer"
                  (click)="setSpeed(Speed.Rabbit)"
                ></fa-icon>
                <ng-container *ngIf="playerState$ | async as playerState">
                  <div
                    [matTooltip]="playerState === ServiceState.Started ? 'Pause' : 'Play'"
                    class="p-1 opacity-50 hover:opacity-100 transition-all ease-in-out cursor-pointer"
                    (click)="togglePlay()"
                  >
                    <div class="play-button" [ngClass]="{ active: playerState === ServiceState.Started }">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                </ng-container>
                <div class="flex flex-row justify-between w-full">
                  <div>
                    {{
                      detail.collectionTimestamps[timeline.step > 0 ? Math.min(timeline.step, detail.collectionTimestamps.length) - 1 : 0]
                        | date: 'h:mm:ss a'
                    }}
                    /
                    {{ detail.collectionTimestamps[detail.collectionTimestamps.length - 1] | date: 'h:mm:ss a' }}
                  </div>
                  <div>{{ Math.min(timeline.step, detail.collectionTimestamps.length) }} / {{ detail.collectionTimestamps.length }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
