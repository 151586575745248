import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { BehaviorSubject, iif } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { LayoutService } from 'src/@vex/services/layout.service';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { ServiceService } from 'src/app/core/services/service.service';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { ProcessInfoComponent } from 'src/app/shared/components/process-info/process-info.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { DisplayDensity } from 'src/app/shared/enums/table-density.enum';
import { RenderValue } from 'src/app/shared/interfaces/render-value';
import { Process } from 'src/app/shared/models/process.model';
import { Service, ServiceState } from 'src/app/shared/models/service.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { deepCopy } from 'src/app/utils/clone';
import { isEmpty } from 'src/app/utils/condition';

@UntilDestroy()
@Component({
  selector: 'mon-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class ProcessesComponent implements OnInit, OnDestroy {
  readonly DEFAULT_SORT_FIELD = 'cpuPercentD';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'desc';

  tableID = tableNames.TASK_MANAGER_PROCESSES;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource = new MatTableDataSource<Process>();

  sessionFilter: number[] = [];
  selection = new SelectionModel<Process>(true, []);
  searchCtrl = new UntypedFormControl();
  DisplayDensity = DisplayDensity;
  ServiceState = ServiceState;
  running = false;
  _highlightPID = -1;
  currCPUUtilization = 0;
  currTriggers: Array<Trigger> = [];

  columns: TableColumn<Process>[] = [
    {
      label: 'Checkbox',
      property: 'collapsed',
      type: 'button',
      visible: true,
      hideable: false
    },
    {
      label: 'Checkbox',
      property: 'checkbox',
      type: 'checkbox',
      visible: true,
      hideable: false
    },
    {
      label: 'Name',
      property: 'nameD',
      type: 'text',
      visible: true,
      hideable: false,
      canCopy: true,
      canQuery: true,
      headerClasses: [],
      cellClasses: ['max-w-20', 'font-medium', 'truncate']
    },
    {
      label: 'Command',
      property: 'commandLine',
      type: 'text',
      visible: false,
      hideable: true,
      canCopy: true,
      cellClasses: ['max-w-20', 'truncate']
    },
    {
      label: 'PID',
      property: 'processID',
      type: 'number',
      visible: true,
      hideable: true,
      headerClasses: ['deep-justify-end'],
      cellClasses: ['text-right', 'truncate']
    },
    {
      label: 'CPU',
      property: 'cpuPercentD',
      type: 'number',
      visible: true,
      hideable: true,
      headerClasses: ['deep-justify-end'],
      cellClasses: ['text-right', 'justify-end', 'truncate']
    },
    {
      label: 'Memory',
      property: 'memorySize',
      display: 'memorySizeD',
      type: 'number',
      visible: true,
      hideable: true,
      headerClasses: ['deep-justify-end'],
      cellClasses: ['text-right', 'justify-end', 'truncate']
    },
    {
      label: 'Disk',
      property: 'disk',
      display: 'diskD',
      type: 'number',
      visible: true,
      hideable: true,
      headerClasses: ['deep-justify-end'],
      cellClasses: ['text-right', 'justify-end', 'truncate']
    },
    {
      label: 'Network',
      property: 'network',
      display: 'networkD',
      type: 'number',
      visible: true,
      hideable: true,
      headerClasses: ['deep-justify-end'],
      cellClasses: ['text-right', 'justify-end', 'truncate']
    },
    {
      label: 'User',
      property: 'username',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: false
    }
  ];

  private _processUsers = new BehaviorSubject<Map<number, string>>(
    new Map<number, string>()
  );
  processUsers$ = this._processUsers
    .asObservable()
    .pipe(distinctUntilChanged((prev, curr) => deepEqual(prev, curr)));

  agentStats$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) => {
      this.dataSource.data = [];

      return iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      );
    }),
    untilDestroyed(this),
    filter((agent) => !!agent),
    tap((agent) => {
      this._processUsers.next(agent.stats.processUsers);
      this.currCPUUtilization = agent.stats.summary.cpu.utilizationPercent;
      this.currTriggers = agent.hasTrigger('Processes.CPUPercent');
    }),
    map((agent) => agent.stats),
    filter((stats) => stats && stats.processes.size > 0),
    tap((stats) => {
      if (this.running) {
        return;
      }
      this.running = true;
      const filteredProcesses =
        this.sessionFilter.length > 0
          ? new Map(
              [...stats.processes.values()]
                .filter((p) => this.sessionFilter.indexOf(p.sessionID) >= 0)
                .map((p) => [`${p.processID}`, p])
            )
          : stats.processes;

      this.updateDataSource(filteredProcesses);
      this.running = false;
    })
  );

  densitySettings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => settings.consoleUI.config.display.density)
  );

  sortSettings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    })
  );

  _hasQueryAbility = new BehaviorSubject<RenderValue<boolean>>({ value: false });
  hasQueryAbility$ = this._hasQueryAbility.asObservable();

  checkQueryAbility$ = this.tenantSvc.getSettings('encrypted.openai.apiKey').pipe(
    tap((res) => {
      this._hasQueryAbility.next({
        value: !!res.get('encrypted.openai.apiKey') ? true : false
      });
    })
  );

  private _filtering = new BehaviorSubject<boolean>(false);
  public filtering$ = this._filtering.asObservable();

  isMobile$ = this.layoutSvc.isMobile$;

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  userFilter: MatSelect;
  @ViewChild(MatSelect) set userFilterSetter(userFilter: MatSelect) {
    this.userFilter = userFilter;
    this.processUsers$.pipe(first());
  }

  get hideableColumns(): TableColumn<Process>[] {
    return this.columns.filter((c) => c.hideable);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private layoutSvc: LayoutService,
    private stateSvc: StateService,
    private tenantSvc: TenantService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private processSvc: ProcessService,
    private serviceSvc: ServiceService
  ) {
    const nav = this.router.getCurrentNavigation();
    this._highlightPID = nav?.extras.state?.highlightPID || -1;

    this.searchCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => this._filtering.next(true))
      )
      .subscribe({
        next: (value) => {
          if (!this.dataSource) {
            return;
          }
          value = value.trim();
          value = value.toLowerCase();
          this.dataSource.filter = value;
          this._filtering.next(false);
        }
      });
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        tap((p) => {
          if (p && p.session) {
            this.sessionFilter = p.session
              .split(',')
              .filter((s: string) => !isNaN(+s)) // make sure value is a number
              .map((s: string) => +s)
              .filter((v, i, s) => s.indexOf(v) === i); // make sure numbers are unique
          }
        })
      )
      .subscribe();

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'memorySizeD':
          return item.memorySize;
        case 'diskD':
          return item.disk;
        case 'networkD':
          return item.network;
        default: {
          if (typeof item[property] === 'string') {
            return item[property].toLocaleLowerCase();
          }
          return item[property];
        }
      }
    };

    this.dataSource.filterPredicate = (data, f) => {
      const filterNum = parseFloat(f);
      if (!isNaN(filterNum)) {
        if (data.processID === filterNum) {
          return true;
        }
      }
      if (
        data.name.toLowerCase().indexOf(f) >= 0 ||
        data.commandLine.toLowerCase().indexOf(f) >= 0 ||
        data.username.toLowerCase().indexOf(f) >= 0
      ) {
        return true;
      }
      return false;
    };
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  initControls(): void {
    this.dataSource.sort = this.sort;

    this.sort.sortChange
      .pipe(
        untilDestroyed(this),
        tap((sort) => {
          const table = {};
          table[this.tableID] = {
            sortField: sort.active,
            sortDirection: sort.direction
          };
          this.stateSvc.updateCurrentUserSettings({
            consoleUI: {
              table
            }
          });
        })
      )
      .subscribe();
  }

  updateDataSource(agentProcesses: Map<string, Process>): void {
    if (!agentProcesses) {
      return;
    }

    const windowsIdleProcess = agentProcesses.get('0');

    if (windowsIdleProcess) {
      windowsIdleProcess.cpuPercent = Math.round(100 - this.currCPUUtilization);
    }

    if (this.dataSource.data.length === 0) {
      this.dataSource.data = Array.from(agentProcesses.values()).slice();
    } else {
      for (let i = 0; i < this.dataSource.data.length; ) {
        const oldProcess = this.dataSource.data[i];
        const oldPID = `${oldProcess.processID}`;
        const newProcess = agentProcesses.get(oldPID);

        if (newProcess != null) {
          // highlight process if need be
          if (this.currTriggers && newProcess.processID !== 0) {
            const found = !!this.currTriggers.find((t) => {
              const f = !!t.conditions.find((c) => c.isMet(newProcess.cpuPercentD));
              return f;
            });
            newProcess['highlight'] = found;
          }

          if (oldProcess != null) {
            newProcess.collapsed = oldProcess.collapsed; // keep state of collapse
            if (!deepEqual(oldProcess, newProcess)) {
              Object.assign(oldProcess, newProcess);
            }
            agentProcesses.delete(oldPID);
          }
          i++;
        } else {
          this.dataSource.data.splice(i, 1);
          this.selection.deselect(oldProcess);
        }
      }

      agentProcesses.forEach((p) => {
        if (p) {
          this.dataSource.data.push(p);
        }
      });

      this.dataSource.sort = this.sort;
    }
  }

  toggleRowDetail(event: Event, row: Process): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    row.collapsed = !row.collapsed;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(change: MatCheckboxChange): void {
    if (change.checked) {
      this.dataSource.filteredData.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  killProcess(event: Event, process: Process): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    const agentID = this.agentLivePollerSvc.liveAgent.id;

    if (process.name !== 'ccagent.exe') {
      this.processSvc.killByPid(agentID, process.processID).subscribe({
        error: (err) => {
          console.warn(err);
        }
      });

      return;
    }

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'microchip',
        title: 'Kill Process',
        confirmationText:
          'You will lose connection to this machine. Did you want to kill CommandCTRL?',
        onConfirm: () => {
          this.processSvc.killByPid(agentID, process.processID).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }

  killSelected(processes: Process[]): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    const killEmAll = () => {
      this.processSvc.killByPid(agentID, ...processes.map((p) => p.processID)).subscribe({
        error: (err) => {
          console.warn(err);
        }
      });
    };

    if (processes.filter((p) => p.name === 'ccagent.exe').length > 0) {
      this.dialog.open(ConfirmDialogComponent, {
        restoreFocus: false,
        data: {
          icon: 'microchip',
          title: 'Kill Process',
          confirmationText:
            'You will lose connection to this machine. Did you want to kill CommandCTRL?',
          onConfirm: () => {
            killEmAll();
          },
          onCancel: () => {}
        }
      });

      return;
    } else {
      killEmAll();
    }
  }

  stopService(service: Service): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    if (service.serviceName !== 'ccagent') {
      this.serviceSvc.stop(agentID, service.serviceName).subscribe({
        error: (err) => {
          console.warn(err);
        }
      });

      return;
    }

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'cog',
        title: 'Stop Service',
        confirmationText:
          'You will lose connection to this machine. Did you want to shutdown CommandCTRL?',
        onConfirm: () => {
          this.serviceSvc.stop(agentID, service.serviceName).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }

  highlightPID(pid: number): void {
    this._highlightPID = this._highlightPID === pid ? -1 : pid;
  }

  copy(tooltip: MatTooltip, value: string): void {
    tooltip.hide();
    setTimeout(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
    }, 50);
    setTimeout(() => {
      tooltip.hide();
    }, 1250);
    setTimeout(() => {
      tooltip.message = 'Copy';
    }, 1500);
    this.clipboard.copy(value);
  }

  queryProcess(process: Process): void {
    this.dialog.open(ProcessInfoComponent, {
      restoreFocus: false,
      width: '600px',
      minHeight: '300px',
      data: {
        process
      }
    });
  }

  userSelectionChange(sessionIDs: number[]): void {
    this.sessionFilter = sessionIDs;

    this.router.navigate([], {
      queryParams: {
        session: sessionIDs.join(',') || null
      },
      queryParamsHandling: 'merge'
    });
  }
}
