import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callOnce',
  pure: true
})
export class CallOncePipe implements PipeTransform {
  transform(fn: ((...args: unknown[]) => void) | string, ...ps: unknown[]): unknown {
    return typeof fn === 'function' ? fn(...ps) : fn;
  }
}
