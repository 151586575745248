import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DiskVolumeData } from 'src/app/shared/interfaces/disk-volumes.interface';
import { EventLog } from 'src/app/shared/interfaces/event-log.interface';
import {
  GPRReportData,
  GPReportScopeType
} from 'src/app/shared/interfaces/group-policy-result-report.interface';
import { IPConfigData } from 'src/app/shared/interfaces/ipconfig.interface';
import { LogCollection } from 'src/app/shared/interfaces/log-collection.interface';
import { PingTestData } from 'src/app/shared/interfaces/ping-test.interface';
import { SpeedtestData } from 'src/app/shared/interfaces/speed-test.interface';
import { TracerouteData } from 'src/app/shared/interfaces/trace-route-test';

import { ApiService, TagResponse } from './api.service';

@Injectable({ providedIn: 'root' })
export class DiagnosticsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getLastDiskVolumesResult(agentID: string): Observable<TagResponse<DiskVolumeData>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/disk/volumes/lastresult`;
    return this.http.get<TagResponse<DiskVolumeData>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getLastDiskVolumesResult response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getLastDiskVolumesResult');
        return throwError(() => err);
      })
    );
  }
  runDiskVolumes(agentID: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/disk/volumes`;
    return this.http.post<TagResponse<string>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.runDiskVolumes response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runDiskVolumes');
        return throwError(() => err);
      })
    );
  }

  runOHM(agentID: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/ohm`;
    return this.http.post<TagResponse<string>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.runOHM response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runOHM');
        return throwError(() => err);
      })
    );
  }

  runSpeedTest(agentID: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/speedtest`;
    return this.http.post<TagResponse<string>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.runSpeedTest response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runSpeedTest');
        return throwError(() => err);
      })
    );
  }

  getLastSpeedTestResult(agentID: string): Observable<TagResponse<SpeedtestData>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/speedtest/lastresult`;
    return this.http.get<TagResponse<SpeedtestData>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getLastSpeedTestResult response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getLastSpeedTestResult');
        return throwError(() => err);
      })
    );
  }

  runPing(agentID: string, sourceIP: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/ping`;
    const params = { sourceIP };
    return this.http.post<TagResponse<string>>(url, params).pipe(
      tap(() => this.log('fetched DiagnosticService.runPing response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runPing');
        return throwError(() => err);
      })
    );
  }

  getLastPingTestResult(agentID: string): Observable<TagResponse<PingTestData>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/ping/lastresult`;
    return this.http.get<TagResponse<PingTestData>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getLastPingTestResult response')),
      map((r) => (r ? new TagResponse<PingTestData>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getLastPingTestResult');
        return throwError(() => err);
      })
    );
  }

  getLastTracerouteResult(agentID: string): Observable<TagResponse<TracerouteData>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/traceroute/lastresult`;
    return this.http.get<TagResponse<TracerouteData>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getLastTracerouteResult response')),
      map((r) => (r ? new TagResponse<TracerouteData>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getLastTracerouteResult');
        return throwError(() => err);
      })
    );
  }

  runTraceroute(agentID: string, destination: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/traceroute`;
    const params = { destination };
    return this.http.post<TagResponse<string>>(url, params).pipe(
      tap(() => this.log('fetched DiagnosticService.runTraceroute response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runTraceroute');
        return throwError(() => err);
      })
    );
  }

  getLastIPConfigResult(agentID: string): Observable<TagResponse<IPConfigData>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/ipconfig/lastresult`;
    return this.http.get<TagResponse<IPConfigData>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getLastIPConfigResult response')),
      map((r) => (r ? new TagResponse<IPConfigData>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getLastIPConfigResult');
        return throwError(() => err);
      })
    );
  }

  runIPConfig(agentID: string, showAll: boolean): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/ipconfig`;
    const params = { showAll };
    return this.http.post<TagResponse<string>>(url, params).pipe(
      tap(() => this.log('fetched DiagnosticService.runIPConfig response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runIPConfig');
        return throwError(() => err);
      })
    );
  }

  getListOfGPRReport(agentID: string): Observable<TagResponse<GPRReportData[]>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/gpreport/listall`;
    return this.http.get<TagResponse<GPRReportData[]>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getListOfGPRReport response')),
      map((r) => (r ? new TagResponse<GPRReportData[]>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getListOfGPRReport');
        return throwError(() => err);
      })
    );
  }

  downloadGPRFile(agentID: string, name: string): void {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/gpreport/download/${name}`;

    this.http.get(url, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute('download', name + '.html');
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  // 0 = None, 1 = Computer, 2 = User
  runGPResultReport(
    agentID: string,
    user: string,
    scope: GPReportScopeType
  ): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/gpreport`;
    const params = { user, scope };
    return this.http.post<TagResponse<string>>(url, params).pipe(
      tap(() => this.log('fetched DiagnosticService.runGPResultReport response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runGPResultReport');
        return throwError(() => err);
      })
    );
  }

  getListOfLogCollections(agentID: string): Observable<TagResponse<LogCollection[]>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/logs/all`;
    return this.http.get<TagResponse<LogCollection[]>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getListOfLogCollections response')),
      map((r) => (r ? new TagResponse<LogCollection[]>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getListOfLogCollections');
        return throwError(() => err);
      })
    );
  }

  downloadLogCollection(agentID: string, name: string): void {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/logs/download/${name}`;

    this.http.get(url, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute('download', name + '.zip');
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  runLogCollection(agentID: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/logs`;
    return this.http.post<TagResponse<string>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.runLogCollection response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runLogCollection');
        return throwError(() => err);
      })
    );
  }

  setDebugMode(agentID: string, duration: number): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/logmode`;
    const params = { duration };
    return this.http.post<TagResponse<string>>(url, params).pipe(
      tap(() => this.log('fetched DiagnosticService.setDebugMode response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.setDebugMode');
        return throwError(() => err);
      })
    );
  }

  runUpdateEventLogTypes(agentID: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/eventlog/types`;
    return this.http.post<TagResponse<string>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.runUpdateEventLogTypes response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runUpdateEventLogTypes');
        return throwError(() => err);
      })
    );
  }

  getListOfEventTypes(agentID: string): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/eventlog/types/lastresult`;
    return this.http.get<TagResponse<string>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getListOfEventTypes response')),
      map((r) => (r ? new TagResponse<string>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getListOfEventTypes');
        return throwError(() => err);
      })
    );
  }

  runArchiveEventLogType(
    agentID: string,
    logType: string
  ): Observable<TagResponse<string>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/eventlog`;
    const params = { logType };
    return this.http.post<TagResponse<string>>(url, params).pipe(
      tap(() => this.log('fetched DiagnosticService.runUpdateEventLogTypes response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<TagResponse<string>>('DiagnosticService.runUpdateEventLogTypes');
        return throwError(() => err);
      })
    );
  }

  downloadEventLog(agentID: string, name: string): void {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/eventlog/download/${name}`;

    this.http.get(url, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute('download', name + '.zip');
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  getListOfEvents(agentID: string): Observable<TagResponse<EventLog[]>> {
    const url = `${this.apiUrl}/agent/${agentID}/diagnostic/eventlog/all`;
    return this.http.get<TagResponse<EventLog[]>>(url, {}).pipe(
      tap(() => this.log('fetched DiagnosticService.getListOfEvents response')),
      map((r) => (r ? new TagResponse<EventLog[]>(r) : undefined)),
      catchError((err) => {
        this.handleError('DiagnosticService.getListOfEvents');
        return throwError(() => err);
      })
    );
  }
}
