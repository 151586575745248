<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>
    <form autocomplete="off" (ngSubmit)="submit()" [formGroup]="form">
      <div class="flex justify-start items-center content-center" mat-dialog-title>
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">{{ script.name }} Feedback</h2>

        <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border"></mat-divider>
      <mat-dialog-content class="flex flex-col">
        <mat-form-field>
          <mat-label>Feedback</mat-label>
          <textarea
            formControlName="feedback"
            class="min-h-20"
            matInput
            #detailInput
            [placeholder]="'Please provide feedback about ' + script.name"
            cdkFocusInitial
          ></textarea>
          <mat-error *ngIf="form.controls.feedback.errors?.required">Feedback can not be empty </mat-error>
          <mat-error *ngIf="form.controls.feedback.errors?.maxlength">Feedback can not be more than 2000 characters </mat-error>
        </mat-form-field>
      </mat-dialog-content>

      <mat-dialog-actions>
        <div class="flex justify-end w-full">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-button type="submit" [disabled]="spin || form.invalid">SUBMIT</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
