import { friendlyDataSizeInBits, friendlyDataSizeInBytes } from 'src/app/utils/data-size';

import { Updatable } from '../interfaces/updatable.interface';
import { ProcessConnection } from './processconnection.model';
import { Service } from './service.model';

export class Process implements Updatable<Process> {
  public cpuPercent: number;
  public description: string;
  public memorySize: number;
  public name: string;
  public commandLine: string;
  public parentProcessID: number;
  public processID: number;
  public sessionID: number;
  public status: string;
  public username: string;
  public io: IO;
  public services: Array<Service> = new Array<Service>();
  public connections: Array<ProcessConnection> = new Array<ProcessConnection>();

  // extra properties for view purposes
  _collapsed = true;
  get collapsed(): boolean {
    return this._collapsed;
  }
  set collapsed(value: boolean) {
    this._collapsed = value;
  }

  // display names
  get cpuPercentD(): number {
    return Math.round(this.cpuPercent);
  }
  get memorySizeD(): string {
    return (Math.round(this.memorySize) / 1024).toLocaleString() + ' K';
  }
  get disk(): number {
    return this.io
      ? this.io.writeTransferRate + this.io.readTransferRate + this.io.otherTransferRate
      : 0;
  }
  get diskD(): string {
    return `${friendlyDataSizeInBytes(this.disk, 1)}/s`;
  }
  get network(): number {
    if (!this.connections || this.connections?.length === 0) {
      return 0;
    }
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return this.connections.map((c) => c.rxRate + c.txRate).reduce(reducer);
  }
  get networkD(): string {
    return `${friendlyDataSizeInBits(this.network)}ps`;
  }

  private _nameD: string;
  get nameD(): string {
    if (this._nameD !== undefined) return this._nameD;

    if (this.processID === 0) {
      this._nameD = 'System Idle Process';

      return this._nameD;
    }

    let subName = '';
    if (this.services && this.services.length > 0) {
      subName = this.services[0].displayName;
      if (this.services.length > 1) {
        subName += ' [' + this.services.length + ']';
      }
    }

    if (this.name !== 'svchost.exe') {
      this._nameD = subName !== '' ? subName : this.name;
    } else {
      this._nameD = 'Service Host' + (subName !== '' ? ': ' + subName : '');
    }

    return this._nameD;
  }

  // helper calls
  get hasServices(): boolean {
    return this.services.length > 0 && this.processID !== 0;
  }
  get hasConnections(): boolean {
    return this.connections != null;
  }

  public constructor(init?: Partial<Process>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    if (this.services && this.services.length > 0) {
      this.services = this.services
        .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
        .map((s) => new Service(s));
    }
    if (this.connections) {
      this.connections = this.connections.map((c) => new ProcessConnection(c));
    }
  }

  update(input: Partial<Process>): this {
    return Object.assign(this, input);
  }

  equals(c?: Partial<Process>): boolean {
    if (this === c) {
      return true;
    }

    const compareServices = (a: Array<Service>, b: Array<Service>): boolean => {
      if (a === b) {
        return true;
      }
      if (a && !b) {
        return false;
      }
      if (!a && b) {
        return false;
      }
      if (a.length !== b.length) {
        return false;
      }

      for (let i = 0; i < a.length; i++) {
        if (!a[i].equals(b[i])) {
          return false;
        }
      }

      return true;
    };

    const compareConnections = (
      a: Array<ProcessConnection>,
      b: Array<ProcessConnection>
    ): boolean => {
      if (a === b) {
        return true;
      }
      if (a && !b) {
        return false;
      }
      if (!a && b) {
        return false;
      }
      if (a.length !== b.length) {
        return false;
      }

      for (let i = 0; i < a.length; i++) {
        if (!a[i].equals(b[i])) {
          return false;
        }
      }

      return true;
    };

    if (
      this.cpuPercent === c.cpuPercent &&
      this.description === c.description &&
      this.processID === c.processID &&
      this.memorySize === c.memorySize &&
      this.name === c.name &&
      this.parentProcessID === c.parentProcessID &&
      this.processID === c.processID &&
      this.status === c.status &&
      this.username === c.username &&
      compareServices(this.services, c.services) &&
      compareConnections(this.connections, c.connections)
    ) {
      return true;
    }

    return false;
  }
}

export class Processes implements Updatable<Processes> {
  public timestamp: Date;
  public processes: Array<Process>;

  constructor(init?: Partial<Processes>) {
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);

    if (this.processes) {
      this.processes = this.processes.map((p) => new Process(p));
    }
  }

  update(input: Partial<Processes>): this {
    return Object.assign(this, input);
  }
}

declare interface IO {
  readOperationCount: number;
  writeOperationCount: number;
  otherOperationCount: number;
  readTransferCount: number;
  writeTransferCount: number;
  otherTransferCount: number;
  writeTransferRate: number;
  readTransferRate: number;
  otherTransferRate: number;
}
