<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>
    <form autocomplete="off" (ngSubmit)="save()" [formGroup]="form">
      <div class="flex justify-start items-center content-center" mat-dialog-title>
        <!-- <img *ngIf="user?.imageSrc" [src]="user?.imageSrc" class="avatar mr-5"> -->
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">{{ form.get('email').value || 'Invite User' }}</h2>

        <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border"></mat-divider>

      <mat-dialog-content class="flex flex-col">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput [readonly]="user?.id" cdkFocusInitial #emailInput />
          <fa-icon [icon]="['fad', 'envelope']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
          <mat-error *ngIf="form.controls.email.errors?.required">We need an email address to invite </mat-error>
          <mat-error *ngIf="form.controls.email.errors?.pattern">We need a proper email address to invite </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="allRoles$ | async as allRoles" class="example-chip-list">
          <mat-label>Roles</mat-label>
          <mat-chip-grid #chipList aria-label="Role selection">
            <mat-chip-row *ngFor="let role of user.getRolesAsArray()" [removable]="role != 'owner'" (removed)="removeRole(role)">
              <div class="flex items-center">
                <img
                  *ngIf="role === 'owner' || rolesMap.get(role)?.system"
                  matTooltip="System Role"
                  class="mr-2 w-4 select-none"
                  src="assets/img/demo/logo.svg"
                />
                <div>
                  {{ role === 'owner' ? 'Owner' : rolesMap.get(role)?.name || role }}
                </div>
              </div>
              <fa-icon matChipRemove *ngIf="role != 'owner'" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth]> </fa-icon>
            </mat-chip-row>
            <input
              placeholder="assign role..."
              #roleInput
              [formControl]="selectableCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addRole($event, allRoles)"
            />
          </mat-chip-grid>
          <mat-autocomplete #auto (optionSelected)="selected($event)" [autoActiveFirstOption]="true" matAutocompleteTrigger>
            <mat-option *ngFor="let role of filteredRoles$ | async" [value]="role.id" [disabled]="spin">
              <div class="flex items-center">
                <img *ngIf="role.system" matTooltip="System Role" class="mr-2 w-4 select-none" src="assets/img/demo/logo.svg" />
                <div>{{ role.name }}</div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <fa-icon [icon]="['fad', 'shield-alt']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
          <!-- <mat-error>Select at least one role</mat-error> -->
          <!-- <mat-hint>Can only contain 3 chips max</mat-hint> -->
        </mat-form-field>

        <div @fadeInRight *ngIf="err" class="card text-white text-opacity-80 bg-red flex justify-between items-center">
          <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
          <pre class="p-2">
            <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="closeError()"></fa-icon>
          </pre>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="flex items-center justify-between">
        <ng-container *ngIf="user">
          <button *ngIf="user.id; else showKeepOpen" (click)="delete()" color="warn" mat-button type="button" [disabled]="spin"
            >DELETE</button
          >
          <ng-template #showKeepOpen>
            <mat-checkbox formControlName="keepOpen" color="primary">Keep Open</mat-checkbox>
          </ng-template>
        </ng-container>
        <div class="flex-1"></div>
        <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
        <button color="primary" mat-button type="submit" [disabled]="spin || form.invalid">
          {{ user?.id ? 'SAVE CHANGES' : 'INVITE' }}
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
