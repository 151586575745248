import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ContainerModule } from '../../directives/container/container.module';
import { SecondaryToolbarComponent } from './secondary-toolbar.component';

@NgModule({
  declarations: [SecondaryToolbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    ContainerModule,
    FontAwesomeModule
  ],
  exports: [SecondaryToolbarComponent]
})
export class SecondaryToolbarModule {}
