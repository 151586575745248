export const commonTags: string[] = [
  'active-directory',
  'app-v',
  'apps',
  'appvolumes',
  'audit',
  'bandwidth',
  'bash',
  'cache',
  'citrix',
  'computer',
  'cpu',
  'cse',
  'dem',
  'desktop',
  'directory',
  'dll',
  'docker',
  'drives',
  'event-log',
  'extension',
  'file-system',
  'files',
  'folder',
  'fslogix',
  'gpo',
  'gpresult',
  'group-policy',
  'hard-drives',
  'hdx',
  'horizon',
  'hyperv',
  'ica',
  'igel',
  'internet',
  'intranet',
  'ip',
  'ivanti',
  'lan',
  'latency',
  'log',
  'logoff',
  'logon',
  'machine',
  'mac-address',
  'mapped-drives',
  'memory',
  'mobile',
  'modules',
  'mq',
  'netscalar',
  'network',
  'page-file',
  'pid',
  'physical-machine',
  'powershell',
  'printers',
  'process',
  'profile',
  'ram',
  'rdp',
  'rds',
  'redirection',
  'remote',
  'rsop',
  'security',
  'session',
  'troubleshooting',
  'user-profile',
  'user',
  'vdi',
  'video',
  'virtual-machine',
  'vmware',
  'wan',
  'windows',
  'wvd',
  'xenapp',
  'xendesktop'
];
