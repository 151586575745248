import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  iif,
  map,
  mergeMap,
  of,
  pairwise,
  retry,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { LayoutService } from 'src/@vex/services/layout.service';
import { ScriptService } from 'src/app/core/services/script.service';
import { ScriptResult, ScriptResultTarget } from 'src/app/shared/interfaces/script.interface';
import { UNDEFINED } from 'src/app/utils/rxjs';

@Component({
  selector: 'mon-script-result',
  templateUrl: './script-result.component.html',
  styleUrls: ['./script-result.component.scss'],
  animations: [stagger40ms, fadeInUp400ms, fadeInRight400ms]
})
export class ScriptResultComponent implements OnInit {
  private _pullResults = new BehaviorSubject<void>(undefined);
  pullResults$ = this._pullResults.asObservable();
  private _pullingMoreResults = new BehaviorSubject<boolean>(true);
  pullingMoreResults$ = this._pullingMoreResults.asObservable();
  private resultLimit = 20;
  private resultPageIndex = 0;
  resultTotal = -1;

  private _pullMoreTargets = new BehaviorSubject<void>(undefined);
  private pullMoreTargets$ = this._pullMoreTargets.asObservable();
  private _pullingTargets = new BehaviorSubject<boolean>(true);
  pullingTargets$ = this._pullingTargets.asObservable();
  private targetLimit = 10;
  private targetPageIndex = 0;
  targetTotal = -1;

  private recentResultsMap = new Map<string, ScriptResult>();
  recentResults = new Array<ScriptResult>();
  recentResults$ = this.pullResults$.pipe(
    filter(
      () =>
        this.resultTotal === -1 ||
        this.resultLimit * this.resultPageIndex < this.resultTotal
    ),
    tap(() => this._pullingMoreResults.next(true)),
    debounceTime(500),
    mergeMap(() =>
      this.scriptSvc
        .getAllResults(null, this.resultLimit, this.resultPageIndex, [
          { path: 'timestamp', dir: 2 }
        ])
        .pipe(
          tap(() => this.resultPageIndex++),
          tap((result) => (this.resultTotal = result.count)),
          map((r) => r.items)
        )
    ),
    tap((results) => results.forEach((r) => this.recentResultsMap.set(r.id, r))),
    tap(() => (this.recentResults = [...this.recentResultsMap.values()])),
    tap((results) => {
      if (this._resultLastNanoUpdate < 1 && results.length > 0) {
        // should only be done once
        this._resultLastNanoUpdate = results.reduce((a, b) =>
          a.lastUpdateUnixNano > b.lastUpdateUnixNano ? a : b
        ).lastUpdateUnixNano;

        this._resultListener.next();
      }
    }),
    tap(() => this._pullingMoreResults.next(false))
  );

  private _selectedLoaded = new BehaviorSubject<boolean>(false);
  readonly selectedLoaded$ = this._selectedLoaded.asObservable();

  private _selectedResultID = new BehaviorSubject<string>(undefined);
  get selectedResultID(): string {
    return this._selectedResultID.value;
  }
  readonly selectedResultID$ = this._selectedResultID
    .asObservable()
    .pipe(distinctUntilChanged());

  private targetsMap = new Map<string, ScriptResultTarget>();
  targets = new Array<ScriptResultTarget>();
  private _refreshTargets = new BehaviorSubject<void>(undefined);
  private refreshTargets$ = this._refreshTargets.asObservable();
  readonly retrieveTargets$ = this.selectedResultID$.pipe(
    distinctUntilChanged(),
    tap(() => {
      this.targetsMap.clear();
      this.targets = [];
      this.targetPageIndex = 0;
      this.targetTotal = -1;
    }),
    mergeMap((resultID) => this.refreshTargets$.pipe(map(() => resultID))),
    mergeMap((resultID) =>
      resultID
        ? this.pullMoreTargets$.pipe(
            mergeMap(() =>
              this.scriptSvc.getResult(resultID).pipe(
                tap((result) => this.recentResultsMap.set(result.id, result)),
                tap(() => (this.recentResults = [...this.recentResultsMap.values()])),
                map((result) => result.id)
              )
            ),
            filter(
              () =>
                this.targetTotal === -1 ||
                this.targetLimit * this.targetPageIndex < this.targetTotal
            ),
            tap(() => this._pullingTargets.next(true)),
            map(() => resultID),
            debounceTime(500),
            switchMap((resultID) => {
              return this.scriptSvc
                .getResultTargets(resultID, null, this.targetLimit, this.targetPageIndex)
                .pipe(
                  tap(() => this.targetPageIndex++),
                  tap((result) => (this.targetTotal = result.count)),
                  map((result) => result.items)
                );
            }),
            tap((items) => items.forEach((i) => this.targetsMap.set(i.agentID, i))),
            tap(
              () =>
                (this.targets = [...this.targetsMap.values()].sort((a, b) =>
                  b.agentID < a.agentID ? 1 : -1
                ))
            ),
            tap(() => this._pullingTargets.next(false)),
            tap((targets) => {
              if (targets.length === 1) {
                this.selectTarget(resultID, targets[0].agentID);
              }
            })
          )
        : UNDEFINED
    )
  );

  private _selectedTargetID = new BehaviorSubject<string>(undefined);
  get selectedTargetID(): string {
    return this._selectedTargetID.value;
  }
  readonly selectedTargetID$ = this._selectedTargetID
    .asObservable()
    .pipe(distinctUntilChanged());

  private _detailRefresh = new BehaviorSubject<void>(undefined);
  readonly detailRefresh$ = this._detailRefresh.asObservable();

  readonly targetDetail$ = combineLatest([
    this.selectedResultID$,
    this.selectedTargetID$,
    this.detailRefresh$
  ]).pipe(
    startWith([undefined, undefined]),
    map(([resultID, targetID]) => {
      return { resultID, targetID };
    }),
    pairwise(),
    tap(([p, c]) => {
      if (p.targetID !== c.targetID) {
        this._selectedLoaded.next(false);
      }
    }),
    switchMap(([, c]) =>
      !c.resultID || !c.targetID
        ? EMPTY
        : this.scriptSvc.getResultTargetDetail(c.resultID, c.targetID).pipe(
            map((scriptResultTarget) => {
              return { resultID: c.resultID, target: scriptResultTarget };
            })
          )
    ), // get more detail
    tap(() => this._selectedLoaded.next(true))
  );

  private _searching = new BehaviorSubject<boolean>(false);
  readonly searching$ = this._searching.asObservable();

  _searchInput = new BehaviorSubject<string>(undefined);
  searchInput$ = this._searchInput.asObservable().pipe(
    debounceTime(250),
    map((search) => search?.toLowerCase()),
    distinctUntilChanged()
  );

  search$ = this.searchInput$.pipe(
    tap(() => this._searching.next(true)),
    switchMap((search) => iif(() => !!search, of([]), UNDEFINED)),
    tap(() => this._searching.next(false))
  );

  searchResult$ = combineLatest([this.searching$, this.search$]).pipe(
    map(([searching, results]) => {
      return { searching, results };
    })
  );

  isMobile$ = this.layoutSvc.isMobile$;

  private _resultLastNanoUpdate = 0;
  private _resultListener = new Subject<void>();
  resultListener$ = this._resultListener.asObservable().pipe(
    switchMap(() => {
      return UNDEFINED.pipe(
        map(() => this._resultLastNanoUpdate),
        switchMap((resultLastNanoUpdate) => {
          return this.scriptSvc.getAllResultsListen(resultLastNanoUpdate).pipe(
            tap((results) => {
              if (results && results.length > 0) {
                this._resultLastNanoUpdate = results.reduce((a, b) =>
                  a.lastUpdateUnixNano > b.lastUpdateUnixNano ? a : b
                ).lastUpdateUnixNano;

                results.forEach((r) => this.recentResultsMap.set(r.id, r));
                this.recentResults = [...this.recentResultsMap.values()].sort(
                  (a, b) => b.created.getTime() - a.created.getTime()
                );

                this._refreshTargetList.next();
              }
            })
          );
        }),
        tap(() => {
          throw 1;
        }),
        retry({
          delay: (error, _retryCount) => of(true).pipe(delay(error === 1 ? 0 : 10_000))
        })
      );
    })
  );

  _refreshTargetList = new Subject<void>();
  targetListener$ = this._refreshTargetList.asObservable().pipe(
    switchMap(() => this.selectedResultID$),
    filter((resultID) => !!resultID),
    switchMap((resultID) => this.scriptSvc.getResult(resultID)),
    switchMap((result) => {
      return combineLatest([
        of(result),
        this.scriptSvc.getResultTargetByIDs(result.id, [...this.targetsMap.keys()])
      ]);
    }),
    tap(([, targets]) => {
      if (targets && targets.length > 0) {
        targets.forEach((t) => this.targetsMap.set(t.agentID, t));
        this.targets = [...this.targetsMap.values()].sort((a, b) =>
          b.agentID < a.agentID ? 1 : -1
        );
        this._detailRefresh.next();
      }
    })
  );

  @ViewChild('resultsWrapper') resultsWrapper: ElementRef;
  @ViewChild('results') results: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private layoutSvc: LayoutService,
    private scriptSvc: ScriptService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const taskID = params.get('taskID') || undefined;
      this._selectedResultID.next(taskID);

      const targetID = params.get('targetID');
      this._selectedTargetID.next(targetID);
    });
  }

  selectResult(resultID: string): void {
    this.router.navigate(['/home/tools/scripts/results', resultID]);
  }

  selectTarget(resultID: string, targetID: string): void {
    this.router.navigate(['/home/tools/scripts/results', resultID, targetID]);
  }

  clearSelectedResult(): void {
    this.router.navigate(['/home/tools/scripts/results']);
  }

  onFilter(search: string): void {
    this._searchInput.next(search);
  }

  trackResultItemByFn(_index: number, result: ScriptResult): string {
    return `${result.id}`;
  }

  trackTargetItemByFn(_index: number, target: ScriptResultTarget): string {
    return `${target.agentID}`;
  }

  onScrollDown(_event: IInfiniteScrollEvent): void {
    this.pullingMoreResults$
      .pipe(
        take(1),
        tap((pulling) => {
          if (pulling) {
            return;
          }
          if (this.resultPageIndex * this.resultLimit < this.resultTotal) {
            this._pullResults.next();
          }
        })
      )
      .subscribe();
  }
}
