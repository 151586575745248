import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { propertyByString } from 'src/app/core/utils/property-by-string';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { AgentBlock } from 'src/app/shared/models/agent-block.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { isEmpty } from 'src/app/utils/condition';
import { AddBlockComponent, AddBlockParams } from './add-block/add-block.component';
import { BlockedMachinesDataSource } from './blocked-machines.datasource';

@UntilDestroy()
@Component({
  selector: 'mon-blocked-machines',
  templateUrl: './blocked-machines.component.html',
  styleUrls: ['./blocked-machines.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class BlockedMachinesComponent {
  readonly DEFAULT_SORT_FIELD = 'agentID';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.INVENTORY_BLOCKED_MACHINES;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource: BlockedMachinesDataSource = new BlockedMachinesDataSource(this.tenantSvc);
  filter = '';
  selection = new SelectionModel<AgentBlock>(true, []);
  searchCtrl = new UntypedFormControl();

  columns: TableColumn<AgentBlock>[] = [
    {
      label: 'Checkbox',
      property: 'checkbox',
      type: 'checkbox',
      visible: true,
      hideable: false
    },
    {
      label: 'Machine',
      property: 'agentID',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Machine ID',
      property: 'machineID',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'IP',
      property: 'ip',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Block Until',
      property: 'blockUntilD',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'buttonArray',
      visible: true,
      hideable: false,
      buttons: [
        {
          icon: 'times',
          tooltip: 'Remove Block',
          fn: this.removeBlock.bind(this),
          buttonClasses: ['hover:bg-red-light'],
          iconClasses: ['text-red']
        }
      ]
    }
  ];

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    }),
    tap(() => this.loadBlockedMachines())
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  controlsPopulated = (): boolean => !!this.sort && !!this.filterInput;
  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }
  filterInput: ElementRef;
  @ViewChild('filterInput') set filterInputSetter(e: ElementRef) {
    if (!this.filterInput) {
      this.filterInput = e;
      this.initControls();
    }
  }

  constructor(
    private dialog: MatDialog,
    private tenantSvc: TenantService,
    private stateSvc: StateService
  ) {
    this.searchCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        filter(() => this.filter !== this.filterInput.nativeElement.value),
        tap(() => {
          this.filter = this.filterInput.nativeElement.value;
          this.loadBlockedMachines();
        })
      )
      .subscribe();
  }

  initControls(): void {
    if (this.controlsPopulated()) {
      this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.tableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();
    }
  }

  loadBlockedMachines(): void {
    this.selection.clear();
    this.dataSource.loadBlockedMachines(this.filter, [
      OrderBy.SortToOrderBy({
        active: this.sortField,
        direction: this.sortDirection
      })
    ]);
  }

  get hideableColumns(): TableColumn<AgentBlock>[] {
    return this.columns.filter((c) => c.hideable);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  getValue(row: AgentBlock, property: string): string {
    return propertyByString(row, property);
  }

  masterToggle(change: MatCheckboxChange): void {
    if (change.checked) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  addBlock(): void {
    this.dialog.open(AddBlockComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new AddBlockParams({
        onSuccess: () => {
          this.stateSvc.refreshSelectedAgent();
          this.loadBlockedMachines();
        }
      })
    });
  }

  removeBlock(agents: AgentBlock[]): void {
    if (!(agents instanceof Array)) {
      agents = [agents];
    }

    this.tenantSvc
      .removeBlockedAgents(agents.map((a) => a.id))
      .pipe(
        tap(() => {
          this.stateSvc.refreshSelectedAgent();
          this.loadBlockedMachines();
        })
      )
      .subscribe();
  }
}
