import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, Subscription, combineLatest, iif, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  retry,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { LayoutService } from 'src/@vex/services/layout.service';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { LoaderService, Options } from 'src/app/core/services/loader.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { RemoteService } from 'src/app/core/services/remote.service';
import { StateService } from 'src/app/core/services/state.service';
import { SystemService } from 'src/app/core/services/system.service';
import { TaskService } from 'src/app/core/services/task.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { RemoteSessionComponent } from 'src/app/shared/components/dataview/remote-session/remote-session.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { EnvironmentVariablesDisplayComponent } from 'src/app/shared/components/dialogs/environment-variables-display/environment-variables-display.component';
import { ComponentDisplayConfig } from 'src/app/shared/components/display-config-button/display-config-button.component';
import { DisplayDensity } from 'src/app/shared/enums/table-density.enum';
import { RemoteMSRAData } from 'src/app/shared/interfaces/remote.interface';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { RemoteSessionType } from 'src/app/shared/models/remote-session.model';
import { Session } from 'src/app/shared/models/session.model';
import { TargetState } from 'src/app/shared/models/task.model';
import { deepCopy } from 'src/app/utils/clone';
import { UNDEFINED } from 'src/app/utils/rxjs';

import { OS } from 'src/app/shared/models/system.model';
import { SessionsDataSource } from './sessions.datasource';

export const CurrentUsersDisplayConfig = new ComponentDisplayConfig({
  id: 'widget.current-users',
  name: 'Current Sessions',
  show: true,
  fields: []
});

@UntilDestroy()
@Component({
  selector: 'mon-current-users',
  templateUrl: './current-users.component.html',
  styleUrls: ['./current-users.component.scss'],
  animations: [fadeInUp400ms]
})
export class CurrentUsersComponent implements OnInit {
  OS = OS;
  _displayConfig: ComponentDisplayConfig;
  @Input() set config(config: ComponentDisplayConfig) {
    this._displayConfig = config;
  }

  @Output() selectionChange = new EventEmitter<Session[]>();

  DisplayDensity = DisplayDensity;
  channelSub: Subscription;
  msraData: RemoteMSRAData;
  os: OS;
  platformFamily = '';

  initialSessionSelected: number[] = [];

  agent$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );

  agentStats$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    tap((agent) => (this.os = agent.system.os)),
    tap((agent) => {
      this.os = agent.system.os;
      this.platformFamily = agent.system.platformFamily;
    }),
    map((agent) => agent.stats)
  );

  isMobile$ = this.layoutSvc.isMobile$;

  // dataSource: SessionsDataSource = new SessionsDataSource(of(getMockStats()));
  dataSource: SessionsDataSource = new SessionsDataSource(
    this.agentStats$.pipe(
      map((stats) => {
        const selected = new Map<string, Session>();
        stats.summary.system.sessions.forEach((s) => {
          const check = this.initialSessionSelected.indexOf(s.id) >= 0;
          if (check) {
            selected.set(s.station, s);
          }
          s['checkbox'] = check;
        });

        this.initialSessionSelected = []; // reset it

        this.selection.selected.forEach((s) => selected.delete(s.station));

        if (selected.size > 0) {
          this.selection.select(...selected.values());
        }

        return stats;
      })
    )
  );
  selection = new SelectionModel<Session>(true, []);

  RemoteSessionType = RemoteSessionType;

  currentDensity = DisplayDensity.Default;

  columns: TableColumn<Session>[] = [
    { label: 'ID', property: 'id', type: 'number', visible: false, hideable: true },
    {
      label: 'Checkbox',
      property: 'checkbox',
      type: 'checkbox',
      conditionalVisible: () => this.dataSource.length > 1,
      hideable: false,
      tooltip: 'Filter Dashboard by User',
      cellClasses: ['w-1']
    },
    {
      label: 'Session',
      property: 'displayUsername',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'max-w-[14rem]', 'truncate']
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: true
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loaderSvc: LoaderService,
    private layoutSvc: LayoutService,
    private stateSvc: StateService,
    private taskSvc: TaskService,
    private remoteSvc: RemoteService,
    private dialog: MatDialog,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private systemSvc: SystemService,
    private pubSubSvc: PubSubWsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        tap((p) => {
          if (p && p.session) {
            this.initialSessionSelected = p.session
              .split(',')
              .filter((s: string) => !isNaN(+s)) // make sure value is a number
              .map((s: string) => +s)
              .filter((v, i, s) => s.indexOf(v) === i); // make sure numbers are unique
          }
        })
      )
      .subscribe();

    this.selection.changed
      .pipe(
        untilDestroyed(this),
        tap((m) => {
          this.selectionChange.emit(m.source.selected);
        }),
        map((m) => m.source.selected.map((s) => s.id)),
        tap((sessionIDs) => {
          this.router.navigate([], {
            queryParams: {
              session: sessionIDs.join(',') || null
            },
            queryParamsHandling: 'merge'
          });
        })
      )
      .subscribe();

    this.loadSessions();
  }

  loadSessions(): void {
    this.dataSource.loadSessions();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  get visibleColumns(): string[] {
    return this.columns
      .filter(
        (column) =>
          column.visible ||
          (column.conditionalVisible ? column.conditionalVisible() : false)
      )
      .map((column) => column.property);
  }

  displayEnvironmentVariables(session: Session): void {
    this.dialog.open(EnvironmentVariablesDisplayComponent, {
      restoreFocus: false,
      width: '600px',
      data: session
    });
  }

  logout(session: Session): void {
    if (!this.agentLivePollerSvc.liveAgent) {
      return;
    }
    const agentID = this.agentLivePollerSvc.liveAgent.id;
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      width: '250px',
      data: {
        icon: 'sign-out',
        title: 'Logout',
        confirmationText: `Would you like to logout ${session.username}?`,
        onCancel: () => {},
        onConfirm: () => {
          this.systemSvc.logoffSession(agentID, session.id, session.username).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        }
      }
    });
  }

  logoutAll(): void {
    if (!this.agentLivePollerSvc.liveAgent) {
      return;
    }
    const agentID = this.agentLivePollerSvc.liveAgent.id;
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'portal-exit',
        title: 'Logout All',
        confirmationText: 'Would you like to logout all users?',
        onCancel: () => {},
        onConfirm: () => {
          this.systemSvc.logoffAllSessions(agentID).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        }
      }
    });
  }

  assist(session: Session, remoteType: RemoteSessionType): void {
    if (!this.agentLivePollerSvc.liveAgent) {
      return;
    }
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    let target;

    let options: Options = null;

    let openMessage = `Connecting to ${session.username}...`;

    if (remoteType === RemoteSessionType.Teleconference) {
      target = window.open(`/remote/connecting`, '_blank');
      openMessage = `Conferencing to ${session.username}...`;
    }

    this.loaderSvc.open(openMessage);

    const expireIn = new Date();
    expireIn.setMinutes(expireIn.getMinutes() + 1);

    let taskUpdateUnixNano = 0;

    this.remoteSvc
      .createConnection(agentID, session.id, session.username, remoteType)
      .pipe(
        switchMap((remoteSession) => {
          switch (remoteType) {
            case RemoteSessionType.Teleconference:
              options = {
                closeAfterTimeout: 0,
                closeButtonHidden: true,
                component: RemoteSessionComponent
              };

              target.location.href = `/remote/${remoteSession.id}?useToken=true`;
              return EMPTY;
            default:
              if (remoteType === RemoteSessionType.MSRA) {
                this.listenForChannelResponse(remoteSession.channelID);
              }
              return UNDEFINED.pipe(
                switchMap(() =>
                  of(taskUpdateUnixNano).pipe(
                    // on retries, we make sure to use the updated time
                    switchMap((t) => this.taskSvc.getTaskListen(remoteSession.taskID, t)),
                    tap((task) => {
                      if (!task) {
                        throw 'still waiting...';
                      } else if (!task.isDone) {
                        taskUpdateUnixNano = task.lastUpdateUnixNano;
                        throw 'still waiting...';
                      }
                    })
                  )
                ),
                retry({
                  delay: (_error, _retryCount) => of(true)
                }),
                map((task) => {
                  return {
                    taskID: task.id,
                    agentID: remoteSession.agentID
                  };
                })
              );
          }
        }),
        switchMap((data) =>
          this.taskSvc
            .getTaskAgentStatus(data.taskID, data.agentID)
            .pipe(switchMap((target) => combineLatest([of(data.taskID), of(target)])))
        ),
        tap(([taskID, target]) => {
          if (target.state === TargetState.TargetSuccess) {
            options = {
              header: remoteType === 'msra' ? 'Connected' : 'Agent Ready',
              closeButtonHidden: false,
              component: RemoteSessionComponent,
              componentData: {
                sessionData: { id: taskID, remoteType, data: this.msraData }
              }
            };
          } else {
            options = {
              header: 'Connection Failed',
              closeButtonHidden: false
            };
          }
          this.loaderSvc.close(options);
        }),
        catchError((err: HttpErrorResponse) => {
          this.loaderSvc.close({
            header: 'Connection Failed',
            closeButtonHidden: false,
            content: parseError(err) || 'Error connecting'
          });
          return throwError(() => err);
        }),
        finalize(() => {
          if (this.channelSub) {
            this.channelSub.unsubscribe();
          }
          this.loaderSvc.close(options);
        })
      )
      .subscribe();
  }

  openMachineInNewTab(machine: string): void {
    window.open(`/home/dashboard?machineID=${machine.toLowerCase()}`, '_blank');
  }

  listenForChannelResponse(channelID: string): void {
    this.channelSub = this.agent$
      .pipe(
        switchMap((agent) =>
          this.pubSubSvc
            .listen<Packet>(
              `$remote-msra:web.${this.stateSvc.tenant.id}.${agent.id}-${channelID}.response`
            )
            .pipe(
              tap((packet) => {
                if (packet.type === PacketType.RemoteMsraPacket) {
                  this.msraData = JSON.parse(<string>packet.content);
                } else if (packet.type === PacketType.CompletePacket) {
                  this.channelSub.unsubscribe();
                } else if (packet.type === PacketType.ErrorPacket) {
                  this.channelSub.unsubscribe();
                }
              })
            )
        )
      )
      .subscribe();
  }
}
