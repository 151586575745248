import { KeyValue } from '@angular/common';

import {
  ActionCauseType,
  ActionEffectType,
  SystemEventCauseType,
  SystemEventEffectType
} from '../enums/action.enum';
import { Updatable } from '../interfaces/updatable.interface';

export class Action implements Updatable<Action> {
  public id: string;
  public name: string;
  public description: string;
  public cause: ActionCause;
  public effect: ActionEffect;
  public apply: ActionApply;
  public timestamp: Date;
  public created: Date;

  constructor(init?: Partial<Action>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);
    this.created = new Date(init.created);
  }

  update(input: Partial<Action>): this {
    return Object.assign(this, input);
  }
}

export class ActionCause implements Updatable<ActionCause> {
  public type: ActionCauseType;
  public systemCause: SystemEventCauseType;
  public triggerID: string;

  constructor(init?: Partial<ActionCause>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }

  update(input: Partial<ActionCause>): this {
    return Object.assign(this, input);
  }
}

export class ActionEffect implements Updatable<ActionEffect> {
  public type: ActionEffectType;
  public systemEffect: SystemEventEffectType;
  public scriptID: string;
  public customArg: string;
  public args: Array<KeyValue<string, string>>;

  constructor(init?: Partial<ActionEffect>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }

  update(input: Partial<ActionEffect>): this {
    return Object.assign(this, input);
  }
}

export class ActionApply implements Updatable<ActionApply> {
  public machines: string;
  public users: string;

  constructor(init?: Partial<ActionApply>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }

  update(input: Partial<ActionApply>): this {
    return Object.assign(this, input);
  }
}
