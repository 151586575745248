<ng-container *ngIf="loading$ | async; else ready">
  <div @fadeInUp class="p-6 pt-0 flex flex-col w-96">
    <div class="py-4 flex items-center justify-center space-x-2">
      <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
      <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
      <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
    </div>
  </div>
</ng-container>
<ng-template #ready>
  <ng-container *ngIf="inviteError$ | async as error; else confirm">
    <div @fadeInUp class="w-80 p-6 pt-0 flex flex-col">
      <h3 class="text-red text-center pb-5">{{ error }}</h3>
      <button (click)="goToSignin()" color="primary" mat-raised-button type="button">GO HOME</button>
    </div>
  </ng-container>
  <ng-template #confirm>
    <div class="pb-2 max-w-30">
      <ng-container *ngIf="tenantInvite$ | async as invite">
        <blockquote @fadeInRight class="m-0">
          <div class="flex flex-col items-center justify-center">
            <div>Confirm Email</div>
            <div class="font-bold">{{ invite.email }}</div>
          </div>
        </blockquote>
        <blockquote @fadeInRight *ngIf="invite.tenantID" class="m-0 bg-green-100 border-green-500 text-green-600">
          <div class="flex items-center justify-center gap-4">
            <div>
              <fa-icon [icon]="['fad', 'envelope-open-text']" size="2xl" matTooltip="You are invited"></fa-icon>
            </div>
            <div>
              You have been invited by <span class="font-bold">{{ invite.friendlyTenantName }}</span
              >. However you must also setup your own account and tenant before joining
              <span class="font-bold">{{ invite.friendlyTenantName }}</span
              >.
            </div>
          </div>
        </blockquote>
        <mon-subscription
          password-step
          tenant-step
          detail-step
          [invite-key]="invite.inviteKey"
          [invite-email]="invite.email"
        ></mon-subscription>
      </ng-container>
    </div>
  </ng-template>
</ng-template>
