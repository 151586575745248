import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[ngLet]', exportAs: 'ngLet' })
export class LetDirective<T = unknown> {
  private view: EmbeddedViewRef<NgLetContext<T>>;

  @Input()
  set ngLet(value: T) {
    // if embeadded view doesn't exist yet create it (only once)

    // if (!!this.view) {
    //   return;
    // }

    this.vcRef.clear();
    this.view = this.vcRef.createEmbeddedView(this.templateRef, {
      $implicit: value,
      ngLet: value
    });
  }

  constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<NgLetContext<T>>
  ) {}
}

export declare class NgLetContext<T = unknown> {
  $implicit: T;
  ngLet: T;
}
