<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex justifty-start items-center" mat-dialog-title>
      <div class="flex-1 flex flex-col">
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">{{ detailGroup.get('email').value || 'New Contact' }}</h2>
      </div>

      <button class="text-secondary -mt-2 -mr-2" (click)="close()" mat-icon-button type="button" [disabled]="spin">
        <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <ng-container *ngIf="user$ | async as user">
      <form
        autocomplete="off"
        [formGroup]="detailGroup"
        (submit)="user.hasPerms([permission.Contact_Update, permission.Contact_Create], 'any') ? save() : close()"
      >
        <mat-dialog-content class="flex flex-col overflow-visible">
          <div class="flex flex-col gap-2 mt-4">
            <mat-form-field fxFlex="auto">
              <mat-label>First Name</mat-label>
              <input formControlName="firstName" matInput #firstName />
              <mat-error *ngIf="detailGroup.controls.firstName.errors?.maxlength">Name can not be more than 150 characters</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="auto">
              <mat-label>Last Name</mat-label>
              <input formControlName="lastName" matInput #lastName />
              <mat-error *ngIf="detailGroup.controls.lastName.errors?.maxlength">Name can not be more than 150 characters</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="auto">
              <mat-label>Email</mat-label>
              <input formControlName="email" matInput #email />
              <mat-error *ngIf="detailGroup.controls.email.errors?.required">We need a proper email address</mat-error>
              <mat-error *ngIf="detailGroup.controls.email.errors?.pattern">We need a proper email address</mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="sendBillingEmail" color="primary">Send Billing Email</mat-checkbox>
            <mat-checkbox formControlName="sendAccountEmail" color="primary">Send Account Email</mat-checkbox>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions>
          <div class="w-full flex justify-end">
            <button
              *hasPerm="[permission.Contact_Update, permission.Contact_Create]; has: 'any'; else: readonly"
              [disabled]="detailGroup.invalid"
              color="primary"
              mat-raised-button
              matStepperNext
              >SAVE CHANGES</button
            >
            <ng-template #readonly>
              <button color="primary" mat-raised-button matStepperNext>CLOSE</button>
            </ng-template></div
          >
        </mat-dialog-actions>
      </form>
    </ng-container>
  </div>
</mon-spinner>
