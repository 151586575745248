import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  Subject,
  delay,
  filter,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  retry,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { StateService } from 'src/app/core/services/state.service';
import { TaskService } from 'src/app/core/services/task.service';
import {
  GPRReportData,
  GPReportScopeType
} from 'src/app/shared/interfaces/group-policy-result-report.interface';
import { RenderValue } from 'src/app/shared/interfaces/render-value';
import { Agent } from 'src/app/shared/models/agent.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

@UntilDestroy()
@Component({
  selector: 'mon-group-policy-report',
  templateUrl: './group-policy-report.component.html',
  styleUrls: ['./group-policy-report.component.scss']
})
export class GroupPolicyReportComponent {
  keys = Object.keys;
  gpReportScopeType = GPReportScopeType;

  defaultUsername: string;
  defaultComputer: string;

  private _gpResultReport = new BehaviorSubject<string>(undefined);
  readonly gpResultReport$ = this._gpResultReport.asObservable();

  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running
    .asObservable()
    .pipe(tap((running) => (this.spin = running)));

  spin = true;
  lastRan = new Date();

  form = new FormGroup({
    scope: new FormControl(1, [Validators.required]),
    username: new FormControl('', [Validators.required])
  });

  rvAgent$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$.pipe(
          filter((a) => !!a),
          take(1)
        ),
        UNDEFINED
      )
    ),
    tap((a) => {
      // default input values
      const domain = a.system.domain;
      const host = a.system.hostname;
      this.defaultComputer = `${domain}\\${host}`;
      this.defaultUsername = a.stats.summary.system.sessions.find(
        (s) => s.username
      )?.username;
    }),
    map<Agent, RenderValue<Agent>>((a) => {
      a ? this.form.enable() : this.form.disable();
      return {
        value: a
      };
    })
  );

  _refreshRecent = new Subject<void>();
  recent$ = this._refreshRecent.asObservable().pipe(
    startWith(undefined),
    tap(() => (this.spin = true)),
    switchMap(() => this.rvAgent$),
    filter((rv) => !!rv.value),
    switchMap((rv) => this.diagnosticsSvc.getListOfGPRReport(rv.value.id)),
    map((response) => {
      if (response?.tag) {
        const sorted = response.tag.sort((a, b) => b.time.localeCompare(a.time));
        return sorted.slice(0, 10);
      }
      return new Array<GPRReportData>();
    }),
    tap(() => (this.spin = false))
  );

  constructor(
    private stateSvc: StateService,
    private taskSvc: TaskService,
    private agentLivePollerSvc: AgentLivePollerService,
    private diagnosticsSvc: DiagnosticsService
  ) {
    this.form.controls.scope.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((scope) => {
          switch (scope) {
            // case GPReportScopeType.All:
            //   this.form.controls.username.setValue(
            //     this.defaultUsername || this.defaultComputer
            //   );
            //   break;
            case GPReportScopeType.Computer:
              this.form.controls.username.setValue(this.defaultComputer);
              break;
            case GPReportScopeType.User:
              this.form.controls.username.setValue(this.defaultUsername);
              break;
          }
        })
      )
      .subscribe();
  }

  public downloadReport(report: GPRReportData): void {
    this.diagnosticsSvc.downloadGPRFile(report.agent_id, report.name);
  }

  public runReport(agentID: string): void {
    const username = this.form.controls.username.value;
    const scope = this.form.controls.scope.value;
    this._running.next(true);
    this._gpResultReport.next('');
    this.lastRan = undefined;

    this.diagnosticsSvc
      .runGPResultReport(agentID, username, scope)
      .pipe(
        switchMap((response) => {
          const taskID = response.tag;
          let taskUpdateUnixNano = 0;

          return this.taskSvc.getTask(taskID).pipe(
            tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
            mergeMap(() =>
              of(taskUpdateUnixNano).pipe(
                // on retries, we make sure to use the updated time
                switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
                tap((task) => {
                  if (!task) {
                    throw 'still waiting...';
                  } else if (!task.isDone) {
                    taskUpdateUnixNano = task.lastUpdateUnixNano;
                    throw 'still waiting...';
                  }
                })
              )
            ),
            retry({
              delay: (_error, _retryCount) => of(true)
            }),
            delay(2000),
            tap(() => this._refreshRecent.next()),
            finalize(() => this._running.next(false))
          );
        })
      )
      .subscribe({
        error: (err) => {
          console.warn(err);
          this.spin = false;
        }
      });
  }

  trackByID(_index: number, report: GPRReportData): string {
    return report.time;
  }
}
