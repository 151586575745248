import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Estimate } from 'src/app/shared/models/subscription.model';

@Component({
  selector: 'mon-estimate',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent {
  @Input() header: string;
  @Input() estimate: Estimate;
}
