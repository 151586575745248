import { Permissions, PermissionValue } from './permissions.model';

export enum ScriptType {
  PowerShell = 'powershell',
  Bash = 'bash'
}

export enum ScriptLocation {
  Public = 'public',
  Private = 'private'
}

export class ScriptChangelog {
  timestamp: Date;
  version: string;
  changelog: string[];

  constructor(init?: Partial<ScriptChangelog>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
    this.timestamp = init.timestamp ? new Date(init.timestamp) : undefined;
  }
}

export class Script {
  created: Date;
  latestTimestamp: Date;
  installedTimestamp: Date;
  publisher: string;
  installRefID: string;
  id: string;
  name: string;
  type: ScriptType;
  location: ScriptLocation;
  latestVersion: string;
  installVersion: string;
  description: string;
  detail: string;
  latestFile: string;
  installFile: string;
  parameters: unknown;
  permissions: Permissions;
  tags: string[];
  recommended: boolean;
  installs: number;
  likes: number;
  disabled: boolean;
  archived: boolean;
  userLiked: boolean;
  userPermission: PermissionValue;

  get installVersionD(): string {
    if (this.hasUpgrade) {
      return `v${this.installVersion} => v${this.latestVersion}`;
    } else {
      return `v${this.installVersion || this.latestVersion}`;
    }
  }

  get hasUpgrade(): boolean {
    if (this.installVersion && this.installVersion !== this.latestVersion) {
      return true;
    }
    return false;
  }

  get uniqueID(): string {
    return `${this.location}:${this.id}`;
  }

  constructor(init?: Partial<Script>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    this.userPermission = new PermissionValue({ value: +init.userPermission });

    this.created = init.created ? new Date(init.created) : undefined;
    this.latestTimestamp = init.latestTimestamp
      ? new Date(init.latestTimestamp)
      : undefined;
    this.installedTimestamp = init.installedTimestamp
      ? new Date(init.installedTimestamp)
      : undefined;
  }
}
