import { Component, EventEmitter, Host, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { CheckboxGroupComponent } from '../checkbox-group.component';

@Component({
  selector: 'mon-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() value: string;
  @Input() label: string;
  @Output() change = new EventEmitter<MatCheckboxChange>();

  constructor(@Host() public checkboxGroup: CheckboxGroupComponent) {}
}
