import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermGuard } from 'src/app/core/guards/perm.guard';
import { Permission } from 'src/app/shared/enums/permission.enum';

import { RolesComponent } from './roles/roles.component';
import { SettingsComponent } from './settings.component';
import { SiteComponent } from './site/site.component';
import { TenantComponent } from './tenant/tenant.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    data: {
      toolbarShadowEnabled: true,
      containerEnabled: true
    },
    children: [
      {
        path: '',
        redirectTo: 'tenant',
        pathMatch: 'full'
      },
      {
        title: 'Settings: Tenant - CommandCTRL',
        path: 'tenant',
        component: TenantComponent
      },
      {
        title: 'Settings: Users - CommandCTRL',
        path: 'users',
        component: UsersComponent,
        data: { requiresPerm: [Permission.User_List] },
        canActivate: [PermGuard]
      },
      {
        title: 'Settings: Roles - CommandCTRL',
        path: 'roles',
        component: RolesComponent,
        data: { requiresPerm: [Permission.Role_List] },
        canActivate: [PermGuard]
      },
      {
        title: 'Settings: Site - CommandCTRL',
        path: 'site',
        component: SiteComponent,
        data: { requiresPerm: [Permission.SiteAPIKey_List] },
        canActivate: [PermGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}
