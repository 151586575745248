import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import Centrifuge from 'centrifuge';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  retry,
  switchMap,
  take,
  tap
} from 'rxjs';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { TaskService } from 'src/app/core/services/task.service';
import { PacketComplete, PacketStart } from 'src/app/shared/interfaces/packet.interface';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { deepCopy } from 'src/app/utils/clone';
import { UNDEFINED } from 'src/app/utils/rxjs';

@Component({
  selector: 'mon-ipconfig',
  templateUrl: './ipconfig.component.html',
  styleUrls: ['./ipconfig.component.scss']
})
export class IpconfigComponent {
  private _ipconfigResult = new BehaviorSubject<string>(undefined);
  readonly ipconfigResult$ = this._ipconfigResult.asObservable();

  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running.asObservable();

  agent$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );

  readonly channelName$ = this.agent$.pipe(
    map((a) => `$diagnostic:web.${this.stateSvc.tenant.id}.${a.id}.ipconfigResponse`)
  );

  readonly channelHistory$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.history(channel, {
        limit: 20,
        reverse: true
      })
    ),
    catchError(() => {
      return of(<Centrifuge.HistoryResult>{
        publications: [],
        offset: -1,
        epoch: ''
      });
    }),
    map((history) => {
      let result = undefined;
      let start: Date;
      let complete: Date;

      for (let i = history.publications.length - 1; i >= 0; i--) {
        const packet = history.publications[i].data as Packet;

        if (packet.type === PacketType.StartPacket) {
          const s: PacketStart = JSON.parse(<string>packet.content);
          result = '';
          complete = undefined;
          start = s.timestamp;
        } else if (start) {
          if (packet.type === PacketType.IPConfigPacket) {
            result += packet.content;
          } else if (packet.type === PacketType.CompletePacket) {
            const s: PacketComplete = JSON.parse(<string>packet.content);
            complete = s.timestamp;
            this._running.next(false);
          } else if (packet.type === PacketType.ErrorPacket) {
            this._running.next(false);
          }
        } else {
          continue;
        }
      }

      if (start) {
        this.lastRan = start;
        if (!complete) {
          this._running.next(true);
        }
      }

      return result;
    }),
    switchMap((result) => {
      return iif(
        () => !!result,
        of(result),
        this.agent$.pipe(
          switchMap((agent) => this.diagnosticsService.getLastIPConfigResult(agent.id)),
          map((response) => {
            if (response.tag) {
              this.lastRan = response.tag.timestamp;
              return response.tag.results;
            }

            return undefined;
          })
        )
      );
    }),
    tap((result) => this._ipconfigResult.next(result))
  );

  readonly channelListener$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          const result = this._ipconfigResult.value || '';
          if (packet.type === PacketType.IPConfigPacket) {
            this._ipconfigResult.next(result + packet.content);
          } else if (packet.type === PacketType.StartPacket) {
            this._running.next(true);
            this._ipconfigResult.next('');
            const s: PacketStart = JSON.parse(<string>packet.content);
            this.lastRan = s.timestamp;
            return;
          } else if (packet.type === PacketType.CompletePacket) {
            this._running.next(false);
            return;
          } else if (packet.type === PacketType.ErrorPacket) {
            this._running.next(false);
            return;
          }
        }),
        tap(() =>
          setTimeout(
            () =>
              (this.output.nativeElement.scrollTop =
                this.output.nativeElement.scrollHeight),
            100
          )
        )
      )
    )
  );

  readonly latestResult$ = UNDEFINED.pipe(
    tap(() => (this.spin = true)),
    switchMap(() => this.channelHistory$),
    tap((result) => this._ipconfigResult.next(result)),
    finalize(() => (this.spin = false))
  );

  spin = true;
  lastRan: Date;
  showAll = true;

  @ViewChild('output', { static: false }) output: ElementRef;

  constructor(
    private clipboard: Clipboard,
    private stateSvc: StateService,
    private fb: UntypedFormBuilder,
    private diagnosticsService: DiagnosticsService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private taskSvc: TaskService,
    private pubSubSvc: PubSubWsService
  ) {}

  public run(agentID: string): void {
    //const ipToTrace = this.form.controls.showAll.value;
    this._running.next(true);
    this._ipconfigResult.next('');
    this.lastRan = undefined;

    this.diagnosticsService
      .runIPConfig(agentID, this.showAll)
      .pipe(
        switchMap((response) => {
          const taskID = response.tag;
          let taskUpdateUnixNano = 0;

          return this.taskSvc.getTask(taskID).pipe(
            tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
            mergeMap(() =>
              of(taskUpdateUnixNano).pipe(
                // on retries, we make sure to use the updated time
                switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
                tap((task) => {
                  if (!task) {
                    throw 'still waiting...';
                  } else if (!task.isDone) {
                    taskUpdateUnixNano = task.lastUpdateUnixNano;
                    throw 'still waiting...';
                  }
                })
              )
            ),
            retry({
              delay: (_error, _retryCount) => of(true)
            }),
            finalize(() => this._running.next(false))
          );
        })
      )
      .subscribe({
        error: (err) => {
          console.warn(err);
          this.spin = false;
        }
      });
  }

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.hide();
    setTimeout(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
    }, 50);
    setTimeout(() => {
      tooltip.hide();
    }, 1250);
    setTimeout(() => {
      tooltip.hide();
      tooltip.message = '';
    }, 1500);
    this.clipboard.copy(text);
  }
}
