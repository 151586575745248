import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { tap } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';
import { ContextUser } from 'src/app/shared/models/context-user.model';
import {
  TenantSwitchComponent,
  TenantSwitchParams
} from 'src/app/view/settings/tenant/tenant-switch/tenant-switch.component';

import { trackById } from '../../../utils/track-by';
import { PopoverRef } from '../../popover/popover-ref';
import { MenuItem } from '../interfaces/menu-item.interface';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: IconName;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent {
  items: MenuItem[];

  user$ = this.stateSvc.currentUser$.pipe(
    tap((u) => {
      this.items = [
        {
          id: '1',
          icon: 'user-circle',
          label: 'My Profile',
          description: u.email,
          colorClass: 'text-teal',
          route: '/home/me',
          callback: (): void => {},
          visible: (): boolean => true
        },
        {
          id: '2',
          icon: 'sync-alt',
          label: 'Switch Tenant',
          description: 'Change To A Different Tenant',
          colorClass: 'text-primary',
          route: undefined,
          callback: (): void => {
            this.dialog.open(TenantSwitchComponent, {
              restoreFocus: false,
              data: new TenantSwitchParams({}),
              width: '600px'
            });
          },
          visible: (u: ContextUser): boolean => u.canSwitchTenant
        }
      ];
    })
  );

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'check-circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'clock',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'minus-circle',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private authSvc: AuthService,
    private stateSvc: StateService
  ) {}

  setStatus(status: OnlineStatus): void {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close(item: MenuItem): void {
    item.callback?.();
    this.popoverRef.close();
  }

  logout(): void {
    this.authSvc.logout({ logoutParams: { returnTo: this.document.location.origin } });
  }
}
