<div *ngIf="widgets$ | async as widgets" class="mx-2 px-2 border-t-2">
  <div class="flex items-center border-y-1 p-2">
    <fa-icon [icon]="['fad', 'tachometer-alt-fast']" [fixedWidth]="true" size="2x" class="text-primary mr-3"></fa-icon>
    <h2>Dashboard</h2>
  </div>

  <h5 class="uppercase caption text-secondar bold py-2">Add Widget</h5>

  <div *ngFor="let widget of widgets" class="pb-2 mb-2">
    <button mat-button color="primary" (click)="addWidget(widget.id)" class="p-2">
      <div class="text-primary font-normal">
        <fa-icon [icon]="['fas', 'plus']" [fixedWidth]="true"></fa-icon>
        {{ widget.name }}
      </div>
    </button>
  </div>
</div>
