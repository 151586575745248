import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { EMPTY, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { NavigationLink } from 'src/@vex/interfaces/navigation-item.interface';
import { ConfigService } from '../@vex/services/config.service';
import { LayoutService } from '../@vex/services/layout.service';
import { NavigationService } from '../@vex/services/navigation.service';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { navItems } from './app-nav-items';
import { AgentLivePollerService } from './core/services/agent-live-poller.service';
import { ServiceState } from './core/services/api.service';
import { PubSubWsService } from './core/services/pubsubws.service';
import { RemoterPollerService } from './core/services/remoter-poller.service';
import { SelectedAgentState, StateService } from './core/services/state.service';
import { TenantService } from './core/services/tenant.service';
import { UserService } from './core/services/user.service';
import { deepCopy } from './utils/clone';
export class Message {
  constructor(
    public sender: string,
    public content: string,
    public isBroadcast = false
  ) {}
}
@Component({
  selector: 'mon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CommandCTRL';
  tenant$ = this.stateSvc.tenant$;
  user$ = this.stateSvc.currentUser$;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleSvc: Title,
    private iconRegistry: MatIconRegistry,
    private navigationSvc: NavigationService,
    private splashScreenSvc: SplashScreenService, // this may not look like it's used, but it's for the spinner during intial load
    private layoutService: LayoutService,
    private configSvc: ConfigService,
    private styleSvc: StyleService,
    private authSvc: AuthService,
    private stateSvc: StateService,
    private userSvc: UserService,
    private tenantSvc: TenantService,
    private agentLivePollerSvc: AgentLivePollerService,
    private remoterPollerSvc: RemoterPollerService,
    private pubSubSvc: PubSubWsService
  ) {
    this.titleSvc.setTitle(this.title);
    this.iconRegistry.setDefaultFontSetClass('material-icons-two-tone');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe({
        next: (queryParamMap) =>
          this.styleSvc.setStyle(queryParamMap.get('style') as Style)
      });
    this.navigationSvc.items = navItems;

    this.verifyAuthenticated();
  }

  private verifyAuthenticated(): void {
    const tenant$ = this.tenantSvc.get();
    const user$ = this.userSvc.me();
    const isAuthenticated$ = this.authSvc.isAuthenticated$.pipe(distinctUntilChanged());
    const authenticatedUser$ = this.authSvc.user$.pipe(distinctUntilChanged());

    combineLatest([isAuthenticated$, authenticatedUser$])
      .pipe(
        take(1),
        switchMap(([isAuthenticated, user]) => {
          if (isAuthenticated && user) {
            return user$;
          }

          this.splashScreenSvc.hide();

          return EMPTY;
        }),
        tap((user) => {
          this.stateSvc.setCurrentUserSettings(user.settings);
          this.stateSvc.setCurrentUser(user);
        }),
        switchMap(() => tenant$), // now that user settings are set - their tenant will be set from their settings
        tap((tenant) => this.stateSvc.setTenant(tenant)),
        tap(() => this.customSetup()),
        switchMap(() =>
          this.styleSvc.onStyleApplied$.pipe(
            take(1),
            tap(() => this.splashScreenSvc.hide())
          )
        )
      )
      .subscribe();
  }

  customSetup(): void {
    this.stateSvc.start();
    this.setupRecentMachines();
    this.setupNavChanges();
    this.setupSearching();
    this.setupPolling();
    // start webSockets
    this.setupWebSockets();
  }
  private setupRecentMachines(): void {
    this.stateSvc.selectedAgent$
      .pipe(
        filter(
          (selectedAgent) =>
            !!selectedAgent.agentID &&
            selectedAgent.state !== SelectedAgentState.Searching
        )
      )
      .subscribe({
        next: (selectedAgent) => {
          this.stateSvc.currentUserSettings$
            .pipe(
              filter((settings) => !!settings),
              first(),
              switchMap((settings) => {
                return this.stateSvc.tenant$.pipe(
                  take(1),
                  map((t) => {
                    const copy = deepCopy(settings);
                    let machines = copy.consoleUI.recentMachines.get(t.id) || [];
                    machines = machines.filter((m) => m !== selectedAgent.agentID);
                    if (selectedAgent.state === SelectedAgentState.Found) {
                      machines.push(selectedAgent.agentID);
                      while (machines.length > 10) {
                        machines.shift();
                      }
                    }
                    copy.consoleUI.recentMachines.set(t.id, machines); // remove previous position of machine
                    return copy;
                  })
                );
              }),
              tap((settings) => this.stateSvc.setCurrentUserSettings(settings))
            )
            .subscribe();
        }
      });
  }
  private setupNavChanges(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        map((e) => e as ActivationEnd),
        tap((e) => {
          const route =
            '/' +
            e.snapshot.pathFromRoot
              .filter((f) => f.routeConfig?.path)
              .map((m) => m.routeConfig.path)
              .join('/');
          const found = this.navigationSvc.findByRoute(route) as NavigationLink;
          if (found) {
            this.stateSvc.currentUserSettings$
              .pipe(
                filter((settings) => !!settings),
                first(),
                tap((settings) => {
                  const copy = deepCopy(settings);
                  copy.consoleUI.recentPages = copy.consoleUI.recentPages.filter(
                    (p) => p !== found.route
                  ); // remove previous position of page
                  copy.consoleUI.recentPages.push(found.route);
                  while (copy.consoleUI.recentPages.length > 6) {
                    copy.consoleUI.recentPages.shift();
                  }
                  this.stateSvc.setCurrentUserSettings(copy);
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }
  private setupPolling(): void {
    combineLatest([
      this.stateSvc.agentRequired$,
      this.stateSvc.selectedAgent$,
      this.stateSvc.stopPolling$
    ]).subscribe({
      next: ([agentRequired, selectedAgent, stopPolling]) => {
        if (
          agentRequired &&
          selectedAgent.state === SelectedAgentState.Found &&
          !stopPolling
        ) {
          if (this.agentLivePollerSvc.state === ServiceState.Stopped) {
            this.agentLivePollerSvc.start();
          } else if (this.agentLivePollerSvc.state === ServiceState.Paused) {
            this.agentLivePollerSvc.unpause();
          }
          if (this.remoterPollerSvc.state === ServiceState.Stopped) {
            this.remoterPollerSvc.start();
          } else if (this.remoterPollerSvc.state === ServiceState.Paused) {
            this.remoterPollerSvc.unpause();
          }
        } else {
          if (this.agentLivePollerSvc.state === ServiceState.Started) {
            this.agentLivePollerSvc.pause();
          }
          if (this.remoterPollerSvc.state === ServiceState.Started) {
            this.remoterPollerSvc.pause();
          }
        }
      }
    });
  }
  private setupSearching(): void {
    this.layoutService.searchOpen$.subscribe({
      next: (state) => {
        if (state && state.state === 'close' && !state.cancelled) {
          if (state.result && state.result !== this.stateSvc.search) {
            this.stateSvc.setSearch(state.result);
            if (this.configSvc.config.search.goToDashboard) {
              this.router.navigate(['home', 'dashboard'], {
                queryParams: { machineID: state.result },
                queryParamsHandling: 'merge'
              });
            }
          }
        }
      }
    });
  }
  private setupWebSockets(): void {
    this.pubSubSvc.connect();
  }
}
