<ng-container *ngIf="settings$ | async as settings">
  <table
    *ngIf="visibleColumns$ | async as visibleColumns"
    [dataSource]="dataSource"
    mat-table
    matSort
    [matSortActive]="this.sortField"
    [matSortDirection]="this.sortDirection"
    [@listStagger]="dataSource.length"
    class="w-full"
  >
    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell [disabled]="column.sortable === false" mat-sort-header>{{
          column.label
        }}</th>
        <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
          <ng-container *ngIf="linkTarget(row) && column.canRoute; else noLink">
            <ng-container *ngFor="let t of row.targets | keyvalue; index as i">
              <span *ngIf="i > 0">, </span
              ><a [routerLink]="['dashboard']" [queryParams]="{ machineID: t.key }" class="text-primary">{{ t.key }}</a>
            </ng-container>
          </ng-container>
          <ng-template #noLink>
            {{ row[column.property] }}
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <!-- Date Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
        <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
          <ng-container [ngSwitch]="column.dateFormat">
            <ng-container *ngSwitchCase="'timeAgo'">
              {{ row[column.property] | timeAgo }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ row[column.property] | date: column.dateFormat || 'medium' }}
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
    <ng-container *matRowDef="let row; columns: visibleColumns">
      <tr @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
    </ng-container>
  </table>
</ng-container>
