<mon-loader></mon-loader>

<vex-config-panel-toggle (openConfig)="toggleConfigPanel()"></vex-config-panel-toggle>

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <vex-config-panel></vex-config-panel>
  <ng-container [ngSwitch]="viewConfigPanel$ | async">
    <ng-container *ngSwitchCase="'dashboard'">
      <mon-dashboard-config-panel view-config-panel></mon-dashboard-config-panel>
    </ng-container>
    <ng-container *ngSwitchCase="'none'"> </ng-container>
  </ng-container>
</vex-sidebar>
<!-- END CONFIGPANEL -->

<div
  [class.vex-layout-boxed]="isBoxed$ | async"
  [class.vex-layout-sidenav-collapsed]="(sidenavCollapsed$ | async) && (isDesktop$ | async)"
  [class.has-fixed-footer]="(isFooterFixed$ | async) && isFooterVisible$ | async"
  [class.vex-layout-vertical]="isLayoutVertical$ | async"
  [class.is-mobile]="isMobile$ | async"
  [class.has-footer]="isFooterVisible$ | async"
  [class.scroll-disabled]="scrollDisabled$ | async"
  [class.toolbar-fixed]="isToolbarFixed$ | async"
  [class.content-container]="containerEnabled$ | async"
  [class.with-search]="searchOpen$ | async"
  [class.bottom-drawer-collapsed]="bottomDrawerCollapsed$ | async"
  class="vex-base-layout-container vex-layout-horizontal flex flex-col absolute top-0 right-0 bottom-0 left-0"
>
  <vex-progress-bar></vex-progress-bar>

  <vex-search *hasPerm="[permission.Search_Invoke]" class="z-0"></vex-search>

  <mat-sidenav-container class="sidenav-container h-full">
    <mat-sidenav
      #sidenav
      class="sidenav"
      [disableClose]="isDesktop$ | async"
      [mode]="!(isDesktop$ | async) || (isLayoutVertical$ | async) ? 'over' : 'side'"
      [opened]="(isDesktop$ | async) && !(isLayoutVertical$ | async)"
      [fixedInViewport]="!(isDesktop$ | async)"
    >
      <vex-sidenav [collapsed]="(sidenavCollapsed$ | async) && (isDesktop$ | async)"></vex-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="vex-layout-sidenav-content">
      <div class="h-full flex flex-col">
        <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async" [mobileQuery]="!(isDesktop$ | async)" class="toolbar z-10"> </vex-toolbar>
        <ng-container *ngIf="showTasks$ | async as showTasks">
          <mon-bottom-drawer class="flex-1" [collapsed]="bottomDrawerCollapsed$ | async" [showDrawer]="showTasks.value">
            <div class="flex flex-col h-full" content>
              <main class="flex-1 content">
                <mon-main></mon-main>
                <ng-container *ngIf="loader$ | async">
                  <div @scaleOut id="vex-splash-screen" class="splash-screen inner-splash-screen content-loader z-10">
                    <div class="wrapper">
                      <div class="ball-scale-multiple">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </main>
              <!-- <div @fadeInRight @fadeOutRight *ngIf="isFooterVisible$ | async" class="footer-wrapper">
              <vex-footer></vex-footer>
            </div> -->
            </div>
            <mon-task-drawer *hasPerm="[permission.Task_List]" bottom-drawer></mon-task-drawer>
          </mon-bottom-drawer>
        </ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
