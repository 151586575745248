import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BaseService {
  constructor() {
    this.log('constructed');
  }
  protected log(message: string | unknown, ...optionalParams: unknown[]): void {
    if (environment.consoleLogs) {
      if (typeof message === 'object') {
        message = JSON.stringify(message);
      }

      const name = this.constructor.name;

      console.log(
        `[${new Date().toLocaleTimeString()} ${name}]:`,
        message,
        ...optionalParams
      );
    }
  }
}
