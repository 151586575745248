import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { ContextUserTenant } from 'src/app/shared/models/context-user-tenant.model';

export class TenantSwitchParams {
  onSuccess: () => void;
  constructor(init?: Partial<TenantSwitchParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-tenant-switch',
  templateUrl: './tenant-switch.component.html',
  styleUrls: ['./tenant-switch.component.scss']
})
export class TenantSwitchComponent {
  err: string;
  spin = true;
  overlayResult = OverlayResult.Unset;
  currentTenantID = this.stateSvc.tenant.id;
  tenants$ = this.userSvc.tenants().pipe(
    tap(() => this._sameTenantNames.next(new Map<string, boolean>())),
    map((t) => {
      const sameNames = new Map<string, boolean>();

      const result = t
        .map((tenant) => {
          if (sameNames.get(tenant.name) === undefined) {
            sameNames.set(tenant.name, false);
          } else {
            sameNames.set(tenant.name, true);
          }

          return tenant;
        })
        .sort((a, b) => (a.owned ? -1 : 1 || b.name.localeCompare(a.name)));

      this._sameTenantNames.next(sameNames);

      return result;
    }),
    finalize(() => (this.spin = false))
  );

  _sameTenantNames = new BehaviorSubject<Map<string, boolean>>(null);
  sameTenantNames$ = this._sameTenantNames.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: TenantSwitchParams,
    private dialogRef: MatDialogRef<TenantSwitchComponent>,
    private router: Router,
    private userSvc: UserService,
    private tenantSvc: TenantService,
    private stateSvc: StateService
  ) {}

  closeError(): void {
    this.err = '';
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.();
      this.dialogRef.close();
    }
  }

  switchTenant(tenant: ContextUserTenant): void {
    if (tenant.id === this.currentTenantID) {
      this.dialogRef.close();
      return;
    }

    this.spin = true;

    this.stateSvc.updateCurrentUserSettings({
      selectedTenantID: tenant.id
    });

    this.stateSvc.tenant$
      .pipe(
        distinctUntilChanged((p, c) => {
          const pID = p?.id || '';
          const cID = c?.id || '';
          if (pID !== cID) {
            this.stateSvc.search$
              .pipe(
                tap((t) => {
                  if (!t) {
                    this.router.navigate(['home', 'dashboard'], {
                      queryParams: { machineID: undefined },
                      queryParamsHandling: 'merge',
                      skipLocationChange: false,
                      replaceUrl: true
                    });
                  }
                })
              )
              .subscribe();

            this.stateSvc.setSearch(undefined);
          }

          return false;
        })
      )
      .subscribe();

    this.tenantSvc
      .get()
      .pipe(
        tap((t) => this.stateSvc.setTenant(t)),
        finalize(() => (this.spin = false))
      )
      .subscribe({
        next: () => {
          this.overlayResult = OverlayResult.Success;
        },
        error: (err: HttpErrorResponse) => {
          this.err = parseError(err);
          this.overlayResult = OverlayResult.Error;
        }
      });
  }
}
