<mon-spinner [spin]="spin" [overlay]="true">
  <div content class="min-h-20">
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>
    <form *ngIf="script$ | async as script" autocomplete="off" (ngSubmit)="submit(script.installRefID)" [formGroup]="form">
      <div class="flex justify-start items-center content-center" mat-dialog-title>
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">Run {{ script.name }} Script</h2>

        <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border"></mat-divider>
      <mat-dialog-content class="flex flex-col">
        <mat-form-field>
          <mat-label>Target Machines</mat-label>
          <mat-chip-grid #chipList aria-label="Target Machine Selection" formControlName="targets">
            <mat-chip-row *ngFor="let target of form.controls.targets.value.sort()" removable (removed)="removeTarget(target)">
              <div class="truncate">{{ target }}</div>
              <fa-icon matChipRemove [icon]="['fad', 'times-circle']" lsize="lg" [fixedWidth]> </fa-icon>
            </mat-chip-row>
            <input
              #machineInput
              placeholder="assign targets..."
              [formControl]="machineSelectCtrl"
              [matAutocomplete]="machineAuto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTarget($event)"
              cdkFocusInitial
            />
          </mat-chip-grid>
          <mat-error *ngIf="form.controls.targets.errors?.invalid">Provide one target</mat-error>
          <mat-autocomplete #machineAuto (optionSelected)="selectedMachine($event)" autoActiveFirstOption matAutocompleteTrigger>
            <ng-container *ngIf="searchResults$ | async">
              <mat-option *ngFor="let searchResult of filteredAgentList" [value]="searchResult" [disabled]="spin">
                <div class="text-xs md:text-base">
                  {{ searchResult.id }}
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <fa-icon [icon]="['fad', 'computer-classic']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
        </mat-form-field>
        <div class="relative w-full">
          <mat-progress-bar
            *ngIf="machineSearching"
            mode="indeterminate"
            color="primary"
            class="absolute"
            [style.height]="'2px'"
            [style.top]="'-25px'"
          ></mat-progress-bar>
        </div>

        <mat-form-field>
          <mat-label>Custom Arguments</mat-label>
          <input
            (keydown.Tab)="selectParam($event)"
            type="text"
            aria-label="Number"
            matInput
            formControlName="customArgs"
            [matAutocomplete]="paramAuto"
            #customArgInput
            (keyup)="getParams(script.parameters, customArgInput)"
          />
          <mat-autocomplete autoActiveFirstOption #paramAuto="matAutocomplete">
            <mat-option
              *ngFor="let option of availParameters"
              (onSelectionChange)="$event.source.disabled = true; selectedParameter($event.source.value)"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <fa-icon [icon]="['fas', 'flag']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
        </mat-form-field>

        <div class="flex flex-col max-h-20 overflow-auto" #parameterInputs>
          <mon-checkbox-group formControlName="argNames">
            <ng-container *ngFor="let pName of objectKeys(script.parameters); index as i">
              <div class="flex items-center justify-center gap-1">
                <mon-checkbox
                  [value]="pName"
                  color="primary"
                  class="p-3"
                  (change)="$event.checked ? parameterName.focus() : null"
                  #checkbox
                >
                </mon-checkbox>
                <mat-form-field class="flex-1">
                  <mat-label>{{ pName }}</mat-label>
                  <input class="parameter-name" [formControlName]="'p-' + pName" matInput #parameterName />
                  <mat-hint *ngIf="script.parameters[pName]">{{ script.parameters[pName] }}</mat-hint>
                </mat-form-field>
              </div>
            </ng-container>
          </mon-checkbox-group>
        </div>

        <div @fadeInRight *ngIf="err" class="card text-white text-opacity-80 bg-red flex justify-between items-center mt-2">
          <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
          <pre class="p-2">
            <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="err=''"></fa-icon>
          </pre>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <div class="flex justify-end w-full">
          <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
          <button color="primary" mat-button type="submit" [disabled]="spin || form.invalid">SUBMIT</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
