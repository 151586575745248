import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Role } from 'src/app/shared/models/role.model';
import { ApiService, Response } from './api.service';

export interface NewRole {
  id: string;
  name: string;
  description: string;
  permissions: string[];
}

export interface PatchRole {
  name: string;
  description: string;
  permissions: string[];
}

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<Role>> {
    const url = `${this.apiUrl}/tenant/role`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Role>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(`fetched RoleService.getAll response in ${performance.now() - start}ms`);
      }),
      map((response) =>
        response
          ? new PagedResult<Role>(Role, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Role>>('RoleService.getAll');
        return throwError(() => err);
      })
    );
  }

  getAllSystem(): Observable<Role[]> {
    const url = `${this.apiUrl}/tenant/role/system`;
    const start = performance.now();
    return this.http.get<Role[]>(url).pipe(
      tap(() => {
        this.log(
          `fetched RoleService.getAllSystem response in ${performance.now() - start}ms`
        );
      }),
      map((r) => r.map((r) => new Role(r))),
      catchError((err) => {
        this.handleError('RoleService.getAllSystem');
        return throwError(() => err);
      })
    );
  }

  getByID(roleID: string): Observable<Role> {
    const url = `${this.apiUrl}/tenant/role/${roleID}`;
    const start = performance.now();
    return this.http.get<Role>(url).pipe(
      tap(() => {
        this.log(
          `fetched RoleService.getByID [${roleID}] response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) => (response ? new Role(response) : undefined)),
      catchError((err) => {
        this.handleError<Role>('RoleService.getByID');
        return throwError(() => err);
      })
    );
  }

  create(newRole: NewRole): Observable<Response> {
    const url = `${this.apiUrl}/tenant/role`;
    const start = performance.now();

    return this.http.post<Response>(url, newRole).pipe(
      tap(() => {
        this.log(`fetched RoleService.create response in ${performance.now() - start}ms`);
      }),
      catchError((err) => {
        this.handleError('RoleService.create');
        return throwError(() => err);
      })
    );
  }

  update(id: string, patch: PatchRole): Observable<Response> {
    const url = `${this.apiUrl}/tenant/role/${id}`;
    const start = performance.now();
    return this.http.patch<Response>(url, patch).pipe(
      tap(() => {
        this.log(`fetched RoleService.update response in ${performance.now() - start}ms`);
      }),
      catchError((err) => {
        this.handleError('RoleService.update');
        return throwError(() => err);
      })
    );
  }

  delete(roleID: string, force = false): Observable<Role> {
    const url = `${this.apiUrl}/tenant/role/${roleID}`;
    const params = {};
    Object.assign(params, { force });
    const start = performance.now();
    return this.http.delete<Role>(url, { params }).pipe(
      tap(() => {
        this.log(`fetched RoleService.delete response in ${performance.now() - start}ms`);
      }),
      catchError((err) => {
        this.handleError<Role>('RoleService.delete');
        return throwError(() => err);
      })
    );
  }

  getAllPermissions(onlyAssignable = false): Observable<string[]> {
    const url = `${this.apiUrl}/tenant/permission`;
    return this.http.get<string[]>(url, { params: { onlyAssignable } }).pipe(
      catchError((err) => {
        this.handleError('RoleService.getAllPermissions');
        return throwError(() => err);
      })
    );
  }
}
