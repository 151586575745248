import { DataSource } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ServiceState } from 'src/app/core/services/api.service';
import { TaskPollerService } from 'src/app/core/services/task-poller.service';
import { TaskService } from 'src/app/core/services/task.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Task } from 'src/app/shared/models/task.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

export class Test extends MatTableDataSource<Task> {}

export class TasksGlobalRecentDataSource implements DataSource<Task> {
  public _maxCount = 20;
  public get maxCount(): number {
    return this._maxCount;
  }
  public set maxCount(maxCount: number) {
    this._maxCount = maxCount;
  }

  public _sort: OrderBy[];
  public get sort(): OrderBy[] {
    return this._sort;
  }
  public set sort(sort: OrderBy[]) {
    this._sort = sort;
  }

  private taskData: Task[] = [];
  private _tasks = new BehaviorSubject<Task[]>(this.taskData);

  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();

  private pollingSubscription: Subscription;
  public get total(): number {
    return this.taskData.length;
  }
  public get length(): number {
    return this.taskData.length;
  }
  public get data(): Task[] {
    return this._tasks.value?.slice() || [];
  }

  constructor(private taskSvc: TaskService, private taskPollerSvc: TaskPollerService) {}

  loadTasks(sort: OrderBy[], maxCount = 20, refresh = false): void {
    this._loading.next(true);

    this.maxCount = maxCount;
    this.sort = sort;

    if (this.taskPollerSvc.state !== ServiceState.Started || refresh) {
      if (this.pollingSubscription) {
        this.pollingSubscription.unsubscribe();
      }

      this.taskSvc
        .getAll(this.maxCount, 0, sort)
        .pipe(
          catchError(() => UNDEFINED),
          finalize(() => this._loading.next(false))
        )
        .subscribe({
          next: (results: PagedResult<Task>) => {
            if (results) {
              this.taskData = results.items;
              this._tasks.next(this.taskData || []);

              this.pollingSubscription = this.taskPollerSvc.recentTasks$
                .pipe(
                  tap(() => this._loading.next(true)),
                  tap((tasks) => this.updateTasks(tasks)),
                  finalize(() => this._loading.next(false))
                )
                .subscribe();
            }

            this.taskPollerSvc.start();
          }
        });
    } else {
      this.sortData();
    }
  }

  connect(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  disconnect(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }

    this._tasks.complete();
    this._loading.complete();
  }

  updateTasks(tasks: Task[]): void {
    tasks = tasks || [];
    const taskMap = new Map<string, Task>();
    tasks.map((t) => taskMap.set(t.id, t));

    for (let i = 0; i < this.taskData.length; ) {
      const oldTask = this.taskData[i];

      const newTask = taskMap.get(oldTask.id);

      if (newTask != null) {
        if (oldTask != null) {
          if (!oldTask.equals(newTask)) {
            Object.assign(oldTask, newTask);
          }
          taskMap.delete(oldTask.id);
        }
      }
      i++;
    }

    this.taskData = this.taskData.concat(Array.from(taskMap.values()));

    this.sortData();
  }

  private sortData(): void {
    this.taskData.sort((a, b) => a.lastUpdate.getTime() - b.lastUpdate.getTime());

    while (this.taskData.length > this.maxCount) {
      this.taskData.shift();
    }
    if (this.sort.length > 0 && this.sort[0].dir > 0) {
      this.taskData = OrderBy.Sort(this.taskData.slice(), this.sort[0]);
    }

    this._tasks.next(this.taskData);
  }
}
