import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { finalize, map, mergeMap, startWith, take, tap } from 'rxjs/operators';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from 'src/@vex/animations/fade-in-right.animation';
import { PostalService } from 'src/app/core/services/postal.service';
import { TenantPatch, TenantService } from 'src/app/core/services/tenant.service';
import { isWebErrorResponse, parseError } from 'src/app/core/utils/http-reponse-error';
import { getEmailRegex } from 'src/app/core/utils/regex';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { Address, Tenant } from 'src/app/shared/models/tenant.model';
import { User } from 'src/app/shared/models/user.model';

export enum TenantEditView {
  CompanyName = 0,
  Email = 1,
  Phone = 2,
  OwnerName = 3,
  BillingAddress = 4
}

export class TenantEditParams {
  tenantID: Tenant['id'];
  view: TenantEditView;
  onSuccess: (patch: TenantPatch) => void;

  constructor(init?: Partial<TenantEditParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-tenant-edit',
  templateUrl: './tenant-edit.component.html',
  styleUrls: ['./tenant-edit.component.scss'],
  animations: [fadeInRight400ms, fadeOutRight400ms]
})
export class TenantEditComponent implements OnInit {
  @ViewChild('container') container: ElementRef;

  TenantEditView = TenantEditView;
  initialLoad: boolean;
  spin: boolean;
  overlayResult = OverlayResult.Unset;

  currentForm: FormGroup;

  companyNameForm = this.fb.group({
    companyName: new FormControl('')
  });
  ownerNameForm = this.fb.group({
    firstName: new FormControl(''),
    lastName: new FormControl('')
  });
  ownerEmailForm = this.fb.group({
    ownerEmail: new FormControl('', [
      (c: UntypedFormControl) => {
        if (!c.value) {
          return null;
        }
        if (!this.users) {
          return { mustExist: true };
        }
        const found = this.users.find((u) => u.email === c.value.toLowerCase());
        return found ? null : { mustExist: true };
      },
      Validators.required,
      Validators.pattern(getEmailRegex())
    ])
  });
  phoneForm = this.fb.group({
    phone: new FormControl('')
  });
  billingAddressForm = this.fb.group({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    company: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    address3: new FormControl(''),
    locality: new FormControl(''),
    region: new FormControl(''),
    regionCode: new FormControl(''),
    postcode: new FormControl(''),
    countryCode: new FormControl('', [Validators.required]),
    country: new FormControl('')
  });

  _view = new BehaviorSubject<TenantEditView>(TenantEditView.CompanyName);
  view$ = this._view.asObservable().pipe(
    tap((v) => {
      switch (v) {
        case TenantEditView.CompanyName:
          this.currentForm = this.companyNameForm;
          break;
        case TenantEditView.OwnerName:
          this.currentForm = this.ownerNameForm;
          break;
        case TenantEditView.Email:
          this.currentForm = this.ownerEmailForm;
          break;
        case TenantEditView.Phone:
          this.currentForm = this.phoneForm;
          break;
        case TenantEditView.BillingAddress:
          this.currentForm = this.billingAddressForm;
          break;
      }
    })
  );

  _tenant = new BehaviorSubject<Tenant>(null);
  tenant$ = this._tenant
    .asObservable()
    .pipe(mergeMap((tenant) => iif(() => !!tenant, of(tenant), this.tenantSvc.get())));

  users: User[];
  usersResult$ = this.tenantSvc.getAllUsers();
  filteredUsers: Observable<User[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: TenantEditParams,
    private dialogRef: MatDialogRef<TenantEditComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private postalSvc: PostalService,
    private tenantSvc: TenantService
  ) {
    this._view.next(params.view);
  }

  ngOnInit(): void {
    this.spin = true;

    combineLatest({
      users: this.usersResult$.pipe(
        tap((result) => {
          this.users = result.items;
          this.filteredUsers = this.ownerEmailForm.controls.ownerEmail.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterUsers(value))
          );
        })
      ),
      tenant: this.tenant$,
      countries: this.postalSvc.getCountries()
    })
      .pipe(
        take(1),
        tap((result) => {
          this.companyNameForm.controls.companyName.setValue(result.tenant.companyName);
          this.ownerEmailForm.controls.ownerEmail.setValue(result.tenant.ownerEmail);
          this.phoneForm.controls.phone.setValue(result.tenant.phone);
          this.ownerNameForm.controls.firstName.setValue(result.tenant.firstName);
          this.ownerNameForm.controls.lastName.setValue(result.tenant.lastName);

          const billing = result.tenant.billingAddress;
          if (billing) {
            this.billingAddressForm.patchValue({
              countryCode: billing.countryCode,
              regionCode: billing.regionCode,
              region: billing.region,
              locality: billing.locality,
              postcode: billing.postcode,
              address1: billing.address1,
              address2: billing.address2,
              address3: billing.address3,
              firstName: billing.firstName,
              lastName: billing.lastName,
              company: billing.company
            });
          }
        }),
        finalize(() => (this.initialLoad = true)),
        finalize(() => (this.spin = false))
      )
      .subscribe();
  }

  saveTenant() {
    this.spin = true;
    const view = this._view.getValue();
    const update = this.getUpdate();
    let call$ = undefined;

    switch (view) {
      case TenantEditView.BillingAddress:
        call$ = this.tenantSvc.updateBilling(update);
        break;
      default:
        call$ = this.tenantSvc.updateDetail(update);
    }

    call$
      .pipe(
        tap(() => (this.params.onSuccess ? this.params.onSuccess(update) : null)),
        finalize(() => (this.spin = false))
      )
      .subscribe({
        next: () => {
          const next = this.findEmptyFields();
          if (next !== undefined) {
            this._view.next(next);
            this.focusOnFirstElement();
          } else {
            this.overlayResult = OverlayResult.Success;
          }
        },
        error: (err: HttpErrorResponse) => {
          const webErr = isWebErrorResponse(err);
          if (webErr) {
            switch (webErr.webcode) {
              case 'invalid_zip_code':
                this.currentForm.controls.postcode.setErrors({ invalid_zip_code: true });
                break;
            }
          }

          this.snackBar.open(parseError(err), 'CLOSE', {
            duration: 3000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.overlayResult = OverlayResult.Error;
        }
      });
  }

  findEmptyFields(): TenantEditView {
    const view = this._view.getValue();

    let i = view + 1;
    let v: TenantEditView;

    for (; v !== view; i++) {
      v = (i % (Object.keys(TenantEditView).length / 2)) as TenantEditView;

      if (
        v === TenantEditView.CompanyName &&
        !this.companyNameForm.controls.companyName.value
      ) {
        break;
      }
      if (
        v === TenantEditView.OwnerName &&
        (!this.ownerNameForm.controls.firstName.value ||
          !this.ownerNameForm.controls.lastName.value)
      ) {
        break;
      }
      if (v === TenantEditView.Email && !this.ownerEmailForm.controls.ownerEmail.value) {
        break;
      }
      if (v === TenantEditView.Phone && !this.phoneForm.controls.phone.value) {
        break;
      }
      if (
        v === TenantEditView.BillingAddress &&
        !this.billingAddressForm.controls.address1.value
      ) {
        break;
      }
    }

    return v === view ? undefined : v;
  }

  getUpdate(): TenantPatch {
    const view = this._view.getValue();

    switch (view) {
      case TenantEditView.CompanyName:
        return { companyName: this.companyNameForm.controls.companyName.value };
      case TenantEditView.OwnerName:
        return {
          firstName: this.ownerNameForm.controls.firstName.value,
          lastName: this.ownerNameForm.controls.lastName.value
        };
      case TenantEditView.Email:
        return {
          ownerID: this.users.find(
            (u) => u.email === this.ownerEmailForm.controls.ownerEmail.value
          ).id
        };
      case TenantEditView.Phone:
        return { phone: this.phoneForm.controls.phone.value };
      case TenantEditView.BillingAddress:
        return {
          billingAddress: new Address({
            firstName: this.billingAddressForm.controls.firstName.value,
            lastName: this.billingAddressForm.controls.lastName.value,
            company: this.billingAddressForm.controls.company.value,
            address1: this.billingAddressForm.controls.address1.value,
            address2: this.billingAddressForm.controls.address2.value,
            address3: this.billingAddressForm.controls.address3.value,
            locality: this.billingAddressForm.controls.locality.value,
            regionCode: this.billingAddressForm.controls.regionCode.value,
            region: this.billingAddressForm.controls.region.value,
            postcode: this.billingAddressForm.controls.postcode.value,
            countryCode: this.billingAddressForm.controls.countryCode.value
          })
        };
    }
  }

  overlayClose(r: OverlayResult) {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.dialogRef.close();
    }
  }

  focusOnFirstElement() {
    setTimeout(
      () => this.container.nativeElement.getElementsByTagName('input')[0]?.focus(),
      400
    );
  }

  getViewDescription(view: TenantEditView): string {
    switch (view || TenantEditView.CompanyName) {
      case TenantEditView.CompanyName:
      case TenantEditView.OwnerName:
      case TenantEditView.Phone:
      case TenantEditView.Email:
        return '';
      case TenantEditView.BillingAddress:
        return 'Billing Address';
    }
  }

  private _filterUsers(email: string): User[] {
    const filterValue = email.toLowerCase();
    return this.users.filter((u) => u.email.toLowerCase().indexOf(filterValue) === 0);
  }
}
