import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AgentResponseType } from 'src/app/shared/models/agent-response.model';

export interface RemoteSessionData {
  id: string;
  remoteType: 'msra' | 'quickassist';
  data?: unknown;
}

@Component({
  selector: 'mon-remote-session',
  templateUrl: './remote-session.component.html',
  styleUrls: ['./remote-session.component.scss']
})
export class RemoteSessionComponent implements AfterViewInit {
  AgentResponseType = AgentResponseType;
  downloadFile: string;

  @Input() sessionData: RemoteSessionData;

  @ViewChild('password') password: ElementRef;

  constructor(
    private clipboard: Clipboard,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    switch (this.sessionData.remoteType) {
      case 'msra':
        const blob = new Blob([this.sessionData.data['fileContents']], {
          type: 'application/octet-stream'
        });
        this.downloadFile = window.URL.createObjectURL(blob);

        this.clipboard.copy(this.sessionData.data['filepassword']);
        break;
      case 'quickassist':
        break;
    }
    this.changeDetectorRef.detectChanges();
  }

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.disabled = false;
    setTimeout(() => tooltip.show(), 1);
    setTimeout(() => (tooltip.disabled = true), 1000);
    this.clipboard.copy(text);
  }
}
