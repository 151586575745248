import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mon-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  state: {
    [k: string]: string;
  };

  constructor(private router: Router) {
    const nav = this.router.getCurrentNavigation();
    this.state = nav.extras.state
      ? nav.extras.state
      : {
          error: 'unknown error',
          error_description: ''
        };
  }
}
