import { Updatable } from '../interfaces/updatable.interface';

export class DiskSummary implements Updatable<DiskSummary> {
  public disks: Array<DiskMetrics>;

  constructor(init?: Partial<DiskSummary>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    if (this.disks) {
      this.disks = this.disks.map((e) => new DiskMetrics(e));
    }
  }

  update(input: Partial<DiskSummary>): this {
    return Object.assign(this, input);
  }
}

export class DiskMetrics implements Updatable<DiskMetrics> {
  public index: number;
  public id: string;
  public serialNumber: string;
  public vendorID: string;
  public productID: string;
  public removableMedia: boolean;
  public capacity: number;
  public formatted: number;
  public drives: string[];
  public type: string;
  public system: boolean;
  public pageFile: boolean;
  public readSpeed: number;
  public writeSpeed: number;
  public latency: number;
  public activeTime: number;
  public queueDepth: number;
  public partitions: DiskPartitions[];

  constructor(init?: Partial<DiskMetrics>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<DiskMetrics>): this {
    return Object.assign(this, input);
  }
}

export class DiskPartitions implements Updatable<DiskPartitions> {
  constructor(init?: Partial<DiskPartitions>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<DiskPartitions>): this {
    return Object.assign(this, input);
  }

  public style: string;
  public number: number;
  public length: number;
  public gptName: string;
  public gptAttributes: number;
  public id: string;
  public type: string;
  public reWriteable: boolean;
  public isService: boolean;
  public mbrBootIndicator: boolean;
  public mbgRecognizedPartion: boolean;
  public mbrHiddenSectors: number;
}
