export class Subscription {
  public activatedAt: Date;
  public cancelledAt: Date;
  public createdAt: Date;
  public startedAt: Date;
  public trialStart: Date;
  public trialEnd: Date;
  public updatedAt: Date;
  public currentTermEnd: Date;
  public nextBillingAt: Date;
  public id: string;
  public itemPriceID: string;
  public quantity: number;
  public unitPrice: number;
  public amount: number;
  public billToType: string;
  public status: string;

  get contractTerm(): Date {
    return Subscription.contractTerm(this);
  }

  get mustContactSales(): boolean {
    return (
      this.billToType.toLowerCase() === 'reseller' ||
      this.billToType.toLowerCase() === 'msp' ||
      this.billToType.toLowerCase() === 'distributor'
    );
  }

  constructor(init?: Partial<Subscription>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
    this.activatedAt = init.activatedAt ? new Date(init.activatedAt) : undefined;
    this.cancelledAt = init.cancelledAt ? new Date(init.cancelledAt) : undefined;
    this.createdAt = init.createdAt ? new Date(init.createdAt) : undefined;
    this.startedAt = init.startedAt ? new Date(init.startedAt) : undefined;
    this.trialStart = init.trialStart ? new Date(init.trialStart) : undefined;
    this.trialEnd = init.trialEnd ? new Date(init.trialEnd) : undefined;
    this.updatedAt = init.updatedAt ? new Date(init.updatedAt) : undefined;
    this.currentTermEnd = init.currentTermEnd ? new Date(init.currentTermEnd) : undefined;
    this.nextBillingAt = init.nextBillingAt ? new Date(init.nextBillingAt) : undefined;
  }

  static contractTerm(s: Subscription): Date {
    const t = Math.max(s.currentTermEnd.getTime(), s.nextBillingAt.getTime());

    return new Date(t);
  }
}

export class Plan {
  public id: string;
  public name: string;
  public description: string;
  public imageLink: string;
  public frequencies: Frequency[];
  public benefits: string[];
  public tags: string[];
  public displayOrder: number;
  public defaultSelection: boolean;

  private _disableFrequency: boolean = undefined;
  get disableFrequency(): boolean {
    if (this._disableFrequency === undefined) {
      this._disableFrequency = this.tags.findIndex((t) => t === 'disable-frequency') > -1;
    }

    return this._disableFrequency;
  }

  private _disableMachineCount: boolean = undefined;
  get disableMachineCount(): boolean {
    if (this._disableMachineCount === undefined) {
      this._disableMachineCount =
        this.tags.findIndex((t) => t === 'disable-machine-count') > -1;
    }

    return this._disableMachineCount;
  }

  private _disablePayment: boolean = undefined;
  get disablePayment(): boolean {
    if (this._disablePayment === undefined) {
      this._disablePayment = this.tags.findIndex((t) => t === 'disable-payment') > -1;
    }

    return this._disablePayment;
  }

  private _disableEstimate: boolean = undefined;
  get disableEstimate(): boolean {
    if (this._disableEstimate === undefined) {
      this._disableEstimate = this.tags.findIndex((t) => t === 'disable-estimate') > -1;
    }

    return this._disableEstimate;
  }

  private _disableBillingOptional: boolean = undefined;
  get billingOptional(): boolean {
    if (this._disableBillingOptional === undefined) {
      this._disableBillingOptional =
        this.tags.findIndex((t) => t === 'billing-optional') > -1;
    }

    return this._disableBillingOptional;
  }

  constructor(init?: Partial<Plan>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    if (this.frequencies) {
      this.frequencies = this.frequencies
        .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
        .map((s) => new Frequency(s));
    }
  }
}

export enum FrequencyType {
  Monthly = 'month',
  Yearly = 'year'
}

export class Frequency {
  public priceID: string;
  public name: string;
  public type: FrequencyType;
  public benefits: string[];
  public price: number;
  public displayOrder: number;
  public defaultMachineCount: number;
  public defaultSelection: boolean;

  constructor(init?: Partial<Frequency>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }
}

export declare interface TotalEstimate {
  immediateEstimate?: Estimate;
  nextEstimate?: Estimate;
}

export declare interface Estimate {
  unitAmount?: number;
  quantity?: number;
  discount?: number;
  subtotal?: number;
  total?: number;
  taxAmount?: number;
  taxRate?: number;
}

export interface PaymentSource {
  id: string;
  status: string;
  card?: CardInfo;
  bankAccount?: BankAccountInfo;
}

export interface CardInfo {
  company: string;
  phone: string;
  billingAddr1: string;
  billingAddr2: string;
  billingCity: string;
  billingState: string;
  billingStateCode: string;
  billingZip: string;
  billingCountry: string;
  firstName: string;
  lastName: string;
  last4: string;
  cardType: string;
  fundingType: string;
  expiryMonth: number;
  expiryYear: number;
}

export interface BankAccountInfo {
  last4: string;
  nameOnAccount: string;
  firstName: string;
  lastName: string;
  bankName: string;
}
