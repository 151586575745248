<mon-spinner [spin]="spin" [overlay]="true">
  <div content class="">
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>
    <div class="flex justify-start items-center" mat-dialog-title>
      <div class="flex-1 flex flex-col">
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">Verify Bank Account</h2>
      </div>

      <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
        <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <blockquote @fadeInRight class="m-0 mt-4 bg-orange-100 border-orange-500 text-orange-600">
      <div class="flex items-center justify-center gap-4">
        <div>
          <fa-icon [icon]="['fad', 'exclamation-square']" size="2xl" matTooltip="You are invited"></fa-icon>
        </div>
        <div
          >You have 10 chances to verify this bank account. Once this limit has been exceeded, the bank account will be permanently
          removed.</div
        >
      </div>
    </blockquote>

    <div class="h-8"></div>

    <form autocomplete="off" [formGroup]="verifyForm">
      <mat-dialog-content class="flex flex-col">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <mat-form-field @fadeInRight class="w-full" floatLabel="always">
            <mat-label>Amount 1</mat-label>
            <input formControlName="amount1" matInput autofocus [readonly]="spin" placeholder="00" #amount1 />
            <span matPrefix>USD 0.&nbsp;</span>
          </mat-form-field>
          <mat-form-field @fadeInRight class="w-full" floatLabel="always">
            <mat-label>Amount 2</mat-label>
            <input formControlName="amount2" matInput [readonly]="spin" placeholder="00" />
            <span matPrefix>USD 0.&nbsp;</span>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="w-full flex justify-end">
          <button mat-button color="primary" mat-raised-button [disabled]="verifyForm.invalid" (click)="submit()">Verify</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
