<ng-container *ngIf="estimate">
  <h4 class="text-center py-2 bold border-b-2 mb-4">{{ header }}</h4>
  <div class="grid grid-flow-dense grid-cols-2 gap-2">
    <div class="text-secondary">{{ estimate.quantity }} machines</div>
    <div class="text-right">${{ (estimate.unitAmount * estimate.quantity) / 100 | number: '1.2' }}</div>

    <ng-container *ngIf="estimate.discount > 0">
      <div class="text-secondary">Discount</div>
      <div class="text-right text-red">-${{ estimate.discount / 100 | number: '1.2' }}</div>
    </ng-container>

    <div class="text-secondary">Local Tax ({{ estimate.taxRate }}%)</div>
    <div class="text-right">${{ estimate.taxAmount / 100 | number: '1.2' }}</div>

    <div class="text-secondary">Total</div>
    <div class="text-right">${{ estimate.total / 100 | number: '1.2' }}</div>
  </div>
</ng-container>
