<mon-spinner [spin]="spin" [overlay]="true">
  <div *ngIf="tenant$ | async as tenant" class="flex flex-col items-center justify-center gap-8" content>
    <div class="flex items-center justify-center text-primary text-lg">Reactivate Subscription</div>
    <div>Would you like to reactivate your subscription?</div>
    <div class="flex gap-4">
      <button type="button" mat-raised-button color="warn" (click)="cancel()">CANCEL</button>
      <button type="button" mat-raised-button color="primary" cdkFocusInitial (click)="confirm()">REACTIVATE MY SUBSCRIPTION</button>
    </div>
  </div>
</mon-spinner>
