import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckbox } from '@angular/material/checkbox';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, iif, map, mergeMap, of, take, tap } from 'rxjs';
import { IntroService } from 'src/app/core/services/intro.service';
import { SearchPerm, SearchService } from 'src/app/core/services/search.service';
import { deepCopy } from 'src/app/utils/clone';

import { Permissions, PermissionValue } from '../../models/permissions.model';

@Component({
  selector: 'mon-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit, AfterViewInit {
  @Input() autoCommit = false;
  @Input() readonly = false;

  // _permissions = getRandomPerms(5);
  private _permissions: Permissions = new Permissions();
  get permissions(): Permissions {
    return this._permissions;
  }
  @Input() set permissions(p: Permissions) {
    this._permissions = p || new Permissions();
    this.updatePermissions();
  }
  protected origPermissions: Map<string, PermissionValue>;
  protected newPermissions: PermissionValue[];

  @Output() onAdd = new EventEmitter<PermissionValue>();
  @Output() onUpdate = new EventEmitter<PermissionValue>();
  @Output() onDelete = new EventEmitter<PermissionValue>();

  protected searching: boolean;

  protected permSelectCtrl = new UntypedFormControl();
  protected searchTerm$ = this.permSelectCtrl.valueChanges.pipe(
    debounceTime(300),
    map(() => (!!this._permInput ? this._permInput.nativeElement.value : '') as string),
    distinctUntilChanged()
  );

  protected searchResults = new Map<string, SearchPerm>();
  protected searchResults$ = this.searchTerm$.pipe(
    filter((filter) => !this.searchResults.get(filter)),
    tap({ next: () => (this.searching = true) }),
    tap({ next: () => this._selectedPerm.next(undefined) }),
    mergeMap((filter) =>
      iif(() => !!filter, this.searchSvc.getPerms(filter), of(Array<SearchPerm>()))
    ),
    tap({
      next: (results) =>
        (this.searchResults = results.reduce((m, r) => {
          m.set(r.name, r);
          return m;
        }, new Map<string, SearchPerm>()))
    }),
    map((results) =>
      results.filter((r) => !this.origPermissions.has(`${r.type}:${r.id}`))
    ),
    tap({ next: () => (this.searching = false) })
  );

  private _selectedPerm = new BehaviorSubject<SearchPerm>(undefined);
  protected selectedPerm$ = this._selectedPerm.asObservable();

  private _permInput: ElementRef;
  @ViewChild('permInput', { static: false }) protected set permInput(
    permInput: ElementRef
  ) {
    this._permInput = permInput;
  }
  @ViewChild('read', { static: false }) private readInput: MatCheckbox;
  @ViewChild('write', { static: false }) private writeInput: MatCheckbox;
  @ViewChild('invoke', { static: false }) private invokeInput: MatCheckbox;

  constructor(private introSvc: IntroService, private searchSvc: SearchService) {
    this.permSelectCtrl.setValue('');
    this.permSelectCtrl.updateValueAndValidity();
  }

  ngAfterViewInit(): void {
    this.introSvc.show('perms-item-search');
  }

  ngOnInit(): void {
    this.updatePermissions();
  }

  public resetInput(): void {
    this.readInput.checked = false;
    this.writeInput.checked = false;
    this.invokeInput.checked = false;

    this.permSelectCtrl.setValue('');
    this.permSelectCtrl.updateValueAndValidity();
  }

  public focusInput(): void {
    this._permInput?.nativeElement.focus();
  }

  protected addPermission() {
    this.selectedPerm$
      .pipe(
        take(1),
        filter((perm) => !!perm),
        tap((perm) => {
          const p = new PermissionValue({
            id: perm.id,
            name: perm.name,
            value: Permissions.getValue(
              this.readInput.checked,
              this.writeInput.checked,
              this.invokeInput.checked
            ),
            type: perm.type
          });

          const index = this.newPermissions.findIndex((i) => i.uniqueID === p.uniqueID);

          if (index > -1) {
            this.newPermissions[index] = p;
          } else {
            this.newPermissions.push(p);
            this.newPermissions.sort((a, b) => a.name.localeCompare(b.name));
          }

          this.onAdd.emit(p);
        })
      )
      .subscribe();
  }

  protected updatePermission(p: PermissionValue) {
    const index = this.newPermissions.findIndex((i) => i.uniqueID === p.uniqueID);

    if (index > -1) {
      this.newPermissions[index] = p;
    } else {
      this.newPermissions.push(p);
      this.newPermissions.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.onUpdate.emit(p);
  }

  protected deletePermission(p: PermissionValue) {
    const index = this.newPermissions.findIndex((i) => i.uniqueID === p.uniqueID);

    if (index > -1) {
      this.newPermissions.splice(index, 1);
    }

    this.onDelete.emit(p);
  }

  protected toggleRead(p: PermissionValue): void {
    p.value = p.value ^ 4;
    this.autoCommit ? this.updatePermission(p) : null;
  }

  protected toggleWrite(p: PermissionValue): void {
    p.value = p.value ^ 2;
    this.autoCommit ? this.updatePermission(p) : null;
  }

  protected toggleInvoke(p: PermissionValue): void {
    p.value = p.value ^ 1;
    this.autoCommit ? this.updatePermission(p) : null;
  }

  protected displayNameOfPerm(p: PermissionValue): string {
    return p.name;
  }

  protected onPermSelected(event: MatAutocompleteSelectedEvent) {
    this._selectedPerm.next(event.option.value);
  }

  protected trackByPerm(_index: number, perm: PermissionValue): string {
    return perm.uniqueID;
  }

  private updatePermissions(): void {
    this.origPermissions = [
      ...this._permissions.roles.values(),
      ...this._permissions.users.values()
    ]
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((m, p) => {
        m.set(p.uniqueID, p);
        return m;
      }, new Map<string, PermissionValue>());

    if (this.newPermissions) {
      return;
    }

    this.newPermissions = deepCopy([...this.origPermissions.values()]);
  }
}
