import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TaskService } from 'src/app/core/services/task.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { Task } from 'src/app/shared/models/task.model';

export class TasksAgentDataSource implements DataSource<Task> {
  private _cachedAgentTasks: Task[];
  private _tasks = new BehaviorSubject<Task[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._tasks.value ? this._tasks.value.length : -1;
  }
  public get data(): Task[] {
    return this._tasks.value?.slice() || [];
  }

  constructor(private taskSvc: TaskService) {}

  loadTasks(agentID: string, filter = '', orderBy: OrderBy[]): void {
    this._loading.next(true);

    if (this._cachedAgentTasks) {
      let results = this._cachedAgentTasks.slice();
      if (filter) {
        results = results.filter((b) => b.description.indexOf(filter) >= 0);
      }

      if (orderBy.length > 0 && orderBy[0].dir > 0) {
        results = OrderBy.Sort(results, orderBy[0]);
      }

      this.total = results.length;
      this._tasks.next(results || []);
      this._loading.next(false);
      return;
    }

    this.taskSvc
      .getAgentAll(agentID)
      .pipe(
        catchError(() => of([])),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (tasks: Task[]) => {
          this._cachedAgentTasks = OrderBy.Sort(tasks, orderBy[0]);
          this.total = this._cachedAgentTasks.length;
          this._tasks.next(this._cachedAgentTasks || []);
        }
      });
  }

  connect(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  disconnect(): void {
    this._tasks.complete();
    this._loading.complete();
  }
}
