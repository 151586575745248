import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { StateService } from 'src/app/core/services/state.service';

import { dropdownAnimation } from '../../animations/dropdown.animation';
import { NavigationDropdown, NavigationItem, NavigationLink } from '../../interfaces/navigation-item.interface';
import { NavigationService } from '../../services/navigation.service';

@UntilDestroy()
@Component({
  selector: 'vex-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation, fadeInRight400ms],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemComponent implements OnInit, OnChanges {
  @Input() item: NavigationItem;
  @Input() level: number;
  isOpen: boolean;
  isActive: boolean;

  isFunc = this.navigationService.isFunc;
  isExternal = this.navigationService.isExternal;
  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;
  isDivider = this.navigationService.isDivider;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private navigationService: NavigationService,
    private stateSvc: StateService
  ) {}

  @HostBinding('class')
  get levelClass(): string {
    return `item-level-${this.level}`;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter<NavigationEnd>((event) => event instanceof NavigationEnd),
        filter(() => this.isDropdown(this.item)),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.onRouteChange();
        }
      });

    this.navigationService.openChange$
      .pipe(
        filter(() => this.isDropdown(this.item)),
        untilDestroyed(this)
      )
      .subscribe({ next: (item) => this.onOpenChange(item) });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
      this.onRouteChange();
    }
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown): void {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange(): void {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown): boolean {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter((child) => this.isDropdown(child))
      .some((child) => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown): boolean {
    return parent.children.some((child) => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child)) {
        return this.router.isActive(child.route, {
          paths: 'subset',
          queryParams: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored'
        });
      }

      return false;
    });
  }

  hasChildren(item: NavigationDropdown): Observable<boolean> {
    const childrenWithPerms = item.children.filter(
      (i) => (i.requiredPerms || []).length > 0
    );

    if (childrenWithPerms.length > 0) {
      return this.stateSvc.currentUser$.pipe(
        take(1),
        map((user) => {
          for (const child of childrenWithPerms) {
            if (user.hasPerms(child.requiredPerms)) {
              return true;
            }
          }
          return false;
        })
      );
    } else if (item.children.length > 0) {
      return of(true);
    } else {
      return of(false);
    }
  }

  isFunction(item: NavigationLink): boolean {
    return !!item.routeFunction;
  }

  runRouteFunction(item: NavigationLink): void {
    item.routeFunction();
  }
}
