<ng-container *ngIf="fieldValue$ | async as fieldValue">
  <div class="relative flex items-center justify-between gap-2 text-2xs md:text-sm px-2">
    <div
      *ngIf="lock$ | async"
      class="z-20 absolute top-0 left-0 rounded w-full h-full bg-primary-light transition ease-in-out duration-200"
    >
      <div class="py-4 flex h-full items-center justify-center space-x-2">
        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
        <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
      </div>
    </div>
    <div class="flex-1 m-0 flex gap-2">
      <mat-form-field class="w-full">
        <mat-label>{{ label }}</mat-label>
        <input
          type="text"
          matInput
          [value]="showCurrentValueInHint ? '' : fieldValue.get(field) || ''"
          [placeholder]="placeholder"
          (keydown.enter)="setSetting(fieldInput.value)"
          #fieldInput
          [readonly]="readonly"
          [disabled]="readonly"
        />
        <mat-hint *ngIf="showCurrentValueInHint && !!fieldValue.get(field)" class="text-3xs">Current {{ label }}: {{ fieldValue.get(field) }}</mat-hint>
      </mat-form-field>
      <ng-container *ngIf="!readonly">
        <div class="py-4 flex gap-2">
          <button
            mat-button
            [disabled]="!fieldInput.value || fieldValue.get(field) === fieldInput.value"
            class="p-2 leading-none min-h-0 min-w-0"
            (click)="setSetting(fieldInput.value)"
          >
            <fa-icon
              [icon]="['fad', 'check']"
              size="lg"
              [ngClass]="!fieldInput.value || fieldValue.get(field) === fieldInput.value ? 'text-gray' : 'text-green'"
            ></fa-icon>
          </button>
          <button mat-button [disabled]="!fieldValue.get(field)" color="warn" class="p-2 leading-none min-h-0 min-w-0" (click)="setSetting('')">
            Clear
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
