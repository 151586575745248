export function propertyByString(o: any, property: string) {
    property = property.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    property = property.replace(/^\./, ''); // strip a leading dot
    const a = property.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}
