<ng-container *ngIf="settings$ | async as settings"></ng-container>
<ng-container *ngIf="agentStats$ | async">
  <blockquote class="whitespace-normal break-all bg-yellow-100 border-yellow-500 text-yellow-900 m-0 mb-2">
    This is currently under construction and some data is stubbed at the moment. Stay tuned for updates.
  </blockquote>
  <div @fadeInUp class="card overflow-x-auto overflow-y-hidden">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
      <h2 class="title my-0 pr-4 mr-4 border-r flex-none">
        <span>Startup Applications</span>
        <span> [{{ this.searchCtrl.value ? dataSource.filteredData.length + '/' : '' }}{{ dataSource.data.length }}] </span>
      </h2>

      <span class="flex-1"></span>

      <div class="bg-card rounded-md border px-2 relative hidden md:flex justify-start items-center content-center">
        <fa-icon [icon]="['fad', 'search']" size="lg" [fixedWidth]="true"></fa-icon>
        <input
          #filterInput
          [formControl]="searchCtrl"
          class="pl-2 py-2 border-0 outline-none w-full bg-transparent"
          placeholder="Search Apps..."
        />
        <button
          mat-icon-button
          [style.visibility]="searchCtrl.value ? 'visible' : 'hidden'"
          (click)="searchCtrl.setValue(''); filterInput.focus()"
          class="flex items-center justify-center w-6 h-6"
          matSuffix
        >
          <fa-icon [icon]="['fad', 'times']" [fixedWidth]="true"></fa-icon>
        </button>
      </div>

      <mon-display-config-button
        matTooltip="Filter Columns"
        *ngIf="displayConfig$ | async as config"
        persistAt="User"
        [config]="config"
        [canHide]="false"
        [icon]="['fad', 'columns']"
        class="ml-2 flex items-center justify-center flex-none"
      ></mon-display-config-button>
    </div>

    <div *ngIf="filtering$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
      <div class="absolute w-full h-full bg-gray opacity-10"></div>
      <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
    </div>

    <table
      [dataSource]="dataSource"
      [@listStagger]="dataSource.data.length"
      class="w-full table-fixed"
      mat-table
      matSort
      [matSortActive]="this.sortField"
      [matSortDirection]="this.sortDirection"
    >
      <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->

      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase w-1/4" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Number Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <ng-container *matRowDef="let row; columns: visibleColumns">
          <tr @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
        </ng-container>
      </ng-container>
    </table>
  </div>

  <div class="card p-4 mt-2">
    <div class="flex justify-between items-center">
      <div class="text-lg">Login Sampling</div>
      <div class="text-secondary">Based On 2 Minutes</div>
    </div>
    <mon-login-metrics></mon-login-metrics>
  </div>
</ng-container>
