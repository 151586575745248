import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubscriptionComponent } from 'src/app/shared/components/subscription/subscription.component';
import { SharedModule } from 'src/app/shared/shared.module';

import { ExpiredSubscriptionComponent } from './expired-subscription.component';

@NgModule({
  declarations: [ExpiredSubscriptionComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatStepperModule,
    ReactiveFormsModule,
    SharedModule,
    SubscriptionComponent
  ],
  exports: [ExpiredSubscriptionComponent]
})
export class ExpiredSubscriptionModule {}
