<ng-container *ngIf="renderData$ | async"></ng-container>
<div @fadeInUp @fadeOutUp class="mt-2">
  <h2 class="m-0 title mb-4">JWT Token</h2>

  <div *ngIf="jwtToken$ | async as jwtToken" class="relative jwt-token">
    <pre class="flex">
    <code class="whitespace-normal text-base p-4 break-all hljs">
      <ng-container *ngIf="splitJWTToken$ | async as split">
        <span class="text-red-400">{{ split[0] }}</span
        >.<span class="text-purple-500">{{ split[1] }}</span
        >.<span class="text-blue-300">{{ split[2] }}</span>
      </ng-container>
    </code>
    </pre>
    <button
      #me="matTooltip"
      matTooltipHideDelay="2000"
      matTooltipDisabled
      matTooltip="Copied"
      class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
      (click)="getCopy(me, jwtToken)"
    >
      copy
    </button>
  </div>

  <h2 class="m-0 title my-4">Agent Install</h2>

  <p class="text-secondary">Open an <b>elevated</b> PowerShell command line and enter the following to install the agent as a service</p>

  <blockquote class="whitespace-normal break-all">
    This will install the agent into your program files directory: %ProgramW6432%\Liquidware\CommandCTRL\
  </blockquote>

  <div  class="relative">
    <markdown [data]="winInstallCode | language: 'powershell'"> </markdown>
    <button
      data-intro-key="copy-text-button"
      #install="matTooltip"
      matTooltipHideDelay="2000"
      matTooltipDisabled
      matTooltip="Copied"
      class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
      (click)="getCopy(install, winInstallCode)"
    >
      copy
    </button>
  </div>

  <p class="text-secondary pt-4 pb-4">To uninstall, run the following in an <b>elevated</b> Powershell command line</p>

  <div  class="relative">
    <markdown [data]="winUninstallCode | language: 'powershell'"> </markdown>
    <button
      #uninstall="matTooltip"
      matTooltipHideDelay="2000"
      matTooltipDisabled
      matTooltip="Copied"
      class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
      (click)="getCopy(uninstall, winUninstallCode)"
    >
      copy
    </button>
  </div>

  <p class="text-secondary pt-4 pb-4">Your agent ID is your machine (host) name. Agent ID can also be found in the logs produced:</p>

  <div class="relative">
    <markdown [data]="logExample | language: ''"> </markdown>
  </div>

  <p class="text-secondary pt-4 pb-4">
    To update the agent with the latest API Key, run the following in an <b>elevated</b> Powershell command line
  </p>

  <div class="relative">
    <markdown [data]="winUpdateCode | language: 'powershell'"> </markdown>
    <button
      #update="matTooltip"
      matTooltipHideDelay="2000"
      matTooltipDisabled
      matTooltip="Copied"
      class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
      (click)="getCopy(update, winUpdateCode)"
    >
      copy
    </button>
  </div>

  <ng-container *ngIf="fullStoryLink">
    <h2 class="title my-4">FullStory</h2>

    <div class="relative">
      <markdown [data]="fullStoryLink | language: ''"> </markdown>
      <button
        #me="matTooltip"
        matTooltipHideDelay="2000"
        matTooltipDisabled
        matTooltip="Copied"
        class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
        (click)="getCopy(me, fullStoryLink)"
      >
        copy
      </button>
    </div>
  </ng-container>

  <div *ngIf="isPubSubWsConnected$ | async as isPubSubWsConnected; else notConnected" class="relative mt-2">
    <h2 class="m-0 title mb-4">PubSubWs Connection</h2>
    <mat-slide-toggle [color]="color" [(ngModel)]="isPubSubWsConnected$">Is Connected: {{ isPubSubWsConnected }}</mat-slide-toggle>
  </div>
  <ng-template #notConnected>
    <h2 class="m-0 title mb-4">PubSubWs Connection</h2>
    <div>Not connected, Attempting to connect ...</div>
  </ng-template>
  <ng-container *ngIf="agent$ | async as agent">
    <form autocomplete="off">
      <button type="submit" mat-raised-button class="px-4 md:px-8" color="primary">Test Pub</button>
    </form>
  </ng-container>
</div>
