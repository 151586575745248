<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex justifty-start items-center" mat-dialog-title>
      <div *ngIf="!loading" class="flex-1 flex flex-col gap-2">
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">{{ detailGroup.get('name').value || 'New Trigger' }}</h2>
        <div *ngIf="!params.copy; else copied" class="text-primary text-2xs cursor-pointer" (click)="createCopy()">Copy This Trigger</div>
        <ng-template #copied>
          <div class="text-secondary text-2xs">This is a copy of {{ copyOf }}</div>
        </ng-template>
      </div>

      <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
        <fa-icon [icon]="['fad', 'times']" size="lg" class="leading-none"></fa-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-stepper orientation="vertical" linear (selectionChange)="onStep($event)">
      <ng-template matStepperIcon="edit">
        <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
      </ng-template>

      <mat-step [stepControl]="detailGroup" label="Detail">
        <form autocomplete="off" [formGroup]="detailGroup">
          <mat-dialog-content class="flex flex-col overflow-visible">
            <div class="flex flex-col gap-2 mt-4">
              <mat-form-field fxFlex="auto">
                <mat-label>Name</mat-label>
                <input formControlName="name" matInput #nameInput />
                <mat-error *ngIf="detailGroup.controls.name.errors?.required">Name can not be empty</mat-error>
                <mat-error *ngIf="detailGroup.controls.name.errors?.maxlength">Name can not be more than 50 characters</mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="auto">
                <mat-label>Description</mat-label>
                <input formControlName="description" matInput #descriptionInput />
                <mat-error *ngIf="detailGroup.controls.description.errors?.required">Name can not be empty</mat-error>
                <mat-error *ngIf="detailGroup.controls.description.errors?.maxlength">Name can not be more than 50 characters</mat-error>
              </mat-form-field>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button [disabled]="detailGroup.invalid" color="primary" mat-raised-button matStepperNext>Next</button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step [stepControl]="conditionsGroup" label="Conditions">
        <form autocomplete="off" [formGroup]="conditionsGroup">
          <mat-dialog-content class="flex flex-col overflow-visible">
            <div class="max-h-20 overflow-x-hidden overflow-y-auto" #conditionInputs>
              <div class="grid grid-flow-row grid-cols-12 gap-2 w-[600px]">
                <ng-container *ngFor="let cIndex of conditionIndices; index as i">
                  <div *ngIf="i > 0" class="col-span-12 flex items-center justify-center text-secondary text-2xs">AND</div>
                  <mat-form-field class="col-span-5 mb-3">
                    <mat-label>Field</mat-label>
                    <mat-select class="condition-name" [formControlName]="'field' + cIndex" #selectedValue>
                      <mat-optgroup *ngFor="let group of fieldGroups" [label]="group.name" [disabled]="group.disabled">
                        <mat-option *ngFor="let f of group.field" [value]="f.value">
                          {{ f.viewValue }}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="col-span-1 min-w-[3rem]">
                    <mat-label>Op</mat-label>
                    <mat-select class="condition-name text-center" [formControlName]="'op' + cIndex">
                      <mat-select-trigger>
                        {{ conditionsGroup.controls['op' + cIndex].value }}
                      </mat-select-trigger>
                      <mat-option *ngFor="let op of ops" [value]="op.name">
                        <div class="flex items-center justify-start gap-2">
                          <span class="w-6">{{ op.name }}</span>
                          <span class="text-secondary text-2xs italic">({{ op.description }})</span>
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="col-span-3">
                    <mat-label>Value</mat-label>
                    <input [formControlName]="'value' + cIndex" matInput class="text-right" />
                    <span matSuffix class="pl-1">{{ getMetricUnit | callOnce: fieldGroups:selectedValue.value }}</span>
                    <mat-error *ngIf="getConditionError('field' + cIndex, 'maxlength')">200 characters max</mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-span-2">
                    <mat-label>Interval</mat-label>
                    <input [formControlName]="'intervalDuration' + cIndex" matInput class="text-right" />
                    <mat-error *ngIf="getConditionError('field' + cIndex, 'maxlength')">200 characters max</mat-error>
                  </mat-form-field>

                  <div *ngIf="!trigger.system" class="col-span-1 flex items-center justify-center">
                    <button
                      color="warn"
                      class="w-8 h-8 leading-none flex items-center justify-center hover:bg-red-light transition duration-200 ease-in-out"
                      mat-icon-button
                      [disabled]="this.conditionIndices.length === 1"
                      (click)="removeCondition(cIndex)"
                    >
                      <fa-icon [icon]="['fad', 'times']"></fa-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>

            <div *ngIf="!trigger.system" class="mt-2">
              <button mat-mini-fab color="primary" (click)="addCondition()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
              </button>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button color="primary" mat-button matStepperPrevious type="button">Back</button>
              <button [disabled]="conditionsGroup.invalid" color="primary" mat-raised-button matStepperNext>Next</button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step [stepControl]="confirmGroup" label="Confirm">
        <form autocomplete="off" [formGroup]="confirmGroup">
          <mat-dialog-content class="flex flex-col gap-2 overflow-visible">
            <div>{{ detailGroup.controls.name.value }}</div>
            <div class="text-secondary italic">{{ detailGroup.controls.description.value }}</div>
            <div class="flex flex-col">
              <div class="text-secondary">Condition{{ conditionIndices.length === 1 ? '' : 's' }}:</div>
              <ng-container *ngFor="let index of conditionIndices; index as i">
                <div class="flex gap-2">
                  <div *ngIf="i > 0" class="col-span-12 flex items-center justify-center text-secondary text-2xs">AND</div>
                  <div>{{
                    toFriendlyCondition
                      | callOnce
                        : conditionsGroup.controls['field' + index].value
                        : conditionsGroup.controls['op' + index].value
                        : conditionsGroup.controls['value' + index].value
                        : conditionsGroup.controls['intervalDuration' + index].value
                  }}</div>
                </div></ng-container
              >
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button color="primary" mat-button matStepperPrevious type="button">Back</button>
              <button
                *ngIf="!trigger.system"
                (click)="save()"
                [disabled]="confirmGroup.invalid"
                color="primary"
                mat-raised-button
                matStepperNext
              >
                CONFIRM
              </button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>
    </mat-stepper>
    <div *ngIf="trigger.system" class="text-secondary italic text-2xs mb-2">System triggers can only be enabled or disabled</div>
  </div>
</mon-spinner>
