<ng-container *ngIf="sortSettings$ | async"> </ng-container>
<ng-container *ngIf="agentStats$ | async"></ng-container>
<ng-container *ngIf="checkQueryAbility$ | async"></ng-container>
<div @fadeInUp class="card relative overflow-x-auto overflow-y-hidden h-full">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center gap-4">
    <h2 class="title my-0 pr-4 mr-4 border-r flex-none">
      <span>Processes [{{ this.searchCtrl.value ? dataSource.filteredData.length + '/' : '' }}{{ dataSource.data.length }}]</span>
    </h2>

    <span class="flex-1"></span>

    <div class="h-full flex items-center justify-center">
      <div class="bg-card rounded-md border px-2 relative hidden md:flex justify-start items-center content-center h-10 w-60">
        <mat-select
          multiple
          disableOptionCentering
          (valueChange)="userSelectionChange($event)"
          [value]="sessionFilter"
          #userFilter
          placeholder="Users"
        >
          <mat-option *ngFor="let user of processUsers$ | async | keyvalue" [value]="user.key" [ngClass]="{ italic: !user.value }">{{
            user.value || 'no username'
          }}</mat-option>
        </mat-select>
      </div>
    </div>

    <div class="h-full flex items-center justify-center">
      <div class="bg-card rounded-md border px-2 relative hidden md:flex justify-start items-center content-center h-10">
        <input
          matInput
          #filterInput
          [formControl]="searchCtrl"
          class="pl-2 py-2 border-0 outline-none w-full bg-transparent"
          placeholder="Search Processes..."
          autocomplete="off"
      /></div>
    </div>

    <button
      class="ml-4 flex-none text-red dark:text-red"
      mat-icon-button
      matTooltip="Kill Processes"
      type="button"
      [disabled]="selection.isEmpty()"
      (click)="killSelected(selection.selected)"
    >
      <fa-icon [icon]="['fas', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
    </button>

    <mon-display-config-button
      matTooltip="Filter Columns"
      *ngIf="displayConfig$ | async as config"
      persistAt="User"
      [config]="config"
      [canHide]="false"
      [icon]="['fad', 'columns']"
      class="ml-2 flex items-center justify-center flex-none"
    ></mon-display-config-button>
  </div>

  <div *ngIf="filtering$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
    <div class="absolute w-full h-full bg-gray opacity-10"></div>
    <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
  </div>

  <ng-container *ngIf="hasQueryAbility$ | async as hasQueryAbility">
    <!-- <cdk-virtual-scroll-viewport [tvsItemSize]="29" [headerHeight]="24" class="full-virtual-scroll-table"> -->
    <table
      [@listStagger]="dataSource.data.length"
      [dataSource]="dataSource"
      mat-table
      matSort
      [matSortActive]="this.sortField"
      [matSortDirection]="this.sortDirection"
      class="w-full"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            (change)="masterToggle($event)"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox
            (change)="$event ? selection.toggle(row) : null"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Collapse Column -->
      <ng-container matColumnDef="collapsed">
        <th *matHeaderCellDef mat-header-cell class="w-[25px]"></th>
        <td *matCellDef="let row" mat-cell>
          <div>
            <a
              *ngIf="row.hasServices || row.hasConnections"
              (click)="toggleRowDetail($event, row)"
              [ngClass]="{ collapsed: row.collapsed }"
              class="row-expander h-8 leading-none flex items-center justify-center hover:bg-hover"
              mat-icon-button
            >
              <fa-icon [icon]="['fad', 'angle-right']" size="lg"> </fa-icon>
            </a>
          </div>
        </td>
      </ng-container>

      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" [ngClass]="column.headerClasses" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" mat-cell>
            <div class="relative">
              <div class="flex items-center">
                <div tipOnEllip [ngClass]="column.cellClasses">{{ row[column.property] }}</div>
                <ng-container *ngIf="column.canQuery && hasQueryAbility.value">
                  <button
                    mat-icon-button
                    #copyTooltip="matTooltip"
                    matTooltip="Query Info"
                    class="flex items-center justify-center ml-1"
                    (click)="queryProcess(row); $event.stopPropagation()"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 fill-teal" viewBox="0 0 24 24">
                      <path
                        d="M22.282 9.821a5.985 5.985 0 0 0-.516-4.91 6.046 6.046 0 0 0-6.51-2.9A6.065 6.065 0 0 0 4.981 4.18a5.985 5.985 0 0 0-3.998 2.9 6.046 6.046 0 0 0 .743 7.097 5.98 5.98 0 0 0 .51 4.911 6.051 6.051 0 0 0 6.515 2.9A5.985 5.985 0 0 0 13.26 24a6.056 6.056 0 0 0 5.772-4.206 5.99 5.99 0 0 0 3.997-2.9 6.056 6.056 0 0 0-.747-7.073zM13.26 22.43a4.476 4.476 0 0 1-2.876-1.04l.141-.081 4.779-2.758a.795.795 0 0 0 .392-.681v-6.737l2.02 1.168a.071.071 0 0 1 .038.052v5.583a4.504 4.504 0 0 1-4.494 4.494zM3.6 18.304a4.47 4.47 0 0 1-.535-3.014l.142.085 4.783 2.759a.771.771 0 0 0 .78 0l5.843-3.369v2.332a.08.08 0 0 1-.033.062L9.74 19.95a4.5 4.5 0 0 1-6.14-1.646zM2.34 7.896a4.485 4.485 0 0 1 2.366-1.973V11.6a.766.766 0 0 0 .388.676l5.815 3.355-2.02 1.168a.076.076 0 0 1-.071 0l-4.83-2.786A4.504 4.504 0 0 1 2.34 7.872zm16.597 3.855-5.833-3.387L15.119 7.2a.076.076 0 0 1 .071 0l4.83 2.791a4.494 4.494 0 0 1-.676 8.105v-5.678a.79.79 0 0 0-.407-.667zm2.01-3.023-.141-.085-4.774-2.782a.776.776 0 0 0-.785 0L9.409 9.23V6.897a.066.066 0 0 1 .028-.061l4.83-2.787a4.5 4.5 0 0 1 6.68 4.66zm-12.64 4.135-2.02-1.164a.08.08 0 0 1-.038-.057V6.075a4.5 4.5 0 0 1 7.375-3.453l-.142.08-4.778 2.758a.795.795 0 0 0-.393.681zm1.097-2.365 2.602-1.5 2.607 1.5v2.999l-2.597 1.5-2.607-1.5Z"
                      />
                    </svg>
                  </button>
                </ng-container>
                <ng-container *ngIf="column.canCopy && row[column.property]">
                  <button
                    mat-icon-button
                    #copyTooltip="matTooltip"
                    matTooltip="Copy"
                    class="flex items-center justify-center copy-button ml-1"
                    (click)="copy(copyTooltip, row[column.property]); $event.stopPropagation()"
                  >
                    <fa-icon [icon]="['fad', 'copy']" size="lg" class="text-primary"></fa-icon>
                  </button>
                </ng-container>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- Number Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" [ngClass]="column.headerClasses" mat-header-cell mat-sort-header>
            {{ column.label }}
          </th>
          <td *matCellDef="let row" mat-cell>
            <div tipOnEllip [ngClass]="column.cellClasses">{{ row[column.display || column.property] }}</div>
          </td>
        </ng-container>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex float-right max-w-[25px]">
            <button
              (click)="killProcess($event, row)"
              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-red-light transition duration-200 ease-in-out"
              mat-icon-button
              matTooltip="Kill"
            >
              <fa-icon [icon]="['fad', 'times']" size="lg" class="text-red"> </fa-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
        <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
        <ng-container *matRowDef="let row; columns: visibleColumns">
          <tr
            mat-row
            @fadeInRight
            class="hover:bg-hover hover:bg-opacity-50 trans-ease-out cursor-pointer text-secondary"
            (click)="highlightPID(row.processID)"
            [class.highlighted-row]="row.highlight"
            [class.selected-row]="row.processID === _highlightPID"
          ></tr>

          <tr *ngIf="(row.hasServices || row.hasConnections) && !row.collapsed" class="bg-base process-row-detail">
            <td [colSpan]="visibleColumns.length" class="p-4">
              <mat-accordion *ngIf="densitySettings$ | async as densitySettings" hideToggle="true" multi="true">
                <mat-expansion-panel *ngIf="row.hasServices" expanded="true">
                  <mat-expansion-panel-header
                    [collapsedHeight]="densitySettings === DisplayDensity.Compact ? '32px' : '48px'"
                    [expandedHeight]="densitySettings === DisplayDensity.Compact ? '32px' : '48px'"
                  >
                    <mat-panel-title>SERVICES</mat-panel-title>
                  </mat-expansion-panel-header>

                  <table class="w-full">
                    <tbody>
                      <ng-container *ngFor="let service of row.services">
                        <tr *ngIf="service.state != 1" class="h-8">
                          <td>{{ service.displayName }}</td>
                          <td>{{ ServiceState[service.state] }}</td>
                          <td>
                            <button
                              (click)="stopService(service)"
                              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover"
                              matTooltip="Stop Service"
                              mat-icon-button
                            >
                              <fa-icon [icon]="['fad', 'stop']" size="lg" class="text-red"> </fa-icon>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="row.hasConnections" expanded="true">
                  <mat-expansion-panel-header
                    [collapsedHeight]="densitySettings === DisplayDensity.Compact ? '32px' : '48px'"
                    [expandedHeight]="densitySettings === DisplayDensity.Compact ? '32px' : '48px'"
                  >
                    <mat-panel-title> CONNECTIONS </mat-panel-title>
                  </mat-expansion-panel-header>

                  <table class="w-full">
                    <thead>
                      <tr>
                        <th class="text-left">Address</th>
                        <th class="text-right">Send</th>
                        <th class="text-right">Receive</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let connection of row.connections">
                        <tr class="h-8">
                          <td class="text-left">
                            {{ connection.localAddress || connection.localIP }}:{{ connection.localPort }}
                            ➡
                            {{ connection.remoteAddress || connection.remoteIP }}:{{ connection.remotePort }}
                          </td>
                          <td class="text-right">{{ connection.txRate * 8 | friendlyBits }}/sec</td>
                          <td class="text-right">{{ connection.rxRate * 8 | friendlyBits }}/sec</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
    <!-- </cdk-virtual-scroll-viewport> -->
  </ng-container>
</div>
