<div class="flex flex-col h-full">
  <div class="script-header border-b-2 p-2">
    <div class="flex">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 flex">
          <div class="text-2xl">{{ target.agentID }}</div>
        </div>
        <div class="flex">
          <span *ngIf="target.started.getFullYear() > 0; else noStart" class="italic text-secondary" matTooltip="Created">{{
            target.started | date: 'medium'
          }}</span>
          <ng-template #noStart>
            <span class="whitespace-pre">&nbsp;</span>
          </ng-template>
          <ng-container *ngIf="target.state !== TargetState.TargetTimeout">
            <span *ngIf="target.updated.getFullYear() > 0" class="text-primary font-bold pl-4" matTooltip="Duration">
              {{ target.updated.getTime() - target.started.getTime() | timeSpan: 'short' }}
            </span>
          </ng-container>
        </div>
        <!-- <div
          *ngIf="target.state === TargetState.TargetProcessing"
          class="flex-1 flex flex-col md:flex-row items-start md:items-center justify-between w-full"
        >
          <div class="flex w-full">
            <div class="flex w-full">
              <button mat-raised-button color="primary" [style.font-variant]="'all-small-caps'" class="text-sm p-1 m-2 ml-0 last:mr-0">
                Abort
              </button>
            </div>
          </div>
        </div> -->
      </div>
      <div class="flex items-center justify-center text-4xl p-4">
        <ng-container [ngSwitch]="target.state">
          <ng-container *ngSwitchCase="TargetState.TargetSuccess">
            <fa-icon class="text-green" [icon]="['fad', 'check']" [fixedWidth]="true" matTooltip="Success"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="TargetState.TargetError">
            <fa-icon class="text-red" [icon]="['fad', 'times']" [fixedWidth]="true" matTooltip="Failed"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="TargetState.TargetAborted">
            <fa-icon class="text-secondary" [icon]="['fad', 'ban']" [fixedWidth]="true" matTooltip="Aborted"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="TargetState.TargetTimeout">
            <fa-icon class="text-orange" [icon]="['fad', 'hourglass-end']" [fixedWidth]="true" matTooltip="Timed out"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="TargetState.TargetQueued">
            <fa-icon class="text-secondary" [icon]="['fas', 'layer-group']" [fixedWidth]="true" matTooltip="Timed out"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="TargetState.TargetCreated">
            <fa-icon class="text-secondary" [icon]="['fas', 'plus']" [fixedWidth]="true" matTooltip="Created"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="TargetState.TargetProcessing">
            <fa-icon
              class="text-secondary"
              [icon]="['fad', 'loader']"
              animation="beat"
              animation="spin"
              [fixedWidth]="true"
              matTooltip="Processing"
            ></fa-icon>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="flex flex-col p-2">
    <h2 *ngIf="target.state === TargetState.TargetTimeout" class="italic bold text-secondary">{{ target.agentID }} timed out</h2>
    <h2 *ngIf="target.state === TargetState.TargetCreated" class="italic bold text-secondary">{{ target.agentID }} created</h2>
    <h2 *ngIf="target.state === TargetState.TargetQueued" class="italic bold text-secondary">{{ target.agentID }} queued</h2>
    <ng-container *ngIf="target.logs">
      <h2>Detail</h2>
      <div class="grid grid-cols-12 gap-2">
        <ng-container *ngIf="target.shellVersion">
          <div class="col-span-3 text-secondary">Shell Version</div>
          <div class="col-span-9 flex-1">{{ target.shellVersion }}</div>
        </ng-container>
        <ng-container>
          <div class="col-span-3 text-secondary">Arguments</div>
          <div class="col-span-9 flex-1" [ngClass]="target.arguments ? '' : 'italic'">
            {{ target.arguments ? target.arguments : 'none' }}
          </div>
        </ng-container>
        <ng-container *ngIf="target.exitCode">
          <div class="col-span-3 text-secondary">Exit Code</div>
          <div class="col-span-9 flex-1">{{ target.exitCode }}</div>
        </ng-container>
        <ng-container *ngIf="target.error">
          <div class="col-span-3 text-secondary">Error</div>
          <div class="col-span-9 flex-1">{{ target.error }}</div>
        </ng-container>
        <ng-container>
          <div class="col-span-3 text-secondary">Logs</div>
          <div class="col-span-9 flex-1 flex flex-col text-xs">
            <div *ngFor="let key of ObjectKeys(target.logs)" class="flex gap-2 justify-between w-full">
              <div class="whitespace-nowrap">{{ key | numberToDate: 'ns' | date: 'medium' }}</div>
              <div class="flex-1 text-secondary">{{ target.logs[key] }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="target.output; else liveOuput">
    <div class="p-2 pb-0">
      <h2>Output</h2>
    </div>
    <div [fillHeight]="true" class="flex-1 p-2 overflow-hidden">
      <div class="relative w-full h-full">
        <div class="code-wrapper absolute top-0 bottom-0 left-0 right-0 overflow-auto">
          <markdown
            emoji
            lineNumbers
            class="prose prose-sm dark:prose-invert prose-hr:mb-3 selection:bg-pink-light"
            [data]="target.output | language: 'powershell'"
          ></markdown>
        </div>
        <button
          #copyScript="matTooltip"
          matTooltip=""
          class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
          (click)="getCopy(copyScript, target.output)"
        >
          copy
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #liveOuput>
    <ng-container *ngIf="target.state === TargetState.TargetProcessing">
      <ng-container *ngIf="channelListener$ | async"></ng-container>
      <div class="flex gap-4 items-center justify-start p-2 pb-0">
        <h2>Output</h2>
        <div class="whitespace-nowrap text-lg text-right text-green animate-pulse">
          <fa-icon [icon]="['fas', 'wave-pulse']"></fa-icon>
        </div>
      </div>
      <div [fillHeight]="true" class="flex-1 p-2 overflow-hidden">
        <div class="relative w-full h-full">
          <div class="code-wrapper absolute top-0 bottom-0 left-0 right-0 overflow-auto">
            <markdown
              emoji
              lineNumbers
              class="prose prose-sm dark:prose-invert prose-hr:mb-3 selection:bg-pink-light"
              [data]="liveOutput | language: 'powershell'"
            ></markdown>
          </div>
          <button
            #copyScript="matTooltip"
            matTooltip="Copy"
            class="absolute right-4 bottom-4 code-button text-sm"
            (click)="getCopy(copyScript, liveOutput)"
          >
            copy
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
