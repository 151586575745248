<div (mouseenter)="showButton = true" (mouseleave)="showButton = false" class="geo-location-widget card h-full relative trans-shadow">
  <div class="absolute w-full border-b px-2 py-2 flex items-center justify-center mb-2 z-10" #headerContainer>
    <fa-icon [icon]="['fad', icon]" size="2x" [fixedWidth]="true" class="mr-2 text-primary"> </fa-icon>

    <h2 tipOnEllip class="m-0 title flex-auto truncate">{{ _displayConfig.name }}</h2>

    <mon-display-config-button
      *ngIf="_displayConfig"
      persistAt="User"
      [config]="_displayConfig"
      class="flex items-center justify-center text-sm w-8 h-8"
    ></mon-display-config-button>
  </div>

  <div class="flex flex-col h-full w-full relative top-0 right-0" [style.padding-top.px]="headerContainer.offsetHeight">
    <div>
      <ng-container *ngFor="let property of _properties | keyvalue">
        <div
          *ngIf="_displayConfig.fieldValue(property.key)"
          class="flex justify-start items-center content-center px-4 first:mt-2 last:mb-2"
        >
          <div class="flex-3/10 caption text-secondary m-0 whitespace-nowrap">
            {{ property.key }}
          </div>
          <div class="flex-7/10 text-left caption m-0 pl-3 truncate">
            {{ property.value }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex-1 min-h-10 max-h-96" #mapContainer>
      <google-map
        *ngIf="_displayConfig.fieldValue('Map')"
        [height]="mapContainer.offsetHeight"
        [width]="'100%'"
        [center]="_latLng"
        [zoom]="8"
        [options]="options"
        #map
      ></google-map>
    </div>
  </div>
</div>
