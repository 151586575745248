import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { mergeDeep } from 'src/@vex/utils/merge-deep';
import { ActionService } from 'src/app/core/services/action.service';
import { StateService } from 'src/app/core/services/state.service';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField,
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { Action } from 'src/app/shared/models/action.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { isEmpty } from 'src/app/utils/condition';

import { ActionsDataSource } from './actions.datasource';
import { AddActionComponent, AddActionParams } from './add-action/add-action.component';
import { mockActions } from './mock.data';

@UntilDestroy()
@Component({
  selector: 'mon-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class ActionsComponent {
  readonly DEFAULT_PAGE_SIZE = 20;
  readonly DEFAULT_SORT_FIELD = 'key';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.TOOLS_ACTIONS;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize = this.DEFAULT_PAGE_SIZE;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource = new ActionsDataSource(this.actionSvc);

  columns: TableColumn<Action>[] = [
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: false,
      buttonIconName: 'angle-right',
      tooltip: 'View'
    }
  ];

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    filter((settings) => !!settings),
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        pageSize: found?.pageSize || this.DEFAULT_PAGE_SIZE,
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.pageSize = isEmpty(settings.pageSize)
        ? this.DEFAULT_PAGE_SIZE
        : settings.pageSize;
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    }),
    tap(() => this.loadActions())
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  controlsPopulated = (): boolean => !!this.paginator && !!this.sort;
  paginator: MatPaginator;

  @ViewChild(MatPaginator) set paginatorSetter(paginator: MatPaginator) {
    if (!this.paginator && !!paginator) {
      this.paginator = paginator;
      this.initControls();
    }
  }

  sort: MatSort;

  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  get hideableColumns(): TableColumn<Action>[] {
    return this.columns.filter((c) => c.hideable);
  }

  constructor(
    private dialog: MatDialog,
    private stateSvc: StateService,
    private actionSvc: ActionService
  ) {}

  initControls(): void {
    if (this.controlsPopulated()) {
      this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};

            table[this.tableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };

            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();

      this.paginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            this.pageIndex = page.pageIndex;
            if (this.pageSize !== page.pageSize) {
              const table = {};
              table[this.tableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            } else {
              this.loadActions();
            }
          })
        )
        .subscribe();
    }
  }

  loadActions(): void {
    this.dataSource.loadActions(this.pageSize, this.pageIndex, [
      OrderBy.SortToOrderBy({
        active: this.sortField,
        direction: this.sortDirection
      })
    ]);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  modAction(action: Action = undefined): void {
    this.dialog.open(AddActionComponent, {
      panelClass: 'actions-dialog-container',
      disableClose: false,
      restoreFocus: false,
      data: new AddActionParams({
        action: action,
        onSuccess: (action) => {
          const found = mockActions.find((a) => a.id == action.id);

          if (found) {
            mergeDeep(found, action);
          } else {
            mockActions.push(action);
            this.loadActions();
          }
        }
      })
    });
  }
}
