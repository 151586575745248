import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatAddress } from 'src/app/utils/address';
import { PaymentSource } from '../../models/subscription.model';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'mon-payment-source-view',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, SharedModule],
  templateUrl: './payment-source-view.component.html',
  styleUrls: ['./payment-source-view.component.scss']
})
export class PaymentSourceViewComponent {
  private _paymentSource: PaymentSource;

  @Input('payment-source')
  get paymentSource(): PaymentSource {
    return this._paymentSource;
  }
  set paymentSource(value: PaymentSource) {
    this._paymentSource = value;

    if (value?.card) {
      this.faPaymentSourceIcon = this.getCCIcon(value.card.cardType || '');
    } else if (value?.bankAccount) {
      this.faPaymentSourceIcon = ['fad', 'money-check-dollar-pen'];
    }
  }

  formatAddress = formatAddress;
  faPaymentSourceIcon: IconProp;

  getCCIcon(v: string): IconProp {
    switch (v) {
      case 'visa':
        return ['fab', 'cc-visa'];
      case 'mastercard':
        return ['fab', 'cc-mastercard'];
      case 'american_express':
        return ['fab', 'cc-amex'];
      case 'discover':
        return ['fab', 'cc-discover'];
      case 'jcb':
        return ['fab', 'cc-jcb'];
      case 'diners_club':
        return ['fab', 'cc-diners-club'];
    }

    return ['fad', 'credit-card'];
  }
}
