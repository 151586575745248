import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, finalize, first, switchMap } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { fadeOutDown400ms } from 'src/app/shared/animations/fade-out-down.animation';
import { fadeOut400ms } from 'src/app/shared/animations/fade-out.animation';
import { scaleOut400ms } from 'src/app/shared/animations/scale-out.animation';
import { UNDEFINED } from 'src/app/utils/rxjs';

export class TenantInvite {
  email = '';
  domain = '';
  inviteKey = '';
  tenantID = '';
  tenantName = '';
  tenantCompanyName = '';
  timestamp = new Date(0);
  userExists = false;
  constructor(init?: Partial<TenantInvite>) {
    if (init?.timestamp) {
      Object.assign(this, init);
      this.timestamp = new Date(init.timestamp);
    }
  }

  private _friendlyTenantName = undefined;
  get friendlyTenantName(): string {
    if (!this._friendlyTenantName) {
      this._friendlyTenantName =
        this.tenantCompanyName || this.tenantName || this.tenantID;
    }

    return this._friendlyTenantName;
  }
}

export interface NavState {
  invite?: string;
  inviteEmail?: string;
  token?: string;
  userConfirmed?: boolean;
}

@Component({
  selector: 'mon-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInUp400ms, scaleOut400ms, fadeOut400ms, fadeOutDown400ms]
})
export class LoginComponent implements OnInit {
  navState: NavState;

  _ready = new BehaviorSubject<boolean>(false);
  ready$ = this._ready.asObservable();

  _routeTo = new BehaviorSubject<'confirm' | undefined>(undefined);
  routeTo$ = this._routeTo.asObservable();

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    const nav = this.router.getCurrentNavigation();
    this.navState = (nav && nav.extras?.state) || {};
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        first(),
        switchMap((params: Params) => {
          const invite = params.invite || this.navState.invite;
          const userConfirmed = params.userConfirmed || this.navState.userConfirmed;
          if (invite) {
            return this.router
              .navigate([''], {
                state: { invite, userConfirmed },
                queryParams: {
                  invite: null,
                  inviteEmail: null,
                  userConfirmed: null
                },
                queryParamsHandling: 'merge'
              })
              .then(() => {
                this.router
                  .navigate(['confirm'], {
                    skipLocationChange: true,
                    state: { invite, userConfirmed },
                    queryParams: {
                      invite: null,
                      inviteEmail: null,
                      userConfirmed: null
                    },
                    queryParamsHandling: 'merge'
                  })
                  .then(() => setTimeout(() => this._routeTo.next('confirm'), 1000));
              })
              .then(() => this._ready.next(true));
          }
          return UNDEFINED;
        }),
        finalize(() => this._ready.next(true))
      )
      .subscribe();
  }

  getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
  }
}
