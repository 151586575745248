import { animate, style, transition, trigger } from '@angular/animations';

export function fadeOutUpAnimation(duration: number) {
  return trigger('fadeOutUp', [
    transition(':leave', [
      style({
        transform: 'translateY(0)',
        opacity: 1,
        left: 0,
        right: 0,
        'z-index': 10000,
        width: '100%'
      }),
      animate(
        `${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`,
        style({
          transform: 'translateY(-20px)',
          opacity: 0
        })
      )
    ])
  ]);
}

export const fadeOutUp400ms = fadeOutUpAnimation(400);
export const fadeOutUp200ms = fadeOutUpAnimation(200);
export const fadeOutUp80ms = fadeOutUpAnimation(80);
