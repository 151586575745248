import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';

@Component({
  selector: 'mon-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: [fadeInUp400ms]
})
export class SigninComponent implements OnInit {
  constructor(
    private router: Router,
    private authSvc: AuthService
  ) {}

  ngOnInit(): void {
    // this.router.navigate(['register'], { skipLocationChange: true });
  }

  signIn(): void {
    this.authSvc.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`
      },
      appState: {
        target: 'home/dashboard'
      }
    });
  }

  goToRegister(): void {
    this.router.navigate(['register']);
  }
}
