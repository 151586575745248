<div class="flex flex-col gap-[1px] justify-center w-full h-full">
  <div *ngFor="let n of data; index as i" class="w-full h-2">
    <div
      [style.width]="dataPercentages[n] + '%'"
      [style.background]="colors[i]"
      [matTooltip]="tooltips[i]"
      [attr.tooltip]="tooltips[i]"
      class="h-full transition-[width] duration-300 rounded opacity-70"
    ></div>
  </div>
</div>
