export class EventLogTypeData {
  eventLogTypes: EventLogType[];
}

export class EventLogType {
  name: string;
}

export interface EventLog {
  agent_id: string;
  agent_version: string;
  name: string;
  username: string;
  time: string;
  storage_lc_path: string;
  tag: string;
}

export enum EventLogTypeList {
  Application,
  Security,
  System
}

export const enumToArray = (enumObject: any) => {
  return Object.keys(enumObject).filter((key) => !isNaN(Number(enumObject[key])));
};
