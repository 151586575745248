<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex justifty-start items-center content-center px-8 pt-8 pb-4">
      <h2 class="headline m-0 flex-auto">Add Action</h2>

      <button class="text-secondary -mt-4 -mr-4" (click)="close()" mat-icon-button type="button" [disabled]="spin">
        <fa-icon [icon]="['fad', 'times']" size="lg"></fa-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-vertical-stepper #verticalStepper="matVerticalStepper" [linear]="true" #stepper (animationDone)="setAllMatStepsAsInteracted()">
      <ng-template matStepperIcon="edit">
        <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
      </ng-template>

      <mat-step [stepControl]="baseGroup">
        <form autocomplete="off" [formGroup]="baseGroup">
          <ng-template matStepLabel>Name</ng-template>

          <div class="subheading-1">Name and describe your action</div>

          <div class="flex flex-col gap-2 mt-4">
            <mat-form-field fxFlex="auto">
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput required cdkFocusInitial />
            </mat-form-field>

            <mat-form-field fxFlex="auto">
              <mat-label>Description</mat-label>
              <input formControlName="description" matInput />
            </mat-form-field>
          </div>

          <div class="flex justify-end items-center content-center gap-2 actions">
            <button (click)="verticalStepper.reset()" [disabled]="baseGroup.pristine" color="primary" mat-button type="button">
              RESET
            </button>
            <button [disabled]="baseGroup.invalid" color="primary" mat-raised-button matStepperNext>NEXT</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="causeGroup">
        <form autocomplete="off" [formGroup]="causeGroup">
          <ng-template matStepLabel>Cause</ng-template>

          <div class="subheading-1">Choose what condition should be met</div>

          <div class="flex flex-col gap-2 mt-4">
            <mat-form-field class="vex-flex-form-field flex-none">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type" required>
                <mat-option *ngFor="let type of causeTypes$ | async" [value]="type">{{ ActionCauseTypeLabel[type] }}</mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container [ngSwitch]="causeGroup.controls.type.value">
              <ng-container *ngSwitchCase="ActionCauseType.System">
                <mat-form-field class="vex-flex-form-field flex-none">
                  <mat-label>System Events</mat-label>
                  <mat-select formControlName="systemCause" required>
                    <mat-option *ngFor="let event of systemCauses$ | async" [value]="event">{{
                      SystemEventCauseTypeLabel[event]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngSwitchCase="ActionCauseType.Trigger">
                <mat-form-field class="vex-flex-form-field flex-none">
                  <mat-label>Triggers</mat-label>
                  <mat-select formControlName="trigger" required>
                    <mat-option *ngFor="let trigger of triggers$ | async" [value]="trigger">{{ trigger.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </div>

          <div class="flex justify-end items-center content-center gap-2 actions">
            <button color="primary" mat-button matStepperPrevious type="button">PREVIOUS</button>
            <button [disabled]="causeGroup.invalid" color="primary" mat-raised-button matStepperNext>NEXT</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="effectGroup">
        <form autocomplete="off" [formGroup]="effectGroup">
          <ng-template matStepLabel>Effect</ng-template>

          <div class="subheading-1">Choose what will happen when this condition is met</div>

          <div class="flex flex-col gap-2 mt-4">
            <mat-form-field class="vex-flex-form-field flex-none">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type" required>
                <mat-option *ngFor="let type of effectTypes$ | async" [value]="type">{{ ActionEffectTypeLabel[type] }}</mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container [ngSwitch]="effectGroup.controls.type.value">
              <ng-container *ngSwitchCase="ActionEffectType.System">
                <mat-form-field class="vex-flex-form-field flex-none">
                  <mat-label>System Action</mat-label>
                  <mat-select formControlName="systemEffect" required>
                    <mat-option *ngFor="let event of systemEffects$ | async" [value]="event">{{
                      SystemEventEffectTypeLabel[event]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngSwitchCase="ActionEffectType.RunScript">
                <mat-form-field class="vex-flex-form-field flex-none">
                  <mat-label>Scripts</mat-label>
                  <mat-select formControlName="script" required>
                    <mat-option *ngFor="let script of installedScripts$ | async" [value]="script">{{ script.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </div>

          <div class="flex justify-end items-center content-center gap-2 actions">
            <button color="primary" mat-button matStepperPrevious type="button">PREVIOUS</button>
            <button [disabled]="effectGroup.invalid" color="primary" mat-raised-button matStepperNext>NEXT</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="applyGroup">
        <form autocomplete="off" [formGroup]="applyGroup">
          <ng-template matStepLabel>Apply To</ng-template>

          <div class="subheading-1">Which machines and users should this be applied to?</div>

          <div class="flex flex-col gap-2 mt-4">
            <mat-form-field fxFlex="auto">
              <mat-label>Machines</mat-label>
              <input formControlName="machines" matInput required cdkFocusInitial />
            </mat-form-field>

            <mat-form-field fxFlex="auto">
              <mat-label>Users</mat-label>
              <input formControlName="users" matInput />
            </mat-form-field>
          </div>

          <div class="flex justify-end items-center content-center gap-2 actions">
            <button color="primary" mat-button matStepperPrevious type="button">PREVIOUS</button>
            <button [disabled]="applyGroup.invalid" color="primary" mat-raised-button matStepperNext>NEXT</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="confirmGroup">
        <form autocomplete="off" [formGroup]="confirmGroup">
          <ng-template matStepLabel>Confirm Action</ng-template>

          <div class="subheading-1">Does this look right?</div>

          <div class="h-48 flex items-center justify-center w-full p-2">
            <div class="h-full flex-1 flex flex-col items-center justify-center">
              <div class="flex-1 flex flex-col items-center justify-end text-primary text-3xl pb-2" [style.font-variant]="'small-caps'">
                <div>if</div>
              </div>
              <ng-container [ngSwitch]="causeGroup.controls.type.value">
                <ng-container *ngSwitchCase="ActionCauseType.System">
                  <div class="flex-1 flex flex-col items-center justify-start pt-2 whitespace-nowrap">
                    <div>
                      {{ ActionCauseTypeLabel[causeGroup.controls.type.value] }}
                      {{ SystemEventCauseTypeLabel[causeGroup.controls.systemCause.value] }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ActionCauseType.Trigger">
                  <div class="flex-1 flex flex-col items-center justify-start pt-2 whitespace-nowrap">
                    <div>'{{ causeGroup.controls.trigger.value?.name }}' triggers</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="h-full flex flex-col items-center justify-center px-4">
              <fa-icon class="text-primary" [icon]="['fad', 'chevron-right']" size="6x"></fa-icon>
            </div>
            <div class="h-full flex-1 flex flex-col items-center justify-center px-4">
              <div class="flex-1 flex flex-col items-center justify-end text-primary text-3xl pb-2" [style.font-variant]="'small-caps'">
                <div>then</div>
              </div>
              <ng-container [ngSwitch]="effectGroup.controls.type.value">
                <ng-container *ngSwitchCase="ActionEffectType.System">
                  <div class="flex-1 flex flex-col items-center justify-start pt-2 whitespace-nowrap">
                    <div>
                      {{ ActionEffectTypeLabel[effectGroup.controls.type.value] }}
                      {{ SystemEventEffectTypeLabel[effectGroup.controls.systemEffect.value] }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ActionEffectType.RunScript">
                  <div class="flex-1 flex flex-col items-center justify-start pt-2 whitespace-nowrap">
                    <div>Run '{{ effectGroup.controls.script.value?.name }}' script</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div class="flex justify-end items-center content-center gap-2 actions">
            <button color="primary" mat-button matStepperPrevious type="button">PREVIOUS</button>
            <button (click)="addAction()" [disabled]="confirmGroup.invalid" color="primary" mat-raised-button matStepperNext>
              CONFIRM
            </button>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</mon-spinner>
