<div class="flex flex-col">
  <!--<blockquote class="whitespace-normal break-all bg-yellow-100 border-yellow-500 text-yellow-900 m-0 mb-2">
    This is currently under construction and some data is stubbed at the moment. Stay tuned for updates.
  </blockquote>-->
  <ng-container *ngIf="settings$ | async as settings"> </ng-container>
  <ng-container *ngIf="dataSource.length > 0">
    <div @fadeInUp class="relative card overflow-x-auto overflow-y-hidden">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="title my-0 pr-4 mr-4 border-r flex-none">
          <span>Alarms</span>
        </h2>

        <span class="flex-1"></span>

        <mon-display-config-button
          matTooltip="Filter Columns"
          *ngIf="displayConfig$ | async as config"
          persistAt="User"
          [config]="config"
          [canHide]="false"
          [icon]="['fad', 'columns']"
          class="ml-2 flex items-center justify-center flex-none"
        ></mon-display-config-button>
      </div>

      <div *ngIf="dataSource.loading$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
        <div class="absolute w-full h-full bg-gray opacity-10"></div>
        <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
      </div>

      <table
        [dataSource]="dataSource"
        mat-table
        matSort
        [matSortActive]="this.sortField"
        [matSortDirection]="this.sortDirection"
        [@listStagger]="dataSource ? dataSource.length : 0"
        class="w-full"
      >
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header [mat-sort-header]="column.display || column.property">
              {{ column.label }}
            </th>
            <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
            <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
              <ng-container [ngSwitch]="column.dateFormat">
                <ng-container *ngSwitchCase="'timeAgo'">
                  {{ row[column.property] | timeAgo }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ row[column.property] | date: column.dateFormat || 'medium' }}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
          <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: visibleColumns" @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer"></tr>
        </ng-container>
      </table>

      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="dataSource ? dataSource.total : 0">
      </mat-paginator>
    </div>
  </ng-container>

  <div *ngIf="dataSource ? dataSource.length === 0 : false" class="h-full md:h-auto flex flex-col gap-y-2">
    <div @fadeInUp class="h-full md:h-auto card overflow-x-auto overflow-y-hidden">
      <div class="flex flex-col justify-center items-center content-center py-6 sm:py-12 h-full">
        <fa-icon [icon]="['fad', 'telescope']" size="5x" class="text-primary text-base sm:text-4xl"></fa-icon>
        <h2 class="m-0 pt-4 text-secondary text-base sm:text-xl">Nothing Here To See</h2>
      </div>
    </div>
  </div>
</div>
