import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strictDate',
  pure: true
})
export class StrictDatePipe extends DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): any {
    if (value instanceof Date) {
      return super.transform(value, format, timezone, locale);
    }
    return value;
  }
}
