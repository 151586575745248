import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/@vex/services/layout.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'mon-bottom-drawer',
  templateUrl: './bottom-drawer.component.html',
  styleUrls: ['./bottom-drawer.component.scss']
})
export class BottomDrawerComponent {
  @Input() collapsed: boolean;
  @Input() height: number;
  @Input() open: boolean;
  @Input() showDrawer = false;
  collapsedOpen$ = this.layoutService.bottomDrawerCollapsedOpen$;
  isMobile$ = this.layoutService.isMobile$;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private stateSvc: StateService
  ) {}

  onMouseEnter(): void {
    this.layoutService.collapseOpenBottomDrawer();
  }

  onMouseLeave(): void {
    this.layoutService.collapseCloseBottomDrawer();
  }

  toggleCollapse(): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          bottomDrawer: {
            state: this.collapsed ? 'expanded' : 'collapsed'
          }
        }
      }
    });
  }

  gotoTasks(): void {
    this.router.navigate(['home', 'tasks'], {
      queryParamsHandling: 'preserve'
    });
  }
}
