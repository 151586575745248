export class strings {
  static Format(str: string, ...args: string[]): string {
    return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
  }

  static capitalizeWords(str: string): string {
    return str.replace(/(?:^|\s)\S/g, (res) => {
      return res.toUpperCase();
    });
  }
}
