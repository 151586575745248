<vex-secondary-toolbar
  introKey="agent-toolbar"
  [header]="agent.id"
  [icon]="agent.system.platformIcon"
  subheader="{{ agent.timestamp.toLocaleString() }}"
  subheader2="{{ agent.version }}"
>
  <div class="flex self-center flex-auto justify-between gap-2">
    <div class="flex flex-col items-center justify-center">
      <div class="hidden md:flex items-center justify-center animate-blur-in gap-2">
        <button
          (click)="toggleLiveMode(!liveMode)"
          class="py-1 px-2 hover:bg-primary-light transition duration-200 ease-in-out"
          mat-button
          type="button"
        >
          <div class="flex items-center justify-center gap-1">
            <fa-icon [icon]="['fad', 'clock-rotate-left']" class="pr-2 item-icon item-color text-primary text-2xl"></fa-icon>
            <div class="text-2xs leading-4 hidden md:block">{{ liveMode ? 'View History' : 'View Real-time' }}</div>
          </div>
        </button>
        <ng-container>
          @if (agent.stats.summary.system.agentHasStratusphereUX) {
            <div><button class="text-2xs leading-4" mat-button [matMenuTriggerFor]="uxMenu">Stratusphere History</button> </div>
          }
        </ng-container>
        <ng-container *ngIf="liveMode">
          <ng-container>
            <button
              *hasPerm="[permission.Shell_Invoke]"
              data-intro-key="agent-shell-icon"
              (click)="openShell(agent.id)"
              matTooltip="Open Shell"
              class="py-1 pl-0 pr-2 hover:bg-primary-light transition duration-200 ease-in-out"
              mat-button
              type="button"
            >
              <div class="flex items-center justify-center gap-1">
                <fa-stack class="text-primary">
                  <fa-icon [icon]="['fas', 'square']" stackItemSize="2x" class="item-icon item-color text-3xs"></fa-icon>
                  <fa-icon [icon]="['fad', 'terminal']" [inverse]="true" stackItemSize="1x" class="text-3xs"></fa-icon>
                </fa-stack>
                <div class="text-2xs leading-4 hidden md:block">Shell</div>
              </div>
            </button>
          </ng-container>
          <button
            *hasPerm="
              [
                permission.MachineDiagnosticSpeedtest_Read,
                permission.MachineDiagnosticPing_Read,
                permission.MachineDiagnosticAgentLogs_Download,
                permission.MachineDiagnosticGroupPolicyReport_Download
              ];
              has: 'any'
            "
            (click)="gotoURL(['home', 'diagnostics', 'agent-tools'])"
            matTooltip="Go To Diagnostics"
            class="py-1 px-2 hover:bg-primary-light transition duration-200 ease-in-out"
            mat-button
            type="button"
          >
            <div class="flex items-center justify-center gap-1">
              <fa-icon [icon]="['fad', 'toolbox']" class="pr-2 item-icon item-color text-primary text-2xl"></fa-icon>
              <div class="text-2xs leading-4 hidden md:block">Diagnostics</div>
            </div>
          </button>
          <ng-container *ngIf="agent.system.os === OS.WINDOWS">
            <button
              *hasPerm="[permission.Script_Invoke]"
              (click)="searchScript()"
              matTooltip="Run Script"
              class="py-1 px-2 hover:bg-primary-light transition duration-200 ease-in-out"
              mat-button
              type="button"
            >
              <div class="flex items-center justify-center gap-1">
                <fa-icon [icon]="['fad', 'scroll']" class="pr-2 item-icon item-color text-primary text-2xl"></fa-icon>
                <div class="text-2xs leading-4 hidden md:block">Scripts</div>
              </div>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <ng-container *hasPerm="[permission.MachineSession_List]">
      <div *ngIf="outgoingSessions && outgoingSessions.length > 0" data-intro-key="agent-remote-icon" class="animate-blur-in">
        <button
          [matTooltip]="getRemoteSessionsTooltip(outgoingSessions)"
          class="hover:bg-deep-orange-light transition duration-200 ease-in-out"
          (click)="gotoOutgoingSession(outgoingSessions)"
          mat-icon-button
          type="button"
        >
          <div class="inline-flex">
            <fa-icon [icon]="['fad', 'chart-network']" class="text-deep-orange inline-flex text-2xl"></fa-icon>
            <fa-icon [icon]="['fad', 'chart-network']" class="text-deep-orange inline-flex animate-ping absolute text-2xl"> </fa-icon>
          </div>
        </button>
        <div [matMenuTriggerFor]="gotoRemoteMenu" [matMenuTriggerData]="{ sessions: outgoingSessions }" #remoteMenu="matMenuTrigger"></div>
      </div>
    </ng-container>

    <div class="flex-1 flex items-center justify-end">
      <div
        [matTooltip]="liveMode ? 'Real-time Mode On' : 'Real-time Mode Off'"
        data-intro-key="live-playback-button"
        class="flex items-center justify-center"
      >
        <mat-slide-toggle
          (change)="toggleLiveMode($event.checked)"
          class="text-secondary"
          [style.font-variant]="'small-caps'"
          [color]="'accent'"
          [checked]="liveMode"
          [disabled]="false"
          [labelPosition]="'before'"
        >
          {{ liveMode ? 'Real-time' : 'History Playback' }}
        </mat-slide-toggle>
      </div>
    </div>
    <div class="flex items-center justify-center w-12">
      <div data-intro-key="agent-connection-status-icon" class="flex-1 flex items-center justify-center h-full">
        <div
          @scaleIn
          *ngIf="agentBlocks.length > 0; else notBlocked"
          matTooltip="Blocked From Connecting"
          class="text-red flex items-center cursor-pointer"
          [routerLink]="['inventory', 'blocked']"
        >
          <fa-icon [icon]="['fad', 'ban']" class="inline-flex text-2xl"></fa-icon>
        </div>
        <ng-template #notBlocked>
          <div data-intro-key="agent-disconnected-icon" class="flex items-center justify-center w-full h-full">
            <div @scaleIn *ngIf="agentConnectionState === 'connecting'" matTooltip="Connecting..." class="text-gray flex items-center">
              <fa-icon [icon]="['fad', 'waveform-path']" class="inline-flex animate-pulse text-2xl"></fa-icon>
            </div>
            <div
              @scaleIn
              *ngIf="agentConnectionState !== 'connecting'"
              [ngClass]="liveMode ? 'text-green' : 'text-gray'"
              class="flex items-center justify-center"
            >
              <ng-container *ngIf="agentConnectionState === 'connected'">
                <div [matTooltip]="liveMode ? 'Agent Connected' : undefined" class="flex items-center">
                  <fa-icon [icon]="['fad', 'heartbeat']" [ngClass]="liveMode ? 'animate-pulse' : ''" class="inline-flex text-2xl"></fa-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="agentConnectionState === 'disconnected'">
                <button
                  mat-icon-button
                  type="button"
                  matTooltip="Agent Disconnected"
                  data-intro-key="live-playback-button"
                  class="hover:bg-yellow-light transition duration-200 ease-in-out"
                  (click)="noConnectionHelp()"
                >
                  <div class="flex items-center justify-center text-red">
                    <fa-icon [icon]="['fad', 'heart-broken']" class="inline-flex animate-ping absolute text-2xl"></fa-icon>
                    <fa-icon [icon]="['fad', 'heart-broken']" class="inline-flex text-2xl"></fa-icon>
                  </div>
                </button>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <mat-menu #gotoRemoteMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template matMenuContent let-sessions="sessions">
      <button *ngFor="let session of sessions" class="mat-menu-item" (click)="openMachineInNewTab(session.agentID)">
        On {{ session.machine }} as {{ session.getUsers(agent.system.hostname.toLowerCase()).join(', ') }}
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #uxMenu="matMenu" xPosition="before" yPosition="below">
    @for (t of uxHistoryTimeValues; track t) {
      <button mat-menu-item target="_blank" (click)="openUX(agent.stats.summary.system.agentHasStratusphereUX, t, agent.id)">
        <span class="capitalize">{{ t }}</span>
      </button>
    }
  </mat-menu>
</vex-secondary-toolbar>
