import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private authSvc: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authSvc.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated !== undefined),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          this.router.navigate(['home', 'dashboard'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
          });
        }
        return !isAuthenticated;
      })
    );
  }
}
