import { IconName } from '@fortawesome/fontawesome-svg-core';

import { Updatable } from '../interfaces/updatable.interface';

export enum OS {
  WINDOWS = 'windows',
  DARWIN = 'darwin',
  LINUX = 'linux'
}

export class System implements Updatable<System> {
  public hostname: string;
  public domain: string;
  public os: OS;
  public platform: string;
  public platformFamily: string;
  public platformVersion = '';
  public platformReleaseID: string;
  public platformDisplayVersion: string;
  public kernelVersion: string;
  public kernelArch: string;
  public bootTime: Date;
  public virtualizationSystem: string;
  public virtualizationRole: string;
  public hostID: string;
  public bios: BIOS;
  public cpu: CPU;
  public gpu: Array<GPU>;
  public memory: Memory;

  get platformIcon(): IconName {
    switch (this.os) {
      case OS.WINDOWS:
        return 'windows';
      case OS.DARWIN:
        return 'apple';
    }
    return null;
  }

  get platformD(): string {
    switch (this.os) {
      case OS.DARWIN:
        return (
          `macOS ${this.platformDisplayVersion} Version ${this.platformVersion}\n` +
          `${this.kernelArch}`
        );
      case OS.LINUX:
        return (
          `${this.platform}\n` +
          `${this.platformDisplayVersion} Version ${this.platformVersion}\n` +
          `${this.kernelArch}`
        );
      case OS.WINDOWS:
        return (
          `${this.platform}\n` +
          `${this.platformReleaseID ? 'Release [' + this.platformReleaseID + '] ' : ''}` +
          `${
            this.platformDisplayVersion
              ? 'Version ' + this.platformDisplayVersion + '\n'
              : ''
          }` +
          `${this.platformVersion}\n` +
          `${this.kernelArch}`
        );
    }
  }

  get biosD(): string {
    if (this.bios) {
      return `${this.bios.vendor}\n${this.bios.version} ${this.bios.releaseDate}`.trim();
    }
    return 'unknown';
  }

  constructor(init?: Partial<System>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);

    this.os = init.os as OS;

    if (this.os === OS.DARWIN) {
      this.platformDisplayVersion = this.getMacOSName(this.platformVersion);
    }

    this.bootTime = new Date(init.bootTime);
    if (this.gpu) {
      this.gpu = this.gpu.map((e) => new GPU(e));
    }
  }

  update(input: Partial<System>): this {
    return Object.assign(this, input);
  }

  private getMacOSName(version: string): string {
    const versionNumber = parseFloat(version);

    if (versionNumber >= 14) {
      return 'Sonoma';
    } else if (versionNumber >= 13) {
      return 'Ventura';
    } else if (versionNumber >= 12) {
      return 'Monterey';
    } else if (versionNumber >= 11) {
      return 'Big Sur';
    } else if (versionNumber >= 10.15) {
      return 'Catalina';
    } else if (versionNumber >= 10.14) {
      return 'Mojave';
    } else if (versionNumber >= 10.13) {
      return 'High Sierra';
    } else if (versionNumber >= 10.12) {
      return 'Sierra';
    } else if (versionNumber >= 10.11) {
      return 'El Capitan';
    } else if (versionNumber >= 10.1) {
      return 'Yosemite';
    } else if (versionNumber >= 10.9) {
      return 'Mavericks';
    } else if (versionNumber >= 10.8) {
      return 'Mountain Lion';
    } else if (versionNumber >= 10.7) {
      return 'Lion';
    } else if (versionNumber >= 10.6) {
      return 'Snow Leopard';
    } else if (versionNumber >= 10.5) {
      return 'Leopard';
    } else if (versionNumber >= 10.4) {
      return 'Tiger';
    } else if (versionNumber >= 10.3) {
      return 'Panther';
    } else if (versionNumber >= 10.2) {
      return 'Jaguar';
    } else if (versionNumber >= 10.1) {
      return 'Puma';
    } else if (versionNumber >= 10.0) {
      return 'Cheetah';
    }

    return version;
  }
}

export class BIOS implements Updatable<BIOS> {
  public manufacturer: string;
  public model: string;
  public releaseDate: string;
  public vendor: string;
  public version: string;

  constructor(init?: Partial<BIOS>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<BIOS>): this {
    return Object.assign(this, input);
  }
}

export class GPU implements Updatable<GPU> {
  public adapter: string;
  public bios: string;
  public chipType: string;
  public dedicatedSystemMemorySize: number;
  public dedicatedVideoMemorySize: number;
  public sharedSystemMemorySize: number;

  constructor(init?: Partial<GPU>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<GPU>): this {
    return Object.assign(this, input);
  }
}

export class CPU implements Updatable<CPU> {
  public sockets: number;
  public cores: number;
  public logicalProcessors: number;
  public family: string;
  public speed: number;
  public model: number;
  public modelName: string;
  public vendor: string;

  constructor(init?: Partial<CPU>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<CPU>): this {
    return Object.assign(this, input);
  }
}

export class Memory implements Updatable<Memory> {
  public total: number;

  constructor(init?: Partial<Memory>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<Memory>): this {
    return Object.assign(this, input);
  }
}
