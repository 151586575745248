import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Trigger } from 'src/app/shared/models/trigger.model';

export class TriggersDataSource implements DataSource<Trigger> {
  private _triggers = new BehaviorSubject<Trigger[]>(undefined);
  private _initialLoading = new BehaviorSubject<boolean>(true);
  public initialLoading$ = this._initialLoading.asObservable();
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public filteredLength: number;
  public get length(): number {
    return this._triggers.value ? this._triggers.value.length : -1;
  }
  public get data(): Trigger[] {
    return this._triggers.value?.slice() || [];
  }

  constructor(private triggerSvc: TriggerService) {}

  loadTriggers(filter = '', limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    this.triggerSvc
      .getAll(filter, limit, page, orderBy)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this._initialLoading.next(false);
          this._loading.next(false);
        })
      )
      .subscribe({
        next: (results: PagedResult<Trigger>) => {
          if (results) {
            this.total = results.count;
            this.filteredLength = results.items.length;
            this._triggers.next(results.items || []);
          }
        }
      });
  }

  connect(): Observable<Trigger[]> {
    return this._triggers.asObservable();
  }

  disconnect(): void {
    this._triggers.complete();
    this._loading.complete();
  }
}
