import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { StateService } from 'src/app/core/services/state.service';

import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent {
  dropdownOpen: boolean;

  user$ = this.stateSvc.currentUser$;

  constructor(
    private stateSvc: StateService,
    private popover: PopoverService,
    private cd: ChangeDetectorRef
  ) {}

  showPopover(originRef: HTMLElement): void {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });

    popoverRef.afterClosed$.subscribe({
      next: () => {
        this.dropdownOpen = false;
        this.cd.markForCheck();
      }
    });
  }
}
