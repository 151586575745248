import { IntroStepEx } from 'src/app/core/services/intro.service';

export const defaultIntroSteps: Map<string, IntroStepEx> = new Map<string, IntroStepEx>([
  [
    'welcome-0',
    {
      id: 'welcome-0',
      title: 'Welcome to CommandCTRL',
      intro: `👋 Looks like you are new around here. Let's do a quick introduction. Click next to begin.`
    }
  ],
  [
    'complete-0',
    {
      id: 'complete-0',
      intro: `
      <div class='text-center'>And just like that, you're a pro now!</div>`
    }
  ],
  [
    'search-button',
    {
      id: 'search-button',
      element: '[data-intro-key="search-button"]',
      intro: 'Search for users or machines by hostname.'
    }
  ],
  [
    'config-panel-button',
    {
      id: 'config-panel-button',
      element: '[data-intro-key="config-panel-button"]',
      intro:
        'View your individual global settings such as colors, density, etc under the configuration panel.'
    }
  ],
  [
    'task-panel',
    {
      id: 'task-panel',
      element: '[data-intro-key="task-panel"]',
      scrollTo: 'off',
      intro:
        "All tasks across all machines in this tenant will show up here. You'll see things such as shutdowns, logoffs, etc issued to machines."
    }
  ],
  [
    'nav-settings-panel',
    {
      id: 'nav-settings-panel',
      element: '[data-intro-key="nav-settings-panel"]',
      intro: 'View information about your company, users, and site settings here.',
      position: 'right'
    }
  ],
  [
    'user-profile-button',
    {
      id: 'user-profile-button',
      element: '[data-intro-key="user-profile-button"]',
      intro: 'Click here to logoff or view your profile.'
    }
  ],
  [
    'install-agent-button',
    {
      id: 'install-agent-button',
      element: '[data-intro-key="install-agent-button"]',
      intro: 'Ready? Click here and get started by installing an agent.'
    }
  ],
  [
    'recent-nav',
    {
      id: 'recent-nav',
      element: '[data-intro-key="recent-nav"]',
      intro: 'View recent pages and machines you visited.'
    }
  ],
  [
    'tenant-name-logo',
    {
      id: 'tenant-name-logo',
      element: '[data-intro-key="tenant-name-logo"]',
      intro:
        'You can view which tenant you are connected to here. If you belong to more than one tenant, you can click here to switch tenants.'
    }
  ],
  [
    'recently-update-machines-list',
    {
      id: 'recently-update-machines-list',
      element: '[data-intro-key="recently-update-machines-list"]',
      intro: 'Here is a list of recently visited machines you can click on and view.'
    }
  ],
  [
    'agent-remote-icon',
    {
      id: 'agent-remote-icon',
      element: '[data-intro-key="agent-remote-icon"]',
      intro:
        'This icon indicates this machine has remote sessions to other machines open. You can click here to go to those remote machines.'
    }
  ],
  [
    'agent-toolbar',
    {
      id: 'agent-toolbar',
      element: '[data-intro-key="agent-toolbar"]',
      intro:
        'This toolbar indicates you have selected a machine to view. You can see the machine name, last collection time, and agent version.'
    }
  ],
  [
    'agent-shell-icon',
    {
      id: 'agent-shell-icon',
      element: '[data-intro-key="agent-shell-icon"]',
      intro: 'Click here if you want to shell into the agent.'
    }
  ],
  [
    'agent-connection-status-icon',
    {
      id: 'agent-connection-status-icon',
      element: '[data-intro-key="agent-connection-status-icon"]',
      intro:
        'This icon indicates the connection between this console and the machine you are viewing.'
    }
  ],
  [
    'agent-disconnected-icon',
    {
      id: 'agent-disconnected-icon',
      element: '[data-intro-key="agent-disconnected-icon"]',
      intro:
        'UH OH! This disconnected icon indicates that the console cannot connect to the agent. Click on this icon if you need more information on how to troubleshoot.'
    }
  ],
  [
    'copy-text-button',
    {
      id: 'copy-text-button',
      element: '[data-intro-key="copy-text-button"]',
      intro: 'You can copy this block of text to your clipboard by clicking here.'
    }
  ],
  [
    'live-playback-button',
    {
      id: 'live-playback-button',
      element: '[data-intro-key="live-playback-button"]',
      intro:
        'Switch between watching live machine metrics or playing back metrics from a previous moment in time.'
    }
  ],
  [
    'perms-item-search',
    {
      id: 'perms-item-search',
      element: '[data-intro-key="perms-item-search"]',
      intro: 'Search for a role or user to apply permissions to.'
    }
  ],
  [
    'playback-view-welcome',
    {
      id: 'playback-view-welcome',
      intro:
        "Welcome to the playback view of a machine. Let's walk through some of the controls."
    }
  ],
  [
    'playback-calendar-button',
    {
      id: 'playback-calendar-button',
      element: '[data-intro-key="playback-calendar-button"]',
      intro: 'Use the calendar to select a range of dates you want to drill down to.'
    }
  ],
  [
    'playback-day-button',
    {
      id: 'playback-day-button',
      element: '[data-intro-key="playback-day-button"]',
      intro: 'Select the day you want to view metrics on.'
    }
  ],
  [
    'playback-go-back-button',
    {
      id: 'playback-go-back-button',
      element: '[data-intro-key="playback-go-back-button"]',
      intro:
        'If you selected the wrong date or hour, you can always click here to go back.'
    }
  ],
  [
    'playback-hour-button',
    {
      id: 'playback-hour-button',
      element: '[data-intro-key="playback-hour-button"]',
      intro: 'Select the hour you want to view metrics on.'
    }
  ],
  [
    'playback-timeline-control-button',
    {
      id: 'playback-timeline-control-button',
      element: '[data-intro-key="playback-timeline-control-button"]',
      intro: 'You can control the speed of playback. Turtle = slow. Rabbit = fast.'
    }
  ],
  [
    'playback-timeline',
    {
      id: 'playback-timeline',
      element: '[data-intro-key="playback-timeline"]',
      intro:
        'This timeline represents any recorded metrics that may have occurred during the hour. It will be buffered in while you view it.'
    }
  ],
  [
    'playback-timeline-incidents',
    {
      id: 'playback-timeline-incidents',
      element: '[data-intro-key="playback-timeline-incidents"]',
      intro:
        'This area will show color coded CPU, memory, disk and network incidents that occurred during the hour. You can drag the timeline cursor to spots you are interested in.'
    }
  ],
  [
    'script-installed-scripts',
    {
      id: 'script-installed-scripts',
      element: '[data-intro-key="script-installed-scripts"]',
      intro: 'This shows you all the available installed scripts on this tenant.'
    }
  ],
  [
    'script-search-scripts',
    {
      id: 'script-search-scripts',
      element: '[data-intro-key="script-search-scripts"]',
      intro: 'You can search across all installed and recommended scripts.'
    }
  ],
  [
    'script-upload-script',
    {
      id: 'script-upload-script',
      element: '[data-intro-key="script-upload-script"]',
      intro:
        'You can upload a custom script that will only be available to your tenant here.'
    }
  ],
  [
    'script-recommended-scripts',
    {
      id: 'script-recommended-scripts',
      element: '[data-intro-key="script-recommended-scripts"]',
      intro:
        'This shows you all the available recommended scripts curated by the CommandCTRL team. You can install or uninstall these.'
    }
  ],
  [
    'script-store-marker',
    {
      id: 'script-store-marker',
      element: '[data-intro-key="script-store-marker"]',
      intro: 'This blue mark indicates this is a recommended store script.'
    }
  ],
  [
    'script-store-stats',
    {
      id: 'script-store-stats',
      element: '[data-intro-key="script-store-stats"]',
      intro:
        'You can see how many times this script has been downloaded and liked by other tenants and users.'
    }
  ],
  [
    'script-detail-header',
    {
      id: 'script-detail-header',
      element: '[data-intro-key="script-detail-header"]',
      intro:
        'Here is some detail about the script. You can see the script type, publisher, version as well as other useful data.'
    }
  ],
  [
    'script-detail-tags',
    {
      id: 'script-detail-tags',
      element: '[data-intro-key="script-detail-tags"]',
      intro: 'These are tags that show related categories.'
    }
  ],
  [
    'script-detail-feedback',
    {
      id: 'script-detail-feedback',
      element: '[data-intro-key="script-detail-feedback"]',
      intro: 'You can provide store feedback here.'
    }
  ],
  [
    'shell-connect-button',
    {
      id: 'shell-connect-button',
      element: '[data-intro-key="shell-connect-button"]',
      intro: 'Click here to begin searching for a machine to connect to. Simple as that.'
    }
  ],
  [
    'shell-connect-shell-type',
    {
      id: 'shell-connect-shell-type',
      element: '[data-intro-key="shell-connect-shell-type"]',
      intro:
        'Choose what kind of shell you want to open. This is dependent on the machine. Most Windows machines have Powershell and Cmd by default. However Bash may not be installed. In the future we may predict and disable shells that are not available.'
    }
  ],
  [
    'shell-connect-input',
    {
      id: 'shell-connect-input',
      element: '[data-intro-key="shell-connect-input"]',
      intro:
        "This is self explanatory. Search for a machine to connect to. We'll start providing suggestions as you type. When ready, just hit <b>enter</b> to connect."
    }
  ],
  [
    'shell-toolbar',
    {
      id: 'shell-toolbar',
      element: '[data-intro-key="shell-toolbar"]',
      intro:
        'This toolbar shows you which machine you are connected to. It will also give you the session time and state of connection.'
    }
  ],
  [
    'shell-view',
    {
      id: 'shell-view',
      element: '[data-intro-key="shell-view"]',
      intro:
        "This remote console window can be treated just like a real console window. Feel free to resize the window, use mouse buttons, mouse scroll wheel or your favorite keyboard shortcuts. Just like a real console window, you can close this session by typing 'exit' or closing this window."
    }
  ],
  [
    'tenant-invite',
    {
      id: 'tenant-invite',
      element: '[data-intro-key="tenant-invite"]',
      intro:
        'It looks like you have been invited to join a tenant. Decide to join or decline that invitation.'
    }
  ],
  [
    'tenant-invite-alert',
    {
      id: 'tenant-invite-alert',
      element: '[data-intro-key="tenant-invite-alert"]',
      intro: 'You have been invited to a tenant. Click here to see who invited you.'
    }
  ],
  [
    'widget-removed',
    {
      id: 'widget-removed',
      element: '[data-intro-key="config-panel-button"]',
      intro:
        'If you need to add this widget back, you can find it located under this configuration panel.'
    }
  ]
]);
