import { Component, Input, OnInit } from '@angular/core';
import { HistoryHourSummary } from 'src/app/shared/models/history.model';
import { tailwindCssColorToRGB } from 'src/app/utils/colors';

@Component({
  selector: 'mon-hour-summary',
  templateUrl: './hour-summary.component.html',
  styleUrls: ['./hour-summary.component.scss']
})
export class HourSummaryComponent implements OnInit {
  @Input() hour: HistoryHourSummary;

  data: number[] = [];
  colors: string[] = [];
  tooltips: string[] = [];

  ngOnInit(): void {
    let n = this.hour.totalProcessesIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`Process Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-blue-500'));
    }

    n = this.hour.totalCPUIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`CPU Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-blue-400'));
    }

    n = this.hour.totalRAMIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`RAM Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-purple-400'));
    }

    n = this.hour.totalDiskIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`Disk Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-green-400'));
    }

    n = this.hour.totalNetworkIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`Network Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-orange'));
    }

    n = this.hour.totalGPUIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`GPU Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-blue-400'));
    }

    n = this.hour.totalSystemIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`System Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-yellow'));
    }
    //   const colors = [];
    //   const series = [];

    //   if (this.hour.totalProcessesIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-blue-500'));
    //     series.push({
    //       name: 'Process Incidents',
    //       data: [this.hour.totalProcessesIncidents()]
    //     });
    //   }
    //   if (this.hour.totalCPUIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-blue-400'));
    //     series.push({ name: 'CPU Incidents', data: [this.hour.totalCPUIncidents()] });
    //   }
    //   if (this.hour.totalRAMIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-purple-400'));
    //     series.push({ name: 'RAM Incidents', data: [this.hour.totalRAMIncidents()] });
    //   }
    //   if (this.hour.totalDiskIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-green-400'));
    //     series.push({ name: 'Disk Incidents', data: [this.hour.totalDiskIncidents()] });
    //   }
    //   if (this.hour.totalNetworkIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-orange'));
    //     series.push({
    //       name: 'Network Incidents',
    //       data: [this.hour.totalNetworkIncidents()]
    //     });
    //   }
    //   if (this.hour.totalGPUIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-blue-400'));
    //     series.push({ name: 'GPU Incidents', data: [this.hour.totalGPUIncidents()] });
    //   }
    //   if (this.hour.totalSystemIncidents() > 0) {
    //     colors.push(tailwindCssColorToRGB('text-yellow'));
    //     series.push({ name: 'System Incidents', data: [this.hour.totalSystemIncidents()] });
    //   }

    //   this.ops = defaultChartOptions({
    //     colors: colors,
    //     tooltip: {
    //       x: {
    //         show: false
    //       },
    //       y: {
    //         formatter: function (val) {
    //           return `${val}`;
    //         }
    //       },
    //       fixed: {
    //         enabled: true,
    //         position: 'topLeft',
    //         offsetY: -30
    //       }
    //     },
    //     xaxis: {
    //       labels: {
    //         show: false
    //       },
    //       axisBorder: {
    //         show: false
    //       }
    //     },
    //     plotOptions: {
    //       bar: {
    //         barHeight: '300%',
    //         horizontal: true,
    //         dataLabels: {
    //           position: 'top'
    //         }
    //       }
    //     },
    //     states: {
    //       active: {
    //         filter: {
    //           type: 'none'
    //         }
    //       },
    //       hover: {
    //         filter: {
    //           type: 'none'
    //         }
    //       }
    //     },
    //     stroke: {
    //       show: true,
    //       width: 1,
    //       colors: [tailwindCssColorToRGB('text-gray-light')]
    //     },
    //     chart: {
    //       type: 'bar',
    //       height: '37px',
    //       animations: {
    //         enabled: true,
    //         easing: 'linear',
    //         dynamicAnimation: {
    //           enabled: true,
    //           speed: 1000
    //         }
    //       }
    //     }
    //   });
    //   this.series = series;
    // }
  }
}
