import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { License } from 'src/app/shared/models/license.model';
import { Processes } from 'src/app/shared/models/process.model';
import {
  PaymentSource,
  Plan,
  Subscription,
  TotalEstimate
} from 'src/app/shared/models/subscription.model';

import { ApiService, TagResponse } from './api.service';

export enum CompanySizeType {
  size0To99 = '0-99',
  size100To249 = '100-249',
  size250To499 = '250-499',
  size500To999 = '500-999',
  size1000Plus = '1000+'
}

export interface SubscriptionLicenseResult {
  subscription?: Subscription;
  license?: License;
}

export enum SubscriptionCancellationReasonCode {
  ProductUnsatifactory = 'Product Unsatisfactory',
  ServiceUnsatifactory = 'Service Unsatisfactory',
  NoLongerNeeded = 'No Longer Needed',
  SwitchingToCompetitorsProduct = "Switching to Competitor's Product",
  OrderChange = 'Order Change',
  Other = 'Other'
}

export interface Coupon {
  id: string;
  name: string;
  discountType: string;
  discountPercentage: number;
  discountAmount: number;
  discountQuantity: number;
  planConstraints: unknown;
  planConstraintCriteria: unknown;
  status: string;
}

export interface CouponCodeValidationTag {
  error?: string;
  coupon?: Coupon;
  valid: boolean;
}

export interface UpdatePaymentSource {
  card?: UpdateCard;
  bankAccount?: UpdateBankAccount;
}

export interface UpdateCard {
  company: string;
  phone: string;
  address1: string;
  address2: string;
  locality: string;
  regionCode: string;
  postcode: string;
  country: string;
  firstName: string;
  lastName: string;
  number: string;
  cvv: string;
  expiryMonth: number;
  expiryYear: number;
}
export interface UpdateBankAccount {
  firstName: string;
  lastName: string;
  bankName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  accountHolderType: string;
}

@Injectable({
  providedIn: 'root'
})
export class SubscribeService extends ApiService {
  private _plansCache: Plan[] = [];

  constructor(private http: HttpClient) {
    super();
  }

  getSubscription(listen = false, newerThan = new Date()): Observable<Subscription> {
    const url = `${this.apiUrl}/subscription`;

    const params = {};

    if (newerThan) {
      Object.assign(params, { newerThan: newerThan.toISOString() });
    }

    if (listen) {
      Object.assign(params, { listen });
    }

    return this.http.get<Subscription>(url, { params }).pipe(
      map((response) => (response ? new Subscription(response) : undefined)),
      catchError((err) => {
        this.handleError<Processes>('SubscribeService.getSubscription');
        return throwError(() => err);
      })
    );
  }

  getPlans(): Observable<Plan[]> {
    if (this._plansCache.length > 0) {
      return of(this._plansCache);
    }

    const url = `${this.publicUrl}/subscription/plan`;

    return this.http.get<Plan[]>(url).pipe(
      map((response) => (response ? response.map((p) => new Plan(p)) : undefined)),
      tap((plans) => (this._plansCache = plans)),
      catchError((err) => {
        this.handleError<Processes>('SubscribeService.getPlans');
        return throwError(() => err);
      })
    );
  }

  getPaymentSource(): Observable<PaymentSource> {
    const url = `${this.apiUrl}/subscription/payment-source`;

    return this.http.get<PaymentSource>(url).pipe(
      catchError((err) => {
        this.handleError<Processes>('SubscribeService.getPaymentSource');
        return throwError(() => err);
      })
    );
  }

  getNewEstimate(
    postcode: string,
    country: string,
    itemPriceID: string,
    quantity: number
  ): Observable<TotalEstimate> {
    const url = `${this.apiUrl}/subscription/estimate`;

    return this.http
      .post<TotalEstimate>(url, { postcode, country, itemPriceID, quantity })
      .pipe(
        catchError((err) => {
          this.handleError('SubscribeService.getEstimate');
          return throwError(() => err);
        })
      );
  }

  getUpdatedEstimate(itemPriceID: string, quantity: number): Observable<TotalEstimate> {
    const url = `${this.apiUrl}/subscription/estimate/update`;

    return this.http.post<TotalEstimate>(url, { itemPriceID, quantity }).pipe(
      catchError((err) => {
        this.handleError('SubscribeService.getEstimate');
        return throwError(() => err);
      })
    );
  }

  updateSubscription(
    itemPriceID: string,
    quantity: number,
    couponCode: string
  ): Observable<Subscription> {
    const url = `${this.apiUrl}/subscription`;

    return this.http
      .post<Subscription>(url, {
        itemPriceID,
        quantity,
        couponCode
      })
      .pipe(
        map((result) => new Subscription(result)),
        catchError((err) => {
          this.handleError('SubscribeService.updateSubscription');
          return throwError(() => err);
        })
      );
  }

  cancelSubscription(
    reasonCode: string,
    competitor: string,
    feedback: string
  ): Observable<SubscriptionLicenseResult> {
    const url = `${this.apiUrl}/subscription/cancel`;

    return this.http
      .post<SubscriptionLicenseResult>(url, {
        reasonCode,
        competitor,
        feedback
      })
      .pipe(
        map((result: SubscriptionLicenseResult) => {
          return {
            subscription: new Subscription(result.subscription),
            license: new License(result.license)
          };
        }),
        catchError((err) => {
          this.handleError('SubscribeService.cancelSubscription');
          return throwError(() => err);
        })
      );
  }

  reactivateSubscription(): Observable<SubscriptionLicenseResult> {
    const url = `${this.apiUrl}/subscription/reactivate`;

    return this.http.post<SubscriptionLicenseResult>(url, {}).pipe(
      map((result: SubscriptionLicenseResult) => {
        return {
          subscription: new Subscription(result.subscription),
          license: new License(result.license)
        };
      }),
      catchError((err) => {
        this.handleError('SubscribeService.reactivateSubscription');
        return throwError(() => err);
      })
    );
  }

  updatePaymentSource(update: UpdatePaymentSource): Observable<PaymentSource> {
    const url = `${this.apiUrl}/subscription/payment-source`;

    return this.http.post<PaymentSource>(url, update).pipe(
      catchError((err) => {
        this.handleError('SubscribeService.updatePaymentSource');
        return throwError(() => err);
      })
    );
  }

  verifyBankAccount(
    id: string,
    amount1: number,
    amount2: number
  ): Observable<PaymentSource> {
    const url = `${this.apiUrl}/subscription/payment-source/verify`;

    return this.http.post<PaymentSource>(url, { id, amount1, amount2 }).pipe(
      catchError((err) => {
        this.handleError('SubscribeService.updatePaymentSource');
        return throwError(() => err);
      })
    );
  }

  validateCouponCode(code: string): Observable<TagResponse<CouponCodeValidationTag>> {
    const url = `${this.cfUrl}/validate-coupon-code?code=${encodeURIComponent(code)}`;

    return this.http.get<TagResponse<CouponCodeValidationTag>>(url, {}).pipe(
      catchError((err) => {
        this.handleError('SubscribeService.validateCouponCode');
        return throwError(() => err);
      })
    );
  }
}
