import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  startWith,
  take,
  tap
} from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import {
  City,
  Country,
  GeoLocation,
  PostalService,
  State
} from 'src/app/core/services/postal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { CustomValidators } from 'src/app/utils/validators';

export type AddressType = 'billing' | 'shipping' | 'credit-card' | 'register';

@Component({
  selector: 'mon-address',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedModule
  ],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  animations: [fadeInRight400ms]
})
export class AddressComponent implements OnInit {
  validators = Validators;

  @Input() formGroup: FormGroup;

  private _type: AddressType = 'billing';
  @Input() get type(): AddressType {
    return this._type;
  }
  set type(v: AddressType) {
    this._type = v;
    if (this.formGroup) {
      this._checkFieldRequirements();
    }
  }

  private _readonly = false;
  @Input() get readonly(): boolean {
    return this._readonly;
  }
  set readonly(v: boolean | '') {
    this._readonly = v === '' || v;
  }

  private _focusInitial = false;
  @Input() get focusInitial(): boolean {
    return this._focusInitial;
  }
  set focusInitial(v: boolean | '') {
    this._focusInitial = v === '' || v;
  }

  initialLoad: boolean;
  currentCountry: string;
  currentRegion: string;

  _country = new BehaviorSubject<string>(null);
  country$ = this._country.asObservable().pipe(distinctUntilChanged());

  _region = new BehaviorSubject<string>(null);
  region$ = this._region.asObservable().pipe(distinctUntilChanged());

  _locality = new BehaviorSubject<string>(null);
  locality$ = this._locality.asObservable();

  countries = new Array<Country>();

  regions = new Array<State>();
  filteredRegions$: Observable<State[]>;

  localities = new Array<City>();
  filteredLocalities$: Observable<City[]>;

  geo: GeoLocation = undefined;
  geoCall$ = iif(
    () => !!this.geo,
    of(this.geo),
    this.postalSvc.getGeo().pipe(
      tap((g) => (this.geo = g)),
      catchError(() => of({} as GeoLocation))
    )
  );

  @ViewChildren(MatInput) inputs: QueryList<MatInput>;

  constructor(private postalSvc: PostalService) {}

  ngOnInit(): void {
    const countryCode = this.formGroup.value.countryCode as string;
    const regionCode = this.formGroup.value.regionCode as string;
    const region = this.formGroup.value.region as string;

    const countryCodeCall$ = !!countryCode
      ? of(countryCode)
      : this.geoCall$.pipe(map((g) => g?.country_code));

    const regionCall$ = !!region ? of(region) : this.geoCall$.pipe(map((g) => g?.region));

    combineLatest([countryCodeCall$, regionCall$])
      .pipe(
        concatMap(([countryCode, region]) =>
          combineLatest([
            of(countryCode),
            of(regionCode),
            of(region),
            this.postalSvc.getCountries(),
            this.postalSvc.getStates(countryCode),
            this.postalSvc.getCities(countryCode, regionCode)
          ])
        ),
        tap(([countryCode, regionCode, region, countries, regions, localities]) => {
          countries && (this.countries = countries);
          regions && (this.regions = regions);
          localities && (this.localities = localities);

          if (!countryCode) {
            return;
          }

          const foundCountry = countries.find((c) => c.iso2 === countryCode);
          this.currentCountry = foundCountry.name;

          this.country$
            .pipe(
              take(1),
              tap(() => {
                if (foundCountry) {
                  this.formGroup.controls.country.setValue(foundCountry.name);
                  this.formGroup.controls.countryCode.setValue(foundCountry.iso2);
                }
              })
            )
            .subscribe();

          if (foundCountry) {
            this.setSelectedCountry(foundCountry);
          }

          let foundRegion: State = undefined;
          if (!!regionCode) {
            foundRegion = regions.find((c) => c.stateCode === regionCode);
          }
          if (!foundRegion && !!region) {
            const lRegionCode = region.toLowerCase();
            foundRegion = regions.find((c) => c.name.toLocaleLowerCase() === lRegionCode);
          }
          this.currentRegion = foundRegion?.name || this.formGroup.value.region;
          this.region$
            .pipe(
              take(1),
              tap(() => {
                if (
                  this.formGroup.controls.region &&
                  this.formGroup.controls.regionCode &&
                  foundRegion
                ) {
                  this.formGroup.controls.region.setValue(foundRegion.name);
                  this.formGroup.controls.regionCode.setValue(foundRegion.stateCode);
                }
              })
            )
            .subscribe();

          if (foundRegion) {
            this.setSelectedRegion(foundRegion);
          }
        }),
        tap(() => {
          if (this.formGroup.controls.region) {
            this.filteredRegions$ = this.formGroup.controls.region.valueChanges.pipe(
              startWith(this.formGroup.value.region),
              map((name) => {
                return name ? this._filterRegions(name) : this.regions.slice();
              })
            );
          }

          if (this.formGroup.controls.locality) {
            this.filteredLocalities$ = this.formGroup.controls.locality.valueChanges.pipe(
              startWith(this.formGroup.value.locality),
              map((name) => {
                return name ? this._filterCities(name) : this.localities.slice();
              })
            );
          }
        }),
        finalize(() => {
          this._checkFieldRequirements();
          this.initialLoad = true;
          this.focusInitial && setTimeout(() => this.inputs.first.focus(), 250);
        })
      )
      .subscribe();
  }

  setSelectedRegion(region: State): void {
    region && this._region.next(region.name);
  }

  setSelectedCountry(country: Country): void {
    country && this._country.next(country.iso2);
  }

  countrySelected(event: MatSelectChange) {
    const value = event.value;
    const country = this.countries.find((c) => c.name === value);
    country && this.formGroup.controls.countryCode.setValue(country.iso2);
    this.setSelectedCountry(country);

    if (value !== this.currentCountry) {
      this.formGroup.controls.region.setValue(null);
      this.formGroup.controls.regionCode.setValue(null);
      this.formGroup.controls.locality.setValue(null);
    }

    this.country$
      .pipe(
        take(1),
        tap((countryCode) => this._checkFieldRequirements(countryCode)),
        mergeMap((countryCode) =>
          this.postalSvc.getStates(countryCode).pipe(
            tap(() => (this.regions = [])),
            tap(() => (this.localities = [])),
            tap((r) => (this.regions = r)),
            tap(() => {
              if (value !== this.currentCountry) {
                this.currentCountry = value;
                this.currentRegion = null;
                this.setSelectedRegion(null);
                this.formGroup.controls.region.setValue('');
                this.formGroup.controls.regionCode.setValue('');
                this.formGroup.controls.locality.setValue('');
              }
            })
          )
        )
      )
      .subscribe();
  }

  regionSelected(event: MatSelectChange | MatAutocompleteSelectedEvent) {
    let value: unknown;
    if (event instanceof MatSelectChange) {
      value = event.value;
    } else if (event instanceof MatAutocompleteSelectedEvent) {
      value = event.option.value;
    }

    const region = this.regions.find((c) => c.name === value);
    region && this.formGroup.controls.regionCode.setValue(region.stateCode);
    this.setSelectedRegion(region);

    if (value !== this.currentRegion) {
      this.formGroup.controls.locality.setValue(null);
    }

    this.region$
      .pipe(
        take(1),
        mergeMap((regionName) => {
          const lRegionName = regionName.toLocaleLowerCase();
          const region = this.regions.find(
            (r) => r.name.toLocaleLowerCase() === lRegionName
          );

          return !!region
            ? this.postalSvc
                .getCities(this.formGroup.controls.countryCode.value, region.stateCode)
                .pipe(tap((c) => (this.localities = c)))
            : UNDEFINED;
        }),
        tap(() => {
          if (value !== this.currentRegion) {
            this.formGroup.controls.locality.setValue('');
          }
        })
      )
      .subscribe();
  }

  private _checkFieldRequirements(countryCode?: string): void {
    countryCode = countryCode || this.formGroup.value.countryCode;

    this.formGroup.controls.countryCode.setValidators(Validators.required);

    if (this.formGroup.controls.country) {
      this.formGroup.controls.country.setValidators(
        CustomValidators.fn('invalidCountry', (v: string) => {
          return !this.countries.find((c) => c.name === v);
        })
      );
    }

    if (this.type === 'credit-card') {
      this.formGroup.controls.region.setValidators(Validators.required);
      this.formGroup.controls.locality.setValidators(Validators.required);
      this.formGroup.controls.postcode.setValidators(Validators.required);
      this.formGroup.controls.address1.setValidators(Validators.required);
    } else if (this.type === 'billing') {
      if (countryCode === 'US' || countryCode === 'CA') {
        this.formGroup.controls.region &&
          this.formGroup.controls.region.addValidators(Validators.required);
        this.formGroup.controls.postcode &&
          this.formGroup.controls.postcode.addValidators(Validators.required);
      } else {
        this.formGroup.controls.region.removeValidators(Validators.required);
        this.formGroup.controls.postcode.removeValidators(Validators.required);
      }
    } else if (this.type === 'register') {
      if (countryCode === 'US' || countryCode === 'CA') {
        this.formGroup.controls.region.addValidators(Validators.required);
        this.formGroup.controls.postcode.addValidators(Validators.required);
      } else {
        this.formGroup.controls.region.removeValidators(Validators.required);
        this.formGroup.controls.postcode.removeValidators(Validators.required);
      }
    } else {
      this.formGroup.controls.region.removeValidators(Validators.required);
      this.formGroup.controls.locality.removeValidators(Validators.required);
      this.formGroup.controls.postcode.removeValidators(Validators.required);
      this.formGroup.controls.address1.removeValidators(Validators.required);
    }
  }

  private _filterRegions(name: string): State[] {
    const filterValue = name.toLowerCase();

    return this.regions.filter((c) => c.name.toLowerCase().includes(filterValue));
  }

  private _filterCities(name: string): City[] {
    const filterValue = name.toLowerCase();

    return this.localities.filter((c) => c.name.toLowerCase().includes(filterValue));
  }

  onRegionBlur(control: MatAutocomplete): void {
    const region = this.formGroup.controls.region;
    const setValue = (v: unknown) => {
      this.setSelectedRegion(v);
    };

    const c = region.getRawValue();

    if (typeof c === 'object') {
      return;
    }

    const options = control.options.toArray();

    if (typeof c === 'string') {
      const lowerC = c.toLocaleLowerCase();
      const option = options.find(
        (option: MatOption) => option.value.toLocaleLowerCase() === lowerC
      );

      if (!!option) {
        option.select();

        return;
      } else {
        setValue(undefined);
      }
    }

    if (region.hasValidator(Validators.required)) {
      region.setErrors({ notValid: true });
    }
  }
}
