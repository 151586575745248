<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event, undefined)" [duration]="500"></mon-result-overlay>
    <h2 mat-dialog-title>Select Duration</h2>
    <p class="text-secondary">Select duration for agent logging to remain in debug mode</p>
    <mat-dialog-content>
      <div class="flex items-center justify-center w-full">
        <mat-form-field>
          <mat-label>Duration</mat-label>
          <mat-select #durationSelect [formControl]="duration">
            <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }} minutes</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="flex items-center justify-end w-full">
        <button mat-button (click)="onCancel()">Cancel</button>
        <button mat-button color="primary" (click)="onConfirm(duration.value)">Confirm</button>
      </div>
    </mat-dialog-actions>
  </div>
</mon-spinner>
