import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import introJS from 'intro.js';
import { BehaviorSubject, timer } from 'rxjs';
import { filter, map, mergeMap, retry, take, tap } from 'rxjs/operators';
import { defaultIntroSteps } from 'src/app/shared/data/intro-steps';
import { deepCopy } from 'src/app/utils/clone';

import { ApiService } from './api.service';
import { StateService } from './state.service';
import { IntroStep } from 'intro.js/src/core/steps';

export interface IntroStepEx extends Partial<IntroStep> {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntroService extends ApiService {
  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running.asObservable();
  get running(): boolean {
    return this._running.value;
  }

  private queueIntros = [];
  private introSteps = defaultIntroSteps;

  hideWizard$ = this.stateSvc.currentUserSettings$.pipe(
    filter((s) => !!s),
    map((s) => s.consoleUI.getGlobalValue<boolean>('hide-intro-wizard') || false)
  );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private stateSvc: StateService
  ) {
    super();

    this.resetSteps();

    this.running$
      .pipe(
        filter((running) => !running),
        tap(() => {
          if (this.queueIntros.length > 0) {
            const steps = this.queueIntros.splice(0, 1);
            this.show(...steps[0]);
          }
        })
      )
      .subscribe();
  }

  public show(...keys: string[]): void {
    this.hideWizard$.pipe(take(1)).subscribe({
      next: (hide) => {
        if (hide) {
          return;
        }

        this._show(keys);
      }
    });
  }

  private _show(keys: string[]): void {
    if (keys.length === 0) {
      keys = Array.from(this.introSteps.keys());
    }

    this.log('intro to: ' + keys.join(', '));

    if (this.introSteps.size == 0) {
      return;
    }

    if (this._running.value) {
      this.queueIntros.push(keys);
      return;
    }

    const maxAttempts = 3;
    let attempts = 0;

    let foundKeys: string[] = [];

    this.running$
      .pipe(
        // only execute if we aren't already running an intro
        filter((running) => !running),
        take(1),
        tap(() => this._running.next(true)),
        // delay(500), // introjs needs a delay to shutdown, otherwise exceptions get thrown
        mergeMap(() => {
          return this.stateSvc.currentUserSettings$.pipe(
            filter((settings) => !!settings),
            take(1),
            map((settings) => settings.consoleUI.intro),
            tap((ids) => {
              ids?.forEach((id) => this.introSteps.delete(id));
            }),
            map(() => {
              if (keys.length === 0) {
                keys = Array.from(this.introSteps.keys());
              }

              foundKeys = keys.filter((k) => this.introSteps.has(k));
              return this.collectSteps(...foundKeys);
            }),
            map((steps) => {
              if (steps.length === foundKeys.length || attempts >= maxAttempts) {
                return steps;
              } else {
                throw 'elements not found';
              }
            }),
            retry({
              delay: (_error, _retryCount) => {
                attempts++;
                return timer(attempts * 250);
              }
            }),
            take(1),
            tap((steps) => {
              this.log(`found ${steps.length} of ${foundKeys.length}`);
              this.log(
                steps.length === foundKeys.length
                  ? 'all elements found'
                  : attempts === maxAttempts
                  ? 'exhausted attempts'
                  : 'what??'
              );

              if (steps.length < 1) {
                this._running.next(false);
                return;
              }

              this.start(steps, () => {
                this.stateSvc.currentUserSettings$
                  .pipe(
                    take(1),
                    tap((settings) => {
                      const introKeys = settings.consoleUI.intro.slice();
                      foundKeys.forEach((k) => {
                        if (introKeys.indexOf(k) < 0) {
                          introKeys.push(k);
                        }
                      });
                      introKeys.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
                      this.stateSvc.updateCurrentUserSettings({
                        consoleUI: {
                          intro: introKeys
                        }
                      });
                      this._running.next(false);
                    })
                  )
                  .subscribe();
              });
            })
          );
        })
      )
      .subscribe();
  }

  public resetSteps(): void {
    this.introSteps = deepCopy(defaultIntroSteps);
  }

  private collectSteps(...keys: string[]): IntroStepEx[] {
    const steps = keys.reduce((steps, k) => {
      const step = this.introSteps.get(k);

      if (step.element) {
        const found = this.document.querySelector(step.element as string);

        // only add element steps that can be found in the document
        if (found) {
          found.setAttribute('data-introjs-key', k);
          steps.push(step);
        }
      } else {
        steps.push(step);
      }

      return steps;
    }, []);

    return steps;
  }

  private start(steps: IntroStepEx[], exitCallbackFn: () => void): void {
    steps.forEach((step) => this.introSteps.delete(step.id));

    introJS()
      .setOptions({
        exitOnEsc: false,
        hidePrev: true,
        tooltipClass: 'intro-custom-css',
        steps: steps,
        exitOnOverlayClick: false
      })
      .onexit(() => exitCallbackFn())
      .start();

    setTimeout(() => this.attachDontShowAgain(), 250);
  }

  private attachDontShowAgain(): void {
    const el = this.document.querySelector('.introjs-tooltipbuttons');
    el.classList.add('flex', 'items-center', 'justify-between', 'gap-2');

    const dontShow = document.createElement('div');
    dontShow.innerHTML = `Hide Introduction`;
    dontShow.classList.add(
      'flex-1',
      'text-left',
      'pr-8',
      'text-primary',
      'underline',
      'cursor-pointer'
    );

    dontShow.addEventListener('click', () => {
      this.stateSvc.updateCurrentUserSettings({
        consoleUI: { global: { 'hide-intro-wizard': true } }
      });
      introJS().exit(false);
    });

    el.prepend(dontShow);
  }
}
