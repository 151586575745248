import { BehaviorSubject } from 'rxjs';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { BStatus, BatteryMetrics } from 'src/app/shared/models/system-summary.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { BatteryIcon } from './dashboard.classes';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';
import { QueryList } from '@angular/core';
import { convertNumToTime, getStringState } from './dashboard.helpers';

const _batteryGraphProps = new BehaviorSubject<string[]>([]);
export const batteryGraphProps$ = _batteryGraphProps.asObservable();
const count = 20;

export function updateBattery(
  batteryCharts: QueryList<LiveGraphComponent>,
  batteryGraphOps: GraphOptions[],
  batteries: BatteryMetrics[],
  triggers: Array<Trigger> = []
): void {
  const now = new Date();
  batteries = batteries || [];
  let index = 0;

  batteries.forEach((b) => {
    const remaining = [Math.round(b.remainingPercentage)];
    const batico = BatteryIcon.getLeft(b);
    let ops: GraphOptions = batteryGraphOps.find((bg) => bg.id === b.serialNumber);

    if (!ops) {
      ops = {
        id: b.serialNumber,
        title: `Battery ${index}`,
        label: [b.deviceName, b.manufacturer].join(' '),
        icon: batico.icon,
        iconColor: 'green',
        maxY: [100],
        seriesColors: [['orange']],
        seriesLineStyle: [['solid']],
        initialSeries: [
          [
            {
              name: 'Battery Remaining',
              data: new Array(count).fill(0)
            }
          ]
        ],
        focusProperties: [],
        properties: {}
      };

      batteryGraphOps.push(ops);
    } else {
      remaining.forEach((v, i) => {
        ops.initialSeries[0][i].data.splice(0, 1);
        ops.initialSeries[0][i].data.push(v);
      });
    }

    ops.timestamp = now;

    Object.assign(ops.focusProperties, [
      {
        label: 'Battery remaining',
        value: `${remaining}%`,
        highlight: !!triggers.find(
          (t) =>
            !!t.conditions.find(
              (c) =>
                c.field === 'Batteries.RemainingPercentage' &&
                c.isMet(b.remainingPercentage)
            )
        ),
        graphColor: 'orange',
        graphLineStyle: 'solid'
      },
      {
        label: 'State',
        value: `${getStringState(b.state)}`
      }
    ]);

    Object.assign(ops.properties, {
      'Current capacity': `${b.current} mW`,
      'Design capacity': `${b.design} mW`,
      'Current voltage': `${b.voltage} mW`,
      'Design voltage': `${b.designVoltage} mW`
    });

    if (b.state == BStatus.Charging || b.state == BStatus.Full) {
      if (batteryCharts !== undefined) {
        batteryCharts.forEach((c) => {
          c.removeProperty('Current discharge rate');
        });
      }
      Object.assign(ops.properties, {
        'Current charge rate': `${b.chargeRate} mW`
      });
    } else {
      if (batteryCharts !== undefined) {
        batteryCharts.forEach((c) => {
          c.removeProperty('Current charge rate');
        });
      }
      Object.assign(ops.properties, {
        'Current discharge rate': `${b.chargeRate} mW`
      });
    }

    if (b.state == BStatus.Charging) {
      const timeNum = (b.full - b.current) / b.chargeRate;
      Object.assign(ops.properties, {
        'Until Charged': `${convertNumToTime(timeNum)}`
      });
    } else {
      if (batteryCharts !== undefined) {
        batteryCharts.forEach((c) => {
          c.removeProperty('Until Charged');
        });
      }
    }

    if (_batteryGraphProps.value.length < 1) {
      _batteryGraphProps.next(
        [...Object.keys(ops.properties || [])].concat(
          ops.focusProperties.map((p) => p.label)
        )
      );
    }

    batteryCharts &&
      batteryCharts
        .filter((dc) => dc.id === b.serialNumber)
        .forEach((dc) => {
          dc.appendData(remaining);
        });

    index++;
  });

  for (let i = batteryGraphOps.length - 1; i >= 0; i--) {
    if (batteryGraphOps[i].timestamp !== now) {
      batteryGraphOps.splice(i, 1);
    }
  }
}
