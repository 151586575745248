import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';

interface QueryResult {
  hash: string;
  timestamp: string;
  type: string;
  result: string;
}

interface ProcessInfo {
  process: string;
  manufacturer: string;
  manufacturer_website: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class QueryService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getProcessInfo(process: string): Observable<ProcessInfo> {
    const url = `${this.apiUrl}/query/process?name=${encodeURIComponent(process)}`;
    const params = {};
    return this.http.get<QueryResult>(url, { params }).pipe(
      map((response) => (response ? JSON.parse(response.result) : undefined)),
      catchError((err) => {
        this.handleError<QueryResult>('QueryService.getProcessInfo');
        return throwError(() => err);
      })
    );
  }
}
