<div (keyup.escape)="close(true)" [class.show]="show$ | async" class="search">
  <div class="right-12 top-12 absolute">
    <button mat-icon-button type="button" (click)="close(true)" color="primary">
      <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
  </div>

  <input
    #searchInput
    (keyup.enter)="search()"
    (keyup)="searchTerm$.next($event)"
    [formControl]="searchCtrl"
    class="leading-none sm:leading-10 text-xl md:text-6xl search-input mb-4 sm:mb-0"
    placeholder="Search..."
  />
  <div class="hidden sm:block search-hint">Hit enter to search</div>

  <div [@listStagger]="searchResults.length" class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2 w-full px-8">
    <div @scaleIn *ngFor="let result of searchResults" class="p-0">
      <mon-search-result (click)="setSearch(result)" class="truncate">
        <div header>{{ result.system.hostname }}</div>
        <div content>{{ getUsers | callOnce: result }}</div>
        <div footer>
          <div class="w-full flex px-1 gap-2 text-secondary"
            ><span class="italic">last seen:</span><span>{{ result.timestamp | timeAgo }}</span></div
          >
        </div>
      </mon-search-result>
    </div>
  </div>
</div>

<div (click)="close(true)" *ngIf="show$ | async" class="search-overlay"></div>
