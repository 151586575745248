import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  mergeMap,
  tap
} from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { CustomValidators } from 'src/app/utils/validators';

export class NewTenantParams {
  onSuccess: () => void;
  constructor(init?: Partial<NewTenantParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-new-tenant',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ResultOverlayComponent,
    SpinnerComponent
  ],
  templateUrl: './new-tenant.component.html',
  styleUrls: ['./new-tenant.component.scss'],
  animations: [fadeInRight400ms]
})
export class NewTenantComponent {
  spin = false;
  overlayResult = OverlayResult.Unset;

  tenantNameValidated: boolean;
  tenantNameValidationErr: string;

  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      CustomValidators.customRegex('exp1', /^[a-z0-9-]*$/m),
      CustomValidators.customRegex('exp2', /^(?!-)(?!.*-$).*$/m),
      CustomValidators.customRegex('exp3', /^(?!.*--)[^-].*$/m)
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: NewTenantParams,
    private dialogRef: MatDialogRef<NewTenantComponent>,
    private snackBar: MatSnackBar,
    private userSvc: UserService,
    private tenantSvc: TenantService
  ) {
    this.form.controls.name.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.tenantNameValidationErr = '';
          this.tenantNameValidated = false;
        }),
        debounceTime(250),
        distinctUntilChanged(),
        filter(() => this.form.valid && !this.tenantNameValidated),
        mergeMap((name) => this.tenantSvc.validateTenantName(name)),
        catchError((err) => {
          console.error(err);
          return UNDEFINED;
        }),
        tap((res) => {
          if (res.tag.error) {
            this.tenantNameValidationErr = res.tag.error;
          } else if (res.tag.valid) {
            this.tenantNameValidated = true;
          }
        })
      )
      .subscribe();
  }

  submit(): void {
    this.spin = true;

    this.userSvc
      .createTenant(this.form.value.name)
      .pipe(
        delay(10000),
        tap(() => {
          this.overlayResult = OverlayResult.Success;
          this.overlayClose(OverlayResult.Success);
        }),
        catchError((err) => {
          this.snackBar.open(parseError(err), 'CLOSE', {
            duration: 3000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });

          this.overlayResult = OverlayResult.Error;

          return UNDEFINED;
        }),
        finalize(() => (this.spin = false))
      )
      .subscribe();
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.();
      this.dialogRef.close();
    }
  }
}
