import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, delay, filter, map, tap } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { StateService } from 'src/app/core/services/state.service';
import { UserService } from 'src/app/core/services/user.service';
import {
  TenantSwitchComponent,
  TenantSwitchParams
} from 'src/app/view/settings/tenant/tenant-switch/tenant-switch.component';

import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { trackNavigationItem } from '../../utils/track-by';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [stagger80ms, fadeInRight400ms]
})
export class SidenavComponent {
  @Input() collapsed: boolean;

  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  data$ = combineLatest([
    this.stateSvc.tenant$.pipe(
      filter((tenant) => !!tenant),
      tap(() => (this.tenantReload = true)),
      delay(0),
      tap(() => (this.tenantReload = false))
    ),
    this.stateSvc.currentUser$.pipe(filter((user) => !!user))
  ]).pipe(
    map(([tenant, user]) => {
      return {
        tenant,
        user
      };
    })
  );

  sameTenantNames$ = this.userSvc.tenants().pipe(
    map((tenants) => {
      const sameNames = new Map<string, boolean>();

      tenants.forEach((tenant) => {
        if (sameNames.get(tenant.name) === undefined) {
          sameNames.set(tenant.name, false);
        } else {
          sameNames.set(tenant.name, true);
        }
      });

      return sameNames;
    })
  );

  tenantReload = false;
  items = this.navigationService.items;
  trackNavigationItem = trackNavigationItem;

  constructor(
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private stateSvc: StateService,
    private userSvc: UserService
  ) {}

  onMouseEnter(): void {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave(): void {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse(): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          sidenav: {
            state: this.collapsed ? 'expanded' : 'collapsed'
          }
        }
      }
    });
  }

  openTenantSwitch(): void {
    if (!this.stateSvc.currentUser.canSwitchTenant) {
      return;
    }

    this.dialog.open(TenantSwitchComponent, {
      restoreFocus: false,
      data: new TenantSwitchParams({}),
      width: '600px'
    });
  }
}
