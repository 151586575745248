import { Updatable } from '../interfaces/updatable.interface';
import { Alarm } from './alarm.model';
import { CpuSummary } from './cpu-summary.model';
import { DiskSummary } from './disk-summary.model';
import { GPUSummary } from './gpu-summary.model';
import { MemorySummary } from './memory-summary.model';
import { NetworkSummary } from './network-summary.model';
import { SystemSummary } from './system-summary.model';

export class AgentSummary implements Updatable<AgentSummary> {
  public system: SystemSummary;
  public cpu: CpuSummary;
  public disk: DiskSummary;
  public gpu: GPUSummary;
  public memory: MemorySummary;
  public network: NetworkSummary;
  public alarms: Array<Alarm>;

  constructor(init?: Partial<AgentSummary>) {
    if (!init) {
      return;
    }
    if (init.system) {
      this.system = new SystemSummary(init.system);
    }
    if (init.cpu) {
      this.cpu = new CpuSummary(init.cpu);
    }
    if (init.disk) {
      this.disk = new DiskSummary(init.disk);
    }
    if (init.gpu) {
      this.gpu = new GPUSummary(init.gpu);
    }
    if (init.memory) {
      this.memory = new MemorySummary(init.memory);
    }
    if (init.network) {
      this.network = new NetworkSummary(init.network);
    }
    if (init.alarms) {
      this.alarms = init.alarms.map((alarm) => new Alarm(alarm));
    }
  }

  update(input: Partial<AgentSummary>): this {
    return Object.assign(this, input);
  }
}
