import { Component } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { NavigationLink } from 'src/@vex/interfaces/navigation-item.interface';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { StateService } from 'src/app/core/services/state.service';

import { PopoverRef } from '../popover/popover-ref';

export interface MegaMenuConfig {
  pages: Array<MegaMenuPage>;
  agents: Array<MegaMenuAgent>;
}

export interface MegaMenuPage {
  label: string;
  route: string;
  icon: string;
}

export interface MegaMenuAgent {
  agentID: string;
}

@Component({
  selector: 'vex-mega-menu',
  templateUrl: './mega-menu.component.html'
})
export class MegaMenuComponent {
  recentPages$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const result = [...settings.consoleUI.recentPages].reverse().map((p) => {
        const found = this.navigationSvc.findByRoute(p);
        return found ? found : {};
      });

      return (result || []) as NavigationLink[];
    })
  );

  recentMachines$ = this.stateSvc.currentUserSettings$.pipe(
    switchMap((settings) =>
      this.stateSvc.tenant$.pipe(
        take(1),
        map((t) => [...(settings.consoleUI.recentMachines.get(t.id) || [])].reverse())
      )
    )
  );

  constructor(
    private popoverRef: PopoverRef<MegaMenuComponent>,
    private navigationSvc: NavigationService,
    private stateSvc: StateService
  ) {}

  close(): void {
    this.popoverRef.close();
  }

  gotoMachine(agentID: string): void {
    this.stateSvc.setSearch(agentID);
    this.close();
  }
}
