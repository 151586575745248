<div class="grid grid-flow-row gap-2 grid-cols-1 pt-2 md:grid-cols-3">
  <div [@listStagger]="2" class="flex flex-col gap-2 col-span-1">
    <div *ngIf="true" @fadeInUp class="card overflow-hidden">
      <div class="flex items-center px-gutter h-14 border-b bg-app-bar">
        <h2 class="title m-0">Reset To Default</h2>
      </div>

      <div class="flex items-center justify-center py-2">
        <div class="flex-1 m-0 pl-4">Introduction Wizard</div>
        <div class="flex-1 m-0 pr-2">
          <button (click)="resetIntro()" data-intro-key="recent-nav" class="h-12 float-right" color="primary" mat-button type="button">
            <span>Reset</span>
          </button>
        </div>
      </div>

      <div class="flex items-center justify-center py-2">
        <div class="flex-1 m-0 pl-4">Table Settings</div>
        <div class="flex-1 m-0 pr-2">
          <button (click)="resetTables()" data-intro-key="recent-nav" class="h-12 float-right" color="primary" mat-button type="button">
            <span>Reset</span>
          </button>
        </div>
      </div>

      <div class="flex items-center justify-center py-2">
        <div class="flex-1 m-0 pl-4">Configuration Settings</div>
        <div class="flex-1 m-0 pr-2">
          <button (click)="resetConfig()" data-intro-key="recent-nav" class="h-12 float-right" color="primary" mat-button type="button">
            <span>Reset</span>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="true" @fadeInUp class="card overflow-hidden">
      <div class="flex items-center px-gutter h-14 border-b bg-app-bar">
        <h2 class="title m-0">Account</h2>
      </div>
      <ng-container *ngLet="canDeleteAccount$ | async as canDeleteAccount">
        <div class="p-4" [matTooltip]="canDeleteAccount ? null:'You must cancel all owned tenant subscriptions first'">
          <button
            class="w-full h-12"
            color="warn"
            mat-button
            type="button"
            mat-flat-button
            (click)="deleteAccount()"
            [disabled]="!canDeleteAccount"
          >
            <span>DELETE MY ACCOUNT</span>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
