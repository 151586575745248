<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <form autocomplete="off" (ngSubmit)="addBlock()" [formGroup]="form">
      <div class="flex justify-start items-center content-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">Add Block</h2>

        <button class="text-secondary -mt-2 -mr-2" (click)="close()" mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" class="leading-none"></fa-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border"></mat-divider>
      <mat-divider class="-mx-6 text-border"></mat-divider>

      <mat-dialog-content class="grid grid-cols-1 gap-4 py-4">
        <div>
          <blockquote>This will stop the machine(s) from reporting metrics and fulfilling requests</blockquote>

          <div class="flex flex-col">
            <mat-form-field>
              <mat-label>Machine</mat-label>
              <input
                #searchInput
                matInput
                formControlName="machineID"
                class="border-b-2 text-2xl focus:outline-none rounded-none border-r-0 border-l-0 border-t-0 bg-transparent w-full"
                placeholder="Search For Machine"
                cdkFocusInitial
                (keyup)="_searchTerm.next($event)"
                [matAutocomplete]="auto"
              />
              <div class="relative border-b-2">
                <mat-progress-bar
                  *ngIf="searchSpin"
                  mode="indeterminate"
                  color="primary"
                  class="absolute"
                  [style.height]="'2px'"
                  [style.top]="'0px'"
                ></mat-progress-bar>
              </div>
              <mat-autocomplete #auto tabSelected autoActiveFirstOption matAutocompleteTrigger>
                <mat-option *ngFor="let searchResult of searchResults$ | async" [value]="searchResult.id" [disabled]="spin">
                  {{ searchResult.id }}
                </mat-option>
              </mat-autocomplete>

              <!-- <mat-label>Machine</mat-label>
              <input formControlName="agentID" matInput cdkFocusInitial />
              <fa-icon [icon]="['fad', 'computer-classic']" size="lg" [fixedWidth]="true" matPrefix></fa-icon> -->
            </mat-form-field>
            <mat-form-field>
              <mat-label>Machine ID</mat-label>
              <input formControlName="machineID" matInput />
              <fa-icon [icon]="['fad', 'fingerprint']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
            </mat-form-field>
            <mat-form-field>
              <mat-label>IP</mat-label>
              <input formControlName="ip" matInput />
              <fa-icon [icon]="['fad', 'location']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
            </mat-form-field>
          </div>

          <mat-radio-group aria-label="Select an option" class="flex flex-col" formControlName="blockOp">
            <mat-radio-button value="0">
              <mat-form-field appearance="fill">
                <mat-label>Block Machine(s) For</mat-label>
                <input formControlName="blockFor" matInput cdkFocusInitial type="number" min="0" max="9999" />
                <span matSuffix> Minutes</span>
              </mat-form-field>
            </mat-radio-button>

            <mat-radio-button value="1">
              <mat-form-field appearance="fill">
                <mat-label>Block Machine(s) Until</mat-label>
                <input formControlName="blockFor" matInput cdkFocusInitial [matDatepicker]="picker" formControlName="blockUntil" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </mat-radio-button>

            <mat-radio-button value="2" class="py-4">Block Indefinitely</mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="err" @fadeInRight @fadeOutRight class="card bg-red-500 justify-between flex text-white">
          <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="justify-end">
        <button mat-button type="submit" [disabled]="spin" cdkFocusInitial>Add Block</button>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
