import { animate, style, transition, trigger } from '@angular/animations';

export function fadeOutAnimation(duration: number) {
    return trigger('fadeOut', [
        transition(':leave', [
            style({
                opacity: 1
            }),
            animate(
                `${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({
                    opacity: 0
                })
            )
        ])
    ]);
}

export const fadeOut400ms = fadeOutAnimation(400);
export const fadeOut200ms = fadeOutAnimation(200);
export const fadeOut80ms = fadeOutAnimation(80);
