<div *ngIf="settings$ | async as settings" class="py-2 mx-2 px-2">
  <div class="flex items-center">
    <fa-icon [icon]="['fad', 'cog']" [fixedWidth]="true" size="2x" class="text-primary mr-3"></fa-icon>
    <div class="text-2xl text-default/90">Configuration</div>
  </div>

  <div class="pb-2 mb-2">
    <h5 class="uppercase caption text-secondar bold py-2">THEME STYLE</h5>

    <div class="rounded">
      <div class="rounded overflow-hidden relative shadow-8 h-16">
        <div class="theme-overlay flex gap-1 items-center justify-center">
          <button (click)="setConfig(Style.light)" mat-raised-button type="button"> LIGHT </button>
          <button (click)="setConfig(Style.dark)" mat-raised-button type="button"> DARK </button>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-2 mb-2">
    <h5 class="uppercase caption text-secondary bold py-2">Display Density</h5>

    <div class="section-content">
      <mat-slide-toggle
        (change)="displayDensityChange($event)"
        [checked]="settings.consoleUI.config.display.density === DisplayDensity.Compact ? true : false"
      >
        {{ settings.consoleUI.config.display.density === DisplayDensity.Compact ? 'Condensed' : 'Default' }}
      </mat-slide-toggle>
    </div>
  </div>

  <div class="pb-2 mb-2">
    <h5 class="uppercase caption text-secondary bold py-2">SEARCH</h5>

    <div class="section-content">
      <mat-slide-toggle (change)="searchChange($event)" [checked]="settings.consoleUI.config.search.goToDashboard">
        {{ settings.consoleUI.config.search.goToDashboard ? 'Go To Dashboard' : 'Stay On Page' }}
      </mat-slide-toggle>
    </div>
  </div>
</div>
