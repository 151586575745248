import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Permission } from 'src/app/shared/enums/permission.enum';

import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { PopoverService } from '../../components/popover/popover.service';
import { ConfigService } from '../../services/config.service';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { StateService } from 'src/app/core/services/state.service';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { fadeOutDown200ms } from 'src/app/shared/animations/fade-out-down.animation';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [fadeInUp200ms, fadeOutDown200ms]
})
export class ToolbarComponent {
  permission = Permission;

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.navbar.position === 'below-toolbar')
  );

  tenantInvites$ = this.stateSvc.tenantInvites$;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private stateSvc: StateService
  ) {}

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      offsetY: 12,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    });
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }
}
