import { Component, ViewChild } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { APIKeyService } from 'src/app/core/services/apikey.service';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { propertyByString } from 'src/app/core/utils/property-by-string';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { APIKey } from 'src/app/shared/models/apikey.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { isEmpty } from 'src/app/utils/condition';

import { APIKeyDataSource } from './api-keys.datasource';

@UntilDestroy()
@Component({
  selector: 'mon-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
  animations: [stagger80ms, fadeInUp400ms, fadeInRight80ms]
})
export class SiteComponent {
  permission = Permission;
  readonly DEFAULT_SORT_FIELD = 'apiKey';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.SETTINGS_SITE;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource: APIKeyDataSource = new APIKeyDataSource(this.apiKeySvc);

  columns: TableColumn<APIKey>[] = [
    {
      label: 'Key',
      property: 'apiKey',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['truncate']
    },
    {
      label: 'Created',
      property: 'timestamp',
      type: 'date',
      visible: true,
      hideable: true
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: false,
      buttonIconName: 'angle-right',
      tooltip: 'View'
    }
  ];

  hashCheckSettings$ = this.tenantSvc.getSettings('agent.script.allowHashCheck');
  updaterSettings$ = this.tenantSvc.getSettings('agent.updater.updateAgentBinaryEnabled');
  ohmSettings$ = this.tenantSvc.getSettings('agent.ohm.enabled');
  gatewaySettings$ = this.tenantSvc.getSettings('agent.gateway.pingable');
  flowTrackingSettings$ = this.tenantSvc.getSettings('tenant.allowFlowTracking');

  refreshOpenai = new BehaviorSubject<void>(undefined);
  openaiSettings$ = this.refreshOpenai
    .asObservable()
    .pipe(switchMap(() => this.tenantSvc.getSettings('openai.apiKey')));

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    }),
    tap(() => this.loadAPIKeys())
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  user$ = this.stateSvc.currentUser$;

  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  constructor(
    private apiKeySvc: APIKeyService,
    private stateSvc: StateService,
    private tenantSvc: TenantService
  ) {}

  initControls(): void {
    if (!!this.sort) {
      this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.tableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();
    }
  }

  loadAPIKeys(): void {
    this.dataSource.loadAPIKeys([
      OrderBy.SortToOrderBy({
        active: this.sortField,
        direction: this.sortDirection
      })
    ]);
  }

  get hideableColumns(): TableColumn<APIKey>[] {
    return this.columns.filter((c) => c.hideable);
  }

  trackByProperty<T>(_index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  getValue(row: APIKey, property: string): string {
    return propertyByString(row, property);
  }

  setSetting(
    setting: string,
    value: unknown,
    refresher: BehaviorSubject<void> = undefined
  ): void {
    this.tenantSvc
      .setSetting(setting, value)
      .pipe(
        tap({
          next: () => refresher?.next()
        })
      )
      .subscribe();
  }
}
