import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, Input, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@auth0/auth0-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  Subject,
  catchError,
  concat,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  timeout
} from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { PostalService } from 'src/app/core/services/postal.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import {
  CompanySizeType,
  Coupon,
  SubscribeService
} from 'src/app/core/services/subscribe.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import { LoaderBallComponent } from 'src/app/shared/components/loader-ball/loader-ball.component';
import { EstimateComponent } from 'src/app/shared/components/subscription/estimate/estimate.component';
import {
  PaymentSourceComponent,
  SubscriptionPaymentSourceParams
} from 'src/app/shared/components/subscription/payment-source/payment-source.component';
import { RenderValue } from 'src/app/shared/interfaces/render-value';
import {
  Frequency,
  PaymentSource,
  Plan,
  Subscription
} from 'src/app/shared/models/subscription.model';
import { SharedModule } from 'src/app/shared/shared.module';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { CustomValidators, PasswordStateMatcher } from 'src/app/utils/validators';
import { scaleOut400ms } from '../../animations/scale-out.animation';
import { AddressComponent } from '../address/address.component';
import { PaymentSourceViewComponent } from '../payment-source-view/payment-source-view.component';

interface CachedPlan {
  freq: Frequency;
  count: number;
}

interface StepParams {
  enablePasswordStep: boolean;
  enableTenantStep: boolean;
  enableDetailStep: boolean;
  enableCouponStep: boolean;
  enableMachineCountStep: boolean;
  enablePaymentStep: boolean;
  enableEstimateStep: boolean;
}

export class SubscriptionParams {
  subscription: Subscription;
  paymentSource: PaymentSource;
  stepParams?: StepParams;
  onSuccess: (result: Subscription | PaymentSource) => void;
  onCancel: () => void;
  constructor(init?: Partial<SubscriptionParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-subscription',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,
    SharedModule,
    ReactiveFormsModule,
    AddressComponent,
    EstimateComponent,
    LoaderBallComponent,
    PaymentSourceViewComponent,
    ResultOverlayComponent,
    SpinnerComponent
  ],
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  animations: [fadeInRight400ms, fadeInUp400ms, scaleOut400ms]
})
export class SubscriptionComponent {
  cache = new Map<string, CachedPlan>();

  companySizeType = CompanySizeType;

  isNewAccountByInvite: boolean;

  enablePasswordStep: boolean;
  enableTenantStep: boolean;
  enableDetailStep: boolean;
  enableCouponStep: boolean;
  enableMachineCountStep: boolean;
  enablePaymentStep: boolean;
  enableEstimateStep: boolean;

  tenantNameValidating: boolean;
  tenantNameValidated: boolean;
  tenantNameValidationErr: string;

  couponValidating: boolean;
  couponValidated: boolean;
  couponValidationErr: string;

  @Input('password-step')
  get passwordStep(): boolean {
    return this.enablePasswordStep;
  }
  set passwordStep(value: boolean | '') {
    this.enablePasswordStep = value === '' || value;
  }
  @Input('tenant-step')
  get tenantStep(): boolean {
    return this.enableTenantStep;
  }
  set tenantStep(value: boolean | '') {
    this.enableTenantStep = value === '' || value;
  }

  @Input('detail-step')
  get detailStep(): boolean {
    return this.enableDetailStep;
  }
  set detailStep(value: boolean | '') {
    this.enableDetailStep = value === '' || value;
  }

  @Input('coupon-step')
  get couponStep(): boolean {
    return this.enableCouponStep;
  }
  set couponStep(value: boolean | '') {
    this.enableCouponStep = value === '' || value;
  }

  @Input('machine-count-step')
  get machineCountStep(): boolean {
    return this.enableMachineCountStep;
  }
  set machineCountStep(value: boolean | '') {
    this.enableMachineCountStep = value === '' || value;
  }

  @Input('payment-step')
  get paymentStep(): boolean {
    return this.enablePaymentStep;
  }
  set paymentStep(value: boolean | '') {
    this.enablePaymentStep = value === '' || value;
  }

  @Input('estimate-step')
  get estimateStep(): boolean {
    return this.enableEstimateStep;
  }
  set estimateStep(value: boolean | '') {
    this.enableEstimateStep = value === '' || value;
  }

  @Input('invite-key') inviteKey: string;
  @Input('invite-email') inviteEmail: string;

  spin = false;
  overlayResult = OverlayResult.Unset;
  machineCountIncrement = 5;
  matcher = new PasswordStateMatcher();

  _selectedCoupon = new BehaviorSubject<Coupon>(undefined);
  selectedCoupon$ = this._selectedCoupon
    .asObservable()
    .pipe(distinctUntilChanged((p, c) => p?.id === c?.id));

  _animateCoupon = new BehaviorSubject<boolean>(false);
  animateCoupon$ = this._animateCoupon.asObservable();

  plans$ = this.subscribeSvc.getPlans().pipe(
    tap((plans) => {
      plans.forEach((p) => {
        const defaultFreq =
          p.frequencies.find((f) => f.defaultSelection) || p.frequencies[0];

        const currentSubscription = this.params?.subscription || undefined;

        const defaultCount =
          currentSubscription && currentSubscription.itemPriceID === defaultFreq.priceID
            ? this.params.subscription.quantity
            : defaultFreq.defaultMachineCount;

        this.cache.set(p.id, {
          freq: defaultFreq,
          count: defaultCount
        });
      });
    }),
    tap((plans) => {
      if (plans.length > 0) {
        const foundPlan =
          (this.params?.subscription != null
            ? plans.find(
                (p) =>
                  !!p.frequencies.find(
                    (f) => f.priceID === this.params.subscription.itemPriceID
                  )
              )
            : undefined) ||
          plans.find((p) => p.defaultSelection) ||
          plans[0];

        if (foundPlan) {
          this._selectedPlan.next(foundPlan);
          this.planForm.controls.plan.setValue(foundPlan);

          const foundFreq =
            (this.params?.subscription != null
              ? foundPlan.frequencies.find(
                  (f) => f.priceID === this.params.subscription.itemPriceID
                )
              : undefined) ||
            foundPlan.frequencies.find((f) => f.defaultSelection) ||
            foundPlan.frequencies[0];

          if (foundFreq) {
            this._selectedFrequency.next(foundFreq);
          }

          return;
        }
      }

      this._selectedPlan.next(undefined);
      this.planForm.controls.plan.setValue(undefined);
    }),
    map((plans) => plans.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)))
  );

  _selectedPlan = new BehaviorSubject<Plan>(undefined);
  selectedPlan$ = this._selectedPlan.asObservable().pipe(
    distinctUntilChanged((p, c) => p?.id === c?.id),
    tap((p) => {
      if (!this.cache.has(p.id)) {
        const defaultFreq =
          p.frequencies.find((f) => f.defaultSelection) || p.frequencies[0];
        this.cache.set(p.id, {
          freq: defaultFreq,
          count: defaultFreq.defaultMachineCount
        });
      }
    }),
    tap(
      () =>
        this.matStepper &&
        this.matStepper.steps
          .filter((s) => ['Frequency', 'Summary'].includes(s.label))
          .forEach((s) => s.reset())
    )
  );

  _selectedFrequency = new BehaviorSubject<Frequency>(undefined);
  selectedFrequency$ = this._selectedFrequency.asObservable();

  passwordForm = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        CustomValidators.passwordStrength
      ]),
      passwordConfirm: new FormControl('')
    },
    {
      validators: (group: FormGroup) => {
        const password = group.get('password');
        const passwordConfirm = group.get('passwordConfirm');
        if (!passwordConfirm?.touched) {
          return null;
        }
        return password?.value === passwordConfirm?.value ? null : { mustMatch: true };
      }
    }
  );

  tenantForm = new FormGroup({
    tenantName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(60),
      CustomValidators.customRegex('exp1', /^[a-z0-9-]*$/m),
      CustomValidators.customRegex('exp2', /^(?!-)(?!.*-$).*$/m),
      CustomValidators.customRegex('exp3', /^(?!.*--)[^-].*$/m)
    ])
  });

  couponForm = new FormGroup({
    coupon: new FormControl('', [])
  });

  detailForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    companySize: new FormControl('', [Validators.required]),

    address1: new FormControl(''),
    address2: new FormControl(''),
    address3: new FormControl(''),
    locality: new FormControl(''),
    region: new FormControl(''),
    regionCode: new FormControl(''),
    postcode: new FormControl(''),
    countryCode: new FormControl('', [Validators.required]),
    country: new FormControl('')
  });

  planForm = new FormGroup({
    plan: new FormControl<Plan>(undefined, [Validators.required])
  });

  frequencyForm = new FormGroup({
    frequency: new FormControl<Frequency>(undefined, [Validators.required])
  });

  machineForm = new FormGroup({
    machineCount: new FormControl(5, [
      Validators.min(0),
      Validators.required,
      CustomValidators.noWhitespace,
      CustomValidators.commonDenominator(5)
    ])
  });

  summaryForm = new FormGroup({});

  paymentSourceForm = new FormGroup({
    exists: new FormControl(false, [Validators.requiredTrue])
  });

  estimateForm = new FormGroup({});

  _refresh = new Subject<void>();
  refresh$ = this._refresh.asObservable();

  private _loadingEstimate = new BehaviorSubject<RenderValue<boolean>>({ value: true });
  loadingEstimate$ = this._loadingEstimate.asObservable();

  private _estimateError = new BehaviorSubject<string>(undefined);
  estimateError$ = this._estimateError.asObservable();

  estimate$ = this.refresh$.pipe(
    map(() => {
      return {
        priceID: this._selectedFrequency.value.priceID,
        quantity: this.machineForm.controls.machineCount.value
      };
    }),
    filter((values) => !!values.priceID && !!values.quantity),
    distinctUntilChanged((a, b) => a.priceID === b.priceID && a.quantity === b.quantity),
    tap(() => this._estimateError.next(undefined)),
    tap(() => this._loadingEstimate.next({ value: true })),
    switchMap((values) =>
      this.subscribeSvc.getUpdatedEstimate(values.priceID, values.quantity).pipe(
        tap(() => this.estimateForm.setErrors(undefined)),
        catchError((err) => {
          this.estimateForm.setErrors({ invalid: true });
          const msg = parseError(err);
          this._estimateError.next(
            `An estimate could not be created${msg ? ': ' + msg : ''}`
          );
          return UNDEFINED;
        }),
        tap(() => setTimeout(() => this.estimateNextBtn.focus(), 100)),
        finalize(() => this._loadingEstimate.next({ value: false }))
      )
    )
  );

  private _submitError = new BehaviorSubject<string>(undefined);
  submitError$ = this._submitError.asObservable();

  private _tenantRegistered = new BehaviorSubject<boolean>(false);
  tenantRegistered$ = this._tenantRegistered.asObservable();

  _paymentSource = new BehaviorSubject<PaymentSource>(undefined);
  paymentSource$ = this._paymentSource
    .asObservable()
    .pipe(tap((ps) => ps && this.paymentSourceForm.controls.exists.setValue(true)));

  @ViewChild(MatStepper) matStepper: MatStepper;

  _passwordInput: ElementRef;
  @ViewChild('password', { static: false }) set passwordInput(passwordInput: ElementRef) {
    this._passwordInput = passwordInput;
    if (this._passwordInput) {
      setTimeout(() => this._passwordInput.nativeElement.focus(), 100);
    }
  }

  _tenantNameInput: ElementRef;
  @ViewChild('tenantName', { static: false }) set tenantNameInput(
    tenantNameInput: ElementRef
  ) {
    this._tenantNameInput = tenantNameInput;
    if (this._tenantNameInput) {
      setTimeout(() => this._tenantNameInput.nativeElement.focus(), 100);
    }
  }

  _couponInput: ElementRef;
  @ViewChild('coupon', { static: false }) set couponInput(couponInput: ElementRef) {
    this._couponInput = couponInput;
    if (this._couponInput) {
      setTimeout(() => this._couponInput.nativeElement.focus(), 100);
    }
  }

  _firstNameInput: ElementRef;
  @ViewChild('firstName', { static: false }) set firstNameInput(
    firstNameInput: ElementRef
  ) {
    this._firstNameInput = firstNameInput;
    if (this._firstNameInput) {
      setTimeout(() => this._firstNameInput.nativeElement.focus(), 100);
    }
  }

  @ViewChild('machineCount', { static: false }) machineCount: ElementRef;
  @ViewChild('estimateNextBtn', { static: false }) estimateNextBtn: MatButton;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private params: SubscriptionParams,
    @Optional() private dialogRef: MatDialogRef<SubscriptionComponent>,
    protected dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authSvc: AuthService,
    protected registrationSvc: RegistrationService,
    protected subscribeSvc: SubscribeService,
    protected postalSvc: PostalService,
    protected tenantSvc: TenantService
  ) {
    if (params?.paymentSource) {
      this._paymentSource.next(params.paymentSource);
    }

    if (params?.subscription) {
      this.machineForm.controls.machineCount.setValue(
        Math.max(params.subscription.quantity, 5)
      );
    }

    if (params?.stepParams) {
      this.enablePasswordStep = params.stepParams.enablePasswordStep;
      this.enableTenantStep = params.stepParams.enableTenantStep;
      this.enableDetailStep = params.stepParams.enableDetailStep;
      this.enableCouponStep = params.stepParams.enableCouponStep;
      this.enableMachineCountStep = params.stepParams.enableMachineCountStep;
      this.enablePaymentStep = params.stepParams.enablePaymentStep;
      this.enableEstimateStep = params.stepParams.enableEstimateStep;
    }

    this.tenantForm.controls.tenantName.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => this.tenantForm.controls.tenantName.markAsTouched()),
        tap(() => {
          this.tenantNameValidationErr = '';
          this.tenantNameValidated = false;
        })
      )
      .subscribe();

    this.machineForm.controls.machineCount.valueChanges
      .pipe(
        tap((c) => {
          const cached = this.cache.get(this.planForm.value.plan.id);
          cached.count = c;
        })
      )
      .subscribe();
  }

  validateTenantName(): void {
    of(this.tenantForm.value.tenantName)
      .pipe(
        take(1),
        tap(() => {
          this.tenantNameValidationErr = '';
          this.tenantNameValidated = false;
        }),
        filter(() => this.tenantForm.valid && !this.tenantNameValidated),
        tap(() => (this.tenantNameValidating = true)),
        mergeMap((name) => this.tenantSvc.validateTenantName(name)),
        catchError((err) => {
          console.error(err);
          return UNDEFINED;
        }),
        tap((res) => {
          this.tenantNameValidating = false;
          this._tenantNameInput.nativeElement.focus();
          if (res.tag.error) {
            this.tenantNameValidationErr = res.tag.error;
          } else if (res.tag.valid) {
            this.tenantNameValidated = true;
          }
        })
      )
      .subscribe();
  }

  selectItem(plan: Plan): void {
    const cached = this.cache.get(plan.id);

    this._selectedPlan.next(plan);
    this.planForm.controls.plan.setValue(plan);
    this.selectFrequency(cached.freq);
  }

  selectFrequency(frequency: Frequency): void {
    const cached = this.cache.get(this.planForm.value.plan.id);
    cached.freq = frequency;

    this._selectedFrequency.next(frequency);
    this.frequencyForm.controls.frequency.setValue(frequency);

    this.machineForm.controls.machineCount.setValue(Math.max(cached.count, 5));
  }

  overlayClose(r: OverlayResult, result: Subscription | PaymentSource): void {
    this.overlayResult =
      r === OverlayResult.Success ? OverlayResult.Success : OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.(result);
      this.spin = false;
      setTimeout(() => window.location.reload(), 2000);
    }
  }

  onStep(event: StepperSelectionEvent) {
    switch (event.selectedStep.label) {
      case 'Password':
        setTimeout(() => this._passwordInput.nativeElement.focus(), 100);
        break;
      case 'Tenant':
        setTimeout(() => this._tenantNameInput.nativeElement.focus(), 100);
        break;
      case 'Details':
        setTimeout(() => this._firstNameInput.nativeElement.focus(), 100);
        break;
      case 'Coupon':
        setTimeout(() => this._couponInput.nativeElement.focus(), 100);
        break;
      case 'Plan':
        break;
      case 'Frequency':
        break;
      case 'Machines':
        setTimeout(() => {
          this.machineCount && this.machineCount.nativeElement.focus();
        }, 100);
        break;
      case 'Summary':
        break;
      case 'Payment':
        break;
      case 'Estimate':
        this._refresh.next();
        break;
    }
  }

  submit(): void {
    if (this.params?.subscription) {
      this.submitUpdateSubscription();
    } else {
      this.submitNewSubscription();
    }
  }

  private submitNewSubscription(): void {
    this.spin = true;
    this.snackBar.dismiss();

    this.registrationSvc
      .confirmTenant(
        this.inviteKey,
        this.inviteEmail,
        this.passwordForm.value.password,
        this.tenantForm.value.tenantName,
        this.detailForm.value.firstName,
        this.detailForm.value.lastName,
        this.detailForm.value.companyName,
        this.detailForm.value.companySize,
        this.detailForm.value.countryCode,
        this.detailForm.value.region,
        this.detailForm.value.regionCode,
        this.detailForm.value.postcode,
        this.frequencyForm.controls.frequency.value.priceID,
        this._selectedCoupon.getValue()?.id || ''
      )
      .pipe(
        tap(() => this._tenantRegistered.next(true)),
        catchError((err) => {
          this.snackBar.open(parseError(err) || 'could not register tenant', 'CLOSE', {
            duration: 3000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });

          return UNDEFINED;
        }),
        finalize(() => (this.spin = false))
      )
      .subscribe();
  }

  private submitUpdateSubscription(): void {
    this.dialogRef.disableClose = true;
    this.spin = true;

    concat(
      this.subscribeSvc
        .updateSubscription(
          this.frequencyForm.controls.frequency.value.priceID,
          this.machineForm.controls.machineCount.value,
          this._selectedCoupon.getValue()?.id || ''
        )
        .pipe(
          catchError((err) => {
            this.spin = false;

            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });

            throw err;
          })
        ),
      this.subscribeSvc.getSubscription(true).pipe(
        timeout(60000),
        tap((result) => {
          this.overlayClose(OverlayResult.Success, result);
        }),
        catchError(() => {
          window.location.reload();

          return UNDEFINED;
        })
      )
    )
      .pipe(
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe();
  }

  planImageIsFontAwesome(plan: Plan): boolean {
    if (plan.imageLink.indexOf('http') >= 0) {
      return false;
    }
    return true;
  }

  planImageAsIconName(name: string): IconName {
    return name as IconName;
  }

  login(): void {
    this.authSvc.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`
      },
      appState: {
        target: 'home/dashboard'
      }
    });
  }

  validateCoupon(): void {
    if (this._selectedCoupon.value?.id === this.couponForm.value.coupon) {
      return;
    }

    this.couponForm.controls.coupon.setErrors(undefined);
    this.couponValidationErr = '';
    this.couponValidated = false;

    this.subscribeSvc
      .validateCouponCode(this.couponForm.value.coupon)
      .pipe(
        catchError((err) => {
          console.error(err);

          return UNDEFINED;
        }),
        tap((res) => {
          this.couponValidating = false;
          this._couponInput.nativeElement.focus();
          if (res.tag.error) {
            this.couponForm.controls.coupon.setErrors({ invalid: true });
            this.couponValidationErr = res.tag.error;
          } else if (res.tag.valid) {
            this.couponForm.controls.coupon.setValue('');
            this.couponValidated = true;
            this._animateCoupon.next(false);
            this._selectedCoupon.next(res.tag.coupon);
            setTimeout(() => this._animateCoupon.next(true), 250);
          }
        })
      )
      .subscribe();
  }

  removeCoupon(): void {
    this.couponValidated = false;

    this._selectedCoupon.next(undefined);
  }

  updatePaymentSource(): void {
    this.dialog.open(PaymentSourceComponent, {
      disableClose: true,
      restoreFocus: false,
      panelClass: 'post-subscription',
      data: new SubscriptionPaymentSourceParams({
        paymentSource: this.params.paymentSource,
        onPaymentSourceUpdated: (ps) => {
          this._paymentSource.next(ps);
          this.matStepper.next();
        }
      }),
      width: '800px'
    });
  }
}
