import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

import {
  NavigationItem,
  NavigationLink
} from '../../interfaces/navigation-item.interface';
import { NavigationService } from '../../services/navigation.service';
import { trackByRoute } from '../../utils/track-by';

@Component({
  selector: 'vex-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent {
  @Input() item: NavigationItem;

  isActive$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => (item: NavigationItem) => this.hasActiveChilds(item))
  );

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;
  trackByRoute = trackByRoute;

  constructor(private navigationService: NavigationService, private router: Router) {}

  hasActiveChilds(parent: NavigationItem): boolean {
    if (this.isLink(parent)) {
      return this.router.isActive(parent.route as string, false);
    }

    if (this.isDropdown(parent) || this.isSubheading(parent)) {
      return parent.children.some((child) => {
        if (this.isDropdown(child)) {
          return this.hasActiveChilds(child);
        }

        if (this.isLink(child) && !this.isFunction(child)) {
          return this.router.isActive(child.route as string, false);
        }

        return false;
      });
    }

    return false;
  }

  isFunction(item: NavigationLink): boolean {
    // isFunction(prop: NavigationLink['route']): boolean {
    // return prop instanceof Function;
    return !!item.routeFunction;
  }

  runRouteFunction(item: NavigationLink): void {
    item.routeFunction();
  }
}
