<div *ngIf="data$ | async as data" class="pt-2 flex flex-col md:flex-row md:items-start">
  <div class="flex-auto">
    <div @fadeInUp class="card overflow-x-auto overflow-y-hidden">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
          <span>Company</span>
        </h2>
      </div>

      <div *ngIf="exposedFields$ | async as exposedFields; else loading" class="grid grid-cols-1 gap-4 pb-4">
        <ng-container *ngIf="license$ | async as rvLicense">
          <div [@listStagger]="exposedFields.length" class="tenant-table">
            <div @fadeInRight *ngIf="true" class="field-link">
              <a
                class="field-link p-1 pr-2 md:p-3 md:pr-6 flex justify-start items-center content-center"
                [ngClass]="data.canUpdateLicense ? 'cursor-pointer' : 'readonly'"
                (click)="
                  data.canUpdateLicense
                    ? data.hasPaymentSource &&
                      (rvLicense.value.status === licenseStatus.NonRewing || rvLicense.value.status === licenseStatus.Cancelled)
                      ? reactivateSubscription()
                      : updateSubscription()
                    : null
                "
              >
                <div class="flex-2/10 align-middle px-1 md:px-4 text-secondary uppercase">Subscription</div>
                <div class="flex-7/10 pl-2 text-left text-xs md:text-base whitespace-pre">
                  <div class="flex items-center gap-4">
                    <div *hasPerm="[permission.Tenant_ViewLicenseAndBilling]" class="flex items-center justify-center gap-4">
                      <ng-container>
                        <div class="p-1 rounded bg-blue-400">
                          <div
                            class="flex flex-col items-center justify-around h-14 border-2 bg-opacity-50 p-1 px-4 rounded border-blue-400 bg-blue-300"
                          >
                            <div class="uppercase leading-4">claimed</div>
                            <div class="text-2xs">{{ data.tenant.license.used }}</div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="subscription$ | async as rvSubscription">
                        <div class="grid grid-cols-2 gap-x-4">
                          <div class="col-span-2 text-secondary">{{ rvSubscription.value['name'] }}</div>
                          <div class="text-secondary" [style.font-variant]="'small-caps'">
                            <ng-container
                              *ngIf="
                                rvLicense.value.status === licenseStatus.Active || rvLicense.value.status === licenseStatus.InTrial;
                                else expired
                              "
                              >Next Billing:</ng-container
                            >
                            <ng-template #expired>Expires:</ng-template>
                          </div>
                          <div>{{ contractTerm(rvSubscription.value) | dateMethod: 'toLocaleDateString' }}</div>
                          <div class="text-secondary" [style.font-variant]="'small-caps'">Limit:</div>
                          <div>{{ rvSubscription.value.quantity }} Machines</div>
                          <div *ngIf="rvSubscription.value.mustContactSales" class="col-span-2 italic text-center text-sm text-secondary"
                            >Contact sales for any changes</div
                          >
                        </div>
                      </ng-container>
                    </div>
                    <div class="flex-1 flex items-center gap-4">
                      <ng-container *ngIf="rvLicense.value.expired; else active">
                        <div class="p-1 rounded bg-red-400 dark:text-red-300">
                          <div
                            class="flex flex-col items-center justify-around h-10 border-2 bg-opacity-50 p-1 px-4 rounded border-red-400 bg-red-300 text-red-700"
                          >
                            <div class="uppercase leading-4">expired</div>
                          </div>
                        </div>
                        <div *ngIf="data.canUpdateLicense; else readonly" class="flex-1 flex items-center justify-between">
                          <ng-container *ngIf="paymentSource$ | async as rvPaymentSource">
                            <button
                              *ngIf="rvPaymentSource.value; else update"
                              mat-button
                              color="primary"
                              (click)="$event.stopPropagation(); reactivateSubscription()"
                            >
                              reactivate
                            </button>
                            <ng-template #update>
                              <button mat-button color="primary" (click)="$event.stopPropagation(); updateSubscription()">update</button>
                            </ng-template>
                          </ng-container>
                        </div>
                        <ng-template #readonly>
                          <div class="text-secondary text-center text-sm"> Please contact the owner at {{ data.tenant.ownerEmail }} </div>
                        </ng-template>
                      </ng-container>
                      <ng-template #active>
                        <ng-container [ngSwitch]="rvLicense.value.status">
                          <ng-container *ngSwitchCase="licenseStatus.Active">
                            <div class="p-1 rounded bg-green-400 dark:text-green-300">
                              <div
                                class="flex flex-col items-center justify-around h-10 border-2 bg-opacity-50 p-1 px-4 rounded border-green-400 bg-green-300 text-green-700"
                              >
                                <div class="uppercase leading-4">{{ licenseStatus.Active }}</div>
                              </div>
                            </div>
                            <div *ngIf="data.canUpdateLicense" class="flex-1 flex items-center justify-between">
                              <button mat-button color="primary">update</button>
                              <button
                                *ngIf="!rvLicense.value.expired && rvLicense.value.status === licenseStatus.Active"
                                mat-button
                                color="warn"
                                (click)="$event.stopPropagation(); cancelSubscription()"
                              >
                                cancel
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="licenseStatus.NonRewing">
                            <div class="p-1 rounded bg-orange-400 dark:text-orange-300">
                              <div
                                class="flex flex-col items-center justify-around h-14 border-2 bg-opacity-50 p-1 px-4 rounded border-orange-400 bg-orange-300 text-orange-700"
                              >
                                <div class="uppercase leading-4">{{ licenseStatus.NonRewing }}</div>
                                <div class="text-2xs">{{ daysLeft | callOnce: rvLicense.value.expiration }}</div>
                              </div>
                            </div>
                            <div *ngIf="data.canUpdateLicense" class="flex-1 flex items-center justify-between">
                              <button mat-button color="primary" (click)="$event.stopPropagation(); reactivateSubscription()">
                                reactivate
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="licenseStatus.InTrial">
                            <div class="p-1 rounded bg-yellow-400 dark:text-yellow-300">
                              <div
                                class="flex flex-col items-center justify-around h-14 border-2 bg-opacity-50 px-4 rounded border-yellow-400 bg-yellow-300 text-yellow-700"
                              >
                                <div class="uppercase leading-4">{{ licenseStatus.InTrial }}</div>
                                <div class="text-2xs">{{ daysLeft | callOnce: rvLicense.value.trialEnd }}</div>
                              </div>
                            </div>
                            <div *ngIf="data.canUpdateLicense" class="flex-1 flex items-center justify-between">
                              <button mat-button color="primary">update</button>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="licenseStatus.Cancelled">
                            <div class="p-1 rounded bg-orange-400 dark:text-orange-300">
                              <div
                                class="flex flex-col items-center justify-evenly h-10 border-2 bg-opacity-50 p-1 px-4 rounded border-orange-400 bg-orange-300 text-orange-700"
                              >
                                <div class="uppercase leading-4">{{ licenseStatus.Cancelled }}</div>
                              </div>
                            </div>
                            <div *ngIf="data.canUpdateLicense" class="flex-1 flex items-center justify-between">
                              <button mat-button color="primary" (click)="$event.stopPropagation(); reactivateSubscription()"
                                >Reactivate</button
                              >
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="pl-2 text-right">
                  <fa-icon [ngClass]="data.canUpdateLicense ? '' : 'invisible'" [icon]="['fad', 'angle-right']" size="lg" class="text-gray">
                  </fa-icon>
                </div>
              </a>
            </div>
            <ng-container *devHide="['beta']">
              <div @fadeInRight *hasPerm="[permission.Tenant_ViewLicenseAndBilling]" class="field-link">
                <ng-container *ngIf="paymentSource$ | async as rvPaymentSource">
                  <a
                    class="p-1 pr-2 md:p-3 md:pr-6 flex justify-start items-center content-center"
                    [ngClass]="data.canUpdateLicense ? 'cursor-pointer' : 'readonly'"
                    (click)="data.canUpdateLicense ? updatePaymentSource(rvPaymentSource.value) : null"
                  >
                    <div class="flex-2/10 align-middle px-1 md:px-4 text-secondary uppercase">Payment Info</div>
                    <div class="flex-7/10 pl-2 text-left text-xs md:text-base whitespace-pre">
                      <ng-container *ngIf="rvPaymentSource.value as ps; else addPayment">
                        <div class="flex items-start justify-start gap-4">
                          <div class="flex flex-col gap-4">
                            <mon-payment-source-view [payment-source]="ps"></mon-payment-source-view>
                            <div *ngIf="ps.status === 'pending_verification'"
                              ><a
                                (click)="verifyBankAccount($event, ps.id)"
                                class="text-sm text-red"
                                matTooltip="You must verify this account before it can be used."
                                >Verify Payment Source</a
                              ></div
                            >
                          </div>
                          <button mat-button color="primary" class="mt-8">update payment</button>
                        </div>
                      </ng-container>
                      <ng-template #addPayment>
                        <div class="flex items-center justify-start gap-4">
                          <div class="text-warn italic text-sm">No Payment Source Provided</div>
                          <button mat-button color="primary">add payment</button></div
                        >
                      </ng-template>
                      <div *hasPerm="[permission.Contact_List]" class="flex flex-col gap-2 pt-4">
                        <div class="text-secondary italic text-sm">Additional Billing And Account Contacts</div>
                        <div class="flex gap-4">
                          <ng-container *ngIf="contacts$ | async">
                            <table @fadeInRight *ngIf="contacts.length > 0" class="card bg-slate-100 dark:bg-slate-700 w-96">
                              <tr
                                *ngFor="let contact of contacts; trackBy: trackByID"
                                class="hover:bg-hover"
                                (click)="data.user.hasPerms([permission.Contact_Read]) ? editContact($event, contact) : undefined"
                              >
                                <td class="px-2">
                                  <div class="flex flex-col">
                                    <div class="text-xs">{{ getContactFullName | callOnce: contact }}</div>
                                    <div class="text-2xs text-secondary">{{ contact.email }}</div>
                                  </div>
                                </td>
                                <td class="px-2">
                                  <div>{{ contact.displaySend }}</div>
                                </td>
                                <td class="flex justify-end px-2">
                                  <div *hasPerm="[permission.Contact_Delete]">
                                    <button
                                      class="ml-4 leading-none flex-none"
                                      mat-icon-button
                                      matTooltip="Remove Block"
                                      type="button"
                                      (click)="removeContact($event, contact)"
                                    >
                                      <fa-icon [icon]="['fad', 'times']" size="lg" class="text-red"> </fa-icon>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </ng-container>
                          <div>
                            <button
                              *hasPerm="[permission.Contact_Create]"
                              mat-button
                              color="primary"
                              (click)="editContact($event, undefined)"
                              >add contact</button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pl-2 text-right">
                      <fa-icon
                        [ngClass]="data.canUpdateLicense ? '' : 'invisible'"
                        [icon]="['fad', 'angle-right']"
                        size="lg"
                        class="text-gray"
                      >
                      </fa-icon>
                    </div>
                  </a>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *hasPerm="[permission.Tenant_Read]">
              <ng-container *ngIf="(billingAddress$ | async) || ' ' as billingAddress">
                <div @fadeInRight class="field-link">
                  <a
                    class="p-1 pr-2 md:p-3 md:pr-6 flex justify-start items-center content-center"
                    [class.readonly]="!data.user.hasPerms([permission.Tenant_Update])"
                    [class.bg-magenta-50]="!billingAddress"
                    (click)="
                      !data.user.hasPerms([permission.Tenant_Update]) ? undefined : editField(data.tenant, tenantEditView.BillingAddress)
                    "
                  >
                    <div class="flex-2/10 align-middle px-1 md:px-4 text-secondary uppercase">Addresses</div>
                    <div class="flex-7/10 pl-2 text-left text-xs md:text-base whitespace-pre">
                      <div class="grid grid-cols-2 gap-2">
                        <div>
                          <div class="text-secondary italic text-sm">Billing</div>
                          <div [ngClass]="billingAddress === ' ' ? 'italic' : ''">{{
                            billingAddress === ' ' ? 'No Billing Address' : billingAddress
                          }}</div>
                        </div>
                        <div
                          *ngIf="shippingAddress$ | async as shippingAddress"
                          matTooltip="Please call support to update Shipping Address"
                        >
                          <div class="text-secondary italic text-sm"
                            >Shipping - <span class="text-red-300 text-xxs italic">readonly</span></div
                          >
                          <div>{{ shippingAddress || ' ' }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="pl-2 text-right">
                      <fa-icon
                        [ngClass]="!data.user.hasPerms([permission.Tenant_Update]) ? 'invisible' : ''"
                        [icon]="['fad', 'angle-right']"
                        size="lg"
                        class="text-gray"
                      >
                      </fa-icon>
                    </div>
                  </a>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let field of exposedFields; trackBy: trackByValue">
              <ng-container *hasPerm="field.requiredPerms">
                <div @fadeInRight class="field-link">
                  <a
                    class="p-1 pr-2 md:p-3 md:pr-6 flex justify-start items-center content-center"
                    [class.readonly]="field.readonly || !data.user.hasPerms([permission.Tenant_Update])"
                    [class.bg-magenta-50]="!field.value"
                    (click)="
                      field.readonly || !data.user.hasPerms([permission.Tenant_Update]) ? undefined : editField(data.tenant, field.view)
                    "
                  >
                    <div class="flex-2/10 align-middle px-1 md:px-4 text-secondary uppercase">{{ field.label }}</div>
                    <div class="flex-7/10 pl-2 text-left text-xs md:text-base whitespace-pre">{{ field.value || ' ' }}</div>
                    <div class="pl-2 text-right">
                      <fa-icon
                        [ngClass]="field.readonly || !data.user.hasPerms([permission.Tenant_Update]) ? 'invisible' : ''"
                        [icon]="['fad', 'angle-right']"
                        size="lg"
                        class="text-gray"
                      >
                      </fa-icon>
                    </div>
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <ng-template #loading>
        <div @listStagger class="tenant-table">
          <ng-container *ngFor="let t of [1, 2, 3]">
            <ng-container *ngIf="true">
              <div @fadeInRight class="field-link">
                <a class="p-1 pr-2 md:p-3 md:pr-6 flex justify-start items-center content-center readonly">
                  <div class="flex-2/10 align-middle px-1 md:px-4 text-secondary uppercase">
                    <div class="w-2/6 h-6 bg-gray-400 rounded animate-pulse"></div>
                  </div>
                  <div class="flex-7/10 pl-2 text-left text-xs md:text-base whitespace-pre">
                    <div class="w-2/6 h-6 bg-gray rounded animate-pulse"></div>
                  </div>
                  <div class="pl-2 text-right"></div>
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</div>
