import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MarkdownModule } from 'ngx-markdown';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { SharedModule } from '../shared.module';
import { BottomDrawerComponent } from './bottom-drawer/bottom-drawer.component';
import { ConstructionComponent } from './construction/construction.component';
import { RemoteSessionComponent } from './dataview/remote-session/remote-session.component';
import { EnvironmentVariablesDisplayComponent } from './dialogs/environment-variables-display/environment-variables-display.component';
import { ResultOverlayComponent } from './dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from './dialogs/spinner/spinner.component';
import { DisplayConfigButtonComponent } from './display-config-button/display-config-button.component';
import { GraphBarComponent } from './graph-bar/graph-bar.component';
import { LoaderComponent } from './loader/loader.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { SearchResultComponent } from './search-result/search-result.component';

@NgModule({
  declarations: [
    BottomDrawerComponent,
    ConstructionComponent,
    DisplayConfigButtonComponent,
    EnvironmentVariablesDisplayComponent,
    LoaderComponent,
    PermissionsComponent,
    RemoteSessionComponent,
    SearchResultComponent
  ],
  imports: [
    ResultOverlayComponent,
    SpinnerComponent,
    GraphBarComponent,

    CommonModule,
    FontAwesomeModule,
    MarkdownModule.forChild(),
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    SecondaryToolbarModule,
    SharedModule
  ],
  exports: [
    BottomDrawerComponent,
    ConstructionComponent,
    DisplayConfigButtonComponent,
    LoaderComponent,
    PermissionsComponent,
    ResultOverlayComponent,
    SearchResultComponent,
    SpinnerComponent,
    GraphBarComponent
  ]
})
export class ComponentsModule {}
