export const lightTheme: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [
      {
        saturation: 32
      },
      {
        lightness: -3
      },
      {
        visibility: 'on'
      },
      {
        weight: 1.18
      }
    ]
  }
];
