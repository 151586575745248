import { Directive, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Directive({
  selector: '[redirect]'
})
export class RedirectDirective implements OnInit {
  @Input('redirect') redirectTo: unknown[];
  @Input('redirectExtras') extras: NavigationExtras = {};
  @Input('extras') set setExtras(extras: NavigationExtras) {
    this.extras = extras;
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(this.redirectTo, this.extras);
  }
}
