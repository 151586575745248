import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { AgentService } from 'src/app/core/services/agent.service';
import { SearchService } from 'src/app/core/services/search.service';
import { StateService } from 'src/app/core/services/state.service';
import { propertyByString } from 'src/app/core/utils/property-by-string';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { Agent } from 'src/app/shared/models/agent.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { isEmpty } from 'src/app/utils/condition';

import { MachinesDataSource } from './machines.datasource';
import {
  RemoveLicenseComponent,
  RemoveLicenseParams
} from './remove-license/remove-license.component';

@UntilDestroy()
@Component({
  selector: 'mon-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class MachinesComponent {
  readonly DEFAULT_PAGE_SIZE = 20;
  readonly DEFAULT_SORT_FIELD = 'system.hostname';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.INVENTORY_MACHINES;
  filter = '';
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize = this.DEFAULT_PAGE_SIZE;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource: MachinesDataSource = new MachinesDataSource(this.searchSvc, this.agentSvc);

  selection = new SelectionModel<Agent>(true, []);
  searchCtrl = new UntypedFormControl();

  columns: TableColumn<Agent>[] = [
    {
      label: 'Checkbox',
      property: 'checkbox',
      type: 'checkbox',
      visible: true,
      hideable: false
    },
    {
      label: 'Licensed',
      property: 'licensed',
      type: 'icon',
      visible: true,
      hideable: true,
      iconName: 'certificate',
      tooltip: 'Licensed'
    },
    {
      label: 'ID',
      property: 'id',
      type: 'text',
      visible: false,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Name',
      property: 'system.hostname',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Domain',
      property: 'system.domain',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Agent Version',
      property: 'version',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['truncate']
    },
    {
      label: 'Last Seen',
      property: 'lastUpdate',
      type: 'date',
      dateFormat: 'timeAgo',
      visible: true,
      hideable: true
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: false,
      buttonIconName: 'angle-right',
      buttonFn: (agent: Agent): void => this.setAgentTo(agent),
      tooltip: 'View'
    }
  ];

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        pageSize: found?.pageSize || this.DEFAULT_PAGE_SIZE,
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.pageSize = isEmpty(settings.pageSize)
        ? this.DEFAULT_PAGE_SIZE
        : settings.pageSize;
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    }),
    tap(() => this.loadMachines())
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  controlsPopulated = (): boolean =>
    !!this.paginator && !!this.sort && !!this.filterInput;
  paginator: MatPaginator;
  @ViewChild(MatPaginator) set paginatorSetter(paginator: MatPaginator) {
    if (!this.paginator && !!paginator) {
      this.paginator = paginator;
      this.initControls();
    }
  }
  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }
  filterInput: ElementRef;
  @ViewChild('filterInput') set filterInputSetter(e: ElementRef) {
    if (!this.filterInput) {
      this.filterInput = e;
      this.initControls();
    }
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private stateSvc: StateService,
    private searchSvc: SearchService,
    private agentSvc: AgentService
  ) {
    this.searchCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        filter(() => this.filter !== this.filterInput.nativeElement.value),
        tap(() => {
          this.filter = this.filterInput.nativeElement.value;
          this.paginator.pageIndex = 0;
          this.loadMachines();
        })
      )
      .subscribe();
  }

  initControls(): void {
    if (this.controlsPopulated()) {
      this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.tableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();

      this.paginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            this.pageIndex = page.pageIndex;
            if (this.pageSize !== page.pageSize) {
              const table = {};
              table[this.tableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            } else {
              this.loadMachines();
            }
          })
        )
        .subscribe();
    }
  }

  loadMachines(): void {
    this.selection.clear();
    this.dataSource.loadMachines(this.filter, this.pageSize, this.pageIndex, [
      OrderBy.SortToOrderBy({
        active: this.sortField,
        direction: this.sortDirection
      })
    ]);
  }

  get hideableColumns(): TableColumn<Agent>[] {
    return this.columns.filter((c) => c.hideable);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  getValue(row: Agent, property: string): string {
    return propertyByString(row, property);
  }

  masterToggle(change: MatCheckboxChange): void {
    if (change.checked) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  setAgentTo(agent: Agent): void {
    this.stateSvc.setSearch(agent.id);
    this.router.navigate(['home', 'dashboard'], {
      queryParams: { machineID: agent.id },
      queryParamsHandling: 'merge'
    });
  }

  removeLicenses(agents: Agent[]): void {
    this.dialog.open(RemoveLicenseComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new RemoveLicenseParams({
        agents,
        onSuccess: () => this.loadMachines()
      }),
      width: '600px'
    });
  }
}
