import { Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  EMPTY,
  Subscription,
  combineLatest,
  iif,
  of,
  timer
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeAll,
  mergeMap,
  repeat,
  switchMap,
  tap,
  toArray
} from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger60ms } from 'src/@vex/animations/stagger.animation';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { AgentService, MetricType } from 'src/app/core/services/agent.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ProcessService } from 'src/app/core/services/process.service';
import { RemoterPollerService } from 'src/app/core/services/remoter-poller.service';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { fadeIn400ms } from 'src/app/shared/animations/fade-in.animation';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { Agent } from 'src/app/shared/models/agent.model';
import { Process } from 'src/app/shared/models/process.model';
import { Remotee, Session } from 'src/app/shared/models/session.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { deepCopy } from 'src/app/utils/clone';

import { OS } from 'src/app/shared/models/system.model';
import { batteryGraphProps$, updateBattery } from './dashboard.battery';
import { GoogleMapLocation } from './dashboard.classes';
import {
  getCPUColumns,
  getDiskColumns,
  getMemColumns,
  getNetColumns
} from './dashboard.columns';
import { cpuGraphProps$, updateCPU } from './dashboard.cpu';
import { diskGraphProps$, updateDisks } from './dashboard.disk';
import { dpGraphProps$, updateDisplayProtocols } from './dashboard.display-protocol';
import { ethGraphProps$, updateEthernet } from './dashboard.ethernet';
import { gpuGraphProps$, updateGPU } from './dashboard.gpu';
import { memGraphProps$, updateMemory } from './dashboard.memory';
import { updateSystem } from './dashboard.system';
import { updateWifi, wifiGraphProps$ } from './dashboard.wifi';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';

@UntilDestroy()
@Component({
  selector: 'mon-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [stagger60ms, fadeInUp400ms, fadeIn400ms]
})
export class DashboardComponent implements OnDestroy {
  math = Math;
  permission = Permission;
  OS = OS;
  objectKeys = Object.keys;

  machineStatsConfig$ = this.configSvc.getMachineStatsDisplayConfig();
  geoLocationConfig$ = this.configSvc.getGeoLocationDisplayConfig();
  currentUsersConfig$ = this.configSvc.getCurrentUsersDisplayConfig();

  topCpuConfig$ = this.configSvc.getTopCPUDisplayConfig();
  topMemConfig$ = this.configSvc.getTopMemDisplayConfig();
  topDiskConfig$ = this.configSvc.getTopDiskDisplayConfig();
  topNetConfig$ = this.configSvc.getTopNetDisplayConfig();

  gpuGraphConfig$ = this.configSvc.getGPUGraphConfig(gpuGraphProps$);
  cpuGraphConfig$ = this.configSvc.getCPUGraphConfig(cpuGraphProps$);
  memGraphConfig$ = this.configSvc.getMemGraphConfig(memGraphProps$);
  diskGraphConfig$ = this.configSvc.getDiskGraphConfig(diskGraphProps$);
  ethGraphConfig$ = this.configSvc.getEthGraphConfig(ethGraphProps$);
  wifiGraphConfig$ = this.configSvc.getWifiGraphConfig(wifiGraphProps$);
  dpGraphConfig$ = this.configSvc.getDisplayProtocolGraphConfig(dpGraphProps$);
  batteryGraphConfig$ = this.configSvc.getBatteryGraphConfig(batteryGraphProps$);

  private _remotees = new BehaviorSubject<Remotee[]>([]);
  remotees$ = this._remotees.asObservable();

  keepAlive$ = this.remotees$.pipe(
    untilDestroyed(this),
    filter((remotees) => !!remotees && remotees.length > 0),
    mergeAll(),
    mergeMap((s) => {
      return timer(0, 30000).pipe(
        mergeMap(() =>
          this.agentSvc.requestAgentToGoLive(s.agentID, MetricType.SessionMetrics)
        )
      );
    })
  );

  collectMetrics$ = combineLatest([this.stateSvc.selectedAgent$, this.remotees$]).pipe(
    filter(([_, remotees]) => !!remotees && remotees.length > 0),
    mergeMap(([selectedAgent, remotees]) => {
      const agentID = selectedAgent.agentID;
      const agentMap = new Map<string, Agent>();
      remotees.map((r) => agentMap.set(r.agentID, undefined));

      return of(remotees).pipe(
        mergeAll(),
        mergeMap((r) => {
          let newerThan = new Date();
          const agent = agentMap.get(r.agentID);
          if (agent) {
            newerThan = new Date(agent.lastLiveUpdate.getTime());
            newerThan.setSeconds(newerThan.getSeconds() + 1);
          }

          return this.agentSvc.getLiveAgent(r.agentID, true, newerThan);
        }),
        tap((agent) => (agent ? agentMap.set(agent.id, agent) : null)),
        toArray(),
        tap((agents) => {
          let sessions: Session[] = [];
          sessions = agents
            .filter((a) => !!a)
            .reduce((s, a) => {
              const agentSessions =
                a.stats.summary.system.sessions.filter(
                  (s) => s.clientname.toLocaleLowerCase() === agentID
                ) || [];
              agentSessions.forEach((s) => s.tags.set('connectedFrom', a.id));
              s.push(...agentSessions);
              return s;
            }, sessions);

          let triggers: Trigger[] = [];
          triggers = agents
            .filter((a) => !!a)
            .reduce((t, a) => {
              t.push(...a.getTriggered());
              return t;
            }, triggers);

          updateDisplayProtocols(
            this.dpToGraphs,
            this.dpToGraphOps,
            sessions,
            triggers,
            'deep-orange'
          );
        }),
        repeat({
          delay: () =>
            // if remotees are still the same set, we should continue polling new metrics, otherwise stop
            this.remotees$.pipe(switchMap((r) => (r === remotees ? of(true) : EMPTY)))
        })
      );
    }),
    untilDestroyed(this)
  );

  sessionsTo$ = combineLatest([
    this.stateSvc.stopPolling$,
    this.remoterPollerSvc.outgoingSessions$
  ]).pipe(
    tap(([stopPolling, remotees]) => {
      this.displayProtocolSubs.forEach((s) => s.unsubscribe());

      this._remotees.next(remotees);

      if (!stopPolling) {
        this.displayProtocolSubs.push(this.keepAlive$.subscribe());
        this.displayProtocolSubs.push(this.collectMetrics$.subscribe());
      }
    })
  );

  private _initialContactMade = new BehaviorSubject<boolean>(false);
  initialContactMade$ = this._initialContactMade
    .asObservable()
    .pipe(distinctUntilChanged());

  agentConnectionState$ = this.agentLivePollerSvc.agentConnectionState$.pipe(
    tap((d) => {
      d === 'connected' && this._initialContactMade.next(true);
    })
  );

  user$ = this.stateSvc.currentUser$;

  live$ = this.stateSvc.liveMode$;

  agent$ = this.live$.pipe(
    switchMap((liveMode) => {
      this.clearGraphs();

      return iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(
          tap(() => this._initialContactMade.next(true)),
          map((agent) => deepCopy(agent))
        )
      );
    }),
    tap((agent) => (this.currentAgentID = agent?.id)),
    filter((agent) => !!agent),
    distinctUntilChanged((prev, curr) => {
      if (!!prev && !!curr) {
        return Math.abs(prev.timestamp.getTime() - curr.timestamp.getTime()) < 3;
      }

      return false;
    }),
    filter((agent) => agent.stats.collectionTimestamp.getTime() > 0),
    tap((agent) => (this.os = agent.system.os)),
    tap((agent) => {
      this.updateLiveTables(agent);
      this.updateLiveGraphs(agent);
    })
  );

  displayProtocolSubs: Subscription[] = [];
  sessionFilter: number[] = [];
  count = 20;
  currentAgentID: string;
  os: OS;
  cpuGraphOps: GraphOptions = {
    title: 'CPU',
    label: '',
    icon: 'microchip',
    iconColor: 'blue',
    maxY: [100],
    seriesColors: [['blue']],
    seriesLineStyle: [['solid']],
    initialSeries: [
      [
        {
          name: 'Utilization',
          data: new Array(this.count).fill(0)
        }
      ]
    ],
    focusProperties: [],
    properties: {}
  };
  memoryGraphOps: GraphOptions = {
    title: 'Memory',
    label: '',
    icon: 'memory',
    iconColor: 'purple',
    maxY: [],
    seriesColors: [['purple']],
    seriesLineStyle: [['solid']],
    initialSeries: [
      [
        {
          name: 'In Use',
          data: new Array(this.count).fill(0)
        }
      ]
    ],
    focusProperties: []
  };
  diskGraphOps: GraphOptions[] = [];
  batteryGraphOps: GraphOptions[] = [];
  dpFromGraphOps: GraphOptions[] = [];
  dpToGraphOps: GraphOptions[] = [];
  ethernetGraphOps: GraphOptions[] = [];
  wifiGraphOps: GraphOptions[] = [];
  gpuGraphOps: GraphOptions[] = [];
  ipLocation: GoogleMapLocation = {
    properties: new Map<string, string>()
  };

  cpuColumns = getCPUColumns(this.killProcess.bind(this));
  memoryColumns = getMemColumns(this.killProcess.bind(this));
  diskColumns = getDiskColumns(this.killProcess.bind(this));
  netColumns = getNetColumns(this.killProcess.bind(this));

  topCPUUsage: Array<Process> = new Array<Process>();
  topMemoryUsage: Array<Process> = new Array<Process>();
  topDiskUsage: Array<Process> = new Array<Process>();
  topNetUsage: Array<Process> = new Array<Process>();
  getProcessID = (p: Process): string => `${p.processID}`;

  @ViewChild('cpuGraph') cpuGraph: LiveGraphComponent;
  @ViewChild('memoryGraph') memoryGraph: LiveGraphComponent;
  @ViewChildren('diskGraph') diskGraphs: QueryList<LiveGraphComponent>;
  @ViewChildren('ethernetGraph') ethernetGraphs: QueryList<LiveGraphComponent>;
  @ViewChildren('wifiGraph') wifiGraphs: QueryList<LiveGraphComponent>;
  @ViewChildren('gpuGraph') gpuGraphs: QueryList<LiveGraphComponent>;
  @ViewChildren('batteryGraph') batteryGraphs: QueryList<LiveGraphComponent>;
  @ViewChildren('dpFromGraph') dpFromGraphs: QueryList<LiveGraphComponent>;
  @ViewChildren('dpToGraph') dpToGraphs: QueryList<LiveGraphComponent>;

  constructor(
    private dialog: MatDialog,
    private stateSvc: StateService,
    private configSvc: ConfigService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private remoterPollerSvc: RemoterPollerService,
    private agentSvc: AgentService,
    private processSvc: ProcessService,
    private tenantSvc: TenantService
  ) {}

  ngOnDestroy(): void {
    this.displayProtocolSubs.forEach((s) => s.unsubscribe());
  }

  clearGraphs(): void {
    this.cpuGraph?.clearData();
    this.memoryGraph?.clearData();
    this.diskGraphs?.forEach((g) => g.clearData());
    this.ethernetGraphs?.forEach((g) => g.clearData());
    this.wifiGraphs?.forEach((g) => g.clearData());
    this.gpuGraphs?.forEach((g) => g.clearData());
    this.batteryGraphs?.forEach((g) => g.clearData());
    this.dpFromGraphs?.forEach((g) => g.clearData());
    this.dpToGraphs?.forEach((g) => g.clearData());
  }

  updateLiveGraphs(agent: Agent): void {
    const system = agent.stats.summary.system;
    const cpu = agent.system.cpu ? agent.stats.summary.cpu : undefined;
    const memory = agent.system.memory ? agent.stats.summary.memory : undefined;
    const disk = agent.stats.summary.disk;
    const network = agent.stats.summary.network;
    const gpu = agent.system.gpu ? agent.stats.summary.gpu : undefined;
    const batteries = agent.stats.summary.system.batteries;
    const sessions = agent.stats.summary.system.sessions;
    const triggered = agent.getTriggered();

    if (cpu) {
      updateCPU(
        this.cpuGraph,
        this.dialog,
        cpu,
        this.cpuGraphOps,
        agent.system.cpu,
        triggered
      );
    }
    if (memory) {
      updateMemory(
        this.memoryGraph,
        this.memoryGraphOps,
        memory,
        agent.system.memory.total,
        triggered
      );
    }
    if (disk) {
      updateDisks(
        this.currentAgentID,
        this.dialog,
        this.diskGraphs,
        this.diskGraphOps,
        disk,
        triggered
      );
    }
    if (system) {
      updateSystem(this.ipLocation, system);
    }
    if (network) {
      updateEthernet(
        this.ethernetGraphs,
        this.ethernetGraphOps,
        network.ethernetAdapters,
        triggered
      );
      updateWifi(this.wifiGraphs, this.wifiGraphOps, network.wifiAdapters, triggered);
    }
    if (gpu) {
      updateGPU(this.gpuGraphs, this.gpuGraphOps, gpu, agent.system.gpu, triggered);
    }
    if (batteries) {
      updateBattery(this.batteryGraphs, this.batteryGraphOps, batteries, triggered);
    }
    if (sessions) {
      let filtered = sessions;

      if (sessions.length > 1) {
        filtered = filtered.filter((s) => this.sessionFilter.indexOf(s.id) >= 0);
      }

      updateDisplayProtocols(this.dpFromGraphs, this.dpFromGraphOps, filtered, triggered);
    }
  }

  updateLiveTables(agent: Agent): void {
    if (agent.stats.processes.size < 1) {
      return;
    }

    const trigger = agent.hasTrigger('Processes.CPUPercent');

    let filteredList = Array.from(agent.stats.processes.values());

    if (this.sessionFilter.length > 0) {
      filteredList = filteredList.filter(
        (p) => this.sessionFilter.indexOf(p.sessionID) >= 0
      );
    }

    this.topCPUUsage = filteredList
      .sort((a, b) => b.cpuPercent - a.cpuPercent)
      .slice(0, 6)
      .filter((p) => p.processID !== 0)
      .map((p) => {
        p['highlight'] = false;
        if (trigger) {
          const triggered = trigger.find((t) =>
            t.conditions.find((c) => c.isMet(p.cpuPercent))
          );
          p['highlight'] = !!triggered;
        }
        return p;
      })
      .slice(0, 5);

    this.topMemoryUsage = filteredList
      .sort((a, b) => b.memorySize - a.memorySize)
      .slice(0, 6)
      .filter((p) => p.processID !== 0)
      .slice(0, 5);

    this.topDiskUsage = filteredList
      .filter((p) => p.disk > 0)
      .sort((a, b) => b.disk - a.disk)
      .slice(0, 6)
      .filter((p) => p.processID !== 0)
      .slice(0, 5);

    this.topNetUsage = filteredList
      .filter((p) => p.network > 0)
      .sort((a, b) => b.network - a.network)
      .slice(0, 6)
      .filter((p) => p.processID !== 0)
      .slice(0, 5);
  }

  killProcess(process: Process): void {
    if (process.name !== 'ccagent.exe') {
      this.processSvc.killByPid(this.currentAgentID, process.processID).subscribe({
        error: (err) => {
          console.warn(err);
        }
      });

      return;
    }

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'microchip',
        title: 'Kill Process',
        confirmationText:
          'You will lose connection to this machine. Did you want to kill CommandCTRL?',
        onConfirm: () => {
          this.processSvc.killByPid(this.currentAgentID, process.processID).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }

  userSelectionChange(sessions: Session[]): void {
    const sessionIDs = sessions.map((s) => s.id);
    this.sessionFilter = sessionIDs;
  }
}
