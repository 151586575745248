import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[devHide]',
  standalone: true
})
export class DevHideDirective<C> implements OnInit {
  @Input('devHide') devHide: string[] | string;
  @Input('devHideElse') placeholder: TemplateRef<C> | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private template: TemplateRef<C>,
    private changes: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const devHide = this.devHide || '';

    if (devHide.length > 0 && DevHideDirective.devHide(devHide)) {
      if (this.placeholder) {
        this.viewContainerRef.createEmbeddedView(this.placeholder);
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.template);
    }

    this.changes.markForCheck();
  }

  static devHide(devHide: string[] | string): boolean {
    if (devHide instanceof Array) {
      return !!environment.devHide.find((ed) => devHide.find((d) => d === ed));
    }

    return !!environment.devHide.find((ed) => ed === devHide);
  }
}
