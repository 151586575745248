import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ScriptResultTarget } from 'src/app/shared/interfaces/script.interface';
import { TargetState, TargetStateDescription } from 'src/app/shared/models/task.model';

@Component({
  selector: 'mon-result-target-item',
  templateUrl: './result-target-item.component.html',
  styleUrls: ['./result-target-item.component.scss']
})
export class ResultTargetItemComponent {
  TargetState = TargetState;
  TargetStateDescription = TargetStateDescription;

  @Input() target: ScriptResultTarget;
  @Input() selected: boolean;

  @ViewChild('item', { static: true }) item: ElementRef;

  scrollTo() {
    this.item.nativeElement.parentElement.scrollIntoViewIfNeeded(true);
  }
}
