import { MemorySummary } from 'src/app/shared/models/memory-summary.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { friendlyDataSizeInBytes } from 'src/app/utils/data-size';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { BehaviorSubject } from 'rxjs';

const _memGraphProps = new BehaviorSubject<string[]>([]);
export const memGraphProps$ = _memGraphProps.asObservable();

export function updateMemory(
  memoryChart: LiveGraphComponent,
  memoryGraphOps: GraphOptions,
  summary: MemorySummary,
  systemMemoryTotal: number,
  triggers: Array<Trigger> = []
): void {
  const value = [Math.round((summary.inUse / 1024) * 10) / 10];

  memoryGraphOps.label = friendlyDataSizeInBytes(systemMemoryTotal * 1024 * 1024, 0);
  memoryGraphOps.maxY = [Math.round((systemMemoryTotal / 1024) * 10) / 10];
  value.forEach((v, i) => {
    memoryGraphOps.initialSeries[0][i].data.splice(0, 1);
    memoryGraphOps.initialSeries[0][i].data.push(v);
  });

  Object.assign(memoryGraphOps.focusProperties, [
    {
      label: 'In Use',
      value: friendlyDataSizeInBytes(summary.inUse * 1024 * 1024, 1),
      graphColor: 'purple',
      graphLineStyle: 'solid'
    },
    {
      label: 'Available',
      value: friendlyDataSizeInBytes(
        (systemMemoryTotal - summary.inUse) * 1024 * 1024,
        1
      ),
      highlight: !!triggers.find(
        (t) =>
          !!t.conditions.find(
            (c) =>
              c.field === 'Summary.Memory.UtilizationPercent' &&
              c.isMet(summary.utilizationPercent)
          )
      )
    }
  ]);

  if (_memGraphProps.value.length < 1) {
    _memGraphProps.next(
      [...Object.keys(memoryGraphOps.properties || [])].concat(
        memoryGraphOps.focusProperties.map((p) => p.label)
      )
    );
  }

  memoryChart && memoryChart.appendData(value);
}
