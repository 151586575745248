import { Component } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StateService } from 'src/app/core/services/state.service';
import { DisplayDensity } from 'src/app/shared/enums/table-density.enum';

import { LayoutService } from '../../services/layout.service';
import { Style } from '../../services/style.service';

export type ConfigPanelView = 'none' | 'dashboard';

@Component({
  selector: 'vex-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent {
  DisplayDensity = DisplayDensity;
  Style = Style;

  settings$ = this.stateSvc.currentUserSettings$;

  constructor(private layoutSvc: LayoutService, private stateSvc: StateService) {}

  setConfig(style: Style): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          style
        }
      }
    });
  }

  sidenavOpenChange(change: MatSlideToggleChange): void {
    change.checked ? this.layoutSvc.openSidenav() : this.layoutSvc.closeSidenav();
  }

  toolbarPositionChange(change: MatRadioChange): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          toolbar: {
            fixed: change.value === 'fixed'
          }
        }
      }
    });
  }

  displayDensityChange(change: MatSlideToggleChange): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          display: {
            density: change.checked ? DisplayDensity.Compact : DisplayDensity.Default
          }
        }
      }
    });
  }

  searchChange(change: MatSlideToggleChange): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          search: {
            goToDashboard: change.checked
          }
        }
      }
    });
  }

  footerVisibleChange(change: MatSlideToggleChange): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          footer: {
            visible: change.checked
          }
        }
      }
    });
  }

  footerPositionChange(change: MatRadioChange): void {
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        config: {
          footer: {
            fixed: change.value === 'fixed'
          }
        }
      }
    });
  }
}
