import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, iif, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { APIKeyService } from 'src/app/core/services/apikey.service';
import { FullStoryService } from 'src/app/core/services/full-story.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { fadeOutUp200ms } from 'src/app/shared/animations/fade-out-up.animation';
import { APIKey } from 'src/app/shared/models/apikey.model';
import { deepCopy } from 'src/app/utils/clone';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mon-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  animations: [fadeOutUp200ms, fadeInUp200ms]
})
export class DebugComponent {
  jwtToken$ = this.authSvc.getAccessTokenSilently();
  renderData$ = combineLatest([
    this.stateSvc.tenant$,
    this.apiKeySvc.getAll().pipe(catchError(() => of<APIKey[]>([])))
  ]).pipe(
    filter(([tenant, apiKeys]) => !!tenant && !!apiKeys),
    tap(([tenant, apiKeys]) => {
      const key = apiKeys.map((k) => k.apiKey).find(() => true) || 'ask-your-admin';

      this.winInstallCode =
        `($user = [Security.Principal.WindowsIdentity]::GetCurrent())| Out-Null;` +
        `($elevated = (New-Object Security.Principal.WindowsPrincipal $user).IsInRole([Security.Principal.WindowsBuiltinRole]::Administrator)) | Out-Null;` +
        `$(if ($elevated) { Write-Host "RUNNING..." } else { Write-Host "PLEASE RUN FROM AN ELEVATED PROMPT"; break; });` +
        `(mkdir $env:ProgramW6432\\Liquidware\\CommandCTRL 2>&1) | Out-Null;` +
        `(cd $env:ProgramW6432\\Liquidware\\CommandCTRL);` +
        `$progressPreference='silentlyContinue';` +
        `Invoke-WebRequest -Uri "https://storage.googleapis.com/${environment.projectID}.appspot.com/utils/tar/tar.exe" -OutFile "tar.exe";` +
        `Invoke-WebRequest -Uri "https://storage.googleapis.com/${environment.projectID}.appspot.com/utils/tar/archiveint.dll" -OutFile "archiveint.dll";` +
        `Invoke-WebRequest -Uri "https://storage.googleapis.com/${environment.projectID}.appspot.com/agent/updates/windows_amd64/ccagent.tar.gz" -OutFile "ccagent.tar.gz";` +
        `Invoke-WebRequest -Uri "https://storage.googleapis.com/${environment.projectID}.appspot.com/agent/updates/windows_amd64/ccupdater.tar.gz" -OutFile "ccupdater.tar.gz";` +
        `.\\tar xvf ccagent.tar.gz;` +
        `.\\tar xvf ccupdater.tar.gz;` +
        `Set-Content -Path ccagent.yaml -Value "tenantID: ${tenant.id}\`napiKey: ${key}";` +
        `.\\ccagent.exe -install-service -start-service -auto-update=10;` +
        `del *.tar.gz;` +
        `del tar.exe;` +
        `del archiveint.dll;`;

      this.winUpdateCode = `cd $env:ProgramW6432\\Liquidware\\CommandCTRL;.\\ccagent.exe -stop-service;Set-Content -Path ccagent.yaml -Value "tenantID: ${tenant.id}\`napiKey: ${key}";.\\ccagent.exe -start-service`;
    })
  );

  fullStoryLink = this.fullStorySvc.getCurrentSessionURL();
  splitJWTToken$ = this.jwtToken$.pipe(map((t) => t.split('.')));
  isPubSubWsConnected$ = this.pubSubSvc.IsConnectedSubject$;
  color: ThemePalette = 'primary';

  public winInstallCode = '';
  public winUpdateCode = '';
  public winUninstallCode =
    'cd $env:ProgramW6432\\Liquidware\\CommandCTRL;.\\ccagent.exe -uninstall-service';
  public logExample = '[INF] 2019/11/06 15:02:35 # agent-id: my-test0';

  constructor(
    private stateSvc: StateService,
    private clipboard: Clipboard,
    private authSvc: AuthService,
    private fullStorySvc: FullStoryService,
    private pubSubSvc: PubSubWsService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private apiKeySvc: APIKeyService
  ) {}

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.disabled = false;
    setTimeout(() => tooltip.show(), 1);
    setTimeout(() => (tooltip.disabled = true), 1000);
    this.clipboard.copy(text);
  }

  agent$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );
}
