import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckDouble, faChevronRight, faEye, faEyeSlash, faTelescope, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ActionsComponent } from './actions.component';
import { AddActionComponent } from './add-action/add-action.component';

@NgModule({
  declarations: [ActionsComponent, AddActionComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FontAwesomeModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class ActionsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCheckDouble,
      faChevronRight,
      faCog,
      faEye,
      faEyeSlash,
      faTelescope,
      faTimes
    );
  }
}
