<div @fadeOut *ngIf="open$ | async" class="result-overlay top-0 right-0 bottom-0 left-0 absolute flex items-center">
  <div class="absolute w-full bg-white top-0 bottom-0 z-10" [style.opacity]="opacity"></div>
  <div class="relative w-full z-20">
    <div *ngIf="state$ | async as state" class="flex justify-center">
      <fa-icon
        @fadeInUp
        @fadeOutUp
        *ngIf="state === OverlayResult.Error"
        class="text-red"
        [icon]="['fad', 'exclamation-square']"
        size="8x"
        [fixedWidth]="true"
        matPrefix
      ></fa-icon>
      <fa-icon
        *ngIf="state === OverlayResult.Success"
        class="text-green"
        [icon]="['fad', 'check-square']"
        size="8x"
        [fixedWidth]="true"
        matPrefix
      ></fa-icon>
    </div>
  </div>
</div>
