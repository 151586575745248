import { Updatable } from '../interfaces/updatable.interface';

export class AgentBlock implements Updatable<AgentBlock> {
  static MAX_BLOCK_TIME = new Date(16725225600000);
  public id = '';
  public agentID = '';
  public machineID = '';
  public ip = '';
  public blockUntil: Date;

  get blockUntilD(): string {
    if (this.isIndefinite()) {
      return 'Indefinitely';
    }
    return this.blockUntil.toLocaleString();
  }

  constructor(init?: Partial<AgentBlock>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.blockUntil = new Date(init.blockUntil);
  }

  update(input: Partial<AgentBlock>): this {
    return Object.assign(this, input);
  }

  isIndefinite(): boolean {
    return this.blockUntil.getTime() === AgentBlock.MAX_BLOCK_TIME.getTime();
  }
}
