import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateMethod',
  pure: true
})
export class DateMethodPipe implements PipeTransform {
  transform(value: Date, method?: string): string {
    if (typeof value[method] === 'function') {
      return value[method]();
    }
    return value[method];
  }
}
