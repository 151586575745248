import { QueryList } from '@angular/core';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { DiskSummary } from 'src/app/shared/models/disk-summary.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';
import { friendlyDataSizeInBytes } from 'src/app/utils/data-size';
import {
  DiskPartitionComponent,
  DiskPartitionParams
} from './widget/disk-partition/disk-partition.component';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

const _diskGraphProps = new BehaviorSubject<string[]>([]);
export const diskGraphProps$ = _diskGraphProps.asObservable();
const count = 20;

export function updateDisks(
  agentID: string,
  dialog: MatDialog,
  diskCharts: QueryList<LiveGraphComponent>,
  diskGraphOps: GraphOptions[],
  summary: DiskSummary,
  triggers: Array<Trigger> = []
): void {
  const now = new Date();
  const disks = summary.disks || [];

  disks
    .sort((d) => d.index)
    .forEach((d) => {
      const value = [Math.round(d.activeTime)];
      let ops: GraphOptions = diskGraphOps.find((dg) => dg.id === d.id);

      if (!ops) {
        ops = {
          id: d.id,
          title: `Disk ${d.index}${
            d.drives && d.drives.length > 0
              ? ` (${d.drives
                  .sort((d1, d2) => (d1 < d2 ? -1 : d1 > d2 ? 1 : 0))
                  .map((drive) => `${drive}:`)
                  .join(' ')})`
              : ''
          }`,
          label: [d.vendorID, d.productID].join(' '),
          icon: 'hdd',
          iconColor: 'green',
          maxY: [100],
          seriesColors: [['green']],
          seriesLineStyle: [['solid']],
          initialSeries: [
            [
              {
                name: 'Active Time',
                data: new Array(count).fill(0)
              }
            ]
          ],
          focusProperties: [],
          properties: {}
        };

        diskGraphOps.push(ops);
      } else {
        value.forEach((v, i) => {
          ops.initialSeries[0][i].data.splice(0, 1);
          ops.initialSeries[0][i].data.push(v);
        });
      }

      ops.timestamp = now;

      if (!ops.buttons) {
        ops.buttons = [
          {
            tooltip: `View Partition Layout`,
            icon: 'search-plus',
            onClick: () =>
              dialog.open(DiskPartitionComponent, {
                restoreFocus: false,
                data: new DiskPartitionParams({
                  graphOptions: ops,
                  agentID
                }),
                minHeight: 200,
                minWidth: 300,
                height: 'auto',
                width: 'auto'
              })
          }
        ];
      }

      Object.assign(ops.focusProperties, [
        {
          label: 'Active Time',
          value: `${value}%`,
          graphColor: 'green',
          graphLineStyle: 'solid'
        },
        {
          label: 'Avg Response Time',
          value: `${Math.round(d.latency * 10) / 10} ms`,
          highlight: !!triggers.find(
            (t) =>
              !!t.conditions.find(
                (c) => c.field === 'Summary.Disk.Disks.Latency' && c.isMet(d.latency)
              )
          )
        },
        {
          label: 'Read Speed',
          value: `${friendlyDataSizeInBytes(d.readSpeed, 1)}/s`
        },
        {
          label: 'Write Speed',
          value: `${friendlyDataSizeInBytes(d.writeSpeed, 1)}/s`
        }
      ]);

      Object.assign(ops.properties, {
        Capacity: friendlyDataSizeInBytes(d.capacity),
        Formatted: friendlyDataSizeInBytes(d.formatted),
        'System Disk': d.system ? 'Yes' : 'No',
        'Page File': d.pageFile ? 'Yes' : 'No',
        Type: d.type
      });

      if (_diskGraphProps.value.length < 1) {
        _diskGraphProps.next(
          [...Object.keys(ops.properties || [])].concat(
            ops.focusProperties.map((p) => p.label)
          )
        );
      }

      diskCharts &&
        diskCharts
          .filter((dc) => dc.id === d.id)
          .forEach((dc) => {
            dc.appendData(value);
          });
    });

  for (let i = diskGraphOps.length - 1; i >= 0; i--) {
    if (diskGraphOps[i].timestamp !== now) {
      diskGraphOps.splice(i, 1);
    }
  }
}
