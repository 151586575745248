import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'mon-no-agent-connection',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './no-agent-connection.component.html',
  styleUrls: ['./no-agent-connection.component.scss']
})
export class NoAgentConnectionComponent {}
