import { Updatable } from '../interfaces/updatable.interface';

export class User implements Updatable<User> {
  public id: string;
  public email: string;
  public roles: Map<string, unknown> = new Map<string, unknown>();
  public updated: Date;
  public added: Date;
  public lastLoginTimestamp: Date;

  get rolesD(): string {
    return this.getRolesAsArray().join(', ');
  }

  constructor(init?: Partial<User>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.updated = new Date(init.updated);
    this.added = new Date(init.added);

    const ll = new Date(init.lastLoginTimestamp || 0);

    this.lastLoginTimestamp =
      init.lastLoginTimestamp && (ll.getTime() <= 0 ? undefined : ll);

    const roles = new Map<string, unknown>();
    if (this.roles) {
      Object.keys(this.roles).map((k) => {
        roles.set(k, this.roles[k]);
      });
    }
    this.roles = roles;
  }

  update(input: Partial<User>): this {
    return Object.assign(this, input);
  }

  getRolesAsArray(): string[] {
    return this.roles
      ? Array.from(this.roles.keys()).sort((a, b) =>
          a === 'owner' ? -1 : a > b ? 1 : -1
        )
      : [];
  }
}
