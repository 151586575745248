import { firstValueInMap, lastFieldValueInMap } from 'src/app/utils/map';

export enum TaskType {
  Process = 0,
  Service = 1,
  System = 2
}

export enum TaskState {
  Queued = 0,
  Processing = 1,
  Completed = 2,
  Aborted = 3,
  Timeout = 4
}

export const TaskStateDescription = new Map<TaskState, [string, string]>([
  [TaskState.Queued, ['Queued', 'Queued']],
  [TaskState.Processing, ['Processing', 'Processing']],
  [TaskState.Completed, ['Completed', 'Completed']],
  [TaskState.Aborted, ['Aborted', 'Aborted']],
  [TaskState.Timeout, ['Timeout', 'Timeout']]
]);

export enum TargetState {
  TargetCreated = 0,
  TargetQueued = 1,
  TargetProcessing = 2,
  TargetSuccess = 3,
  TargetError = 4,
  TargetAborted = 5,
  TargetTimeout = 6
}

export const TargetStateDescription = new Map<TargetState, [string, string]>([
  [TargetState.TargetCreated, ['Created', 'Created']],
  [TargetState.TargetQueued, ['Queued', 'Queued']],
  [TargetState.TargetProcessing, ['Processing', 'Processing']],
  [TargetState.TargetSuccess, ['Success', 'Success']],
  [TargetState.TargetError, ['Error', 'Error']],
  [TargetState.TargetAborted, ['Aborted', 'Aborted']],
  [TargetState.TargetTimeout, ['Timeout', 'Timeout']]
]);

export enum ActionType {
  Custom = 0,
  Execute = 1,
  Process = 2,
  Service = 3,
  System = 4,
  Remote = 5,
  Diagnostic = 6,
  Shell = 7,
  Script = 8
}

export class Task {
  public timestamp: Date;
  public lastUpdate: Date;
  public id: string;
  public createdByUserEmail: string;
  public description: string;
  public targets: Map<string, Target>;
  public actions: Action[];
  public lastUpdateUnixNano: number;
  public state: TaskState;
  public targetTotal: number;
  public targetSuccess: number;
  public targetError: number;
  public targetAborted: number;
  public targetTimeout: number;

  // display names
  get stateD(): string {
    return TaskStateDescription.get(this.state)[0];
  }

  _targetD: string;
  get targetD(): string {
    if (!this._targetD) {
      const targets = [...this.targets.keys()];
      const output = targets.slice(0, Math.min(targets.length, 3)).join(', ');
      this._targetD =
        targets.length > 3 ? output + ` and ${targets.length - 3} more` : output;
    }
    return this._targetD;
  }

  _resultD: string;
  get resultD(): string {
    if (!this._resultD) {
      if (this.state > TaskState.Processing) {
        if (this.targets.size > 1) {
          const found = [...this.targets.values()].find(
            (t) => t.state > TargetState.TargetSuccess
          );
          if (found) {
            this._resultD = 'not all were successful';
            return this._resultD;
          }
          this._resultD = 'all successful';
          return this._resultD;
        }
        if (this.targets.size === 1) {
          const t = firstValueInMap(this.targets);
          if (t.logs) {
            const l = lastFieldValueInMap<string>(t.logs);
            this._resultD = l;
          } else if (t.state === TargetState.TargetAborted) {
            this._resultD = `task aborted on ${t.agentID}`;
          } else if (t.state === TargetState.TargetError) {
            this._resultD = `task errored on ${t.agentID}`;
          } else if (t.state === TargetState.TargetTimeout) {
            this._resultD = `task timeout on ${t.agentID}`;
          } else {
            this._resultD = '';
          }
        }
      }
    }

    return this._resultD;
  }

  get isDone(): boolean {
    return this.state > 1;
  }

  constructor(init?: Partial<Task>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);
    this.lastUpdate = new Date(init.lastUpdate);

    const targets = new Map<string, Target>();
    if (this.targets) {
      Object.keys(this.targets).map((k) => {
        targets.set(k, this.targets[k]);
      });
    }
    this.targets = targets;
  }

  equals(c?: Partial<Task>): boolean {
    if (this === c) {
      return true;
    }

    if (
      this.id === c.id &&
      this.description === c.description &&
      this.state === c.state &&
      this.timestamp === c.timestamp &&
      this.lastUpdate === c.lastUpdate
    ) {
      return true;
    }

    return false;
  }
}

export interface Target {
  agentID: string;
  state: TargetState;
  logs: { [key: string]: string };
}

export interface Action {
  params: { [key: string]: string };
  type: ActionType;
}
