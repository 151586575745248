import { QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { GPUSummary } from 'src/app/shared/models/gpu-summary.model';
import { GPU } from 'src/app/shared/models/system.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';

const _gpuGraphProps = new BehaviorSubject<string[]>([]);
export const gpuGraphProps$ = _gpuGraphProps.asObservable();
const count = 20;

export function updateGPU(
  gpuCharts: QueryList<LiveGraphComponent>,
  gpuGraphOps: GraphOptions[],
  summary: GPUSummary,
  systemGpu: GPU[],
  triggers: Array<Trigger> = []
): void {
  const now = new Date();
  const gpus = summary.metrics || [];

  gpus.forEach((g, i) => {
    const title = `GPU ${i}`;
    const gpuDedicated = `${Math.round(
      systemGpu[i].dedicatedVideoMemorySize / (1024 * 1024 * 1024)
    )} GB`;
    const gpuShared = `${Math.round(
      systemGpu[i].sharedSystemMemorySize / (1024 * 1024 * 1024)
    )} GB`;
    const value = [g.usagePercent];
    let ops: GraphOptions = gpuGraphOps.find((gg) => gg.id === g.physicalAdapterIndex);

    if (!ops) {
      ops = {
        id: g.physicalAdapterIndex,
        title: title,
        label: g.adapterName,
        icon: 'monitor-heart-rate',
        iconColor: 'blue',
        maxY: [100],
        seriesColors: [['blue']],
        seriesLineStyle: [['solid']],
        initialSeries: [
          [
            {
              name: 'Utilization',
              data: new Array(count).fill(0)
            }
          ]
        ],
        focusProperties: [],
        properties: {}
      };

      gpuGraphOps.push(ops);
    } else {
      value.forEach((v, i) => {
        ops.initialSeries[0][i].data.splice(0, 1);
        ops.initialSeries[0][i].data.push(v);
      });
    }

    ops.timestamp = now;

    Object.assign(ops.focusProperties, [
      {
        label: 'Utilization',
        value: `${g.usagePercent}%`,
        highlight: !!triggers.find(
          (t) =>
            !!t.conditions.find(
              (c) =>
                c.field === 'Summary.GPU.Metrics.UsagePercent' && c.isMet(g.usagePercent)
            )
        ),
        graphColor: 'blue',
        graphLineStyle: 'solid'
      },
      {
        label: 'Shared',
        value: `${(g.sharedUsage / (1024 * 1024 * 1024)).toFixed(1)} / ${gpuShared}`
      }
    ]);

    Object.assign(ops.properties, {
      Dedicated: `${(g.dedicatedUsage / (1024 * 1024 * 1024)).toFixed(
        1
      )} / ${gpuDedicated}`,
      Temp: `${g.temperature / 10} C`
    });

    if (g.power > 0) {
      ops.focusProperties['Power'] = `${g.power} A`;
    }

    if (g.fanRPM) {
      ops.focusProperties['Fan'] = `${g.fanRPM} RPM`;
    }

    if (_gpuGraphProps.value.length < 1) {
      _gpuGraphProps.next(
        [...Object.keys(ops.properties || [])].concat(
          ops.focusProperties.map((p) => p.label)
        )
      );
    }

    gpuCharts &&
      gpuCharts
        .filter((gc) => gc.id === g.physicalAdapterIndex)
        .forEach((gc) => {
          gc.appendData(value);
        });
  });

  for (let i = gpuGraphOps.length - 1; i >= 0; i--) {
    if (gpuGraphOps[i].timestamp !== now) {
      gpuGraphOps.splice(i, 1);
    }
  }
}
