export function getEmailRegex(): RegExp {
  return /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/im;
}

export function getDnsOrIpRegex(): RegExp {
  return /(\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$)/im;
}

export function getIPAddressRegex(): RegExp {
  return /\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b/im;
}

export function getDNSRegex(): RegExp {
  return /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/im;
}

export function getRoleIDRegex(): RegExp {
  return /^[a-z0-9]([a-z0-9]|-[^\W_]|_[^\W_])*[a-z0-9]*$/;
}

export function getTriggerKeyRegex(): RegExp {
  return /^[a-z0-9]([a-z0-9]|-[^\W_]|_[^\W_])*[a-z0-9]*$/;
}

export function getScriptIDRegex(): RegExp {
  return /^[a-z0-9]([a-z0-9]|-[^\W_]|_[^\W_])*[a-z0-9]*$/;
}

export function getScriptTagRegex(): RegExp {
  return /^[a-z0-9]([a-z0-9]|-[^\W_]|_[^\W_])*[a-z0-9]*$/;
}

export function getScriptParameterNameRegex(): RegExp {
  return /^[a-zA-Z_]+[_a-zA-Z0-9]*$/;
}

export function getScriptArgDetectRegex(): RegExp {
  return /(^-| -)+([a-zA-Z0-9-]*)$/i;
}

export function getScriptValidCharRegex(): RegExp {
  return /[a-zA-Z0-9-]/i;
}

export function getCommandLineExeRegex(): RegExp {
  return /(?:.*.exe)/gi;
}
