import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { APIKey } from 'src/app/shared/models/apikey.model';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class APIKeyService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(): Observable<Array<APIKey>> {
    const url = `${this.apiUrl}/tenant/apikey`;
    const start = performance.now();
    return this.http.get<Array<APIKey>>(url).pipe(
      tap(() => {
        this.log(
          `fetched APIKeyService.getAll response in ${performance.now() - start}ms`
        );
      }),
      map((response) => (response ? response.map((t) => new APIKey(t)) : undefined)),
      catchError((err) => {
        this.handleError<Array<APIKey>>('APIKeyService.getAll');
        return throwError(() => err);
      })
    );
  }
}
