import { IconName } from '@fortawesome/fontawesome-svg-core';
import { BatteryMetrics, BStatus } from 'src/app/shared/models/system-summary.model';

export class WifiIcon {
  color: string;
  icon: IconName;

  public static getQuality = (strength: number): WifiIcon => {
    if (strength >= 75) {
      return { color: 'green', icon: 'wifi' };
    }
    if (strength >= 50) {
      return { color: 'amber', icon: 'wifi-fair' };
    }
    if (strength >= 25) {
      return { color: 'orange', icon: 'wifi-weak' };
    }
    if (strength > 0) {
      return { color: 'red', icon: 'wifi-weak' };
    }
    if (strength === 0) {
      return { color: 'gray', icon: 'wifi-slash' };
    }
    return undefined;
  };
}

export class BatteryIcon {
  color: string;
  icon: IconName;

  public static getLeft = (b: BatteryMetrics): BatteryIcon => {
    const remaining = Math.round((b.current / b.full) * 100);
    if (b.state == BStatus.Charging || b.state == BStatus.Full) {
      if (remaining == 100) {
        return { color: 'green', icon: 'battery-bolt' };
      }
      if (remaining >= 75) {
        return { color: 'green', icon: 'battery-bolt' };
      }
      if (remaining >= 50) {
        return { color: 'amber', icon: 'battery-bolt' };
      }
      if (remaining >= 25) {
        return { color: 'orange', icon: 'battery-bolt' };
      }
      if (remaining > 0) {
        return { color: 'red', icon: 'battery-bolt' };
      }
      if (remaining === 0) {
        return { color: 'gray', icon: 'battery-bolt' };
      }
    } else {
      if (remaining == 100) {
        return { color: 'green', icon: 'battery-full' };
      }
      if (remaining >= 75) {
        return { color: 'green', icon: 'battery-three-quarters' };
      }
      if (remaining >= 50) {
        return { color: 'amber', icon: 'battery-half' };
      }
      if (remaining >= 25) {
        return { color: 'orange', icon: 'battery-quarter' };
      }
      if (remaining > 0) {
        return { color: 'red', icon: 'battery-low' };
      }
      if (remaining === 0) {
        return { color: 'gray', icon: 'battery-empty' };
      }
    }
    return undefined;
  };
}

export class GoogleMapLocation {
  lat?: number;
  lon?: number;
  latLng?: google.maps.LatLng;
  properties: Map<string, string> = new Map<string, string>();
}
