<div
  *ngIf="agreement$ | async as agreement"
  class="flex flex-col items-center justify-center w-full h-full p-4"
  [ngClass]="blurOut ? 'animate-blur-out' : 'animate-blur-in'"
>
  <div>
    <div class="flex flex-col justify-center items-center content-center">
      <div class="fill-current text-center">
        <img class="w-60 animate-blur-in" src="assets/img/demo/logo.svg" />
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center md:justify-center md:w-1/3">
    <div class="py-4 text-4xl text-center font-bold" [style.font-variant]="'small-caps'">{{ agreement.title }}</div>
    <div class="md:text-2xl text-center">
      You must agree to the following terms and conditions outlined in the following document{{
        agreement.documents.length > 1 ? 's' : ''
      }}:
    </div>
    <div class="py-4">
      <div *ngFor="let document of agreement.documents" class="flex items-center justify-center">
        <mat-checkbox (change)="toggle($event.checked)" color="primary" class="pr-4" type="checkbox"> </mat-checkbox>
        <a [href]="document.link" class="text-primary text-xs md:text-base mt-1" target="_agreement">{{ document.name }}</a>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      class=""
      type="button"
      [disabled]="agreement.documents.length !== agreeCount"
      (click)="agree(agreement.key)"
    >
      I Agree
    </button>
  </div>
</div>
