<div class="secondary-toolbar-placeholder">&nbsp;</div>

<div class="secondary-toolbar shadow-b py-1 px-2 border-t w-full relative flex items-center">
  <!-- <div  -->
  <div class="px-gutter w-full flex justify-between content-center" vexContainer>
    <div class="flex justify-start content-center items-center" [attr.data-intro-key]="introKey ? introKey : null">
      <ng-container *ngIf="spinner || icon">
        <div [ngClass]="{ 'animate w-full': spinner }">
          <fa-icon [icon]="icon ? ['fab', icon] : ['fad', 'question-circle']" size="2x" class="text-gray"> </fa-icon>
        </div>
      </ng-container>
      <div class="pl-3 pr-3 border-r mr-3 ml-3 relative header-container">
        <h1
          [ngClass]="{ 'animate w-full': spinner }"
          [ngStyle]="{ height: spinner ? '1.4rem' : 'unset' }"
          class="subheading-2 font-medium m-0 truncate"
        >
          {{
            header
              ? header
              : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
          }}
        </h1>
        <ng-container *ngIf="spinner || subheader || subheader2">
          <div class="flex justify-start content-center">
            <ng-container *ngIf="spinner || subheader">
              <span [ngClass]="{ 'animate w-full': spinner }" class="text-xs text-secondary">{{
                subheader
                  ? subheader
                  : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
              }}</span>
            </ng-container>
            <ng-container *ngIf="subheader2">
              <span *ngIf="subheader2" class="hidden sm:block text-xs text-secondary pl-4">{{ subheader2 }}</span>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
