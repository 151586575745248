import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, finalize, map, of, tap } from 'rxjs';
import { delay, distinctUntilChanged } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import {
  SubscribeService,
  UpdatePaymentSource
} from 'src/app/core/services/subscribe.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { PaymentSource } from 'src/app/shared/models/subscription.model';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { CustomValidators } from 'src/app/utils/validators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AddressComponent } from 'src/app/shared/components/address/address.component';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import { LoaderBallComponent } from 'src/app/shared/components/loader-ball/loader-ball.component';
import { CreditCardDirective } from 'src/app/shared/directives/credit-card.directive';
import { DevHideDirective } from 'src/app/shared/directives/dev-hide.directive';

type PaymentSourceType = 'UNSELECTED' | 'CC' | 'ACH';
type ACHAccountType = 'checking' | 'savings';
type ACHAccountHolderType = 'individual' | 'company';

export class SubscriptionPaymentSourceParams {
  paymentSource: PaymentSource;
  onPaymentSourceUpdated: (result: PaymentSource) => void;

  constructor(init?: Partial<SubscriptionPaymentSourceParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'mon-payment-source',
  imports: [
    DevHideDirective,
    CreditCardDirective,
    CommonModule,
    AddressComponent,
    LoaderBallComponent,
    SpinnerComponent,
    ResultOverlayComponent,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    ReactiveFormsModule
  ],
  templateUrl: './payment-source.component.html',
  styleUrls: ['./payment-source.component.scss'],
  animations: [fadeInRight400ms]
})
export class PaymentSourceComponent implements OnInit {
  ccMonths = Array.from({ length: 12 }, (_, i) => 1 + i);
  ccYears = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() + i);
  showTypeSelection = true;
  spin = true;
  overlayResult = OverlayResult.Unset;

  paymentSourceForm = new FormGroup({
    type: new FormControl<PaymentSourceType>('CC')
  });

  ccForm = new FormGroup(
    {
      companyName: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      firstName: new FormControl('', [
        Validators.required,
        CustomValidators.noWhitespace
      ]),
      lastName: new FormControl('', [Validators.required, CustomValidators.noWhitespace]),
      address1: new FormControl(''),
      address2: new FormControl(''),
      locality: new FormControl(''),
      region: new FormControl(''),
      regionCode: new FormControl(''),
      postcode: new FormControl(''),
      countryCode: new FormControl('', [Validators.required]),
      country: new FormControl(''),
      number: new FormControl('', [
        Validators.required,
        Validators.minLength(12),
        CustomValidators.luhnCheck,
        CustomValidators.noWhitespace
      ]),
      cvv: new FormControl('', [
        Validators.minLength(3),
        Validators.maxLength(4),
        Validators.required,
        CustomValidators.noWhitespace
      ]),
      expiryMonth: new FormControl(this.ccMonths[0], [
        Validators.required,
        CustomValidators.noWhitespace
      ]),
      expiryYear: new FormControl(this.ccYears[0], [
        Validators.required,
        CustomValidators.noWhitespace
      ])
    },
    [
      (g: FormGroup) => {
        const now = new Date();
        const testDate = new Date();
        testDate.setFullYear(g.controls.expiryYear.value);
        testDate.setMonth(g.controls.expiryMonth.value - 1);
        if (testDate.getTime() < now.getTime()) {
          g.controls.expiryMonth.setErrors({ invalid: true });
        } else {
          g.controls.expiryMonth.setErrors(null);
        }
        return null;
      }
    ]
  );

  achForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, CustomValidators.noWhitespace]),
    lastName: new FormControl('', [Validators.required, CustomValidators.noWhitespace]),
    bankName: new FormControl('', [Validators.required, CustomValidators.noWhitespace]),
    accountNumber: new FormControl('', [
      Validators.required,
      CustomValidators.noWhitespace
    ]),
    routingNumber: new FormControl('', [
      Validators.required,
      CustomValidators.noWhitespace
    ]),
    accountType: new FormControl<ACHAccountType>('checking', [
      Validators.required,
      CustomValidators.noWhitespace
    ]),
    accountHolderType: new FormControl<ACHAccountHolderType>('individual', [
      Validators.required,
      CustomValidators.noWhitespace
    ])
  });

  estimateForm = new FormGroup({});

  subscription$ = this.subscribeSvc.getSubscription().pipe(
    tap(() => (this.spin = false)),
    tap((s) => {
      if (s.status === 'cancelled') {
        this.showTypeSelection = false;
        this._selectedPaymentSource.next('CC');
        setTimeout(() => this.ccCompanyName?.nativeElement.focus(), 100);
      }
    })
  );

  _selectedPaymentSource = new BehaviorSubject<PaymentSourceType>('UNSELECTED');
  selectedPaymentSource$ = this._selectedPaymentSource.asObservable().pipe(
    distinctUntilChanged(),
    tap((t) => this.paymentSourceForm.controls.type.setValue(t)),
    tap(
      () =>
        this.matStepper &&
        this.matStepper.steps
          .filter((s) => ['Credit Card', 'Bank Account'].includes(s.label))
          .forEach((s) => s.reset())
    )
  );

  _refresh = new BehaviorSubject<void>(undefined);
  refresh$ = this._refresh.asObservable();

  animate$ = of(false).pipe(
    delay(0),
    map(() => true)
  );

  @ViewChild(MatStepper) matStepper: MatStepper;
  @ViewChild('ccCompanyName') ccCompanyName: ElementRef;
  @ViewChild('ccFirstName') ccFirstName: ElementRef;
  @ViewChild('achFirstName') achFirstName: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected params: SubscriptionPaymentSourceParams,
    private dialogRef: MatDialogRef<PaymentSourceComponent>,
    private snackBar: MatSnackBar,
    private subscribeSvc: SubscribeService
  ) {}

  ngOnInit(): void {
    const card = this.params.paymentSource?.card;

    if (card) {
      this.ccForm.controls.companyName.setValue(card.company);
      this.ccForm.controls.phone.setValue(card.phone);
      this.ccForm.controls.firstName.setValue(card.firstName);
      this.ccForm.controls.lastName.setValue(card.lastName);
      this.ccForm.controls.address1.setValue(card.billingAddr1);
      this.ccForm.controls.address2.setValue(card.billingAddr2);
      this.ccForm.controls.locality.setValue(card.billingCity);
      this.ccForm.controls.regionCode.setValue(card.billingStateCode);
      this.ccForm.controls.region.setValue(card.billingState);
      this.ccForm.controls.countryCode.setValue(card.billingCountry);
      this.ccForm.controls.postcode.setValue(card.billingZip);
      this.ccForm.controls.expiryMonth.setValue(card.expiryMonth);
      this.ccForm.controls.expiryYear.setValue(card.expiryYear);
    }

    const bankAccount = this.params.paymentSource?.bankAccount;

    if (bankAccount) {
      this.ccForm.controls.firstName.setValue(bankAccount.firstName);
      this.ccForm.controls.lastName.setValue(bankAccount.lastName);
    }
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.dialogRef.close();
    }
  }

  onStep(event: StepperSelectionEvent) {
    switch (event.selectedStep.label) {
      case 'Payment Source':
        break;
      case 'Credit Card':
        setTimeout(() => this.ccCompanyName?.nativeElement.focus(), 100);
        break;
      case 'Bank Account':
        setTimeout(() => this.achFirstName?.nativeElement.focus(), 100);
        break;
    }
  }

  submit(): void {
    this.spin = true;

    const paymentSource: UpdatePaymentSource = {};

    switch (this._selectedPaymentSource.getValue()) {
      case 'CC':
        paymentSource.card = {
          company: this.ccForm.controls.companyName.value,
          phone: this.ccForm.controls.phone.value,
          address1: this.ccForm.controls.address1.value,
          address2: this.ccForm.controls.address2.value,
          locality: this.ccForm.controls.locality.value,
          regionCode: this.ccForm.controls.regionCode.value,
          postcode: this.ccForm.controls.postcode.value,
          country: this.ccForm.controls.countryCode.value,
          firstName: this.ccForm.controls.firstName.value,
          lastName: this.ccForm.controls.lastName.value,
          number: this.ccForm.controls.number.value,
          cvv: this.ccForm.controls.cvv.value,
          expiryMonth: this.ccForm.controls.expiryMonth.value,
          expiryYear: this.ccForm.controls.expiryYear.value
        };
        break;
      case 'ACH':
        paymentSource.bankAccount = {
          firstName: this.achForm.controls.firstName.value,
          lastName: this.achForm.controls.lastName.value,
          bankName: this.achForm.controls.bankName.value,
          accountNumber: this.achForm.controls.accountNumber.value,
          routingNumber: this.achForm.controls.routingNumber.value,
          accountType: this.achForm.controls.accountType.value,
          accountHolderType: this.achForm.controls.accountHolderType.value
        };
        break;
    }

    this.subscribeSvc
      .updatePaymentSource(paymentSource)
      .pipe(
        tap((result) => {
          this.overlayResult = OverlayResult.Unset;
          this.params.onPaymentSourceUpdated?.(result);
          this.dialogRef.close();
        }),
        // switchMap(() =>
        //   // we may need to update the subscription as well, if not just move along to updating payment
        //   iif(
        //     () => !!this.params?.itemPriceID && !!this.params?.quantity,
        //     this.subscribeSvc
        //       .updateSubscription(
        //         this.params.itemPriceID,
        //         this.params.quantity,
        //         this.params.couponCode
        //       )
        //       .pipe(
        //         tap((result) => {
        //           this.overlayResult = OverlayResult.Unset;
        //           this.params.onSubscriptionUpdated?.(result);
        //           this.dialogRef.close();
        //         })
        //       ),
        //     UNDEFINED
        //   )
        // ),
        catchError((err) => {
          const errMsg = parseError(err, 'failed to submit payment source');
          this.snackBar.open(errMsg.toUpperCase(), 'CLOSE', {
            duration: 10000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });

          return UNDEFINED;
        }),
        finalize(() => (this.spin = false))
      )
      .subscribe();

    return;
  }

  selectPaymentSource(t: PaymentSourceType): void {
    this._selectedPaymentSource.next(t);
    setTimeout(() => this.matStepper.next(), 100);
  }
}
