import { Updatable } from '../interfaces/updatable.interface';

export class MemorySummary implements Updatable<MemorySummary> {
  public inUse: number;
  public utilizationPercent: number;

  constructor(init?: Partial<MemorySummary>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<MemorySummary>): this {
    return Object.assign(this, input);
  }
}
