import deepMergeLib from 'deepmerge';
import { isPlainObject } from 'is-plain-object';

export function deepCopy<T>(source: T): T {
  const mapCopy = (s) => {
    const x = new Map();
    s.forEach((v: unknown, k: unknown) => {
      x.set(deepCopy(k), deepCopy(v));
    });
    return x;
  };

  return Array.isArray(source)
    ? source.map((item) => deepCopy(item))
    : source instanceof Map
    ? mapCopy(source)
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
        o[prop] = deepCopy(source[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(source)))
    : (source as T);
}

export function mergeMaps<K, V>(
  maps: ReadonlyArray<ReadonlyMap<K, V>>,
  deep = false
): Map<K, V> {
  return maps.reduce<Map<K, V>>((mutableCarry, current) => {
    for (const [key, value] of current.entries()) {
      const currentValue = mutableCarry.get(key);
      mutableCarry.set(
        key,
        deep && typeof value === 'object' && typeof currentValue === 'object'
          ? (deepMerge([
              currentValue as unknown as object,
              value as unknown as object
            ]) as unknown as V)
          : value
      );
    }
    return mutableCarry;
  }, new Map<K, V>());
}

export function mergeSets<V>(sets: ReadonlyArray<ReadonlySet<V>>): Set<V> {
  return sets.reduce<Set<V>>((mutableCarry, current) => {
    for (const value of current.values()) {
      mutableCarry.add(value);
    }
    return mutableCarry;
  }, new Set<V>());
}

export function deepMerge<T extends object>(
  values: ReadonlyArray<T>,
  options = { mergeMaps: true, mergeSets: true }
): T {
  const [mergeAsMaps, mergeAsSets] =
    (options.mergeMaps || options.mergeSets) && values.length > 0
      ? values.reduce(
          (carry, value) => [
            carry[0] && value instanceof Map,
            carry[1] && value instanceof Set
          ],
          [options.mergeMaps, options.mergeSets]
        )
      : [false, false];

  if (mergeAsSets) {
    return mergeSets(values as any) as T;
  }
  if (mergeAsMaps) {
    return mergeMaps(values as any, true) as T;
  }

  return deepMergeLib.all(values as Array<T>, {
    customMerge: () => (a: T, b: T) => deepMerge<T>([a, b], options),
    isMergeableObject: (value: object) =>
      (options.mergeSets && value instanceof Set) ||
      (options.mergeMaps && value instanceof Map) ||
      isPlainObject(value),
    clone: false
  }) as T;
}
