<div class="border-4 border-opacity-50 border-gray-light dark:border-gray-500 rounded drop-shadow-md">
  <div class="text-xs sm:text-base card overflow-auto flex flex-col md:flex-row p-4">
    <div class="flex gap-2">
      <div class="flex flex-col gap-2 items-start justify-center">
        <h3 class="body-2 m-0 text-accent-500 text-lg">PAGES</h3>
        <div class="grid grid-cols-3 gap-2">
          <a
            *ngFor="let page of recentPages$ | async"
            matRipple
            (click)="close()"
            [routerLink]="page.route"
            queryParamsHandling="merge"
            class="text-dark p-1 sm:p-2 text-center hover:bg-hover hover:text-default trans-ease-out rounded block no-underline"
          >
            <fa-icon [icon]="['fad', page.icon]" class="text-primary" size="lg"></fa-icon>
            <div class="body-1 mt-2">{{ page.label }}</div>
          </a>
        </div>
      </div>
    </div>

    <ng-container *ngIf="recentMachines$ | async as machines">
      <div *ngIf="machines.length > 0" class="flex gap-2">
        <div class="flex flex-col gap-2 items-start justify-start">
          <h3 class="body-2 m-0 text-accent-500 text-lg">MACHINES</h3>
          <div class="grid grid-cols-2 gap-2">
            <button
              *ngFor="let machine of machines"
              matRipple
              (click)="gotoMachine(machine)"
              class="text-left text-dark body-1 no-underline hover:bg-hover hover:text-default trans-ease-out truncate p-2 md:p-4 rounded"
            >
              {{ machine }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
