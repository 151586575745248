import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, map } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ApiService } from './api.service';

export interface City {
  id?: string;
  name?: string;
}

export interface State {
  id?: string;
  name?: string;
  stateCode?: string;
}

export interface Country {
  id?: string;
  name?: string;
  iso3?: string;
  iso2?: string;
}

export interface GeoLocation {
  region?: string;
  ip?: string;
  country?: string;
  country_code?: string;
  city?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostalService extends ApiService {
  private _countryCache = new Array<Country>();
  private _stateCache = {};
  private _cityCache = {};

  constructor(private http: HttpClient) {
    super();
  }

  getCountries(): Observable<Array<Country>> {
    if (this._countryCache.length > 0) {
      return of(this._countryCache);
    }

    const url = `${this.cfUrl}/postal`;
    const start = performance.now();

    return this.http.get<Array<Country>>(url).pipe(
      tap(() => {
        this.log(
          `fetched PostalService.getCountries() response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((countries) => countries || new Array<Country>()),
      tap(
        (countries) =>
          (this._countryCache = countries.sort((a, b) => (a.name > b.name ? 1 : -1)))
      ),
      catchError((err) => {
        this.handleError<Array<Country>>('PostalService.getCountries');
        return throwError(() => err);
      })
    );
  }

  getStates(countryISO2: Country['iso2']): Observable<Array<State>> {
    if (this._stateCache[countryISO2]?.length >= 0) {
      return of(this._stateCache[countryISO2]);
    }

    if (!countryISO2) {
      this._stateCache[countryISO2] = [];
      return of(this._stateCache[countryISO2]);
    }

    const url = `${this.cfUrl}/postal`;
    const params = {
      countryISO2
    };
    const start = performance.now();
    return this.http.get<Array<State>>(url, { params }).pipe(
      tap(() => {
        this.log(
          `fetched PostalService.getStates('${countryISO2}') response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((states) => states || new Array<State>()),
      tap(
        (states) =>
          (this._stateCache[countryISO2] = states.sort((a, b) =>
            a.name > b.name ? 1 : -1
          ))
      ),
      catchError((err) => {
        this.handleError<Array<State>>('PostalService.getStates');
        return throwError(() => err);
      })
    );
  }

  getCities(
    countryISO2: Country['iso2'],
    stateCode: State['stateCode']
  ): Observable<Array<City>> {
    const key = countryISO2 + '.' + stateCode;
    if (this._cityCache[key]?.length > 0) {
      return of(this._cityCache[key]);
    }

    if (!countryISO2 || !stateCode) {
      this._cityCache[key] = [];
      return of(this._cityCache[key]);
    }

    const url = `${this.cfUrl}/postal`;
    const params = {
      countryISO2,
      stateCode
    };
    const start = performance.now();
    return this.http.get<Array<City>>(url, { params }).pipe(
      tap(() => {
        this.log(
          `fetched PostalService.getCities('${countryISO2}', '${stateCode}') response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((cities) => cities || new Array<City>()),
      tap(
        (cities) =>
          (this._cityCache[key] = cities.sort((a, b) => (a.name > b.name ? 1 : -1)))
      ),
      catchError((err) => {
        this.handleError<Array<City>>('PostalService.getCities');
        return throwError(() => err);
      })
    );
  }

  getGeo(): Observable<GeoLocation> {
    const url = `https://get.geojs.io/v1/ip/geo.json`;
    return this.http.get<GeoLocation>(url);
  }
}
