import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[fillHeight]'
})
export class FillHeightDirective implements AfterViewInit, OnChanges {
  @Input('fillHeight') condition?: unknown = true;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resize();
  }

  constructor(private renderer: Renderer2, private elRef: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condition) {
      this.resize();
    }
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  resize(): void {
    const e = this.elRef.nativeElement;

    if (e.getBoundingClientRect) {
      if (this.condition === '' || this.condition) {
        const eRect = e.getBoundingClientRect();

        const p = e.parentElement;
        const pRect = p.getBoundingClientRect();

        const pHeight = pRect.height;
        const offset = eRect.y - pRect.y;

        this.renderer.setStyle(e, 'height', `${pHeight - offset}px`);
      } else {
        this.renderer.removeStyle(e, 'height');
      }
    }
  }
}
