import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTooltip } from '@angular/material/tooltip';
import { BehaviorSubject, combineLatest, distinctUntilChanged, EMPTY, filter, map, of, Subject, switchMap, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { IntroService } from 'src/app/core/services/intro.service';
import { ScriptService } from 'src/app/core/services/script.service';
import { StateService } from 'src/app/core/services/state.service';
import { PermissionsComponent } from 'src/app/shared/components/permissions/permissions.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { PermissionValue } from 'src/app/shared/models/permissions.model';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';

@Component({
  selector: 'mon-script-detail',
  templateUrl: './script-detail.component.html',
  styleUrls: ['./script-detail.component.scss'],
  animations: [fadeInUp400ms]
})
export class ScriptDetailComponent implements AfterViewInit {
  permission = Permission;
  scriptLocation = ScriptLocation;

  _script: Script = undefined;
  get script(): Script {
    return this._script;
  }
  @Input() set script(script: Script) {
    this._script = script;
    this._refresh.next(true);
  }
  @Input() disableAction = false;
  @Input() disableSocial = false;

  @Output() run = new EventEmitter<Script>();
  @Output() upgrade = new EventEmitter<Script>();
  @Output() enable = new EventEmitter<Script>();
  @Output() disable = new EventEmitter<Script>();
  @Output() delete = new EventEmitter<Script>();
  @Output() uninstall = new EventEmitter<Script>();
  @Output() install = new EventEmitter<Script>();
  @Output() modify = new EventEmitter<Script>();
  @Output() archive = new EventEmitter<Script>();
  @Output() unlike = new EventEmitter<Script>();
  @Output() like = new EventEmitter<Script>();
  @Output() feedback = new EventEmitter<Script>();

  @Output() tagSelect = new EventEmitter<string>();

  private _loadSource = new Subject<boolean>();
  loadSource$ = this._loadSource.asObservable().pipe(distinctUntilChanged());

  private _loadPermissions = new Subject<boolean>();
  loadPermissions$ = this._loadPermissions.asObservable().pipe(distinctUntilChanged());

  private _loadChangelog = new Subject<boolean>();
  loadChangelog$ = this._loadChangelog.asObservable().pipe(distinctUntilChanged());

  private _codeLoaded = new BehaviorSubject<boolean>(false);
  readonly codeLoaded$ = this._codeLoaded.asObservable();

  private _refresh = new BehaviorSubject(undefined);
  readonly refresh$ = this._refresh.asObservable();

  scriptCode$ = combineLatest([this.loadSource$, this.refresh$]).pipe(
    tap(() => this._codeLoaded.next(false)),
    switchMap(() => of(this.script)),
    switchMap((script) => {
      return script
        ? this.scriptSvc.location(script.location).getCodeByID(script.id)
        : EMPTY;
    }),
    tap(() => this._codeLoaded.next(true))
  );

  scriptPerms$ = combineLatest([this.loadPermissions$, this.refresh$]).pipe(
    switchMap(() => of(this.script)),
    filter((script) => !!script.installRefID),
    switchMap((script) => {
      return script
        ? this.scriptSvc
            .location(ScriptLocation.Private)
            .getPermsByID(script.installRefID)
        : EMPTY;
    })
  );

  scriptChangelog$ = combineLatest([this.loadChangelog$, this.refresh$]).pipe(
    map(() => this.script),
    switchMap(() => of(this.script)),
    switchMap((script) => {
      return script
        ? this.scriptSvc.location(script.location).getChangelogByID(script.id)
        : EMPTY;
    }),
    map((c) => Array.from(c?.values() || []).reverse())
  );

  user$ = this.stateSvc.currentUser$;

  @ViewChild('permControl', { static: false }) permControl: PermissionsComponent;

  constructor(
    private clipboard: Clipboard,
    private stateSvc: StateService,
    private introSvc: IntroService,
    private scriptSvc: ScriptService
  ) {}

  ngAfterViewInit(): void {
    this.introSvc.show(
      'script-detail-header',
      'script-detail-tags',
      'script-detail-feedback'
    );
  }

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.hide();
    setTimeout(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
    }, 50);
    setTimeout(() => {
      tooltip.hide();
    }, 1250);
    setTimeout(() => {
      tooltip.hide();
      tooltip.message = 'Copy';
    }, 1500);
    this.clipboard.copy(text);
  }

  runScript() {
    this.run.emit(this._script);
  }

  upgradeScript() {
    this.upgrade.emit(this._script);
  }

  enableScript() {
    this.enable.emit(this._script);
  }

  disableScript() {
    this.disable.emit(this._script);
  }

  deleteScript() {
    this.delete.emit(this._script);
  }

  uninstallScript() {
    this.uninstall.emit(this._script);
  }

  modScript() {
    this.modify.emit(this._script);
  }

  installScript() {
    this.install.emit(this._script);
  }

  archiveScript() {
    this.archive.emit(this._script);
  }

  unlikeScript() {
    this.unlike.emit(this._script);
  }

  likeScript() {
    this.like.emit(this._script);
  }

  giveFeedback() {
    this.feedback.emit(this._script);
  }

  permOnAdd(perm: PermissionValue): void {
    this.scriptSvc
      .location(ScriptLocation.Private)
      .setPermByID(this._script.installRefID, perm)
      .pipe(
        tap(() => this.permControl.resetInput()),
        tap(() => setTimeout(() => this.permControl.focusInput(), 500)),
        tap(() => this._refresh.next(true))
      )
      .subscribe();
  }

  permOnUpdate(perm: PermissionValue): void {
    this.scriptSvc
      .location(ScriptLocation.Private)
      .setPermByID(this._script.installRefID, perm)
      .pipe(tap(() => this._refresh.next(true)))
      .subscribe();
  }

  permOnDelete(perm: PermissionValue): void {
    this.scriptSvc
      .location(ScriptLocation.Private)
      .deletePermByID(this._script.installRefID, perm)
      .pipe(tap(() => this._refresh.next(true)))
      .subscribe();
  }

  tabChange(event: MatTabChangeEvent) {
    switch (event.tab.textLabel) {
      case 'Source':
        this._loadSource.next(true);
        break;
      case 'Permissions':
        this._loadPermissions.next(true);
        break;
      case 'Changelog':
        this._loadChangelog.next(true);
        break;
    }
  }
}
