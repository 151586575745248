import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';
import { AgentStats } from 'src/app/shared/models/agent-stats.model';
import { Session } from 'src/app/shared/models/session.model';

export class SessionsDataSource implements DataSource<Session> {
  private pollingSubscription: Subscription;
  private _sessions = new BehaviorSubject<Session[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._sessions.value ? this._sessions.value.length : -1;
  }
  public get data(): Session[] {
    return this._sessions.value?.slice() || [];
  }
  public data$ = this._sessions.asObservable().pipe(distinctUntilChanged());

  constructor(private agentStats$: Observable<AgentStats>) {}

  loadSessions(): void {
    this._loading.next(true);

    this.pollingSubscription = this.agentStats$
      .pipe(
        catchError(() => of([])),
        finalize(() => this._loading.next(false)),
        map((stats: AgentStats): Session[] => stats?.summary?.system.sessions || []),
        tap((sessions) => {
          this.total = sessions.length;

          if (this._sessions.value) {
            this.updateSessions(sessions);
            return;
          }

          this._sessions.next(sessions || []);
        })
      )
      .subscribe();
  }

  connect(): Observable<Session[]> {
    return this._sessions.asObservable();
  }

  disconnect(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }

    this._sessions.complete();
    this._loading.complete();
  }

  private updateSessions(sessions: Array<Session>): void {
    let updateOnDeleteOrAdd = false;

    const sessionMap = new Map<string, Session>();
    sessions.forEach((s) => sessionMap.set(`${s.id}:${s.username}`, s));

    for (let i = 0; i < this._sessions.value.length; ) {
      const oldSession = this._sessions.value[i];
      const key = `${oldSession.id}:${oldSession.username}`;
      const newSession = sessionMap.get(key);

      if (newSession != null) {
        if (oldSession != null) {
          oldSession.update(newSession);
          sessionMap.delete(key);
          updateOnDeleteOrAdd = true;
        }
        i++;
      } else {
        this._sessions.value.splice(i, 1);
        updateOnDeleteOrAdd = true;
      }
    }

    sessionMap.forEach((s) => {
      if (s) {
        updateOnDeleteOrAdd = true;
        this._sessions.value.push(s);
      }
    });

    if (updateOnDeleteOrAdd) {
      this._sessions.next(this._sessions.value);
    }
  }
}
