export enum PacketType {
  StartPacket = 'start-packet',
  CompletePacket = 'complete-packet',
  ErrorPacket = 'error-packet',

  RemoteMsraPacket = 'remote-msra-packet',

  TraceroutePacket = 'traceroute-packet',
  ShellOutputPacket = 'shell-output-packet',

  IPConfigPacket = 'ipconfig-packet',

  PingPacket = 'ping-packet',
  PingDupePacket = 'ping-dupe-packet',
  PingStatPacket = 'ping-stat-packet',

  TestPacket = 'test-packet',

  DiskVolumesPacket = 'disk-volumes-packet',
  OhmPacket = 'ohm-packet',

  SpeedtestUserPacket = 'speedtest-user-packet',
  SpeedtestServersPacket = 'speedtest-servers-packet',
  SpeedtestLatencyPacket = 'speedtest-latency-packet',
  SpeedtestDownRunningPacket = 'speedtest-down-running-packet',
  SpeedtestDownCompletePacket = 'speedtest-down-complete-packet',
  SpeedtestUpRunningPacket = 'speedtest-up-running-packet',
  SpeedtestUpCompletePacket = 'speedtest-up-complete-packet',

  ScriptDownloadPacket = 'script-download-packet',
  ScriptShellVersionPacket = 'script-shell-version-packet',
  ScriptSignCheckPacket = 'script-sign-check-packet',
  ScriptHashCheckPacket = 'script-hash-check-packet',
  ScriptRunStartPacket = 'script-run-start-packet',
  ScriptRunEndPacket = 'script-run-end-packet',
  ScriptOutputPacket = 'script-output-packet'
}

export class Packet {
  public type: PacketType;
  public version: string;
  public timeStamp: Date;
  public apiKey: string;
  public tenantID: string;
  public agentID: string;
  public tag: string;
  public content: unknown;

  constructor(init?: Partial<Packet>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }
}
