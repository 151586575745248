import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  switchMap,
  timer
} from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { Agent } from 'src/app/shared/models/agent.model';

import { UNDEFINED } from '../../utils/rxjs';
import { AgentService } from './agent.service';
import { ApiService, ServiceState } from './api.service';
import { SelectedAgentState, StateService } from './state.service';

export type AgentConnectionState =
  | 'unknown'
  | 'connecting'
  | 'connected'
  | 'disconnected'
  | 'blocked';

@Injectable({ providedIn: 'root' })
export class AgentLivePollerService extends ApiService {
  private _state = new BehaviorSubject<ServiceState>(ServiceState.Stopped);
  readonly state$ = this._state.asObservable();
  get state(): ServiceState {
    return this._state.value;
  }
  set state(value: ServiceState) {
    if (this._state.value === value) {
      return;
    }
    this._state.next(value);
  }

  private _liveAgent = new BehaviorSubject<Agent>(undefined);
  readonly liveAgent$ = this._liveAgent.asObservable();
  get liveAgent(): Agent {
    return this._liveAgent.value;
  }

  private _agentConnectionState = new BehaviorSubject<AgentConnectionState>('unknown');
  readonly agentConnectionState$ = this._agentConnectionState.asObservable();
  get agentDisconnected(): AgentConnectionState {
    return this._agentConnectionState.value;
  }

  newerThan: Date;
  private liveRequestTimer$: Observable<number>;
  private liveRequestSubscription: Subscription;
  private pollSub: Subscription;
  private listen: boolean;

  constructor(
    private router: Router,
    private stateSvc: StateService,
    private agentSvc: AgentService
  ) {
    super();

    this.liveRequestTimer$ = timer(0, 15000);

    combineLatest([this.stateSvc.selectedAgent$, this.stateSvc.tenant$]).subscribe({
      next: ([selectedAgent, tenant]) => {
        this.listen = false;
        if (selectedAgent.state !== SelectedAgentState.Found || !tenant) {
          this.stop();
        }
      }
    });

    combineLatest([this.stateSvc.liveMode$, this.state$]).subscribe({
      next: ([liveMode, state]) => {
        if (liveMode && state == ServiceState.Started) {
          this.newerThan = new Date(0);
          this.setAgentConnectionState('connecting');
          this.pollLiveAgent();
        } else {
          if (this.pollSub && !this.pollSub.closed) {
            this.pollSub.unsubscribe();
          }
        }
      }
    });
  }

  public start(): void {
    if (this.state !== ServiceState.Stopped) {
      return;
    }

    if (this._agentConnectionState.getValue() === 'connecting') {
      return;
    }

    this.state = ServiceState.Starting;

    this.setAgentConnectionState('connecting');

    if (this.pollSub && !this.pollSub.closed) {
      this.pollSub.unsubscribe();
    }

    this.state$
      .pipe(
        tap((state) => {
          if (state === ServiceState.Started) {
            this.startTimer();
          } else {
            if (this.liveRequestSubscription && !this.liveRequestSubscription.closed) {
              this.liveRequestSubscription.unsubscribe();
            }
          }
        })
      )
      .subscribe();

    this.listen = false;
    this.newerThan = new Date(0);

    this.state = ServiceState.Started;
  }

  public stop(): void {
    if (this.state === ServiceState.Stopped || this.state === ServiceState.Stopping) {
      return;
    }

    this.state = ServiceState.Stopping;

    if (this.liveRequestSubscription) {
      this.liveRequestSubscription.unsubscribe();
    }
    if (this.pollSub) {
      this.pollSub.unsubscribe();
    }

    this.setAgentConnectionState('unknown');

    this.setAgent(undefined);

    this.state = ServiceState.Stopped;
  }

  public pause(): void {
    if (this.state === ServiceState.Started) {
      this.state = ServiceState.Paused;
    }
  }

  public unpause(): void {
    if (this.state === ServiceState.Paused) {
      this.state = ServiceState.Started;
    }
  }

  private startTimer() {
    this.liveRequestSubscription = combineLatest([
      this.liveRequestTimer$,
      this.stateSvc.selectedAgent$,
      this.stateSvc.liveMode$
    ])
      .pipe(
        filter(
          ([_liveRequestTimer, selectedAgent, liveMode]) =>
            liveMode &&
            selectedAgent.state === SelectedAgentState.Found &&
            selectedAgent.agentBlocks.length === 0 &&
            this.state === ServiceState.Started
        ),
        switchMap(() => {
          return this.agentSvc
            .requestAgentToGoLive(this.stateSvc.selectedAgent.agentID)
            .pipe(
              catchError((err) => {
                console.error(err);

                return UNDEFINED;
              })
            );
        })
      )
      .subscribe();
  }

  private pollLiveAgent() {
    let start: number;

    this.pollSub = this.stateSvc.selectedAgent$
      .pipe(
        take(1),
        tap((selectedAgent) => {
          if (selectedAgent.agentBlocks.length > 0) {
            this.setAgentConnectionState('blocked');
            this.listen = false;
          } else {
            this.listen = true;
          }
        }),
        tap(() => (start = performance.now())),
        switchMap((selectedAgent) =>
          this.agentSvc
            .getLiveAgent(
              this.stateSvc.selectedAgent.agentID,
              this.listen,
              this.newerThan
            )
            .pipe(
              tap(() => {
                if (selectedAgent.agentBlocks.length === 0) {
                  const elapsed = performance.now() - start;
                  this.setAgentConnectionState(
                    this.newerThan.getTime() === new Date(0).getTime()
                      ? 'connecting'
                      : elapsed > 15 * 1000 || !this.listen
                        ? 'disconnected'
                        : 'connected'
                  );
                }
              })
            )
        )
      )
      .subscribe({
        next: (agent) => {
          this.listen = true;

          this.stateSvc.setServerDisconnected(false);

          if (agent) {
            this.setAgent(agent);
            const newerThan = agent.lastLiveUpdate;
            // rounding up to the next millisecond because golang precision is microseconds
            newerThan.setMilliseconds(newerThan.getMilliseconds() + 1);
            this.newerThan = newerThan;
          }
        },
        error: (err) => {
          if (err.status !== 404) {
            this.stateSvc.setServerDisconnected(true);
          } else {
            if (!this.pollSub.closed) {
              this.pollSub.unsubscribe();
            }
            this.pollLiveAgent();
          }
        },
        complete: () => {
          if (!this.pollSub.closed) {
            this.pollSub.unsubscribe();
          }
          this.pollLiveAgent();
        }
      });
  }

  private setAgentConnectionState(disconnected: AgentConnectionState) {
    if (this.agentDisconnected === disconnected) {
      return;
    }
    this.log(`agentDisconnected update to: ${disconnected}`);
    this._agentConnectionState.next(disconnected);
  }

  private setAgent(agent: Agent): void {
    if (this.liveAgent === agent) {
      return;
    }
    if (this.liveAgent && agent) {
      if (this.liveAgent.lastLiveUpdate === agent.lastLiveUpdate) {
        return;
      }
    }

    this._liveAgent.next(agent);

    if (!this.liveAgent?.stats) {
      this.router.navigate(window.location.pathname.split('/'), {
        skipLocationChange: true,
        queryParams: null,
        queryParamsHandling: 'merge'
      });
    }
  }
}
