import { BStatus } from 'src/app/shared/models/system-summary.model';

export function routeTo(link: string): void {
  window.open(link, '_blank');
}

export function convertNumToTime(n: number) {
  // Check sign of given number
  const sign: number = n >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  n = n * sign;

  // Separate the int from the decimal part
  const hour = Math.floor(n);
  let decpart: number = n - hour;

  const min = 1 / 60;

  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  let minute: string = Math.floor(decpart * 60) + '';

  // Add padding if need
  if (minute.length < 2) {
    minute = '0' + minute;
  }

  // Add Sign in final result
  const signAdded: string = sign == 1 ? '' : '-';

  // Concate hours and minutes
  const final: string = signAdded + hour + 'h:' + minute + 'm';

  return final;
}

export function getStringState(state: BStatus): string {
  switch (state) {
    case BStatus.Discharging:
      return 'Discharging';
    case BStatus.Charging:
      return 'Charging';
    case BStatus.Full:
      return 'Full';
    default:
      return 'Unknown';
  }
}
