import { Updatable } from '../interfaces/updatable.interface';

export class CpuSummary implements Updatable<CpuSummary> {
  public utilizationPercent: number;

  constructor(init?: Partial<CpuSummary>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<CpuSummary>): this {
    return Object.assign(this, input);
  }
}
