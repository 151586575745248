import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { loadJS } from 'src/app/utils/load-js';

import { ApiService } from './api.service';

declare let JitsiMeetExternalAPI: any;

export interface JitsiOptions {
  roomName: string;
  password: string;
  width: any;
  height: any;
  parentNode: Element;
  userInfo?: {
    email: string;
    displayName: string;
  };
  onload?: (event: Event) => void;
  onPasswordRequired?: (result: any) => void;
  onReadyToClose?: (result: any) => void;
  onParticipantRoleChanged?: (result: any) => void;
  onParticipantJoined?: (result: any) => void;
  onParticipantLeft?: (result: any) => void;
  onVideoConferenceJoined?: (result: any) => void;
  onVideoConferenceLeft?: (result: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class JitsiService extends ApiService {
  JitsiMeetExternalAPI = 'JitsiMeetExternalAPI';
  api: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
  }

  createMeeting(options: JitsiOptions): void {
    loadJS('https://meet.jit.si/external_api.js');

    let passwordAttempted = false;

    const domain = 'meet.jit.si';

    const passwordRequiredCallback = (result: any) => {
      if (!passwordAttempted) {
        this.api.executeCommand('password', options.password);
      }
      passwordAttempted = true;
      if (options.onPasswordRequired) {
        options.onPasswordRequired(result);
      }
    };

    const participantRoleChangedCallback = (result: any) => {
      if (result.role === 'moderator') {
        this.api.executeCommand('password', options.password);
        this.api.executeCommand('subject', ' ');
      }
      if (options.onParticipantRoleChanged) {
        options.onParticipantRoleChanged(result);
      }
    };

    const participantLeftCallback = (result: any) => {
      options.onParticipantLeft({
        id: result.id,
        displayName: this.api.getDisplayName(result.id)
      });
    };

    const onloadCallback = (event: Event) => {
      if (options.onload) {
        options.onload(event);
      }
      this.api.addEventListener('passwordRequired', passwordRequiredCallback);
      this.api.addEventListener('participantRoleChanged', participantRoleChangedCallback);
      if (options.onParticipantLeft) {
        this.api.addEventListener('participantLeft', participantLeftCallback);
      }
      if (options.onReadyToClose) {
        this.api.addEventListener('readyToClose', options.onReadyToClose);
      }
      if (options.onParticipantJoined) {
        this.api.addEventListener('participantJoined', options.onParticipantJoined);
      }
      if (options.onVideoConferenceJoined) {
        this.api.addEventListener(
          'videoConferenceJoined',
          options.onVideoConferenceJoined
        );
      }
      if (options.onVideoConferenceLeft) {
        this.api.addEventListener('videoConferenceLeft', options.onVideoConferenceLeft);
      }
    };

    const nativeJitsiOptions = {
      roomName: options.roomName,
      width: options.width,
      height: options.height,
      parentNode: options.parentNode,
      userInfo: options.userInfo,
      onload: onloadCallback
    };

    const loadAPI = () => {
      if (window[this.JitsiMeetExternalAPI]) {
        this.api = new JitsiMeetExternalAPI(domain, nativeJitsiOptions);
      } else {
        setTimeout(() => loadAPI(), 1000);
      }
    };

    loadAPI();
  }

  endMeeting(): void {
    this.api.executeCommand('hangup');
  }
}
