import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mon-graph-bar',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './graph-bar.component.html',
  styleUrls: ['./graph-bar.component.scss']
})
export class GraphBarComponent implements OnInit, AfterViewInit {
  @Input() data: number[] = [];
  @Input() colors: string[] = [];
  @Input() tooltips: string[] = [];

  maxDataValue = 0;
  dataPercentages = new Map<number, number>();

  ngOnInit(): void {
    if (this.data) {
      this.maxDataValue = Math.max(...this.data);
      this.data.forEach((d) => (this.dataPercentages[d] = 0));
    }
  }

  ngAfterViewInit(): void {
    if (this.data) {
      setTimeout(
        () =>
          this.data.forEach(
            (d) => (this.dataPercentages[d] = (d * 100) / this.maxDataValue)
          ),
        0
      );
    }
  }
}
