import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { TriggerCategory } from 'src/app/shared/enums/trigger-category.enum';
import { Agent, CCAgent } from 'src/app/shared/models/agent.model';
import {
  HistoryDate,
  HistoryHourDetail,
  HistoryHourSummary
} from 'src/app/shared/models/history.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { RemoteeCollection } from 'src/app/shared/models/session.model';
import { ApiService, NoContent } from './api.service';

export enum MetricType {
  SessionMetrics = 1 << 0,
  BatteryMetrics = 1 << 1,
  DiskMetrics = 1 << 2,
  SystemMetrics = 1 << 3,
  GPUMetrics = 1 << 4,
  ServiceMetrics = 1 << 5,
  MemoryMetrics = 1 << 6,
  NetworkMetrics = 1 << 7,
  IPMetrics = 1 << 8,
  ProcessMetrics = 1 << 9,
  AllMetrics = SessionMetrics |
    BatteryMetrics |
    DiskMetrics |
    SystemMetrics |
    GPUMetrics |
    ServiceMetrics |
    MemoryMetrics |
    NetworkMetrics |
    IPMetrics |
    ProcessMetrics
}

interface DownloadMeta {
  version: string;
  url: string;
  fileName: string;
  sha256: string;
}

@Injectable({ providedIn: 'root' })
export class AgentService extends ApiService {
  constructor(
    private http: HttpClient,
    triggerSvc: TriggerService
  ) {
    super();

    triggerSvc.cacheTriggers();
  }

  requestAgentToGoLive(
    agentID: string,
    metricType = MetricType.AllMetrics
  ): Observable<NoContent> {
    const url = `${this.apiUrl}/agent/${agentID}/live/start?metricType=${metricType}`;
    const start = performance.now();
    return this.http.get(url).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.requestAgentToGoLive [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      catchError((err) => {
        this.handleError('AgentService.requestAgentToGoLive');
        return throwError(() => err);
      })
    );
  }

  getLiveAgent(
    agentID: string,
    listen = false,
    newerThan: Date = new Date(0)
  ): Observable<Agent> {
    const url = `${this.apiUrl}/agent/${agentID}/live`;
    const params = {};
    if (newerThan) {
      Object.assign(params, { newerThan: newerThan.toISOString() });
    }
    if (listen) {
      Object.assign(params, { listen });
    }
    const start = performance.now();
    return this.http.get<Agent>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getLiveAgent [${agentID}] [${newerThan.toISOString()}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) =>
        response ? new Agent(Object.assign(response, { isLive: true })) : undefined
      ),
      catchError((err) => {
        this.handleError<Agent>('AgentService.getLiveAgent');
        return throwError(() => err);
      })
    );
  }

  getAll(
    limit: number,
    page: number,
    orderBy: OrderBy[]
  ): Observable<PagedResult<Agent>> {
    const url = `${this.apiUrl}/agent`;
    const params = {};
    Object.assign(params, {
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Agent>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(
          `fetched AgentService.getAll response in ${performance.now() - start}ms`
        );
      }),
      map((response) =>
        response
          ? new PagedResult<Agent>(Agent, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Agent>>('AgentService.getAll');
        return throwError(() => err);
      })
    );
  }

  getByID(agentID: string, search = false): Observable<Agent> {
    const url = `${this.apiUrl}/agent/${agentID}`;
    const params = {};
    if (search) {
      Object.assign(params, { search });
    }
    const start = performance.now();
    return this.http.get<Agent>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getByID [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? new Agent(response) : undefined)),
      catchError((err) => {
        this.handleError<Agent>('AgentService.getByID');
        return throwError(() => err);
      })
    );
  }

  listenForFirstAgent(): Observable<Agent> {
    const url = `${this.apiUrl}/agent/first`;
    const params = {};
    return this.http.get<Agent>(url, { params }).pipe(
      map((response) => (response ? new Agent(response) : undefined)),
      catchError((err) => {
        this.handleError<Agent>('AgentService.listenForFirstAgent');

        return throwError(() => err);
      })
    );
  }

  getOutgoingRemotees(
    agentID: string,
    newerThan: Date = new Date(0)
  ): Observable<RemoteeCollection> {
    const url = `${this.apiUrl}/agent/${agentID}/outgoing-sessions`;
    const params = {};
    if (newerThan) {
      Object.assign(params, { newerThan: newerThan.toISOString() });
    }
    const start = performance.now();
    return this.http.get<RemoteeCollection>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getOutgoingSessions [${agentID}] [${newerThan.toISOString()}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? new RemoteeCollection(response) : undefined)),
      catchError((err) => {
        this.handleError<RemoteeCollection>('AgentService.getOutgoingSessions');
        return throwError(() => err);
      })
    );
  }

  getHistoryAllDates(agentID: string): Observable<Array<HistoryDate>> {
    const url = `${this.apiUrl}/agent/${agentID}/history/date`;
    const start = performance.now();
    return this.http.get<Array<HistoryDate>>(url).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getHistoryAllDates [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => response.map((d) => new HistoryDate(d))),
      catchError((err) => {
        this.handleError<Array<HistoryDate>>('AgentService.getHistoryAllDates');
        return throwError(() => err);
      })
    );
  }

  getHistoryDates(
    agentID: string,
    begin: Date,
    end: Date
  ): Observable<Array<HistoryDate>> {
    const url = `${this.apiUrl}/agent/${agentID}/history/date/range`;
    const params = {
      b: begin.toISOString(),
      e: end.toISOString()
    };
    const start = performance.now();
    return this.http.get<Array<HistoryDate>>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getHistoryDates [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response: Array<unknown>) => {
        // the response will come back as UTC formatted maps - we will need to convert to local timezone
        const historyDates = new Map<string, HistoryDate>();

        response.map((date) => {
          const dateID = date['id'];
          const dateHours = <Array<string>>date['hours'];
          const dateAlarmsByHour = <unknown>date['alarmsByHour'];

          for (let i = 0; i < dateHours.length; i++) {
            const hour = dateHours[i];
            const d = `${dateID}`;
            const localTimestamp = new Date(
              Date.parse(
                `${d.substr(0, 4)}-` +
                  `${d.substr(4, 2)}-` +
                  `${d.substr(6, 2)}T` +
                  `${hour}:00:00Z`
              )
            );

            // ignore any hour not in this local timezone
            const removeThisHour =
              localTimestamp.getTime() < begin.getTime() ||
              localTimestamp.getTime() > end.getTime();

            if (removeThisHour) {
              continue;
            } else {
              const adjustedHourID = String(localTimestamp.getHours()).padStart(2, '0'); //adjusted to local timezone
              const adjustedDateID =
                `${localTimestamp.getFullYear()}` +
                `${String(localTimestamp.getMonth()).padStart(2, '0')}` +
                `${String(localTimestamp.getDate()).padStart(2, '0')}`;

              const hourEntry = new HistoryHourSummary({
                refHourID: hour,
                refDateID: dateID,
                id: adjustedHourID,
                dateID: adjustedDateID,
                timestamp: localTimestamp
              });

              const dateEntry =
                historyDates.get(adjustedDateID) ||
                new HistoryDate({
                  id: adjustedDateID,
                  timestamp: localTimestamp,
                  alarms: new Map<TriggerCategory, Map<string, number>>(),
                  hourlySummaries: []
                });

              const found = dateAlarmsByHour && dateAlarmsByHour[hour];

              if (found) {
                const foundAlarms = found['alarms'];
                const hourAlarms = new Map<TriggerCategory, Map<string, number>>();

                // add hourly totals to daily totals
                Object.keys(foundAlarms).forEach((foundCategoryKey: TriggerCategory) => {
                  const foundCategory = foundAlarms[foundCategoryKey];

                  const hourCategory =
                    hourAlarms.get(foundCategoryKey) || new Map<string, number>();

                  const totalCategory =
                    dateEntry.alarms.get(foundCategoryKey) || new Map<string, number>();

                  Object.keys(foundCategory).forEach((foundAlarmKey) => {
                    const foundAlarmCount = foundCategory[foundAlarmKey];

                    hourCategory.set(foundAlarmKey, foundAlarmCount);

                    totalCategory.set(
                      foundAlarmKey,
                      (totalCategory.get(foundAlarmKey) || 0) + foundAlarmCount
                    );
                  });

                  hourAlarms.set(foundCategoryKey, hourCategory);
                  dateEntry.alarms.set(foundCategoryKey, totalCategory);
                });

                hourEntry.alarms = hourAlarms;
              }

              dateEntry.hourlySummaries.push(hourEntry);

              historyDates.set(adjustedDateID, dateEntry);
            }
          }
        });

        return [...historyDates.values()];
      }),
      catchError((err) => {
        this.handleError<Array<HistoryDate>>('AgentService.getHistoryDates');
        return throwError(() => err);
      })
    );
  }

  getHistoryAllHours(agentID: string, dateID: string): Observable<HistoryDate> {
    const url = `${this.apiUrl}/agent/${agentID}/history/date/${dateID}/hour`;
    const params = {};
    const start = performance.now();
    return this.http.get<HistoryDate>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getHistoryAllHours [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => new HistoryDate(response)),
      catchError((err) => {
        this.handleError<HistoryDate>('AgentService.getHistoryAllHours');
        return throwError(() => err);
      })
    );
  }

  getHistoryHourDetail(
    agentID: string,
    dateID: string,
    adjustedHourID: string
  ): Observable<HistoryHourDetail> {
    const url = `${this.apiUrl}/agent/${agentID}/history/date/${dateID}/hour/${adjustedHourID}`;
    const params = {};
    const start = performance.now();
    return this.http.get<HistoryHourDetail>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getHistoryHourDetail [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => new HistoryHourDetail(Object.assign({ agentID }, response))),
      catchError((err) => {
        this.handleError<HistoryHourDetail>('AgentService.getHistoryHourDetail');
        return throwError(() => err);
      })
    );
  }

  getHistoryAgent(agentID: string, time: Date): Observable<Agent> {
    const url = `${this.apiUrl}/agent/${agentID}/history`;
    const params = {
      p: time.toISOString()
    };
    const start = performance.now();
    return this.http.get<Agent>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getHistoryAgent [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? new Agent(response) : undefined)),
      catchError((err) => {
        this.handleError<Agent>('AgentService.getHistoryAgent');
        return throwError(() => err);
      })
    );
  }

  getHistoryAgentLatest(agentID: string): Observable<Agent> {
    const url = `${this.apiUrl}/agent/${agentID}/history/latest`;
    const params = {};
    const start = performance.now();
    return this.http.get<Agent>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getHistoryAgentLatest [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? new Agent(response) : undefined)),
      catchError((err) => {
        this.handleError<Agent>('AgentService.getHistoryAgentLatest');
        return throwError(() => err);
      })
    );
  }

  getVersion(os: string, arch: string): Observable<CCAgent> {
    const url = `${this.apiUrl}/agent/version?os=${os}&arch=${arch}`;
    const start = performance.now();

    return this.http.get<CCAgent>(url).pipe(
      tap(() =>
        this.log(
          `fetched AgentService.getVersion response in ${performance.now() - start}ms`
        )
      ),
      map((response) => (response ? new CCAgent(response) : undefined)),
      catchError((err) => {
        this.handleError<CCAgent>('AgentService.getVersion');
        return throwError(() => err);
      })
    );
  }

  getDownloadInfo(os: 'macos' | 'windows'): Observable<DownloadMeta> {
    const url = `${this.apiUrl}/agent/downloadMeta?os=${os}`;

    return this.http.get<DownloadMeta>(url).pipe(
      catchError((err) => {
        this.handleError<CCAgent>('AgentService.getVersion');
        return throwError(() => err);
      })
    );
  }

  removeLicense(
    agentID: string,
    includeMachineID: boolean,
    includeIP: boolean,
    blockFor: number,
    blockUntil: Date,
    uninstall: boolean,
    blockIndefinitely: boolean
  ): Observable<NoContent> {
    const url = `${this.apiUrl}/agent/${agentID}/license/remove`;
    return this.http
      .patch(url, {
        includeMachineID,
        includeIP,
        blockFor,
        blockUntil,
        uninstall,
        blockIndefinitely
      })
      .pipe(
        tap(() => this.log(`fetched AgentService.removeLicense [${agentID}]response`)),
        map(() => new NoContent()),
        catchError((err) => {
          this.handleError('AgentService.removeLicense');
          return throwError(() => err);
        })
      );
  }
}
