import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject, finalize, map } from 'rxjs';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { PostalService } from 'src/app/core/services/postal.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { SubscribeService } from 'src/app/core/services/subscribe.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { SubscriptionComponent } from 'src/app/shared/components/subscription/subscription.component';
import { TenantInvite } from 'src/app/view/page/login/login.component';

@Component({
  selector: 'mon-confirm',
  standalone: true,
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  animations: [fadeInUp400ms, fadeInRight80ms],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    SubscriptionComponent
  ]
})
export class ConfirmComponent {
  invite = '';
  userConfirmed = false;
  token = '';

  private _loading = new BehaviorSubject<boolean>(true);
  loading$ = this._loading.asObservable();

  private _inviteError = new BehaviorSubject<string>(undefined);
  inviteError$ = this._inviteError.asObservable();

  private _tenantInvite = new BehaviorSubject<TenantInvite>(undefined);
  tenantInvite$ = this._tenantInvite.asObservable();

  constructor(
    protected dialog: MatDialog,
    protected subscribeSvc: SubscribeService,
    protected postalSvc: PostalService,
    private router: Router,
    private registrationSvc: RegistrationService,
    private authSvc: AuthService
  ) {
    const nav = this.router.getCurrentNavigation();
    if (nav) {
      this.invite = nav.extras.state?.invite;
      this.userConfirmed = nav.extras.state?.userConfirmed || false;
      this.token = nav.extras.state?.token;
      this.registrationSvc
        .getTenantRegistration(this.invite)
        .pipe(
          map((ti) => this._tenantInvite.next(ti)),
          finalize(() => {
            this._loading.next(false);
          })
        )
        .subscribe({
          next: () => {
            if (this.userConfirmed && this.token) {
              // go straight to registering the tenant with this invite and token
              this.confirmTenant();
            }
          },
          error: (err: HttpErrorResponse) => {
            switch (err.status) {
              case 404:
                this._inviteError.next('invalid invite provided');
                break;
              default:
                this._inviteError.next(parseError(err));
                break;
            }
          }
        });
    }
  }

  confirmTenant(): void {
    if (this._tenantInvite.value.userExists && !this.userConfirmed) {
      this.authSvc.loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}`
        },
        appState: {
          target: `?userConfirmed=true&invite=${this._tenantInvite.value.inviteKey}`
        }
      });
      return;
    }
  }

  goToSignin(): void {
    this.router.navigate(['signin'], { skipLocationChange: true });
  }
}
