<ng-container *ngIf="locality$ | async"></ng-container>

<ng-container *ngIf="initialLoad">
  <div [formGroup]="formGroup" class="flex flex-col">
    <mat-form-field>
      <mat-label>Country</mat-label>
      <mat-select required formControlName="country" #countryInput (selectionChange)="countrySelected($event)">
        <mat-option *ngFor="let c of countries" [value]="c.name">
          <span class="flag-icon" [ngClass]="'flag-icon-' + c.iso2.toLowerCase()"></span>
          {{ c.name }}
        </mat-option>
      </mat-select>
      <span
        class="flag-icon -top-1 mr-3 ml-1"
        [ngClass]="'flag-icon-' + formGroup.controls.countryCode.value?.toLowerCase() || ''"
        matPrefix
      ></span>
      <mat-error *ngIf="formGroup.controls.country.errors?.invalidCountry">Please select a valid country from the list</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="formGroup.controls.region">
      <mat-label>State/Province</mat-label>
      <ng-container *ngIf="country$ | async as countryCode">
        <ng-container *ngIf="countryCode === 'US' || countryCode === 'CA'; else showFreeInput">
          <mat-select required formControlName="region" #regionInput (selectionChange)="regionSelected($event)">
            <mat-option *ngFor="let r of regions" [value]="r.name">
              {{ r.name }}
            </mat-option>
          </mat-select>
        </ng-container>
        <ng-template #showFreeInput>
          <input
            [required]="formGroup.controls.region.hasValidator(validators.required)"
            formControlName="region"
            matInput
            [matAutocomplete]="regionInput"
            [readonly]="readonly"
            (blur)="onRegionBlur(regionInput)"
          />
          <mat-autocomplete
            tabSelected
            autoActiveFirstOption
            autoSelectActiveOption
            #regionInput="matAutocomplete"
            (optionSelected)="regionSelected($event)"
          >
            <mat-option *ngFor="let s of filteredRegions$ | async" [value]="s.name">
              {{ s.name }}
            </mat-option>
          </mat-autocomplete>
        </ng-template>
      </ng-container>
    </mat-form-field>
    <div @fadeInRight class="flex gap-2 justify-between">
      <mat-form-field *ngIf="type !== 'register' && formGroup.controls.locality" class="flex-1">
        <mat-label>City</mat-label>
        <input formControlName="locality" matInput [matAutocomplete]="cityInput" [readonly]="readonly" />
        <mat-autocomplete tabSelected autoActiveFirstOption autoSelectActiveOption #cityInput="matAutocomplete">
          <mat-option *ngFor="let c of filteredLocalities$ | async" [value]="c.name">
            {{ c.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <mat-label>ZIP Code</mat-label>
        <input
          [required]="formGroup.controls.postcode.hasValidator(validators.required)"
          formControlName="postcode"
          matInput
          [readonly]="readonly"
          #zip
        />
        <mat-error *ngIf="formGroup.controls.postcode.errors?.invalid_zip_code">Invalid ZIP Code</mat-error>
      </mat-form-field>
    </div>
    <ng-container *ngIf="type !== 'register'">
      <mat-form-field @fadeInRight *ngIf="formGroup.controls.address1">
        <mat-label>Address Line 1</mat-label>
        <input formControlName="address1" matInput [readonly]="readonly" />
        <fa-icon [icon]="['fad', 'map-marker-alt']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
      </mat-form-field>
      <mat-form-field @fadeInRight *ngIf="formGroup.controls.address2">
        <mat-label>Address Line 2</mat-label>
        <input formControlName="address2" matInput [readonly]="readonly" />
      </mat-form-field>
      <mat-form-field @fadeInRight *ngIf="formGroup.controls.address3">
        <mat-label>Address Line 3</mat-label>
        <input formControlName="address3" matInput [readonly]="readonly" />
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>
