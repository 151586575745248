import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {
  transform(value: number, imperial?: boolean): string {
    if (imperial) {
      value = Math.round((value * 0.621371 + Number.EPSILON) * 100) / 100;
      return `${value} mi`;
    } else {
      value = Math.round((value + Number.EPSILON) * 100) / 100;
      return `${value} km`;
    }
  }
}
