import { Updatable } from '../interfaces/updatable.interface';

export class GPUSummary implements Updatable<GPUSummary> {
  public metrics: Array<GPUMetrics>;

  constructor(init?: Partial<GPUSummary>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    if (this.metrics) {
      this.metrics = this.metrics.map((e) => new GPUMetrics(e));
    }
  }

  update(input: Partial<GPUSummary>): this {
    return Object.assign(this, input);
  }
}

export class GPUMetrics implements Updatable<GPUMetrics> {
  public adapterName: string;
  public physicalAdapterIndex: number;
  public usagePercent: number;
  public fanRPM: number;
  public temperature: number;
  public power: number;
  public dedicatedUsage: number;
  public sharedUsage: number;

  constructor(init?: Partial<GPUMetrics>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<GPUMetrics>): this {
    return Object.assign(this, input);
  }
}
