<div class="toolbar w-full px-2 flex justify-start items-center content-center" vexContainer>
  <button (click)="openSidenav()" [hidden]="!mobileQuery" mat-icon-button type="button" class="sm-icon-button">
    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="mr-4 flex justify-start items-center content-center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1 [hidden]="mobileQuery" class="title pl-4 m-0 select-none">VEX</h1>
  </a>

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <fa-icon [icon]="['fad', 'user-plus']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <fa-icon [icon]="['fad', 'check-double']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <fa-icon [icon]="['fad', 'clipboard-check']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <fa-icon [icon]="['fad', 'ballot']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <fa-icon [icon]="['fad', 'file-alt']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <fa-icon [icon]="['fad', 'clipboard-list']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <fa-icon [icon]="['fad', 'receipt']" size="lg" [fixedWidth]="true"></fa-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div #megaMenuOriginRef class="ml-2">
    <button
      *ngIf="isHorizontalLayout$ | async"
      (click)="openMegaMenu(megaMenuOriginRef)"
      data-intro-key="recent-nav"
      class="h-12"
      color="primary"
      mat-button
      type="button"
    >
      RECENT
      <fa-icon class="-mr-1" inline="true" [icon]="['fad', 'caret-down']" [fixedWidth]="true"> </fa-icon>
    </button>
  </div>

  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [hidden]="mobileQuery"
    class="px-gutter flex flex-none justify-start items-center content-center"
  >
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="flex items-center justify-center gap-1">
    <div
      *hasPerm="[permission.Search_Invoke]"
      (click)="openSearch()"
      data-intro-key="search-button"
      class="flex items-center justify-center"
    >
      <span class="hidden sm:inline text-gray-400 cursor-default md:pr-2">press "/" to search</span>
      <button mat-icon-button type="button" class="sm-icon-button">
        <fa-icon [icon]="['fad', 'search']" class="text-primary"></fa-icon>
      </button>
    </div>

    <ng-container *ngIf="tenantInvites$ | async as invites">
      <a
        @fadeInUp
        @fadeOutDown
        *ngIf="invites.length > 0"
        data-intro-key="tenant-invite-alert"
        [routerLink]="['me', 'general']"
        [queryParamsHandling]="'preserve'"
        mat-icon-button
        type="button"
        matTooltip="You have been invited to join another tenant"
        class="sm-icon-button"
      >
        <div class="inline-flex">
          <fa-icon [icon]="['fad', 'envelope-open-text']" class="text-green inline-flex"></fa-icon>
          <fa-icon [icon]="['fad', 'envelope-open-text']" class="text-green inline-flex animate-ping absolute"> </fa-icon>
        </div>
      </a>
    </ng-container>

    <!-- <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <fa-icon [icon]="['fad', 'bookmark']" class="text-primary" size="lg"></fa-icon>
      </button>
    </div> -->

    <div>
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <!-- <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item>
        <mat-icon [icIcon]="emojioneUS"></mat-icon>
        <span>English</span>
      </button>

      <button mat-menu-item>
        <mat-icon [icIcon]="emojioneDE"></mat-icon>
        <span>German</span>
      </button>
    </mat-menu> -->
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [hidden]="mobileQuery"> </vex-navigation>
