import { Sort } from '@angular/material/sort';

export class OrderBy {
  public dir: number;
  public path: string;

  constructor(init?: Partial<OrderBy>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  static SortToOrderBy(sort: Sort): OrderBy {
    if (sort) {
      let dir = 0;
      switch (sort.direction) {
        case 'asc':
          dir = 1;
          break;
        case 'desc':
          dir = 2;
          break;
      }
      return new OrderBy({
        dir,
        path: sort.active
      });
    } else {
      return new OrderBy({
        dir: 0,
        path: ''
      });
    }
  }

  static OrderByToString(orderBy: OrderBy[]): string {
    return orderBy
      ? orderBy
          .filter((o) => o && o.dir > 0 && o.dir < 3)
          .map((o) => (o ? `${o.dir === 1 ? 'asc' : 'desc'}(${o.path})` : ''))
          .join(',')
      : '';
  }

  static Sort<T>(value: T[], orderBy: OrderBy): T[] {
    if (orderBy.dir === 0) {
      return value;
    }

    const compare = (
      a: string | number | Date,
      b: string | number | Date,
      isAsc: boolean
    ): number => {
      if (a instanceof Date && b instanceof Date) {
        return (a.getTime() - b.getTime()) * (isAsc ? 1 : -1);
      } else {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }
    };

    return value.sort((a, b) =>
      compare(a[orderBy.path], b[orderBy.path], orderBy.dir === 1)
    );
  }
}
