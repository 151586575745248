import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSpan'
})
export class TimeSpanPipe implements PipeTransform {
  transform(
    totalMilliseconds: number,
    format: 'long' | 'medium' | 'short' = 'long'
  ): unknown {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    const SecondInMS = 1000; // seconds
    const MinuteInMS = SecondInMS * 60; // minutes
    const HourInMs = MinuteInMS * 60; // hours
    const daysInMS = HourInMs * 24; // days

    days = Math.floor(totalMilliseconds / daysInMS);
    const dayRem = totalMilliseconds % daysInMS;

    if (dayRem > 0) {
      hours = Math.floor(dayRem / HourInMs);
      const hourRem = dayRem % HourInMs;

      if (hourRem > 0) {
        minutes = Math.floor(hourRem / MinuteInMS);
        const minuteRem = hourRem % MinuteInMS;

        if (minuteRem > 0) {
          seconds = Math.floor(minuteRem / SecondInMS);
        }
      }
    }

    switch (format) {
      case 'short':
        const s: string[] = [];
        if (days > 0) {
          s.push(`${days}d`);
        }
        if (hours > 0) {
          s.push(`${hours}h`);
        }
        if (minutes > 0) {
          s.push(`${minutes}m`);
        }
        if (seconds > 0) {
          s.push(`${seconds}s`);
        }

        if (s.length > 0) {
          return s.join(' ');
        }
        break;
      case 'medium':
        if (days < 1) {
          if (hours < 1) {
            if (minutes < 1) {
              return `${seconds}s`;
            } else {
              return `${minutes}m ${seconds}s`;
            }
          } else {
            return `${hours}h ${minutes}m ${seconds}s`;
          }
        }
        break;
    }
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
}
