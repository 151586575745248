import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { fadeInRight40ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { Invite } from 'src/app/core/services/user.service';
import { propertyByString } from 'src/app/core/utils/property-by-string';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { User } from 'src/app/shared/models/user.model';
import { isEmpty } from 'src/app/utils/condition';

import { InvitesDataSource } from './invites.datasource';
import { UserEditComponent, UserEditParams } from './user-edit/user-edit.component';
import { UsersDataSource } from './users.datasource';

interface NewUser {
  id?: string;
  email: string;
}

@UntilDestroy()
@Component({
  selector: 'mon-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [fadeInRight40ms, fadeInUp400ms, stagger20ms]
})
export class UsersComponent implements OnInit {
  readonly DEFAULT_PAGE_SIZE = 20;
  readonly DEFAULT_SORT_FIELD = 'email';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  userTableID = tableNames.SETTINGS_USERS;
  userPageIndex = 0;
  userPageSizeOptions: number[] = [5, 10, 20, 50];
  userPageSize = this.DEFAULT_PAGE_SIZE;
  userSortField = this.DEFAULT_SORT_FIELD;
  userSortDirection = this.DEFAULT_SORT_DIRECTION;
  userDataSource: UsersDataSource = new UsersDataSource(this.tenantSvc);
  userColumns: TableColumn<User>[] = [
    {
      label: 'ID',
      property: 'id',
      type: 'text',
      visible: false,
      hideable: false,
      cellClasses: ['truncate']
    },
    {
      label: 'Email',
      property: 'email',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Roles',
      property: 'rolesD',
      display: 'roles',
      type: 'text',
      sortable: false,
      visible: true,
      hideable: true,
      cellClasses: ['max-w-8', 'truncate']
    },
    {
      label: 'Updated',
      property: 'updated',
      dateFormat: 'timeAgo',
      type: 'date',
      visible: false,
      hideable: true
    },
    {
      label: 'Added',
      property: 'added',
      type: 'date',
      dateFormat: 'timeAgo',
      visible: true,
      hideable: true,
      cellClasses: ['max-w-8', 'truncate']
    },
    {
      label: 'Last Login',
      property: 'lastLoginTimestamp',
      type: 'date',
      visible: true,
      hideable: true,
      cellClasses: ['max-w-10', 'truncate']
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      hideable: false,
      buttonIconName: 'angle-right',
      tooltip: 'View',
      cellClasses: ['max-w-5', 'truncate']
    }
  ];

  userDisplayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.userTableID}`,
        show: true,
        fields: this.hideableUserColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleUserColumns$ = this.userDisplayConfig$.pipe(
    map((config) =>
      this.userColumns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  initialLoading$ = this.userDataSource.loading$.pipe(startWith(true), take(3));

  inviteTableID = tableNames.SETTINGS_INVITES;
  invitePageIndex = 0;
  invitePageSizeOptions: number[] = [5, 10, 20, 50];
  invitePageSize = this.DEFAULT_PAGE_SIZE;
  inviteSortField = this.DEFAULT_SORT_FIELD;
  inviteSortDirection = this.DEFAULT_SORT_DIRECTION;
  inviteDataSource: InvitesDataSource = new InvitesDataSource(this.tenantSvc);
  inviteColumns: TableColumn<Invite>[] = [
    {
      label: 'ID',
      property: 'id',
      type: 'text',
      visible: false,
      hideable: false,
      cellClasses: ['truncate']
    },
    {
      label: 'Email',
      property: 'email',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Roles',
      property: 'roles',
      display: 'roles',
      type: 'textArray',
      sortable: false,
      visible: true,
      hideable: true,
      cellClasses: ['max-w-8', 'truncate']
    },
    {
      label: 'Invited',
      property: 'timestamp',
      type: 'date',
      visible: true,
      hideable: true
    },
    {
      label: 'Status',
      property: 'rejected',
      type: 'text',
      textFn: (i) => (i.rejected ? 'Declined' : 'Pending'),
      visible: true,
      hideable: true
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'buttonArray',
      visible: true,
      hideable: false,
      buttons: [
        {
          icon: 'paper-plane-top',
          tooltip: 'Resend Invite',
          fn: this.resendInvite.bind(this),
          buttonClasses: ['hover:bg-primary-light'],
          iconClasses: ['text-primary'],
          showConditionFn: (i) => !i.rejected
        },
        {
          icon: 'times',
          tooltip: 'Delete Invite',
          fn: this.deleteInvite.bind(this),
          buttonClasses: ['hover:bg-red-light'],
          iconClasses: ['text-red']
        }
      ]
    }
  ];

  inviteDisplayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.inviteTableID}`,
        show: true,
        fields: this.hideableInviteColumns.map(
          (c) => new DisplayField(c.label, c.visible)
        )
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleInviteColumns$ = this.inviteDisplayConfig$.pipe(
    map((config) =>
      this.inviteColumns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  userTablesettings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const userSettings = settings.consoleUI.table[this.userTableID];

      return {
        userPageSize: userSettings?.pageSize || this.DEFAULT_PAGE_SIZE,
        userSortField: userSettings?.sortField || this.DEFAULT_SORT_FIELD,
        userSortDirection: userSettings?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.userPageSize = isEmpty(settings.userPageSize)
        ? this.DEFAULT_PAGE_SIZE
        : settings.userPageSize;
      this.userSortField = isEmpty(settings.userSortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.userSortField;
      this.userSortDirection = isEmpty(settings.userSortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.userSortDirection;
    }),
    tap(() => this.loadUsers())
  );

  inviteTablesettings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const inviteSettings = settings.consoleUI.table[this.inviteTableID];

      return {
        invitePageSize: inviteSettings?.pageSize || this.DEFAULT_PAGE_SIZE,
        inviteSortField: inviteSettings?.sortField || this.DEFAULT_SORT_FIELD,
        inviteSortDirection: inviteSettings?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.invitePageSize = isEmpty(settings.invitePageSize)
        ? this.DEFAULT_PAGE_SIZE
        : settings.invitePageSize;
      this.inviteSortField = isEmpty(settings.invitePageSize)
        ? this.DEFAULT_SORT_FIELD
        : settings.inviteSortField;
      this.inviteSortDirection = isEmpty(settings.inviteSortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.inviteSortDirection;
    }),
    tap(() => this.loadInvites())
  );

  userControlsPopulated = (): boolean => !!this.userPaginator && !!this.userSort;
  userPaginator: MatPaginator;
  @ViewChild('userPaginator') set userPaginatorSetter(paginator: MatPaginator) {
    if (!this.userPaginator && !!paginator) {
      this.userPaginator = paginator;
      this.initControls();
    }
  }
  userSort: MatSort;
  @ViewChild('userSort') set userSortSetter(sort: MatSort) {
    if (!this.userSort && !!sort) {
      this.userSort = sort;
      this.initControls();
    }
  }

  inviteControlsPopulated = (): boolean => !!this.invitePaginator && !!this.inviteSort;
  invitePaginator: MatPaginator;
  @ViewChild('invitePaginator') set invitePaginatorSetter(paginator: MatPaginator) {
    if (!this.invitePaginator && !!paginator) {
      this.invitePaginator = paginator;
      this.initControls();
    }
  }
  inviteSort: MatSort;
  @ViewChild('inviteSort') set inviteSortSetter(sort: MatSort) {
    if (!this.inviteSort && !!sort) {
      this.inviteSort = sort;
      this.initControls();
    }
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private tenantSvc: TenantService,
    private stateSvc: StateService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof ActivationEnd),
        map((e) => e as ActivationEnd),
        take(1),
        tap((e) => {
          const requestEmail = e.snapshot.queryParamMap.get('request');
          if (requestEmail) {
            this.router.navigate([location.pathname], {
              queryParams: {
                request: null
              },
              queryParamsHandling: 'merge'
            });

            this.modUser({ email: requestEmail });
          }
        })
      )
      .subscribe();
  }

  initControls(): void {
    if (this.userControlsPopulated()) {
      this.userSort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.userTableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();

      this.userPaginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            let refreshData = true;
            this.userPageIndex = page.pageIndex;
            if (this.userPageSize !== page.pageSize) {
              refreshData = false; // updating currentUserSettings will handle this
              const table = {};
              table[this.userTableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            }

            if (refreshData) {
              this.loadUsers();
            }
          })
        )
        .subscribe();
    }

    if (this.inviteControlsPopulated()) {
      this.inviteSort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.inviteTableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();

      this.invitePaginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            let refreshData = true;
            this.invitePageIndex = page.pageIndex;
            if (this.invitePageSize !== page.pageSize) {
              refreshData = false; // updating currentUserSettings will handle this
              const table = {};
              table[this.inviteTableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            }

            if (refreshData) {
              this.loadInvites();
            }
          })
        )
        .subscribe();
    }
  }

  loadUsers(): void {
    this.userDataSource.loadUsers('', this.userPageSize, this.userPageIndex, [
      OrderBy.SortToOrderBy({
        active: this.userSortField,
        direction: this.userSortDirection
      })
    ]);
  }

  loadInvites(): void {
    this.inviteDataSource.loadInvites('', this.invitePageSize, this.invitePageIndex, [
      OrderBy.SortToOrderBy({
        active: this.inviteSortField,
        direction: this.inviteSortDirection
      })
    ]);
  }

  get hideableUserColumns(): TableColumn<User>[] {
    return this.userColumns.filter((c) => c.hideable);
  }

  get hideableInviteColumns(): TableColumn<Invite>[] {
    return this.inviteColumns.filter((c) => c.hideable);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  getValue(row: User, property: string): string {
    return propertyByString(row, property);
  }

  modUser(user?: User | NewUser): void {
    this.dialog.open(UserEditComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new UserEditParams({
        userID: user ? user.id : null,
        userEmail: user ? user.email : null,
        onSuccess: (result) => (result === 'edit' ? this.loadUsers() : this.loadInvites())
      }),
      width: '600px'
    });
  }

  getRoles(invite: Invite): string {
    return Object.keys(invite.roles).join(', ');
  }

  resendInvite(invite: Invite): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: `paper-plane`,
        title: `Resend invite to ${invite.email}?`,
        confirmationText: `Would you like to send another invite to this email?`,
        onConfirm: () => {
          this.tenantSvc.resendInvite(invite.id).subscribe();
        },
        onCancel: () => {}
      }
    });
  }

  deleteInvite(invite: Invite): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: `times`,
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: `Delete invite to ${invite.email}?`,
        confirmationText: `Would you like to delete this invite?`,
        onConfirm: () => {
          this.tenantSvc
            .deleteInvite(invite.id)
            .pipe(tap(() => this.loadInvites()))
            .subscribe();
        },
        onCancel: () => {}
      }
    });
  }
}
