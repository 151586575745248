import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Session } from 'src/app/shared/models/session.model';

import { ApiService, Response } from './api.service';

@Injectable({ providedIn: 'root' })
export class SystemService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAllSessions(agentID: string): Observable<Array<Session>> {
    const url = `${this.apiUrl}/agent/${agentID}/system/session`;
    return this.http.get<Array<Session>>(url).pipe(
      tap(() => this.log('fetched SystemService.getAllSessions response')),
      map((response) =>
        response
          ? response.filter((s) => Session.isUserSession(s)).map((s) => new Session(s))
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Session>>('SystemService.getAllSessions');
        return throwError(() => err);
      })
    );
  }

  logoffSession(agentID: string, sessionID: number, username = ''): Observable<Response> {
    const url = `${this.apiUrl}/agent/${agentID}/system/session/logoff`;
    return this.http.post<Response>(url, { sessionID, username }).pipe(
      tap(() => this.log('fetched SystemService.logoffSession response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('SystemService.logoffSession');
        return throwError(() => err);
      })
    );
  }

  logoffAllSessions(agentID: string): Observable<Response> {
    const url = `${this.apiUrl}/agent/${agentID}/system/session/logoffAll`;
    return this.http.post<Response>(url, {}).pipe(
      tap(() => this.log('fetched SystemService.logoffAllSessions response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('SystemService.logoffAllSessions');
        return throwError(() => err);
      })
    );
  }

  shutdown(agentID: string, timeout = 0, reboot = false): Observable<Response> {
    const url = `${this.apiUrl}/agent/${agentID}/system/shutdown`;
    return this.http.post<Response>(url, { reboot, timeout }).pipe(
      tap(() => this.log('fetched SystemService.shutdown response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('SystemService.shutdown');
        return throwError(() => err);
      })
    );
  }
}
