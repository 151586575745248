import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';

export class DebugSettingsParams {
  duration: number;
  onSuccess: boolean;
  constructor(init?: Partial<DebugSettingsParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-debug-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatDialogModule,
    MatOptionModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    ResultOverlayComponent,
    SpinnerComponent
  ],
  templateUrl: './debug-settings.component.html',
  styleUrls: ['./debug-settings.component.scss']
})
export class DebugSettingsComponent {
  duration = new FormControl(5);
  minutes = [0, 5, 10, 15, 20, 25, 30];
  spin = false;
  overlayResult = OverlayResult.Unset;
  err: string;

  constructor(
    public dialogRef: MatDialogRef<DebugSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public params: DebugSettingsParams
  ) {
    // Initialize the available duration options
    //this.minutes = Array.from({ length: 12 }, (_, i) => (i + 1) * 5);
  }

  onCancel(): void {
    const params = new DebugSettingsParams();
    params.duration = 0;
    params.onSuccess = false;
    this.dialogRef.close(params);
  }

  onConfirm(duration: number): void {
    const params = new DebugSettingsParams();
    params.duration = duration;
    params.onSuccess = true;

    this.dialogRef.close(params);
  }

  closeError(): void {
    this.err = '';
  }

  overlayClose(r: OverlayResult, result: number): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.dialogRef.close(result);
    }
  }
}
