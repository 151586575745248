import { Updatable } from '../interfaces/updatable.interface';

export enum ControlsAccepted {
  AcceptStop = 1,
  AcceptShutdown = 2,
  AcceptPauseAndContinue = 4,
  AcceptParamChange = 8,
  AcceptNetBindChange = 16,
  AcceptHardwareProfileChange = 32,
  AcceptPowerEvent = 64,
  AcceptSessionChange = 128
}

export enum ServiceType {
  KernelDriver = 1,
  FileSystemDriver = 2,
  StopPending = 4,
  Adapter = 8,
  RecognizerDriver = 16,
  Win32OwnProcess = 32,
  Win32ShareProcess = 64,
  Win32 = 128,
  InteractiveProcess = 256,
  ServiceDriver = 512,
  ServiceAll = 1024
}

export enum ServiceState {
  Stopped = 1,
  StartPending = 2,
  StopPending = 3,
  Running = 4,
  ContinuePending = 5,
  PausePending = 6,
  Paused = 7
}

export class Service implements Updatable<Service> {
  public controlsAccepted: ControlsAccepted;
  public displayName: string;
  public processID: number;
  public serviceFlags: number;
  public serviceName: string;
  public state: ServiceState;
  public type: ServiceType;

  // display names
  get stateD(): string {
    return ServiceState[this.state];
  }
  get typeD(): string {
    return ServiceType[this.state];
  }
  get controlsAcceptedD(): string {
    return ControlsAccepted[this.state];
  }
  /* eslint-disable no-bitwise */
  get canStop(): boolean {
    return Boolean(this.controlsAccepted & ControlsAccepted.AcceptStop);
  }
  get canShutdown(): boolean {
    return Boolean(this.controlsAccepted & ControlsAccepted.AcceptShutdown);
  }
  get canPauseAndContinue(): boolean {
    return Boolean(this.controlsAccepted & ControlsAccepted.AcceptPauseAndContinue);
  }
  /* eslint-enable no-bitwise */

  update(input: Partial<Service>): this {
    return Object.assign(this, input);
  }

  public constructor(init?: Partial<Service>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  equals(c?: Partial<Service>): boolean {
    if (this === c) {
      return true;
    }

    if (
      this.controlsAccepted === c.controlsAccepted &&
      this.displayName === c.displayName &&
      this.processID === c.processID &&
      this.serviceFlags === c.serviceFlags &&
      this.serviceName === c.serviceName &&
      this.state === c.state &&
      this.type === c.type
    ) {
      return true;
    }

    return false;
  }
}

export class Services implements Updatable<Services> {
  public timestamp: Date;
  public services: Array<Service>;

  constructor(init?: Partial<Services>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);

    if (this.services) {
      this.services = this.services.map((s) => new Service(s));
    }
  }

  update(input: Partial<Services>): this {
    return Object.assign(this, input);
  }
}
