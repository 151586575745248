import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileDownload } from '@fortawesome/pro-duotone-svg-icons';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MarkdownModule } from 'ngx-markdown';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { AgentToolsComponent } from './agent-tools.component';
import { GroupPolicyReportComponent } from './group-policy-report/group-policy-report.component';
import { IpconfigComponent } from './ipconfig/ipconfig.component';
import { LogReportComponent } from './log-report/log-report.component';
import { PingTestComponent } from './ping-test/ping-test.component';
import { SpeedTestComponent } from './speed-test/speed-test.component';
import { TracerouteComponent } from './traceroute/traceroute.component';
import { EventLogComponent } from './event-log/event-log.component';

@NgModule({
  declarations: [
    AgentToolsComponent,
    SpeedTestComponent,
    PingTestComponent,
    GroupPolicyReportComponent,
    LogReportComponent,
    TracerouteComponent,
    IpconfigComponent,
    EventLogComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FontAwesomeModule,
    SharedModule,
    MarkdownModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgApexchartsModule,
    ReactiveFormsModule
  ]
})
export class AgentToolsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFileDownload);
  }
}
