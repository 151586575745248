import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { ActivationEnd, NavigationEnd, Router, Scroll } from '@angular/router';
import { HotkeysService } from '@ngneat/hotkeys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  tap,
  withLatestFrom
} from 'rxjs';
import { IntroService } from 'src/app/core/services/intro.service';
import { StateService } from 'src/app/core/services/state.service';
import { scaleOut400ms } from 'src/app/shared/animations/scale-out.animation';
import { BottomDrawerComponent } from 'src/app/shared/components/bottom-drawer/bottom-drawer.component';
import { HotkeyHelpComponent } from 'src/app/shared/components/hotkey-help/hotkey-help.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { RenderValue } from 'src/app/shared/interfaces/render-value';
import { ContextUser } from 'src/app/shared/models/context-user.model';
import { Tenant } from 'src/app/shared/models/tenant.model';
import { TaskDrawerComponent } from 'src/app/view/layout/task-drawer/task-drawer.component';
import { environment } from 'src/environments/environment';

import { loadJS } from '../../app/utils/load-js';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from '../animations/fade-in-right.animation';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { checkRouterChildsData } from '../utils/check-router-childs-data';

@UntilDestroy()
@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [fadeInRight400ms, fadeOutRight400ms, scaleOut400ms]
})
export class LayoutComponent implements OnInit, AfterViewInit {
  permission = Permission;
  showTasks$ = combineLatest([this.stateSvc.tenant$, this.stateSvc.currentUser$]).pipe(
    filter(([t, u]) => !!t && !!u),
    map<[Tenant, ContextUser], RenderValue<boolean>>(([t, u]) => {
      return { value: !t.license.expired && u.hasPerms([Permission.Task_List]) };
    })
  );

  tenant$ = this.stateSvc.tenant$;
  serverDisconnected$ = this.stateSvc.serverDisconnected$;

  isLayoutVertical$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.layout === 'vertical')
  );
  isBoxed$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.boxed)
  );
  isToolbarFixed$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.toolbar.fixed)
  );
  isFooterFixed$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.footer.fixed)
  );
  isFooterVisible$ = this.configService.config$.pipe(
    filter((config) => !!config),
    map((config) => config.footer.visible)
  );
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  bottomDrawerCollapsed$ = this.layoutService.bottomDrawerCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;
  isMobile$ = this.layoutService.isMobile$;
  viewConfigPanel$ = this.layoutService.viewConfigPanel;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    startWith(null as string),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.toolbarShadowEnabled
      )
    )
  );

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    startWith(null as string),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.scrollDisabled
      )
    )
  );

  containerEnabled$ = this.router.events.pipe(
    filter<NavigationEnd>((event) => event instanceof NavigationEnd),
    startWith(null as string),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.containerEnabled
      )
    )
  );

  searchOpen$ = this.layoutService.searchOpen$;

  loader$ = this.loader.value$.pipe(debounceTime(400), distinctUntilChanged());

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  @ViewChild(MatSidenavContainer, { static: true })
  sidenavContainer: MatSidenavContainer;
  @ViewChild(TaskDrawerComponent, { static: true }) taskDrawer: TaskDrawerComponent;
  @ViewChild(BottomDrawerComponent, { static: true })
  bottomDrawer: BottomDrawerComponent;

  constructor(
    public loader: LoadingBarService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private dialog: MatDialog,
    private hotkeysSvc: HotkeysService,
    private introSvc: IntroService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private stateSvc: StateService
  ) {
    loadJS(
      `https://maps.googleapis.com/maps/api/js?key=${environment.mapsAPIKey}&libraries=marker&loading=async`
    );

    this.hotkeysSvc
      .addShortcut({ keys: '/', description: 'Search Machine or User' })
      .pipe(mergeMap(() => this.stateSvc.currentUser$.pipe(take(1))))
      .subscribe({
        next: (user) => {
          if (user.hasPerms([Permission.Search_Invoke])) {
            this.layoutService.openSearch();
          }
        }
      });

    this.hotkeysSvc
      .addShortcut({ keys: '`', description: 'Toggle Configuration Panel' })
      .subscribe({
        next: () => {
          this.layoutService.toggleConfigPanel();
        }
      });

    this.hotkeysSvc
      .addShortcut({ keys: 'control.`', description: 'Launch Shell' })
      .pipe(mergeMap(() => this.stateSvc.currentUser$.pipe(take(1))))
      .subscribe({
        next: (user) => {
          if (user.hasPerms([Permission.Shell_Invoke])) {
            window.open(
              `shell`,
              '_blank',
              'toolbar=no, menubar=no, titlebar=no, height=625, width=1200'
            );
          }
        }
      });

    this.hotkeysSvc
      .addShortcut({ keys: 'control.,', description: 'Go To Profile Settings' })
      .subscribe({
        next: () => {
          this.router.navigate(['home', 'me', 'settings'], {
            queryParamsHandling: 'merge'
          });
        }
      });

    this.hotkeysSvc
      .addShortcut({ keys: 'control.shift.<', description: 'Go To Profile' })
      .subscribe({
        next: () => {
          this.router.navigate(['home', 'me', 'general'], {
            queryParamsHandling: 'merge'
          });
        }
      });

    this.hotkeysSvc
      .addSequenceShortcut({ keys: 'g>d', description: 'Go To Dashboard' })
      .subscribe({
        next: () => {
          this.router.navigate(['home', 'dashboard'], {
            queryParamsHandling: 'merge'
          });
        }
      });

    this.hotkeysSvc
      .addSequenceShortcut({ keys: 'g>p', description: 'Go To Processes' })
      .subscribe({
        next: () => {
          this.router.navigate(['home', 'task-manager', 'processes'], {
            queryParamsHandling: 'merge'
          });
        }
      });

    this.hotkeysSvc
      .addSequenceShortcut({ keys: 'g>s', description: 'Go To Services' })
      .subscribe({
        next: () => {
          this.router.navigate(['home', 'task-manager', 'services'], {
            queryParamsHandling: 'merge'
          });
        }
      });

    this.hotkeysSvc
      .addSequenceShortcut({ keys: 'g>t', description: 'Go To Tasks' })
      .subscribe({
        next: () => {
          this.router.navigate(['home', 'tasks'], {
            queryParamsHandling: 'merge'
          });
        }
      });

    this.hotkeysSvc.registerHelpModal(() => {
      const ref = this.dialog.open(HotkeyHelpComponent, {
        autoFocus: false,
        width: '500px'
      });
      ref.componentInstance.dimiss.subscribe(() => ref.close());
    });
  }

  ngOnInit(): void {
    this.layoutService.sidenavOpen$.pipe(untilDestroyed(this)).subscribe({
      next: (open) => (open ? this.sidenav.open() : this.sidenav.close())
    });

    this.layoutService.configPanelOpen$.pipe(untilDestroyed(this)).subscribe({
      next: (open) => (open ? this.configpanel.open() : this.configpanel.close())
    });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof ActivationEnd),
        map((e) => e as ActivationEnd),
        tap((e) => {
          const machineID = e.snapshot.queryParamMap.get('machineID');
          if (!!machineID && this.stateSvc.selectedAgent?.agentID !== machineID) {
            this.stateSvc.setSearch(machineID);
          }
        })
      )
      .subscribe();

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter<NavigationEnd>((event) => event instanceof NavigationEnd),
        withLatestFrom(this.isDesktop$),
        filter(([, matches]) => !matches)
      )
      .subscribe({ next: () => this.sidenav.close() });
  }

  ngAfterViewInit(): void {
    this.setupIntroWizard();

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter<Scroll>((e) => e instanceof Scroll)
      )
      .subscribe({
        next: (e) => {
          if (e.position) {
            // backward navigation
            this.sidenavContainer.scrollable.scrollTo({
              start: e.position[0],
              top: e.position[1]
            });
          } else if (e.anchor) {
            // anchor navigation

            const scroll = (anchor: HTMLElement) =>
              this.sidenavContainer.scrollable.scrollTo({
                behavior: 'smooth',
                top: anchor.offsetTop,
                left: anchor.offsetLeft
              });

            let anchorElem = this.document.getElementById(e.anchor);

            if (anchorElem) {
              scroll(anchorElem);
            } else {
              setTimeout(() => {
                anchorElem = this.document.getElementById(e.anchor);
                scroll(anchorElem);
              }, 100);
            }
          } else {
            // forward navigation
            this.sidenavContainer.scrollable.scrollTo({
              top: 0,
              start: 0
            });
          }
        }
      });
  }

  private setupIntroWizard(): void {
    const showIntro = () =>
      this.introSvc.show(
        'welcome-0',
        'search-button',
        'tenant-invite-alert',
        'user-profile-button',
        'config-panel-button',
        'task-panel',
        'nav-settings-panel',
        'tenant-name-logo',
        'recent-nav',
        'copy-text-button',
        'recently-update-machines-list',
        'install-agent-button',
        'tenant-invite',
        'complete-0'
      );

    this.router.events
      .pipe(
        filter<NavigationEnd>((event) => event instanceof NavigationEnd),
        tap(() => showIntro())
      )
      .subscribe();

    showIntro();
  }

  toggleConfigPanel(): void {
    this.configpanel.open();
  }
}
