export function formatAddress(
  firstName = '',
  lastName = '',
  company: string,
  address: string[],
  locality: string,
  region: string,
  postcode: string,
  country: string
): string {
  const name = [firstName, lastName].filter((s) => !!s).join(' ');

  const fullAddress = address
    ? address
        .filter((a) => !!a)
        .join('\n')
        .trim()
    : '';
  const area =
    `` +
    `${locality ? locality + ', ' : ''}` +
    `${region ? region + '  ' : ''}` +
    `${postcode ? postcode + '' : ''}`;
  return (
    `` +
    `${name ? name + '\n' : ''}` +
    `${company ? company + '\n' : ''}` +
    `${fullAddress ? fullAddress + '\n' : ''}` +
    `${area ? area + '\n' : ''}` +
    `${country ? country : ''}`
  );
}
