export function loadJS(url: string, section: 'body' | 'head' = 'body'): void {
  let body: HTMLElement;
  switch (section) {
    case 'body':
      body = document.body as HTMLElement;
      break;
    case 'head':
      body = document.head as HTMLHeadElement;
      break;
    default:
      throw 'section can not be determined';
  }
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = false;
  script.defer = true;
  body.appendChild(script);
}
