<ng-container *ngIf="settings$ | async as settings"> </ng-container>
<div class="grid grid-flow-row gap-2 grid-cols-1 pt-2 md:grid-cols-2">
  <div [@listStagger]="3" class="col-span-1 flex flex-col gap-2">
    <div *ngIf="true" @fadeInUp class="card">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
          <span>Agent Settings</span>
        </h2>
      </div>

      <ng-container *ngIf="hashCheckSettings$ | async as hashCheckSettings">
        <div class="flex items-center justify-between py-2 text-2xs md:text-sm">
          <div class="flex-1 m-0 pl-4">Allow Agents To Hash Check If Script Is Not Signed</div>
          <div class="m-0 pr-2">
            <mat-slide-toggle
              *hasPerm="[permission.Tenant_Update]; else noPerm"
              (change)="setSetting('agent.script.allowHashCheck', $event.checked)"
              [checked]="hashCheckSettings.get('agent.script.allowHashCheck') ? true : false"
              [disabled]=""
            >
            </mat-slide-toggle>
            <ng-template #noPerm>
              <mat-slide-toggle [checked]="hashCheckSettings.get('agent.script.allowHashCheck') ? true : false" disabled>
              </mat-slide-toggle>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="updaterSettings$ | async as updaterSettings">
        <div class="flex items-center justify-between py-2 text-2xs md:text-sm">
          <div class="flex-1 m-0 pl-4">Enable/Disable Agent Updates</div>
          <div class="m-0 pr-2">
            <mat-slide-toggle
              *hasPerm="[permission.Tenant_Update]; else noPerm"
              (change)="setSetting('agent.updater.updateAgentBinaryEnabled', $event.checked)"
              [checked]="updaterSettings.get('agent.updater.updateAgentBinaryEnabled') ? true : false"
              [disabled]=""
            >
            </mat-slide-toggle>
            <ng-template #noPerm>
              <mat-slide-toggle [checked]="updaterSettings.get('agent.updater.updateAgentBinaryEnabled') ? true : false" disabled>
              </mat-slide-toggle>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="ohmSettings$ | async as ohmSettings">
        <div class="flex items-center justify-between py-2 text-2xs md:text-sm">
          <div class="flex-1 m-0 pl-4">Enable/Disable Open Hardware Monitor On Agents</div>
          <div class="m-0 pr-2">
            <mat-slide-toggle
              *hasPerm="[permission.Tenant_Update]; else noPerm"
              (change)="setSetting('agent.ohm.enabled', $event.checked)"
              [checked]="ohmSettings.get('agent.ohm.enabled') ? true : false"
              [disabled]=""
            >
            </mat-slide-toggle>
            <ng-template #noPerm>
              <mat-slide-toggle [checked]="ohmSettings.get('agent.ohm.enabled') ? true : false" disabled> </mat-slide-toggle>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="gatewaySettings$ | async as gatewaySettings">
        <div class="flex items-center justify-between py-2 text-2xs md:text-sm">
          <div class="flex-1 m-0 pl-4">Enable/Disable Gateway Ping On Agents</div>
          <div class="m-0 pr-2">
            <mat-slide-toggle
              *hasPerm="[permission.Tenant_Update]; else noPerm"
              (change)="setSetting('agent.gateway.pingable', $event.checked)"
              [checked]="gatewaySettings.get('agent.gateway.pingable') ? true : false"
              [disabled]=""
            >
            </mat-slide-toggle>
            <ng-template #noPerm>
              <mat-slide-toggle [checked]="gatewaySettings.get('agent.gateway.pingable') ? true : false" disabled> </mat-slide-toggle>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="true" @fadeInUp class="card">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
          <span>Tenant Settings</span>
        </h2>
      </div>

      <ng-container *ngIf="flowTrackingSettings$ | async as flowTrackingSettings">
        <div class="flex items-center justify-between py-2 text-2xs md:text-sm">
          <div class="flex-1 m-0 pl-4">Share Usability Workflows with Liquidware</div>
          <div class="m-0 pr-2">
            <mat-slide-toggle
              *hasPerm="[permission.Tenant_Update]; else noPerm"
              (change)="setSetting('tenant.allowFlowTracking', $event.checked)"
              [checked]="flowTrackingSettings.get('tenant.allowFlowTracking') ? true : false"
              [disabled]=""
            >
            </mat-slide-toggle>
            <ng-template #noPerm>
              <mat-slide-toggle [checked]="flowTrackingSettings.get('tenant.allowFlowTracking') ? true : false" disabled>
              </mat-slide-toggle>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="true" @fadeInUp class="card">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
          <span>3rd Party APIs</span>
        </h2>
      </div>

      <div class="pl-2 pr-6 h-10 border-b sticky left-0 flex justify-start items-center content-center bg-gray-300 dark:bg-gray-500">
        <h3 class="text-secondary my-0 pr-4 mr-4 border-r flex-none"> <span>OpenAI</span></h3>
      </div>
      <ng-container *ngIf="user$ | async as user">
        <mon-input-field
          [readonly]="!user.hasPerms([permission.Tenant_Update])"
          field="encrypted.openai.apiKey"
          label="API Key"
          clearOnSubmit
          showCurrentValueInHint
        ></mon-input-field>
        <mon-input-field
          [readonly]="!user.hasPerms([permission.Tenant_Update])"
          field="openai.organizationID"
          label="Organization ID"
        ></mon-input-field>
        <mon-select
          [readonly]="!user.hasPerms([permission.Tenant_Update])"
          field="openai.model"
          label="OpenAI Model"
          [defaultValue]="'gpt-4'"
        >
          <mat-option *ngFor="let v of ['gpt-3.5-turbo-0125', 'gpt-4', 'gpt-4-turbo']" [value]="v">{{ v }}</mat-option>
        </mon-select>
      </ng-container>
    </div>
  </div>
  <div class="col-span-1">
    <div class="flex flex-col md:flex-row md:items-start">
      <div class="flex-auto">
        <div @fadeInUp *ngIf="true" class="card relative overflow-x-auto overflow-y-hidden">
          <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
            <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
              <span>API Keys</span>
            </h2>

            <span class="flex-1"></span>

            <!--
            <button *hasPerm="[permission.Tenant_Update]" class="ml-4 flex-none" mat-icon-button matTooltip="Add APIKey" type="button">
              <fa-icon [icon]="['fad', 'plus']" size="lg"></fa-icon>
            </button>-->

            <mon-display-config-button
              matTooltip="Filter Columns"
              *ngIf="displayConfig$ | async as config"
              persistAt="User"
              [config]="config"
              [canHide]="false"
              [icon]="['fad', 'columns']"
              class="ml-2 flex items-center justify-center flex-none"
            ></mon-display-config-button>
          </div>

          <div *ngIf="dataSource.loading$ | async" class="absolute top-0 left-0 bottom-0 right-0 z-10">
            <div class="absolute w-full h-full bg-gray opacity-30"></div>
            <fa-icon
              [icon]="['fad', 'cog']"
              animation="spin"
              size="4x"
              class="flex w-full h-full items-center justify-center text-primary animate-pulse"
            ></fa-icon>
          </div>

          <table
            [dataSource]="dataSource"
            [@listStagger]="dataSource.length"
            mat-table
            matSort
            [matSortActive]="this.sortField"
            [matSortDirection]="this.sortDirection"
            class="w-full"
          >
            <!-- Text Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
              <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
                <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
                  {{ getValue(row, column.property) }}
                </td>
              </ng-container>
            </ng-container>

            <!-- Date Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
              <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
                <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
                  <ng-container [ngSwitch]="column.dateFormat">
                    <ng-container *ngSwitchCase="'timeAgo'">
                      {{ row[column.property] | timeAgo }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ row[column.property] | date: column.dateFormat || 'medium' }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>

            <!-- Button Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
              <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
                <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
                <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
                  <div class="flex float-right">
                    <button
                      (click)="column.buttonFn(row)"
                      class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover"
                      mat-icon-button
                    >
                      <fa-icon [icon]="['fad', column.buttonIconName]" size="lg" class="text-gray" [matTooltip]="column.tooltip"> </fa-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
              <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
              <tr
                @fadeInRight
                *matRowDef="let row; columns: visibleColumns"
                class="hover:bg-hover trans-ease-out cursor-pointer"
                mat-row
              ></tr>
            </ng-container>
          </table>

          <mat-paginator [length]="dataSource.length"> </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
