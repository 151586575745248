<ng-container *ngIf="c$ | async as rvC">
  <button
    mat-icon-button
    [matMenuTriggerFor]="remoteMenu"
    [matMenuTriggerData]="{ config: rvC.value }"
    type="button"
    [ngClass]="class"
    class="flex items-center justify-center transition duration-200 ease-in-out sm-icon-button"
  >
    <ng-container *ngIf="icon; else defaultIcon">
      <fa-icon [icon]="icon" [size]="size" [fixedWidth]="true"></fa-icon>
    </ng-container>
    <ng-template #defaultIcon>
      <fa-icon [icon]="['fas', 'ellipsis-vertical']" class="text-gray"> </fa-icon>
    </ng-template>
  </button>

  <mat-menu #remoteMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template matMenuContent let-config="config">
      <button *ngFor="let field of config.fields" mat-menu-item (click)="$event.stopPropagation(); toggle(field.name)">
        <!-- ! noop specified for mat-checkbox; let the button handle the click otherwise you get a double check -->
        <mat-checkbox noop [checked]="field.value" color="primary" class="h-full w-full">
          {{ field.name }}
        </mat-checkbox>
      </button>
      <button *ngIf="canHide" (click)="hideControl()" class="mat-mdc-menu-item mat-mdc-focus-indicator">Remove</button>
    </ng-template>
  </mat-menu>
</ng-container>
