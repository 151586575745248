export class ShellTicket {
  public id: string;
  public expires: Date;

  constructor(init?: Partial<ShellTicket>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.expires = new Date(init.expires);
  }
}
