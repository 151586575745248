import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { filter } from 'rxjs/operators';
import { scaleInOutAnimation } from 'src/@vex/animations/scale-in-out.animation';
import { ConfigService } from 'src/@vex/services/config.service';
import { Style } from 'src/@vex/services/style.service';
import {
  ComponentDisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { darkTheme } from 'src/app/view/dashboard/widget/geo-location/map-style.dark';
import { lightTheme } from 'src/app/view/dashboard/widget/geo-location/map-style.light';

export const GeoLocationDisplayConfig = new ComponentDisplayConfig({
  id: 'widget.geo-location',
  name: 'Location',
  show: true,
  fields: [new DisplayField('Map', true)]
});

@Component({
  selector: 'mon-geo-location[config]',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss'],
  animations: [scaleInOutAnimation]
})
export class GeoLocationComponent {
  showButton: boolean;
  darkMap: google.maps.MapTypeStyle[] = darkTheme;
  lightMap: google.maps.MapTypeStyle[] = lightTheme;

  themeStyle: google.maps.MapTypeStyle[] = this.lightMap;
  style: google.maps.StyledMapType;
  marker: google.maps.marker.AdvancedMarkerElement;
  circle: google.maps.Circle;

  _googleMap: google.maps.Map;
  @ViewChild('map') set googleMap(googleMap: GoogleMap) {
    if (!googleMap) {
      return;
    }

    this._googleMap = googleMap.googleMap;

    this.circle = new google.maps.Circle({
      strokeColor: '#e91e63',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#e91e63',
      fillOpacity: 0.25,
      map: googleMap.googleMap,
      radius: 15000
    });
    this.markup();
  }

  _properties: Map<string, string> = new Map<string, string>();
  @Input() set properties(properties: Map<string, string>) {
    this._properties = properties;
    if (this._displayConfig) {
      this._displayConfig.addFields([...properties.keys()]);
    }
  }

  @Input() icon: IconName;

  _latLng: google.maps.LatLng;
  @Input() set latLng(latLng: google.maps.LatLng) {
    this._latLng = latLng;
    this.markup();
  }

  @Input() options: google.maps.MapOptions = {
    clickableIcons: false,
    draggable: false,
    draggableCursor: 'default',
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: this.lightMap
  };

  @Input() iconClass: string[];

  _displayConfig: ComponentDisplayConfig;
  @Input() set config(config: ComponentDisplayConfig) {
    this._displayConfig = config;
  }

  constructor(configSvc: ConfigService) {
    configSvc.config$.pipe(filter((config) => !!config)).subscribe({
      next: (config) => {
        this.themeStyle =
          config.style && config.style === Style.dark ? this.darkMap : this.lightMap;
        this.markup();
      }
    });
  }

  markup(): void {
    if (this._googleMap) {
      this._googleMap.setOptions({ styles: this.themeStyle });
    }
    if (this.marker) {
      this.marker.position = this._latLng;
    }
    if (this.circle) {
      this.circle.setCenter(this._latLng);
    }
  }
}
