import { Updatable } from '../interfaces/updatable.interface';
import { IPLocation } from './iplocation.model';
import { Session } from './session.model';

export class SystemSummary implements Updatable<SystemSummary> {
  static STRATUSPHERE_UX_REG_KEY =
    'HKEY_LOCAL_MACHINE\\SOFTWARE\\Liquidware Labs\\ConnectorID\\HubUiURI';

  public batteries: Array<BatteryMetrics>;
  public ipLocation: IPLocation;
  public sessions: Array<Session>;
  public startupApps: StartupApp[];
  public other: Map<string, unknown>;

  get agentHasStratusphereUX(): string {
    return (
      (this.other &&
        this.other.get('WindowsRegistrySettings')[
          SystemSummary.STRATUSPHERE_UX_REG_KEY
        ]) ||
      ''
    );
  }

  constructor(init?: Partial<SystemSummary>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    if (this.ipLocation) {
      this.ipLocation = new IPLocation(this.ipLocation);
    }
    if (this.startupApps) {
      this.startupApps = init.startupApps.map((sa) => new StartupApp(sa));
    }
    if (this.batteries) {
      this.batteries = this.batteries.map((e) => new BatteryMetrics(e));
    }
    if (this.sessions) {
      this.sessions = this.sessions
        .filter((s) => Session.isUserSession(s))
        .map((s) => new Session(s));
    }
    if (init.other) {
      this.other = new Map<string, unknown>();
      Object.keys(init.other).map((k) => this.other.set(k, init.other[k]));
    }
  }

  update(input: Partial<SystemSummary>): this {
    return Object.assign(this, input);
  }
}

export class StartupApp implements Updatable<StartupApp> {
  name: string;
  publisher: string;
  status: boolean;
  impact: number;
  type: string;
  diskIO: number;
  cpu: number;
  path: string;

  constructor(init?: Partial<StartupApp>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<StartupApp>): this {
    return Object.assign(this, input);
  }
}

export class BatteryMetrics implements Updatable<BatteryMetrics> {
  public state: BStatus;
  public current: number;
  public full: number;
  public remainingPercentage: number;
  public design: number;
  public chargeRate: number;
  public voltage: number;
  public designVoltage: number;
  public deviceName: string;
  public manufacturer: string;
  public serialNumber: string;
  public batteryTemperature: number;
  public information: BatteryInformation;
  public waitStatus: BatteryWaitStatus;
  public status: BatteryStatus;

  constructor(init?: Partial<BatteryMetrics>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<BatteryMetrics>): this {
    return Object.assign(this, input);
  }
}

export class BatteryStatus implements Updatable<BatteryStatus> {
  public powerState: number;
  public capacity: number;
  public voltage: number;
  public rate: number;

  constructor(init?: Partial<BatteryStatus>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<BatteryStatus>): this {
    return Object.assign(this, input);
  }
}

export class BatteryWaitStatus implements Updatable<BatteryWaitStatus> {
  public batteryTag: number;
  public timeout: number;
  public powerState: number;
  public lowCapacity: number;
  public highCapacity: number;

  constructor(init?: Partial<BatteryWaitStatus>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<BatteryWaitStatus>): this {
    return Object.assign(this, input);
  }
}

export class BatteryInformation implements Updatable<BatteryInformation> {
  public capabilities: number;
  public technology: number;
  public reserved: number;
  public chemistry: number;
  public designedCapacity: number;
  public fullChargedCapacity: number;
  public defaultAlert1: number;
  public defaultAlert2: number;
  public critialBias: number;
  public cycleCount: number;

  constructor(init?: Partial<BatteryInformation>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<BatteryInformation>): this {
    return Object.assign(this, input);
  }
}

export enum BStatus {
  Unknown = 0,
  Empty = 1,
  Full = 2,
  Charging = 3,
  Discharging = 4
}
