import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class PasswordStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (!control?.touched) {
      return false;
    }
    form?.form.updateValueAndValidity();
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );
    const invalidMatch = control.value !== form?.form.controls.password.value;
    return invalidCtrl || invalidParent || invalidMatch;
  }
}

export class CustomValidators {
  static fn<T>(errorName: string, predicate: (v: T) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (predicate(control.value)) {
        const err = {};
        err[errorName] = true;
        return err;
      }

      return null;
    };
  }

  static customRegex(errorName: string, expression: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!expression.test(control.value)) {
        const err = {};
        err[errorName] = true;
        return err;
      }

      return null;
    };
  }

  static noWhitespace(control: AbstractControl): ValidationErrors | null {
    if (control.value === 0) {
      return null;
    }
    const isValid = !!control.value;
    // const isValid = !!control.value.trim();
    return isValid ? null : { required: true };
  }

  static minChipCount(minChipLength: number, chipRegex: RegExp = undefined): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const chips: string[] = control.value || [];

      if (chips.length < minChipLength) {
        return { invalid: true };
      }

      if (chipRegex) {
        for (const chip of chips) {
          if (!chipRegex.test(chip)) {
            return { invalid: true };
          }
        }
      }

      return null;
    };
  }

  static commonDenominator(n: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number = control.value || 0;
      const isValid = value % n === 0;
      return isValid ? null : { invalidDenominator: true };
    };
  }

  static luhnCheck(control: AbstractControl): ValidationErrors | null {
    let cardNumber = control.value || '';

    if (!cardNumber.length) {
      return null;
    }

    // Remove all whitespaces from card number.
    cardNumber = cardNumber.replace(/\s/g, '');

    // 1. Remove last digit;
    const lastDigit = Number(cardNumber[cardNumber.length - 1]);

    // 2. Reverse card number
    const reverseCardNumber = cardNumber
      .slice(0, cardNumber.length - 1)
      .split('')
      .reverse()
      .map((x) => Number(x));

    let sum = 0;

    // 3. + 4. Multiply by 2 every digit on odd position.
    // Subtract 9 if digit > 9
    for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
      reverseCardNumber[i] = reverseCardNumber[i] * 2;
      if (reverseCardNumber[i] > 9) {
        reverseCardNumber[i] = reverseCardNumber[i] - 9;
      }
    }

    // 5. Make the sum of obtained values from step 4.
    sum = reverseCardNumber.reduce((acc, currValue) => acc + currValue, 0);

    // 6. Calculate modulo 10 of the sum from step 5 and the last digit.
    // If it's 0, you have a valid card number :)
    const isValid = (sum + lastDigit) % 10 === 0;

    return isValid ? null : { invalid: true };
  }

  static passwordStrength(control: AbstractControl): ValidationErrors | null {
    let failed = {};
    // const failed = { passwordStrength: true };
    const password = control.value;

    // Check for minimum length of 8 characters
    if (password.length < 8) {
      failed = Object.assign(failed, { minLength: true });
    }

    let count = 0;

    // Check for lower case letters
    if (/[a-z]/.test(password)) {
      count++;
    } else {
      failed = Object.assign(failed, { lowerCase: true });
    }

    // Check for upper case letters
    if (/[A-Z]/.test(password)) {
      count++;
    } else {
      failed = Object.assign(failed, { upperCase: true });
    }

    // Check for digits
    if (/\d/.test(password)) {
      count++;
    } else {
      failed = Object.assign(failed, { digits: true });
    }

    // Check for special characters
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(password)) {
      count++;
    } else {
      failed = Object.assign(failed, { specialChars: true });
    }

    if (count < 3) {
      failed = Object.assign(failed, { variety: true });
    }

    control['reqs'] = failed;

    // Check if at least 3 types of characters are present
    return count >= 3 && failed['minLength'] === undefined ? null : failed;
  }
}
