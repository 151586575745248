import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, EMPTY, iif, of, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  expand,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { LayoutService } from 'src/@vex/services/layout.service';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { AgentService } from 'src/app/core/services/agent.service';
import { IntroService } from 'src/app/core/services/intro.service';
import { RemoterPollerService } from 'src/app/core/services/remoter-poller.service';
import { SelectedAgentState, StateService } from 'src/app/core/services/state.service';
import { scaleOut400ms } from 'src/app/shared/animations/scale-out.animation';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { deepCopy } from 'src/app/utils/clone';

@UntilDestroy()
@Component({
  selector: 'mon-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    stagger80ms,
    fadeInUp400ms,
    fadeInRight400ms,
    fadeOutRight400ms,
    scaleOut400ms
  ]
})
export class MainComponent implements OnDestroy {
  permission = Permission;

  _recentMachinesLoaded = new BehaviorSubject<boolean>(false);
  recentMachinesLoaded$ = this._recentMachinesLoaded.asObservable();

  recentMachines$ = this.stateSvc.tenant$.pipe(
    tap(() => this._recentMachinesLoaded.next(false)),
    switchMap((t) => {
      return this.stateSvc.currentUserSettings$.pipe(
        map((settings) => settings.consoleUI.recentMachines.get(t.id) || []),
        take(1)
      );
    }),
    switchMap((machines) => {
      return iif(
        () => machines.length >= 6,
        of(machines),
        this.agentSvc.getAll(6 - machines.length, 0, []).pipe(
          catchError(() => of({ items: [] })),
          map((r) => Array.from(new Set(machines.concat(r.items.map((a) => a.id))))),
          tap((machines) => {
            if (this.firstAgentSubscription) {
              this.firstAgentSubscription.unsubscribe();
            }
            if (machines.length < 1) {
              this.monitorForFirstAgent(); // we'll redirect the user to the dashboard once the first agent is installed
            }
            if (machines.length === 1) {
              this.gotoMachine(machines[0]);
            }
          }) // set gets unique items
        )
      );
    }),
    tap(() => this._recentMachinesLoaded.next(true))
  );

  agentToolbarState$ = combineLatest([
    this.stateSvc.liveMode$.pipe(
      mergeMap((liveMode) =>
        iif(
          () => liveMode,
          this.agentLivePollerSvc.liveAgent$,
          this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
        )
      )
    ),
    this.agentLivePollerSvc.agentConnectionState$,
    this.remoterPollerSvc.outgoingSessions$.pipe(startWith([])),
    this.stateSvc.selectedAgent$
  ]).pipe(
    filter(([agent]) => !!agent), //agent must not be undefined
    map(([agent, agentConnectionState, outgoingSessions, selectedAgent]) => {
      return {
        agent: agent,
        agentBlocks: selectedAgent.agentBlocks,
        agentConnectionState: agentConnectionState,
        outgoingSessions: outgoingSessions || []
      };
    })
  );

  mainState$ = combineLatest([
    this.stateSvc.agentRequired$,
    this.stateSvc.selectedAgent$,
    this.stateSvc.liveMode$,
    this.stateSvc.tenant$
  ]).pipe(
    map(([agentRequired, selectedAgent, liveMode, tenant]) => {
      return {
        agentRequired,
        selectedAgent,
        liveMode,
        licenseExpired: tenant.license.expired
      };
    })
  );

  SelectedAgentState = SelectedAgentState;
  timerSubscription: Subscription;
  firstAgentSubscription: Subscription;
  serverDisconnected = false;
  idleDialogOpen: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private layoutSvc: LayoutService,
    private stateSvc: StateService,
    private agentSvc: AgentService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private remoterPollerSvc: RemoterPollerService,
    public introSvc: IntroService
  ) {
    this.agentLivePollerSvc.agentConnectionState$
      .pipe(
        take(1),
        delay(500),
        tap((state) => {
          if (state === 'connecting') {
            this.introSvc.show(
              'agent-toolbar',
              'agent-shell-icon',
              'agent-connection-status-icon',
              'live-playback-button'
            );
          } else if (state === 'disconnected') {
            this.introSvc.show('agent-disconnected-icon');
          }
        })
      )
      .subscribe();

    this.remoterPollerSvc.outgoingSessions$
      .pipe(
        filter((remotees) => !!remotees && remotees.length > 0),
        take(1),
        delay(500),
        tap(() => this.introSvc.show('agent-remote-icon'))
      )
      .subscribe();

    this.stateSvc.documentIdle$.subscribe({
      next: (idle) => {
        if (idle) {
          this.idlePopup();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  idlePopup(): void {
    if (!this.stateSvc.selectedAgent) {
      return;
    }

    if (this.stateSvc.serverDisconnected) {
      return;
    }

    if (this.idleDialogOpen && this.idleDialogOpen.getState() === MatDialogState.OPEN) {
      return;
    }

    this.stateSvc.setUserIdle(true);

    if (this.dialog.getDialogById('idle_dialog')) {
      return;
    }

    this.idleDialogOpen = this.dialog.open(ConfirmDialogComponent, {
      id: 'idle_dialog',
      autoFocus: true,
      restoreFocus: false,
      disableClose: true,
      panelClass: 'idle-dialog',
      width: '250px',
      data: {
        icon: 'user-clock',
        title: "You're idle",
        confirmationText: 'Are you still there?',
        cancelButtonText: 'Yes',
        onCancel: () => {
          this.stateSvc.setUserIdle(false);
        }
      }
    });
  }

  openSearch(): void {
    this.layoutSvc.openSearch();
  }

  gotoMachine(agentID: string): void {
    this.stateSvc.setSearch(agentID);
  }

  private monitorForFirstAgent(): void {
    const listen$ = this.agentSvc.listenForFirstAgent();

    this.firstAgentSubscription = listen$
      .pipe(
        expand((agent) => {
          if (!!agent) {
            this.stateSvc.setSearch(agent.id);
            this.router.navigate(['home', 'dashboard'], {
              queryParams: { machineID: agent.id },
              queryParamsHandling: 'merge'
            });
            return EMPTY;
          } else {
            return listen$;
          }
        })
      )
      .subscribe();
  }
}
