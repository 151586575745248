import { Updatable } from '../interfaces/updatable.interface';
import { OS } from './system.model';

export class Session implements Updatable<Session> {
  public id: number;
  public station: string;
  public username: string;
  public domainname: string;
  public clientname: string;
  public upn: string;
  public syntheticUPN: string;
  public clientIP: string;
  public state: number;
  public tags: Map<string, unknown>;

  _os: OS;
  private get os(): OS {
    if (!this._os) {
      if (this.tags && this.tags.has('os')) {
        this._os = this.tags.get('os') as OS;
      } else {
        this._os = OS.WINDOWS;
      }
    }

    return this._os;
  }

  _displayUsername: string;
  get displayUsername(): string {
    if (this._displayUsername) {
      return this._displayUsername;
    }
    const remoteSession = this.isRemoteSession;

    this._displayUsername =
      this.os === OS.WINDOWS
        ? (remoteSession ? `[${remoteSession.sessionType}] ` : '') +
          this.username +
          (this.upn ? ` (${this.upn})` : '') +
          (this.state != 0 ? ' [disconnected]' : '')
        : `${this.username}`;

    return this._displayUsername;
  }
  get userSession(): boolean {
    return Session.isUserSession(this);
  }
  _isRemoteSession: Remoter;
  get isRemoteSession(): Remoter {
    if (this._isRemoteSession !== undefined) {
      return this._isRemoteSession;
    }

    const dp = this.tags && this.tags.get('displayProtocol');
    if (dp) {
      this._isRemoteSession = new Remoter();
      this._isRemoteSession.machine = this.clientname;
      this._isRemoteSession.username = this.username;
      this._isRemoteSession.sessionID = this.id;
      this._isRemoteSession.sessionType = getDisplayProtocolDisplayName(dp['Protocol']);
    } else {
      this._isRemoteSession = null;
    }

    return this._isRemoteSession;
  }

  static isUserSession(session: Session): boolean {
    return session.id >= -1 && session.id < 9999 && session.username !== '';
  }

  constructor(init?: Partial<Session>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);

    const tags = new Map<string, unknown>();
    if (this.tags) {
      Object.keys(this.tags).map((k) => {
        tags.set(k, this.tags[k]);
      });

      this.tags = tags;

      const dp = this.tags.get('displayProtocol');
      if (dp) {
        if (dp['Latency'] !== undefined && dp['Protocol'] === 'console') {
          dp['Protocol'] = 'ica-cgp';
        }
      }
    }
  }

  getEnvironmentVariables(): Array<string> {
    return this.tags.get('envars') as Array<string>;
  }

  getEnvironmentVariablesAsMap(): Map<string, string> {
    const envars = this.getEnvironmentVariables();

    const envarsMap: Map<string, string> = new Map<string, string>();

    envars.map((e) => {
      const match = e.match(/([^=.]*)=(.*)/i);
      if (match != null) {
        if (envarsMap.has(match[1])) {
          envarsMap[match[1]] = match[2];
        } else {
          envarsMap.set(match[1], match[2]);
        }
      }
    });

    return envarsMap;
  }

  update(input: Partial<Session>): this {
    if (
      this.station !== input.station ||
      this.username !== input.username ||
      this.state !== input.state
    ) {
      this._displayUsername = undefined;
      this._isRemoteSession = undefined;
    }
    return Object.assign(this, input);
  }
}

export class AgentUserSession extends Session {
  public agentID: string;
  public timestamp: Date;

  constructor(init?: Partial<AgentUserSession>) {
    super(init);

    this.timestamp = new Date(init.timestamp);
  }
}

export class Remotee {
  agentID: string;
  machine: string;
  sessionsFrom: Array<string>;
  remoters: Map<string, Array<Session>>;
  timestamp: Date;

  constructor(init?: Partial<Remotee>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);

    const sessions = new Map<string, Array<Session>>();

    if (this.remoters) {
      Object.keys(this.remoters).map((k) => {
        sessions.set(
          k,
          this.remoters[k].map((s) => new Session(s))
        );
      });

      this.remoters = sessions;
    }
  }

  _users: Array<string>;
  getUsers(machine: string): Array<string> {
    if (this._users !== undefined) {
      return this._users;
    }
    const users = new Array<string>();
    const session = this.remoters.get(machine);
    if (session) {
      session
        .filter((s) => s.tags.has('displayProtocol'))
        .map((s: Session) => {
          const dp = s.tags.get('displayProtocol');
          return users.push(
            `[${getDisplayProtocolDisplayName(dp['Protocol'])}] ${s.username}`
          );
        });
    }
    this._users = users;
    return this._users;
  }
}

export class Remoter {
  sessionID: number;
  username: string;
  machine: string;
  sessionType: string;

  constructor(init?: Partial<Remoter>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }
}

export class RemoteeCollection {
  remotees: Array<Remotee>;
  timestamp: Date;

  constructor(init?: Partial<Remotee>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);

    if (this.remotees) {
      this.remotees = this.remotees.map((r) => new Remotee(r));
    }
  }
}

export function getDisplayProtocolDisplayName(protocol: string): string {
  switch (protocol) {
    case 'rdp-sxs':
    case 'rdp-tcp':
    case 'rdp-udp':
      return 'RDP';
    case 'ica-tcp':
    case 'ica-cgp':
      return 'HDX';
    case 'pcoip':
      return 'PCoIP';
    case 'blast':
      return 'Blast';
  }
  return protocol;
}
