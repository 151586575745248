import { Component } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { Link } from 'src/@vex/interfaces/link.interface';
import { StateService } from 'src/app/core/services/state.service';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { tailwindCssColorToRGB } from 'src/app/utils/colors';

@Component({
  selector: 'mon-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [scaleIn400ms, fadeInRight400ms, fadeInUp400ms]
})
export class SettingsComponent {
  tailwindCssColorToRGB = tailwindCssColorToRGB;

  tenant$ = this.stateSvc.tenant$;

  links: Link[] = [
    {
      label: 'COMPANY',
      route: './tenant',
      routerLinkActiveOptions: { exact: true }
    },
    {
      label: 'USERS',
      route: './users',
      requiredPerms: [Permission.User_List]
    },
    {
      label: 'ROLES',
      route: './roles',
      requiredPerms: [Permission.Role_List]
    },
    {
      label: 'SITE',
      route: './site',
      disabled: false,
      requiredPerms: [Permission.SiteAPIKey_List]
    }
  ];

  constructor(private stateSvc: StateService) {}
}
