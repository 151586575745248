import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { tableNames } from 'src/app/shared/constants/tables';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { Task } from 'src/app/shared/models/task.model';
import { isEmpty } from 'src/app/utils/condition';

import { TasksComponent, TaskScope } from '../../tasks/tasks.component';
import { TasksGlobalRecentDataSource } from './tasks-global-recent.datasource';

@UntilDestroy()
@Component({
  selector: 'mon-task-drawer',
  templateUrl: './task-drawer.component.html',
  styleUrls: ['./task-drawer.component.scss'],
  animations: [stagger20ms, fadeInRight80ms]
})
export class TaskDrawerComponent extends TasksComponent {
  dataSource = new TasksGlobalRecentDataSource(this.taskSvc, this.taskPollerSvc);

  scope = TaskScope.Global;
  tableID = tableNames.BOTTOM_DRAWER_TASKS;

  pullNew: boolean;
  maxCount: 20;

  settings$ = this.stateSvc.tenant$.pipe(
    filter((t) => !!t),
    tap(() => this.taskPollerSvc.clearRecent()),
    tap(() => (this.pullNew = true)),
    mergeMap(() =>
      this.stateSvc.currentUserSettings$.pipe(
        filter((settings) => !!settings),
        map(
          (settings) =>
            settings.consoleUI.table[this.tableID] || {
              pageSize: this.DEFAULT_PAGE_SIZE,
              sortField: this.DEFAULT_SORT_FIELD,
              sortDirection: this.DEFAULT_SORT_DIRECTION
            }
        ),
        distinctUntilChanged((prev, curr) => {
          return deepEqual(prev, curr);
        }),
        tap((settings) => {
          this.pageSize = isEmpty(settings.pageSize)
            ? this.DEFAULT_PAGE_SIZE
            : settings.pageSize;
          this.sortField = isEmpty(settings.sortField)
            ? this.DEFAULT_SORT_FIELD
            : settings.sortField;
          this.sortDirection = isEmpty(settings.sortDirection)
            ? this.DEFAULT_SORT_DIRECTION
            : settings.sortDirection;
        }),
        tap(() => this.loadTasks()),
        tap(() => {
          if (this.sort) {
            if (this.sortSubscription) {
              this.sortSubscription.unsubscribe();
              this.sortSubscription = undefined;
            }

            this.initControls();
          }
        })
      )
    )
  );

  loadTasks(): void {
    this.dataSource.loadTasks(
      [
        OrderBy.SortToOrderBy({
          active: this.sortField,
          direction: this.sortDirection
        })
      ],
      this.maxCount,
      this.pullNew
    );

    this.pullNew = false;
  }

  linkTarget(t: Task): boolean {
    return t.targets.size < 4;
  }

  trackByID(_index: number, id: string): string {
    return id;
  }
}
