import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleDown, faPlug, faPlus, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';

import { ShellComponent } from './shell.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatTooltipModule,
    ReactiveFormsModule,
    PageLayoutModule
  ]
})
export class ShellModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPlug, faTimes, faPlus, faAngleDown);
  }
}
