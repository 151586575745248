import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { BehaviorSubject, distinctUntilChanged, take, tap } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';
import { SystemService } from 'src/app/core/services/system.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ComponentDisplayConfig } from 'src/app/shared/components/display-config-button/display-config-button.component';
import { Agent } from 'src/app/shared/models/agent.model';

import { OpenHardwareMonitorComponent } from '../open-hardware-monitor/open-hardware-monitor.component';
import { OS } from 'src/app/shared/models/system.model';

export const MachineStatsDisplayConfig = new ComponentDisplayConfig({
  id: 'widget.machine-stats',
  name: 'System',
  show: true,
  fields: []
});

@UntilDestroy()
@Component({
  selector: 'mon-machine-stats',
  templateUrl: './machine-stats.component.html',
  styleUrls: ['./machine-stats.component.scss']
})
export class MachineStatsComponent {
  objectKeys = Object.keys;
  OS = OS;

  _displayConfig: ComponentDisplayConfig;
  @Input() set config(config: ComponentDisplayConfig) {
    this.agentProperties$
      .pipe(
        take(1),
        tap((p) => {
          config.addFields(Object.keys(p));
          this._displayConfig = config;
        })
      )
      .subscribe();
  }

  private _agent: Agent;
  get agent(): Agent {
    return this._agent;
  }
  @Input() set agent(agent: Agent) {
    this._agent = agent;
    this._agentProperties.next({
      Hostname: agent.system.hostname,
      Domain: agent.system.domain,
      'Boot Time': agent.system.bootTime,
      Processor: agent.system.cpu.modelName,
      Platform: agent.system.platformD,
      Manufacturer: agent.system.bios.manufacturer,
      Model: agent.system.bios.model,
      BIOS: agent.system.biosD
    });
  }

  _agentProperties = new BehaviorSubject<unknown>({
    Hostname: '',
    Domain: '',
    'Boot Time': undefined,
    Processor: '',
    Platform: '',
    Manufacturer: '',
    Model: '',
    BIOS: ''
  });
  agentProperties$ = this._agentProperties
    .asObservable()
    .pipe(distinctUntilChanged((prev, curr) => deepEqual(prev, curr)));

  constructor(
    private dialog: MatDialog,
    private stateSvc: StateService,
    private systemSvc: SystemService
  ) {}

  hardwareInfo(): void {
    this.dialog.open(OpenHardwareMonitorComponent, {
      restoreFocus: false,
      minHeight: 700,
      minWidth: 600,
      height: 'auto',
      width: 'auto'
    });
  }

  shutdownSystem(timeout = 0, reboot = false): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: `${reboot ? 'sync-alt' : 'power-off'}`,
        title: `${reboot ? 'Restart' : 'Shutdown'}?`,
        confirmationText: `Would you like to ${reboot ? 'restart' : 'shutdown'} ${
          this.stateSvc.selectedAgent.agentID
        }?`,
        onConfirm: () => {
          this.systemSvc
            .shutdown(this.stateSvc.selectedAgent.agentID, timeout, reboot)
            .subscribe({
              error: (err) => {
                console.warn(err);
              }
            });
        },
        onCancel: () => {}
      }
    });
  }
}
