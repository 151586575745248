import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { InputFieldComponent } from 'src/app/shared/components/settings/input-field/input-field.component';
import { SharedModule } from 'src/app/shared/shared.module';

import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { RolesComponent } from './roles/roles.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { SiteComponent } from './site/site.component';
import { TenantEditComponent } from './tenant/tenant-edit/tenant-edit.component';
import { TenantSwitchComponent } from './tenant/tenant-switch/tenant-switch.component';
import { TenantComponent } from './tenant/tenant.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UsersComponent } from './users/users.component';
import { DevHideDirective } from 'src/app/shared/directives/dev-hide.directive';
import { AddressComponent } from 'src/app/shared/components/address/address.component';
import { SelectComponent } from 'src/app/shared/components/settings/select/select.component';
import { PaymentSourceViewComponent } from 'src/app/shared/components/payment-source-view/payment-source-view.component';

@NgModule({
  declarations: [
    SettingsComponent,
    SiteComponent,
    TenantComponent,
    TenantEditComponent,
    TenantSwitchComponent,
    UserEditComponent,
    UsersComponent,
    RolesComponent,
    RoleEditComponent
  ],
  imports: [
    AddressComponent,
    InputFieldComponent,
    SelectComponent,
    DevHideDirective,
    CommonModule,
    ComponentsModule,
    FontAwesomeModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    PageLayoutModule,
    PaymentSourceViewComponent,
    ReactiveFormsModule,
    SettingsRoutingModule,
    SharedModule
  ]
})
export class SettingsModule {}
