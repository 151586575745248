export enum LicenseStatus {
  Future = 'future',
  InTrial = 'in-trial',
  Active = 'active',
  NonRewing = 'non-renewing',
  Paused = 'paused',
  Cancelled = 'cancelled'
}

export class License {
  public count: number;
  public used: number;
  public expiration: Date;
  public trialEnd: Date;
  public status: string;
  public expired: boolean;

  public get trialDaysLeft(): number {
    return new Date().getTime() - this.trialEnd.getTime() / (1000 * 3600 * 24);
  }

  public get cancelled(): boolean {
    return this.status === LicenseStatus.Cancelled;
  }

  public get nextBilling(): Date {
    const d = new Date(this.expiration.getTime());
    d.setDate(d.getDate() + 1);
    return d;
  }

  constructor(init?: Partial<License>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.expiration = new Date(init.expiration);
    this.trialEnd = new Date(init.trialEnd);
  }
}
