import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ConfigPanelView } from '../components/config-panel/config-panel.component';
import { SearchState } from '../components/search/search.component';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private _viewConfigPanel = new BehaviorSubject<ConfigPanelView>('none');
  viewConfigPanel = this._viewConfigPanel.asObservable();

  private _quickpanelOpenSubject = new BehaviorSubject<boolean>(false);
  quickpanelOpen$ = this._quickpanelOpenSubject.asObservable();

  private _sidenavOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavOpen$ = this._sidenavOpenSubject.asObservable();

  private _sidenavCollapsedSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsed$ = this._sidenavCollapsedSubject.asObservable();

  private _sidenavCollapsedOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsedOpen$ = this._sidenavCollapsedOpenSubject.asObservable();

  private _bottomDrawerCollapsedSubject = new BehaviorSubject<boolean>(false);
  bottomDrawerCollapsed$ = this._bottomDrawerCollapsedSubject.asObservable();

  private _bottomDrawerCollapsedOpenSubject = new BehaviorSubject<boolean>(false);
  bottomDrawerCollapsedOpen$ = this._bottomDrawerCollapsedOpenSubject.asObservable();

  private _configPanelOpenSubject = new BehaviorSubject<boolean>(false);
  configPanelOpen$ = this._configPanelOpenSubject.asObservable();

  private _searchOpen = new BehaviorSubject<SearchState>(undefined);
  searchOpen$ = this._searchOpen.asObservable();

  isDesktop$ = this.breakpointObserver
    .observe(`(min-width: 1280px)`)
    .pipe(map((state) => state.matches));
  ltLg$ = this.breakpointObserver
    .observe(`(max-width: 1279px)`)
    .pipe(map((state) => state.matches));
  gtMd$ = this.breakpointObserver
    .observe(`(min-width: 960px)`)
    .pipe(map((state) => state.matches));
  ltMd$ = this.breakpointObserver
    .observe(`(max-width: 959px)`)
    .pipe(map((state) => state.matches));
  gtSm$ = this.breakpointObserver
    .observe(`(min-width: 600px)`)
    .pipe(map((state) => state.matches));
  isMobile$ = this.breakpointObserver
    .observe(`(max-width: 599px)`)
    .pipe(map((state) => state.matches));

  isLtLg = (): boolean => this.breakpointObserver.isMatched(`(max-width: 1279px)`);

  isMobile = (): boolean => this.breakpointObserver.isMatched(`(max-width: 599px)`);

  constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
    this.router.events
      .pipe(
        filter<ActivationStart>((event) => event instanceof ActivationStart),
        tap((e) => {
          const view = e.snapshot.data.configPanel || 'none';
          this._viewConfigPanel.next(view);
        })
      )
      .subscribe();
  }

  openQuickpanel(): void {
    this._quickpanelOpenSubject.next(true);
  }

  closeQuickpanel(): void {
    this._quickpanelOpenSubject.next(false);
  }

  openSidenav(): void {
    this._sidenavOpenSubject.next(true);
  }

  closeSidenav(): void {
    this._sidenavOpenSubject.next(false);
  }

  collapseSidenav(): void {
    this._sidenavCollapsedSubject.next(true);
  }

  expandSidenav(): void {
    this._sidenavCollapsedSubject.next(false);
  }

  collapseOpenSidenav(): void {
    this._sidenavCollapsedOpenSubject.next(true);
  }

  collapseCloseSidenav(): void {
    this._sidenavCollapsedOpenSubject.next(false);
  }

  collapseBottomDrawer(): void {
    this._bottomDrawerCollapsedSubject.next(true);
  }

  expandBottomDrawer(): void {
    this._bottomDrawerCollapsedSubject.next(false);
  }

  collapseOpenBottomDrawer(): void {
    this._bottomDrawerCollapsedOpenSubject.next(true);
  }

  collapseCloseBottomDrawer(): void {
    this._bottomDrawerCollapsedOpenSubject.next(false);
  }

  toggleConfigPanel(): void {
    this._configPanelOpenSubject.next(!this._configPanelOpenSubject.value);
  }

  openSearch(): void {
    this._searchOpen.next({ cancelled: false, result: undefined, state: 'open' });
  }

  closeSearch(result: SearchState): void {
    this._searchOpen.next(result);
    this._searchOpen.next(undefined);
  }
}
