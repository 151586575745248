import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerInputEvent,
  MatDateRangePicker,
  MatDateRangeSelectionStrategy
} from '@angular/material/datepicker';
import { MatSlider } from '@angular/material/slider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { delay, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentService } from 'src/app/core/services/agent.service';
import { ServiceState } from 'src/app/core/services/api.service';
import { IntroService } from 'src/app/core/services/intro.service';
import { SelectedAgentState, StateService } from 'src/app/core/services/state.service';
import { TriggerCategory } from 'src/app/shared/enums/trigger-category.enum';
import {
  getCategoryAlarmTotals,
  HistoryDate,
  HistoryHourSummary
} from 'src/app/shared/models/history.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';

import { BufferTimlineComponent } from './buffer-timline/buffer-timline.component';

export enum Zoom {
  Hour = 'hour',
  Day = 'day',
  Week = 'week'
}

export enum Speed {
  Turtle = 3000,
  Rabbit = 1000
}

class dateRange {
  private now: Date = new Date();
  begin: Date = new Date(
    new Date(new Date().setDate(this.now.getDate() - 4)).setHours(0, 0, 0, 0)
  );
  end: Date = new Date(new Date().setDate(this.now.getDate()));

  constructor(init?: Partial<dateRange>) {
    if (!init) {
      return;
    }
    this.begin = init.begin;
    this.end = init.end;
  }
}

@Injectable()
export class HistoryRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D): DateRange<D> {
    return this._createFiveDayRange(date);
  }

  createPreview(activeDate: D): DateRange<D> {
    return this._createFiveDayRange(activeDate);
  }

  private _createFiveDayRange(date: D | null): DateRange<D> {
    if (date) {
      const start = this._dateAdapter.addCalendarDays(date, -2);
      const end = this._dateAdapter.addCalendarDays(date, 2);
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-timeline-toolbar',
  templateUrl: './timeline-toolbar.component.html',
  styleUrls: ['./timeline-toolbar.component.scss'],
  animations: [fadeInRight400ms, fadeOutRight400ms, fadeInUp200ms, stagger40ms],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: HistoryRangeSelectionStrategy
    }
  ]
})
export class TimelineToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  Math = Math;
  Zoom: typeof Zoom = Zoom;
  Speed: typeof Speed = Speed;
  ServiceState: typeof ServiceState = ServiceState;

  private _range = new BehaviorSubject<dateRange>(new dateRange());
  dateRange$ = this._range.asObservable();

  private _selectedDate = new BehaviorSubject<HistoryDate>(undefined);
  selectedDate$ = this._selectedDate.asObservable();

  private _selectedDateHoursPageIndex = new BehaviorSubject<number>(0);
  selectedDateHoursPageIndex$ = this._selectedDateHoursPageIndex.asObservable();

  selectedDateHours$ = combineLatest([
    this.selectedDate$,
    this.selectedDateHoursPageIndex$
  ]).pipe(
    map(([selectedDate, page]) => {
      if (!selectedDate) {
        return new PagedResult<HistoryHourSummary>(HistoryHourSummary, {
          items: [],
          count: 0,
          page: 0,
          limit: this.maxHoursPerPage
        });
      }

      if (selectedDate.hourlySummaries.length <= this.maxHoursPerPage) {
        return new PagedResult<HistoryHourSummary>(HistoryHourSummary, {
          items: selectedDate.hourlySummaries,
          count: selectedDate.hourlySummaries.length,
          page: page,
          limit: this.maxHoursPerPage
        });
      }

      return new PagedResult<HistoryHourSummary>(HistoryHourSummary, {
        items: selectedDate.hourlySummaries.filter(
          (_, i) =>
            i >= this.maxHoursPerPage * page &&
            i < this.maxHoursPerPage * page + this.maxHoursPerPage
        ),
        count: selectedDate.hourlySummaries.length,
        page: page,
        limit: this.maxHoursPerPage
      });
    })
  );

  private _selectedHour = new BehaviorSubject<HistoryHourSummary>(undefined);
  selectedHour$ = this._selectedHour.asObservable();

  zoom$ = combineLatest([this.selectedDate$, this.selectedHour$]).pipe(
    mergeMap(([selectedDate, selectedHour]) =>
      iif(
        () => !!selectedHour,
        of(Zoom.Hour),
        iif(() => !!selectedDate, of(Zoom.Day), of(Zoom.Week))
      )
    )
  );

  dates$ = combineLatest([
    this.stateSvc.liveMode$,
    this.stateSvc.selectedAgent$,
    this.dateRange$
  ]).pipe(
    tap(([, selectedAgent]) => {
      if (selectedAgent.state === SelectedAgentState.Found) {
        this.selectedAgentID = selectedAgent.agentID;
      } else {
        this.selectedAgentID = undefined;
      }
    }),
    filter(
      ([liveMode, selectedAgent]) =>
        !liveMode && selectedAgent.state === SelectedAgentState.Found
    ),
    mergeMap(([, selectedAgent, range]) =>
      this.agentSvc.getHistoryDates(selectedAgent.agentID, range.begin, range.end)
    )
  );

  hourDetail$ = combineLatest([this.stateSvc.selectedAgent$, this.selectedHour$]).pipe(
    tap(() => {
      this.agentHistoryPlayerSvc.setDetail(undefined).setIndex(0).clearBuffer();
    }),
    filter(
      ([selectedAgent, selectedHour]) =>
        selectedAgent.state === SelectedAgentState.Found && !!selectedHour
    ),
    mergeMap(([selectedAgent, selectedHour]) =>
      this.agentSvc.getHistoryHourDetail(
        selectedAgent.agentID,
        selectedHour.refDateID,
        selectedHour.refHourID
      )
    ),
    tap((detail) => {
      this.agentHistoryPlayerSvc.setDetail(detail).setIndex(0);
      this.togglePlay();
    })
  );

  playerState$ = this.agentHistoryPlayerSvc.state$;
  playerIndex$ = this.agentHistoryPlayerSvc.index$.pipe(map((index) => index + 1)); // the UI index is based on 1 and not 0
  beforeClickState: ServiceState;
  selectedAgentID: string;
  // hourPagingIndex: number;
  maxHoursPerPage = 12;

  datePickerFilter = (date: Date): boolean => {
    const tooOld = new Date(new Date().setDate(new Date().getDate() - 30));

    if (date < tooOld || date > new Date()) {
      return false;
    }

    return true;
  };

  @ViewChild('timeline', { static: false }) timeline: MatSlider;
  @ViewChild('bufferTimeline', { static: false }) bufferTimeline: BufferTimlineComponent;
  @ViewChild('picker', { static: false }) picker: MatDateRangePicker<Date>;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private stateSvc: StateService,
    private agentSvc: AgentService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private introSvc: IntroService
  ) {}

  ngAfterViewInit(): void {
    this.introSvc.show(
      'playback-view-welcome',
      'playback-calendar-button',
      'playback-day-button'
    );

    this.selectedDate$
      .pipe(
        filter((selectedDate) => !!selectedDate),
        take(1),
        delay(500),
        tap(() => this.introSvc.show('playback-go-back-button', 'playback-hour-button'))
      )
      .subscribe();

    this.selectedHour$
      .pipe(
        filter((selectedHour) => !!selectedHour),
        take(1),
        delay(500),
        tap(() =>
          this.introSvc.show(
            'playback-timeline',
            'playback-timeline-incidents',
            'playback-timeline-control-button'
          )
        )
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.agentHistoryPlayerSvc.bufferedIndex$
      .pipe(
        untilDestroyed(this),
        filter(() => !!this.bufferTimeline),
        tap((index) => this.bufferTimeline.buffered(index))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.agentHistoryPlayerSvc.clearBuffer();
  }

  gotoWeek(): void {
    this._selectedDate.next(undefined);
  }

  gotoDate(date: HistoryDate): void {
    this._selectedDate.next(date);
    this._selectedDateHoursPageIndex.next(0); // reset page index
    this._selectedHour.next(undefined);
  }

  gotoHour(hour: HistoryHourSummary): void {
    this._selectedHour.next(hour);
  }

  nextPageOfHours(): void {
    this._selectedDateHoursPageIndex.next(this._selectedDateHoursPageIndex.value + 1);
  }

  prevPageOfHours(): void {
    this._selectedDateHoursPageIndex.next(this._selectedDateHoursPageIndex.value - 1);
  }

  togglePlay(): void {
    this.agentHistoryPlayerSvc.togglePlay();
  }

  timerValueChange(): void {
    this.agentHistoryPlayerSvc.setIndex(this.timeline.step - 1);

    if (this.beforeClickState === ServiceState.Started) {
      this.agentHistoryPlayerSvc.play();
    }
  }

  sliding(): void {
    this.agentHistoryPlayerSvc.setIndex(this.timeline.step - 1);
  }

  mousedown(): void {
    this.beforeClickState = this.agentHistoryPlayerSvc.state;
    this.agentHistoryPlayerSvc.pause();
  }

  mouseup(): void {
    if (this.beforeClickState === ServiceState.Started) {
      this.agentHistoryPlayerSvc.play();
    }
  }

  getSpeed(): Speed {
    return <Speed>(<unknown>this.agentHistoryPlayerSvc.timerSpeed);
  }

  setSpeed(speed: Speed): void {
    this.agentHistoryPlayerSvc.setSpeed(speed);
  }

  startDateChange(event: MatDatepickerInputEvent<Date, unknown>): void {
    const now = new Date();
    const begin = new Date(
      new Date(event.value).setHours(
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
        now.getMilliseconds()
      )
    );
    const end = new Date(new Date(begin.getTime()).setDate(begin.getDate() + 4));

    this._range.next(
      new dateRange({
        begin: begin,
        end: end
      })
    );
  }

  datePickerOpened(): void {
    let historyDates: Array<HistoryDate>;
    const currentDate = new Date(this.picker.datepickerInput.getStartValue().getTime()); // make a copy - as to not alter original

    const renderMetricValues = () => {
      const el = this.document.getElementById(this.picker.id);

      const cells = Array.from(
        el.querySelectorAll<HTMLDivElement>(
          '.mat-calendar .mat-calendar-body-cell:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content'
        )
      );

      cells.forEach((c) => {
        const day = c.innerText;

        const dateID = `${currentDate.getUTCFullYear()}${(
          '0' +
          (currentDate.getMonth() + 1)
        ).slice(-2)}${('0' + day).slice(-2)}`;

        const date = historyDates.find((d) => d.id === dateID);

        if (date) {
          const totals = getCategoryAlarmTotals(date.alarms);

          if (totals.size < 1) {
            return;
          }

          c.innerHTML = `<div class='flex flex-row w-full h-full justify-center items-center'>
            <div class='calendar-metric-wrapper flex flex-col h-full w-full justify-center'>
                ${
                  totals.has(TriggerCategory.AgentStatsProcesses)
                    ? `<div class='text-3xs calendar-metric-value bg-blue bg-opacity-80 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsProcesses
                      )}</div>`
                    : ''
                }
                ${
                  totals.has(TriggerCategory.AgentStatsSummaryCPU)
                    ? `<div class='text-3xs calendar-metric-value bg-blue bg-opacity-50 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsSummaryCPU
                      )}</div>`
                    : ''
                }
                ${
                  totals.has(TriggerCategory.AgentStatsSummaryMemory)
                    ? `<div class='text-3xs calendar-metric-value bg-purple bg-opacity-50 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsSummaryMemory
                      )}</div>`
                    : ''
                }
                ${
                  totals.has(TriggerCategory.AgentStatsSummaryDisk)
                    ? `<div class='text-3xs calendar-metric-value bg-green bg-opacity-50 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsSummaryDisk
                      )}</div>`
                    : ''
                }
                ${
                  totals.has(TriggerCategory.AgentStatsSummaryNetwork)
                    ? `<div class='text-3xs calendar-metric-value bg-orange bg-opacity-50 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsSummaryNetwork
                      )}</div>`
                    : ''
                }
                ${
                  totals.has(TriggerCategory.AgentStatsSummaryGPU)
                    ? `<div class='text-3xs calendar-metric-value bg-blue bg-opacity-50 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsSummaryGPU
                      )}</div>`
                    : ''
                }
                ${
                  totals.has(TriggerCategory.AgentStatsSummarySystem)
                    ? `<div class='text-3xs calendar-metric-value bg-yellow bg-opacity-50 rounded-sm'>${totals.get(
                        TriggerCategory.AgentStatsSummarySystem
                      )}</div>`
                    : ''
                }
            </div>
            <div class='h-full w-full flex justify-center items-center'>${day}</div>
          </div>`;
        }
      });
    };

    this.agentSvc.getHistoryAllDates(this.selectedAgentID).subscribe({
      next: (result) => {
        historyDates = result;

        const el = this.document.getElementById(this.picker.id);

        const monthBtns = Array.from(
          el.querySelectorAll<HTMLDivElement>('button.mat-icon-button')
        );

        monthBtns.forEach((b) => {
          b.onclick = () => {
            const v = b.classList.contains('mat-calendar-next-button') ? 1 : -1;
            currentDate.setMonth(currentDate.getMonth() + v, currentDate.getDate());
            renderMetricValues();
          };
        });

        renderMetricValues();
      }
    });
  }
}
