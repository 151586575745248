<div @fadeInUp class="w-80 p-6 pt-0 flex flex-col gap-4">
  <p class="text-center py-4">Sign up now for your FREE trial or the FREE Community Edition of CommandCTRL</p>
  <button (click)="goToRegister()" color="primary" mat-raised-button type="button">SIGN UP</button>

  <table class="w-full">
    <tr>
      <td class="w-2/5">
        <hr class="border-gray-light" />
      </td>
      <td class="text-secondary text-center">OR</td>
      <td class="w-2/5">
        <hr class="border-gray-light" />
      </td>
    </tr>
  </table>

  <button (click)="signIn()" color="primary" mat-raised-button type="button">SIGN IN</button>
</div>
