<ng-container *ngIf="channelListener$ | async"></ng-container>
<ng-container *ngIf="agent$ | async"></ng-container>

<mon-spinner [spin]="spin" [overlay]="true">
  <div content #container>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex">
      <h2 class="border-b pb-1 flex-auto">
        <fa-icon class="text-green" [icon]="['fad', 'bars-progress']"></fa-icon>
        Hardware Information
      </h2>
    </div>

    <cdk-virtual-scroll-viewport itemSize="48" style="height: 600px">
      <ng-container *cdkVirtualFor="let item of dataSource"></ng-container>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <button mat-icon-button disabled></button>
          <fa-icon [icon]="['fad', icons[node.type]]" size="lg" class="text-primary mr-2"></fa-icon>
          {{ node.name }}
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <fa-icon
              [icon]="['fad', treeIcons[treeControl.isExpanded(node).toString()]]"
              size="lg"
              class="flex items-center justify-center"
            ></fa-icon>
          </button>
          <fa-icon [icon]="['fad', icons[node.type]]" size="lg" class="text-primary mr-2"></fa-icon>
          {{ node.name }}
        </mat-tree-node>
      </mat-tree>
    </cdk-virtual-scroll-viewport>

    <div @fadeInRight *ngIf="err" class="card text-white text-opacity-80 bg-red flex justify-between items-center">
      <pre class="flex items-center p-4 whitespace-pre-wrap">{{ err }}</pre>
      <pre class="p-2">
        <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="closeError()"></fa-icon>
      </pre>
    </div>

    <div mat-dialog-actions class="float-right pt-4">
      <button mat-button (click)="this.dialogRef.close()" cdkFocusInitial>Close</button>
    </div>
  </div>
</mon-spinner>
