export enum Permission {
  Search_Invoke = 'search:invoke',

  LiveMode_Invoke = 'live-mode:invoke',
  PlaybackMode_Invoke = 'playback-mode:invoke',
  Shell_Invoke = 'shell:invoke',
  Remote_Invoke = 'remote:invoke',

  Agent_List = 'agent:list',
  Agent_Read = 'agent:read',

  MachineProcess_List = 'machine-process:list',
  MachineProcess_Kill = 'machine-process:kill',
  MachineProcess_Read = 'machine-process:read',

  MachineSystem_Read = 'machine-system:read',
  MachineSystem_Shutdown = 'machine-system:shutdown',

  MachineCPU_Read = 'machine-cpu:read',

  MachineMemory_Read = 'machine-memory:read',

  MachineDisk_Read = 'machine-disk:read',

  MachineNetwork_Read = 'machine-network:read',

  MachineGPU_Read = 'machine-gpu:read',

  MachineGeo_Read = 'machine-geo:read',

  MachineSession_List = 'machine-session:list',
  MachineSession_Read = 'machine-session:read',
  MachineSession_Logoff = 'machine-session:logoff',

  MachineService_List = 'machine-service:list',
  MachineService_Read = 'machine-service:read',
  MachineService_Stop = 'machine-service:stop',
  MachineService_Start = 'machine-service:start',
  MachineService_Disable = 'machine-service:disable',
  MachineService_Delete = 'machine-service:delete',

  MachineStartupApplications_List = 'machine-startup-applications:list',
  MachineStartupLogin_List = 'machine-startup-login:list',

  Task_List = 'task:list',
  Task_Read = 'task:read',
  Task_Delete = 'task:delete',

  MachineDiagnosticSpeedtest_Invoke = 'machine-diagnostic-speedtest:invoke',
  MachineDiagnosticSpeedtest_Read = 'machine-diagnostic-speedtest:read',
  MachineDiagnosticPing_Invoke = 'machine-diagnostic-ping:invoke',
  MachineDiagnosticPing_Read = 'machine-diagnostic-ping:read',
  MachineDiagnosticGroupPolicyReport_Invoke = 'machine-diagnostic-group-policy-report:invoke',
  MachineDiagnosticGroupPolicyReport_Download = 'machine-diagnostic-group-policy-report:download',
  MachineDiagnosticAgentLogs_Invoke = 'machine-diagnostic-agent-logs:invoke',
  MachineDiagnosticAgentLogs_Download = 'machine-diagnostic-agent-logs:download',

  MachineBlock_List = 'machine-block:list',
  MachineBlock_Create = 'machine-block:create',
  MachineBlock_Read = 'machine-block:read',
  MachineBlock_Update = 'machine-block:update',
  MachineBlock_Delete = 'machine-block:delete',

  Tenant_Read = 'tenant:read',
  Tenant_Update = 'tenant:update',
  Tenant_UpdateLicenseAndBilling = 'tenant:update-license-and-billing',
  Tenant_ViewLicenseAndBilling = 'tenant:view-license-and-billing',
  Tenant_Delete = 'tenant:delete',

  Contact_List = 'contact:list',
  Contact_Create = 'contact:create',
  Contact_Read = 'contact:read',
  Contact_Update = 'contact:update',
  Contact_Delete = 'contact:delete',

  User_List = 'user:list',
  User_Create = 'user:create',
  User_Read = 'user:read',
  User_Update = 'user:update',
  User_Delete = 'user:delete',

  Role_List = 'role:list',
  Role_Create = 'role:create',
  Role_Read = 'role:read',
  Role_Update = 'role:update',
  Role_Delete = 'role:delete',

  SiteAPIKey_List = 'site-api-key:list',
  SiteAPIKey_Create = 'site-api-key:create',
  SiteAPIKey_Read = 'site-api-key:read',
  SiteAPIKey_Delete = 'site-api-key:delete',

  Action_List = 'action:list',
  Action_Invoke = 'action:invoke',
  Action_Create = 'action:create',
  Action_Read = 'action:read',
  Action_Update = 'action:update',
  Action_Delete = 'action:delete',

  Trigger_List = 'trigger:list',
  Trigger_Create = 'trigger:create',
  Trigger_Read = 'trigger:read',
  Trigger_Update = 'trigger:update',
  Trigger_Delete = 'trigger:delete',

  Alarm_List = 'alarm:list',
  Alarm_Create = 'alarm:create',
  Alarm_Read = 'alarm:read',
  Alarm_Update = 'alarm:update',
  Alarm_Delete = 'alarm:delete',

  Script_List = 'script:list',
  Script_Invoke = 'script:invoke',
  Script_Search = 'script:search',
  Script_Install = 'script:install',
  Script_Uninstall = 'script:uninstall',
  Script_Create = 'script:create',
  Script_Read = 'script:read',
  Script_Update = 'script:update',
  Script_Delete = 'script:delete'
}
