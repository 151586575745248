import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { SelectedAgentState, StateService } from 'src/app/core/services/state.service';
import { TaskPollerService } from 'src/app/core/services/task-poller.service';
import { TaskService } from 'src/app/core/services/task.service';
import {
  DisplayConfig,
  DisplayField,
  TableComponentDisplayConfig,
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { Task } from 'src/app/shared/models/task.model';
import { isEmpty } from 'src/app/utils/condition';

import { TasksGlobalRecentDataSource } from '../layout/task-drawer/tasks-global-recent.datasource';
import { TasksAgentDataSource } from './tasks-agent.datasource';
import { TasksGlobalDataSource } from './tasks-global.datasource';

export enum TaskScope {
  Global = 0,
  Agent = 1
}

@UntilDestroy()
@Component({
  selector: 'mon-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class TasksComponent {
  readonly DEFAULT_PAGE_SIZE = 20;
  readonly DEFAULT_SORT_FIELD = 'lastUpdate';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'desc';

  tableID = tableNames.HISTORY_TASKS;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource: TasksAgentDataSource | TasksGlobalDataSource | TasksGlobalRecentDataSource;

  columns: TableColumn<Task>[] = [
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['font-medium', 'truncate', 'max-w-10']
    },
    {
      label: 'Target',
      property: 'targetD',
      type: 'text',
      visible: true,
      hideable: true,
      sortable: false,
      cellClasses: ['font-medium', 'truncate', 'max-w-10'],
      canRoute: true
    },
    {
      label: 'Status',
      property: 'stateD',
      display: 'state',
      type: 'text',
      visible: true,
      hideable: true
    },
    {
      label: 'Result',
      property: 'resultD',
      type: 'text',
      visible: true,
      hideable: true,
      sortable: false,
      cellClasses: ['truncate', 'max-w-10']
    },
    {
      label: 'Started By',
      property: 'createdByUserEmail',
      type: 'text',
      visible: true,
      hideable: true
    },
    {
      label: 'Started',
      property: 'timestamp',
      type: 'date',
      visible: false,
      hideable: true
    },
    {
      label: 'Updated',
      property: 'lastUpdate',
      type: 'date',
      dateFormat: 'timeAgo',
      visible: true,
      hideable: true,
      cellClasses: ['w-40', 'max-w-40', 'truncate']
    }
  ];

  pageSubscription: Subscription;
  sortSubscription: Subscription;

  settings$ = this.stateSvc.selectedAgent$.pipe(
    untilDestroyed(this),
    filter(
      (selectedAgent) =>
        selectedAgent.state === SelectedAgentState.Found ||
        selectedAgent.state === SelectedAgentState.NotFound
    ),
    tap((selectedAgent) => {
      if (this.dataSource) {
        return;
      }

      this.scope =
        selectedAgent.state === SelectedAgentState.Found
          ? TaskScope.Agent
          : TaskScope.Global;

      this.dataSource =
        this.scope === TaskScope.Agent
          ? new TasksAgentDataSource(this.taskSvc)
          : new TasksGlobalDataSource(this.taskSvc);
    }),
    switchMap(() =>
      this.stateSvc.currentUserSettings$.pipe(
        filter((settings) => !!settings),
        map(
          (settings) =>
            settings.consoleUI.table[this.tableID] || {
              pageSize: this.DEFAULT_PAGE_SIZE,
              sortField: this.DEFAULT_SORT_FIELD,
              sortDirection: this.DEFAULT_SORT_DIRECTION
            }
        ),
        distinctUntilChanged((prev, curr) => {
          return deepEqual(prev, curr);
        }),
        tap((settings) => {
          this.pageSize = isEmpty(settings.pageSize)
            ? this.DEFAULT_PAGE_SIZE
            : settings.pageSize;
          this.sortField = isEmpty(settings.sortField)
            ? this.DEFAULT_SORT_FIELD
            : settings.sortField;
          this.sortDirection = isEmpty(settings.sortDirection)
            ? this.DEFAULT_SORT_DIRECTION
            : settings.sortDirection;
        }),
        tap(() => {
          this.loadTasks();
        }),
        tap((settings) => {
          if (this.sort) {
            if (this.sortSubscription) {
              this.sortSubscription.unsubscribe();
              this.sortSubscription = undefined;
            }
            // ! calling the sort here should render the sorting arrow in the header, but it doesn't
            // ! this can occur programmatically when the user resets their table preferences
            // ! see BUG: https://github.com/angular/components/issues/15715
            this.sort.sort({
              start: settings.sortDirection,
              id: settings.sortField,
              disableClear: false
            });
            this.initControls();
          }
        })
      )
    )
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new TableComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  controlsPopulated = (): boolean => !!this.paginator && !!this.sort;
  paginator: MatPaginator;
  @ViewChild(MatPaginator) set paginatorSetter(paginator: MatPaginator) {
    if (!this.paginator && !!paginator) {
      this.paginator = paginator;
      this.initControls();
    }
  }
  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  scope: TaskScope = TaskScope.Agent;

  constructor(
    protected stateSvc: StateService,
    protected taskSvc: TaskService,
    protected taskPollerSvc: TaskPollerService
  ) {}

  initControls(): void {
    if (!!this.sort && !this.sortSubscription) {
      this.sortSubscription = this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap((sort) => {
            const table = {};
            table[this.tableID] = {
              sortField: sort.active,
              sortDirection: sort.direction
            };
            this.stateSvc.updateCurrentUserSettings({
              consoleUI: {
                table
              }
            });
          })
        )
        .subscribe();
    }

    if (!!this.paginator && !this.pageSubscription) {
      this.pageSubscription = this.paginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            this.pageIndex = page.pageIndex;
            if (this.pageSize !== page.pageSize) {
              const table = {};
              table[this.tableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            } else {
              this.loadTasks();
            }
          })
        )
        .subscribe();
    }
  }

  loadTasks(): void {
    switch (this.dataSource.constructor) {
      case TasksAgentDataSource:
        (this.dataSource as TasksAgentDataSource).loadTasks(
          this.stateSvc.selectedAgent.agentID,
          '',
          [
            OrderBy.SortToOrderBy({
              active: this.sortField,
              direction: this.sortDirection
            })
          ]
        );
        break;
      case TasksGlobalDataSource:
        (this.dataSource as TasksGlobalDataSource).loadTasks(
          this.pageSize,
          this.pageIndex,
          [
            OrderBy.SortToOrderBy({
              active: this.sortField,
              direction: this.sortDirection
            })
          ]
        );
        break;
    }
  }

  get hideableColumns(): TableColumn<Task>[] {
    return this.columns.filter((c) => c.hideable);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }
}
