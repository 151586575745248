<button
  (click)="openConfig.emit()"
  data-intro-key="config-panel-button"
  class="config-panel-toggle flex justify-center items-center opacity-50 hover:opacity-100 transition-opacity"
  color="primary"
  mat-fab
  type="button"
>
  <fa-icon [icon]="['fad', 'cog']" [fixedWidth]="true" size="2x" class="flex"></fa-icon>
</button>
