import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable, take } from 'rxjs';

import { ApiService } from '../services/api.service';
import { StateService } from '../services/state.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private stateSvc: StateService) {}

  tenantID$ = combineLatest([
    this.stateSvc.currentUserSettings$,
    this.stateSvc.tenant$
  ]).pipe(
    map(([settings, tenant]) => {
      return settings?.selectedTenantID || tenant?.id || '';
    })
  );

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.indexOf(ApiService.BASE_URL) >= 0 ||
      request.url.indexOf(ApiService.BASE_WS_URL) >= 0
    ) {
      return this.tenantID$.pipe(
        take(1),
        mergeMap((tenantID) => {
          if (tenantID) {
            const newRequest = request.clone({
              setHeaders: {
                'x-commandctrl-tenant-id': tenantID
              }
            });
            return next.handle(newRequest);
          }

          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }
}
