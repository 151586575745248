import { Updatable } from '../interfaces/updatable.interface';

export class ContextUserTenant implements Updatable<ContextUserTenant> {
  public id: string;
  public domain: string;
  public name: string;
  public companyName: string;
  public owned: boolean;
  public licenseStatus: string;

  constructor(init?: Partial<ContextUserTenant>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<ContextUserTenant>): this {
    return Object.assign(this, input);
  }
}
