import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScriptResult } from 'src/app/shared/interfaces/script.interface';

@Component({
  selector: 'mon-result-list-item',
  templateUrl: './result-list-item.component.html',
  styleUrls: ['./result-list-item.component.scss']
})
export class ResultListItemComponent {
  @Input() result: ScriptResult;
  @Input() selected: boolean;

  @Output() dismiss = new EventEmitter<MouseEvent>();

  @ViewChild('item', { static: true }) item: ElementRef;

  scrollTo() {
    this.item.nativeElement.parentElement.scrollIntoViewIfNeeded(true);
  }

  handleDismiss(element: MouseEvent) {
    this.dismiss.next(element);
  }
}
