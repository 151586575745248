import { AfterViewInit, Directive, Input } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Directive({
  selector: '[tabSelected]'
})
export class TabSelectedDirective implements AfterViewInit {
  @Input('preventDefault') preventDefault = false;

  constructor(private auto: MatAutocomplete) {}

  ngAfterViewInit() {
    this.auto._keyManager.onKeydown = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'Tab':
          if (this.auto.isOpen) {
            const option = this.auto.options.find((x) => x.active);
            if (option) {
              if (option.selected) {
                option.deselect();
              }
              option.select();
              if (this.preventDefault) {
                event.preventDefault();
              }

              return;
            }
          }
          this.auto._keyManager.tabOut.next();
          break;
        case 'ArrowDown':
          this.auto._keyManager.setNextItemActive();
          break;

        case 'ArrowUp':
          this.auto._keyManager.setPreviousItemActive();
          break;
      }
    };
  }
}
