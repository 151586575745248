export enum ActionCauseType {
  System = 'system',
  Trigger = 'trigger'
}

export const ActionCauseTypeLabel: { [key in ActionCauseType]: string } = {
  [ActionCauseType.System]: 'System',
  [ActionCauseType.Trigger]: 'Trigger'
};

export enum SystemEventCauseType {
  Logon = 'logon',
  Logoff = 'logoff'
}

export const SystemEventCauseTypeLabel: { [key in SystemEventCauseType]: string } = {
  [SystemEventCauseType.Logon]: 'Logon',
  [SystemEventCauseType.Logoff]: 'Logoff'
};

export enum ActionEffectType {
  System = 'system',
  RunScript = 'run-script'
}

export const ActionEffectTypeLabel: { [key in ActionEffectType]: string } = {
  [ActionEffectType.System]: 'System',
  [ActionEffectType.RunScript]: 'Run Script'
};

export enum SystemEventEffectType {
  Logon = 'logon',
  Logoff = 'logoff',
  PowerOff = 'power-off',
  Restart = 'restart',
  KillProcess = 'kill-process'
}

export const SystemEventEffectTypeLabel: { [key in SystemEventEffectType]: string } = {
  [SystemEventEffectType.Logon]: 'Logon',
  [SystemEventEffectType.Logoff]: 'Logoff',
  [SystemEventEffectType.PowerOff]: 'Power Off',
  [SystemEventEffectType.Restart]: 'Restart',
  [SystemEventEffectType.KillProcess]: 'Kill Process'
};
