<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <form autocomplete="off" (ngSubmit)="removeLicenses()" [formGroup]="form">
      <div class="flex justifty-start items-center content-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">Unlicense Machine{{ agents.length > 1 ? 's' : '' }}</h2>

        <button class="text-secondary -mt-2 -mr-2" (click)="close()" mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" class="leading-none"></fa-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border"></mat-divider>
      <mat-dialog-content @fadeInRight *ngIf="processed" class="bg-primary-600 py-4 text-on-primary-600">
        <div *ngIf="form.controls.blockOp.value === '0'; else uninstallAgent">
          Blocking Machine(s) From Licensing For <b>{{ form.controls.blockFor.value }}</b> Minutes
        </div>
        <ng-template #uninstallAgent>Processing Agent{{ agents.length > 1 ? 's' : '' }} </ng-template>
      </mat-dialog-content>
      <mat-divider class="-mx-6 text-border"></mat-divider>

      <mat-dialog-content (scroll)="onScroll($event)" class="grid grid-cols-1 gap-4 py-4">
        <div *ngIf="!processed" @fadeInRight>
          <blockquote>
            This will stop the machine{{ agents.length > 1 ? 's' : '' }} from reporting metrics and fulfilling requests
          </blockquote>

          <div class="flex justify-start items-center content-center pb-4">
            <mat-slide-toggle formControlName="includeMachineID">
              {{ form.controls.includeMachineID.value ? 'Include Machine ID' : 'Exclude Machine ID' }}
            </mat-slide-toggle>
            <mat-slide-toggle formControlName="includeIP" class="px-4">
              {{ form.controls.includeIP.value ? 'Include IP Address' : 'Exclude IP Address' }}
            </mat-slide-toggle>
          </div>

          <mat-radio-group aria-label="Select an option" class="flex flex-col" formControlName="blockOp">
            <mat-radio-button value="0" (change)="updateUninstall(0)">
              <mat-form-field appearance="fill">
                <mat-label>Block Machine{{ agents.length > 1 ? 's' : '' }} For</mat-label>
                <input formControlName="blockFor" matInput cdkFocusInitial type="number" min="0" max="9999" />
                <span matSuffix> Minutes</span>
              </mat-form-field>
            </mat-radio-button>

            <mat-radio-button value="1" (change)="updateUninstall(1)">
              <mat-form-field appearance="fill">
                <mat-label>Block Machine(s) Until</mat-label>
                <input formControlName="blockFor" matInput cdkFocusInitial [matDatepicker]="picker" formControlName="blockUntil" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </mat-radio-button>

            <mat-radio-button value="2" class="py-4" (change)="updateUninstall(2)">Block Indefinitely</mat-radio-button>
          </mat-radio-group>

          <div class="flex justify-center items-center content-center pb-4">
            <mat-slide-toggle formControlName="uninstall">
              {{ form.controls.uninstall.value ? 'Uninstall Agent' : 'Do Not Uninstall Agent' + (agents.length > 1 ? 's' : '') }}
            </mat-slide-toggle>
          </div>
        </div>

        <div
          @fadeInRight
          (@fadeInRight.start)="autoScrollToEnd && $event.element.scrollIntoView()"
          class="flex justify-evenly"
          *ngFor="let item of processedItems"
        >
          <div class="flex-8/10">{{ item.agent.id }}</div>
          <div [ngSwitch]="item.processResult">
            <ng-container *ngSwitchCase="0">
              <fa-icon @fadeInUp animation="spin" [icon]="['fas', 'certificate']" size="lg" class="flex-2/10 text-red"> </fa-icon>
            </ng-container>
            <ng-container *ngSwitchCase="1">
              <fa-icon @fadeInUp [icon]="['fad', 'check-square']" size="lg" class="flex-2/10 text-green"> </fa-icon>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <fa-icon @fadeInUp [icon]="['fad', 'exclamation-square']" size="lg" class="flex-2/10 text-red"> </fa-icon>
            </ng-container>
          </div>
        </div>

        <div @fadeInRight *ngIf="err" class="card bg-red justify-between flex text-white">
          <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="justify-end">
        <button mat-button type="submit" [disabled]="spin" cdkFocusInitial>{{ buttonText }}</button>
      </mat-dialog-actions>
    </form>
  </div>
</mon-spinner>
