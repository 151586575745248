import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subscription, timer } from 'rxjs';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Agent } from 'src/app/shared/models/agent.model';
import { HistoryHourDetail } from 'src/app/shared/models/history.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

import { AgentService } from './agent.service';
import { ApiService, ServiceState } from './api.service';
import { SelectedAgentState, StateService } from './state.service';

export interface BufferedIndexResult {
  index: number;
  result: 0 | 1 | 2;
}

@Injectable({
  providedIn: 'root'
})
export class AgentHistoryPlayerService extends ApiService {
  private _state = new BehaviorSubject<ServiceState>(ServiceState.Stopped);
  state$ = this._state.asObservable();
  get state(): ServiceState {
    return this._state.value;
  }
  set state(value: ServiceState) {
    if (this._state.value === value) {
      return;
    }
    this._state.next(value);
  }

  private _hourDetail = new BehaviorSubject<HistoryHourDetail>(undefined);
  hourDetail$ = this._hourDetail.asObservable();
  get hourDetail(): HistoryHourDetail {
    return this._hourDetail.value;
  }

  private _index = new BehaviorSubject<number>(0);
  index$ = this._index.asObservable();
  get index(): number {
    return this._index.value;
  }

  private _bufferedIndex = new BehaviorSubject<BufferedIndexResult>(undefined);
  bufferedIndex$ = this._bufferedIndex.asObservable();
  get bufferedIndex(): BufferedIndexResult {
    return this._bufferedIndex.value;
  }

  private _historyAgent = new BehaviorSubject<Agent>(undefined);
  historyAgent$ = this._historyAgent.asObservable();
  get historyAgent(): Agent {
    return this._historyAgent.value;
  }

  _timerSpeed = new BehaviorSubject<number>(1000);
  get timerSpeed(): number {
    return this._timerSpeed.value;
  }

  timer$ = this._timerSpeed.pipe(
    switchMap((speed) => timer(0, speed)),
    filter(() => !!this.hourDetail),
    tap(() => {
      if (this._index.value >= this.hourDetail.collectionTimestamps.length) {
        this.stop();
      }
    }),
    filter(() => this.buffer[this.nextIndex + 1] !== undefined), // do not proceed until the agent is buffered
    tap(() => {
      this._index.next(++this.nextIndex);
      this.setAgent(this.buffer[this.nextIndex]);
    })
  );

  private timerSubscription: Subscription;
  private nextIndex = 0;

  private bufferSubscription = new Map<number, Subscription>();
  private buffer: Agent[];
  private bufferCount: 0;

  constructor(
    private router: Router,
    private stateSvc: StateService,
    private agentSvc: AgentService
  ) {
    super();

    combineLatest([this.stateSvc.liveMode$, this.stateSvc.selectedAgent$])
      .pipe(
        tap(([liveMode]) => {
          if (liveMode) {
            this.setAgent(undefined);
          }
        }),
        filter(([liveMode]) => !liveMode),
        mergeMap(([, selectedAgent]) => {
          return selectedAgent.state === SelectedAgentState.Found
            ? this.agentSvc.getHistoryAgentLatest(selectedAgent.agentID)
            : UNDEFINED;
        }),
        filter((agent) => !!agent),
        tap((agent) => this.setAgent(agent))
      )
      .subscribe();

    this.hourDetail$
      .pipe(
        tap((hourDetail) => {
          this.buffer = Array.from<Agent>(
            Array(hourDetail ? hourDetail.collectionTimestamps.length : 0)
          );
          this.bufferCount = 0;
        }),
        filter((hourDetail) => !!hourDetail),
        tap(() => {
          this.bufferSubscription.forEach((s) => s.unsubscribe());
          this.bufferAgentAtTime(0, true);
        })
      )
      .subscribe();
  }

  setDetail(detail: HistoryHourDetail): this {
    this._hourDetail.next(detail);
    return this;
  }

  setIndex(index: number): this {
    if (this.nextIndex === index) {
      return this;
    }

    this.nextIndex = index;

    if (this.hourDetail) {
      this.bufferSubscription.forEach((s) => s.unsubscribe());
      this.bufferAgentAtTime(this.nextIndex, true);
    }

    this._index.next(this.nextIndex);

    return this;
  }

  setSpeed(speed: number): this {
    this._timerSpeed.next(speed);
    return this;
  }

  togglePlay(): void {
    if (this.state === ServiceState.Stopped) {
      this._index.next(0);
      this.state = ServiceState.Starting;
    }
    if (this.state === ServiceState.Started && !!this.timerSubscription) {
      this.pause();
    } else {
      this.play();
    }
  }

  pause(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.state === ServiceState.Paused) {
      return;
    }

    this.log('PAUSING');
    this.state = ServiceState.Paused;
  }

  play(): void {
    if (this.state === ServiceState.Started) {
      return;
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.state = ServiceState.Started;
    this.log('PLAYING');
    this.timerSubscription = this.timer$.subscribe();
  }

  stop(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.state === ServiceState.Stopped) {
      return;
    }

    this.state = ServiceState.Stopped;
    this.log('DONE');
  }

  clearBuffer(): void {
    this.stop();
    this.bufferSubscription.forEach((s) => s.unsubscribe());
    this.buffer = [];
    this.bufferCount = 0;
  }

  private setAgent(agent: Agent): void {
    if (agent !== null) {
      this._historyAgent.next(agent);

      if (!this.historyAgent?.stats) {
        this.router.navigate(window.location.pathname.split('/'), {
          skipLocationChange: true,
          queryParams: null,
          queryParamsHandling: 'merge'
        });
      }
    }
  }

  private bufferAgentAtTime(index: number, setAgent = false) {
    const setAgentObservable = (i: number) => {
      if (setAgent) {
        setAgent = false;
        this.setAgent(this.buffer[i]);
      }
    };

    while (true) {
      if (this.bufferCount >= this.hourDetail.collectionTimestamps.length) {
        setAgentObservable(index);
        return;
      }

      if (index >= this.hourDetail.collectionTimestamps.length) {
        index = 0;
      }

      if (this.buffer[index] === undefined) {
        break;
      } else {
        setAgentObservable(index);
        ++index;
      }
    }

    const bufferNext = (agent: Agent) => {
      this.bufferCount++;
      this.buffer[index] = agent;

      this._bufferedIndex.next({
        index: index,
        result: agent === undefined ? 0 : agent === null ? 1 : 2
      });

      if (this.bufferCount >= this.hourDetail.collectionTimestamps.length) {
        this.log('DONE BUFFERING');
      }

      const s = this.bufferSubscription.get(index);
      if (s.closed) {
        this.bufferSubscription.delete(index);
      } else {
        this.bufferAgentAtTime(++index);
      }
    };

    this.bufferSubscription.set(
      index,
      this.agentSvc
        .getHistoryAgent(
          this.hourDetail.agentID,
          this.hourDetail.collectionTimestamps[index]
        )
        .subscribe({
          next: (agent) => {
            const currentIndex = index;

            bufferNext(agent);

            setAgentObservable(currentIndex);
          },
          error: () => {
            bufferNext(null);
          }
        })
    );
  }
}
