<div *ngIf="isDivider(item)">
  <hr />
</div>

<a
  @fadeInRight
  *ngIf="isFunc(item)"
  (click)="item.func()"
  [matTooltip]="item.tooltip"
  class="item flex items-center cursor-pointer w-full"
  matRipple
>
  <span class="flex items-center justify-center">
    <ng-container *ngIf="!item.iconStack; else iconStack">
      <fa-icon
        *ngIf="level === 0 && item.icon"
        [icon]="['fad', item.icon]"
        size="sm"
        [fixedWidth]="true"
        class="item-icon item-color item-size"
      >
      </fa-icon>
    </ng-container>
    <ng-template #iconStack>
      <fa-stack class="icon-size text-xs">
        <fa-icon [icon]="['fas', 'square']" stackItemSize="2x" class="item-icon item-color"></fa-icon>
        <fa-icon [icon]="['fad', item.icon]" class="item-color item-icon opacity-50" [inverse]="true" stackItemSize="1x"></fa-icon>
      </fa-stack>
    </ng-template>
  </span>
  <span class="flex-auto truncate item-label">{{ item.label }}</span>
</a>

<a
  @fadeInRight
  *ngIf="isExternal(item)"
  [href]="item['href']"
  [target]="item['target']"
  class="item relative flex items-center cursor-pointer w-full"
  matRipple
  [matTooltip]="item.tooltip"
>
  <span class="flex items-center justify-center">
    <ng-container *ngIf="!item.iconStack; else iconStack">
      <fa-icon
        *ngIf="level === 0 && item.icon"
        [icon]="['fad', item.icon]"
        size="sm"
        [fixedWidth]="true"
        class="item-icon item-color item-size"
      >
      </fa-icon>
    </ng-container>
    <ng-template #iconStack>
      <fa-stack class="icon-size text-xs">
        <fa-icon [icon]="['fas', 'square']" stackItemSize="2x" class="item-icon item-color"></fa-icon>
        <fa-icon [icon]="['fad', item.icon]" [inverse]="true" stackItemSize="1x"></fa-icon>
      </fa-stack>
    </ng-template>
  </span>
  <span class="flex-auto truncate item-label">{{ item.label }}</span>
</a>

<a
  @fadeInRight
  *ngIf="isLink(item) && !isFunction(item)"
  [fragment]="item['fragment']"
  [routerLinkActiveOptions]="item['routerLinkActive'] || { exact: false }"
  [routerLink]="item['route']"
  queryParamsHandling="preserve"
  class="item relative flex items-center cursor-pointer w-full"
  matRipple
  routerLinkActive="active"
  [matTooltip]="item.tooltip"
>
  <span class="flex items-center justify-center">
    <ng-container *ngIf="!item.iconStack; else iconStack">
      <fa-icon
        *ngIf="level === 0 && item.icon"
        [icon]="['fad', item.icon]"
        size="sm"
        [fixedWidth]="true"
        [flip]="item.iconFlip ? item.iconFlip : null"
        [rotate]="item.iconRotate ? item.iconRotate : null"
        class="item-icon item-color item-size"
      >
      </fa-icon>
    </ng-container>
    <ng-template #iconStack>
      <fa-stack class="icon-size text-xs">
        <fa-icon [icon]="['fas', 'square']" stackItemSize="2x" class="item-icon item-color"></fa-icon>
        <fa-icon [icon]="['fad', item.icon]" [inverse]="true" stackItemSize="1x"></fa-icon>
      </fa-stack>
    </ng-template>
  </span>
  <span class="flex-auto truncate item-label">{{ item.label }}</span>
  <span
    *ngIf="item['badge']"
    [style.background]="item['badge'].background"
    [style.color]="item['badge'].color"
    class="item-badge text-center flex-none"
    >{{ item['badge'].value }}</span
  >
</a>

<div
  @fadeInRight
  *ngIf="isLink(item) && isFunction(item)"
  (click)="runRouteFunction(item)"
  class="item flex"
  matRipple
  routerLinkActive="active"
  [matTooltip]="item.tooltip"
>
  <span class="flex items-center justify-center">
    <fa-icon *ngIf="level === 0" [icon]="['fad', item.icon]" size="sm" [fixedWidth]="true" class="item-icon item-color item-size flex-none">
    </fa-icon>
  </span>
  <span class="flex-auto truncate item-label">{{ item.label }}</span>
  <span
    *ngIf="item['badge']"
    [style.background]="item['badge'].background"
    [style.color]="item['badge'].color"
    class="item-badge text-center"
    >{{ item['badge'].value }}</span
  >
</div>

<div @fadeInRight *ngIf="isDropdown(item)" class="dropdown">
  <div
    *ngIf="hasChildren(item) | async"
    (click)="toggleOpen()"
    [class.active]="isOpen || isActive"
    [class.open]="isOpen"
    class="dropdown-label item flex items-center justify-between cursor-pointer"
    matRipple
  >
    <span class="flex items-center justify-center">
      <fa-icon *ngIf="level === 0" [icon]="['fad', item.icon]" size="sm" [fixedWidth]="true" class="item-icon item-color item-size">
      </fa-icon>
    </span>
    <div class="w-full">
      <span class="flex-1 truncate item-label">{{ item.label }}</span>
    </div>
    <span
      *ngIf="item['badge']"
      [style.background]="item['badge'].background"
      [style.color]="item['badge'].color"
      class="item-badge text-center"
      >{{ item['badge'].value }}</span
    >
    <fa-icon *ngIf="level === 0" [icon]="['fad', 'angle-right']" [fixedWidth]="true" class="item-dropdown-icon flex-none"> </fa-icon>
  </div>
  <div [@dropdown]="isOpen" class="dropdown-items overflow-hidden">
    <ng-container *ngFor="let subItem of item['children']">
      <ng-container *ngIf="!subItem.hidden">
        <vex-sidenav-item *hasPerm="subItem.requiredPerms; has: 'any'" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
      </ng-container>
    </ng-container>
  </div>
</div>

<div @fadeInRight *ngIf="isSubheading(item)" [attr.data-intro-key]="!!item.introID ? item.introID : null">
  <div class="subheading">
    <div class="label uppercase whitespace-nowrap item-label text-primary text-xs">{{ item.label }}</div>
    <ng-container *ngFor="let subItem of item['children']">
      <ng-container *ngIf="!subItem.hidden">
        <vex-sidenav-item *hasPerm="subItem.requiredPerms" [item]="subItem" [level]="0"></vex-sidenav-item>
      </ng-container>
    </ng-container>
  </div>
</div>
