export class PermissionValue {
  id: string;
  name: string;
  value: number;
  type: 'role' | 'user';

  get uniqueID(): string {
    return `${this.type}:${this.id}`;
  }

  constructor(init?: Partial<PermissionValue>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  get read(): boolean {
    return (this.value & 4) === 4;
  }

  get write(): boolean {
    return (this.value & 2) === 2;
  }

  get invoke(): boolean {
    return (this.value & 1) === 1;
  }
}

export class Permissions {
  roles: Map<string, PermissionValue>;
  users: Map<string, PermissionValue>;

  constructor(init?: Partial<Permissions>) {
    const roles = new Map<string, PermissionValue>();
    const users = new Map<string, PermissionValue>();

    if (init) {
      Object.assign(this, init);

      if (this.roles) {
        Object.keys(this.roles).map((k) => {
          roles.set(
            k,
            new PermissionValue({
              id: k,
              name: this.roles[k].name,
              value: this.roles[k].value,
              type: 'role'
            })
          );
        });
      }

      if (this.users) {
        Object.keys(this.users).map((k) => {
          users.set(
            k,
            new PermissionValue({
              id: k,
              name: this.users[k].name,
              value: this.users[k].value,
              type: 'user'
            })
          );
        });
      }
    }

    this.roles = roles;
    this.users = users;
  }

  add(perm: PermissionValue): void {
    switch (perm.type) {
      case 'role':
        this.roles.set(perm.id, perm);
        break;
      case 'user':
        this.users.set(perm.id, perm);
        break;
      default:
        return;
    }
  }

  delete(perm: PermissionValue): void {
    switch (perm.type) {
      case 'role':
        this.roles.delete(perm.id);
        break;
      case 'user':
        this.users.delete(perm.id);
        break;
      default:
        return;
    }
  }

  asRaw() {
    return {
      roles: [...this.roles.values()].reduce((m, r) => {
        m.set(r.id, r.value);
        return m;
      }, new Map<string, number>()),
      users: [...this.users.values()].reduce((m, r) => {
        m.set(r.id, r.value);
        return m;
      }, new Map<string, number>())
    };
  }

  static getValue(read: boolean, write: boolean, invoke: boolean): number {
    let value = 0;
    if (read) {
      value += 4;
    }
    if (write) {
      value += 2;
    }
    if (invoke) {
      value += 1;
    }

    return value;
  }
}
