<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex justify-start items-center content-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">Switch Tenant</h2>

      <button mat-dialog-close mat-icon-button type="button" [disabled]="spin" class="sm-icon-button">
        <!-- <mat-icon>open_in_new</mat-icon> -->
        <fa-icon [icon]="['fad', 'times']" class="text-secondary"></fa-icon>
      </button>
    </div>

    <mat-divider></mat-divider>

    <mat-dialog-content class="flex flex-col min-h-1/2">
      <mat-selection-list [multiple]="false" hideSingleSelectionIndicator>
        <mat-list-option *ngFor="let tenant of tenants$ | async" [value]="tenant" (click)="switchTenant(tenant)">
          <ng-container *ngIf="sameTenantNames$ | async as sameTenantNames">
            <div class="flex justify-center items-center content-center">
              <div class="align-middle text-pink flex-1/10">
                <fa-icon *ngIf="tenant.id === currentTenantID" [icon]="['fad', 'asterisk']" size="lg"> </fa-icon>
              </div>
              <div class="text-left w-full pl-3 flex-8/10">
                <div
                  >{{ tenant.name }}
                  <span matTooltip="Last 4 digits of the tenant ID" *ngIf="sameTenantNames.get(tenant.name)" class="text-secondary">
                    - {{ tenant.id.substring(0, 4) }}</span
                  >
                </div>
                <div class="text-secondary text-xs">{{ tenant.companyName }}</div>
              </div>
              <div class="text-right flex-1/10">
                <fa-icon [icon]="['fad', 'angle-right']" size="lg" class="text-gray"> </fa-icon>
              </div>
            </div>
          </ng-container>
        </mat-list-option>
      </mat-selection-list>

      <div @fadeInRight *ngIf="err" class="card bg-red justify-between flex text-white">
        <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
        <pre class="p-2">
            <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="closeError()"></fa-icon>
          </pre>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="justify-end">
      <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
    </mat-dialog-actions>
  </div>
</mon-spinner>
