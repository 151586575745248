import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TenantService } from 'src/app/core/services/tenant.service';
import { AgentBlock } from 'src/app/shared/models/agent-block.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';

export class BlockedMachinesDataSource implements DataSource<AgentBlock> {
  private _agentBlocks = new BehaviorSubject<AgentBlock[]>(undefined);
  private _initialLoading = new BehaviorSubject<boolean>(true);
  public initialLoading$ = this._initialLoading.asObservable();
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();

  public serverResultsLength: number;
  public filteredLength: number;
  public get data(): AgentBlock[] {
    return this._agentBlocks.value?.slice() || [];
  }

  constructor(private tenantSvc: TenantService) {}

  loadBlockedMachines(filter = '', orderBy: OrderBy[]): void {
    orderBy = orderBy || [];
    this._loading.next(true);

    this.tenantSvc
      .getBlockedAgents()
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this._initialLoading.next(false);
          this._loading.next(false);
        })
      )
      .subscribe({
        next: (blocks: AgentBlock[]) => {
          let results = blocks;
          this.serverResultsLength = results.length;

          if (filter) {
            results = results.filter(
              (b) =>
                b.agentID.indexOf(filter) >= 0 ||
                b.machineID.indexOf(filter) >= 0 ||
                b.ip.indexOf(filter) >= 0
            );
          }

          if (orderBy.length > 0 && orderBy[0].dir > 0) {
            results = OrderBy.Sort(results, orderBy[0]);
          }

          this.filteredLength = results.length;
          this._agentBlocks.next(results || []);
          this._loading.next(false);
          return;
        }
      });
  }

  connect(): Observable<AgentBlock[]> {
    return this._agentBlocks.asObservable();
  }

  disconnect(): void {
    this._agentBlocks.complete();
    this._loading.complete();
  }
}
