import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  @Input('autoFocus') focus = true;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.focus) {
      setTimeout(() => this.elementRef.nativeElement.focus(), 250);
    }
  }
}
