<ng-container *ngIf="latestResult$ | async"></ng-container>

<mon-spinner [spin]="spin" [overlay]="true">
  <div class="flex flex-col w-full h-full" content>
    <ng-container *ngLet="tracerouteResult$ | async as tracerouteResult">
      <div *ngIf="tracerouteResult !== undefined; else noResults" class="flex-1 flex flex-col w-full h-full">
        <div class="flex-1 relative w-full h-full">
          <div class="code-wrapper absolute top-0 bottom-0 left-0 right-0 overflow-auto" #output>
            <markdown
              class="prose prose-xs dark:prose-invert prose-pre:inline-block prose-pre:mb-0 prose-pre:px-2 prose-pre:py-1 prose-pre:pb-4 selection:bg-pink-light text-3xs fix-markdown-padding"
              [data]="tracerouteResult | language: ''"
            >
            </markdown>
          </div>
          <button
            #copyScript="matTooltip"
            matRipple
            matTooltip=""
            class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
            (click)="getCopy(copyScript, tracerouteResult)"
          >
            copy
          </button>
        </div>
        <div class="flex items-center">
          <ng-container *ngIf="running$ | async; else notRunning">
            <ng-container *ngIf="channelListener$ | async"></ng-container>
            <div class="flex-1 text-secondary" [style.font-variant]="'small-caps'">Running...</div>
            <div class="flex-1 whitespace-nowrap text-xs text-right text-green animate-pulse">
              <fa-icon [icon]="['fas', 'wave-pulse']"></fa-icon>
            </div>
          </ng-container>
          <ng-template #notRunning>
            <div class="flex-1 text-secondary" [style.font-variant]="'small-caps'">Last Results</div>
            <div class="flex-1 whitespace-nowrap text-xs text-right text-secondary">{{ lastRan | date: 'short' }}</div>
          </ng-template>
        </div>
      </div>
      <ng-template #noResults>
        <div class="flex-1 flex justify-center p-12 w-full h-full">
          <div class="italic text-secondary">{{ spin ? '' : 'No results' }}</div>
        </div>
      </ng-template>
    </ng-container>
    <div *ngIf="agent$ | async as agent" class="flex w-full items-center justify-center">
      <form (ngSubmit)="run(agent.id)" [formGroup]="form" autocomplete="off" class="flex w-full items-center justify-center gap-2">
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput formControlName="ipToTrace" [readonly]="spin || (running$ | async)" />
          <mat-error *ngIf="form.controls.ipToTrace.errors?.required">Address cannot be blank</mat-error>
          <mat-error *ngIf="form.controls.ipToTrace.errors?.pattern">Address must be properly formatted</mat-error>
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary" [disabled]="spin || form.invalid || (running$ | async)">Traceroute!</button>
      </form>
    </div>
  </div>
</mon-spinner>
