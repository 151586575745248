import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[creditCard]',
  standalone: true
})
export class CreditCardDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    const { selectionStart } = input;

    let trimmedCardNum = value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

    /* Handle American Express 4-6-5 spacing format */
    const partitions =
      trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
        ? [4, 6, 5]
        : [4, 4, 4, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach((partition) => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    });

    input.value = numbers.join(' ');

    /* Handle caret position if user edits the number later */
    if (selectionStart < value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }
}
