import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  tap
} from 'rxjs/operators';
import { fadeInRight40ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { StateService } from 'src/app/core/services/state.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { isEmpty } from 'src/app/utils/condition';
import { SelectionModel } from '@angular/cdk/collections';
import { TriggersDataSource } from './triggers.datasource';
import { MatDialog } from '@angular/material/dialog';
import {
  TriggerEditComponent,
  TriggerEditParams
} from './trigger-edit/trigger-edit.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { ConfirmDialog } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { BehaviorSubject, combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'mon-triggers',
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.scss'],
  animations: [stagger20ms, fadeInRight40ms, fadeInUp400ms]
})
export class TriggersComponent {
  readonly DEFAULT_PAGE_SIZE = 20;
  readonly DEFAULT_SORT_FIELD = 'name';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.TOOLS_TRIGGERS;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageSize = this.DEFAULT_PAGE_SIZE;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource = new TriggersDataSource(this.triggerSvc);
  selection = new SelectionModel<Trigger>(true, []);

  columns: TableColumn<Trigger>[] = [
    {
      label: 'Checkbox',
      property: 'checkbox',
      type: 'checkbox',
      visible: true,
      hideable: false
    },
    {
      label: 'ID',
      property: 'id',
      type: 'text',
      visible: false,
      hideable: true,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Disabled',
      property: 'disabled',
      type: 'text',
      visible: false,
      hideable: true
    },
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      hideable: false
    },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: true,
      hideable: false
    },
    {
      label: 'Conditions',
      property: 'conditionsD',
      type: 'text',
      visible: true,
      hideable: true,
      sortable: false
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'buttonArray',
      visible: true,
      hideable: false,
      buttons: [
        {
          icon: 'clone',
          tooltip: 'Copy',
          fn: (t: Trigger): void => this.editTrigger(t, true),
          buttonClasses: ['hover:bg-primary-light'],
          iconClasses: ['text-primary']
        },
        {
          icon: 'angle-right',
          tooltip: 'View',
          fn: (t: Trigger): void => this.editTrigger(t)
        }
      ]
    }
  ];

  _spinner = new BehaviorSubject<boolean>(false);
  spinner$ = this._spinner.asObservable();

  loading$ = combineLatest([this.dataSource.loading$, this.spinner$]).pipe(
    map(([loading, spinner]) => loading || spinner)
  );

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    filter((settings) => !!settings),
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        pageSize: found?.pageSize || this.DEFAULT_PAGE_SIZE,
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.pageSize = isEmpty(settings.pageSize)
        ? this.DEFAULT_PAGE_SIZE
        : settings.pageSize;
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    }),
    tap(() => this.loadTriggers())
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  controlsPopulated = (): boolean => !!this.paginator && !!this.sort;
  paginator: MatPaginator;
  @ViewChild(MatPaginator) set paginatorSetter(paginator: MatPaginator) {
    if (!this.paginator && !!paginator) {
      this.paginator = paginator;
      this.initControls();
    }
  }
  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  get hideableColumns(): TableColumn<Trigger>[] {
    return this.columns.filter((c) => c.hideable);
  }

  constructor(
    private dialog: MatDialog,
    private confirm: ConfirmDialog,
    private snackBar: MatSnackBar,
    private stateSvc: StateService,
    private triggerSvc: TriggerService
  ) {}

  initControls(): void {
    if (this.controlsPopulated()) {
      this.sort.sortChange
        .pipe(
          untilDestroyed(this),
          tap({
            next: (sort) => {
              const table = {};
              table[this.tableID] = {
                sortField: sort.active,
                sortDirection: sort.direction
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            }
          })
        )
        .subscribe();

      this.paginator.page
        .pipe(
          untilDestroyed(this),
          tap((page) => {
            this.pageIndex = page.pageIndex;
            if (this.pageSize !== page.pageSize) {
              const table = {};
              table[this.tableID] = {
                pageSize: page.pageSize
              };
              this.stateSvc.updateCurrentUserSettings({
                consoleUI: {
                  table
                }
              });
            } else {
              this.loadTriggers();
            }
          })
        )
        .subscribe();
    }
  }

  loadTriggers(): void {
    this.dataSource.loadTriggers('', this.pageSize, this.pageIndex, [
      OrderBy.SortToOrderBy({
        active: this.sortField,
        direction: this.sortDirection
      })
    ]);
  }

  masterToggle(change: MatCheckboxChange): void {
    if (change.checked) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  editTrigger(trigger?: Trigger, copy = false): void {
    let triggerID = null;
    let onSuccess = null;

    if (trigger) {
      triggerID = trigger.id;
      onSuccess = () => {
        this.loadTriggers();
      };
    } else {
      onSuccess = () => {
        this.loadTriggers();
      };
    }

    this.dialog.open(TriggerEditComponent, {
      disableClose: false,
      restoreFocus: false,
      panelClass: 'trigger-edit',
      data: new TriggerEditParams({
        copy,
        triggerID: triggerID,
        onSuccess: onSuccess
      })
    });
  }

  enableTrigger(triggers: Trigger[]) {
    this._spinner.next(true);

    this.triggerSvc
      .enable(triggers.map((t) => t.id))
      .pipe(
        tap(() => {
          this.snackBar.open(
            `${triggers.length > 1 ? triggers.length : triggers[0].name} enabled`,
            'CLOSE',
            {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            }
          );
          triggers.forEach((t) => (t.disabled = false));
        }),
        catchError((err) => {
          this.snackBar.open(parseError(err), 'CLOSE', {
            duration: 3000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });

          return UNDEFINED;
        }),
        finalize(() => this._spinner.next(false))
      )
      .subscribe();
  }

  disableTrigger(triggers: Trigger[]) {
    this._spinner.next(true);

    this.triggerSvc
      .disable(triggers.map((t) => t.id))
      .pipe(
        tap(() => {
          this.snackBar.open(
            `${triggers.length > 1 ? triggers.length : triggers[0].name} disabled`,
            'CLOSE',
            {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            }
          );
          triggers.forEach((t) => (t.disabled = true));
        }),
        catchError((err) => {
          this.snackBar.open(parseError(err), 'CLOSE', {
            duration: 3000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });

          return UNDEFINED;
        }),
        finalize(() => this._spinner.next(false))
      )
      .subscribe();
  }

  removeTrigger(triggers: Trigger[]) {
    this._spinner.next(true);

    this.confirm.open(
      triggers.length > 1
        ? `Remove ${triggers.length} triggers?`
        : `Remove ${triggers[0].name}?`,
      () => {
        this.triggerSvc
          .delete(triggers.map((t) => t.id))
          .pipe(
            tap(() => {
              // toast message
              this.snackBar.open(
                `${triggers.length > 1 ? triggers.length : triggers[0].name} removed`,
                'CLOSE',
                {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                }
              );
              this.loadTriggers();
            }),
            catchError((err) => {
              this.snackBar.open(parseError(err), 'CLOSE', {
                duration: 3000,
                panelClass: ['error-snack'],
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });
              return UNDEFINED;
            }),
            finalize(() => this._spinner.next(false))
          )
          .subscribe();
      }
    );
  }
}
