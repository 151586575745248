import { Updatable } from '../interfaces/updatable.interface';

export class IPLocation implements Updatable<IPLocation> {
  public status: string;
  public country: string;
  public countryCode: number;
  public region: string;
  public regionName: string;
  public city: string;
  public zip: string;
  public lat: number;
  public lon: number;
  public timezone: string;
  public isp: string;
  public org: string;
  public as: string;
  public query: string;
  public timestamp: Date;

  constructor(init?: Partial<IPLocation>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);
  }

  update(input: Partial<IPLocation>): this {
    return Object.assign(this, input);
  }
}
