<ng-container *ngIf="settings$ | async as settings"></ng-container>
<ng-container *ngIf="!(dataSource.initialLoading$ | async)">
  <div [hidden]="dataSource.serverResultsLength === 0" @fadeInUp class="card relative overflow-x-auto overflow-y-hidden">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
      <h2 class="title my-0 pr-4 mr-4 border-r flex-none hidden md:block">
        <span>Blocked Machines</span>
      </h2>

      <span class="flex-1"></span>

      <div class="bg-card rounded-md border px-2 relative flex justify-start items-center content-center flex-auto">
        <fa-icon [icon]="['fad', 'search']" size="lg" [fixedWidth]="true"></fa-icon>
        <input
          #filterInput
          [formControl]="searchCtrl"
          class="pl-2 py-2 border-0 outline-none w-full bg-transparent"
          placeholder="Search Machines..."
        />
        <button
          mat-icon-button
          [style.visibility]="filterInput.value ? 'visible' : 'hidden'"
          (click)="searchCtrl.setValue(''); filterInput.focus()"
          class="flex items-center justify-center w-6 h-6"
          matSuffix
        >
          <fa-icon [icon]="['fad', 'times']" [fixedWidth]="true"></fa-icon>
        </button>
      </div>

      <button class="ml-4 flex-none" mat-icon-button matTooltip="Add Block" type="button" (click)="addBlock()">
        <fa-icon [icon]="['fad', 'plus']" size="lg"> </fa-icon>
      </button>

      <button
        class="ml-4 leading-none flex-none"
        mat-icon-button
        matTooltip="Remove Block"
        type="button"
        [disabled]="selection.isEmpty()"
        (click)="removeBlock(selection.selected)"
      >
        <fa-icon [icon]="['fad', 'times']" size="lg"> </fa-icon>
      </button>

      <mon-display-config-button
        matTooltip="Filter Columns"
        *ngIf="displayConfig$ | async as config"
        persistAt="User"
        [config]="config"
        [canHide]="false"
        [icon]="['fad', 'columns']"
        class="ml-2 flex items-center justify-center flex-none"
      ></mon-display-config-button>
    </div>

    <div *ngIf="dataSource.loading$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
      <div class="absolute w-full h-full bg-gray opacity-10"></div>
      <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
    </div>

    <table
      [dataSource]="dataSource"
      [@listStagger]="dataSource.filteredLength"
      mat-table
      matSort
      [matSortActive]="this.sortField"
      [matSortDirection]="this.sortDirection"
      class="w-full"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            (change)="masterToggle($event)"
            [checked]="selection.hasValue() && selection.selected.length === dataSource.filteredLength"
            [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.filteredLength"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox
            (change)="$event ? selection.toggle(row) : null"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ getValue(row, column.property) }}
          </td>
        </ng-container>
      </ng-container>

      <!-- ButtonArray Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'buttonArray'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <div class="flex items-center justify-end">
              <ng-container *ngFor="let button of column.buttons">
                <button
                  *ngIf="button.showConditionFn ? button.showConditionFn(row) : true"
                  [matTooltip]="button.tooltip"
                  (click)="button.fn(row)"
                  [ngClass]="button.buttonClasses"
                  class="flex items-center justify-center w-8 h-8 leading-none transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
                  mat-icon-button
                >
                  <fa-icon [icon]="['fad', button.icon]" size="lg" [class]="button.iconClasses"> </fa-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
        <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: visibleColumns" @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer"></tr>
      </ng-container>
    </table>

    <mat-paginator [length]="dataSource.filteredLength"> </mat-paginator>
  </div>

  <div *ngIf="dataSource.serverResultsLength === 0" class="h-full md:h-auto flex flex-col gap-y-2">
    <div @fadeInUp class="card flex-1 overflow-x-auto overflow-y-hidden">
      <div class="py-6 sm:py-12 h-full flex flex-col justify-center items-center content-center">
        <fa-icon [icon]="['fad', 'telescope']" size="5x" class="text-primary text-base sm:text-4xl"></fa-icon>
        <h2 class="m-0 pt-4 text-secondary text-base sm:text-xl">Nothing Here To See</h2>
      </div>
    </div>
    <div @fadeInUp class="card overflow-x-auto overflow-y-hidden p-4">
      <button mat-button type="button" color="primary" (click)="addBlock()">
        <fa-icon [icon]="['fad', 'plus']" class="mr-2" inline="true" size="lg"> </fa-icon>
        <span>Add Block</span>
      </button>
    </div>
  </div>
</ng-container>
