import { DisplayDensity } from 'src/app/shared/enums/table-density.enum';

import { Config } from '../services/config.service';
import { Style } from '../services/style.service';

export const defaultConfig: Config = {
  display: { density: DisplayDensity.Default },
  search: { goToDashboard: true },
  id: 'vex-layout-apollo',
  name: '',
  imgSrc: '//vex.visurel.com/landing/assets/img/layouts/apollo.png',
  layout: 'horizontal',
  boxed: false,
  bottomDrawer: { state: 'expanded' },
  sidenav: { state: 'expanded' },
  toolbar: { fixed: true },
  navbar: { position: 'below-toolbar' },
  footer: {
    visible: false,
    fixed: true
  },
  style: Style.default
};
