import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { BehaviorSubject, iif } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { ServiceService } from 'src/app/core/services/service.service';
import { StateService } from 'src/app/core/services/state.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { Service, ServiceState } from 'src/app/shared/models/service.model';
import { OS } from 'src/app/shared/models/system.model';
import { isEmpty } from 'src/app/utils/condition';

@UntilDestroy()
@Component({
  selector: 'mon-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class ServicesComponent implements OnInit, OnDestroy {
  readonly DEFAULT_SORT_FIELD = 'displayName';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.TASK_MANAGER_SERVICES;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource = new MatTableDataSource<Service>();

  private _filtering = new BehaviorSubject<boolean>(false);
  public filtering$ = this._filtering.asObservable();

  selection = new SelectionModel<Service>(true, []);
  searchCtrl = new UntypedFormControl();
  ServiceState = ServiceState;
  running = false;
  os: string;

  columns: TableColumn<Service>[] = [
    // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true, hideable: false },
    {
      label: 'Name',
      property: 'displayName',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Status',
      property: 'stateD',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['text-secondary', 'truncate', 'font-medium']
    },
    {
      label: 'Flags',
      property: 'serviceFlags',
      type: 'text',
      visible: false,
      hideable: false,
      cellClasses: ['text-secondary', 'truncate', 'font-medium']
    },
    {
      label: 'CA',
      property: 'controlsAccepted',
      type: 'text',
      visible: false,
      hideable: false,
      cellClasses: ['text-secondary', 'truncate', 'font-medium']
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'buttonArray',
      visible: true,
      hideable: false,
      buttons: [
        {
          icon: 'stop',
          tooltip: 'Stop Service',
          fn: this.stopService.bind(this),
          buttonClasses: ['hover:bg-red-light'],
          iconClasses: ['text-red'],
          showConditionFn: (s) => s.canStop
        },
        {
          icon: 'sync-alt',
          tooltip: 'Restart Service',
          fn: this.restartService.bind(this),
          buttonClasses: ['hover:bg-orange-light'],
          iconClasses: ['text-orange'],
          showConditionFn: (s) => s.canStop
        },
        {
          icon: 'play',
          tooltip: 'Start Service',
          fn: this.startService.bind(this),
          buttonClasses: ['hover:bg-green-light'],
          iconClasses: ['text-green'],
          showConditionFn: (s) => !s.canStop && s.state == ServiceState.Stopped
        },
        {
          icon: 'ban',
          tooltip: 'Disable Service',
          fn: this.disableService.bind(this),
          buttonClasses: ['hover:bg-gray-light'],
          iconClasses: ['text-gray'],
          showConditionFn: (s) => s.canStop && this.os === OS.WINDOWS
        },
        {
          icon: 'minus-circle',
          tooltip: 'Remove Service',
          fn: this.deleteService.bind(this),
          buttonClasses: ['hover:bg-red-light'],
          iconClasses: ['text-red'],
          showConditionFn: (s) => s.canStop && this.os === OS.WINDOWS
        }
      ]
    }
  ];

  agentStats$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$
      )
    ),
    untilDestroyed(this),
    filter((agent) => !!agent),
    tap((agent) => (this.os = agent.system.os)),
    map((agent) => agent.stats),
    filter((stats) => stats && stats.services.size > 0),
    tap((stats) => {
      if (this.running) {
        return;
      }
      this.running = true;
      this.updateDataSource(stats.services); //need to clone map
      // this.updateDataSource(deepCopy(stats.services)); //need to clone map
      this.running = false;
    })
  );

  sortSettings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        sortField: found?.sortField != null ? found?.sortField : this.DEFAULT_SORT_FIELD,
        sortDirection:
          found?.sortDirection != null
            ? found?.sortDirection
            : this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    })
  );

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  get hideableColumns(): TableColumn<Service>[] {
    return this.columns.filter((c) => c.hideable);
  }

  constructor(
    private dialog: MatDialog,
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private serviceSvc: ServiceService
  ) {
    this.searchCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => this._filtering.next(true))
      )
      .subscribe({
        next: (value) => {
          if (!this.dataSource) {
            return;
          }
          value = value.trim();
          value = value.toLowerCase();
          this.dataSource.filter = value;
          this._filtering.next(false);
        }
      });
  }

  ngOnInit(): void {
    this.dataSource.filterPredicate = (data, f) => {
      const filterNum = parseFloat(f);
      if (!isNaN(filterNum)) {
        if (data.processID === filterNum) {
          return true;
        }
      }
      if (data.stateD.toLowerCase().indexOf(f) >= 0) {
        return true;
      }
      if (data.serviceName.toLowerCase().indexOf(f) >= 0) {
        return true;
      }
      if (data.displayName.toLowerCase().indexOf(f) >= 0) {
        return true;
      }
      return false;
    };
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  initControls(): void {
    this.dataSource.sort = this.sort;

    this.sort.sortChange
      .pipe(
        untilDestroyed(this),
        tap((sort) => {
          const table = {};
          table[this.tableID] = {
            sortField: sort.active,
            sortDirection: sort.direction
          };
          this.stateSvc.updateCurrentUserSettings({
            consoleUI: {
              table
            }
          });
        })
      )
      .subscribe();
  }

  updateDataSource(agentServices: Map<string, Array<Service>>): void {
    const servicesMap = new Map<string, Service>();
    const services = new Array<Service>();

    agentServices.forEach((internalServices) =>
      internalServices.forEach((s) => {
        servicesMap.set(s.serviceName, s);
        services.push(s);
      })
    );

    if (this.dataSource.data.length < 10) {
      this.dataSource.data = services;
    } else {
      for (let i = 0; i < this.dataSource.data.length; ) {
        const oldService = this.dataSource.data[i];
        const newService = servicesMap.get(oldService.serviceName);

        if (newService != null) {
          if (oldService != null) {
            if (!oldService.equals(newService)) {
              Object.assign(oldService, newService);
            }
            servicesMap.delete(oldService.serviceName);
          }
          i++;
        } else {
          this.dataSource.data.splice(i, 1);
        }
      }

      servicesMap.forEach((s) => {
        if (s) {
          this.dataSource.data.push(s);
        }
      });
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(change: MatCheckboxChange): void {
    if (change.checked) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  stopService(service: Service): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'stop',
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: 'Stop?',
        confirmationText: `Would you like to stop ${service.displayName}?`,
        onConfirm: () => {
          this.serviceSvc.stop(agentID, service.serviceName).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }

  startService(service: Service): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    this.serviceSvc.start(agentID, service.serviceName).subscribe({
      error: (err) => {
        console.warn(err);
      }
    });
  }

  restartService(service: Service): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'sync-alt',
        title: 'Restart?',
        confirmationText: `Would you like to restart ${service.displayName}?`,
        onConfirm: () => {
          this.serviceSvc.restart(agentID, service.serviceName).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }

  deleteService(service: Service): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'minus',
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: 'Delete?',
        confirmationText: `Would you like to delete ${service.displayName}?`,
        onConfirm: () => {
          this.serviceSvc.delete(agentID, service.serviceName).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }

  disableService(service: Service): void {
    const agentID = this.agentLivePollerSvc.liveAgent.id;

    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: 'ban',
        title: 'Disable?',
        confirmationText: `Would you like to disable ${service.displayName}?`,
        onConfirm: () => {
          this.serviceSvc.disable(agentID, service.serviceName).subscribe({
            error: (err) => {
              console.warn(err);
            }
          });
        },
        onCancel: () => {}
      }
    });
  }
}
