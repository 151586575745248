import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: unknown) {
    const keys = Object.keys(data);
    // Filter out numeric keys (e.g., All, Computer, User)
    return keys.filter((key) => isNaN(Number(key)));
  }
}
