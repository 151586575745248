<ng-container *ngIf="tenant$ | async as t">
  <ng-container *ngIf="t.license.expired; else licensed">
    <div class="flex flex-col items-center justify-start w-full h-full bg-base transition-all">
      <div class="flex-1 flex flex-col items-center justify-center">
        <div @fadeInUp class="card flex gap-6 p-6">
          <div class="flex flex-col items-center justify-center gap-6">
            <div @fadeInUp class="flex flex-col justify-center items-center content-center">
              <div class="fill-current text-center">
                <img class="w-40 animate-blur-in" src="assets/img/demo/logo.svg" />
              </div>
            </div>

            <div class="text-center">
              <h2 class="title m-0 text-secondary text-2xl">CommandCTRL</h2>
            </div>

            <h4 class="text-center w-full text-primary uppercase">
              {{ t.name }}
            </h4>

            <div class="text-center w-full text-red">Tenant Subscription Has Expired</div>

            <ng-container *ngIf="!(isTenantOwner$ | async)">
              <div class="text-secondary text-center">Please contact the owner at {{ t.ownerEmail }}</div>
            </ng-container>

            <ng-container *ngIf="isTenantOwner$ | async">
              <button (click)="reactivateSubscription()" color="primary" mat-raised-button type="button">Reactivate Subscription</button>
            </ng-container>
          </div>
          <ng-container *ngIf="user$ | async as user">
            <ng-container *ngIf="user.canSwitchTenant">
              <div class="w-1 bg-gray-light"></div>
              <div class="flex flex-col items-center justify-center">
                <div @fadeInUp class="flex flex-col gap-4">
                  <button (click)="switchTenant()" color="primary" mat-raised-button type="button">Switch Tenant</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #licensed>
    <ng-container *redirect="['home']; extras: { skipLocationChange: false }"></ng-container>
  </ng-template>
</ng-container>
