<mon-spinner [spin]="spin" [overlay]="true">
  <div *ngIf="tenant$ | async as tenant" class="flex flex-col items-center justify-center gap-8" content>
    <div class="flex items-center justify-center text-warn text-lg">
      Cancelling will cause your subscription to expire on {{ tenant.license.expiration | dateMethod: 'toLocaleDateString' }}
    </div>
    <div>Are you sure you want to cancel?</div>
    <div @fadeInRight *ngIf="error$ | async as error" class="card text-white text-opacity-80 bg-red flex justify-between items-center">
      <pre class="p-4 whitespace-pre-wrap">{{ error }}</pre>
    </div>
    <div class="flex flex-col w-4/5">
      <mat-form-field>
        <mat-label>Cancellation Reason</mat-label>
        <mat-select required #reasonCode>
          <mat-option *ngFor="let reason of cancelReasons$ | async" [value]="reason">{{ reason }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="reasonCode.value == SubscriptionCancellationReasonCode.SwitchingToCompetitorsProduct">
        <mat-label>Competitor's Name</mat-label>
        <input matInput #competitor/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Other Feedback</mat-label>
        <textarea matInput class="h-40 max-h-40" #feedback></textarea>
      </mat-form-field>
    </div>
    <div class="flex gap-4">
      <button type="button" mat-raised-button color="warn" (click)="confirm()" [disabled]="!reasonCode.value"
        >CANCEL MY SUBSCRIPTION</button
      >
      <button type="button" mat-raised-button color="primary" cdkFocusInitial (click)="cancel()">KEEP MY SUBSCRIPTION</button>
    </div>
  </div>
</mon-spinner>
