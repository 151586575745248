<div
  class="flex items-start p-2 m-1 rounded-md rounded-l-none hover:bg-primary-light cursor-pointer border-l-2 border-transparent"
  [ngClass]="{
    'bg-gray bg-opacity-20': selected
  }"
  #item
>
  <div class="flex flex-col truncate">
    <div class="flex items-center">
      <div class="flex-1 text-sm w-full">
        <div class="truncate">
          {{ target.agentID }}
        </div>
      </div>
    </div>
    <div class="text-xs">
      <span *ngIf="target.started.getFullYear() > 0; else noStart" class="italic text-secondary" matTooltip="Created">{{
        target.started | date: 'medium'
      }}</span>
      <ng-template #noStart>
        <span class="whitespace-pre">&nbsp;</span>
      </ng-template>
      <ng-container *ngIf="target.state !== TargetState.TargetTimeout">
        <span *ngIf="target.updated.getFullYear() > 0" class="text-primary font-bold pl-4" matTooltip="Duration">
          {{ target.updated.getTime() - target.started.getTime() | timeSpan: 'short' }}
        </span>
      </ng-container>
    </div>
    <div class="flex justify-between">
      <div class="flex-1 text-secondary text-xs">
        <span [style.font-variant]="'small-caps'">{{ TargetStateDescription.get(target.state)[0] }}</span>
      </div>
    </div>
  </div>
  <div class="flex-1 flex flex-col items-end self-stretch justify-between">
    <div class="flex items-center justify-center text-2xl h-full" [style.font-variant]="'small-caps'">
      <ng-container [ngSwitch]="target.state">
        <ng-container *ngSwitchCase="TargetState.TargetSuccess">
          <fa-icon class="text-green" [icon]="['fad', 'check']" [fixedWidth]="true" matTooltip="Success"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="TargetState.TargetError">
          <fa-icon class="text-red" [icon]="['fad', 'times']" [fixedWidth]="true" matTooltip="Failed"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="TargetState.TargetAborted">
          <fa-icon class="text-secondary" [icon]="['fad', 'ban']" [fixedWidth]="true" matTooltip="Aborted"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="TargetState.TargetTimeout">
          <fa-icon class="text-orange" [icon]="['fad', 'hourglass-end']" [fixedWidth]="true" matTooltip="Timed out"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="TargetState.TargetQueued">
          <fa-icon class="text-secondary" [icon]="['fas', 'layer-group']" [fixedWidth]="true" matTooltip="Timed out"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="TargetState.TargetCreated">
          <fa-icon class="text-secondary" [icon]="['fas', 'plus']" [fixedWidth]="true" matTooltip="Created"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="TargetState.TargetProcessing">
          <fa-icon
            class="text-secondary"
            [icon]="['fad', 'loader']"
            animation="spin-pulse"
            [fixedWidth]="true"
            matTooltip="Processing"
          ></fa-icon>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
