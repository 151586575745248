const BRANCH_SCRUBBED = 'release-odyssey';
const branchScrubbed = `${BRANCH_SCRUBBED}`;
export const branch = {
  suffix: `${branchScrubbed ? '-' + branchScrubbed : ''}`,
  subdomain: `${branchScrubbed ? branchScrubbed + '.' : ''}`
};

const projectID = 'cc-dev-00';
const region = 'us-west2';
const wsHost = {
  url: `ws-broker${branch.suffix}.cc-dev.liquidware.com`
};

export const environment = {
  debug: true,
  localDev: false,
  auth0Domain: 'cc-dev-00.us.auth0.com',
  auth0ClientID: '9fPGAHyoIiHJUd6x9JTlnni2XHWyD412',
  auth0RedirectURI: `${window.location.origin}`,
  mapsAPIKey: 'AIzaSyAuLg6tLgLdZh27ESfWGjQGRu9lPMLhRew',
  production: false,
  devHide: ['dev'],
  consoleLogs: true,
  disableRouter: true,
  projectID,
  region,
  baseUrl: `https://api${branch.suffix}.cc-dev.liquidware.com`,
  cfURL: `https://func${branch.suffix}.cc-dev.liquidware.com`,
  wsHost: wsHost.url,
  wsUrl: `wss://${wsHost.url}/ws_console`,
  loginUri: 'https://cc-login.liquidware.com/dev'
};
