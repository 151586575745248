import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
  ExternalNavigationLink,
  NavigationDivider,
  NavigationDropdown,
  NavigationFunc,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from '../interfaces/navigation-item.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  items: NavigationItem[] = [];

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  triggerOpenChange(item: NavigationDropdown): void {
    this._openChangeSubject.next(item);
  }

  isFunc(item: NavigationItem): item is NavigationFunc {
    return item.type === 'func';
  }

  isExternal(item: NavigationItem): item is ExternalNavigationLink {
    return item.type === 'external';
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  isDivider(item: NavigationItem): item is NavigationDivider {
    return item.type === 'divider';
  }

  findByRoute(route: string): NavigationItem {
    let found: NavigationItem;

    let parentIcon;

    this.items.some((i) => {
      if (this.isLink(i) && i.route === route) {
        found = i;
        return true;
      }

      if (this.isDropdown(i)) {
        parentIcon = i.icon;
        return i.children.some((j) => {
          if (j.type === 'link' && j.route === route) {
            found = j;
            return true;
          }

          return false;
        });
      }

      if (this.isSubheading(i)) {
        return i.children.some((j) => {
          if (j.type === 'link' && j.route === route) {
            found = j;
            return true;
          }
          return false;
        });
      }

      return false;
    });

    if (found) {
      if (!found['icon']) {
        found['icon'] = parentIcon;
      }

      return found;
    }

    return null;
  }
}
