import { animate, style, transition, trigger } from '@angular/animations';

export function scaleOutAnimation(duration: number) {
    return trigger('scaleOut', [
        transition(':leave', [
            style({
                transform: 'scale(1)'
            }),
            animate(
                `${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({
                    transform: 'scale(0)'
                })
            )
        ])
    ]);
}

export const scaleOut400ms = scaleOutAnimation(400);
