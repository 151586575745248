import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { BehaviorSubject } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { mergeDeep } from 'src/@vex/utils/merge-deep';
import { LoaderService } from 'src/app/core/services/loader.service';
import { fadeOutDown80ms } from 'src/app/shared/animations/fade-out-down.animation';

@Component({
  selector: 'mon-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fadeInUp400ms, fadeOutDown80ms]
})
export class LoaderComponent implements OnInit {
  private _show = new BehaviorSubject<boolean>(false);
  show$ = this._show.asObservable();

  options$ = this.loaderSvc.options$;

  showCard = true;
  showCloseButton = false;

  _closeButton: MatButton;
  _viewContainerRef: ViewContainerRef;

  @ViewChild('closeButton') set closeButton(closeButton: MatButton) {
    this._closeButton = closeButton;
    setTimeout(() => {
      if (this._closeButton) {
        this._closeButton.focus();
      }
    }, 0);
  }

  @ViewChild('dynamic', { read: ViewContainerRef }) set viewContainerRef(
    viewContainerRef: ViewContainerRef
  ) {
    this._viewContainerRef = viewContainerRef;
    const ops = this.loaderSvc.options;
    if (this._viewContainerRef && ops.component) {
      const component = this._viewContainerRef.createComponent(ops.component);
      if (ops.componentData) {
        mergeDeep(component.instance, ops.componentData);
      }
      component.changeDetectorRef.detectChanges();
    }
  }

  constructor(private loaderSvc: LoaderService) {}

  ngOnInit(): void {
    this.loaderSvc.options$.subscribe({
      next: (ops) => {
        if (ops.loading) {
          this._show.next(true);
          this.showCard = true;
          return;
        }

        if (ops.closeAfterTimeout) {
          setTimeout(() => this.slowClose(), ops.closeAfterTimeout);
        } else if (ops.closeButtonHidden) {
          this.slowClose();
        }
      }
    });
  }

  slowClose(): void {
    this.showCard = false;
  }

  close(): void {
    if (this.showCard) {
      return;
    }
    this._show.next(false);
  }
}
