import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { AgentService } from 'src/app/core/services/agent.service';
import { APIKeyService } from 'src/app/core/services/apikey.service';
import { StateService } from 'src/app/core/services/state.service';
import { fadeOutUp200ms } from 'src/app/shared/animations/fade-out-up.animation';
import { CCAgent } from 'src/app/shared/models/agent.model';
import { APIKey } from 'src/app/shared/models/apikey.model';
import { Tenant } from 'src/app/shared/models/tenant.model';
import { branch, environment } from 'src/environments/environment';

@Component({
  selector: 'mon-download-agent',
  templateUrl: './download-agent.component.html',
  styleUrls: ['./download-agent.component.scss'],
  animations: [fadeOutUp200ms, fadeInUp200ms]
})
export class DownloadAgentComponent implements AfterViewInit {
  environment = environment;
  showAPIKeyWarning: boolean;

  tenant$ = this.stateSvc.tenant$;
  windowsAmd64Version$ = this.agentSvc.getVersion('windows', 'amd64');
  darwinAmd64Version$ = this.agentSvc.getVersion('darwin', 'amd64');
  darwinArm64Version$ = this.agentSvc.getVersion('darwin', 'arm64');

  macOSDownloadInfo$ = this.agentSvc.getDownloadInfo('macos');

  public winInstallExample = '';
  public winDownloadLink = '';
  public osxDownloadLink = '';
  public apiKey = '';
  public tenantId = '';

  test = `($user = [Security.Principal.WindowsIdentity]::GetCurrent())| Out-Null;`;

  visibility = 'hidden';

  @ViewChild('installCodeEl', { static: false }) installCodeEl: ElementRef;
  @ViewChild('uninstallCodeEl', { static: true }) uninstallCodeEl: ElementRef;
  @ViewChild('logExampleEl', { static: true }) logExampleEl: ElementRef;

  codeRender: Array<ElementRef> = [];

  isDebug = environment.debug;

  renderData$ = combineLatest([
    this.stateSvc.tenant$,
    this.apiKeySvc.getAll().pipe(catchError(() => of([]))),
    this.windowsAmd64Version$
  ]).pipe(
    filter(([tenant, apiKeys, ccagent]) => !!tenant && !!apiKeys && !!ccagent),
    tap(([tenant, apiKeys, ccagent]) => {
      this.getWinInstallCode(tenant, apiKeys ? apiKeys[0] : undefined, ccagent);
      this.visibility = 'visible';
    })
  );

  constructor(
    private clipboard: Clipboard,
    private stateSvc: StateService,
    private apiKeySvc: APIKeyService,
    private agentSvc: AgentService
  ) {}

  ngAfterViewInit(): void {
    this.codeRender = [this.installCodeEl, this.uninstallCodeEl, this.logExampleEl];
  }

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.disabled = false;
    setTimeout(() => tooltip.show(), 1);
    setTimeout(() => (tooltip.disabled = true), 5000);
    this.clipboard.copy(text);
  }

  getWinInstallCode(tenant: Tenant, apiKey: APIKey, agent: CCAgent): void {
    let key = 'ask-your-admin';
    const agentFullVersion = agent.ccagent.version;
    let agentVersion = 'error unknown version';
    this.tenantId = tenant.id;
    this.apiKey = apiKey ? apiKey.apiKey : 'ask-your-admin';

    const regex = /^(\d{2})(\d{2})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})-([a-z0-9]+)$/;
    const match = agentFullVersion.match(regex);
    if (match) {
      const [, , yearPart2, month, day, hour, minute, ,] = match;
      agentVersion = `${yearPart2}.${parseInt(month)}.${+day}.${+`${hour}${minute}`}`;
    } else {
      console.error('Invalid date format for agent version');
    }

    if (!apiKey) {
      this.showAPIKeyWarning = true;
    } else {
      key = apiKey.apiKey;
    }

    this.winDownloadLink = `https://cdn.liquidware.com/CommandCTRL/windows/${environment.projectID}/CommandCTRL.Agent.amd64-${agentVersion}.${branch.subdomain}msi`;
    this.osxDownloadLink = `https://cdn.liquidware.com/CommandCTRL/macos/${environment.projectID}/CommandCTRL.Agent.universal-${agentVersion}.${branch.subdomain}dmg`;

    this.winInstallExample = `msiexec.exe /i "CommandCTRL.Agent.amd64-${agentVersion}.${branch.subdomain}msi" /qn TENANTID="${tenant.id}" APIKEY="${key}"`;
  }

  pkgToDmg(pkg: string): string {
    return pkg.replace('.pkg', '.dmg');
  }
}
