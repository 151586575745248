import { CommonModule } from '@angular/common';
import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BehaviorSubject, finalize, switchMap, tap } from 'rxjs';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
  selector: 'mon-select',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() defaultValue: string;
  @Input() field: string;
  @Input() label: string;

  private _readonly = false;
  @Input() get readonly(): boolean {
    return this._readonly;
  }
  set readonly(v: boolean | '') {
    this._readonly = v === '' || v;
  }

  _lock = new BehaviorSubject<boolean>(undefined);
  lock$ = this._lock.asObservable();

  _refresh = new BehaviorSubject<void>(undefined);
  fieldValue$ = this._refresh.asObservable().pipe(
    switchMap(() => this.tenantSvc.getSettings(this.field)),
    tap({
      next: () => this._lock.next(false)
    })
  );

  @ContentChildren(MatOption) options: QueryList<MatOption>;
  constructor(private tenantSvc: TenantService) {}

  onSelectionChange(event: MatSelectChange): void {
    this.setSetting(event.value);
  }

  setSetting(value: unknown): void {
    this._lock.next(true);

    this.tenantSvc
      .setSetting(this.field, value)
      .pipe(
        tap({
          next: () => this._refresh.next()
        }),
        finalize(() => this._lock.next(false))
      )
      .subscribe();
  }
}
