export const firstItemInMap = <T, U>(m: Map<T, U>): [T, U] => m.entries().next().value;
export const firstKeyInMap = <T, U>(m: Map<T, U>): T => m.keys().next().value;
export const firstValueInMap = <T, U>(m: Map<T, U>): U => m.values().next().value;

export const lastItemInMap = <T, U>(m: Map<T, U>): [T, U] => Array.from(m).pop();
export const lastKeyInMap = <T, U>(m: Map<T, U>): T => Array.from(m.keys()).pop();
export const lastValueInMap = <T, U>(m: Map<T, U>): U => Array.from(m.values()).pop();

export const lastFieldInObject = <U>(m: unknown): [string, U] => [
  Object.keys(m).pop(),
  m[Object.keys(m).pop()]
];
export const lastFieldKeyInMap = (m: unknown): string => Object.keys(m).pop();
export const lastFieldValueInMap = <U>(m: unknown): U => m[Object.keys(m).pop()];
