import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { APIKeyService } from 'src/app/core/services/apikey.service';
import { APIKey } from 'src/app/shared/models/apikey.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';

export class APIKeyDataSource implements DataSource<APIKey> {
  private _cachedAPIKeys: APIKey[];
  private _apiKeys = new BehaviorSubject<APIKey[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._apiKeys.value ? this._apiKeys.value.length : -1;
  }
  public get data(): APIKey[] {
    return this._apiKeys.value?.slice() || [];
  }

  constructor(private apiKeySvc: APIKeyService) {}

  loadAPIKeys(orderBy: OrderBy[]): void {
    orderBy = orderBy || [];
    this._loading.next(true);

    if (this._cachedAPIKeys) {
      let results = this._cachedAPIKeys.slice();

      if (orderBy.length > 0 && orderBy[0].dir > 0) {
        results = OrderBy.Sort(results, orderBy[0]);
      }

      this.total = results.length;
      this._apiKeys.next(results || []);
      this._loading.next(false);
      return;
    }

    this.apiKeySvc
      .getAll()
      .pipe(
        catchError(() => of([])),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (apiKeys: APIKey[]) => {
          this._cachedAPIKeys = apiKeys;
          this.total = this._cachedAPIKeys.length;
          this._apiKeys.next(this._cachedAPIKeys || []);
        }
      });
  }

  connect(): Observable<APIKey[]> {
    return this._apiKeys.asObservable();
  }

  disconnect(): void {
    this._apiKeys.complete();
    this._loading.complete();
  }
}
