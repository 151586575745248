import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { md5 } from 'src/app/utils/md5';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GravatarService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getImage(email: string): Observable<string> {
    const emailMD5 = md5(email);
    const url = `https://s.gravatar.com/avatar/${emailMD5}?d=404`; //redirect to 404 if image not found
    // const url = `https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=${email}&size=HR120x120`; //cors issues with office 365 - maybe for future use?

    const cached = localStorage.getItem(`image-${emailMD5}`);
    if (cached) {
      return of(cached);
    }

    return this.http.get<string>(url).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 200) {
          localStorage.setItem(`image-${emailMD5}`, url);
          return of(url);
        }

        return of('');
      })
    );
  }
}
