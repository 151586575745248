<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>

    <div class="flex justify-start items-center" mat-dialog-title>
      <div class="flex-1 flex flex-col">
        <h2 tipOnEllip class="headline m-0 truncate flex-auto">{{ formDetail.get('name').value || 'New Script' }}</h2>
      </div>

      <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
        <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <div *hasPerm="['godmode']" class="relative">
      <div class="absolute bottom-6 text-xs h-full">
        <mat-checkbox
          (change)="toggleLocation($event.checked)"
          [disabled]="edit"
          [checked]="script.location === scriptLocation.Public"
          color="primary"
          class="mr-2"
        >
          Public
        </mat-checkbox>
      </div>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-stepper orientation="vertical" linear (selectionChange)="onStep($event)">
      <ng-template matStepperIcon="edit">
        <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
      </ng-template>

      <mat-step [stepControl]="formUpload" label="Upload">
        <form autocomplete="off" [formGroup]="formUpload">
          <mat-dialog-content class="flex flex-col">
            <input class="hidden" type="file" accept=".ps1, .sh" (change)="onFileSelected($event)" #fileUpload />

            <mat-form-field class="hidden">
              <input formControlName="filename" matInput />
              <mat-error *ngIf="formUpload.controls.filename.errors?.required">Description can not be empty</mat-error>
            </mat-form-field>

            <div>
              <blockquote class="whitespace-normal bg-yellow-100 border-yellow-500 text-yellow">
                Scripts should be signed for security reasons
              </blockquote>
            </div>

            <div class="flex flex-col items-center justify-center">
              <div class="flex items-center justify-center p-4 gap-4">
                <div class="truncate w-60">
                  <span class="mr-4" [ngClass]="!filename ? 'text-red' : 'text-green'">{{
                    filename ? 'Uploaded' : 'No file uploaded yet'
                  }}</span>
                </div>
                <button matTooltip="Upload" mat-mini-fab [color]="'primary'" (click)="fileUpload.click()" #uploadButton>
                  <fa-icon [icon]="['fas', 'paperclip']" size="lg"></fa-icon>
                </button>
              </div>
              <mat-checkbox (change)="importComments = !importComments" [checked]="importComments" color="primary" class="mr-2">
                Automatically use script comments to autofill fields
              </mat-checkbox>
              <div class="text-2xs text-secondary italic">
                Follow best practices for scripts to help autofill fields. Read more about formatting
                <a
                  class="underline"
                  target="_blank"
                  href="https://docs.microsoft.com/en-us/powershell/scripting/developer/help/examples-of-comment-based-help?view=powershell-7.2"
                  >here</a
                >
                and use <span matTooltip=".CC_ID, .CC_NAME, .CC_DETAIL and .CC_TAGS are extended fields">exteneded fields</span>.
              </div>
            </div>

            <div class="min-h-6">
              <mat-progress-bar class="progress-bar mb-4" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
              </mat-progress-bar>
            </div>
            <div @fadeInRight *ngIf="!scriptSigned" class="p-2 text-red-400 text-sm">
              This script is not signed and will not run on any machine unless you allow an online hash check to verify security of script
              (see Tenant > Site > Scripts). You may still set up this script.
            </div>
            <div @fadeInRight *ngIf="uploadErr" class="card text-white text-opacity-80 bg-red flex justify-between items-center">
              <pre class="p-4 whitespace-pre-wrap">{{ uploadErr }}</pre>
              <pre class="p-2">
                <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="uploadErr=''"></fa-icon>
              </pre>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="!edit && formUpload.invalid">Next</button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step [stepControl]="formDetail" label="Detail">
        <form autocomplete="off" [formGroup]="formDetail">
          <mat-dialog-content class="flex flex-col overflow-visible">
            <mat-form-field *ngIf="!edit">
              <mat-label>ID</mat-label>
              <input formControlName="id" matInput [readonly]="edit" #idInput />
              <fa-icon [icon]="['fad', 'fingerprint']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
              <mat-hint>Only lowercase alphanumeric, dashs and underscores allowed</mat-hint>
              <mat-error *ngIf="formDetail.controls.id.errors?.required">ID can not be empty</mat-error>
              <mat-error *ngIf="formDetail.controls.id.errors?.maxlength">ID can not be more than 50 characters </mat-error>
              <mat-error *ngIf="formDetail.controls.id.errors?.pattern">ID must only contain lowercase alphanumeric, -, _ values</mat-error>
              <mat-error *ngIf="formDetail.controls.id.errors?.pattern">ID must not repeat -, _ or end with these</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput #nameInput />
              <mat-error *ngIf="formDetail.controls.name.errors?.required">Name can not be empty</mat-error>
              <mat-error *ngIf="formDetail.controls.name.errors?.maxlength">Name can not be more than 50 characters</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Description</mat-label>
              <input formControlName="description" matInput />
              <mat-hint>Provide a short description of this script</mat-hint>
              <mat-error *ngIf="formDetail.controls.description.errors?.required">Description can not be empty</mat-error>
              <mat-error *ngIf="formDetail.controls.description.errors?.maxlength"
                >Description can not be more than 200 characters</mat-error
              >
            </mat-form-field>

            <mat-form-field *ngIf="commonTags$ | async as commonTags">
              <mat-label>Tags</mat-label>
              <mat-chip-grid #chipList aria-label="Tag selection" formControlName="tags">
                <mat-chip-row *ngFor="let tag of script.tags.sort()" removable (removed)="removeTag(tag)">
                  <div class="truncate">{{ tag }}</div>
                  <fa-icon matChipRemove [icon]="['fad', 'times-circle']" size="lg" [fixedWidth]> </fa-icon>
                </mat-chip-row>
                <input
                  placeholder="assign tags..."
                  #tagInput
                  [formControl]="selectableCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addTag($event)"
                />
              </mat-chip-grid>
              <mat-hint>Provide at least 3 tags</mat-hint>
              <mat-error *ngIf="formDetail.controls.tags.errors?.invalid">Provide 3 proper tags</mat-error>
              <mat-error *ngIf="formDetail.controls.tags.errors?.invalid"
                >Tags must only contain lowercase alphanumeric, -, _ values</mat-error
              >
              <mat-error *ngIf="formDetail.controls.tags.errors?.invalid">Tags must not repeat -, _ or end with these</mat-error>
              <mat-autocomplete #auto (optionSelected)="selected($event)" [autoActiveFirstOption]="true" matAutocompleteTrigger>
                <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag" [disabled]="spin">
                  <div class="text-xs md:text-base">{{ tag }}</div>
                </mat-option>
              </mat-autocomplete>
              <fa-icon [icon]="['fad', 'tags']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
            </mat-form-field>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button mat-button color="primary" matStepperPrevious>Back</button>
              <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="formDetail.invalid">Next</button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step [stepControl]="formParameters" label="Parameters">
        <form autocomplete="off" [formGroup]="formParameters">
          <mat-dialog-content class="flex flex-col overflow-visible">
            <div class="max-h-20 overflow-auto" #parameterInputs>
              <div class="grid grid-flow-row grid-cols-12 gap-2">
                <ng-container *ngFor="let pIndex of parameterIndices; index as i">
                  <mat-form-field class="col-span-3 mb-3">
                    <mat-label>Parameter</mat-label>
                    <input class="parameter-name" [formControlName]="'name' + pIndex" matInput (loaded)="focusOnMe($event)" />
                    <mat-error *ngIf="getParamError('name' + pIndex, 'maxlength')">50 characters max</mat-error>
                    <mat-error *ngIf="getParamError('name' + pIndex, 'pattern')">Invalid characters</mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-span-7">
                    <mat-label>Description</mat-label>
                    <input [formControlName]="'description' + pIndex" matInput />
                    <mat-error *ngIf="getParamError('name' + pIndex, 'maxlength')">200 characters max</mat-error>
                  </mat-form-field>

                  <div class="col-span-2 flex items-center justify-center">
                    <button mat-button color="warn" (click)="removeParameter(pIndex)">Delete</button>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="mt-2">
              <button mat-mini-fab color="primary" (click)="addParameter()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
              </button>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button mat-button color="primary" matStepperPrevious>Back</button>
              <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="formParameters.invalid">Next</button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step [stepControl]="formDocumentation" label="Documentation">
        <form autocomplete="off" [formGroup]="formDocumentation">
          <mat-dialog-content class="relative flex flex-col" [ngClass]="markdown ? null : 'mt-4'">
            <div class="flex flex-col items-stretch" [ngClass]="markdown ? null : 'min-h-20 max-h-20'">
              <mat-form-field [hidden]="!markdown" class="flex-1 h-full">
                <mat-label>Summary, parameters, examples and notes</mat-label>
                <textarea
                  formControlName="detail"
                  class="min-h-20"
                  matInput
                  #detailInput
                  placeholder="Please include information such as a summary, parameters, examples and notes"
                ></textarea>
                <mat-hint>
                  <a href="https://www.markdownguide.org/basic-syntax" target="_blank" class="">Click here to read about markdown syntax</a>
                </mat-hint>
                <mat-error *ngIf="formDocumentation.controls.detail.errors?.required">Detail can not be empty</mat-error>
              </mat-form-field>

              <markdown
                emoji
                [hidden]="markdown"
                class="prose prose-sm dark:prose-invert prose-hr:mb-3 flex-1 overflow-auto"
                [data]="formDocumentation.controls.detail.value"
              ></markdown>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex">
              <button mat-button (click)="markdown = !markdown" [disabled]="!formDocumentation.controls.detail.value" class="uppercase">
                {{ markdown ? 'Preview' : 'Edit' }}
              </button>
              <div class="w-full flex-1 flex justify-end">
                <button mat-button color="primary" matStepperPrevious>Back</button>
                <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="formDocumentation.invalid">Next</button>
              </div>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step *ngIf="!edit" [stepControl]="formPermissions" label="Permissions">
        <form autocomplete="off" [formGroup]="formPermissions">
          <mat-dialog-content class="flex flex-col overflow-visible">
            <mon-permissions
              *ngIf="showPermissions"
              #permControl
              [permissions]="permissions$ | async"
              [autoCommit]="true"
              (onAdd)="permOnAdd($event)"
              (onUpdate)="permOnUpdate($event)"
              (onDelete)="permOnDelete($event)"
            ></mon-permissions>
            <div @fadeInRight *ngIf="err" class="card text-white text-opacity-80 bg-red flex justify-between items-center mt-2">
              <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
              <pre class="p-2">
                <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="err=''"></fa-icon>
              </pre>
            </div>
          </mat-dialog-content>
          <mat-dialog-actions>
            <div class="w-full flex justify-end">
              <button mat-button color="primary" matStepperPrevious>Back</button>
              <button
                *ngIf="edit; else newScript"
                mat-button
                color="primary"
                mat-raised-button
                matStepperNext
                [disabled]="formPermissions.invalid"
              >
                Next
              </button>
              <ng-template #newScript>
                <button
                  color="primary"
                  mat-button
                  (click)="edit ? confirm(save.bind(this)) : save()"
                  [disabled]="spin || formPermissions.invalid"
                  mat-raised-button
                  matStepperNext
                >
                  CREATE
                </button>
              </ng-template>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>

      <mat-step *ngIf="edit" [stepControl]="formChangelog" label="Changelog">
        <form autocomplete="off" [formGroup]="formChangelog">
          <mat-dialog-content class="relative flex flex-col mt-4'">
            <div class="flex flex-col items-stretch min-h-10 max-h-10'">
              <mat-form-field class="flex-1 h-full">
                <mat-label>Separate each changelog message by a carriage return</mat-label>
                <textarea formControlName="changelog" class="min-h-10" matInput #changelogInput></textarea>
                <mat-error *ngIf="formChangelog.controls.changelog.errors?.required">Changelog can not be empty</mat-error>
              </mat-form-field>
            </div>

            <div class="pt-2 min-h-10 max-h-10">
              <div class="text-secondary pb-2 text-lg">Changelog preview:</div>
              <ul class="ml-6 list-disc">
                <li *ngFor="let c of changelogs$ | async">{{ c }}</li>
              </ul>
            </div>

            <div @fadeInRight *ngIf="err" class="card text-white text-opacity-80 bg-red flex justify-between items-center mt-2">
              <pre class="p-4 whitespace-pre-wrap">{{ err }}</pre>
              <pre class="p-2">
                <fa-icon class="cursor-pointer" [icon]="['fad', 'times-circle']" size="lg" [fixedWidth] (click)="err=''"></fa-icon>
              </pre>
            </div>
          </mat-dialog-content>

          <mat-dialog-actions>
            <div class="w-full flex">
              <div class="w-full flex-1 flex justify-end">
                <button mat-button color="primary" matStepperPrevious>Back</button>
                <button
                  color="primary"
                  mat-button
                  (click)="edit ? confirm(save.bind(this)) : save()"
                  [disabled]="spin || formChangelog.invalid"
                  mat-raised-button
                  matStepperNext
                >
                  {{ edit ? 'SAVE CHANGES' : 'CREATE' }}
                </button>
              </div>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>
    </mat-stepper>
  </div>
</mon-spinner>
