import {
  ActionCauseType,
  ActionEffectType,
  SystemEventCauseType,
  SystemEventEffectType,
} from 'src/app/shared/enums/action.enum';
import { Action, ActionApply, ActionCause, ActionEffect } from 'src/app/shared/models/action.model';

export const mockActions: Action[] = [
  new Action({
    id: `${new Date().getTime()}`,
    name: 'Kill process on logon',
    description: 'This will kill a specific process on logon',
    cause: new ActionCause({
      type: ActionCauseType.System,
      systemCause: SystemEventCauseType.Logon
    }),
    effect: new ActionEffect({
      type: ActionEffectType.System,
      systemEffect: SystemEventEffectType.KillProcess
    }),
    apply: new ActionApply({
      machines: 'accounting*'
    })
  })
];
