import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import Centrifuge from 'centrifuge';
import { catchError, combineLatest, from, map, of, switchMap, tap } from 'rxjs';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { ScriptResultTarget } from 'src/app/shared/interfaces/script.interface';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { TargetState, TargetStateDescription } from 'src/app/shared/models/task.model';
import { getShellVersion } from 'src/app/utils/powershell';

@Component({
  selector: 'mon-result-target-detail',
  templateUrl: './result-target-detail.component.html',
  styleUrls: ['./result-target-detail.component.scss']
})
export class ResultTargetDetailComponent {
  TargetState = TargetState;
  TargetStateDescription = TargetStateDescription;
  ObjectKeys = Object.keys;

  @Input() resultID: string = undefined;
  @Input() target: ScriptResultTarget = undefined;

  readonly channelName$ = this.stateSvc.tenant$.pipe(
    map(
      (t) => `$script:web.${t.id}.${this.target.agentID}.${this.resultID}.scriptResponse`
    )
  );

  liveOutput = '';
  channelListener$ = this.channelName$.pipe(
    switchMap((channel) =>
      from(
        this.pubSubSvc.history(channel, {
          limit: 100,
          reverse: true
        })
      ).pipe(
        catchError(() => {
          return of(<Centrifuge.HistoryResult>{
            publications: [],
            offset: -1,
            epoch: ''
          });
        }),
        switchMap((history) => combineLatest([of(channel), of(history)]))
      )
    ),
    switchMap(([channel, history]) => {
      if (history) {
        let liveOutput = '';

        for (let i = 0; i < history.publications.length; i++) {
          const packet = history.publications[i].data as Packet;

          if (packet.type === PacketType.ScriptOutputPacket) {
            liveOutput = packet.content['output'] + liveOutput;
          } else if (packet.type === PacketType.ScriptShellVersionPacket) {
            this.target.shellVersion = getShellVersion(packet.content['version']);
          } else if (packet.type === PacketType.StartPacket) {
            break;
          }
        }

        this.liveOutput = liveOutput;
      }

      return this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          if (packet.type === PacketType.ScriptOutputPacket) {
            this.liveOutput += packet.content['output'];
          } else if (packet.type === PacketType.ScriptShellVersionPacket) {
            this.target.shellVersion = getShellVersion(packet.content['version']);
          }
        })
      );
    })
  );

  constructor(
    private clipboard: Clipboard,
    private stateSvc: StateService,
    private pubSubSvc: PubSubWsService
  ) {}

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.hide();
    setTimeout(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
    }, 50);
    setTimeout(() => {
      tooltip.hide();
    }, 1250);
    setTimeout(() => {
      tooltip.hide();
      tooltip.message = '';
    }, 1500);
    this.clipboard.copy(text);
  }
}
