<div class="absolute w-full h-full top-0 bg-gradient"></div>
<div class="absolute w-full h-full sm:bg-contain top-0 bg-hex"></div>
<div class="hidden sm:block absolute dot"></div>
<div class="absolute w-full h-full sm:bg-contain top-0 bg-hex bg-hex-bottom"></div>
<div class="overflow-auto w-full h-full flex flex-col sm:flex-row items-center sm:justify-evenly content-center gap-2 sm:gap-4 sm:p-4">
  <div class="flex flex-col sm:flex-row items-center justify-center sm:h-full gap-10 z-10">
    <div class="flex items-center sm:items-start h-fit p-4 md:p-10 gap-4">
      <div class="flex justify-center -pt-2">
        <img title="logo" class="w-28 sm:w-20 animate-blur-in" src="assets/img/demo/logo.svg" />
      </div>
      <div class="flex flex-col items-center justify-center gap-10">
        <div class="card sm:border-r-0 sm:shadow-none p-4 sm:p-2 md:p-0 flex flex-col gap-6 md:bg-transparent">
          <p class="text-2xl sm:text-3xl md:text-4xl font-bold">Take CommandCTRL of your workspaces today!</p>
          <p>Liquidware's new SaaS Real-time Remediation Solution offers these critical features:</p>
          <div class="flex flex-col gap-1">
            <div class="flex gap-2">
              <fa-icon [icon]="['fad', 'check']" class="text-primary" size="lg"></fa-icon>
              <div>DVR playback feature lets you see how issues actually occurred.</div>
            </div>
            <div class="flex gap-2">
              <fa-icon [icon]="['fad', 'check']" class="text-primary-500" size="lg"></fa-icon>
              <div>Switch feature lets you seamlessly navigate and switch between two separate workspaces.</div>
            </div>
            <div class="flex gap-2">
              <fa-icon [icon]="['fad', 'check']" class="text-primary" size="lg"></fa-icon>
              <div>Mirroring lets you see the protocol to the physical machine endpoint.</div>
            </div>
            <div class="flex gap-2">
              <fa-icon [icon]="['fad', 'check']" class="text-primary" size="lg"></fa-icon>
              <div>(Optional) Integration with ChatGPT - AI. Identify which processes are running.</div>
            </div>
          </div>
        </div>
        <img src="/assets/img/laptop.png" class="hidden sm:block w-96" />
        <div class="hidden sm:block h-48"></div>
      </div>
    </div>
    <div
      class="main-card-form card shadow-2xl flex flex-none flex-col items-center justify-center transition-all duration-400 m-2 sm:m-0 mb-4 w-80 min-w-20"
      [ngClass]="[(ready$ | async) ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4']"
    >
      <div class="text-center mt-4 pb-6">
        <h2 class="title m-0 text-secondary secondary- text-2xl">CommandCTRL</h2>
      </div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
