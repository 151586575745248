<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <ng-container *ngIf="processInfo$ | async as processInfo">
      <mat-form-field class="w-full">
        <mat-label>Process</mat-label>
        <input type="text" matInput [value]="processInfo.process" [readonly]="true" />
      </mat-form-field>
      <mat-form-field *ngIf="processInfo.manufacturer" class="w-full">
        <mat-label>Manufacturer</mat-label>
        <input type="text" matInput [value]="processInfo.manufacturer" [readonly]="true" />
      </mat-form-field>
      <mat-form-field *ngIf="processInfo.manufacturer_website" class="w-full">
        <mat-label>Website</mat-label>
        <input type="text" matInput [value]="processInfo.manufacturer_website" [readonly]="true" />
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Description</mat-label>
        <textarea class="min-h-15" matInput [value]="processInfo.description" [readonly]="true"></textarea>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="error">
      <div class="">Could not perform query on {{ params.process }}</div>
    </ng-container>
  </div>
</mon-spinner>
