import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { scaleOut400ms } from '../../animations/scale-out.animation';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'mon-loader-ball',
  templateUrl: './loader-ball.component.html',
  styleUrls: ['./loader-ball.component.scss'],
  animations: [scaleOut400ms]
})
export class LoaderBallComponent {}
