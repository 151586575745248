<div
  *ngIf="user$ | async as user"
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  data-intro-key="user-profile-button"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-1 hover:bg-hover h-12"
  matRipple
>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary"
    [ngClass]="{ 'bg-primary-light': !user.imageLink, truncate: !!user.imageLink }"
  >
    <fa-icon *ngIf="!user.imageLink; else userImage" [icon]="['fad', 'user']"></fa-icon>
    <ng-template #userImage> <img class="animate-blur-in" [src]="user.imageLink" /> </ng-template>
  </div>
</div>
