import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faApple,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faLinux,
  faWindows
} from '@fortawesome/free-brands-svg-icons';
import {
  faAddressCard,
  faAlarmExclamation,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleRight,
  faAsterisk,
  faBallot,
  faBan,
  faBank,
  faBarsProgress,
  faBatteryBolt,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryLow,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBellOn,
  faBellSlash,
  faBinary,
  faBolt,
  faBook,
  faBookmark,
  faBug,
  faBuilding,
  faBuildingShield,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNodes,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClockRotateLeft,
  faClone,
  faCloudDownload,
  faCog,
  faColumns,
  faCommentDots,
  faComputer,
  faComputerClassic,
  faConstruction,
  faCopy,
  faCreditCard,
  faDesktop,
  faDiscDrive,
  faDown,
  faDownload,
  faEngine,
  faEnvelope,
  faEnvelopeOpenText,
  faEthernet,
  faExclamation,
  faExclamationSquare,
  faExternalLink,
  faExternalLinkSquare,
  faEye,
  faFan,
  faFileAlt,
  faFingerprint,
  faFlagCheckered,
  faGlobe,
  faHandPaper,
  faHdd,
  faHeadset,
  faHeartBroken,
  faHeartbeat,
  faHourglassEnd,
  faHouseSignal,
  faIdBadge,
  faLink,
  faLoader,
  faLocation,
  faLock,
  faMapMarkerAlt,
  faMemory,
  faMicrochip,
  faMinus,
  faMinusCircle,
  faMoneyCheckDollarPen,
  faMonitorHeartRate,
  faNetworkWired,
  faPaperPlane,
  faPaperPlaneTop,
  faPencilAlt,
  faPercentage,
  faPhone,
  faPlay,
  faPlug,
  faPlus,
  faPortalExit,
  faPowerOff,
  faQuestionCircle,
  faRabbitFast,
  faReceipt,
  faSatelliteDish,
  faScroll,
  faSealExclamation,
  faSearch,
  faSearchPlus,
  faServer,
  faShapes,
  faShareAlt,
  faShield,
  faShieldAlt,
  faShieldCheck,
  faShieldPlus,
  faSignIn,
  faSignOut,
  faSiren,
  faSitemap,
  faSquareFull,
  faStop,
  faStopCircle,
  faSyncAlt,
  faTachometerAltFast,
  faTags,
  faTasks,
  faTelescope,
  faTemperatureHigh,
  faTerminal,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToolbox,
  faTriangle,
  faTurtle,
  faUnlink,
  faUp,
  faUser,
  faUserCircle,
  faUserClock,
  faUserHeadset,
  faUserPlus,
  faUserShield,
  faUserTag,
  faUsers,
  faUsersClass,
  faUsersRectangle,
  faWaveformPath,
  faWifi,
  faWifi1,
  faWifi2,
  faWifiSlash
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowRightLong,
  faBars,
  faCertificate,
  faEllipsisVertical,
  faFlag,
  faLayerGroup,
  faPaperclip,
  faPlus as faPlusSolid,
  faSlash,
  faSquare,
  faTimes as faTimesSolid,
  faWavePulse
} from '@fortawesome/pro-solid-svg-icons';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MarkdownModule } from 'ngx-markdown';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { ComponentsModule } from '../shared/components/components.module';
import { NoAgentConnectionComponent } from '../shared/components/no-agent-connection/no-agent-connection.component';
import { SharedModule } from '../shared/shared.module';
import { AgreementComponent } from './agreement/agreement.component';
import { AlarmsModule } from './alarms/alarms.module';
import { DashboardConfigPanelComponent } from './dashboard/dashboard-config-panel/dashboard-config-panel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CurrentUsersComponent } from './dashboard/widget/current-users/current-users.component';
import { DiskPartitionComponent } from './dashboard/widget/disk-partition/disk-partition.component';
import { GeoLocationComponent } from './dashboard/widget/geo-location/geo-location.component';
import { LiveGraphComponent } from './dashboard/widget/live-graph/live-graph.component';
import { LiveTableComponent } from './dashboard/widget/live-table/live-table.component';
import { MachineStatsComponent } from './dashboard/widget/machine-stats/machine-stats.component';
import { OpenHardwareMonitorComponent } from './dashboard/widget/open-hardware-monitor/open-hardware-monitor.component';
import { AgentToolsModule } from './diagnostics/agent-tools/agent-tools.module';
import { DebugComponent } from './diagnostics/debug/debug.component';
import { OnlineUsersComponent } from './diagnostics/online-users/online-users.component';
import { ExpiredSubscriptionComponent } from './expired-subscription/expired-subscription.component';
import { ExpiredSubscriptionModule } from './expired-subscription/expired-subscription.module';
import { AddBlockComponent } from './inventory/blocked-machines/add-block/add-block.component';
import { BlockedMachinesComponent } from './inventory/blocked-machines/blocked-machines.component';
import { MachinesComponent } from './inventory/machines/machines.component';
import { RemoveLicenseComponent } from './inventory/machines/remove-license/remove-license.component';
import { UserSessionsComponent } from './inventory/user-sessions/user-sessions.component';
import { AgentToolbarComponent } from './layout/main/agent-toolbar/agent-toolbar.component';
import { MainComponent } from './layout/main/main.component';
import { BufferTimlineComponent } from './layout/main/timeline-toolbar/buffer-timline/buffer-timline.component';
import { DateSummaryComponent } from './layout/main/timeline-toolbar/date-summary/date-summary.component';
import { HourSummaryComponent } from './layout/main/timeline-toolbar/hour-summary/hour-summary.component';
import { IncidentTimlineComponent } from './layout/main/timeline-toolbar/incident-timline/incident-timline.component';
import { TimelineToolbarComponent } from './layout/main/timeline-toolbar/timeline-toolbar.component';
import { TaskDrawerComponent } from './layout/task-drawer/task-drawer.component';
import { LoginMetricsComponent } from './login-metrics/login-metrics.component';
import { DownloadAgentComponent } from './misc/download-agent/download-agent.component';
import { ErrorComponent } from './page/error/error.component';
import { LoginModule } from './page/login/login.module';
import { LogoutComponent } from './page/logout/logout.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { ProfileModule } from './profile/profile.module';
import { RemoteComponent } from './remote/remote.component';
import { ScriptsModule } from './scripts/scripts.module';
import { SettingsModule } from './settings/settings.module';
import { GraphComponent } from './task-manager/performance/graph/graph.component';
import { PerformanceComponent } from './task-manager/performance/performance.component';
import { ProcessesComponent } from './task-manager/processes/processes.component';
import { ServicesComponent } from './task-manager/services/services.component';
import { StartupComponent } from './task-manager/startup/startup.component';
import { TasksComponent } from './tasks/tasks.component';
import { ActionsModule } from './tools/actions/actions.module';
import { TriggersModule } from './tools/triggers/triggers.module';

@NgModule({
  declarations: [
    AddBlockComponent,
    AgentToolbarComponent,
    BlockedMachinesComponent,
    CurrentUsersComponent,
    DashboardComponent,
    DebugComponent,
    DownloadAgentComponent,
    ErrorComponent,
    GeoLocationComponent,
    LiveGraphComponent,
    LiveTableComponent,
    MachineStatsComponent,
    MachinesComponent,
    MainComponent,
    NotFoundComponent,
    OnlineUsersComponent,
    PerformanceComponent,
    ProcessesComponent,
    RemoteComponent,
    RemoveLicenseComponent,
    ServicesComponent,
    StartupComponent,
    TaskDrawerComponent,
    TasksComponent,
    TimelineToolbarComponent,
    DateSummaryComponent,
    HourSummaryComponent,
    IncidentTimlineComponent,
    BufferTimlineComponent,
    LoginMetricsComponent,
    GraphComponent,
    AgreementComponent,
    DashboardConfigPanelComponent,
    LogoutComponent,
    DiskPartitionComponent,
    OpenHardwareMonitorComponent,
    UserSessionsComponent
  ],
  imports: [
    NoAgentConnectionComponent,
    ActionsModule,
    AgentToolsModule,
    AlarmsModule,
    CommonModule,
    ComponentsModule,
    ContainerModule,
    FontAwesomeModule,
    FormsModule,
    GoogleMapsModule,
    LoginModule,
    MarkdownModule.forChild(),
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    NgApexchartsModule,
    PageLayoutModule,
    ProfileModule,
    ReactiveFormsModule,
    RouterModule,
    ScriptsModule,
    ScrollingModule,
    SecondaryToolbarModule,
    SettingsModule,
    SharedModule,
    TriggersModule,
    ExpiredSubscriptionModule
  ],
  exports: [
    MainComponent,
    TaskDrawerComponent,
    DashboardConfigPanelComponent,
    ExpiredSubscriptionComponent
  ]
})
export class ViewModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      // brand
      faApple,
      faLinux,
      faWindows,
      faCcVisa,
      faCcMastercard,
      faCcJcb,
      faCcDiscover,
      faCcAmex,
      faCcDinersClub,

      // solid
      faArrowRightLong,
      faBars,
      faEllipsisVertical,
      faFlag,
      faLayerGroup,
      faSquare,
      faPaperclip,
      faPlusSolid,
      faTimesSolid,
      faWavePulse,

      // duotone
      faAddressCard,
      faAlarmExclamation,
      faAngleDoubleLeft,
      faAngleDoubleRight,
      faAngleDown,
      faAngleRight,
      faAsterisk,
      faBallot,
      faBan,
      faBank,
      faBarsProgress,
      faBatteryBolt,
      faBatteryFull,
      faBatteryThreeQuarters,
      faBatteryHalf,
      faBatteryQuarter,
      faBatteryLow,
      faBatteryEmpty,
      faBellOn,
      faBellSlash,
      faBinary,
      faBolt,
      faBook,
      faBookmark,
      faBug,
      faBuilding,
      faBuildingShield,
      faCalendar,
      faCalendarAlt,
      faCaretDown,
      faCertificate,
      faChartBar,
      faChartLine,
      faChartNetwork,
      faCheck,
      faCheckCircle,
      faCheckDouble,
      faCheckSquare,
      faChevronDoubleLeft,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faCircleNodes,
      faClipboardCheck,
      faClipboardList,
      faClock,
      faClockRotateLeft,
      faClone,
      faCloudDownload,
      faCog,
      faColumns,
      faCommentDots,
      faComputer,
      faComputerClassic,
      faConstruction,
      faCopy,
      faCreditCard,
      faDesktop,
      faDiscDrive,
      faDown,
      faDownload,
      faEngine,
      faEnvelope,
      faEnvelopeOpenText,
      faEthernet,
      faExclamation,
      faExclamationSquare,
      faExternalLink,
      faExternalLinkSquare,
      faEye,
      faFan,
      faFileAlt,
      faFingerprint,
      faFlagCheckered,
      faGlobe,
      faHandPaper,
      faHdd,
      faHeadset,
      faHeartbeat,
      faHeartBroken,
      faHourglassEnd,
      faHouseSignal,
      faIdBadge,
      faLink,
      faLoader,
      faLocation,
      faLock,
      faMapMarkerAlt,
      faMemory,
      faMicrochip,
      faMinus,
      faMinusCircle,
      faMoneyCheckDollarPen,
      faMonitorHeartRate,
      faNetworkWired,
      faPaperPlane,
      faPaperPlaneTop,
      faPencilAlt,
      faPercentage,
      faPhone,
      faPlay,
      faPlug,
      faPlus,
      faPortalExit,
      faPowerOff,
      faQuestionCircle,
      faRabbitFast,
      faReceipt,
      faSatelliteDish,
      faScroll,
      faSealExclamation,
      faSearch,
      faSearchPlus,
      faServer,
      faShapes,
      faShareAlt,
      faShield,
      faShieldPlus,
      faShieldAlt,
      faShieldCheck,
      faSignIn,
      faSignOut,
      faSiren,
      faSitemap,
      faSlash,
      faSquareFull,
      faStop,
      faStopCircle,
      faSyncAlt,
      faTachometerAltFast,
      faTags,
      faTasks,
      faTelescope,
      faTerminal,
      faTemperatureHigh,
      faThumbsUp,
      faThumbtack,
      faTimes,
      faTimesCircle,
      faToggleOff,
      faToolbox,
      faTriangle,
      faTurtle,
      faUnlink,
      faUp,
      faUser,
      faUserCircle,
      faUserClock,
      faUserHeadset,
      faUserPlus,
      faUsers,
      faUsersClass,
      faUsersRectangle,
      faUserShield,
      faUserTag,
      faWaveformPath,
      faWifi,
      faWifi1,
      faWifi2,
      faWifiSlash
    );
  }
}
