import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { fadeOut200ms } from 'src/app/shared/animations/fade-out.animation';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'mon-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [fadeOut200ms]
})
export class SpinnerComponent {
  spin: boolean;
  @Input('spin')
  get _spin(): boolean {
    return this.spin;
  }
  set _spin(value: boolean | '') {
    this.spin = value === '' || value;
  }

  @Input() header: string;
  @Input() footer: string;
  @Input() opacity = 0.75;
  @Input() overlay: boolean;
}
