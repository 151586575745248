import { QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { WifiInterface } from 'src/app/shared/models/network-summary.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { WifiIcon } from './dashboard.classes';
import { LiveGraphComponent } from './widget/live-graph/live-graph.component';

const _wifiGraphProps = new BehaviorSubject<string[]>([]);
export const wifiGraphProps$ = _wifiGraphProps.asObservable();
const count = 20;

export function updateWifi(
  wifiCharts: QueryList<LiveGraphComponent>,
  wifiGraphOps: GraphOptions[],
  wifiAdapters: WifiInterface[],
  triggers: Array<Trigger> = []
): void {
  wifiAdapters = wifiAdapters || [];
  const now = new Date();

  wifiAdapters
    .filter((w) => w.interfaceState === 1)
    .forEach((w) => {
      // w.signalQuality = Math.floor(Math.random() * Math.floor(100)); // FOR SIMULATION
      const quality = WifiIcon.getQuality(w.signalQuality);
      const value = [
        Math.round((w.sendSpeed * 8) / 1000),
        Math.round((w.receiveSpeed * 8) / 1000)
      ];
      let ops: GraphOptions = wifiGraphOps.find((wg) => wg.id === w.interfaceGUID);

      if (!ops) {
        ops = {
          id: w.interfaceGUID,
          title: `Wi-Fi`,
          label: w.interfaceDescription,
          icon: quality.icon,
          iconColor: quality.color,
          seriesColors: [['orange', 'orange']],
          seriesLineStyle: [['dashed', 'solid']],
          maxY: [undefined],
          initialSeries: [
            [
              {
                name: 'Send',
                data: new Array(count).fill(0)
              },
              {
                name: 'Receive',
                data: new Array(count).fill(0)
              }
            ]
          ],
          focusProperties: [],
          properties: {}
        };

        wifiGraphOps.push(ops);
      } else {
        ops.icon = quality.icon;
        ops.iconColor = quality.color;
        value.forEach((v, i) => {
          ops.initialSeries[0][i].data.splice(0, 1);
          ops.initialSeries[0][i].data.push(v);
        });
      }

      ops.timestamp = now;

      Object.assign(ops.focusProperties, [
        {
          label: 'Send',
          value: w.sendSpeedD,
          graphColor: 'orange',
          graphLineStyle: 'dashed'
        },
        {
          label: 'Receive',
          value: w.receiveSpeedD,
          graphColor: 'orange',
          graphLineStyle: 'solid'
        },
        {
          label: 'Strength',
          value: `${w.signalQuality}%`,
          highlight: !!triggers.find(
            (t) =>
              !!t.conditions.find(
                (c) =>
                  c.field === 'Summary.Network.WifiAdapters.SignalQuality' &&
                  c.isMet(w.signalQuality)
              )
          )
        },
        {
          label: 'Gateway Latency',
          value: w.gatewayPing.enabled
            ? w.gatewayPing
              ? w.gatewayPing.pingD
              : ''
            : 'Disabled',
          highlight: !!triggers.find(
            (t) =>
              !!t.conditions.find(
                (c) =>
                  c.field === 'Summary.Network.WifiAdapters.GatewayPing.AvgRtt' &&
                  c.isMet(w.gatewayPing.avgRtt)
              )
          )
        },
        {
          label: 'Link Speed(Rx/Tx)',
          value: `${w.rxRate / 1000}/${w.txRate / 1000}Mbps`
        }
      ]);

      Object.assign(ops.properties, {
        IP: w.ipv4,
        'Gateway IP': w.gatewayIP,
        SSID: w.ssid,
        Protocol: w.protocol,
        Security: `${w.cipher}, ${w.auth}`,
        Band: w.band
      });

      if (_wifiGraphProps.value.length < 1) {
        _wifiGraphProps.next(
          [...Object.keys(ops.properties || [])].concat(
            ops.focusProperties.map((p) => p.label)
          )
        );
      }

      wifiCharts &&
        wifiCharts
          .filter((wc) => wc.id === w.interfaceGUID)
          .forEach((wc) => {
            wc.appendData(value); // always send as Kpbs
          });
    });

  for (let i = wifiGraphOps.length - 1; i >= 0; i--) {
    if (wifiGraphOps[i].timestamp !== now) {
      wifiGraphOps.splice(i, 1);
    }
  }
}
