import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, throwError } from 'rxjs';
import { catchError, concatMap, finalize, tap } from 'rxjs/operators';
import { fadeInRight400ms, fadeOutRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { AgentService } from 'src/app/core/services/agent.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { originalOrder } from 'src/app/core/utils/keyvalue-sort';
import { fadeOutUp80ms } from 'src/app/shared/animations/fade-out-up.animation';
import { Agent } from 'src/app/shared/models/agent.model';

export class RemoveLicenseParams {
  agents: Agent[];
  includeMachineID = true;
  includeIP = true;
  onSuccess: () => void;
  constructor(init?: Partial<RemoveLicenseParams>) {
    Object.assign(this, init);
  }
}

export class ProcessedAgent {
  agent: Agent;
  processResult: number;
  constructor(init?: Partial<ProcessedAgent>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-remove-license',
  templateUrl: './remove-license.component.html',
  styleUrls: ['./remove-license.component.scss'],
  animations: [fadeInRight400ms, fadeOutUp80ms, fadeInUp200ms, fadeOutRight400ms]
})
export class RemoveLicenseComponent implements OnInit {
  originalOrder = originalOrder;

  agents = this.params.agents;

  processed = false;
  form: UntypedFormGroup;

  buttonText = 'BLOCK MACHINES';
  autoScrollToEnd = true;

  spin = true;

  processedItems = new Array<ProcessedAgent>();

  err: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: RemoveLicenseParams,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RemoveLicenseComponent>,
    private agentSvc: AgentService
  ) {
    this.form = this.fb.group({
      includeMachineID: false,
      includeIP: false,
      blockOp: ['0'],
      blockFor: [5],
      blockUntil: new Date(),
      uninstall: false
    });

    this.form.controls.blockFor.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.updateBlockOp(0);
        this.updateUninstall(0);
      }
    });

    this.form.controls.blockUntil.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.updateBlockOp(1);
        this.updateUninstall(1);
      }
    });
  }

  ngOnInit(): void {
    this.spin = false;
  }

  removeLicenses(): void {
    if (!this.processed) {
      this.processRemoval();
    } else {
      this.close();
    }
  }

  processRemoval(): void {
    this.buttonText = 'CANCEL';
    this.processed = true;
    const includeMachineID = this.form.controls.includeMachineID.value;
    const includeIP = this.form.controls.includeIP.value;
    const blockFor =
      this.form.controls.blockOp.value === '0' ? this.form.controls.blockFor.value : 0;
    const blockUntil =
      this.form.controls.blockOp.value === '1'
        ? this.form.controls.blockUntil.value
        : new Date(0);
    const uninstall = this.form.controls.uninstall.value;
    const blockIndefinitely = this.form.controls.blockOp.value >= 2;

    from(this.params.agents)
      .pipe(
        concatMap((agent, index) => {
          this.processedItems.push(
            new ProcessedAgent({ agent: agent, processResult: 0 })
          );

          return this.agentSvc
            .removeLicense(
              agent.id,
              includeMachineID,
              includeIP,
              blockFor,
              blockUntil,
              uninstall,
              blockIndefinitely
            )
            .pipe(
              catchError((err) => {
                this.processedItems[index].processResult = 2;
                return throwError(() => err);
              }),
              tap(() => (this.processedItems[index].processResult = 1))
            );
        }),
        finalize(() => (this.buttonText = 'DONE'))
      )
      .subscribe({ error: (err) => (this.err = parseError(err)) });
  }

  updateUninstall(op: number): void {
    this.form.controls.uninstall.setValue(op >= 1);
  }

  updateBlockOp(op: number): void {
    this.form.controls.blockOp.setValue(`${op}`);
  }

  close(): void {
    if (this.processed) {
      this.params.onSuccess?.();
    }
    this.dialogRef.close();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    // visible height + pixel scrolled >= total height
    if (event.target instanceof HTMLElement) {
      const element = event.target as HTMLElement;
      if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
        this.autoScrollToEnd = true;
      } else {
        this.autoScrollToEnd = false;
      }
    }
  }
}
