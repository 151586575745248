import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  delay,
  filter,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  retry,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { StateService } from 'src/app/core/services/state.service';
import { TaskService } from 'src/app/core/services/task.service';
import {
  EventLog,
  EventLogTypeData,
  EventLogTypeList,
  enumToArray
} from 'src/app/shared/interfaces/event-log.interface';
import { LogCollection } from 'src/app/shared/interfaces/log-collection.interface';
import { deepCopy } from 'src/app/utils/clone';
import { UNDEFINED } from 'src/app/utils/rxjs';

@Component({
  selector: 'mon-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent {
  form = new FormGroup({
    scope: new FormControl('', [Validators.required])
  });
  spin = true;
  eventLogTypes = EventLogTypeList;
  selectedType: string;

  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running
    .asObservable()
    .pipe(tap((running) => (this.spin = running)));

  constructor(
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private stateSvc: StateService,
    private taskSvc: TaskService,
    private diagnosticsSvc: DiagnosticsService
  ) {}

  private _eventTypeResult = new BehaviorSubject<EventLogTypeData>(undefined);
  readonly _eventTypeResult$ = this._eventTypeResult.asObservable();

  // Reverse mapping object
  eventLogTypeNames = {
    [EventLogTypeList.Application]: 'Application',
    [EventLogTypeList.Security]: 'Security',
    [EventLogTypeList.System]: 'System'
  };

  ngOnInit(): void {
    this.form.get('scope').valueChanges.subscribe((selectedValue: string) => {
      this.selectedType = selectedValue;
    });
  }

  agent$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );

  getEnumKeys() {
    return enumToArray(this.eventLogTypes);
  }

  _refreshRecent = new Subject<void>();
  recent$ = this._refreshRecent.asObservable().pipe(
    startWith(undefined),
    tap(() => (this.spin = true)),
    switchMap(() => this.agent$),
    filter((agent) => !!agent),
    switchMap((agent) => this.diagnosticsSvc.getListOfEvents(agent.id)),
    map((response) => {
      if (response?.tag) {
        const sorted = response.tag.sort((a, b) => b.time.localeCompare(a.time));
        return sorted.slice(0, 10);
      }
      return new Array<LogCollection>();
    }),
    tap(() => (this.spin = false))
  );

  public archiveEventType(agentID: string): void {
    this._running.next(true);
    const selectedValue = this.form.get('scope').value;
    const selectedEnumName = this.eventLogTypeNames[selectedValue];

    this.diagnosticsSvc
      .runArchiveEventLogType(agentID, selectedEnumName)
      .pipe(
        switchMap((response) => {
          const taskID = response.tag;
          let taskUpdateUnixNano = 0;

          return this.taskSvc.getTask(taskID).pipe(
            tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
            switchMap(() =>
              UNDEFINED.pipe(
                map(() => taskUpdateUnixNano),
                // on retries, we make sure to use the updated time
                switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
                tap((task) => {
                  if (!task) {
                    throw 'still waiting...';
                  } else if (!task.isDone) {
                    taskUpdateUnixNano = task.lastUpdateUnixNano;
                    throw 'still waiting...';
                  }
                })
              )
            ),
            retry({
              delay: (_error, _retryCount) => of(true)
            }),
            delay(2000),
            tap(() => this._refreshRecent.next()),
            finalize(() => this._running.next(false))
          );
        })
      )
      .subscribe({
        error: (err) => {
          console.warn(err);
          this.spin = false;
        }
      });
  }

  public downloadEventLog(evt: EventLog): void {
    this.diagnosticsSvc.downloadEventLog(evt.agent_id, evt.name);
  }

  trackByID(_index: number, log: LogCollection): string {
    return log.time;
  }
}
