import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { AgentConnectionState } from 'src/app/core/services/agent-live-poller.service';
import { StateService } from 'src/app/core/services/state.service';
import { scaleOut400ms } from 'src/app/shared/animations/scale-out.animation';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { NoAgentConnectionComponent } from 'src/app/shared/components/no-agent-connection/no-agent-connection.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { AgentBlock } from 'src/app/shared/models/agent-block.model';
import { Agent } from 'src/app/shared/models/agent.model';
import { Script } from 'src/app/shared/models/script.model';
import { Remotee } from 'src/app/shared/models/session.model';
import { OS } from 'src/app/shared/models/system.model';
import {
  ScriptInvokeComponent,
  ScriptInvokeParams
} from 'src/app/view/scripts/script-invoke/script-invoke.component';
import { ScriptSearchComponent } from '../../../scripts/script-search/script-search.component';

export enum UXHistoryTimeTypes {
  Yesterday = 'yesterday',
  ThisWeek = 'this week',
  OneWeekAgo = '1 week ago',
  TodayAndSevenDays = 'today and 7 days',
  ThisMonth = 'this month'
}

@Component({
  selector: 'mon-agent-toolbar',
  templateUrl: './agent-toolbar.component.html',
  styleUrls: ['./agent-toolbar.component.scss'],
  animations: [scaleIn400ms, scaleOut400ms]
})
export class AgentToolbarComponent {
  static readonly UX_PATH =
    '/stratusphere/angular/beta/demo/diagnostics/individual-views';

  uxHistoryTimeTypes = Object.keys(UXHistoryTimeTypes);
  uxHistoryTimeValues = Object.values(UXHistoryTimeTypes);
  permission = Permission;
  OS = OS;

  @Input() agent: Agent;
  @Input() agentBlocks: AgentBlock[];
  @Input() outgoingSessions: Remotee[];
  @Input() agentConnectionState: AgentConnectionState;
  @Input() liveMode: boolean;

  @ViewChild('remoteMenu') remoteMenu: MatMenuTrigger;

  noConnectionDialogOpen: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private stateSvc: StateService
  ) {}

  openMachineInNewTab(agentID: string): void {
    window.open(`/home/dashboard?machineID=${agentID}`, '_blank');
  }

  openUX(link: string, t: UXHistoryTimeTypes, agentID: string) {
    link = `${link.indexOf('https://') !== 0 ? `https://${link}` : `${link}`}${AgentToolbarComponent.UX_PATH}`;

    window.open(
      `${link}/?machineName=${agentID}&date=${encodeURIComponent(t)}`,
      '_blank'
    );
  }

  gotoOutgoingSession(sessions: Array<Remotee>): void {
    if (sessions.length === 1) {
      this.openMachineInNewTab(sessions[0].agentID);
    } else {
      this.remoteMenu.openMenu();
    }
  }

  getRemoteSessionsTooltip(sessions: Array<Remotee>): string {
    const hostname = this.agent.system.hostname.toLowerCase();

    if (sessions.length === 1) {
      const usernames = sessions[0].getUsers(hostname);
      const machine = sessions[0].machine;
      return `${usernames.join(', ')} is connected to ${machine}`;
    }

    return 'Multiple remote sessions are connected';
  }

  toggleLiveMode(toggle: boolean): void {
    this.stateSvc.setLiveMode(toggle);
  }

  noConnectionHelp(): void {
    if (
      this.noConnectionDialogOpen &&
      this.noConnectionDialogOpen.getState() === MatDialogState.OPEN
    ) {
      return;
    }

    if (this.dialog.getDialogById('no_connection_dialog')) {
      return;
    }

    const x = NoAgentConnectionComponent;

    this.noConnectionDialogOpen = this.dialog.open(ConfirmDialogComponent, {
      id: 'no_connection_dialog',
      autoFocus: true,
      restoreFocus: false,
      disableClose: true,
      maxWidth: '80vw',
      data: {
        component: x,
        icon: 'question-circle',
        title: `Connection Tips`,
        cancelButtonText: 'OK',
        onCancel: () => {}
      }
    });
  }

  openShell(agentID: string): void {
    window.open(
      `shell?machineID=${agentID}`,
      '_blank',
      'toolbar=no, menubar=no, titlebar=no, height=625, width=1200'
    );
  }

  gotoURL(url: string | string[]): void {
    if (typeof url === 'string') {
      url = [url];
    }

    this.router.navigate(url, { queryParamsHandling: 'merge' });
  }

  searchScript(): void {
    this.dialog.open(ScriptSearchComponent, {
      id: 'script_search_dialog',
      autoFocus: true,
      restoreFocus: false,
      data: {
        onSuccess: (script: Script) => {
          this.runScript(script);
        }
      }
    });
  }

  runScript(script: Script): void {
    this.dialog.open(ScriptInvokeComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new ScriptInvokeParams({
        targetMachines: [this.agent.id],
        script: script.installRefID,
        onSuccess: (task) => {
          this.router.navigate(['/home/tools/scripts/results', task.id]);
        }
      }),
      width: '600px',
      height: 'auto',
      maxWidth: '95vw',
      maxHeight: '95vh'
    });
  }
}
