<div class="flex flex-col h-full">
  <div class="script-header border-b-2 p-2">
    <div class="flex flex-col">
      <div class="flex">
        <div class="text-2xl">{{ script.name }}</div>
        <div class="pl-2 text-secondary flex flex-col items-center justify-center">
          <div matTooltip="Script ID">{{ script.id }}</div>
        </div>
      </div>
      <div data-intro-key="script-detail-header" class="flex flex-col md:flex-row md:items-center mt-2 truncate">
        <div class="md:pr-4 uppercase">{{ script.type }}</div>
        <div class="md:px-4 md:border-l-2" matTooltip="Author">{{ script.publisher }}</div>
        <div class="flex truncate">
          <ng-container *ngIf="script.location == scriptLocation.Public">
            <div class="px-4 border-l-2" matTooltip="Public installs">
              <fa-icon class="pr-2 text-gray dark:text-white" [icon]="['fad', 'cloud-download']"></fa-icon>{{ script.installs }}
            </div>
            <div class="px-4 border-l-2" matTooltip="Public rating">
              <fa-icon class="pr-2 text-gray dark:text-white" [icon]="['fad', 'thumbs-up']"></fa-icon>{{ script.likes }}
            </div>
          </ng-container>
          <div class="flex mt-1 md:mt-0 md:px-4 md:border-l-2">
            <div
              *ngIf="script.archived"
              class="rounded bg-red-300 px-2 mr-1"
              matTooltip="This script is no longer supported or available publicly. If you uninstall this script, you will lose it."
            >
              <span>archived</span>
            </div>
            <div class="rounded bg-gray-100 dark:bg-gray px-2 mr-1">
              <span>{{ script.location }}</span>
            </div>
            <div
              class="rounded px-2"
              [ngClass]="script.hasUpgrade ? 'bg-red-300 dark:bg-red' : 'bg-gray-100 dark:bg-gray'"
              [matTooltip]="script.hasUpgrade ? 'Update available' : 'Version'"
            >
              <ng-container *ngIf="script.hasUpgrade; else noUpgrade">
                <span class="text-secondary">v</span>
                <span>{{ script.installVersion }}</span>
                <fa-icon [icon]="['fas', 'long-arrow-right']" class="px-1"></fa-icon>
                <span class="text-secondary">v</span>
                <span>{{ script.latestVersion }}</span>
              </ng-container>
              <ng-template #noUpgrade>
                <span class="text-secondary">v</span>
                <span>{{ script.installVersion || script.latestVersion }}</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngLet="(user$ | async).hasPerms([permission.Search_Invoke]) as hasPerm">
        <div data-intro-key="script-detail-tags" class="hidden md:flex flex-wrap gap-1 mt-2">
          <div
            (click)="hasPerm ? tagSelect.emit(tag) : null"
            [attr.matRipple]="hasPerm"
            *ngFor="let tag of script.tags"
            class="rounded bg-pink-200 dark:bg-pink px-2 last:mr-0"
            [ngClass]="hasPerm ? 'cursor-pointer' : 'cursor-default'"
          >
            <span class="whitespace-nowrap">{{ tag }}</span>
          </div>
        </div>
      </ng-container>
      <div class="flex mt-2">
        <div>{{ script.description }}</div>
      </div>
      <div class="flex flex-col md:flex-row items-start md:items-center justify-between w-full mt-2">
        <div class="flex w-full">
          <div class="flex w-full">
            <ng-container *ngIf="script.installVersion; else install">
              <ng-container *ngIf="script.userPermission.invoke">
                <button
                  *hasPerm="[permission.Script_Invoke]"
                  [disabled]="disableAction"
                  (click)="runScript()"
                  mat-raised-button
                  color="primary"
                  [style.font-variant]="'all-small-caps'"
                  class="text-sm p-1 m-2 ml-0 last:mr-0"
                >
                  Run
                </button>
              </ng-container>
              <ng-container *ngIf="script.userPermission.write">
                <ng-container *hasPerm="[permission.Script_Update]">
                  <button
                    *ngIf="script.hasUpgrade"
                    [disabled]="disableAction"
                    (click)="upgradeScript()"
                    mat-raised-button
                    color="accent"
                    [style.font-variant]="'all-small-caps'"
                    class="text-sm p-1 m-2 ml-0 last:mr-0"
                  >
                    Upgrade
                  </button>
                  <ng-container *ngIf="script.disabled; else disabled">
                    <button
                      [disabled]="disableAction"
                      (click)="enableScript()"
                      mat-raised-button
                      color="primary"
                      [style.font-variant]="'all-small-caps'"
                      class="text-sm p-1 m-2 ml-0 last:mr-0"
                    >
                      Enable
                    </button>
                  </ng-container>
                  <ng-template #disabled>
                    <button
                      [disabled]="disableAction"
                      (click)="disableScript()"
                      mat-raised-button
                      color="warn"
                      [style.font-variant]="'all-small-caps'"
                      class="text-sm p-1 m-2 ml-0 last:mr-0"
                    >
                      Disable
                    </button>
                  </ng-template>
                </ng-container>
              </ng-container>
              <ng-container *hasPerm="[permission.Script_Delete]">
                <ng-container *ngIf="script.location === scriptLocation.Private; else uninstall">
                  <button
                    [disabled]="disableAction"
                    (click)="deleteScript()"
                    mat-raised-button
                    color="warn"
                    [style.font-variant]="'all-small-caps'"
                    class="text-sm p-1 m-2 ml-0 last:mr-0"
                  >
                    Delete
                  </button>
                </ng-container>
              </ng-container>
              <ng-template #uninstall>
                <ng-container *hasPerm="[permission.Script_Uninstall]">
                  <button
                    [disabled]="disableAction"
                    (click)="uninstallScript()"
                    mat-raised-button
                    color="warn"
                    [style.font-variant]="'all-small-caps'"
                    class="text-sm p-1 m-2 ml-0 last:mr-0"
                  >
                    Uninstall
                  </button>
                </ng-container>
              </ng-template>
              <ng-container *ngIf="script.userPermission.write">
                <ng-container *hasPerm="[permission.Script_Update]">
                  <button
                    *ngIf="script.location === scriptLocation.Private"
                    [disabled]="disableAction"
                    (click)="modScript()"
                    mat-raised-button
                    [style.font-variant]="'all-small-caps'"
                    class="text-sm p-1 m-2 ml-0 last:mr-0"
                  >
                    Edit
                  </button>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #install>
              <ng-container *hasPerm="[permission.Script_Install]">
                <button
                  [disabled]="disableAction"
                  (click)="installScript()"
                  mat-raised-button
                  color="primary"
                  [style.font-variant]="'all-small-caps'"
                  class="text-sm p-1 m-2 ml-0 last:mr-0"
                >
                  Install
                </button>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="script.location === scriptLocation.Public">
              <button
                *hasPerm="['godmode']"
                [disabled]="disableAction"
                (click)="modScript()"
                mat-raised-button
                [style.font-variant]="'all-small-caps'"
                class="text-sm p-1 m-2 ml-0 last:mr-0"
              >
                Edit
              </button>
              <ng-container *ngIf="!script.archived">
                <button
                  *hasPerm="['godmode']"
                  [disabled]="disableAction"
                  (click)="archiveScript()"
                  mat-button
                  color="primary"
                  [style.font-variant]="'all-small-caps'"
                  class="text-sm p-1 m-2 ml-0 last:mr-0"
                >
                  Archive
                </button>
              </ng-container>
              <button
                *hasPerm="['godmode']"
                [disabled]="disableAction"
                (click)="deleteScript()"
                mat-button
                color="warn"
                [style.font-variant]="'all-small-caps'"
                class="text-sm p-1 m-2 ml-0 last:mr-0"
              >
                Delete
              </button>
            </ng-container>
          </div>
        </div>
        <div data-intro-key="script-detail-feedback" *ngIf="script.location === scriptLocation.Public" class="flex justify-evenly gap-1">
          <button
            [disabled]="disableSocial"
            (click)="script.userLiked ? unlikeScript() : likeScript()"
            mat-button
            [style.font-variant]="'all-small-caps'"
            class="text-sm p-1 my-2"
            [color]="script.userLiked ? 'warn' : 'primary'"
          >
            {{ script.userLiked ? 'unlike' : 'like' }}
          </button>
          <button (click)="giveFeedback()" mat-button [style.font-variant]="'all-small-caps'" class="text-sm p-1 my-2">feedback</button>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-group p-2 h-full min-h-40 md:min-h-0">
    <mat-tab-group class="h-full" animationDuration="100" [dynamicHeight]="true" (selectedTabChange)="tabChange($event)">
      <mat-tab label="Documentation">
        <div class="absolute h-full top-0 bottom-0 left-0 right-0 overflow-auto">
          <div class="p-4 py-2">
            <markdown emoji class="prose prose-sm dark:prose-invert prose-hr:mb-3 overflow-auto" [data]="script.detail"></markdown>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Source">
        <div *ngIf="scriptCode$ | async as code" class="absolute mt-2 top-0 bottom-0 left-0 right-0">
          <div @fadeInUp *ngIf="codeLoaded$ | async" class="relative w-full h-full">
            <div class="code-wrapper absolute top-0 bottom-0 left-0 right-0 overflow-auto">
              <markdown
                emoji
                lineNumbers
                class="prose prose-sm dark:prose-invert prose-hr:mb-3 overflow-auto fix-markdown-padding selection:bg-pink-light"
                [data]="code | language: 'powershell'"
              ></markdown>
            </div>
            <button
              #copyScript="matTooltip"
              matTooltip="Copy"
              class="absolute right-4 bottom-1 code-button text-sm"
              (click)="getCopy(copyScript, code)"
            >
              copy
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="!!script.installRefID" label="Permissions">
        <div *ngIf="scriptPerms$ | async as perms" class="absolute top-0 bottom-0 left-0 right-0">
          <div @fadeInUp class="relative w-full h-full">
            <ng-container *ngIf="user$ | async as user">
              <mon-permissions
                #permControl
                [readonly]="!(user.hasPerms([permission.Script_Update]) && script.userPermission.write)"
                [permissions]="perms"
                (onAdd)="permOnAdd($event)"
                (onUpdate)="permOnUpdate($event)"
                (onDelete)="permOnDelete($event)"
              ></mon-permissions>
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Changelog">
        <div *ngIf="scriptChangelog$ | async as changelog" class="absolute h-full top-0 bottom-0 left-0 right-0 overflow-auto">
          <ng-container *ngIf="changelog.length > 0; else noChangelog">
            <div class="py-2">
              <div *ngFor="let c of changelog" class="ml-4 flex flex-col mb-4 last:mb-0">
                <div class="flex flex-col">
                  <div class="flex">
                    <h3>v{{ c.version }}</h3>
                    <h4 class="ml-4 font-light">({{ c.timestamp | date: 'short' }})</h4>
                  </div>
                  <ul class="ml-6 list-disc">
                    <ng-container *ngIf="c.changelog?.length > 0; else noMessages">
                      <li *ngFor="let m of c.changelog">{{ m }}</li>
                    </ng-container>
                    <ng-template #noMessages>
                      <li class="italic text-secondary">no logs</li>
                    </ng-template>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noChangelog>
            <div class="m-2"><h5 class="italic">First commit</h5></div>
          </ng-template>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
