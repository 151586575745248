import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { catchError, finalize, map, tap } from 'rxjs';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import {
  FieldGroup,
  FieldGroups,
  Ops,
  Trigger,
  TriggerCondition
} from 'src/app/shared/models/trigger.model';
import { SharedModule } from 'src/app/shared/shared.module';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { CustomValidators } from 'src/app/utils/validators';

export class TriggerEditParams {
  copy: boolean;
  triggerID: Trigger['id'];
  onSuccess: (trigger: Trigger) => void;
  constructor(init?: Partial<TriggerEditParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-trigger-edit',
  standalone: true,
  templateUrl: './trigger-edit.component.html',
  styleUrls: ['./trigger-edit.component.scss'],
  imports: [
    SharedModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    SpinnerComponent,
    ReactiveFormsModule,
    ResultOverlayComponent
  ]
})
export class TriggerEditComponent implements OnInit {
  toFriendlyCondition = Trigger.toFriendlyCondition;
  copyOf: string;
  fieldGroups = FieldGroups;
  ops = Ops;

  overlayResult = OverlayResult.Unset;
  spin = false;
  loading = false;

  conditionIndex = 0;
  conditionIndices: number[] = [];

  detailGroup = this.fb.group({
    name: [
      '',
      [CustomValidators.noWhitespace, Validators.required, Validators.maxLength(50)]
    ],
    description: ['', [Validators.maxLength(200)]]
  });
  conditionsGroup = this.fb.group({});
  confirmGroup = this.fb.group({});

  trigger: Trigger = new Trigger();

  @ViewChild(MatStepper) matStepper: MatStepper;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('descriptionInput', { static: true }) descriptionInput: ElementRef;
  @ViewChild('conditionInputs', { static: true }) conditionInputs: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected params: TriggerEditParams,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TriggerEditParams>,
    private snackBar: MatSnackBar,
    private triggerSvc: TriggerService
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.nameInput.nativeElement.focus(), 400);

    if (this.params.triggerID) {
      this.spin = true;
      this.loading = true;
      this.triggerSvc
        .getByID(this.params.triggerID)
        .pipe(
          map((t) => {
            if (this.params.copy) {
              this.makeCopy(t);
            }

            return t;
          }),
          tap((t) => {
            this.trigger = t;

            this.detailGroup.controls.name.setValue(this.trigger.name);
            this.detailGroup.controls.description.setValue(this.trigger.description);

            this.trigger.conditions.forEach((c) => {
              this.addCondition(c.field, c.op, c.value, c.intervalDuration);
            });

            if (this.trigger.system) {
              this.detailGroup.disable();
              this.conditionsGroup.disable();
            }
          }),
          catchError((err) => {
            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.dialogRef.close();
            return UNDEFINED;
          }),
          finalize(() => {
            this.spin = false;
            this.loading = false;
          })
        )
        .subscribe();
    }
  }

  onStep(event: StepperSelectionEvent) {
    switch (event.selectedStep.label) {
      case 'Detail':
        setTimeout(() => {
          this.nameInput.nativeElement.focus();
        }, 1000);
        break;
      case 'Conditions':
        if (this.conditionIndices.length === 0) {
          this.addCondition();
        }
        break;
    }
  }

  addCondition(field = '', op = '', value = undefined, intervalDuration = 6): void {
    const i = this.conditionIndex++;
    this.conditionIndices.push(i);
    this.conditionsGroup.addControl(
      `field${i}`,
      this.fb.control(field, [Validators.required])
    );
    this.conditionsGroup.addControl(`op${i}`, this.fb.control(op, [Validators.required]));
    this.conditionsGroup.addControl(
      `value${i}`,
      this.fb.control(value, [Validators.required])
    );
    this.conditionsGroup.addControl(
      `intervalDuration${i}`,
      this.fb.control(intervalDuration, [Validators.required])
    );
  }

  removeCondition(i: number): void {
    this.conditionIndices = this.conditionIndices.filter((p) => p !== i);
    this.conditionsGroup.removeControl(`field${i}`, {});
    this.conditionsGroup.removeControl(`op${i}`, {});
    this.conditionsGroup.removeControl(`value${i}`, {});
    this.conditionsGroup.removeControl(`intervalDuration${i}`, {});
  }

  getConditionError(name: string, validationName: string): boolean {
    const v = this.conditionsGroup.controls[name].errors?.[validationName];
    return !!v;
  }

  getMetricUnit(fieldGroups: FieldGroup[], fieldValue: string): string {
    if (fieldValue) {
      for (const fg of fieldGroups) {
        const field = fg.field.find((f) => f.value === fieldValue);

        if (field) {
          return field.metricUnit;
        }
      }
    }

    return '';
  }

  save(): void {
    this.spin = true;

    const conditions: TriggerCondition[] = [];

    this.conditionIndices.forEach((i) => {
      const field = this.conditionsGroup.controls[`field${i}`].value;
      const op = this.conditionsGroup.controls[`op${i}`].value;
      const value = this.conditionsGroup.controls[`value${i}`].value;
      const intervalDuration =
        this.conditionsGroup.controls[`intervalDuration${i}`].value;
      conditions.push(
        new TriggerCondition({
          field,
          op,
          value,
          intervalDuration
        })
      );
    });

    if (this.params.triggerID && !this.params.copy) {
      this.triggerSvc
        .update(
          this.trigger.id,
          this.detailGroup.controls.name.value,
          this.detailGroup.controls.description.value,
          conditions
        )
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: () => (this.overlayResult = OverlayResult.Success),
          error: (err: HttpErrorResponse) => {
            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.overlayResult = OverlayResult.Error;
          }
        });
    } else {
      this.triggerSvc
        .create(
          this.detailGroup.controls.name.value,
          this.detailGroup.controls.description.value,
          conditions
        )
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: () => (this.overlayResult = OverlayResult.Success),
          error: (err: HttpErrorResponse) => {
            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.overlayResult = OverlayResult.Error;
          }
        });
    }
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.(undefined);
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  makeCopy(t: Trigger): void {
    this.params.copy = true;
    this.copyOf = t.name;
    t.name = '';
    t.id = '';
    t.system = false;
    t.disabled = false;
    t.created = undefined;
    t.timestamp = undefined;
  }

  createCopy(): void {
    this.makeCopy(this.trigger);
    this.matStepper.selectedIndex = 0;
    this.detailGroup.controls.name.setValue('');
    this.nameInput.nativeElement.focus();
  }
}
