import { HttpErrorResponse } from '@angular/common/http';

export const ERR_WEBCODE_WAITING_ON_CONFIRM = 'email_waiting_for_confirm';

export interface WebErrorResponse {
  message: string;
  webcode: string;
}

export function isWebErrorResponse(err: HttpErrorResponse): WebErrorResponse {
  const e = err.error as WebErrorResponse;

  return e;
}

export function parseError(err: HttpErrorResponse, defaultValue = ''): string {
  let errMsg = defaultValue;
  if (typeof err.error === typeof {} && err.error?.message) {
    errMsg = err.error.message;
  } else if (typeof err.error === 'string') {
    errMsg = err.error;
  } else if (err.statusText) {
    errMsg = `[${err.status}] ${err.statusText}`;
  } else if (err.message) {
    errMsg = err.message;
  }

  return errMsg;
}
