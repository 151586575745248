import { Component, Input, OnInit } from '@angular/core';
import { BufferedIndexResult } from 'src/app/core/services/agent-history-player.service';

@Component({
  selector: 'mon-buffer-timline',
  templateUrl: './buffer-timline.component.html',
  styleUrls: ['./buffer-timline.component.scss']
})
export class BufferTimlineComponent implements OnInit {
  @Input() count: number;

  bufferedArray: BufferedIndexResult[];

  ngOnInit(): void {
    this.bufferedArray = new Array(this.count).fill(0);
  }

  buffered(buffer: BufferedIndexResult): void {
    this.bufferedArray[buffer.index] = buffer;
  }
}
