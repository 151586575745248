import { Component, Input } from '@angular/core';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions
} from '@angular/material/checkbox';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject, Subscription, switchMap, take, tap } from 'rxjs';
import { IntroService } from 'src/app/core/services/intro.service';
import { StateService } from 'src/app/core/services/state.service';
import { RenderValue } from 'src/app/shared/interfaces/render-value';

export type PersistLevel = 'User' | 'Tenant' | 'Agent';

export class DisplayField {
  public name: string;
  public value: boolean | string;

  constructor(name: string, value: boolean | string) {
    this.name = name;
    this.value = value;
  }
}

export interface DisplayConfig {
  id: string;
  name: string;
  show?: boolean;
  fields?: DisplayField[];
}

export class ComponentDisplayConfig implements DisplayConfig {
  public id: string;
  public name: string;
  public show = true;
  public fields = [];

  private fieldMap = new Map<string, DisplayField>();

  private _config = new Subject<ComponentDisplayConfig>();
  config$ = this._config.asObservable();

  constructor(init?: Partial<DisplayConfig>) {
    if (init) {
      this.id = init.id;
      this.merge(init, true);
    }

    if (this.fields && this.fields.length > 0) {
      this.fields.forEach((f) => this.fieldMap.set(f.name, f));
    }

    this.fields.sort(this.sortByName);
  }

  sortByName = (a: DisplayField, b: DisplayField) => (a.name > b.name ? 1 : -1);

  merge(config: Partial<DisplayConfig>, addIfMissing = false): ComponentDisplayConfig {
    if (!config) {
      return this;
    }

    this.name = config.name;
    this.show = config.show;

    const fields: unknown = config.fields;

    if (fields instanceof Array) {
      fields.forEach((f: DisplayField) => {
        if (this.fieldMap.has(f.name)) {
          const updateF = this.fieldMap.get(f.name);
          updateF.value = f.value;
        } else if (addIfMissing) {
          this.fields.push(f);
          this.fieldMap.set(f.name, f);
        }
      });
    } else if (fields instanceof Object) {
      Object.keys(fields).forEach((k) => {
        const f = new DisplayField(k, fields[k]);
        if (this.fieldMap.has(f.name)) {
          const updateF = this.fieldMap.get(f.name);
          updateF.value = f.value;
        } else if (addIfMissing) {
          this.fields.push(f);
          this.fieldMap.set(f.name, f);
        }
      });
    }

    if (addIfMissing) {
      this._config.next(this);
    }

    return this;
  }

  public addFields(fields: string[]): void {
    let fieldsAdded = false;
    fields.forEach((f) => {
      if (!this.fieldMap.has(f)) {
        fieldsAdded = true;
        const field = new DisplayField(f, true);
        this.fields.push(field);
        this.fieldMap.set(f, field);
      }
    });

    if (fieldsAdded) {
      this._config.next(this);
    }
  }

  public hideControl(): void {
    if (this.show) {
      this.show = false;
      this._config.next(this);
    }
  }

  public toggle(fieldName: string): void {
    if (this.fieldMap.has(fieldName)) {
      const field = this.fieldMap.get(fieldName);
      if (typeof field.value === 'boolean') {
        field.value = !field.value;
        this._config.next(this);
      } else {
        throw new Error('toggle only works for booleans');
      }
    }
  }

  public fieldValue(fieldName: string): boolean | string {
    if (this.fieldMap.has(fieldName)) {
      const field = this.fieldMap.get(fieldName);
      return field.value;
    }

    return true;
  }

  public updateFieldValue(fieldName: string, value: boolean | string): void {
    if (this.fieldMap.has(fieldName)) {
      const field = this.fieldMap.get(fieldName);
      field.value = value;
    }
  }

  public result(): unknown {
    const fields = {};
    this.fields.forEach((f) => (fields[f.name] = f.value));

    return {
      fields: fields,
      name: this.name,
      show: this.show
    };
  }
}

export class TableComponentDisplayConfig extends ComponentDisplayConfig {
  public result(): unknown {
    const fields = {};
    this.fields.forEach((f) => (fields[f.name] = f.value));

    return {
      fields: fields
    };
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-display-config-button[persistAt][config]',
  templateUrl: './display-config-button.component.html',
  styleUrls: ['./display-config-button.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
    }
  ]
})
export class DisplayConfigButtonComponent {
  @Input() persistAt: PersistLevel;

  @Input() canHide = true;

  _configSub: Subscription;

  _c = new BehaviorSubject<RenderValue<ComponentDisplayConfig>>({ value: undefined });
  c$ = this._c.asObservable();

  @Input() set config(config: DisplayConfig) {
    if (this._configSub) {
      return;
    }

    const componentDisplayConfig =
      config instanceof ComponentDisplayConfig
        ? config
        : new ComponentDisplayConfig(config);

    this._c.next({ value: componentDisplayConfig });

    this._configSub = this.c$
      .pipe(
        untilDestroyed(this),
        switchMap((c) => c.value.config$),
        tap((c) => {
          this.stateSvc.updateCurrentUserSettings(this.getConfigObject(c));
        })
      )
      .subscribe();
  }

  @Input() icon?: IconProp;
  @Input() size?: SizeProp = 'lg';
  @Input() class?: string;
  @Input() name?: string;

  constructor(
    private stateSvc: StateService,
    private introSvc: IntroService
  ) {}

  hideControl(): void {
    this.c$
      .pipe(
        take(1),
        tap((c) => c.value.hideControl())
      )
      .subscribe();
    this.introSvc.show('widget-removed');
  }

  toggle(fieldName: string): void {
    this.c$
      .pipe(
        take(1),
        tap((c) => c.value.toggle(fieldName))
      )
      .subscribe();
  }

  private getConfigObject(config: ComponentDisplayConfig): unknown {
    const root = {
      consoleUI: {}
    };

    if (!config.id) {
      return root;
    }

    const split = config.id.split('.');

    let r: unknown = root.consoleUI;

    for (let i = 0; i < split.length; i++) {
      const newRoot: unknown = i === split.length - 1 ? config.result() : {};

      r[split[i]] = newRoot;

      if (i === split.length - 1) {
        newRoot;
        break;
      }

      r = newRoot;
    }

    return root;
  }
}
