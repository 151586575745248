import { SystemSummary } from 'src/app/shared/models/system-summary.model';
import { GoogleMapLocation } from './dashboard.classes';

export function updateSystem(
  ipLocation: GoogleMapLocation,
  summary: SystemSummary
): void {
  if (summary.ipLocation) {
    if (ipLocation) {
      if (
        ipLocation.lat !== summary.ipLocation.lat ||
        ipLocation.lon !== summary.ipLocation.lon
      ) {
        ipLocation.latLng = new google.maps.LatLng(
          summary.ipLocation.lat,
          summary.ipLocation.lon
        );
      }
      ipLocation.properties.set('External IP', summary.ipLocation.query);
      ipLocation.properties.set('ISP', summary.ipLocation.isp);
      ipLocation.properties.set('City', summary.ipLocation.city);
      ipLocation.properties.set(
        'Country',
        `${summary.ipLocation.regionName}, ${summary.ipLocation.country}`
      );
      return;
    }

    ipLocation = {
      lat: summary.ipLocation.lat,
      lon: summary.ipLocation.lon,
      latLng: new google.maps.LatLng(summary.ipLocation.lat, summary.ipLocation.lon),
      properties: new Map([
        ['External IP', summary.ipLocation.query],
        ['ISP', summary.ipLocation.isp],
        ['City', summary.ipLocation.city],
        ['Country', summary.ipLocation.country]
      ])
    };
  }
}
