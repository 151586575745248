<mon-spinner [spin]="spin" [overlay]="true">
  <div class="flex flex-col w-full h-full" content>
    <h4>Agent Logging</h4>
    <div class="flex-1 flex">
      <div class="pl-2 flex-1 flex flex-col">
        <div class="grid grid-cols-12 auto-cols-max gap-x-2 max-h-32 overflow-auto">
          <div class="col-span-4 text-secondary" [style.font-variant]="'small-caps'">Timestamp</div>
          <div class="col-span-7 text-secondary" [style.font-variant]="'small-caps'">Name</div>
          <div class="col-span-1 text-secondary" [style.font-variant]="'small-caps'"></div>

          <ng-container *ngFor="let log of recent$ | async; trackBy: trackByID">
            <div class="col-span-4 flex items-center truncate text-xs">{{ log.time | date: 'MM/dd/yyyy hh:mm:ss' }}</div>
            <div class="col-span-7 flex items-center text-xs">
              <div class="truncate">{{ log.name | slice: 19 }}</div>
            </div>
            <div class="col-span-1 flex items-center truncate text-xs">
              <button
                (click)="downloadLog(log)"
                class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
                matTooltip="Download"
                mat-icon-button
              >
                <fa-icon [icon]="['fad', 'download']" size="sm" class="text-primary"></fa-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center justify-around mt-2">
      <button *ngLet="agent$ | async as agent" (click)="packageLogs(agent.id)" mat-raised-button color="primary"> Archive Now </button>
      <button *ngLet="agent$ | async as agent" (click)="setDebugMode(agent.id)" mat-raised-button color="primary"> Set Debug Mode </button>
    </div>
  </div>
</mon-spinner>
