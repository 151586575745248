import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { tap, timer } from 'rxjs';
import { catchError, filter, repeat, switchMap, take } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { StateService } from 'src/app/core/services/state.service';
import {
  SubscribeService,
  SubscriptionLicenseResult
} from 'src/app/core/services/subscribe.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { UNDEFINED } from 'src/app/utils/rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { PaymentSource } from 'src/app/shared/models/subscription.model';
import { SpinnerComponent } from '../../dialogs/spinner/spinner.component';
import {
  PaymentSourceComponent,
  SubscriptionPaymentSourceParams
} from '../payment-source/payment-source.component';
import { TenantService } from 'src/app/core/services/tenant.service';

export interface ReactivateData {
  onCancel: () => void;
  onConfirm: (s: SubscriptionLicenseResult) => void;
}

@Component({
  standalone: true,
  selector: 'mon-reactivate',
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule,
    SpinnerComponent
  ],
  templateUrl: './reactivate.component.html',
  styleUrls: ['./reactivate.component.scss'],
  animations: [fadeInRight80ms]
})
export class ReactivateComponent {
  spin = false;

  tenant$ = this.stateSvc.tenant$;

  subscription$ = this.subscribeSvc.getSubscription();

  paymentSource$ = this.subscribeSvc.getPaymentSource().pipe(catchError(() => UNDEFINED));

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ReactivateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReactivateData,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private stateSvc: StateService,
    private tenantSvc: TenantService,
    private subscribeSvc: SubscribeService
  ) {}

  confirm(): void {
    const reactivate = () => {
      this.spin = true;

      return this.subscribeSvc
        .reactivateSubscription()
        .pipe(
          switchMap(() =>
            this.tenantSvc.get().pipe(
              repeat({
                delay: (count) => timer(count * 1000)
              }),
              filter((t) => !t.license.expired),
              take(1)
            )
          ),
          tap(() => window.location.reload()),
          catchError((err) => {
            const errMsg = parseError(err, 'failed to submit billing information');
            this.snackBar.open(errMsg.toUpperCase(), 'CLOSE', {
              duration: 10000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });

            this.spin = false;

            return UNDEFINED;
          })
        )
        .subscribe();
    };

    this.paymentSource$
      .pipe(
        tap((ps) => {
          if (ps && ps.card) {
            reactivate();
          } else if (ps && ps.status !== 'pending_verification' && ps.bankAccount) {
            reactivate();
          } else {
            this.spin = false;

            this.dialog.open(PaymentSourceComponent, {
              disableClose: true,
              restoreFocus: false,
              panelClass: 'post-subscription',
              data: new SubscriptionPaymentSourceParams({
                paymentSource: ps,
                onPaymentSourceUpdated: (_result: PaymentSource) => {
                  reactivate();
                }
              }),
              width: '800px'
            });
          }
        })
      )
      .subscribe();
  }

  cancel(): void {
    if (this.data?.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
