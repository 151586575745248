import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ActionService } from 'src/app/core/services/action.service';
import { Action } from 'src/app/shared/models/action.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { deepCopy } from 'src/app/utils/clone';

import { mockActions } from './mock.data';

export class ActionsDataSource implements DataSource<Action> {
  private _actions = new BehaviorSubject<Action[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._actions.value ? this._actions.value.length : -1;
  }
  public get data(): Action[] {
    return this._actions.value?.slice() || [];
  }

  constructor(private actionSvc: ActionService) {}

  loadActions(limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    // this.actionSvc
    //   .getAll(limit, page, orderBy)
    //   .pipe(
    //     catchError(() =>CONTINUE_UNDEFINED),
    //     finalize(() => this._loading.next(false))
    //   )
    of(deepCopy(mockActions))
      .pipe(
        map((actions) => {
          return new PagedResult<Action>(Action, {
            count: actions.length,
            items:
              orderBy[0].dir === 0
                ? actions
                : actions.sort(
                    (a, b) =>
                      (orderBy[0].dir > 1 ? -1 : 1) *
                      (a[orderBy[0].path] < b[orderBy[0].path]
                        ? -1
                        : a[orderBy[0].path] > b[orderBy[0].path]
                        ? 1
                        : 0)
                  ),
            page: page,
            limit: limit
          });
        }),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (results: PagedResult<Action>) => {
          if (results) {
            this.total = results.count;
            this._actions.next(results.items || []);
          }
        }
      });
  }

  connect(): Observable<Action[]> {
    return this._actions.asObservable();
  }

  disconnect(): void {
    this._actions.complete();
    this._loading.complete();
  }
}
