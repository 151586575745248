<ng-container *ngIf="channelListener$ | async"></ng-container>
<ng-container *ngIf="agent$ | async"></ng-container>

<mon-spinner [spin]="spin" [overlay]="true">
  <div content #container>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)"></mon-result-overlay>

    <div class="flex">
      <h2 class="border-b pb-1 flex-auto">
        <fa-icon class="text-green" [icon]="['fad', 'bars-progress']"></fa-icon>
        Partition Layout
      </h2>
    </div>

    <div class="flex flex-col gap-8 py-4">
      <div class="flex items-center justifty-center">
        <h2 class="headline m-0 flex-auto">{{ diskName }}</h2>
        <div class="subheading-1">{{ diskSize }}</div>
      </div>

      <div class="flex-1 flex justify-center gap-2">
        <div *ngFor="let tile of tiles" class="bg-blue-200 dark:bg-blue rounded p-2 h-auto whitespace-pre">{{ tile.text }}</div>
      </div>
    </div>
    <div mat-dialog-actions class="float-right pt-4">
      <button mat-button (click)="this.dialogRef.close()" cdkFocusInitial>Close</button>
    </div>
  </div>
</mon-spinner>
