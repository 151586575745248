import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { of, switchMap } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { QueryService } from 'src/app/core/services/query.service';
import { getCommandLineExeRegex } from 'src/app/core/utils/regex';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import { Process } from 'src/app/shared/models/process.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

interface ProcessParams {
  process: Process;
}

@Component({
  selector: 'mon-process-info',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, SpinnerComponent],
  templateUrl: './process-info.component.html',
  styleUrls: ['./process-info.component.scss']
})
export class ProcessInfoComponent implements OnInit {
  spin: boolean;
  error: boolean;
  processRegex = getCommandLineExeRegex();

  processInfo$ = of(
    (() => {
      let p = this.params.process.nameD;
      if (this.params.process.commandLine) {
        const g = this.params.process.commandLine.match(this.processRegex);
        if (g && g.length > 0) {
          p = g[0];
        }
      }

      this.params.process.services?.forEach((s) => (p += `${p}, ${s.displayName}`));

      return p;
    })()
  ).pipe(
    switchMap((p) =>
      this.querySvc.getProcessInfo(p).pipe(
        finalize(() => (this.spin = false)),
        catchError((err) => {
          console.error(err);
          this.error = true;

          return UNDEFINED;
        })
      )
    )
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) protected params: ProcessParams,
    private querySvc: QueryService
  ) {}

  ngOnInit(): void {
    this.spin = true;
  }
}
