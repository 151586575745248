import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCloudDownload, faCloudUpload } from '@fortawesome/pro-duotone-svg-icons';
import { faStar, faStarHalfAlt } from '@fortawesome/pro-solid-svg-icons';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule } from 'ngx-markdown';
import { CheckboxGroupModule } from 'src/app/shared/components/checkbox-group/checkbox-group.module';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ScriptDetailComponent } from './script-detail/script-detail.component';
import { ScriptEditComponent } from './script-edit/script-edit.component';
import { ScriptFeedbackComponent } from './script-feedback/script-feedback.component';
import { ScriptInvokeComponent } from './script-invoke/script-invoke.component';
import { ScriptListItemComponent } from './script-list-item/script-list-item.component';
import { ResultListItemComponent } from './script-result/result-list-item/result-list-item.component';
import { ResultTargetDetailComponent } from './script-result/result-target-detail/result-target-detail.component';
import { ResultTargetItemComponent } from './script-result/result-target-item/result-target-item.component';
import { ScriptResultComponent } from './script-result/script-result.component';
import { ScriptsComponent } from './scripts.component';

@NgModule({
  declarations: [
    ScriptsComponent,
    ScriptEditComponent,
    ScriptFeedbackComponent,
    ScriptListItemComponent,
    ScriptResultComponent,
    ResultListItemComponent,
    ResultTargetItemComponent,
    ResultTargetDetailComponent,
    ScriptDetailComponent,
    ScriptInvokeComponent
  ],
  imports: [
    CommonModule,
    CheckboxGroupModule,
    FontAwesomeModule,
    InfiniteScrollModule,
    MarkdownModule.forChild(),
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRippleModule,
    MatTabsModule,
    MatTooltipModule,
    MatStepperModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule
  ],
  exports: [ScriptListItemComponent]
})
export class ScriptsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCloudDownload, faCloudUpload, faStar, faStarHalfAlt);
  }
}
