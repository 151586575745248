import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { Process } from 'src/app/shared/models/process.model';

export const getCPUColumns = (killFn: (p: Process) => void): TableColumn<Process>[] => [
  {
    label: 'Name',
    property: 'nameD',
    type: 'text',
    visible: true,
    hideable: true,
    cellClasses: ['font-medium', 'truncate', 'justify-end']
  },
  {
    label: 'PID',
    property: 'processID',
    type: 'number',
    visible: true,
    hideable: false,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: 'CPU',
    property: 'cpuPercentD',
    type: 'number',
    visible: true,
    hideable: true,
    cellClasses: ['text-right', 'justify-end', 'w-1/12']
  },
  {
    label: 'Actions',
    property: 'actions',
    type: 'button',
    buttonIconName: 'times',
    buttonFn: killFn,
    tooltip: 'Kill',
    visible: true,
    hideable: true
  }
];

export const getMemColumns = (killFn: (p: Process) => void): TableColumn<Process>[] => [
  {
    label: 'Name',
    property: 'nameD',
    type: 'text',
    visible: true,
    hideable: true,
    cellClasses: ['font-medium', 'truncate']
  },
  {
    label: 'PID',
    property: 'processID',
    type: 'number',
    visible: true,
    hideable: false,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: 'Memory',
    property: 'memorySizeD',
    type: 'number',
    visible: true,
    hideable: true,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: 'Actions',
    property: 'actions',
    type: 'button',
    buttonIconName: 'times',
    buttonFn: killFn,
    tooltip: 'Kill',
    visible: true,
    hideable: true
  }
];

export const getDiskColumns = (killFn: (p: Process) => void): TableColumn<Process>[] => [
  {
    label: 'Name',
    property: 'nameD',
    type: 'text',
    visible: true,
    hideable: true,
    cellClasses: ['font-medium', 'truncate']
  },
  {
    label: 'PID',
    property: 'processID',
    type: 'number',
    visible: true,
    hideable: false,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: '',
    property: 'disk',
    type: 'number',
    visible: false,
    hideable: false
  },
  {
    label: 'Disk',
    property: 'diskD',
    type: 'number',
    visible: true,
    hideable: true,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: 'Actions',
    property: 'actions',
    type: 'button',
    buttonIconName: 'times',
    buttonFn: killFn,
    tooltip: 'Kill',
    visible: true,
    hideable: true
  }
];

export const getNetColumns = (killFn: (p: Process) => void): TableColumn<Process>[] => [
  {
    label: 'Name',
    property: 'nameD',
    type: 'text',
    visible: true,
    hideable: true,
    cellClasses: ['font-medium', 'truncate']
  },
  {
    label: 'PID',
    property: 'processID',
    type: 'number',
    visible: true,
    hideable: false,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: '',
    property: 'network',
    type: 'number',
    visible: false,
    hideable: false
  },
  {
    label: 'Network',
    property: 'networkD',
    type: 'number',
    visible: true,
    hideable: true,
    cellClasses: ['text-right', 'justify-end']
  },
  {
    label: 'Actions',
    property: 'actions',
    type: 'button',
    buttonIconName: 'times',
    buttonFn: killFn,
    tooltip: 'Kill',
    visible: true,
    hideable: true
  }
];
