<ng-container *ngIf="settings$ | async"> </ng-container>
<div class="pt-2 flex flex-col md:flex-row md:items-start">
  <div class="flex-auto">
    <div @fadeInUp class="card relative overflow-x-auto overflow-y-hidden">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
          <span>Roles</span>
        </h2>

        <span class="flex-1"></span>

        <button (click)="modRole()" class="ml-4 flex-none" mat-icon-button matTooltip="Add Role" type="button">
          <fa-stack class="icon-size text-xs">
            <fa-icon [icon]="['fad', 'shield-alt']" transform="left-2" stackItemSize="2x"></fa-icon>
            <fa-icon
              [icon]="['fas', 'plus']"
              stackItemSize="1x"
              transform="shrink-3 down-10 right-14"
              class="item-color item-icon opacity-50"
            ></fa-icon>
          </fa-stack>
        </button>

        <mon-display-config-button
          matTooltip="Filter Columns"
          *ngIf="displayConfig$ | async as config"
          persistAt="User"
          [config]="config"
          [canHide]="false"
          [icon]="['fad', 'columns']"
          class="ml-2 flex items-center justify-center flex-none"
        ></mon-display-config-button>
      </div>

      <div *ngIf="dataSource.loading$ | async" class="absolute top-0 left-0 bottom-0 right-0 z-10">
        <div class="absolute w-full h-full bg-gray opacity-30"></div>
        <fa-icon
          [icon]="['fad', 'cog']"
          animation="spin"
          size="4x"
          class="flex w-full h-full items-center justify-center text-primary animate-pulse"
        ></fa-icon>
      </div>

      <table
        [dataSource]="dataSource"
        [@listStagger]="dataSource.length"
        mat-table
        matSort
        [matSortActive]="this.sortField"
        [matSortDirection]="this.sortDirection"
        class="w-full"
      >
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              [disabled]="column.sortable === false"
              [mat-sort-header]="column.display || column.property"
            >
              {{ column.label }}
            </th>
            <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
              {{ getValue(row, column.property) }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
            <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
              <ng-container [ngSwitch]="column.dateFormat">
                <ng-container *ngSwitchCase="'timeAgo'">
                  {{ row[column.property] | timeAgo }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ row[column.property] | date: column.dateFormat || 'medium' }}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <!-- Button Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
            <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
              <div class="flex float-right">
                <fa-icon [icon]="['fad', column.buttonIconName]" size="lg" class="text-gray" [matTooltip]="column.tooltip"> </fa-icon>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
          <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: visibleColumns"
            @fadeInRight
            (click)="modRole(row)"
            class="hover:bg-hover trans-ease-out cursor-pointer"
          ></tr>
        </ng-container>
      </table>

      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="dataSource.total"> </mat-paginator>
    </div>
  </div>
</div>

<blockquote class="text-primary my-2 whitespace-normal break-all">
  System roles <b>Owner</b>, <b>Administrator</b> and <b>User</b> are provided by default and cannot be removed
</blockquote>
