import { Component } from '@angular/core';
import { switchMap } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { AgentService } from 'src/app/core/services/agent.service';
import { StateService } from 'src/app/core/services/state.service';
import { OS } from 'src/app/shared/models/system.model';

@Component({
  selector: 'mon-agent-tools',
  templateUrl: './agent-tools.component.html',
  styleUrls: ['./agent-tools.component.scss'],
  animations: [fadeInUp400ms, stagger80ms]
})
export class AgentToolsComponent {
  OS = OS;

  live$ = this.stateSvc.liveMode$;

  agent$ = this.stateSvc.selectedAgent$.pipe(
    switchMap((selectedAgent) => this.agentSvc.getByID(selectedAgent.agentID))
  );

  constructor(protected stateSvc: StateService, protected agentSvc: AgentService) {}
}
