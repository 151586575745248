<div class="relative overflow-hidden">
  <div
    *ngIf="data.icon"
    [ngClass]="data.iconBgClasses || ['bg-primary-light dark:bg-primary-900 dark:bg-opacity-20']"
    class="absolute -left-4 -bottom-8 flex items-center justify-center w-24 h-24 rounded-full m-4 ml-0 text-primary"
  >
    <fa-icon [icon]="['fad', data.icon]" [fixedWidth]="true" [ngClass]="data.iconClasses" size="4x"> </fa-icon>
  </div>

  <div class="flex justify-start items-center content-center" mat-dialog-title>
    <h2 *ngIf="data.title" class="headline m-0 flex-auto">{{ data.title }}</h2>
  </div>

  <mat-divider></mat-divider>

  <mat-dialog-content class="py-4">
    <ng-template #viewContainerRef></ng-template>
    <div [innerHTML]="data.htmlDialogContent"></div>
    <p class="mx-4" *ngIf="!data.htmlDialogContent">{{ data.confirmationText }}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="flex items-center justify-between confirm-button-collection gap-2">
    <div class="flex items-center justify-start pl-20">
      <mat-checkbox
        *ngIf="data.confirmWithCheckbox"
        (change)="confirmed = !confirmed"
        (click)="$event.stopPropagation()"
        [checked]=""
        color="primary"
        >Check to confirm</mat-checkbox
      >
    </div>
    <div class="flex items-center justify-end">
      <button [style.display]="data.onCancel === undefined ? 'none' : 'block'" #cancelBtn mat-button (click)="cancel()">
        {{ data.cancelButtonText || 'No' }}
      </button>
      <button
        [disabled]="!confirmed"
        [style.display]="data.onConfirm === undefined ? 'none' : 'block'"
        #confirmBtn
        mat-button
        (click)="confirm()"
      >
        {{ data.confirmButtonText || 'Yes' }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
