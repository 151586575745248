import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { CheckboxGroupComponent } from './checkbox-group.component';
import { CheckboxComponent } from './checkbox/checkbox.component';

@NgModule({
  declarations: [CheckboxGroupComponent, CheckboxComponent],
  imports: [CommonModule, MatCheckboxModule],
  exports: [CheckboxGroupComponent, CheckboxComponent]
})
export class CheckboxGroupModule {}
