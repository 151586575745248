<div [ngClass]="hasHighlight() ? 'bg-red-500 bg-opacity-40' : ''" class="card relative h-full trans-shadow transition-colors duration-500">
  <div class="flex flex-col content-between">
    <div class="border-b mb-2 flex-1">
      <div class="flex">
        <div
          (click)="headerSelected()"
          [ngClass]="['text-' + options.iconColor]"
          class="rounded-full w-12 h-12 flex items-center justify-center transition-colors duration-1000"
        >
          <fa-icon [icon]="['fad', options.icon]" size="2x"></fa-icon>
        </div>

        <div class="flex-1 flex items-center truncate">
          <div class="flex-1 flex flex-col justify-center truncate">
            <h2 tipOnEllip class="title m-0 leading-4 truncate">{{ options.title }}</h2>
            <p tipOnEllip class="uppercase caption text-secondary m-0 font-bold truncate">{{ options.label }}</p>
          </div>
          <ng-content select="[buttons]"></ng-content>
        </div>

        <div class="pt-2 pr-2">
          <mon-display-config-button
            *ngIf="_displayConfig"
            persistAt="User"
            [config]="_displayConfig"
            class="flex items-center justify-center text-sm w-8 h-8"
          ></mon-display-config-button>
        </div>
      </div>
    </div>
    <div class="flex-1 px-2">
      <div class="flex flex-row" style="min-height: 100px">
        <ng-container *ngLet="options.focusProperties as focusProperties">
          <div *ngIf="focusProperties.length > 0" class="flex-1">
            <div class="grid grid-flow-row gap-0 focus-properties" [ngClass]="focusProperties.length > 2 ? 'grid-cols-2' : 'grid-cols-1'">
              <ng-container *ngFor="let property of focusProperties; index as i; track">
                <div
                  [hidden]="!_displayConfig.fieldValue(property.label)"
                  class="flex flex-col border-l-2 pl-1 border-opacity-50"
                  [ngClass]="[
                    i >= focusProperties.length - 1 && i % 2 == 0 ? 'col-span-2' : '',
                    'border-' + (property.graphColor ? property.graphColor : 'transparent'),
                    property.graphLineStyle === 'dashed' ? 'border-dashed' : ''
                  ]"
                >
                  <div [ngClass]="property.highlight ? 'text-red' : 'text-secondary'" class="caption m-0 truncate">
                    {{ property.label }}
                  </div>
                  <div tipOnEllip [ngClass]="property.highlight ? 'text-red' : ''" class="flex-1 text-sm m-0 truncate">
                    {{ property.value }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <div *ngIf="options.properties" class="pl-2">
          <div class="flex flex-col">
            <ng-container *ngFor="let property of objectKeys(options.properties)">
              <div [hidden]="!_displayConfig.fieldValue(property)" class="flex-1 last:mb-2">
                <div class="flex flex-row">
                  <div class="flex-1 caption text-secondary m-0">{{ property }}</div>
                  <div class="shrink-0 caption m-0 pl-1 truncate">{{ options.properties[property] }}</div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div
        [ngClass]="_displayConfig.fieldValue('Graph') ? '' : 'hidden'"
        class="opacity-40 hover:opacity-100 absolute block w-full bottom-0 left-0"
      >
        <apx-chart
          [tooltip]="apexOptions.tooltip"
          [grid]="apexOptions.grid"
          [colors]="apexOptions.colors"
          [xaxis]="apexOptions.xaxis"
          [yaxis]="apexOptions.yaxis"
          [stroke]="apexOptions.stroke"
          [chart]="apexOptions.chart"
          [series]="[]"
          #chartObj
        >
        </apx-chart>
      </div>
    </div>
  </div>
</div>
