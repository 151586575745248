import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { finalize } from 'rxjs';
import { ContactService } from 'src/app/core/services/contact.service';
import { StateService } from 'src/app/core/services/state.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { getEmailRegex } from 'src/app/core/utils/regex';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { Contact } from 'src/app/shared/models/contact.model';
import { SharedModule } from 'src/app/shared/shared.module';

export class ContactEditParams {
  contact: Contact;
  onSuccess: (contact: Contact) => void;
  constructor(init?: Partial<ContactEditParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-contact-edit',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    SpinnerComponent,
    ReactiveFormsModule,
    ResultOverlayComponent
  ],
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {
  permission = Permission;
  overlayResult = OverlayResult.Unset;
  spin = false;

  user$ = this.stateSvc.currentUser$;

  detailGroup = this.fb.group({
    firstName: ['', [Validators.maxLength(150)]],
    lastName: ['', [Validators.maxLength(150)]],
    email: [
      '',
      [Validators.maxLength(70), Validators.required, Validators.pattern(getEmailRegex())]
    ],
    sendBillingEmail: [true],
    sendAccountEmail: [true]
  });

  @ViewChild('firstName') firstNameInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: ContactEditParams,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactEditParams>,
    private snackBar: MatSnackBar,
    private stateSvc: StateService,
    private contactSvc: ContactService
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.firstNameInput.nativeElement.focus(), 400);

    if (this.params.contact) {
      this.detailGroup.patchValue(this.params.contact);
    }
  }

  save(): void {
    this.spin = true;

    if (this.params.contact) {
      this.contactSvc
        .update(
          this.params.contact.id,
          this.detailGroup.controls.firstName.value,
          this.detailGroup.controls.lastName.value,
          this.detailGroup.controls.email.value,
          this.detailGroup.controls.sendBillingEmail.value,
          this.detailGroup.controls.sendAccountEmail.value
        )
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: () => (this.overlayResult = OverlayResult.Success),
          error: (err: HttpErrorResponse) => {
            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.overlayResult = OverlayResult.Error;
          }
        });
    } else {
      this.contactSvc
        .create(
          this.detailGroup.controls.firstName.value,
          this.detailGroup.controls.lastName.value,
          this.detailGroup.controls.email.value,
          this.detailGroup.controls.sendBillingEmail.value,
          this.detailGroup.controls.sendAccountEmail.value
        )
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: () => (this.overlayResult = OverlayResult.Success),
          error: (err: HttpErrorResponse) => {
            this.snackBar.open(parseError(err), 'CLOSE', {
              duration: 3000,
              panelClass: ['error-snack'],
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.overlayResult = OverlayResult.Error;
          }
        });
    }
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.(
        new Contact({
          id: this.params.contact?.id,
          firstName: this.detailGroup.controls.firstName.value,
          lastName: this.detailGroup.controls.lastName.value,
          email: this.detailGroup.controls.email.value,
          sendBillingEmail: this.detailGroup.controls.sendBillingEmail.value,
          sendAccountEmail: this.detailGroup.controls.sendAccountEmail.value
        })
      );
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
