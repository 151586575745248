import { Component, Input } from '@angular/core';
import { TriggerCategory } from 'src/app/shared/enums/trigger-category.enum';
import { HistoryHourDetail } from 'src/app/shared/models/history.model';

@Component({
  selector: 'mon-incident-timline',
  templateUrl: './incident-timline.component.html',
  styleUrls: ['./incident-timline.component.scss']
})
export class IncidentTimlineComponent {
  TriggerCategory: typeof TriggerCategory = TriggerCategory;

  @Input() detail: HistoryHourDetail;

  hasIncident(t: TriggerCategory, c: Date): boolean {
    let found: boolean;

    if (this.detail.alarms && this.detail.alarms.has(t)) {
      this.detail.alarms.get(t).forEach((v) => {
        found = !!v.find((x) => x.getTime() === c.getTime());
        if (found) {
          return;
        }
      });
    }

    return found;
  }
}
