<div class="flex">
  <h2 class="border-b pb-1 flex-auto" mat-dialog-title>
    <fa-layers [fixedWidth]="true" class="text-primary">
      <fa-icon [icon]="['fad', 'square-full']"></fa-icon>
      <fa-layers-text content="%" style="color: white" transform="shrink-6"> </fa-layers-text>
    </fa-layers>
    Environment Variables
  </h2>
</div>

<div class="py-4" class="envar-content" mat-dialog-content>
  <ng-container *ngIf="envars">
    <table class="">
      <tr *ngFor="let envar of envars | keyvalue">
        <td>{{ envar.key }}</td>
        <td>{{ envar.value }}</td>
      </tr>
    </table>
  </ng-container>
</div>

<div mat-dialog-actions class="float-right pt-4">
  <button mat-button (click)="this.dialogRef.close()" cdkFocusInitial>Close</button>
</div>
