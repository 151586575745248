import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { environment } from 'src/environments/environment';

import { DevHideDirective } from './shared/directives/dev-hide.directive';
import { Permission } from './shared/enums/permission.enum';

export const navItems: NavigationItem[] = [
  {
    type: 'link',
    label: 'Dashboard',
    route: '/home/dashboard',
    icon: 'tachometer-alt-fast',
    requiredPerms: [
      Permission.MachineSystem_Read,
      Permission.MachineGeo_Read,
      Permission.MachineSession_List,
      Permission.MachineProcess_Read,
      Permission.MachineCPU_Read,
      Permission.MachineMemory_Read,
      Permission.MachineDisk_Read,
      Permission.MachineNetwork_Read,
      Permission.MachineGPU_Read
    ]
  },
  {
    type: 'dropdown',
    label: 'Task Manager',
    icon: 'desktop',
    children: [
      {
        type: 'link',
        label: 'Processes',
        route: '/home/task-manager/processes',
        requiredPerms: [Permission.MachineProcess_List]
      },
      {
        type: 'link',
        label: 'Services',
        route: '/home/task-manager/services',
        requiredPerms: [Permission.MachineService_List]
      },
      {
        type: 'link',
        label: 'Performance',
        route: '/home/task-manager/performance'
      } /*
      {
        type: 'link',
        label: 'Startup',
        route: '/home/task-manager/startup',
        requiredPerms: [Permission.MachineStartupApplications_List]
      }*/
    ]
  },
  {
    type: 'link',
    label: 'Tasks',
    route: '/home/tasks',
    icon: 'tasks',
    requiredPerms: [Permission.Task_List]
  },
  {
    type: 'link',
    label: 'Diagnostics',
    route: '/home/diagnostics/agent-tools',
    icon: 'toolbox',
    requiredPerms: [
      Permission.MachineDiagnosticSpeedtest_Read,
      Permission.MachineDiagnosticPing_Read,
      Permission.MachineDiagnosticAgentLogs_Download,
      Permission.MachineDiagnosticGroupPolicyReport_Download
    ]
  },
  {
    type: 'subheading',
    label: 'Inventory',
    children: [
      {
        type: 'link',
        label: 'Machines',
        route: '/home/inventory/machines',
        icon: 'computer-classic',
        requiredPerms: [Permission.Agent_List]
      },
      {
        type: 'link',
        label: 'Blocked Machines',
        route: '/home/inventory/blocked',
        icon: 'ban',
        requiredPerms: [Permission.MachineBlock_List]
      },
      {
        type: 'link',
        label: 'User Sessions',
        route: '/home/inventory/user-sessions',
        icon: 'users-rectangle',
        requiredPerms: [Permission.Agent_List]
      }
    ]
  },
  {
    type: 'subheading',
    label: 'Tools',
    children: [
      {
        type: 'dropdown',
        label: 'Scripts',
        icon: 'scroll',
        children: [
          {
            type: 'link',
            label: 'Store',
            route: '/home/tools/scripts/store',
            requiredPerms: [Permission.Script_List]
          },
          {
            type: 'link',
            label: 'Results',
            route: '/home/tools/scripts/results',
            requiredPerms: [Permission.Script_List]
          }
        ]
      },
      // {
      //   type: 'link',
      //   label: 'Scripts',
      //   route: '/home/tools/scripts',
      //   icon: 'scroll',
      //   requiredPerms: [Permission.Script_List]
      // },
      // {
      //   type: 'link',
      //   label: 'Actions',
      //   route: '/home/tools/actions',
      //   icon: 'triangle',
      //   requiredPerms: [Permission.Action_List],
      //   hidden: DevHideDirective.devHide(['beta', 'stage'])
      // },
      {
        type: 'link',
        label: 'Triggers',
        route: '/home/tools/triggers',
        icon: 'shapes',
        requiredPerms: [Permission.Trigger_List],
        hidden: DevHideDirective.devHide(['beta'])
      },
      // {
      //   type: 'link',
      //   label: 'Alarms',
      //   route: '/home/tools/alarms',
      //   icon: 'alarm-exclamation',
      //   requiredPerms: [Permission.Alarm_List],
      //   hidden: DevHideDirective.devHide(['beta', 'stage'])
      // },
      {
        type: 'func',
        label: 'Shell',
        func: (): void => {
          window.open(
            `shell`,
            '_blank',
            'toolbar=no, menubar=no, titlebar=no, height=625, width=1200'
          );
        },
        icon: 'terminal',
        iconStack: true,
        requiredPerms: [Permission.Shell_Invoke]
      },
      {
        type: 'link',
        label: 'Debug',
        route: '/home/diagnostics/debug',
        icon: 'bug',
        hidden: !environment.debug
      }
    ]
  },
  {
    type: 'subheading',
    introID: 'nav-settings-panel',
    label: 'Settings',
    children: [
      {
        type: 'link',
        label: 'Tenant',
        route: '/home/settings/tenant',
        icon: 'building'
      },
      {
        type: 'link',
        label: 'Users',
        route: '/home/settings/users',
        icon: 'users',
        requiredPerms: [Permission.User_List]
      },
      {
        type: 'link',
        label: 'Roles',
        route: '/home/settings/roles',
        icon: 'shield-alt',
        requiredPerms: [Permission.Role_List]
      },
      {
        type: 'link',
        label: 'Site',
        route: '/home/settings/site',
        icon: 'globe',
        requiredPerms: [Permission.SiteAPIKey_List]
      }
    ]
  },
  {
    type: 'subheading',
    label: 'Miscellaneous',
    children: [
      {
        type: 'link',
        label: 'Install Agent',
        route: '/home/misc/download-agent',
        icon: 'cloud-download'
      },
      {
        type: 'external',
        label: 'Online Help',
        icon: 'book',
        href: 'https://www.liquidware.com/commandctrl-documentation',
        target: '_documentation'
      },
      {
        type: 'external',
        label: 'Community',
        icon: 'bookmark',
        href: 'https://www.liquidware.com/community',
        target: '_community'
      },
      {
        type: 'external',
        label: 'Request Feature',
        icon: 'envelope',
        href: 'https://www.liquidware.com/FeatureRequests',
        target: '_community'
      },
      {
        type: 'external',
        label: 'Support',
        icon: 'headset',
        href: 'https://www.liquidware.com/support',
        target: '_support'
      }
    ]
  }
];
