import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { TenantService } from '../services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementGuard  {
  static AllAgreed = false;

  constructor(private router: Router, private tenantSvc: TenantService) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (AgreementGuard.AllAgreed) {
      return true;
    }

    return this.tenantSvc.getOpenAgreements().pipe(
      retry({
        delay: (_error, _retryCount) => timer(1000)
      }),
      map((agreements) => {
        if (agreements.length > 0) {
          const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
            state: {
              agreements
            }
          };

          this.router.navigate(['agreement'], navigationExtras);
          return false;
        } else {
          AgreementGuard.AllAgreed = true;
          return true;
        }
      })
    );
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate();
  }
}
