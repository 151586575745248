import { Config } from 'src/@vex/services/config.service';
import { defaultConfig } from 'src/@vex/utils/default-config';
import { deepCopy } from 'src/app/utils/clone';

import { Updatable } from '../interfaces/updatable.interface';

interface Geo {
  city: string;
  region: string;
  country: string;
}

interface LastLogin {
  ip: string;
  timestamp: Date;
  geo: Geo;
}

export class ContextUser implements Updatable<ContextUser> {
  public id: string;
  public email: string;
  public userID: string;
  public ownedTenantIDs: string[];
  public userOfTenantIDs: string[];
  public tags: Map<string, unknown>;
  public roles: Map<string, unknown>;
  public perms: Map<string, unknown>;
  public settings: ContextUserSettings = new ContextUserSettings();
  public updated: Date;
  public added: Date;
  public timestamp: Date;
  public created: Date;
  public imageLink: string;
  public lastLogin: LastLogin;

  get geoD(): string {
    if (this.lastLogin.geo) {
      return `${this.lastLogin.geo.city}, ${this.lastLogin.geo.region} ${this.lastLogin.geo.country}`;
    }
    return '';
  }

  get rolesD(): string {
    return Array.from(this.roles.keys()).join(', ');
  }

  get createdD(): string {
    return this.created.toLocaleDateString();
  }

  get canSwitchTenant(): boolean {
    return this.userOfTenantIDs.length > 1;
  }

  constructor(init?: Partial<ContextUser>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.ownedTenantIDs = this.ownedTenantIDs || [];
    this.userOfTenantIDs = this.userOfTenantIDs || [];
    this.updated = new Date(init.updated);
    this.added = new Date(init.added);
    this.timestamp = new Date(init.timestamp);
    this.created = new Date(init.created);
    this.lastLogin.timestamp = new Date(init.lastLogin.timestamp);

    const tags = new Map<string, string>();
    if (this.tags) {
      Object.keys(this.tags).map((k) => {
        tags.set(k, this.tags[k]);
      });
      this.tags = tags;
      this.settings = new ContextUserSettings(this.tags.get('console-ui-settings'));
    }

    const roles = new Map<string, unknown>();
    if (this.roles) {
      Object.keys(this.roles).map((k) => {
        roles.set(k, this.roles[k]);
      });
      this.roles = roles;
    }

    const perms = new Map<string, unknown>();
    if (this.perms) {
      Object.keys(this.perms).map((k) => {
        perms.set(k, this.perms[k]);
      });
      this.perms = perms;
    }
  }

  hasPerms(perms: string[], has: 'any' | 'all' = 'all'): boolean {
    switch (has) {
      case 'any':
        return !!perms.find((p) => this.perms.has(p));
      case 'all':
        return perms.filter((p) => this.perms.has(p)).length === perms.length;
    }
  }

  update(input: Partial<ContextUser>): this {
    return Object.assign(this, input);
  }
}

export class ContextUserSettings {
  selectedTenantID?: string;
  consoleUI?: ConsoleUI = new ConsoleUI();

  constructor(init?: Partial<ContextUserSettings>) {
    Object.assign(this, init);

    if (init) {
      this.consoleUI = new ConsoleUI(init.consoleUI);
    }
  }
}

export class ConsoleUI {
  config?: Config = deepCopy(defaultConfig);
  global? = {};
  table? = {};
  widget? = {};
  intro?: string[] = [];
  recentMachines? = new Map<string, Array<string>>();
  recentPages? = new Array<string>();

  constructor(init?: Partial<ConsoleUI>) {
    Object.assign(this, init);

    if (init) {
      if (!init.config) {
        this.config = deepCopy(defaultConfig);
      }

      if (!init.global) {
        this.global = {};
      }

      this.recentMachines = new Map<string, Array<string>>();
      if (init.recentMachines instanceof Map) {
        this.recentMachines = init.recentMachines;
      } else if (init.recentMachines) {
        Object.keys(init.recentMachines)
          .filter((key) => key.length === 10)
          .forEach((key) => this.recentMachines.set(key, init.recentMachines[key]));
      }

      if (!init.recentPages) {
        this.recentPages = [];
      }

      if (!init.table) {
        this.table = {};
      }

      if (!init.intro) {
        this.intro = [];
      }
    }
  }

  getGlobalValue<T>(key: string): T {
    const v = this.global[key];
    if (v) {
      return v as T;
    } else {
      return v;
    }
  }

  setGlobalValue(key: string, value: unknown): void {
    this.global[key] = value;
  }

  getSettingByID<T>(id: string): T {
    const split = id.split('.');

    if (split.length > 0) {
      let value = this.valueOf();

      for (let i = 0; i < split.length; i++) {
        value = value[split[i]];
      }

      return value as T;
    }

    return undefined;
  }
}
