import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TaskService } from 'src/app/core/services/task.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Task } from 'src/app/shared/models/task.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

export class TasksGlobalDataSource implements DataSource<Task> {
  private _tasks = new BehaviorSubject<Task[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(true);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._tasks.value ? this._tasks.value.length : -1;
  }
  public get data(): Task[] {
    return this._tasks.value?.slice() || [];
  }

  constructor(private taskSvc: TaskService) {}

  loadTasks(limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    this.taskSvc
      .getAll(limit, page, orderBy)
      .pipe(
        catchError(() => UNDEFINED),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (results: PagedResult<Task>) => {
          if (results) {
            this.total = results.count;
            this._tasks.next(results.items || []);
          }
        }
      });
  }

  connect(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  disconnect(): void {
    this._tasks.complete();
    this._loading.complete();
  }
}
