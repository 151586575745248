import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { CanvasAddon } from '@xterm/addon-canvas';
import { FitAddon } from '@xterm/addon-fit';
import { SearchAddon } from '@xterm/addon-search';
import { WebLinksAddon } from '@xterm/addon-web-links';
import { IDisposable, Terminal } from '@xterm/xterm';
import Centrifuge from 'centrifuge';
import {
  BehaviorSubject,
  interval,
  Observable,
  of,
  Subscription,
  throwError
} from 'rxjs';
import { catchError, filter, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { PacketStart } from 'src/app/shared/interfaces/packet.interface';
import { Agent } from 'src/app/shared/models/agent.model';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { ShellTicket } from 'src/app/shared/models/shell-ticket.model';
import { OS } from 'src/app/shared/models/system.model';
import { UNDEFINED } from 'src/app/utils/rxjs';
import { parseError } from '../utils/http-reponse-error';
import { ApiService, TagResponse } from './api.service';
import { PubSubWsService } from './pubsubws.service';
import { StateService } from './state.service';
import { TaskService } from './task.service';

interface OpenShellResponse {
  taskID: string;
  ticketID: string;
}

export type ShellState = 'disconnected' | 'connected' | 'connecting' | 'closed' | 'error';

export enum ShellType {
  Powershell = 'powershell',
  Cmd = 'cmd',
  Sh = 'sh',
  Bash = 'bash',
  Zsh = 'zsh'
}

export class Shell {
  private _state = new BehaviorSubject<ShellState>('disconnected');
  state$ = this._state.asObservable();

  private _agent = new BehaviorSubject<Agent>(undefined);
  agent$ = this._agent.asObservable();

  shellType: ShellType;
  id: number;

  terminal: Terminal;
  fitAddon = new FitAddon();
  webLinksAddon = new WebLinksAddon();
  searchAddon = new SearchAddon();
  canvasAddon = new CanvasAddon();
  lastShellError: string;

  disposables: IDisposable[] = [];

  startTime: Date;
  sessionTime: number;

  sessionID: string;
  sub: Subscription;

  constructor(
    private shellSvc: ShellService,
    private stateSvc: StateService,
    private pubSubSvc: PubSubWsService,
    private taskSvc: TaskService
  ) {
    this.id = new Date().getTime();
  }

  setShellElement(e: ElementRef): void {
    this.terminal = new Terminal({
      // logLevel: 'debug',
      cursorBlink: true,
      // fontFamily: 'consolas',
      screenReaderMode: true,
      cols: 128,
      // windowsMode: true,
      theme: {
        selectionBackground: '#e91e63'
      }
    });
    this.terminal.open(e.nativeElement);
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.loadAddon(this.webLinksAddon);
    this.terminal.loadAddon(this.searchAddon);
    this.terminal.loadAddon(this.canvasAddon);
  }

  connect(agent: Agent): void {
    this._agent.next(agent);
    this.shellType = this.getShellByOS(agent.system.os);
    this.startTime = null;
    this.sessionTime = null;

    this.state$ // for session time
      .pipe(
        filter((s) => s === 'connected'),
        take(1),
        tap({
          next: () => {
            this.terminal.clear();
            this.startTime = new Date();
            this.sessionTime = new Date().getTime() - this.startTime.getTime();
            const poll = interval(1000)
              .pipe(
                switchMap(() =>
                  this.state$.pipe(
                    take(1),
                    tap((state) => {
                      if (state === 'connected' && this.startTime) {
                        this.sessionTime =
                          new Date().getTime() - this.startTime.getTime();
                      } else {
                        poll.unsubscribe();
                      }
                    })
                  )
                )
              )
              .subscribe();

            setTimeout(() => this.terminal.focus(), 200);
          }
        })
      )
      .subscribe();

    this._state.next('connecting');

    const openSession$ = this.shellSvc.requestShellConnection(agent.id, this.shellType);

    const startListening$ = openSession$.pipe(
      tap((resp) => (this.sessionID = resp.tag.ticketID)),
      switchMap((resp) => {
        const taskID = resp.tag.taskID;
        let taskUpdateUnixNano = 0;

        return this.taskSvc.getTask(resp.tag.taskID).pipe(
          tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
          switchMap(() =>
            UNDEFINED.pipe(
              map(() => taskUpdateUnixNano),
              switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
              tap((task) => {
                if (!task) {
                  throw 'still waiting...';
                } else if (!task.isDone) {
                  taskUpdateUnixNano = task.lastUpdateUnixNano;
                  throw 'still waiting...';
                }
              })
            )
          ),
          retry({
            delay: (_error, _retryCount) => of(true)
          })
        );
      }),
      tap((t) => {
        // the task failed or did not reach the agent
        if (t.targetSuccess != 1) {
          throw { error: t.resultD };
        }
      }),
      tap({
        next: () => {
          this.clearDisposables();
          const tenantID = this.stateSvc.tenant.id;
          const sessionID = this.sessionID;
          const svc = this.pubSubSvc;
          const ch = `$shell:agent.${tenantID}.${agent.id}.${sessionID}.shellRequest`;

          const disposables = this.disposables;
          const terminal = this.terminal;

          disposables.push(
            terminal.onTitleChange(function (title) {
              document.title = title;
            })
          );

          disposables.push(
            terminal.onSelectionChange(function () {
              const copied = terminal.getSelection();
              if (copied) {
                navigator.clipboard.writeText(copied);
              }
            })
          );

          disposables.push(
            terminal.onResize(function (data) {
              svc.Send(ch, {
                type: 'shell-resize',
                payload: [data.cols, data.rows]
              });
            })
          );

          disposables.push(
            terminal.onData(function (data) {
              svc.Send(ch, {
                type: 'shell-input',
                payload: data
              });
            })
          );
        }
      }),
      switchMap(() => this.channelHistory$),
      switchMap(() => this.channelListener$)
    );

    this.sub = startListening$.subscribe({
      complete: () => {
        this._state.next('disconnected');
      },
      error: (err) => {
        this.state$
          .pipe(
            take(1),
            tap((state) => {
              state === 'connected' ? this.disconnect() : null;
            })
          )
          .subscribe();
        this.lastShellError = parseError(err) || 'unexpected disconnect';
        this._state.next('error');
      }
    });
  }

  paste(data: string): void {
    if (this._state.value === 'connected') {
      this.terminal.paste(data);
    }
  }

  resize(): void {
    if (this._state.value === 'connected') {
      this.fitAddon.fit();
      const data = this.fitAddon.proposeDimensions();
      const ch = `$shell:agent.${this.stateSvc.tenant.id}.${this._agent.value.id}.${this.sessionID}.shellRequest`;
      this.pubSubSvc.Send(ch, {
        type: 'shell-resize',
        payload: [data.cols, data.rows]
      });
    }
  }

  disconnect(): void {
    const ch = `$shell:agent.${this.stateSvc.tenant.id}.${this._agent.value.id}.${this.sessionID}.shellRequest`;
    this.pubSubSvc.Send(ch, {
      type: 'shell-disconnect',
      payload: ''
    });
    this.sub.unsubscribe();
    this.clearDisposables();
    this._state.next('disconnected');
  }

  clearDisposables(): void {
    this.disposables.forEach((d) => d.dispose());
    this.disposables.length = 0; // clear disposables
  }

  getShellByOS(os: OS): ShellType {
    switch (os) {
      case OS.WINDOWS:
        return ShellType.Powershell;
      case OS.DARWIN:
      case OS.LINUX:
        return ShellType.Sh;
    }
  }

  readonly channelName$ = this.agent$.pipe(
    map(
      (a) =>
        `$shell:web.${this.stateSvc.tenant.id}.${a.id}.${this.sessionID}.shellResponse`
    )
  );

  readonly channelHistory$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.history(channel, {
        limit: 20,
        reverse: false
      })
    ),
    catchError((err) => {
      console.error('error retrieving history', err);

      return of(<Centrifuge.HistoryResult>{
        publications: [],
        offset: -1,
        epoch: ''
      });
    }),
    map((history) => {
      let start: Date;

      for (let i = 0; i < history.publications.length; i++) {
        const packet = history.publications[i].data as Packet;
        console.log(`HISTORY: ${JSON.stringify(packet)}`);

        if (packet.type === PacketType.StartPacket) {
          const s: PacketStart = JSON.parse(<string>packet.content);
          start = s.timestamp;
          this.terminal.focus();
          this._state.next('connected');
          this.resize();
        } else if (start) {
          if (packet.type === PacketType.ShellOutputPacket) {
            const msg = JSON.parse(<string>packet.content);
            this.terminal.write(msg);
          } else if (packet.type === PacketType.CompletePacket) {
            this.disconnect();
          } else if (packet.type === PacketType.ErrorPacket) {
            this._state.next('error');
          }
        } else {
          continue;
        }
      }

      return;
    })
  );

  readonly channelListener$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          if (packet.type === PacketType.ShellOutputPacket) {
            const msg = JSON.parse(<string>packet.content);
            this.terminal.write(msg);
          } else if (packet.type === PacketType.StartPacket) {
            this.terminal.focus();
            this._state.next('connected');
            this.resize();
            return;
          } else if (packet.type === PacketType.CompletePacket) {
            this.disconnect();
          } else if (packet.type === PacketType.ErrorPacket) {
            this._state.next('error');
          }
        })
      )
    )
  );
}

@Injectable({ providedIn: 'root' })
export class ShellService extends ApiService {
  constructor(
    private http: HttpClient,
    private stateSvc: StateService,
    private pubSubSvc: PubSubWsService,
    private taskSvc: TaskService
  ) {
    super();
  }

  newShell(): Shell {
    return new Shell(this, this.stateSvc, this.pubSubSvc, this.taskSvc);
  }

  requestShellConnection(
    agentID: string,
    shellType: ShellType
  ): Observable<TagResponse<OpenShellResponse>> {
    const url = `${this.apiUrl}/agent/${agentID}/shell/open`;
    const params = { shellType };

    return this.http.post<TagResponse<OpenShellResponse>>(url, params).pipe(
      map((response) =>
        response ? new TagResponse<OpenShellResponse>(response) : undefined
      ),
      catchError((err) => {
        this.handleError<TagResponse<OpenShellResponse>>('ShellService.openShell');
        return throwError(() => err);
      })
    );
  }

  getWebSocketTicket(agentID: string, shellType: string): Observable<ShellTicket> {
    const url = `${this.baseWSUrl}/ws_ticket`;
    const params = {
      agentID,
      shellType
    };
    return this.http.get(url, { params }).pipe(
      tap(() => this.log('fetched ShellService.getWebSocketTicket response')),
      map((response) => new ShellTicket(response)),
      catchError((err) => {
        this.handleError<ShellTicket>('SystemService.getWebSocketTicket');
        return throwError(() => err);
      })
    );
  }
}
