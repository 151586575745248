<div class="relative" style="height: 5px">
  <div class="buffer-timeline h-full flex flex-row justify-center">
    <div *ngFor="let buffered of bufferedArray" class="flex-auto flex flex-col">
      <div
        [ngClass]="{ 'bg-red': buffered.result === 1, 'bg-gray': buffered.result === 2 }"
        class="flex-initial bg-opacity-50"
        style="height: 5px"
      ></div>
    </div>
  </div>
</div>
