import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { PaymentSource } from 'src/app/shared/models/subscription.model';
import { LoaderBallComponent } from 'src/app/shared/components/loader-ball/loader-ball.component';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import {
  OverlayResult,
  ResultOverlayComponent
} from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribeService } from 'src/app/core/services/subscribe.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { catchError, finalize, tap } from 'rxjs';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { UNDEFINED } from 'src/app/utils/rxjs';

export class VerifyPaymentSourceParams {
  paymentSourceID: string;
  onVerified: (paymentSource: PaymentSource) => void;
  constructor(init?: Partial<VerifyPaymentSourceParams>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'mon-verify',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    LoaderBallComponent,
    ReactiveFormsModule,
    ResultOverlayComponent,
    SpinnerComponent
  ],
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  animations: [fadeInRight400ms]
})
export class VerifyComponent implements OnInit {
  spin: boolean;
  overlayResult = OverlayResult.Unset;

  verifyForm = new FormGroup({
    amount1: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^\d{2}$/)
    ]),
    amount2: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^\d{2}$/)
    ])
  });

  @ViewChild('amount1') amount1: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected params: VerifyPaymentSourceParams,
    private dialogRef: MatDialogRef<VerifyComponent>,
    private snackBar: MatSnackBar,
    private subscribeSvc: SubscribeService
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.amount1?.nativeElement.focus(), 100);
  }

  submit(): void {
    this.spin = true;

    this.subscribeSvc
      .verifyBankAccount(
        this.params.paymentSourceID,
        +this.verifyForm.value.amount1,
        +this.verifyForm.value.amount2
      )
      .pipe(
        tap((paymentSource) => {
          this.overlayResult = OverlayResult.Unset;
          this.params.onVerified?.(paymentSource);
          this.dialogRef.close();
        }),
        catchError((err) => {
          const errMsg = parseError(err, 'failed to submit verification amounts');
          this.snackBar.open(errMsg.toUpperCase(), 'CLOSE', {
            duration: 10000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });

          return UNDEFINED;
        }),
        finalize(() => (this.spin = false))
      )
      .subscribe();
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.dialogRef.close();
    }
  }
}
