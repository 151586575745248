import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AlarmService } from 'src/app/core/services/alarm.service';
import { Alarm } from 'src/app/shared/models/alarm.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

export class AlarmsDataSource implements DataSource<Alarm> {
  private _alarms = new BehaviorSubject<Alarm[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._alarms.value ? this._alarms.value.length : -1;
  }
  public get data(): Alarm[] {
    return this._alarms.value?.slice() || [];
  }

  constructor(private alarmSvc: AlarmService) {}

  loadAlarms(limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    this.alarmSvc
      .getAll(limit, page, orderBy)
      .pipe(
        catchError(() => UNDEFINED),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (results: PagedResult<Alarm>) => {
          if (results) {
            this.total = results.count;
            this._alarms.next(results.items || []);
          }
        }
      });
  }

  connect(): Observable<Alarm[]> {
    return this._alarms.asObservable();
  }

  disconnect(): void {
    this._alarms.complete();
    this._loading.complete();
  }
}
