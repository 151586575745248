import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Target, Task } from 'src/app/shared/models/task.model';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TaskService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getTask(taskID: string): Observable<Task> {
    const url = `${this.apiUrl}/task/${taskID}`;
    const params = {};
    const start = performance.now();
    return this.http.get<Task>(url, { params }).pipe(
      tap(() =>
        this.log(`fetched TaskService.getTask response in ${performance.now() - start}ms`)
      ),
      map((t) => (t ? new Task(t) : undefined)),
      catchError((err) => {
        this.handleError<Task>('TaskService.getTask');
        return throwError(() => err);
      })
    );
  }

  getTaskAgentStatus(taskID: string, agentID: string): Observable<Target> {
    const url = `${this.apiUrl}/task/${taskID}/agent/${agentID}`;
    const params = {};
    const start = performance.now();
    return this.http.get<Target>(url, { params }).pipe(
      tap(() =>
        this.log(`fetched TaskService.getTask response in ${performance.now() - start}ms`)
      ),
      catchError((err) => {
        this.handleError<Target>('TaskService.getTask');
        return throwError(() => err);
      })
    );
  }

  getTaskListen(taskID: string, newerThanUnixNano: number | string): Observable<Task> {
    const url = `${this.apiUrl}/task/${taskID}/listen`;
    const params = {};
    if (newerThanUnixNano) {
      Object.assign(params, { newerThanUnixNano });
    }
    const start = performance.now();
    return this.http.get<Task>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched TaskService.getTaskListen [${newerThanUnixNano}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((t) => (t ? new Task(t) : undefined)),
      catchError((err) => {
        this.handleError<Task>('TaskService.getTaskListen');
        return throwError(() => err);
      })
    );
  }

  getAll(limit: number, page: number, orderBy: OrderBy[]): Observable<PagedResult<Task>> {
    const url = `${this.apiUrl}/task`;
    const params = {};
    Object.assign(params, {
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Task>>(url, { observe: 'response', params }).pipe(
      tap(() =>
        this.log(`fetched TaskService.getAll response in ${performance.now() - start}ms`)
      ),
      map((response) =>
        response
          ? new PagedResult<Task>(Task, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Task>>('TaskService.getAll');
        return throwError(() => err);
      })
    );
  }

  getAllListen(newerThanUnixNano: number | string): Observable<Task[]> {
    const url = `${this.apiUrl}/task/listen`;
    const params = {};
    if (newerThanUnixNano) {
      Object.assign(params, { newerThanUnixNano });
    }
    const start = performance.now();
    return this.http.get<Task[]>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched TaskService.getAllListen [${newerThanUnixNano}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? response.map((t) => new Task(t)) : undefined)),
      catchError((err) => {
        this.handleError<Task[]>('TaskService.getAllListen');
        return throwError(() => err);
      })
    );
  }

  getAgentAll(agentID: string): Observable<Array<Task>> {
    const url = `${this.apiUrl}/agent/${agentID}/task`;
    const params = {};
    const start = performance.now();
    return this.http.get<Array<Task>>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched TaskService.getAgentAll [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? response.map((t) => new Task(t)) : undefined)),
      catchError((err) => {
        this.handleError<Array<Task>>('TaskService.getAgentAll');
        return throwError(() => err);
      })
    );
  }

  getAgentAllListen(
    agentID: string,
    newerThan: Date = new Date(0)
  ): Observable<Array<Task>> {
    const url = `${this.apiUrl}/agent/${agentID}/task/listen`;
    const params = {};
    if (newerThan) {
      Object.assign(params, { newerThan: newerThan.toISOString() });
    }
    const start = performance.now();
    return this.http.get<Array<Task>>(url, { params }).pipe(
      tap(() =>
        this.log(
          `fetched TaskService.getAgentAll [${agentID}] [${newerThan.toISOString()}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => (response ? response.map((t) => new Task(t)) : undefined)),
      catchError((err) => {
        this.handleError<Array<Task>>('TaskService.getAgentAll');
        return throwError(() => err);
      })
    );
  }
}
