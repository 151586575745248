import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  skip,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp200ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { IntroService } from 'src/app/core/services/intro.service';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { UserService } from 'src/app/core/services/user.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  NewTenantComponent,
  NewTenantParams
} from 'src/app/shared/components/tenant/new-tenant/new-tenant.component';
import { UNDEFINED } from 'src/app/utils/rxjs';

interface userProperty {
  icon: IconName;
  label: string;
  value: string;
}

interface Tenant {
  id: string;
  name: string;
  domain?: string;
  company?: string;
  owned?: boolean;
  licenseStatus?: string;
  invite?: boolean;
  inviteID?: string;
  lockInteraction?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'mon-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  animations: [fadeInUp200ms, fadeInRight400ms, stagger80ms, scaleIn400ms]
})
export class GeneralComponent implements OnInit {
  searchCtrl = new FormControl();

  userProperties$ = this.stateSvc.currentUser$.pipe(
    filter((u) => !!u),
    take(1),
    map((user) => {
      const props: userProperty[] = [];

      props.push({
        icon: 'envelope',
        label: 'Email',
        value: user.email
      });

      props.push({
        icon: 'shield-alt',
        label: 'Roles',
        value: user.rolesD || 'none'
      });

      props.push({
        icon: 'calendar',
        label: 'Created',
        value: user.createdD
      });

      props.push({
        icon: 'sign-in',
        label: 'Last Login',
        value: `${user.lastLogin.timestamp.toLocaleString(undefined, {
          formatMatcher: 'best fit'
        })}\n${user.lastLogin.ip}\n${user.geoD}`
      });

      return props;
    })
  );

  tenantInvites$ = this.userSvc.getInvites();

  _invokeSearchFilter = new BehaviorSubject<void>(undefined);
  invokeSearchFilter$ = this._invokeSearchFilter.asObservable();

  _refreshUser = new BehaviorSubject<void>(undefined);
  refreshUser$ = this._refreshUser.asObservable();

  _refreshTenants = new BehaviorSubject<void>(undefined);
  refreshTenants$ = this._refreshTenants.asObservable();

  tenants$ = this.refreshUser$.pipe(
    switchMap(() =>
      this.stateSvc.currentUser$.pipe(
        filter((u) => !!u),
        take(1),
        mergeMap(() =>
          this.refreshTenants$.pipe(
            switchMap(() =>
              combineLatest([
                this.tenantInvites$.pipe(
                  tap((i) => (this.hasInvites = i.count > 0)),
                  map((invites) =>
                    invites.items.map((i) => {
                      return {
                        id: i.tenantID,
                        company: i.companyName,
                        name: i.name,
                        owned: false,
                        invite: true,
                        inviteID: i.id
                      };
                    })
                  ),
                  tap((invites) => (this.invites = invites))
                ),
                this.userSvc.tenants().pipe(
                  map((tenants) => {
                    tenants.sort((a, b) =>
                      a.owned ? -1 : 1 || b.name.localeCompare(a.name)
                    );

                    const result: Tenant[] = [];

                    let owned = 0;

                    tenants.forEach((tenant) => {
                      if (tenant.owned) {
                        owned++;
                      }

                      result.push({
                        id: tenant.id,
                        company: tenant.companyName,
                        name: tenant.name,
                        domain: tenant.domain,
                        owned: tenant.owned,
                        licenseStatus: tenant.licenseStatus
                      });
                    });

                    this._disableCreateTenant.next(owned >= 5);

                    return result;
                  }),
                  tap((tenants) => (this.tenants = tenants))
                )
              ])
            )
          )
        ),
        tap(() => this._invokeSearchFilter.next())
      )
    )
  );

  currentTenant$ = this.stateSvc.tenant$.pipe(
    filter((t) => !!t),
    take(1)
  );

  _disableCreateTenant = new BehaviorSubject<boolean>(false);
  disableCreateTenant$ = this._disableCreateTenant.asObservable();

  tenantAndUser$ = combineLatest([
    this.userProperties$,
    this.tenants$,
    this.currentTenant$
  ]).pipe(
    map(([userProperties, tenants, currentTenant]) => {
      const invites = tenants[0];
      const joined = tenants[1];

      return {
        userProperties,
        invites,
        joined,
        currentTenant
      };
    })
  );

  switchTenant$ = (tenantID: string) =>
    of(tenantID).pipe(
      tap((tenantID) => {
        this.stateSvc.updateCurrentUserSettings({
          selectedTenantID: tenantID
        });
      }),
      switchMap(() => {
        return this.tenantSvc.get().pipe(
          map((t) => {
            this.stateSvc.setTenant(t);
            this.stateSvc.setSearch('');
          }),
          switchMap(() =>
            this.stateSvc.tenant$.pipe(
              skip(1), // skip one because it will be old, wait to take the one it is switched to
              take(1),
              tap(() =>
                this.router.navigate(['home', 'dashboard'], {
                  queryParams: { machineID: undefined },
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                  replaceUrl: true
                })
              )
            )
          )
        );
      })
    );

  hasInvites = false;
  tenants: Tenant[] = [];
  filteredTenants: Tenant[] = [];
  invites: Tenant[] = [];
  filteredInvites: Tenant[] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private introSvc: IntroService,
    private stateSvc: StateService,
    private tenantSvc: TenantService,
    private userSvc: UserService
  ) {}

  ngOnInit(): void {
    this.introSvc.show('tenant-invite');

    this.searchCtrl.valueChanges
      .pipe(
        startWith(''),
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        mergeMap((filter: string) => {
          return this.invokeSearchFilter$.pipe(
            tap(() => {
              this.filteredTenants = this.tenants.filter(
                (t) =>
                  t.name.toLocaleLowerCase().indexOf(filter) >= 0 ||
                  t.company?.toLocaleLowerCase().indexOf(filter) >= 0
              );

              this.filteredInvites = this.invites.filter(
                (t) =>
                  t.name.toLocaleLowerCase().indexOf(filter) >= 0 ||
                  t.company?.toLocaleLowerCase().indexOf(filter) >= 0
              );
            })
          );
        })
      )
      .subscribe();
  }

  joinTenant(tenant: Tenant): void {
    tenant.lockInteraction = true;
    this.userSvc
      .joinTenant(tenant.id)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.dialog.open(ConfirmDialogComponent, {
            restoreFocus: false,
            data: {
              icon: `exclamation`,
              iconBgClasses: ['bg-red-light'],
              iconClasses: ['text-red'],
              title: `Error joining tenant`,
              confirmationText: parseError(err, 'could not join tenant'),
              hideConfirmButton: true,
              cancelButtonText: 'OK',
              onCancel: () => {}
            }
          });
          return throwError(() => err);
        }),
        switchMap(() =>
          this.userSvc.me().pipe(tap((u) => this.stateSvc.setCurrentUser(u)))
        ),
        tap(() => this._refreshTenants.next()),
        tap(() => this.stateSvc.refreshTenantInvites()),
        finalize(() => (tenant.lockInteraction = false))
      )
      .subscribe();
  }

  declineTenant(tenant: Tenant): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: `times`,
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: `Decline invite?`,
        confirmationText: `Are you sure you want to decline the invite from ${
          tenant.name || tenant.company || tenant.domain
        }?`,
        onConfirm: () => {
          tenant.lockInteraction = true;
          this.userSvc
            .rejectTenant(tenant.id)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                this.dialog.open(ConfirmDialogComponent, {
                  restoreFocus: false,
                  data: {
                    icon: `exclamation`,
                    iconBgClasses: ['bg-red-light'],
                    iconClasses: ['text-red'],
                    title: `Error declining tenant`,
                    confirmationText: parseError(err, 'could not decline tenant'),
                    hideConfirmButton: true,
                    cancelButtonText: 'OK',
                    onCancel: () => {}
                  }
                });
                return throwError(() => err);
              }),
              tap(() => this._refreshTenants.next()),
              tap(() => this.stateSvc.refreshTenantInvites()),
              finalize(() => (tenant.lockInteraction = false))
            )
            .subscribe();
        },
        onCancel: () => {}
      }
    });
  }

  leaveTenant(tenant: Tenant): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: `unlink`,
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: `Leaving tenant?`,
        confirmWithCheckbox: this.tenants.length < 2,
        htmlDialogContent: `Are you sure you want to leave ${
          tenant.name || tenant.company || tenant.domain
        }?`,
        onConfirm: () => {
          tenant.lockInteraction = true;
          this.userSvc
            .leaveTenant(tenant.id)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                tenant.lockInteraction = false;
                this.dialog.open(ConfirmDialogComponent, {
                  restoreFocus: false,
                  data: {
                    icon: `exclamation`,
                    iconBgClasses: ['bg-red-light'],
                    iconClasses: ['text-red'],
                    title: `Error leaving tenant`,
                    confirmationText: parseError(err, 'could not leave tenant'),
                    hideConfirmButton: true,
                    cancelButtonText: 'OK',
                    onCancel: () => {}
                  }
                });
                return throwError(() => err);
              }),
              switchMap(() =>
                this.userSvc.me().pipe(
                  tap((u) => this.stateSvc.setCurrentUser(u)),
                  switchMap((u) => {
                    // set selected tenant to primary if we are deleting the tenant we are on
                    return u.settings.selectedTenantID === tenant.id
                      ? this.switchTenant$(u.ownedTenantIDs[0])
                      : UNDEFINED;
                  })
                )
              ),
              tap(() => this._refreshTenants.next())
            )
            .subscribe();
        },
        onCancel: () => {}
      }
    });
  }

  removeTenant(tenant: Tenant): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: false,
      data: {
        icon: `building`,
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: `Removing tenant?`,
        confirmWithCheckbox: this.tenants.length < 2,
        htmlDialogContent: `
        ${this.lastTenantWarning()}
        <div class="flex items-center justify-center">
          Are you sure you want to remove ${
            tenant.name || tenant.company || tenant.domain
          }?
        </div>`,
        onConfirm: () => {
          tenant.lockInteraction = true;
          this.userSvc
            .removeTenant(tenant.id)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                tenant.lockInteraction = false;
                this.dialog.open(ConfirmDialogComponent, {
                  restoreFocus: false,
                  data: {
                    icon: `exclamation`,
                    iconBgClasses: ['bg-red-light'],
                    iconClasses: ['text-red'],
                    title: `Error removing tenant`,
                    confirmationText: parseError(err, 'could not remove tenant'),
                    hideConfirmButton: true,
                    cancelButtonText: 'OK',
                    onCancel: () => {}
                  }
                });
                return throwError(() => err);
              }),
              switchMap(() =>
                this.userSvc.me().pipe(
                  tap((u) => this.stateSvc.setCurrentUser(u)),
                  switchMap((u) => {
                    // set selected tenant to primary if we are deleting the tenant we are on
                    return u.settings.selectedTenantID === tenant.id
                      ? this.switchTenant$(u.ownedTenantIDs[0])
                      : UNDEFINED;
                  })
                )
              ),
              tap(() => this._refreshTenants.next())
            )
            .subscribe();
        },
        onCancel: () => {}
      }
    });
  }

  createTenant(): void {
    this.dialog.open(NewTenantComponent, {
      disableClose: true,
      restoreFocus: false,
      width: '400px',
      data: new NewTenantParams({
        onSuccess: () => {
          this.userSvc
            .me()
            .pipe(
              tap((u) => this.stateSvc.setCurrentUser(u)),
              tap(() => this._refreshUser.next())
            )
            .subscribe();
        }
      })
    });
  }

  trackByID(_index: number, tenant: Tenant): string {
    return tenant.id;
  }

  getTenantColor(tenant: Tenant): string {
    switch (tenant.licenseStatus) {
      case 'active':
        return 'text-green';
      case 'in-trial':
        return 'text-yellow';
      case 'non-renewing':
        return 'text-orange';
      case 'cancelled':
        return 'text-red';
    }

    return 'text-secondary';
  }

  lastTenantWarning(): string {
    return this.tenants.length > 1
      ? ''
      : `
      <div class="card text-white text-opacity-80 bg-red flex flex-col justify-between items-center p-4 mb-4 text-center gap-2">
        <h2>WARNING:</h2>
        <p>Deleting your last tenant will close your account</p>
      </div>
    `;
  }
}
