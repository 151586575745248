<ng-container *ngIf="userTablesettings$ | async"></ng-container>
<ng-container *ngIf="inviteTablesettings$ | async"></ng-container>
<div [@listStagger]="2" class="pt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
  <div @fadeInUp *ngIf="true" class="flex flex-col card relative overflow-x-auto overflow-y-hidden w-full">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
      <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
        <span>Users</span>
      </h2>

      <span class="flex-1"></span>

      <button (click)="modUser()" class="ml-4 flex-none" mat-icon-button matTooltip="Invite User" type="button">
        <fa-icon [icon]="['fad', 'user-plus']" size="lg"></fa-icon>
      </button>

      <mon-display-config-button
        matTooltip="Filter Columns"
        *ngIf="userDisplayConfig$ | async as config"
        persistAt="User"
        [config]="config"
        [canHide]="false"
        [icon]="['fad', 'columns']"
        class="ml-2 flex items-center justify-center flex-none"
      ></mon-display-config-button>
    </div>

    <div *ngIf="userDataSource.loading$ | async" class="absolute top-0 left-0 bottom-0 right-0 z-10">
      <div class="absolute w-full h-full bg-gray opacity-30"></div>
      <fa-icon
        [icon]="['fad', 'cog']"
        animation="spin"
        size="4x"
        class="flex w-full h-full items-center justify-center text-primary animate-pulse"
      ></fa-icon>
    </div>

    <table
      [dataSource]="userDataSource"
      [@listStagger]="userDataSource.length"
      mat-table
      matSort
      [matSortActive]="this.userSortField"
      [matSortDirection]="this.userSortDirection"
      class="w-full"
      #userSort="matSort"
    >
      <!-- Text Columns -->
      <ng-container *ngFor="let column of userColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th
            *matHeaderCellDef
            class="uppercase"
            [disabled]="column.sortable === false"
            mat-header-cell
            [mat-sort-header]="column.display || column.property"
          >
            {{ column.label }}
          </th>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ getValue(row, column.property) }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Date Columns -->
      <ng-container *ngFor="let column of userColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <ng-container [ngSwitch]="column.dateFormat">
              <ng-container *ngSwitchCase="'timeAgo'">
                {{ row[column.property] | timeAgo }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ row[column.property] | date: column.dateFormat || 'medium' }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <!-- Button Columns -->
      <ng-container *ngFor="let column of userColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <div class="flex float-right">
              <fa-icon [icon]="['fad', column.buttonIconName]" size="lg" class="text-gray" [matTooltip]="column.tooltip"> </fa-icon>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="visibleUserColumns$ | async as visibleColumns">
        <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: visibleColumns"
          @fadeInRight
          (click)="modUser(row)"
          class="hover:bg-hover trans-ease-out cursor-pointer"
        ></tr>
      </ng-container>
    </table>

    <div class="flex-1"></div>

    <mat-paginator [pageSizeOptions]="userPageSizeOptions" [pageSize]="userPageSize" [length]="userDataSource.total" #userPaginator>
    </mat-paginator>
  </div>
  <div @fadeInUp *ngIf="true" class="flex flex-col card relative overflow-x-auto overflow-y-hidden w-full">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
      <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
        <span>Invites</span>
      </h2>

      <span class="flex-1"></span>

      <mon-display-config-button
        matTooltip="Filter Columns"
        *ngIf="inviteDisplayConfig$ | async as config"
        persistAt="User"
        [config]="config"
        [canHide]="false"
        [icon]="['fad', 'columns']"
        class="ml-2 flex items-center justify-center flex-none"
      ></mon-display-config-button>
    </div>

    <div *ngIf="inviteDataSource.loading$ | async" class="absolute top-0 left-0 bottom-0 right-0 z-10">
      <div class="absolute w-full h-full bg-gray opacity-30"></div>
      <fa-icon
        [icon]="['fad', 'cog']"
        animation="spin"
        size="4x"
        class="flex w-full h-full items-center justify-center text-primary animate-pulse"
      ></fa-icon>
    </div>

    <table
      [dataSource]="inviteDataSource"
      [@listStagger]="inviteDataSource.length"
      mat-table
      matSort
      [matSortActive]="this.inviteSortField"
      [matSortDirection]="this.inviteSortDirection"
      class="w-full"
      #inviteSort="matSort"
    >
      <!-- Text Columns -->
      <ng-container *ngFor="let column of inviteColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th
            *matHeaderCellDef
            class="uppercase"
            [disabled]="column.sortable === false"
            mat-header-cell
            [mat-sort-header]="column.display || column.property"
          >
            {{ column.label }}
          </th>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ column.textFn ? column.textFn(row) : getValue(row, column.property) }}
          </td>
        </ng-container>
      </ng-container>

      <!-- TextArray Columns -->
      <ng-container *ngFor="let column of inviteColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'textArray'" [matColumnDef]="column.property">
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            [disabled]="column.sortable === false"
            [mat-sort-header]="column.display || column.property"
          >
            {{ column.label }}
          </th>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ getRoles | callOnce: row }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Date Columns -->
      <ng-container *ngFor="let column of inviteColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ row[column.property] | timeAgo }}
          </td>
        </ng-container>
      </ng-container>

      <!-- ButtonArray Columns -->
      <ng-container *ngFor="let column of inviteColumns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'buttonArray'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <div class="flex items-center justify-end">
              <ng-container *ngFor="let button of column.buttons">
                <button
                  *ngIf="button.showConditionFn ? button.showConditionFn(row) : true"
                  [matTooltip]="button.tooltip"
                  (click)="button.fn(row)"
                  [ngClass]="button.buttonClasses"
                  class="flex items-center justify-center w-8 h-8 leading-none transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
                  mat-icon-button
                >
                  <fa-icon [icon]="['fad', button.icon]" size="lg" [class]="button.iconClasses"> </fa-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="visibleInviteColumns$ | async as visibleColumns">
        <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: visibleColumns" @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer"></tr>
      </ng-container>
    </table>

    <div class="flex-1"></div>

    <mat-paginator [pageSizeOptions]="invitePageSizeOptions" [pageSize]="invitePageSize" [length]="inviteDataSource.total" #invitePaginator>
    </mat-paginator>
  </div>
</div>
