import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  ViewChild
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { HotkeysService } from '@ngneat/hotkeys';
import { BehaviorSubject, interval, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
  tap
} from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { AgentService } from 'src/app/core/services/agent.service';
import { IntroService } from 'src/app/core/services/intro.service';
import { SearchService } from 'src/app/core/services/search.service';
import { Shell, ShellService, ShellType } from 'src/app/core/services/shell.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { Agent } from 'src/app/shared/models/agent.model';

@Component({
  selector: 'mon-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [fadeInUp400ms]
})
export class ShellComponent implements AfterViewInit {
  _search = new BehaviorSubject<boolean>(false);
  search$ = this._search.asObservable().pipe(
    tap({
      next: (search) => {
        this.manualErr = undefined;

        if (search) {
          setTimeout(() => {
            this.introSvc.show('shell-connect-shell-type', 'shell-connect-input');
          }, 1000);
        }
      }
    })
  );

  _shellType = new BehaviorSubject<ShellType>(ShellType.Powershell);
  shellType$ = this._shellType.asObservable();

  _searchTerm = new BehaviorSubject<KeyboardEvent>(null);
  searchTerm$ = this._searchTerm.asObservable().pipe(
    debounceTime(300),
    tap(() => (this.manualErr = undefined)),
    map(
      () => (!!this._searchInput ? this._searchInput.nativeElement.value : '') as string
    ),
    distinctUntilChanged()
  );

  searchResults$ = this.searchTerm$.pipe(
    tap({ next: () => (this.spin = true) }),
    mergeMap((term) => {
      if (!term) return of([]);

      return this.searchSvc.getAgents(term).pipe(
        map((r) => r.items.sort((a, b) => a.id.localeCompare(b.id))),
        map((agents) => {
          const found = agents.findIndex((a) => a.id === term);
          if (found >= 0) {
            const moveMe = agents.splice(found, 1);
            agents = [moveMe[0], ...agents];
          }

          return agents;
        })
      );
    }),
    tap({ next: () => (this.spin = false) })
  );

  shell: Shell = this.shellSvc.newShell();

  shellState$ = this.shell.state$.pipe(
    tap({
      next: (state) => {
        switch (state) {
          case 'connected':
            this.introSvc.show('shell-toolbar', 'shell-view');
            break;
        }
      }
    })
  );

  agent$ = this.shell.agent$;

  shellOps = Object.keys(ShellType);
  keyInput: string;
  spin = false;

  time: number;
  manualErr: string;

  protected displayNameOfAgent(a: Agent): string {
    return a.id;
  }

  _searchInput: ElementRef;
  @ViewChild('searchInput') set searchInput(searchInput: ElementRef) {
    this._searchInput = searchInput;
    if (this._searchInput) {
      this._searchInput.nativeElement.focus();
    }
  }
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('term') term: ElementRef;
  @ViewChild('connectBtn') set connectBtn(connectBtn: MatButton) {
    if (connectBtn) {
      connectBtn.focus();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.shell.resize();
  }

  constructor(
    private introSvc: IntroService,
    hotkeysSvc: HotkeysService,
    private agentSvc: AgentService,
    private searchSvc: SearchService,
    private shellSvc: ShellService
  ) {
    this.initShell();

    hotkeysSvc.addShortcut({ keys: '/' }).subscribe({
      next: () => {
        this._search.next(true);
      }
    });
  }

  ngAfterViewInit(): void {
    const params = new URLSearchParams(window.location.search);
    if (params) {
      const agentID = params.get('machineID');

      if (agentID) {
        this.agentSvc
          .getByID(agentID)
          .pipe(tap((agent) => this.connect(agent)))
          .subscribe();
      } else {
        this.introSvc.show('shell-connect-button');
      }
    }
  }

  initShell(): void {
    interval(250)
      .pipe(
        filter(() => !!this.term),
        tap(() => this.shell.setShellElement(this.term)),
        take(1)
      )
      .subscribe();
  }

  onKeyInput(event: string): void {
    this.keyInput = event;
  }

  manualSelect(agentID: string): void {
    if (!agentID) {
      return;
    }

    this.manualErr = ' ';

    this.agentSvc
      .getByID(agentID)
      .pipe(
        tap((agent) => this.connect(agent)),
        catchError((err) => (this.manualErr = parseError(err)))
      )
      .subscribe();
  }

  dropdownSelect(event: MatAutocompleteSelectedEvent): void {
    this.connect(event.option.value);
  }

  connect(agent: Agent): void {
    this._search.next(false);
    this.shell.connect(agent);
  }

  rightClickPaste(ev: Event): void {
    ev.preventDefault();
    navigator.clipboard
      .readText()
      .then((text) => {
        this.shell.paste(text);
      })
      .catch((err) => console.error(err));
  }

  trackByID(index: number, shell: Shell): number {
    return shell.id;
  }
}
