<div class="incident-timeline-absolute-wrapper top-0 h-full left-0 right-0" style="height: 20px">
  <div class="incident-timeline-relative-wrapper relative w-full h-full">
    <div class="incident-timeline absolute h-full flex flex-row justify-center">
      <ng-container *ngIf="detail">
        <div *ngFor="let c of detail.collectionTimestamps" class="flex-auto flex flex-col">
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsProcesses, c) as hasIncident"
            [matTooltip]="hasIncident ? 'Process Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-blue bg-opacity-80': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsSummaryCPU, c) as hasIncident"
            [matTooltip]="hasIncident ? 'CPU Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-blue bg-opacity-50': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsSummaryMemory, c) as hasIncident"
            [matTooltip]="hasIncident ? 'Memory Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-purple bg-opacity-50': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsSummaryDisk, c) as hasIncident"
            [matTooltip]="hasIncident ? 'Disk Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-green bg-opacity-50': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsSummaryNetwork, c) as hasIncident"
            [matTooltip]="hasIncident ? 'Network Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-orange bg-opacity-50': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsSummaryGPU, c) as hasIncident"
            [matTooltip]="hasIncident ? 'GPU Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-blue bg-opacity-50': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
          <div
            *ngLet="hasIncident(TriggerCategory.AgentStatsSummarySystem, c) as hasIncident"
            [matTooltip]="hasIncident ? 'System Incident at ' + (c | date: 'h:mm:ss a') : null"
            [ngClass]="{ 'bg-yellow bg-opacity-50': hasIncident }"
            class="flex-initial"
            style="height: 5px"
          ></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
