import { friendlyDataSizeInBits } from 'src/app/utils/data-size';

import { Updatable } from '../interfaces/updatable.interface';
import { GatewayPing } from './gateway-ping.model';

export class NetworkSummary implements Updatable<NetworkSummary> {
  public ethernetAdapters: Array<EthernetInterface>;
  public wifiAdapters: Array<WifiInterface>;

  constructor(init?: Partial<NetworkSummary>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);

    if (this.ethernetAdapters) {
      this.ethernetAdapters = this.ethernetAdapters.map((e) => new EthernetInterface(e));
    }

    if (this.wifiAdapters) {
      this.wifiAdapters = this.wifiAdapters.map((w) => new WifiInterface(w));
    }
  }

  update(input: Partial<NetworkSummary>): this {
    return Object.assign(this, input);
  }
}

export class EthernetInterface implements Updatable<EthernetInterface> {
  public index: number;
  public interfaceDescription: string;
  public interfaceGUID: string;
  public interfaceState: number;
  public ipv4: string;
  public ipv6: string;
  public address: string;
  public dns: string;
  public gatewayIP: string;
  public gatewayPing: GatewayPing;
  public sendSpeed: number;
  public receiveSpeed: number;

  get sendSpeedD(): string {
    return `${friendlyDataSizeInBits(this.sendSpeed, 0)}ps`;
  }
  get receiveSpeedD(): string {
    return `${friendlyDataSizeInBits(this.receiveSpeed, 0)}ps`;
  }

  constructor(init?: Partial<EthernetInterface>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.gatewayPing = new GatewayPing(this.gatewayPing);
  }

  update(input: Partial<EthernetInterface>): this {
    input.gatewayPing.update(input.gatewayPing);
    return Object.assign(this, input);
  }
}

export class WifiInterface implements Updatable<WifiInterface> {
  public interfaceDescription: string;
  public interfaceGUID: string;
  public interfaceState: number;
  public ipv4: string;
  public ipv6: string;
  public address: string;
  public dns: string;
  public gatewayIP: string;
  public gatewayPing: GatewayPing;
  public ssid: string;
  public description: string;
  public auth: string;
  public cipher: string;
  public signalQuality: number;
  public channel: number;
  public protocol: string;
  public rxRate: number;
  public txRate: number;
  public sendSpeed: number;
  public receiveSpeed: number;

  get sendSpeedD(): string {
    return `${friendlyDataSizeInBits(this.sendSpeed, 0)}ps`;
  }
  get receiveSpeedD(): string {
    return `${friendlyDataSizeInBits(this.receiveSpeed, 0)}ps`;
  }

  get band(): string {
    return this.freq24chans[this.channel]
      ? '2.4GHz'
      : this.freq5chans[this.channel]
      ? '5GHz'
      : 'unknown';
  }

  constructor(init?: Partial<WifiInterface>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.gatewayPing = new GatewayPing(this.gatewayPing);
  }

  update(input: Partial<WifiInterface>): this {
    input.gatewayPing.update(input.gatewayPing);
    return Object.assign(this, input);
  }

  private freq24chans = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true,
    13: true,
    14: true
  };

  private freq5chans = {
    32: true,
    34: true,
    36: true,
    38: true,
    40: true,
    42: true,
    44: true,
    46: true,
    48: true,
    50: true,
    52: true,
    54: true,
    56: true,
    58: true,
    60: true,
    62: true,
    64: true,
    68: true,
    96: true,
    100: true,
    102: true,
    104: true,
    106: true,
    108: true,
    110: true,
    112: true,
    114: true,
    116: true,
    118: true,
    120: true,
    122: true,
    124: true,
    126: true,
    128: true,
    132: true,
    134: true,
    136: true,
    138: true,
    140: true,
    142: true,
    144: true,
    149: true,
    151: true,
    153: true,
    155: true,
    157: true,
    159: true,
    161: true,
    165: true,
    169: true,
    173: true,
    183: true,
    184: true,
    185: true,
    187: true,
    188: true,
    189: true,
    192: true,
    196: true
  };
}
