import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, EMPTY, iif, Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { fadeInRight400ms, fadeOutRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { LayoutService } from 'src/@vex/services/layout.service';
import { IntroService } from 'src/app/core/services/intro.service';
import { NewScript, ScriptService } from 'src/app/core/services/script.service';
import { SearchService } from 'src/app/core/services/search.service';
import { StateService } from 'src/app/core/services/state.service';
import { Permission } from 'src/app/shared/enums/permission.enum';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

import { ScriptEditComponent, ScriptEditParams } from './script-edit/script-edit.component';
import { ScriptFeedbackComponent, ScriptFeedbackParams } from './script-feedback/script-feedback.component';
import { ScriptInvokeComponent, ScriptInvokeParams } from './script-invoke/script-invoke.component';
import { ScriptListItemComponent } from './script-list-item/script-list-item.component';

interface SelectedScript {
  location: ScriptLocation;
  id: string;
}

interface Category {
  name: string;
  description: string;
  introKey: string;
  expanded: boolean;
  pageIndex: number;
  limit: number;
  total: number;
  pullScripts: BehaviorSubject<void>;
  pullScripts$: Observable<Script[]>;
  pulling: BehaviorSubject<boolean>;
  pulling$: Observable<boolean>;
  map: Map<string, Script>;
  scripts: Script[];
}

@Component({
  selector: 'mon-scripts',
  templateUrl: './scripts.component.html',
  styleUrls: ['./scripts.component.scss'],
  animations: [stagger40ms, fadeInUp400ms, fadeInRight400ms, fadeOutRight400ms]
})
export class ScriptsComponent implements OnInit, AfterViewInit {
  permission = Permission;
  objectKeys = Object.keys;
  scriptLocation = ScriptLocation;

  private _pullInstalledScripts = new BehaviorSubject<void>(undefined);
  private _pullingMoreInstalled = new BehaviorSubject<boolean>(false);

  private _pullRecommendedScripts = new BehaviorSubject<void>(undefined);
  private _pullingMoreRecommended = new BehaviorSubject<boolean>(false);

  installedCategory: Category = {
    name: 'Installed',
    description: 'Scripts currently in your library',
    introKey: 'script-installed-scripts',
    expanded: true,
    pageIndex: 0,
    limit: 10,
    total: 0,
    pullScripts: this._pullInstalledScripts,
    pullScripts$: this._pullInstalledScripts.asObservable().pipe(
      tap(() => this._pullingMoreInstalled.next(true)),
      debounceTime(500),
      map(() => this.installedCategory),
      mergeMap((c) =>
        this.scriptSvc
          .location(ScriptLocation.Private)
          .getAll(null, c.limit, c.pageIndex, [
            { path: 'disabled', dir: 1 },
            { path: 'location', dir: 1 },
            { path: 'scriptName', dir: 1 }
          ])
          .pipe(tap(() => c.pageIndex++))
      ),
      tap((results) => (this.installedCategory.total = results.count)),
      tap((results) =>
        results.items.forEach((s) => this.installedCategory.map.set(s.uniqueID, s))
      ),
      tap(() => this._pullingMoreInstalled.next(false)),
      map(
        () => (this.installedCategory.scripts = [...this.installedCategory.map.values()])
      )
    ),
    pulling: this._pullingMoreInstalled,
    pulling$: this._pullingMoreInstalled.asObservable(),
    map: new Map<string, Script>(),
    scripts: []
  };

  recommendedCategory: Category = {
    name: 'Recommended',
    description: 'A curated list of scripts recommended by Liquidware',
    introKey: 'script-recommended-scripts',
    expanded: true,
    pageIndex: 0,
    limit: 10,
    total: 0,
    pullScripts: this._pullRecommendedScripts,
    pullScripts$: this._pullRecommendedScripts.pipe(
      tap(() => this._pullingMoreRecommended.next(true)),
      debounceTime(500),
      map(() => this.recommendedCategory),
      mergeMap((c) =>
        this.scriptSvc
          .location(ScriptLocation.Public)
          .getRecommended(c.limit, c.pageIndex, [
            { path: 'installs', dir: 2 },
            { path: 'likes', dir: 2 },
            { path: 'name', dir: 1 }
          ])
          .pipe(tap(() => c.pageIndex++))
      ),
      tap((results) => (this.recommendedCategory.total = results.count)),
      tap((results) =>
        results.items.forEach((s) => this.recommendedCategory.map.set(s.uniqueID, s))
      ),
      tap(() => this._pullingMoreRecommended.next(false)),
      map(
        () =>
          (this.recommendedCategory.scripts = [...this.recommendedCategory.map.values()])
      )
    ),
    pulling: this._pullingMoreRecommended,
    pulling$: this._pullingMoreRecommended.asObservable(),
    map: new Map<string, Script>(),
    scripts: []
  };

  categories: Category[] = [this.installedCategory, this.recommendedCategory];

  private _scriptLock = new BehaviorSubject<boolean>(false);
  readonly scriptLock$ = this._scriptLock.asObservable();

  private _likeLock = new BehaviorSubject<boolean>(false);
  readonly likeLock$ = this._likeLock.asObservable();

  private _selectedLoaded = new BehaviorSubject<boolean>(false);
  readonly selectedLoaded$ = this._selectedLoaded.asObservable();

  private _selected = new BehaviorSubject<SelectedScript>(undefined);
  readonly selected$ = this._selected.asObservable().pipe(
    startWith(undefined),
    distinctUntilChanged((x, y) => {
      if (!x && !!y) {
        return false;
      }

      if (!!x && !y) {
        return false;
      }

      if (!!x && !!y) {
        return x.id === y.id && x.location === y.location;
      }

      return x === y;
    })
  );
  get selected(): SelectedScript {
    return this._selected.value;
  }

  private _detailRefresh = new BehaviorSubject<void>(undefined);
  readonly detailRefresh$ = this._detailRefresh.asObservable();

  readonly scriptDetail$ = combineLatest([this.detailRefresh$, this.selected$]).pipe(
    pairwise(),
    map(([p, n]) => {
      this._scriptLock.next(true);
      let selectedScript = n[1];
      if (!selectedScript || !selectedScript.id || !selectedScript.location) {
        selectedScript = undefined;
      }
      if (n[1]) {
        if (!p[1] || p[1].id !== n[1].id || p[1].location !== n[1].location) {
          this._selectedLoaded.next(false);
        }
      }
      return selectedScript;
    }),
    switchMap((s) => {
      const id = s?.id || '';
      const location = s?.location || ScriptLocation.Public;
      if (!!s) {
        return this.scriptSvc
          .location(location)
          .getByID(id)
          .pipe(
            catchError(() => {
              this._selected.next(undefined);
              this.router.navigate(['/home/tools/scripts/store']);
              return UNDEFINED;
            })
          );
      }
      return UNDEFINED;
    }), // get more detail
    tap((s) => {
      if (s) {
        if (this.installedCategory.map.has(s.uniqueID) || s.installRefID) {
          this.installedCategory.map.set(s.uniqueID, s);
          this.installedCategory.scripts = [...this.installedCategory.map.values()];
        } else {
          this.recommendedCategory.map.set(s.uniqueID, s);
          this.recommendedCategory.scripts = [...this.recommendedCategory.map.values()];
        }
      }
    }),
    tap(() => this._scriptLock.next(false)),
    tap(() => this._selectedLoaded.next(true))
  );

  private _codeLoaded = new BehaviorSubject<boolean>(false);
  readonly codeLoaded$ = this._codeLoaded.asObservable();

  scriptCode$ = combineLatest([this.detailRefresh$, this.selected$.pipe(take(1))]).pipe(
    tap(() => this._codeLoaded.next(false)),
    switchMap(([, selected]) => {
      return selected
        ? this.scriptSvc.location(selected.location).getCodeByID(selected.id)
        : EMPTY;
    }),
    tap(() => this._codeLoaded.next(true))
  );

  scriptChangelog$ = combineLatest([
    this.detailRefresh$,
    this.selected$.pipe(take(1))
  ]).pipe(
    switchMap(([, selected]) => {
      return selected
        ? this.scriptSvc.location(selected.location).getChangelogByID(selected.id)
        : EMPTY;
    }),
    map((c) => Array.from(c?.values() || []).reverse())
  );

  private _searching = new BehaviorSubject<boolean>(false);
  readonly searching$ = this._searching.asObservable();

  _searchInput = new BehaviorSubject<string>(undefined);
  searchInput$ = this._searchInput.asObservable().pipe(
    debounceTime(250),
    map((search) => search?.toLowerCase()),
    distinctUntilChanged()
  );

  search$ = this.searchInput$.pipe(
    tap(() => this._searching.next(true)),
    switchMap((search) =>
      iif(
        () => !!search,
        this.searchSvc.getScripts(search).pipe(map((result) => result.items)),
        UNDEFINED
      )
    ),
    tap(() => this._searching.next(false))
  );

  searchResult$ = combineLatest([this.searching$, this.search$]).pipe(
    map(([searching, results]) => {
      return { searching, results };
    })
  );

  user$ = this.stateSvc.currentUser$;

  isMobile$ = this.layoutSvc.isMobile$;

  @ViewChildren('matExpansionPanel') matExpansionPanels: QueryList<MatExpansionPanel>;
  @ViewChild('filter') filter: ElementRef;

  constructor(
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private stateSvc: StateService,
    private introSvc: IntroService,
    private layoutSvc: LayoutService,
    private searchSvc: SearchService,
    private scriptSvc: ScriptService
  ) {}

  ngAfterViewInit(): void {
    this.introSvc.show(
      'script-search-scripts',
      'script-upload-script',
      'script-installed-scripts',
      'script-recommended-scripts',
      'script-store-marker',
      'script-store-stats'
    );
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const location = params.get('location') || undefined;
      const scriptID = params.get('scriptID');

      this._selected.next({
        id: scriptID,
        location: location as ScriptLocation
      });
    });
  }

  select(script: Script): void {
    this.router.navigate(['/home/tools/scripts/store', script.location, script.id]);
  }

  onFilter(search: string): void {
    this._searchInput.next(search);
  }

  editScript(script?: Script): void {
    let scriptID = null;
    let location = null;
    let onSuccess = null;

    if (script) {
      scriptID = script.id;
      location = script.location;
      onSuccess = () => {
        this._detailRefresh.next();
      };
    } else {
      onSuccess = (s: NewScript) => {
        const newScript = new Script(s as unknown);
        this.installedCategory.map.set(newScript.uniqueID, newScript);
        this.updateScriptInList(newScript);
        this.installedCategory.total++;
        this.select(newScript);
      };
    }

    this.dialog.open(ScriptEditComponent, {
      disableClose: true,
      restoreFocus: false,
      panelClass: 'script-edit',
      data: new ScriptEditParams({
        scriptID: scriptID,
        scriptLocation: location,
        onSuccess: onSuccess
      }),
      width: '600px'
    });
  }

  enableScript(script: Script): void {
    const refreshDetail =
      `${this.selected?.location}:${this.selected?.id}` == script.uniqueID;
    this._scriptLock.next(true);

    this.scriptSvc
      .location(script.location)
      .enable(script.id)
      .pipe(
        tap(() => {
          if (refreshDetail) {
            this._detailRefresh.next();
          } else {
            this._scriptLock.next(false);
          }
        }),
        tap(() => {
          this.updateScriptInList(script);
        })
      )
      .subscribe();
  }

  disableScript(script: Script): void {
    const refreshDetail =
      `${this.selected?.location}:${this.selected?.id}` == script.uniqueID;

    this.scriptSvc
      .location(script.location)
      .disable(script.id)
      .pipe(
        tap(() => {
          if (refreshDetail) {
            this._detailRefresh.next();
          } else {
            this._scriptLock.next(false);
          }
        }),
        tap(() => {
          this.updateScriptInList(script);
        })
      )
      .subscribe();
  }

  installScript(script: Script): void {
    this._scriptLock.next(true);

    this.scriptSvc
      .location(script.location)
      .install(script.id)
      .pipe(
        tap(() => {
          this.recommendedCategory.map.delete(script.uniqueID);
          this.recommendedCategory.scripts = [...this.recommendedCategory.map.values()];

          this.installedCategory.map.set(script.uniqueID, script);
          this.installedCategory.total++;
          this._detailRefresh.next();
        })
      )
      .subscribe();
  }

  uninstallScript(script: Script): void {
    this._scriptLock.next(true);
    this.scriptSvc
      .location(script.location)
      .uninstall(script.id)
      .pipe(
        tap(() => {
          if (script.archived) {
            this.select(undefined);
          }

          this.installedCategory.map.delete(script.uniqueID);
          this.installedCategory.scripts = [...this.installedCategory.map.values()];

          this.recommendedCategory.map.set(script.uniqueID, script);
          this.installedCategory.total--;
          this._detailRefresh.next();
        })
      )
      .subscribe();
  }

  deleteScript(script: Script): void {
    this._scriptLock.next(true);
    this.scriptSvc
      .location(script.location)
      .delete(script.id)
      .pipe(
        finalize(() => this._scriptLock.next(false)),
        tap(() => {
          this.installedCategory.map.delete(script.uniqueID);
          this.installedCategory.scripts = [...this.installedCategory.map.values()];
          this.installedCategory.total--;

          if (`${this.selected?.location}:${this.selected?.id}` == script.uniqueID) {
            this._selected.next(undefined);
          }
        })
      )
      .subscribe();
  }

  archiveScript(script: Script): void {
    this._scriptLock.next(true);
    this.scriptSvc
      .location(ScriptLocation.Public)
      .archive(script.id)
      .pipe(
        finalize(() => this._scriptLock.next(false)),
        tap(() => {
          this.recommendedCategory.map.delete(script.uniqueID);

          if (!script.installVersion) {
            this.select(undefined);
          }
        })
      )
      .subscribe();
  }

  likeScript(script: Script): void {
    this._likeLock.next(true);
    this.scriptSvc
      .location(ScriptLocation.Public)
      .like(script.id)
      .pipe(
        finalize(() => this._likeLock.next(false)),
        tap(() => {
          this._detailRefresh.next();
        })
      )
      .subscribe();
  }

  unlikeScript(script: Script): void {
    this._likeLock.next(true);
    this.scriptSvc
      .location(ScriptLocation.Public)
      .unlike(script.id)
      .pipe(
        finalize(() => this._likeLock.next(false)),
        tap(() => {
          this._detailRefresh.next();
        })
      )
      .subscribe();
  }

  upgradeScript(script: Script): void {
    this._scriptLock.next(true);
    this.scriptSvc
      .location(script.location)
      .upgrade(script.id)
      .pipe(
        tap(() => {
          this._detailRefresh.next();
        })
      )
      .subscribe();
  }

  runScript(script: Script): void {
    this.dialog.open(ScriptInvokeComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new ScriptInvokeParams({
        script: script,
        onSuccess: (task) => {
          this.router.navigate(['/home/tools/scripts/results', task.id]);
        }
      }),
      width: '600px',
      height: 'auto',
      maxWidth: '95vw',
      maxHeight: '95vh'
    });
  }

  giveFeedback(script: Script): void {
    this.dialog.open(ScriptFeedbackComponent, {
      disableClose: true,
      restoreFocus: false,
      data: new ScriptFeedbackParams({
        script: script
      }),
      width: '600px',
      height: 'auto',
      maxWidth: '95vw',
      maxHeight: '95vh'
    });
  }

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.hide();
    setTimeout(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
    }, 50);
    setTimeout(() => {
      tooltip.hide();
    }, 1250);
    setTimeout(() => {
      tooltip.hide();
      tooltip.message = 'Copy';
    }, 1500);
    this.clipboard.copy(text);
  }

  trackCategoryFn(_index: number, category: Category): string {
    return `${category.name}`;
  }

  trackScriptFn(_index: number, script: Script): string {
    return script.uniqueID;
  }

  expandedCount(categories: Category[]): number {
    if (categories.length > this.matExpansionPanels.length) {
      return categories.length;
    }

    return Math.max(1, this.matExpansionPanels.filter((p) => p.expanded).length);
  }

  scrollTo(e: ScriptListItemComponent) {
    e.scrollTo();
  }

  onScrollDown(_event: IInfiniteScrollEvent, category: Category): void {
    category.pulling$
      .pipe(
        take(1),
        tap((pulling) => {
          if (pulling) {
            return;
          }
          if (category.pageIndex * category.limit < category.total) {
            category.pullScripts.next();
          }
        })
      )
      .subscribe();
  }

  updateScriptInList(script: Script): void {
    this.scriptSvc
      .location(script.location)
      .getByID(script.id)
      .pipe(
        tap((s) => {
          if (this.installedCategory.map.has(s.uniqueID)) {
            this.installedCategory.map.set(s.uniqueID, s);
            this.installedCategory.scripts = [...this.installedCategory.map.values()];
          } else if (this.recommendedCategory.map.has(s.uniqueID)) {
            this.recommendedCategory.map.set(s.uniqueID, s);
            this.recommendedCategory.scripts = [...this.recommendedCategory.map.values()];
          }
        })
      )
      .subscribe();
  }

  setFilter(filter: string): void {
    this.filter.nativeElement.value = filter;
  }
}
