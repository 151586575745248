import { Component, ViewChild } from '@angular/core';
import Centrifuge from 'centrifuge';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { BehaviorSubject, iif, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  map,
  mergeMap,
  retry,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { scaleInOutAnimation } from 'src/@vex/animations/scale-in-out.animation';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { TagResponse } from 'src/app/core/services/api.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { TaskService } from 'src/app/core/services/task.service';
import { fadeIn200ms } from 'src/app/shared/animations/fade-in.animation';
import { PacketComplete, PacketStart } from 'src/app/shared/interfaces/packet.interface';
import {
  SpeedtestData,
  SpeedtestDownRunningPacket,
  SpeedtestLatencyPacket,
  SpeedtestServersPacket,
  SpeedtestUpRunningPacket,
  SpeedtestUserPacket
} from 'src/app/shared/interfaces/speed-test.interface';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { deepCopy } from 'src/app/utils/clone';
import { friendlyDataSizeInBits } from 'src/app/utils/data-size';
import { UNDEFINED } from 'src/app/utils/rxjs';

interface formatterOptions {
  globals: {
    seriesTotals: Array<number>;
  };
}

@Component({
  selector: 'mon-speed-test',
  templateUrl: './speed-test.component.html',
  styleUrls: ['./speed-test.component.scss'],
  animations: [fadeIn200ms, scaleInOutAnimation]
})
export class SpeedTestComponent {
  friendlyDataSizeInBits = friendlyDataSizeInBits;
  static MAX = 1000 * 1000 * 1000;
  max = SpeedTestComponent.MAX;
  spin = true;
  completionErr: string;

  maxDownload = 0;
  maxUpload = 0;

  finalUser = true;
  finalServer = true;
  finalLatency = true;
  finalDownload = true;
  finalUpload = true;

  download = 0; //bits
  upload = 0; //bits
  timestamp = new Date();
  latency = 0; //ms
  isp = 'Unknown';
  host = 'Unknown';
  name = 'Unknown';
  country = 'Unknown';
  distance = 0;

  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running.asObservable();

  agent$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );

  readonly channelName$ = this.agent$.pipe(
    map((a) => `$diagnostic:web.${this.stateSvc.tenant.id}.${a.id}.speedtestResponse`)
  );

  lastRunResult$ = (agentID: string) =>
    this.diagnosticsService.getLastSpeedTestResult(agentID).pipe(
      map((response: TagResponse<SpeedtestData>) => {
        const speedTestData: SpeedtestData = response.tag;
        if (!speedTestData) {
          return undefined;
        }

        return {
          download: speedTestData.servers[0].dl_speed * 1_000_000,
          upload: speedTestData.servers[0].ul_speed * 1_000_000,
          timestamp: speedTestData.timestamp,
          latency: speedTestData.servers[0].latency / 1_000_000,
          isp: speedTestData.user_info.Isp,
          name: speedTestData.servers[0].name,
          country: speedTestData.servers[0].country,
          distance: speedTestData.servers[0].distance
        };
      })
    );

  readonly channelHistory$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.history(channel, {
        limit: 60,
        reverse: true
      })
    ),
    catchError(() => {
      return of(<Centrifuge.HistoryResult>{
        publications: [],
        offset: -1,
        epoch: ''
      });
    }),
    map((history) => {
      let result = undefined;
      let start: Date;
      let complete: Date;

      for (let i = history.publications.length - 1; i >= 0; i--) {
        const packet = history.publications[i].data as Packet;

        if (packet.type === PacketType.StartPacket) {
          this.setStart();
          const s: PacketStart = JSON.parse(<string>packet.content);
          result = {
            download: undefined,
            upload: undefined,
            timestamp: s.timestamp,
            latency: undefined,
            isp: undefined,
            name: undefined,
            country: undefined,
            distance: undefined
          };
          complete = undefined;
          start = s.timestamp;
        } else if (start) {
          if (packet.type === PacketType.SpeedtestUserPacket) {
            const p: SpeedtestUserPacket = JSON.parse(<string>packet.content);
            result.isp = p.isp;
          } else if (packet.type === PacketType.SpeedtestServersPacket) {
            const p: SpeedtestServersPacket = JSON.parse(<string>packet.content);
            const server = p.servers[0];
            result.name = server.name;
            result.country = server.country;
            result.distance = server.distance;
            this.finalServer = true;
          } else if (packet.type === PacketType.SpeedtestLatencyPacket) {
            const p: SpeedtestLatencyPacket = JSON.parse(<string>packet.content);
            result.latency = p.latency / 1000_000;
            this.finalLatency = true;
          } else if (packet.type === PacketType.SpeedtestDownRunningPacket) {
            const p: SpeedtestDownRunningPacket = JSON.parse(<string>packet.content);
            result.download = p.downloadRate * 1_000_000;
            this.finalDownload = p.final;
            if (this.download > this.maxDownload) {
              this.maxDownload = this.download;
            }
            this.chart?.updateSeries([
              this.maxDownload ? (this.download / this.maxDownload) * 100 : 0,
              this.maxUpload ? (this.upload / this.maxUpload) * 100 : 0
            ]);
          } else if (packet.type === PacketType.SpeedtestUpRunningPacket) {
            const p: SpeedtestUpRunningPacket = JSON.parse(<string>packet.content);
            result.upload = p.uploadRate * 1_000_000;
            this.finalUpload = p.final;
            if (this.upload > this.maxUpload) {
              this.maxUpload = this.upload;
            }
            this.chart?.updateSeries([
              this.maxDownload ? (this.download / this.maxDownload) * 100 : 0,
              this.maxUpload ? (this.upload / this.maxUpload) * 100 : 0
            ]);
          } else if (packet.type === PacketType.CompletePacket) {
            this._running.next(false);
            const s: PacketComplete = JSON.parse(<string>packet.content);
            complete = s.timestamp;
          } else if (packet.type === PacketType.ErrorPacket) {
            this._running.next(false);
          }
        } else {
          continue;
        }
      }

      if (start) {
        if (!complete) {
          this._running.next(true);
        }
      }

      return result;
    }),
    switchMap((result) => {
      return iif(
        () => !!result,
        of(result),
        this.agent$.pipe(switchMap((agent) => this.lastRunResult$(agent.id)))
      );
    }),
    tap((result) => this.updateResult(result))
  );

  readonly channelListener$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          if (packet.type === PacketType.SpeedtestUserPacket) {
            const p: SpeedtestUserPacket = JSON.parse(<string>packet.content);
            this.isp = p.isp;
            this.finalUser = true;
          } else if (packet.type === PacketType.SpeedtestServersPacket) {
            const p: SpeedtestServersPacket = JSON.parse(<string>packet.content);
            const server = p.servers[0];
            this.name = server.name;
            this.country = server.country;
            this.distance = server.distance;
            this.finalServer = true;
          } else if (packet.type === PacketType.SpeedtestLatencyPacket) {
            const p: SpeedtestLatencyPacket = JSON.parse(<string>packet.content);
            this.latency = p.latency / 1000_000;
            this.finalLatency = true;
          } else if (packet.type === PacketType.SpeedtestDownRunningPacket) {
            const p: SpeedtestDownRunningPacket = JSON.parse(<string>packet.content);
            this.download = p.downloadRate * 1_000_000;
            this.finalDownload = p.final;
            if (this.download > this.maxDownload) {
              this.maxDownload = this.download;
            }
            this.chart.updateSeries([
              this.maxDownload ? (this.download / this.maxDownload) * 100 : 0,
              this.maxUpload ? (this.upload / this.maxUpload) * 100 : 0
            ]);
          } else if (packet.type === PacketType.SpeedtestUpRunningPacket) {
            const p: SpeedtestUpRunningPacket = JSON.parse(<string>packet.content);
            this.upload = p.uploadRate * 1_000_000;
            this.finalUpload = p.final;
            if (this.upload > this.maxUpload) {
              this.maxUpload = this.upload;
            }
            this.chart.updateSeries([
              this.maxDownload ? (this.download / this.maxDownload) * 100 : 0,
              this.maxUpload ? (this.upload / this.maxUpload) * 100 : 0
            ]);
          } else if (packet.type === PacketType.StartPacket) {
            this.setStart();
            const p: PacketStart = JSON.parse(<string>packet.content);
            this.timestamp = p.timestamp;
          } else if (packet.type === PacketType.CompletePacket) {
            this.checkForError(packet);

            setTimeout(() => this._running.next(false), 2000);
          } else if (packet.type === PacketType.ErrorPacket) {
            setTimeout(() => this._running.next(false), 2000);
          }
        })
      )
    )
  );

  readonly latestResult$ = UNDEFINED.pipe(
    tap(() => (this.spin = true)),
    switchMap(() => this.channelHistory$),
    finalize(() => (this.spin = false))
  );

  apexOptions: ApexOptions = {
    colors: ['#5C77FE', '#E91E63'],
    labels: ['Download', 'Upload'],
    series: [
      (this.download / SpeedTestComponent.MAX) * 100,
      (this.upload / SpeedTestComponent.MAX) * 100
    ],
    chart: {
      height: 180,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      type: 'radialBar',
      sparkline: {
        enabled: false
      },
      selection: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: ['#87D4F9']
        // stops: [0, 100]
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 5,
          size: '20%'
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          show: true,
          name: {
            fontSize: '22px'
          },
          value: {
            fontSize: '16px',
            formatter: function (v: number): string {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return `${friendlyDataSizeInBits(
                (v / 8 / 100) * SpeedTestComponent.MAX
              )}ps`;
            }
          },
          total: {
            show: false,
            label: 'Up / Down',
            formatter: function (w: formatterOptions): string {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return `${friendlyDataSizeInBits(
                w.globals.seriesTotals[1]
              )} / ${friendlyDataSizeInBits(
                (w.globals.seriesTotals[0] / 8 / 100) * SpeedTestComponent.MAX
              )}`;
            }
          }
        }
      }
    },
    grid: undefined
  };

  @ViewChild(ChartComponent) chart: ChartComponent;

  constructor(
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private diagnosticsService: DiagnosticsService,
    private taskSvc: TaskService,
    private pubSubSvc: PubSubWsService
  ) {}

  run(agentID: string): void {
    const expireIn = new Date();
    expireIn.setMinutes(expireIn.getMinutes() + 1);

    this.setStart();

    this.diagnosticsService
      .runSpeedTest(agentID)
      .pipe(
        switchMap((response) => {
          const taskID = response.tag;
          let taskUpdateUnixNano = 0;

          return this.taskSvc.getTask(taskID).pipe(
            tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
            switchMap(() =>
              of(taskUpdateUnixNano).pipe(
                // on retries, we make sure to use the updated time
                switchMap((t) => this.taskSvc.getTaskListen(taskID, t))
              )
            ),
            tap((task) => {
              if (!task) {
                throw 'still waiting...';
              } else if (!task.isDone) {
                taskUpdateUnixNano = task.lastUpdateUnixNano;
                throw 'still waiting...';
              }
            }),
            retry(3)
          );
        }),
        delay(1500),
        switchMap(() => (this.download ? UNDEFINED : this.channelHistory$)),
        finalize(() => this._running.next(false))
      )
      .subscribe({
        error: (err) => {
          console.warn(err);
        }
      });
  }

  private setStart(): void {
    this.completionErr = undefined;
    this.maxDownload = 0;
    this.maxUpload = 0;
    this.download = undefined;
    this.upload = undefined;
    this.latency = undefined;
    this.isp = undefined;
    this.timestamp = undefined;
    this.name = undefined;
    this.country = undefined;
    this.distance = undefined;

    this.finalUser = false;
    this.finalLatency = false;
    this.finalServer = false;
    this.finalDownload = false;
    this.finalUpload = false;

    this._running.next(true);
  }

  private checkForError(packet: Packet) {
    if (packet.type === PacketType.CompletePacket && packet.content) {
      const content = JSON.parse(packet.content as string);

      if (content.error) {
        this.completionErr = content.error;
      }
    }
  }

  private updateResult(result) {
    if (result) {
      this.download = result.download;
      this.upload = result.upload;
      this.timestamp = result.timestamp;
      this.latency = result.latency;
      this.isp = result.isp;
      this.name = result.name;
      this.country = result.country;
      this.distance = result.distance;

      this.maxDownload = this.download;
      this.maxUpload = this.upload;

      if (this.chart) {
        this.chart.updateSeries([
          this.maxDownload ? (this.download / this.maxDownload) * 100 : 0,
          this.maxUpload ? (this.upload / this.maxUpload) * 100 : 0
        ]);
      }
    }
  }
}
