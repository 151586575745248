<div *ngIf="tenantAndUser$ | async as resolved" [@listStagger]="2" class="grid grid-flow-row gap-2 pt-2 grid-cols-1 md:grid-cols-3">
  <div class="col-span-1 md:col-span-2">
    <div *ngIf="resolved.userProperties" @fadeInUp class="card overflow-hidden">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
        <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
          <span>About</span>
        </h2>
      </div>
      <div class="px-gutter py-4 grid grid-flow-row grid-cols-1 gap-2 md:grid-cols-2 md:gap-4">
        <div *ngFor="let property of resolved.userProperties" class="flex">
          <div class="flex items-center justify-center h-9 w-9 text-primary bg-primary-light dark:bg-opacity-5 rounded-full mx-4">
            <fa-icon [icon]="['fad', property.icon]" size="lg"></fa-icon>
          </div>
          <div class="flex-1 flex flex-col">
            <div class="m-0 whitespace-pre">{{ property.value }}</div>
            <div class="m-0 text-secondary text-xs">{{ property.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="resolved.joined" @fadeInUp class="card overflow-hidden col-span-1">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-between items-center content-center gap-2">
      <h2 class="headline my-0 pr-4 mr-4 border-r flex-none">
        <span>Tenants</span>
      </h2>
      <div class="flex-1 h-full flex items-center justify-center">
        <div class="bg-card rounded-md border px-2 relative hidden md:flex justify-start items-center content-center h-10">
          <input
            matInput
            #filterInput
            [formControl]="searchCtrl"
            class="pl-2 py-2 border-0 outline-none w-full bg-transparent"
            placeholder="Filter Tenants..."
            autocomplete="off"
        /></div>
      </div>
      <ng-container *ngLet="disableCreateTenant$ | async as disableCreateTenant">
        <div [matTooltip]="disableCreateTenant ? 'You can only own 5 tenants' : null">
          <button mat-button (click)="createTenant()" class="text-primary" [disabled]="disableCreateTenant">New</button>
        </div>
      </ng-container>
    </div>
    <div [@listStagger]="filteredTenants.length + filteredInvites.length" class="px-gutter py-4">
      <div @fadeInRight *ngFor="let tenant of filteredTenants; trackBy: trackByID" class="flex items-center justify-center py-2 relative">
        <div
          *ngIf="tenant.lockInteraction"
          class="z-20 absolute top-0 rounded w-full h-full bg-primary-light transition ease-in-out duration-200"
        >
          <div class="py-4 flex h-full items-center justify-center space-x-2">
            <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
            <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
            <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
          </div>
        </div>
        <div [ngClass]="{ 'opacity-50': tenant.lockInteraction }" class="flex items-center justify-center w-full h-full">
          <div class="flex items-center justify-center h-9 w-9 text-primaryrounded-full mx-1">
            <div *ngIf="tenant.id === resolved.currentTenant.id" class="text-pink">
              <fa-icon [icon]="['fad', 'asterisk']" size="lg" matTooltip="Current Tenant"></fa-icon>
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-center">
            <div class="m-0"
              >{{ tenant.company || tenant.name }}
              <span *ngIf="tenant.company" class="m-0 text-secondary text-xs">{{ tenant.name }}</span>
            </div>
            <div>
              <div [style.font-variant]="'all-small-caps'"
                >{{ tenant.owned ? 'OWNED -' : 'JOINED -' }} <span [ngClass]="getTenantColor(tenant)">{{ tenant.licenseStatus }}</span></div
              >
            </div>
          </div>
          <div class="flex items-center justify-center px-4">
            <div class="flex items-center justify-center gap-2">
              <ng-container
                *ngLet="
                  tenants.length > 1 &&
                  tenant.owned &&
                  (tenant.licenseStatus === 'in-trial' || tenant.licenseStatus === 'cancelled') as canDelete
                "
              >
                <div *ngIf="tenant.owned" [matTooltip]="canDelete ? null : 'Must be an expired or trial subscription'">
                  <button
                    mat-button
                    [disabled]="!canDelete"
                    (click)="removeTenant(tenant)"
                    color="error"
                    [ngClass]="canDelete ? 'hover:bg-red-100' : null"
                    >Remove</button
                  >
                </div>
              </ng-container>
              <button mat-button *ngIf="!tenant.owned" (click)="leaveTenant(tenant)" color="error" class="hover:bg-red-100">Leave</button>
            </div>
          </div>
        </div>
      </div>
      <div
        @fadeInRight
        data-intro-key="tenant-invite"
        *ngFor="let invite of filteredInvites; index as i; trackBy: trackByID"
        class="flex flex-col items-center justify-center py-2 relative"
      >
        <div *ngIf="i === 0" class="flex flex-col items-center justify-center w-full pb-2">
          <div class="flex items-center justify-center gap-2 w-full text-primary text-base">
            <fa-icon [icon]="['fad', 'envelope-open-text']" size="lg" matTooltip="You are invited"></fa-icon>
            <h4 class="py-2">Tenant Invites</h4>
          </div>
          <mat-divider class="w-full"></mat-divider>
        </div>
        <div class="relative w-full h-full">
          <div
            *ngIf="invite.lockInteraction"
            class="z-20 absolute top-0 rounded w-full h-full bg-primary-light transition ease-in-out duration-200"
          >
            <div class="py-4 flex h-full items-center justify-center space-x-2">
              <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
              <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
              <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
            </div>
          </div>
          <div [ngClass]="{ 'opacity-50': invite.lockInteraction }" class="flex items-center justify-center w-full h-full">
            <div class="flex items-center justify-center h-9 w-9 text-primaryrounded-full mx-1">
              <div class="flex items-center justify-center text-primary"> </div>
            </div>
            <div class="flex-1 flex flex-col justify-center">
              <div class="m-0"
                >{{ invite.company || invite.name }}
                <span *ngIf="invite.company" class="m-0 text-secondary text-xs">{{ invite.name }}</span>
              </div>
              <div>
                <div [style.font-variant]="'all-small-caps'"
                  >INVITED<span [ngClass]="getTenantColor(invite)">{{ invite.licenseStatus }}</span></div
                >
              </div>
            </div>
            <div class="flex items-center justify-center px-4">
              <div class="flex items-center justify-center gap-2">
                <div
                  (click)="joinTenant(invite)"
                  matRipple
                  class="text-green hover:bg-green-light p-2 rounded trans-ease-out cursor-pointer"
                  >Join</div
                >
                <div (click)="declineTenant(invite)" matRipple class="text-red hover:bg-red-light p-2 rounded trans-ease-out cursor-pointer"
                  >Decline</div
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        @fadeInRight
        *ngIf="filteredTenants.length === 0 && filteredInvites.length === 0 && filterInput.value"
        class="text-secondary italic px-2"
      >
        No results found for '{{ filterInput.value }}'
      </div>
    </div>
  </div>
</div>
