import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Services } from 'src/app/shared/models/service.model';

import { ApiService, Response } from './api.service';

@Injectable({ providedIn: 'root' })
export class ServiceService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(agentID: string): Observable<Services> {
    const url = `${this.apiUrl}/agent/${agentID}/service`;
    return this.http.get<Services>(url).pipe(
      tap(() => this.log('fetched ServiceService.getAll response')),
      map((response) => (response ? new Services(response) : undefined)),
      catchError((err) => {
        this.handleError<Services>('ServiceService.getAll');
        return throwError(() => err);
      })
    );
  }

  stop(agentID: string, serviceName: string): Observable<Response> {
    this.log(`Stopping service: ${serviceName} on ${agentID}`);
    const url = `${this.apiUrl}/agent/${agentID}/service/${serviceName}/stop`;
    return this.http.post<Response>(url, {}).pipe(
      tap(() => this.log('fetched ServiceService.stop response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('ServiceService.stop');
        return throwError(() => err);
      })
    );
  }

  start(agentID: string, serviceName: string): Observable<Response> {
    this.log(`Starting service: ${serviceName} on ${agentID}`);
    const url = `${this.apiUrl}/agent/${agentID}/service/${serviceName}/start`;
    return this.http.post<Response>(url, {}).pipe(
      tap(() => this.log('fetched ServiceService.start response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('ServiceService.start');
        return throwError(() => err);
      })
    );
  }

  restart(agentID: string, serviceName: string): Observable<Response> {
    this.log(`Restarting service: ${serviceName} on ${agentID}`);
    const url = `${this.apiUrl}/agent/${agentID}/service/${serviceName}/restart`;
    return this.http.post<Response>(url, {}).pipe(
      tap(() => this.log('fetched ServiceService.restart response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('ServiceService.restart');
        return throwError(() => err);
      })
    );
  }

  disable(agentID: string, serviceName: string): Observable<Response> {
    this.log(`Disabling service: ${serviceName} on ${agentID}`);
    const url = `${this.apiUrl}/agent/${agentID}/service/${serviceName}/disable`;
    return this.http.post<Response>(url, {}).pipe(
      tap(() => this.log('fetched ServiceService.disable response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('ServiceService.disable');
        return throwError(() => err);
      })
    );
  }

  delete(agentID: string, serviceName: string): Observable<Response> {
    this.log(`Deleting service: ${serviceName} on ${agentID}`);
    const url = `${this.apiUrl}/agent/${agentID}/service/${serviceName}/delete`;
    return this.http.delete<Response>(url).pipe(
      tap(() => this.log('fetched ServiceService.delete response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('ServiceService.delete');
        return throwError(() => err);
      })
    );
  }

  pause(agentID: string, serviceName: string): Observable<Response> {
    this.log(`Pausing service: ${serviceName} on ${agentID}`);
    const url = `${this.apiUrl}/agent/${agentID}/service/${serviceName}/pause`;
    return this.http.post<Response>(url, {}).pipe(
      tap(() => this.log('fetched ServiceService.pause response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError<Response>('ServiceService.pause');
        return throwError(() => err);
      })
    );
  }
}
