<div
  class="flex items-start pl-0 m-1 ml-0 rounded-md rounded-l-none  cursor-pointer border-l-2"
  [ngClass]="{
    'hover:bg-hover': showHover,
    'bg-gray bg-opacity-20': selected,
    'opacity-50': script.disabled
  }"
  #item
>
  <div class="flex w-full">
    <div
      [attr.data-intro-key]="script.location === scriptLocation.Public ? 'script-store-marker' : ''"
      class="w-1 mr-2"
      [ngClass]="script.location === scriptLocation.Public ? 'bg-blue-300' : ''"
    ></div>
    <div class="flex justify-between w-full p-2 pl-0">
      <div class="flex-1 flex flex-col">
        <div class="flex items-center">
          <div class="flex-1 text-sm cursor-pointer">
            {{ script.name }} <span class="text-2xs text-secondary" matTooltip="Version">v{{ script.latestVersion }}</span>
          </div>
          <ng-container *ngIf="!script.installVersion">
            <div data-intro-key="script-store-stats" class="flex">
              <div matTooltip="Public installs" class="text-2xs text-secondary px-1 flex items-center justify-center">
                <fa-icon class="pr-1" [icon]="['fad', 'cloud-download']"></fa-icon>{{ script.installs }}
              </div>
              <div matTooltip="Public rating" class="text-2xs text-secondary pl-1 flex items-center justify-center">
                <fa-icon class="pr-1" [icon]="['fad', 'thumbs-up']"></fa-icon>{{ script.likes }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="text-secondary text-xs">{{ script.description }}</div>
        <div class="flex justify-between">
          <div class="flex-1 text-secondary text-xs">{{ script.publisher }}</div>
        </div>
      </div>
      <ng-container *ngIf="script.installVersion">
        <div class="flex flex-col items-end self-stretch justify-between">
          <button
            *ngIf="menu"
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ script }"
            (click)="$event.stopPropagation()"
            matTooltip="Manage"
            class="text-gray-400"
          >
            <fa-icon size="lg" class="" [icon]="['fad', 'cog']"></fa-icon>
          </button>
          <ng-container *ngIf="script.disabled; else enabled">
            <div class="text-secondary text-xs italic" [style.font-variant]="'small-caps'">DISABLED</div>
          </ng-container>
          <ng-template #enabled>
            <fa-icon
              *ngIf="script.hasUpgrade"
              matTooltip="Update available"
              size="lg"
              class="text-red dark:text-red-400"
              [icon]="['fad', 'seal-exclamation']"
            ></fa-icon>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
