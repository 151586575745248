<div class="p-4">
  <p class="text-lg pb-4"
    >This agent isn't responding to console requests. Here are some things you can verify on the machine running the agent:</p
  >

  <ul class="list-disc list-inside">
    <li>Is the agent still installed on the machine?</li>
    <li>Is the agent service running?</li>
    <li
      ><a [routerLink]="['/home/inventory/blocked']" class="text-primary">Check your list of blocked machines</a>. Is the agent
      service being blocked by IP?</li
    >
    <li>Is there an internet connection to the machine?</li>
    <li>Is the machine clock synchronized within 10 seconds?</li>
    <li>Are there any clues in the logs located on the machine running the agent?</li>
  </ul>

  <p class="pt-5">While you investigate, we'll keep trying to connect.</p>
</div>
