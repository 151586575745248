<div class="px-4 md:px-0 flex flex-col items-center justify-center gap-20 text-center">
  <div class="h-10"></div>
  <div class="flex flex-col items-center justify-center gap-20">
    <div class="flex flex-col items-center justify-center gap-4">
      <img class="w-32 select-none" src="assets/img/demo/logo.svg" />
      <div class="headline m-0 text-secondary text-3xl">CommandCTRL</div>
      <div class="flex flex-col items-start justify-start">
        <!-- <div class="headline m-0 text-secondary text-2xl">Page not found.</div> -->
        <div class="headline m-0 text-secondary text-lg text-left">requested URL {{ router.url }} was not found</div>
        <!-- <div class="headline m-0 text-secondary text-lg">requested URL {{ path }} was not found</div> -->
        <a [routerLink]="['/']" class="text-primary">go home</a>
        <!-- <button onclick="history.go(-1)" class="text-primary">go back</button> -->
      </div>
    </div>
    <div class="flex text-4xl md:text-6xl font-bold text-secondary">
      <div>(╯°□°)╯</div>
      <div>︵</div>
      <div class="flex flex-col">
        <div>┻━┻</div>
        <div style="transform: rotate(-180deg)">404</div>
      </div>
    </div>
  </div>
</div>
