import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Action } from 'src/app/shared/models/action.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ActionService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(
    limit: number,
    page: number,
    orderBy: OrderBy[]
  ): Observable<PagedResult<Action>> {
    const url = `${this.apiUrl}/action`;
    const params = {};
    Object.assign(params, {
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Action>>(url, { observe: 'response', params }).pipe(
      tap(() =>
        this.log(
          `fetched ActionService.getAll response in ${performance.now() - start}ms`
        )
      ),
      map((response) =>
        response
          ? new PagedResult<Action>(Action, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Action>>('ActionService.getAll');
        return throwError(() => err);
      })
    );
  }
}
