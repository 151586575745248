<div *ngIf="dataSource.connect() | async" class="card h-full">
  <div class="border-b p-2 flex justify-start items-center content-center">
    <fa-icon [ngClass]="iconClass" [icon]="['fad', icon]" size="2x" class="mr-2"> </fa-icon>

    <h2 tipOnEllip class="m-0 title flex-auto truncate">{{ header }}</h2>

    <div class="flex items-center justify-center">
      <button
        mat-icon-button
        type="button"
        queryParamsHandling="preserve"
        class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 sm-icon-button"
        (click)="routeTo()"
        [matTooltip]="linkTip"
      >
        <fa-icon [icon]="['fad', 'external-link']" class="flex items-center justify-center"> </fa-icon>
      </button>

      <mon-display-config-button
        *ngIf="_displayConfig"
        persistAt="User"
        [config]="_displayConfig"
        class="flex items-center justify-center text-sm w-8 h-8"
      ></mon-display-config-button>
    </div>
  </div>

  <div class="pb-4">
    <table [dataSource]="dataSource" class="w-full" [matSortActive]="sortField" [matSortDirection]="sortDirection" mat-table matSort #table>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td tipOnEllip *matCellDef="let row" mat-cell>
            <div [ngClass]="column.cellClasses">{{ row[column.display || column.property] }}</div>
          </td>
        </ng-container>
      </ng-container>

      <!-- Number Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ row[column.display || column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Button Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <div class="flex float-right pr-2">
              <button
                (click)="$event.stopPropagation(); column.buttonFn(row); (false)"
                class="w-8 h-8 leading-none flex items-center justify-center hover:bg-red-light transition duration-200 ease-in-out xs-icon-button"
                mat-icon-button
                [matTooltip]="column.tooltip"
              >
                <fa-icon [icon]="['fad', column.buttonIconName]" class="text-red"> </fa-icon>
              </button>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr> -->
      <ng-container *matRowDef="let row; columns: visibleColumns">
        <tr
          (click)="rowClick(row); (false)"
          mat-row
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          [class.highlighted-row]="row.highlight"
        ></tr>
      </ng-container>
    </table>
  </div>
</div>
