import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export function fadeInRightAnimation(duration: number): AnimationTriggerMetadata {
  return trigger('fadeInRight', [
    transition(':enter', [
      style({
        transform: 'translateX(-20px)',
        opacity: 0
      }),
      animate(
        `${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`,
        style({
          transform: 'translateX(0)',
          opacity: 1
        })
      )
    ])
  ]);
}

export const fadeInRight400ms = fadeInRightAnimation(400);
export const fadeInRight80ms = fadeInRightAnimation(80);
export const fadeInRight40ms = fadeInRightAnimation(40);

export function fadeOutRightAnimation(duration: number): AnimationTriggerMetadata {
  return trigger('fadeOutRight', [
    transition(':leave', [
      style({
        transform: 'translateX(0)',
        opacity: 1
      }),
      animate(
        `${duration}ms cubic-bezier(0.35, 0, 0.25, 1)`,
        style({
          transform: 'translateX(20px)',
          opacity: 0
        })
      )
    ])
  ]);
}

export const fadeOutRight400ms = fadeOutRightAnimation(400);
