import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BaseService } from './base.service';

export enum ServiceState {
  Stopped = 1,
  Started = 2,
  Stopping = 3,
  Starting = 4,
  Paused = 5
}

export class NoContent {}

export class Response {
  message: string;
}

export class TagResponse<T> extends Response {
  tag: T;

  constructor(init?: Partial<unknown>) {
    super();
    if (!init) {
      return;
    }

    Object.assign(this, init);

    if (typeof init['tag'] === 'string') {
      this.tag = JSON.parse(init['tag']);
    }
  }
}

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  public static BASE_URL: string = environment.baseUrl;
  public static BASE_CF_URL: string = environment.cfURL;
  public static PUBLIC_URL: string = ApiService.BASE_URL + '/public';
  public static API_URL: string = ApiService.BASE_URL + '/api';
  public static BASE_WS_URL = `https://${environment.wsHost}`;
  protected baseUrl: string;
  protected apiUrl: string;
  protected publicUrl: string;
  protected cfUrl: string;
  protected baseWSUrl: string;

  constructor() {
    super();
    this.baseUrl = ApiService.BASE_URL;
    this.apiUrl = ApiService.API_URL;
    this.publicUrl = ApiService.PUBLIC_URL;
    this.cfUrl = ApiService.BASE_CF_URL;
    this.baseWSUrl = ApiService.BASE_WS_URL;
  }
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      if (error.error) {
        return of(error.error);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
