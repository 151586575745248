import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import {
  ScriptResult,
  ScriptResultTarget
} from 'src/app/shared/interfaces/script.interface';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Permissions } from 'src/app/shared/models/permissions.model';
import {
  Script,
  ScriptChangelog,
  ScriptLocation
} from 'src/app/shared/models/script.model';
import { Task } from 'src/app/shared/models/task.model';
import { getShellVersion } from 'src/app/utils/powershell';

import { ApiService, Response, TagResponse } from './api.service';

interface Upload {
  comments: object;
  signed: boolean;
  filename: string;
}

export interface NewScript {
  id: string;
  location: ScriptLocation;
  name: string;
  description: string;
  detail: string;
  filename: string;
  parameters: unknown;
  tags: string[];
  permissions: {
    roles: Map<string, number>;
    users: Map<string, number>;
  };
}

export interface PatchScript {
  location: ScriptLocation;
  name: string;
  description: string;
  detail: string;
  filename: string;
  parameters: unknown;
  tags: string[];
  changelog: string[];
}

export interface PatchScriptPerm {
  id: string;
  type: string;
  value: number;
}

class Location extends ApiService {
  location: ScriptLocation;
  get locationString(): string {
    return this.location === ScriptLocation.Private ? '/tenant' : '';
  }

  constructor(l: ScriptLocation, private http: HttpClient) {
    super();

    this.location = l;
  }

  getRecommended(
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<Script>> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }
    const url = `${this.apiUrl}${this.locationString}/script/recommended`;
    const params = {};
    Object.assign(params, {
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Script>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.getRecommended response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) =>
        response
          ? new PagedResult<Script>(Script, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.getRecommended');
        return throwError(() => err);
      })
    );
  }

  getAll(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<Script>> {
    const url = `${this.apiUrl}${this.locationString}/script`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Script>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.getAll response in ${performance.now() - start}ms`
        );
      }),
      map((response) =>
        response
          ? new PagedResult<Script>(Script, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.getAll');
        return throwError(() => err);
      })
    );
  }

  getCodeByID(id: string): Observable<string> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}/code`;
    const start = performance.now();
    return this.http.get(url, { responseType: 'text' }).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.getCodeByID [${id}] response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((c) => c.replace(/\t/gi, '  ')), // replace tabs with 2 spaces
      catchError((err) => {
        this.handleError<string>('ScriptService.getCodeByID');
        return throwError(() => err);
      })
    );
  }

  getPermsByID(installedRefID: string): Observable<Permissions> {
    const url = `${this.apiUrl}${this.locationString}/script/installed/${installedRefID}/perms`;
    return this.http.get(url).pipe(
      map((results) => new Permissions(results)),
      catchError((err) => {
        this.handleError<string>('ScriptService.getPermsByID');
        return throwError(() => err);
      })
    );
  }

  getChangelogByID(id: string): Observable<Map<string, ScriptChangelog>> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}/changelog`;
    const start = performance.now();
    return this.http.get<Map<string, ScriptChangelog>>(url).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.getChangelogByID [${id}] response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) => {
        const map = new Map<string, ScriptChangelog>();
        Object.keys(response).map((k) => map.set(k, new ScriptChangelog(response[k])));
        return map;
      }),
      catchError((err) => {
        this.handleError<string>('ScriptService.getChangelogByID');
        return throwError(() => err);
      })
    );
  }

  getByID(id: string): Observable<Script> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}`;
    const start = performance.now();
    return this.http.get<Script>(url).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.getByID [${id}] response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) => (response ? new Script(response) : undefined)),
      catchError((err) => {
        this.handleError<Script>('ScriptService.getByID');
        return throwError(() => err);
      })
    );
  }

  getByInstallRefID(installRefID: string): Observable<Script> {
    if (this.location == ScriptLocation.Public) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/installed/${installRefID}`;
    const start = performance.now();
    return this.http.get<Script>(url).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.getByInstallRefID [${installRefID}] response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) => (response ? new Script(response) : undefined)),
      catchError((err) => {
        this.handleError<Script>('ScriptService.getByInstallRefID');
        return throwError(() => err);
      })
    );
  }

  create(newScript: NewScript): Observable<Response> {
    const url = `${this.apiUrl}${this.locationString}/script`;
    const start = performance.now();
    return this.http.post<Response>(url, newScript).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.create response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError('ScriptService.create');
        return throwError(() => err);
      })
    );
  }

  invoke(
    installedRefID: string,
    targets: string[],
    customArgs: string,
    args: unknown
  ): Observable<TagResponse<Task>> {
    if (this.location == ScriptLocation.Public) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/installed/${installedRefID}/invoke`;
    const start = performance.now();
    return this.http.post<TagResponse<Task>>(url, { targets, customArgs, args }).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.invoke response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Response>('ScriptService.invoke');
        return throwError(() => err);
      })
    );
  }

  update(id: string, patchScript: PatchScript): Observable<Response> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}`;
    const start = performance.now();
    return this.http.patch<Response>(url, patchScript).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.update response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError('ScriptService.update');
        return throwError(() => err);
      })
    );
  }

  upgrade(id: string): Observable<Response> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/upgrade`;
    const start = performance.now();
    return this.http.patch<Response>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.upgradeInstall response in ${
            performance.now() - start
          }ms`
        );
      }),
      catchError((err) => {
        this.handleError('ScriptService.upgradeInstall');
        return throwError(() => err);
      })
    );
  }

  delete(id: string, force = false): Observable<Script> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}`;
    const params = {};
    Object.assign(params, { force });
    const start = performance.now();
    return this.http.delete<Script>(url, { params }).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.delete response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Script>('ScriptService.delete');
        return throwError(() => err);
      })
    );
  }

  archive(id: string): Observable<Response> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/archive`;
    const start = performance.now();
    return this.http.patch<Response>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.archive response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Script>('ScriptService.archive');
        return throwError(() => err);
      })
    );
  }

  install(id: string): Observable<Script> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/install`;
    const start = performance.now();
    return this.http.post<Script>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.install response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Script>('ScriptService.install');
        return throwError(() => err);
      })
    );
  }

  uninstall(id: string): Observable<Script> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/uninstall`;
    const start = performance.now();
    return this.http.post<Script>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.uninstall response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Script>('ScriptService.uninstall');
        return throwError(() => err);
      })
    );
  }

  like(id: string): Observable<Response> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/like`;
    const start = performance.now();
    return this.http.post<Response>(url, {}).pipe(
      tap(() => {
        this.log(`fetched ScriptService.like response in ${performance.now() - start}ms`);
      }),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.like');
        return throwError(() => err);
      })
    );
  }

  unlike(id: string): Observable<Response> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/unlike`;
    const start = performance.now();
    return this.http.post<Response>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.unlike response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.unlike');
        return throwError(() => err);
      })
    );
  }

  feedback(id: string, feedback: string): Observable<Response> {
    if (this.location == ScriptLocation.Private) {
      throw new Error('not implemented');
    }

    const url = `${this.apiUrl}${this.locationString}/script/${id}/feedback`;
    const start = performance.now();
    return this.http.post<Response>(url, { feedback }).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.feedback response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.feedback');
        return throwError(() => err);
      })
    );
  }

  disable(id: string): Observable<Response> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}/disable`;
    const start = performance.now();
    return this.http.patch<Response>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.disable response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.disable');
        return throwError(() => err);
      })
    );
  }

  enable(id: string): Observable<Response> {
    const url = `${this.apiUrl}${this.locationString}/script/${id}/enable`;
    const start = performance.now();
    return this.http.patch<Response>(url, {}).pipe(
      tap(() => {
        this.log(
          `fetched ScriptService.enable response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<Array<Script>>('ScriptService.enable');
        return throwError(() => err);
      })
    );
  }

  setPermByID(installedRefID: string, perm: PatchScriptPerm): Observable<Response> {
    const url = `${this.apiUrl}${this.locationString}/script/installed/${installedRefID}/perms`;
    return this.http.patch<Response>(url, perm).pipe(
      catchError((err) => {
        this.handleError<string>('ScriptService.setPermByID');
        return throwError(() => err);
      })
    );
  }

  deletePermByID(installedRefID: string, perm: PatchScriptPerm): Observable<Response> {
    const url = `${this.apiUrl}${this.locationString}/script/installed/${installedRefID}/perms`;
    const params = {};
    Object.assign(params, { params: perm });
    return this.http.delete<Response>(url, params).pipe(
      catchError((err) => {
        this.handleError<string>('ScriptService.deletePermByID');
        return throwError(() => err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(private http: HttpClient) {}

  private _location = new Map<ScriptLocation, Location>();

  public location(l: ScriptLocation) {
    if (!this._location.has(l)) {
      this._location.set(l, new Location(l, this.http));
    }

    return this._location.get(l);
  }

  public uploadScript(data: FormData): Observable<HttpEvent<Upload>> {
    const url = `${ApiService.API_URL}/script/upload`;
    return this.http.post<Upload>(url, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public getResult(resultID: string): Observable<ScriptResult> {
    const url = `${ApiService.API_URL}/script/result/${resultID}`;
    return this.http.get<Task>(url).pipe(
      map((task) => {
        if (task) {
          const action = task.actions[0];
          return {
            created: new Date(task.timestamp),
            id: task.id,
            scriptID: action.params.scriptID,
            scriptName: action.params.scriptName,
            scriptVersion: action.params.scriptVersion,
            targets: [...Object.keys(task.targets)],
            isDone: task.state > 1,
            invokedBy: task.createdByUserEmail,
            lastUpdate: new Date(task.lastUpdate),
            lastUpdateUnixNano: task.lastUpdateUnixNano,
            state: task.state,
            targetTotal: task.targetTotal,
            targetSuccess: task.targetSuccess,
            targetError: task.targetError,
            targetAborted: task.targetAborted,
            targetTimeout: task.targetTimeout
          };
        }

        return undefined;
      })
    );
  }

  public getResultListen(
    taskID: string,
    newerThanUnixNano: number | string
  ): Observable<ScriptResult> {
    const url = `${ApiService.API_URL}/script/result/${taskID}/listen`;
    const params = {};
    if (newerThanUnixNano) {
      Object.assign(params, { newerThanUnixNano });
    }
    return this.http.get<Task>(url, { params }).pipe(
      map((t) => {
        if (t) {
          const action = t.actions[0];
          return {
            created: new Date(t.timestamp),
            id: t.id,
            scriptID: action.params.scriptID,
            scriptName: action.params.scriptName,
            scriptVersion: action.params.scriptVersion,
            targets: [...Object.keys(t.targets)],
            isDone: t.state > 1,
            invokedBy: t.createdByUserEmail,
            lastUpdate: new Date(t.lastUpdate),
            lastUpdateUnixNano: t.lastUpdateUnixNano,
            state: t.state,
            targetTotal: t.targetTotal,
            targetSuccess: t.targetSuccess,
            targetError: t.targetError,
            targetAborted: t.targetAborted,
            targetTimeout: t.targetTimeout
          };
        }
        return undefined;
      })
    );
  }

  public getAllResults(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<ScriptResult>> {
    const url = `${ApiService.API_URL}/script/result`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    return this.http.get<Array<Task>>(url, { observe: 'response', params }).pipe(
      map((response) => {
        if (response) {
          const items = response.body.map<ScriptResult>((i) => {
            const action = i.actions[0];
            return {
              created: new Date(i.timestamp),
              id: i.id,
              scriptID: action.params.scriptID,
              scriptName: action.params.scriptName,
              scriptVersion: action.params.scriptVersion,
              targets: [...Object.keys(i.targets)],
              isDone: i.state > 1,
              invokedBy: i.createdByUserEmail,
              lastUpdate: new Date(i.lastUpdate),
              lastUpdateUnixNano: i.lastUpdateUnixNano,
              state: i.state,
              targetTotal: i.targetTotal,
              targetSuccess: i.targetSuccess,
              targetError: i.targetError,
              targetAborted: i.targetAborted,
              targetTimeout: i.targetTimeout
            };
          });

          return new PagedResult<ScriptResult>(null, {
            count: +response.headers.get('Pagination-Count'),
            items: items,
            page: page,
            limit: limit
          });
        }

        return undefined;
      })
    );
  }

  public getAllResultsListen(
    newerThanUnixNano: number | string
  ): Observable<ScriptResult[]> {
    const url = `${ApiService.API_URL}/script/result/listen`;
    const params = {};
    if (newerThanUnixNano) {
      Object.assign(params, { newerThanUnixNano });
    }
    return this.http.get<Array<Task>>(url, { params }).pipe(
      map((response) =>
        response
          ? response.map<ScriptResult>((i) => {
              const action = i.actions[0];
              return {
                created: new Date(i.timestamp),
                id: i.id,
                scriptID: action.params.scriptID,
                scriptName: action.params.scriptName,
                scriptVersion: action.params.scriptVersion,
                targets: [...Object.keys(i.targets)],
                isDone: i.state > 1,
                invokedBy: i.createdByUserEmail,
                lastUpdate: new Date(i.lastUpdate),
                lastUpdateUnixNano: i.lastUpdateUnixNano,
                state: i.state,
                targetTotal: i.targetTotal,
                targetSuccess: i.targetSuccess,
                targetError: i.targetError,
                targetAborted: i.targetAborted,
                targetTimeout: i.targetTimeout
              };
            })
          : undefined
      )
    );
  }

  public getResultTargetByIDs(
    taskID: string,
    targetIDs: string[]
  ): Observable<ScriptResultTarget[]> {
    const url = `${ApiService.API_URL}/script/result/${taskID}/targets`;
    const params = {};
    Object.assign(params, {
      targetIDs
    });
    return this.http.get<Array<object>>(url, { params }).pipe(
      map((response) => {
        if (response) {
          return response.map<ScriptResultTarget>((i) => {
            const tags = i['tags'] || {};
            return {
              agentID: i['agentID'],
              arguments: tags.arguments || '',
              error: tags.error || '',
              exitCode: tags.exitCode || 0,
              logs: i['logs'],
              output: tags.output || '',
              outputTruncated: tags.outputTruncated || false,
              shellVersion: tags.version,
              started: i['processingTimestamp']
                ? new Date(i['processingTimestamp'])
                : undefined,
              state: i['state'],
              updated: i['updated'] ? new Date(i['updated']) : undefined
            };
          });
        }
        return undefined;
      })
    );
  }

  public getResultTargets(
    taskID: string,
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<ScriptResultTarget>> {
    const url = `${ApiService.API_URL}/script/result/${taskID}/targets`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    return this.http.get<Array<object>>(url, { observe: 'response', params }).pipe(
      map((response) => {
        if (response) {
          const items = response.body.map<ScriptResultTarget>((i) => {
            const tags = i['tags'] || {};
            return {
              agentID: i['agentID'],
              arguments: tags.arguments || '',
              error: tags.error || '',
              exitCode: tags.exitCode || 0,
              logs: i['logs'],
              output: tags.output || '',
              outputTruncated: tags.outputTruncated || false,
              shellVersion: tags.version,
              started: i['processingTimestamp']
                ? new Date(i['processingTimestamp'])
                : undefined,
              state: i['state'],
              updated: i['updated'] ? new Date(i['updated']) : undefined
            };
          });
          return new PagedResult<ScriptResultTarget>(null, {
            count: +response.headers.get('Pagination-Count'),
            items: items,
            page: page,
            limit: limit
          });
        }
        return undefined;
      })
    );
  }

  public getResultTargetsListen(
    taskID: string,
    newerThanUnixNano: number | string
  ): Observable<ScriptResultTarget[]> {
    const url = `${ApiService.API_URL}/script/result/${taskID}/targets/listen`;
    const params = {};
    if (newerThanUnixNano) {
      Object.assign(params, { newerThanUnixNano });
    }
    return this.http.get<Array<object>>(url, { params }).pipe(
      map((response) =>
        response
          ? response.map<ScriptResultTarget>((i) => {
              const tags = i['tags'] || {};
              return {
                agentID: i['agentID'],
                arguments: tags.arguments || '',
                error: tags.error || '',
                exitCode: tags.exitCode || 0,
                logs: i['logs'],
                output: tags.output || '',
                outputTruncated: tags.outputTruncated || false,
                shellVersion: tags.version,
                started: i['processingTimestamp']
                  ? new Date(i['processingTimestamp'])
                  : undefined,
                state: i['state'],
                updated: i['updated'] ? new Date(i['updated']) : undefined
              };
            })
          : undefined
      )
    );
  }

  public getResultTargetDetail(
    taskID: string,
    targetID: string
  ): Observable<ScriptResultTarget> {
    const url = `${ApiService.API_URL}/script/result/${taskID}/targets/${targetID}`;
    return this.http.get<object>(url).pipe(
      map((response) => {
        const tags = response['tags'] || {};
        return {
          agentID: response['agentID'],
          arguments: tags.arguments || '',
          error: tags.error || '',
          exitCode: tags.exitCode || 0,
          logs: response['logs'] || undefined,
          output: tags.output || '',
          outputTruncated: tags.outputTruncated || false,
          shellVersion: getShellVersion(tags.version || {}),
          started: response['processingTimestamp']
            ? new Date(response['processingTimestamp'])
            : undefined,
          state: response['state'],
          updated: response['updated'] ? new Date(response['updated']) : undefined
        };
      })
    );
  }
}
