import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Session } from 'src/app/shared/models/session.model';

@Component({
  selector: 'mon-environment-variables-display',
  templateUrl: './environment-variables-display.component.html',
  styleUrls: ['./environment-variables-display.component.scss']
})
export class EnvironmentVariablesDisplayComponent {
  envars: Map<string, string> = new Map<string, string>();

  constructor(
    public dialogRef: MatDialogRef<EnvironmentVariablesDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public session: Session
  ) {
    this.envars = session.getEnvironmentVariablesAsMap();
  }
}
