import { Updatable } from '../interfaces/updatable.interface';

export interface Field {
  value: string;
  viewValue: string;
  metricUnit?: string;
}

export interface FieldGroup {
  disabled?: boolean;
  name: string;
  field: Field[];
}

export interface Op {
  name: string;
  description: string;
}

export const FieldGroups: FieldGroup[] = [
  {
    name: 'System',
    field: [
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.FramesPerSec',
        viewValue: 'Display Protocol Frames Per Second',
        metricUnit: 'FPS'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.PacketLossUplink',
        viewValue: 'Display Protocol Packet Loss Uplink',
        metricUnit: '%'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.BaseUDPRtt',
        viewValue: 'Display Protocol UDP Latency',
        metricUnit: 'ms'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.BaseTCPRtt',
        viewValue: 'Display Protocol TCP Latency',
        metricUnit: 'ms'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.Latency',
        viewValue: 'Display Protocol HDX Latency',
        metricUnit: 'ms'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.ImagingActiveMinQuality',
        viewValue: 'Display Protocol Image Quality',
        metricUnit: '%'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.FrameQuality',
        viewValue: 'Display Protocol Frame Quality',
        metricUnit: '%'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.ImagingEncodedFramesPerSec',
        viewValue: 'Display Protocol Image Encoded Frames Per Second',
        metricUnit: 'FPS'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.Rtt',
        viewValue: 'Display Protocol Blast Latency',
        metricUnit: 'ms'
      },
      {
        value: 'Summary.System.Sessions.Tags.displayProtocol.RoundTripLatencyMS',
        viewValue: 'Display Protocol PCoIP Latency',
        metricUnit: 'ms'
      },
      {
        value: 'Summary.System.Batteries.RemainingPercentage',
        viewValue: 'Battery Remaining Percentage',
        metricUnit: '%'
      }
    ]
  },
  {
    name: 'Processes',
    field: [
      {
        value: 'Processes.CPUPercent',
        viewValue: 'Process CPU Percent',
        metricUnit: '%'
      }
    ]
  },
  {
    name: 'CPU',
    field: [
      {
        value: 'Summary.CPU.UtilizationPercent',
        viewValue: 'CPU Utilization Percent',
        metricUnit: '%'
      }
    ]
  },
  {
    name: 'GPU',
    field: [
      {
        value: 'Summary.GPU.Metrics.UsagePercent',
        viewValue: 'GPU Utilization Percent',
        metricUnit: '%'
      }
    ]
  },
  {
    name: 'Memory',
    field: [
      {
        value: 'Summary.Memory.UtilizationPercent',
        viewValue: 'Memory Utilization Percent',
        metricUnit: '%'
      }
    ]
  },
  {
    name: 'Disk',
    field: [
      {
        value: 'Summary.Disk.Disks.Latency',
        viewValue: 'Disk Average Response Time',
        metricUnit: 'ms'
      }
    ]
  },
  {
    name: 'Network',
    field: [
      {
        value: 'Summary.Network.EthernetAdapters.GatewayPing.AvgRtt',
        viewValue: 'Ethernet Latency',
        metricUnit: 'ns'
      },
      {
        value: 'Summary.Network.WifiAdapters.SignalQuality',
        viewValue: 'Wi-Fi Strength',
        metricUnit: '%'
      },
      {
        value: 'Summary.Network.WifiAdapters.GatewayPing.AvgRtt',
        viewValue: 'Wi-Fi Latency',
        metricUnit: 'ns'
      },
      {
        value: 'Summary.Network.WifiAdapters.InterfaceState',
        viewValue: 'Wi-Fi State'
      }
    ]
  }
];

export const Ops: Op[] = [
  { name: '>', description: 'greater than' },
  { name: '>=', description: 'greater than or equal to' },
  { name: '<', description: 'less than' },
  { name: '<=', description: 'less than or equal to' },
  { name: '==', description: 'equal to' },
  { name: '!=', description: 'not equal to' }
];

export class TriggerCondition {
  public field: string;
  public op: string;
  public value: unknown;
  public intervalDuration: number;

  constructor(init?: Partial<TriggerCondition>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }

  isMet(value: unknown): boolean {
    switch (this.op) {
      case '>':
        return value > this.value;
      case '>=':
        return value >= this.value;
      case '<':
        return value < this.value;
      case '<=':
        return value <= this.value;
      case '==':
        return value == this.value;
      case '!=':
        return value != this.value;
    }

    return false;
  }
}

export class Trigger implements Updatable<Trigger> {
  public id: string;
  public name: string;
  public description: string;
  public conditions: Array<TriggerCondition>;
  public extraParameters: { [key: string]: unknown };
  public system: boolean;
  public disabled: boolean;
  public timestamp: Date;
  public created: Date;

  private _conditionsD: string = undefined;
  get conditionsD(): string {
    if (this._conditionsD) {
      return this._conditionsD;
    }

    let conditions = '';

    this.conditions.forEach((c, i) => {
      if (i > 0) {
        conditions += ' and ';
      }

      conditions += Trigger.toFriendlyCondition(
        c.field,
        c.op,
        c.value,
        c.intervalDuration
      );
    });

    this._conditionsD = conditions;

    return this._conditionsD;
  }

  constructor(init?: Partial<Trigger>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    this.timestamp = new Date(init.timestamp);
    this.created = new Date(init.created);

    if (this.conditions) {
      this.conditions = this.conditions.map((c) => new TriggerCondition(c));
    } else {
      this.conditions = [];
    }
  }

  update(input: Partial<Trigger>): this {
    return Object.assign(this, input);
  }

  static toFriendlyCondition(
    field: string,
    op: string,
    value: unknown,
    intervalDuration: number
  ): string {
    const intervals =
      intervalDuration > 0
        ? ` for ${intervalDuration} interval${intervalDuration != 1 ? 's' : ''}`
        : '';

    const opDescription = Ops.find((o) => o.name === op)?.description ?? 'unknown op';

    return `${Trigger.toFieldFriendlyName(
      field
    )} is ${opDescription} ${value}${intervals}`;
  }

  static toFieldFriendlyName(field: string): string {
    let found = undefined;
    FieldGroups.some((g) => {
      found = g.field.find((f) => f.value === field);

      return !!found;
    });

    return found?.viewValue || '';
  }
}
