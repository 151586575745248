import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { Subscription, filter, tap } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

@Directive({
  selector: '[hasPerm]'
})
export class HasPermDirective<C> implements OnInit, OnDestroy {
  @Input('hasPerm') requiredPerms: string[];
  @Input('hasPermElse') placeholder: TemplateRef<C> | null = null;
  @Input('hasPermHas') requireAll: 'all' | 'any' = 'all';

  _prevResult: boolean = undefined;
  _userSubscription: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private template: TemplateRef<C>,
    private stateSvc: StateService,
    private changes: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const requiredPerms = this.requiredPerms || [];

    if (requiredPerms.length > 0) {
      this.stateSvc.currentUser$
        .pipe(
          filter((user) => !!user),
          tap({
            next: (user) => {
              const hasPerms = user.hasPerms(requiredPerms);

              if (hasPerms === this._prevResult) {
                return;
              }

              this._prevResult = hasPerms;
              this.viewContainerRef.clear();

              if (hasPerms) {
                this.viewContainerRef.createEmbeddedView(this.template);
              } else if (this.placeholder) {
                this.viewContainerRef.createEmbeddedView(this.placeholder);
              }
              this.changes.markForCheck();
            }
          })
        )
        .subscribe();
    } else {
      this.viewContainerRef.createEmbeddedView(this.template);
    }
  }

  ngOnDestroy(): void {
    if (this._userSubscription) {
      this._userSubscription.unsubscribe();
    }
  }
}
