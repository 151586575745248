<div class="card flex flex-col h-full">
  <div class="border-b px-2 py-2 flex justify-start items-center content-center">
    <fa-icon [icon]="['fad', 'users']" size="2x" [fixedWidth]="true" class="mr-2 text-primary"> </fa-icon>

    <h2 class="m-0 title flex-auto truncate">{{ _displayConfig.name }}</h2>

    <div class="flex items-center justify-center">
      <button
        *ngIf="dataSource.data?.length > 0"
        mat-icon-button
        type="button"
        (click)="logoutAll()"
        class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 xs-icon-button"
        matTooltip="Logout All"
      >
        <fa-icon [icon]="['fad', 'portal-exit']" size="lg" class="flex items-center justify-center"> </fa-icon>
      </button>

      <mon-display-config-button
        *ngIf="_displayConfig"
        persistAt="User"
        [config]="_displayConfig"
        class="flex items-center justify-center text-sm w-8 h-8"
      ></mon-display-config-button>
    </div>
  </div>

  <div [fillHeight]="!(isMobile$ | async)" class="flex-1 pb-4 overflow-y-auto max-h-20">
    <table [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Checkbox Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'checkbox'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header mat>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <mat-checkbox
              (change)="$event ? selection.toggle(row) : null"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)"
              color="primary"
              [matTooltip]="column.tooltip"
            >
            </mat-checkbox>
          </td>
        </ng-container>
      </ng-container>

      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" tipOnEllip mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Number Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex float-right pr-2">
            <button
              *ngIf="row.isRemoteSession"
              (click)="openMachineInNewTab(row.isRemoteSession.machine)"
              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-deep-purple-light transition duration-200 ease-in-out sm:ml-2 lg:ml-1 xs-icon-button"
              [matTooltip]="row.username + ' is connected from ' + row.isRemoteSession.machine + ' - Click to open'"
              mat-icon-button
            >
              <fa-icon [icon]="['fad', 'chart-network']" size="lg" class="text-deep-purple"> </fa-icon>
            </button>
            <button
              *ngIf="os === OS.WINDOWS"
              (click)="displayEnvironmentVariables(row)"
              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-primary-light transition duration-200 ease-in-out sm:ml-2 lg:ml-1 xs-icon-button"
              matTooltip="Environment Variables"
              mat-icon-button
            >
              <fa-icon [icon]="['fad', 'percentage']" size="lg" class="text-primary"></fa-icon>
            </button>
            <button
              (click)="logout(row)"
              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-primary-light transition duration-200 ease-in-out sm:ml-2 lg:ml-1 xs-icon-button"
              matTooltip="Logout"
              mat-icon-button
            >
              <fa-icon [icon]="['fad', 'sign-out']" size="lg" class="text-primary"> </fa-icon>
            </button>
            <button
              *ngIf="os === OS.DARWIN"
              (click)="assist(row, RemoteSessionType.Teleconference)"
              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-primary-light transition duration-200 ease-in-out sm:ml-2 lg:ml-1 xs-icon-button"
              matTooltip="Teleconference"
              mat-icon-button
            >
              <fa-icon [icon]="['fad', 'headset']" size="lg" class="text-primary"> </fa-icon>
            </button>
            <button
              *ngIf="os === OS.WINDOWS"
              [matMenuTriggerFor]="remoteMenu"
              [matMenuTriggerData]="{ session: row }"
              class="w-8 h-8 leading-none flex items-center justify-center hover:bg-primary-light transition duration-200 ease-in-out sm:ml-2 lg:ml-1 xs-icon-button"
              matTooltip="Remote"
              mat-icon-button
            >
              <fa-icon [icon]="['fad', 'eye']" size="lg" class="text-primary"> </fa-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container *matRowDef="let row; columns: visibleColumns">
        <tr mat-row @fadeInUp [ngClass]="{ 'not-italic': row.state === 0 }" class="hover:bg-hover trans-ease-out italic"></tr>
      </ng-container>
    </table>
  </div>
</div>

<mat-menu #remoteMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent let-session="session">
    <button *devHide="['prod']" (click)="assist(session, RemoteSessionType.Teleconference)" class="mat-menu-item">
      <fa-icon [icon]="['fad', 'headset']" size="lg" class="pr-3 text-primary"> </fa-icon>
      Teleconference
    </button>
    <button (click)="assist(session, RemoteSessionType.MSRA)" class="mat-menu-item">
      <fa-icon [icon]="['fab', 'windows']" size="lg" class="pr-3 text-primary"> </fa-icon>
      Remote Assist
    </button>
    <button *ngIf="platformFamily !== 'Server'" (click)="assist(session, RemoteSessionType.QuickAssist)" class="mat-menu-item">
      <fa-icon [icon]="['fab', 'windows']" size="lg" class="pr-3 text-primary"> </fa-icon>
      Quick Assist
    </button>
  </ng-template>
</mat-menu>
