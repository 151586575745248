<ng-container *ngIf="resultListener$ | async"></ng-container>
<ng-container *ngIf="targetListener$ | async"></ng-container>

<div *ngIf="searchResult$ | async as search" class="flex flex-col h-full">
  <div class="grid grid-flow-row grid-cols-12 gap-2 h-full w-full">
    <div [fillHeight]="!(isMobile$ | async)" class="flex flex-col col-span-12 md:col-span-4 md:h-full gap-2">
      <div @fadeInUp class="flex items-stretch justify-stretch">
        <div class="flex-1 card">
          <div class="flex flex-col items-center justify-center px-4 h-full">
            <mat-form-field class="w-full">
              <mat-label>Search</mat-label>
              <input matInput autocomplete="off" (keyup)="onFilter(filter.value)" class="" #filter />
              <button
                matRipple
                *ngIf="filter.value"
                (click)="filter.value = ''; onFilter(filter.value)"
                class="rounded-full w-6 h-6"
                matSuffix
              >
                <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
              </button>
            </mat-form-field>
            <div class="relative w-full">
              <mat-progress-bar
                *ngIf="searching$ | async"
                mode="indeterminate"
                color="primary"
                class="absolute"
                [style.height]="'2px'"
                [style.top]="'-25px'"
              ></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>

      <div [fillHeight]="!selectedResultID && !(isMobile$ | async)" class="flex flex-col gap-2 pb-2 md:pb-0">
        <div
          class="card md:overflow-auto h-full"
          infinite-scroll
          [scrollWindow]="false"
          [alwaysCallback]="true"
          (scrolled)="onScrollDown($event)"
          #resultsWrapper
        >
          <ng-container *ngIf="search.searching || search.results">
            <div class="flex items-center p-4">
              <div class="flex-1">
                <div class="flex">
                  <div class="flex-1 flex flex-col">
                    <div class="text-lg" [style.font-variant]="'small-caps'">Search Results</div>
                    <div class="text-secondary text-xs"></div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div class="items-center justify-end">{{ search.results?.length || '' }}</div>
            </div>
            <div
              [@listStagger]="search.results?.length || 0"
              class="flex flex-col"
              [ngClass]="selectedResultID === undefined ? '' : 'overflow-hidden'"
            >
              <ng-container *ngIf="search.searching; else reportResults">
                <div @fadeInRight class="card p-4 text-secondary flex items-center justify-center">
                  <div class="py-4 flex items-center justify-center space-x-2">
                    <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
                    <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
                    <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
                  </div>
                </div>
              </ng-container>
              <ng-template #reportResults>
                <ng-container *ngIf="search.results.length > 0; else noResults">
                  <ng-container *ngFor="let result of search.results; trackBy: trackResultItemByFn">
                    <mon-result-list-item
                      @fadeInRight
                      *ngIf="selectedResultID === undefined || selectedResultID === result.id"
                      [result]="result"
                      [selected]="result.id === selectedResultID"
                      (dismiss)="clearSelectedResult(); $event.stopPropagation()"
                      (click)="selectedResultID === undefined ? selectResult(result) : null"
                    ></mon-result-list-item>
                  </ng-container>
                </ng-container>
                <ng-template #noResults>
                  <div @fadeInRight class="card p-4 text-secondary flex items-center justify-center">
                    <div>No Results :-(</div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </ng-container>

          <ng-container #recent>
            <div class="flex items-center p-4">
              <div class="flex-1">
                <div class="flex">
                  <div class="flex-1 flex flex-col">
                    <div class="text-lg" [style.font-variant]="'small-caps'">
                      {{ selectedResultID === undefined ? 'Results' : 'Selected Result' }}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div class="items-center justify-end">{{ selectedResultID === undefined && resultTotal > -1 ? resultTotal : '' }}</div>
            </div>

            <ng-container *ngIf="recentResults$ | async; else loading">
              <div
                [@listStagger]="recentResults.length"
                class="flex flex-col"
                [ngClass]="selectedResultID === undefined ? '' : 'overflow-hidden'"
                #results
              >
                <ng-container *ngIf="recentResults.length > 0; else noResults">
                  <ng-container *ngFor="let result of recentResults; trackBy: trackResultItemByFn">
                    <mon-result-list-item
                      @fadeInRight
                      *ngIf="selectedResultID === undefined || selectedResultID === result.id"
                      [result]="result"
                      [selected]="result.id === selectedResultID"
                      (dismiss)="clearSelectedResult(); $event.stopPropagation()"
                      (click)="selectedResultID === undefined ? selectResult(result.id) : null"
                    ></mon-result-list-item>
                  </ng-container>
                  <ng-container *ngIf="(pullingMoreResults$ | async) && !selectedResultID">
                    <div class="py-4 flex items-center justify-center space-x-2">
                      <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
                      <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-200"></div>
                      <div class="w-4 h-4 bg-blue-400 rounded-full animate-pulse animation-delay-400"></div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noResults>
                  <div @fadeInRight class="p-4 text-secondary flex items-center justify-center">
                    <div>Nothing here to see</div>
                  </div>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #loading>
              <div [@listStagger]="2" class="flex flex-col">
                <div class="animate-pulse flex flex-col p-2">
                  <ng-container *ngFor="let i of selectedResultID ? [1] : [1, 2]">
                    <div @fadeInRight class="pt-2 pb-1">
                      <div class="grid space-y-2 grid-cols-12 gap-0">
                        <div class="h-4 bg-gray rounded col-span-8"></div>
                        <div class="h-2 bg-gray rounded col-span-10"></div>
                        <div class="h-2 bg-gray rounded col-span-5"></div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="selectedResultID$ | async as selectedResultID"
        class="flex-1 flex flex-col md:h-full"
        [ngClass]="selectedResultID ? 'block' : 'hidden'"
      >
        <div class="flex-1 card h-full">
          <div class="flex items-center p-4">
            <div class="flex-1">
              <div class="flex">
                <div class="flex-1 flex flex-col">
                  <div class="text-lg" [style.font-variant]="'small-caps'">Target Machines</div>
                </div>
                <div></div>
              </div>
            </div>
            <div class="items-center justify-end">{{ targetTotal > -1 ? targetTotal : '' }}</div>
          </div>
          <div *ngIf="pullingTargets$ | async" [@listStagger]="2" class="flex flex-col">
            <div class="animate-pulse flex flex-col p-2">
              <ng-container *ngFor="let i of [1]">
                <div @fadeInRight class="py-2 pb-1">
                  <div class="grid space-y-2 grid-cols-12 gap-0">
                    <div class="h-4 bg-gray rounded col-span-8"></div>
                    <div class="h-2 bg-gray rounded col-span-10"></div>
                    <div class="h-2 bg-gray rounded col-span-5"></div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="retrieveTargets$ | async">
            <div [@listStagger]="targets.length" class="flex flex-col">
              <ng-container *ngIf="targets.length > 0; else noResults">
                <ng-container *ngFor="let target of targets; trackBy: trackTargetItemByFn">
                  <mon-result-target-item
                    @fadeInRight
                    [target]="target"
                    [selected]="target.agentID === selectedTargetID"
                    (click)="selectTarget(selectedResultID, target.agentID)"
                  ></mon-result-target-item>
                </ng-container>
              </ng-container>
              <ng-template #noResults>
                <div @fadeInRight class="card p-4 text-secondary flex items-center justify-center">
                  <div>Nothing here to see</div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-span-12 md:col-span-8" #detailPanel>
      <ng-container *ngIf="targetDetail$ | async as result">
        <div @fadeInRight *ngIf="selectedLoaded$ | async" class="card w-full h-full">
          <mon-result-target-detail [resultID]="result.resultID" [target]="result.target"></mon-result-target-detail>
        </div>
      </ng-container>
    </div>
  </div>
</div>
