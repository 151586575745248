<div *ngIf="tenant$ | async">
  <div @fadeInUp class="card overflow-hidden">
    <div class="h-24 relative overflow-hidden vector-bg"> </div>

    <div class="z-10 relative -mt-16 px-gutter flex items-center">
      <div class="flex items-center justify-center h-24 w-24">
        <div class="animate-blur-in flex items-center justify-center w-full h-full rounded-full bg-opacity-50">
          <fa-duotone-icon
            [icon]="['fad', 'sitemap']"
            size="4x"
            [primaryColor]="tailwindCssColorToRGB('text-primary')"
            [secondaryColor]="tailwindCssColorToRGB('text-on-primary-600')"
          ></fa-duotone-icon>
        </div>
      </div>

      <div class="max-w-full flex-auto sm:ml-6">
        <div class="h-16 flex items-center">
          <h1 @fadeInRight class="headline text-white m-0">SETTINGS</h1>
        </div>

        <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
          <ng-container *ngFor="let link of links">
            <a
              *hasPerm="link.requiredPerms"
              #rla="routerLinkActive"
              [active]="rla.isActive"
              [disabled]="link.disabled"
              [routerLink]="link.route"
              mat-tab-link
              queryParamsHandling="preserve"
              [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
              routerLinkActive
            >
              {{ link.label }}
            </a>
          </ng-container>
        </nav>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
