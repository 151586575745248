import { Component, Input } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'vex-secondary-toolbar',
  templateUrl: './secondary-toolbar.component.html',
  styleUrls: ['./secondary-toolbar.component.scss'],
  animations: []
})
export class SecondaryToolbarComponent {
  @Input() bgColor: string;
  @Input() connecting = true;
  @Input() spinner = false;
  @Input() icon: IconName;
  @Input() header: string;
  @Input() introKey: string;
  @Input() subheader: string;
  @Input() subheader2: string;
  @Input() crumbs: string[];
}
