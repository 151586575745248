import { Component } from '@angular/core';
import { filter, map } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'mon-dashboard-config-panel',
  templateUrl: './dashboard-config-panel.component.html',
  styleUrls: ['./dashboard-config-panel.component.scss']
})
export class DashboardConfigPanelComponent {
  widgets$ = this.stateSvc.currentUserSettings$.pipe(
    filter((s) => !!s),
    map((s) => {
      const widgets = Object.keys(s.consoleUI.widget)
        .map((name) => [name, s.consoleUI.widget[name]])
        .filter(([, w]) => w['name'] && !w['show'])
        .map(([n, w]) => {
          return {
            id: n,
            name: w['name']
          };
        });

      if (widgets.length > 0) {
        return widgets;
      }

      return undefined;
    })
  );

  constructor(private stateSvc: StateService) {}

  addWidget(id: string): void {
    const update = {
      consoleUI: {
        widget: {}
      }
    };

    update.consoleUI.widget[id] = { show: true };

    this.stateSvc.updateCurrentUserSettings(update);
  }
}
