import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { StateService } from 'src/app/core/services/state.service';
import { DisplayDensity } from 'src/app/shared/enums/table-density.enum';

import { mergeDeep } from '../utils/merge-deep';
import { LayoutService } from './layout.service';
import { Style, StyleService } from './style.service';

export interface Config {
  display: {
    density: DisplayDensity;
  };
  search: {
    goToDashboard: boolean;
  };
  id: string;
  rtl?: boolean;
  name: string;
  imgSrc: string;
  layout: 'vertical' | 'horizontal';
  boxed: boolean;
  bottomDrawer: {
    state: 'expanded' | 'collapsed';
  };
  sidenav: {
    state: 'expanded' | 'collapsed';
  };
  toolbar: {
    fixed: boolean;
  };
  navbar: {
    position: 'below-toolbar' | 'in-toolbar';
  };
  footer: {
    visible: boolean;
    fixed: boolean;
  };
  style: Style;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends BaseService {
  config: Config;

  private _configSubject = new BehaviorSubject(undefined);
  config$ = this._configSubject.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private styleSvc: StyleService,
    private layoutSvc: LayoutService,
    private stateSvc: StateService
  ) {
    super();
    this.init();
  }

  private init(): void {
    this.config$.pipe(filter((config) => !!config)).subscribe({
      next: (config) => {
        this._updateConfig(config);
      }
    });

    this.stateSvc.currentUserSettings$.pipe(filter((settings) => !!settings)).subscribe({
      next: (settings) => {
        const config = settings.consoleUI.config;

        if (config) {
          this._configSubject.next(
            mergeDeep({ ...this._configSubject.getValue() }, config)
          );
        }
      }
    });
  }

  private _updateConfig(config: Config) {
    this.config = mergeDeep(this.config, config);

    const body = this.document.body;

    body.classList.add(this.config.id);

    this.config.display.density === DisplayDensity.Compact
      ? body.classList.add('density-condensed')
      : body.classList.remove('density-condensed');

    this.styleSvc.setStyle(this.config.style);

    this.config.sidenav.state === 'expanded'
      ? this.layoutSvc.expandSidenav()
      : this.layoutSvc.collapseSidenav();

    this.config.bottomDrawer.state === 'expanded'
      ? this.layoutSvc.expandBottomDrawer()
      : this.layoutSvc.collapseBottomDrawer();

    // Workaround so charts and other externals know they have to resize on Layout switch
    if (window) {
      window.dispatchEvent(new Event('resize'));

      setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    }
  }
}
