import { Injectable } from '@angular/core';
import { init as initFullStory, FullStory } from '@fullstory/browser';
import { ContextUser } from 'src/app/shared/models/context-user.model';
import { environment } from 'src/environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FullStoryService extends ApiService {
  devMode = environment.localDev;

  constructor() {
    super();
    initFullStory({ orgId: 'FPBKV', devMode: this.devMode });
  }

  restart(): void {
    if (this.devMode) return;

    FullStory('restart');
  }

  shutdown(): void {
    if (this.devMode) return;

    FullStory('shutdown');
  }

  identify(user: ContextUser): void {
    if (this.devMode) return;

    FullStory('setIdentity', {
      uid: user.email,
      properties:{
        displayName: user.email,
        email: user.email,
        uid: user.userID,
      }
    })
  }

  getCurrentSessionURL(): string {
    return FullStory('getSession', { format: 'url' });
  }
}
