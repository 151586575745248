import { Pipe, PipeTransform } from '@angular/core';
import { friendlyDataSizeInBits, friendlyDataSizeInBytes } from 'src/app/utils/data-size';

@Pipe({
  name: 'friendlyBytes'
})
export class DataSizeInBytesPipe implements PipeTransform {
  transform(value: number, fractionDigits?: number, showSuffix?: boolean): string {
    return friendlyDataSizeInBytes(value, fractionDigits, showSuffix);
  }
}

@Pipe({
  name: 'friendlyBits'
})
export class DataSizeInBitsPipe implements PipeTransform {
  transform(value: number, fractionDigits?: number, showSuffix?: boolean): string {
    return friendlyDataSizeInBits(value, fractionDigits, showSuffix);
  }
}
