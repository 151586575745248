import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { BehaviorSubject, catchError, of, tap } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { StateService } from 'src/app/core/services/state.service';
import {
  SubscribeService,
  SubscriptionCancellationReasonCode,
  SubscriptionLicenseResult
} from 'src/app/core/services/subscribe.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { SpinnerComponent } from 'src/app/shared/components/dialogs/spinner/spinner.component';
import { SharedModule } from 'src/app/shared/shared.module';

export interface CancelData {
  onCancel: () => void;
  onConfirm: (s: SubscriptionLicenseResult) => void;
}

@Component({
  standalone: true,
  selector: 'mon-cancel',
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    SharedModule,
    SpinnerComponent
  ],
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
  animations: [fadeInRight400ms]
})
export class CancelComponent {
  spin = false;
  SubscriptionCancellationReasonCode = SubscriptionCancellationReasonCode;

  tenant$ = this.stateSvc.tenant$;
  cancelReasons$ = of(Object.values(SubscriptionCancellationReasonCode));

  _error = new BehaviorSubject<string>(undefined);
  error$ = this._error.asObservable();

  @ViewChild('reasonCode') reasonCode: MatSelect;
  @ViewChild('competitor', { static: false }) competitor: ElementRef;
  @ViewChild('feedback') feedback: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<CancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CancelData,
    private stateSvc: StateService,
    private subscribeSvc: SubscribeService
  ) {}

  confirm(): void {
    this.spin = true;
    this._error.next(undefined);

    this.subscribeSvc
      .cancelSubscription(
        this.reasonCode.value,
        this.competitor?.nativeElement.value || '',
        this.feedback.nativeElement.value
      )
      .pipe(
        catchError((err) => {
          this.spin = false;
          this._error.next(parseError(err, 'could not reactivate'));
          throw err;
        }),
        tap((s) => {
          if (this.data?.onConfirm) {
            this.data.onConfirm(s);
          }
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  cancel(): void {
    if (this.data?.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
