export interface GPRReportData {
  agent_id: string;
  agent_version: string;
  name: string;
  scope: string;
  username: string;
  time: string;
  storage_gpr_path: string;
}

export enum GPReportScopeType {
  Computer = 1,
  User = 2
}
