import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[loaded]'
})
export class LoadedDirective {
  @Output() loaded = new EventEmitter<ElementRef>();

  constructor(private elRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.loaded.emit(this.elRef);
  }
}
