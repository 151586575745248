<ng-container *ngIf="user$ | async">
  <ng-container *ngIf="agentConnectionState$ | async as state">
    <ng-container *ngIf="agent$ | async as agent">
      <div class="relative" [ngClass]="(initialContactMade$ | async) ? 'h-fit' : 'h-full'">
        <div
          @fadeIn
          *ngIf="(live$ | async) && state !== 'connected'"
          class="absolute top-0 left-0 bottom-0 right-0 bg-base z-20 w-full h-full flex flex-col gap-4 items-center justify-start pt-32"
          [ngClass]="{ 'opacity-90': state === 'connecting' || state === 'disconnected' }"
        >
          <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="'connecting'">
              <div class="animate-pulse text-secondary text-3xl">CONNECTING...</div>
            </ng-container>
            <ng-container *ngSwitchCase="'disconnected'">
              <div class="text-2xl">AGENT OFFLINE</div>
              <div class="px-4"><mon-no-agent-connection></mon-no-agent-connection></div>
            </ng-container>
            <ng-container *ngSwitchCase="'blocked'">
              <h2>AGENT BLOCKED</h2>
              <div class="px-4">
                <div>This has agent has been blocked from reporting data</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="initialContactMade$ | async">
          <div [@listStagger] class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-2 dashboard-view" vexContainer>
            <ng-container *ngIf="machineStatsConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineSystem_Read]">
                <ng-container *ngIf="config.show">
                  <mon-machine-stats @fadeInUp [config]="config" [agent]="agent" class="col-span-1 lg:col-span-5"></mon-machine-stats>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="geoLocationConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineGeo_Read]">
                <ng-container *ngIf="config.show">
                  <mon-geo-location
                    *ngIf="ipLocation.properties.size > 0"
                    @fadeInUp
                    icon="location"
                    [config]="config"
                    [properties]="ipLocation.properties"
                    [latLng]="ipLocation.latLng"
                    class="col-span-1 lg:col-span-3 min-h-64"
                  ></mon-geo-location>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="currentUsersConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineSession_List]">
                <ng-container *ngIf="config.show">
                  <mon-current-users
                    *ngIf="agent"
                    @fadeInUp
                    [config]="config"
                    class="col-span-1 lg:col-span-4"
                    (selectionChange)="userSelectionChange($event)"
                  ></mon-current-users>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *hasPerm="[permission.MachineProcess_Read]">
              <ng-container *ngIf="topCpuConfig$ | async as config">
                <ng-container *ngIf="config.show">
                  <mon-live-table
                    @fadeInUp
                    class="col-span-1 lg:col-span-3"
                    [config]="config"
                    [data]="topCPUUsage"
                    [idField]="getProcessID"
                    [columns]="cpuColumns"
                    sortField="cpuPercentD"
                    sortDirection="desc"
                    icon="microchip"
                    [iconClass]="['text-blue']"
                    [header]="config.name"
                    linkTip="Go to Processes"
                    [link]="['/home/task-manager/processes']"
                  ></mon-live-table>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="topMemConfig$ | async as config">
                <ng-container *ngIf="config.show">
                  <mon-live-table
                    @fadeInUp
                    class="col-span-1 lg:col-span-3"
                    [config]="config"
                    [data]="topMemoryUsage"
                    [idField]="getProcessID"
                    [columns]="memoryColumns"
                    sortField="memorySize"
                    sortDirection="desc"
                    icon="memory"
                    [iconClass]="['text-purple']"
                    header="Top 5 RAM Consumers"
                    linkTip="Go to Processes"
                    [link]="['/home/task-manager/processes']"
                  ></mon-live-table>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="os === OS.WINDOWS">
                <ng-container *ngIf="topDiskConfig$ | async as config">
                  <ng-container *ngIf="config.show">
                    <mon-live-table
                      @fadeInUp
                      class="col-span-1 lg:col-span-3"
                      [config]="config"
                      [data]="topDiskUsage"
                      [idField]="getProcessID"
                      [columns]="diskColumns"
                      sortField="disk"
                      sortDirection="desc"
                      icon="hdd"
                      [iconClass]="['text-green']"
                      header="Top 5 Disk Consumers"
                      linkTip="Go to Processes"
                      [link]="['/home/task-manager/processes']"
                    ></mon-live-table>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="os === OS.WINDOWS">
                <ng-container *ngIf="topNetConfig$ | async as config">
                  <ng-container *ngIf="config.show">
                    <mon-live-table
                      @fadeInUp
                      class="col-span-1 lg:col-span-3"
                      [config]="config"
                      [data]="topNetUsage"
                      [idField]="getProcessID"
                      [columns]="netColumns"
                      sortField="network"
                      sortDirection="desc"
                      icon="network-wired"
                      [iconClass]="['text-orange']"
                      header="Top 5 Net Consumers"
                      linkTip="Go to Processes"
                      [link]="['/home/task-manager/processes']"
                    ></mon-live-table>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="cpuGraphConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineCPU_Read]">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    @fadeInUp
                    class="col-span-1 lg:col-span-3"
                    [config]="config"
                    [options]="cpuGraphOps"
                    #cpuGraph
                  ></mon-live-graph>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="memGraphConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineMemory_Read]">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    @fadeInUp
                    class="col-span-1 lg:col-span-3"
                    [config]="config"
                    [options]="memoryGraphOps"
                    #memoryGraph
                  ></mon-live-graph>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="diskGraphConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineDisk_Read]">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    *ngFor="let ops of diskGraphOps; track"
                    @fadeInUp
                    class="lg:col-span-3"
                    [config]="config"
                    [id]="ops.id"
                    [options]="ops"
                    #diskGraph
                  >
                    <div buttons>
                      <button
                        *ngFor="let btn of ops.buttons"
                        mat-icon-button
                        type="button"
                        queryParamsHandling="preserve"
                        class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 sm-icon-button"
                        (click)="btn.onClick ? btn.onClick() : null"
                        [matTooltip]="btn.tooltip"
                      >
                        <fa-icon [icon]="['fad', btn.icon || 'external-link']" class="flex items-center justify-center"> </fa-icon>
                      </button>
                    </div>
                  </mon-live-graph>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *hasPerm="[permission.MachineNetwork_Read]">
              <ng-container *ngIf="live$ | async">
                <ng-container *ngIf="sessionsTo$ | async">
                  <ng-container *ngIf="dpGraphConfig$ | async as config">
                    <ng-container *ngIf="config.show">
                      <mon-live-graph
                        *ngFor="let ops of dpToGraphOps; track"
                        @fadeInUp
                        class="lg:col-span-3"
                        [config]="config"
                        [id]="ops.id"
                        [options]="ops"
                        #dpToGraph
                      >
                        <div buttons>
                          <button
                            *ngFor="let btn of ops.buttons"
                            mat-icon-button
                            type="button"
                            queryParamsHandling="preserve"
                            class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 sm-icon-button"
                            (click)="btn.onClick ? btn.onClick() : null"
                            [matTooltip]="btn.tooltip"
                          >
                            <fa-icon [icon]="['fad', 'external-link']" class="flex items-center justify-center"> </fa-icon>
                          </button>
                        </div>
                      </mon-live-graph>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="live$ | async">
                <ng-container *ngIf="dpGraphConfig$ | async as config">
                  <ng-container *ngIf="config.show">
                    <mon-live-graph
                      *ngFor="let ops of dpFromGraphOps; track"
                      @fadeInUp
                      class="lg:col-span-3"
                      [config]="config"
                      [id]="ops.id"
                      [options]="ops"
                      #dpFromGraph
                    >
                      <div buttons>
                        <button
                          *ngFor="let btn of ops.buttons"
                          mat-icon-button
                          type="button"
                          queryParamsHandling="preserve"
                          class="flex items-center justify-center text-gray hover:bg-gray-light transition duration-200 ease-in-out w-8 h-8 mr-1 xs-icon-button"
                          (click)="btn.onClick ? btn.onClick() : null"
                          [matTooltip]="btn.tooltip"
                        >
                          <fa-icon [icon]="['fad', 'external-link']" class="flex items-center justify-center"> </fa-icon>
                        </button>
                      </div>
                    </mon-live-graph>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="ethGraphConfig$ | async as config">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    *ngFor="let ops of ethernetGraphOps; track"
                    @fadeInUp
                    class="lg:col-span-3"
                    [config]="config"
                    [id]="ops.id"
                    [options]="ops"
                    #ethernetGraph
                  ></mon-live-graph>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="wifiGraphConfig$ | async as config">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    *ngFor="let ops of wifiGraphOps; track"
                    @fadeInUp
                    class="lg:col-span-3"
                    [config]="config"
                    [id]="ops.id"
                    [options]="ops"
                    #wifiGraph
                  ></mon-live-graph>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="gpuGraphConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineGPU_Read]">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    *ngFor="let ops of gpuGraphOps; track"
                    @fadeInUp
                    class="lg:col-span-3"
                    [config]="config"
                    [id]="ops.id"
                    [options]="ops"
                    #gpuGraph
                  >
                  </mon-live-graph>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="batteryGraphConfig$ | async as config">
              <ng-container *hasPerm="[permission.MachineGPU_Read]">
                <ng-container *ngIf="config.show">
                  <mon-live-graph
                    *ngFor="let ops of batteryGraphOps; track"
                    @fadeInUp
                    class="lg:col-span-3"
                    [config]="config"
                    [id]="ops.id"
                    [options]="ops"
                    #batteryGraph
                  >
                  </mon-live-graph>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
