<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>

    <ng-container *ngIf="subscription$ | async as subscription; else loader">
      <div class="flex justify-start items-center" mat-dialog-title>
        <div class="flex-1 flex flex-col">
          <h2 tipOnEllip class="headline m-0 truncate flex-auto">Payment Source Info</h2>
        </div>

        <button class="text-secondary -mt-2 -mr-2" mat-dialog-close mat-icon-button type="button" [disabled]="spin">
          <fa-icon [icon]="['fad', 'times']" size="lg" class="leading-none"></fa-icon>
        </button>
      </div>

      <mat-divider class="text-border"></mat-divider>

      <blockquote
        *ngIf="params.paymentSource"
        @fadeInRight
        class="m-0 mt-4 bg-orange-100 dark:bg-opacity-20 border-orange-500 text-orange-600"
      >
        <div class="flex items-center justify-center gap-4">
          <div>
            <fa-icon [icon]="['fad', 'exclamation-square']" size="2xl" matTooltip="You are invited"></fa-icon>
          </div>
          <div>Any update will overwrite your current payment source.</div>
        </div>
      </blockquote>

      <ng-container *ngIf="selectedPaymentSource$ | async as selectedPaymentSource">
        <mat-stepper orientation="vertical" linear (selectionChange)="onStep($event)">
          <ng-template matStepperIcon="edit">
            <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
          </ng-template>
          <ng-template matStepperIcon="done">
            <fa-icon [icon]="['fad', 'check-double']"></fa-icon>
          </ng-template>

          <mat-step *ngIf="showTypeSelection" [stepControl]="paymentSourceForm" label="Payment Source">
            <form autocomplete="off" [formGroup]="paymentSourceForm">
              <mat-dialog-content class="flex flex-col">
                <h4 class="pb-4 text-secondary text-center">Please Select Payment Source</h4>
                <div class="flex flex-wrap items-stretch justify-center gap-1 md:gap-8">
                  <button mat-button (click)="selectPaymentSource('CC')" class="p-0 h-fit">
                    <div
                      class="flex flex-col items-center justify-start cursor-pointer rounded hover:bg-primary-light transition-all"
                      [ngClass]="selectedPaymentSource === 'CC' ? 'dark:bg-gray-700 bg-gray-100' : ''"
                      #item
                    >
                      <div class="flex-1 flex flex-col md:gap-4 items-center justify-start text-sm md:text-base p-1 md:p-6 pb-0">
                        <fa-icon [icon]="['fad', 'credit-card']" size="6x" class="text-gray"></fa-icon>
                        <div class="w-32 text-center text-secondary uppercase font-bold">Credit Card</div>
                      </div>
                      <div
                        class="h-8 flex p-2 items-center justify-center w-full rounded-b"
                        [ngClass]="selectedPaymentSource === 'CC' ? 'dark:bg-green-700 bg-green-300' : ''"
                      >
                        <div *ngIf="selectedPaymentSource === 'CC'; else notSelected" class="text-secondary font-bold">SELECTED</div>
                        <ng-template #notSelected>
                          <div class="text-secondary font-bold">&nbsp;</div>
                        </ng-template>
                      </div>
                    </div>
                  </button>
                  <button mat-button (click)="selectPaymentSource('ACH')" class="p-0 h-fit">
                    <div
                      class="flex flex-col items-center justify-start cursor-pointer rounded hover:bg-primary-light transition-all"
                      [ngClass]="selectedPaymentSource === 'ACH' ? 'dark:bg-gray-700 bg-gray-100' : ''"
                      #item
                    >
                      <div class="flex-1 flex flex-col md:gap-4 items-center justify-start text-sm md:text-base p-1 md:p-6 pb-0">
                        <fa-icon [icon]="['fad', 'bank']" size="6x" class="text-gray"></fa-icon>
                        <div class="w-32 text-center text-secondary uppercase font-bold">Bank Account</div>
                      </div>
                      <div
                        class="h-8 flex p-2 items-center justify-center w-full rounded-b"
                        [ngClass]="selectedPaymentSource === 'ACH' ? 'dark:bg-green-700 bg-green-300' : ''"
                      >
                        <div *ngIf="selectedPaymentSource === 'ACH'; else notSelected" class="text-secondary font-bold">SELECTED</div>
                        <ng-template #notSelected>
                          <div class="text-secondary font-bold">&nbsp;</div>
                        </ng-template>
                      </div>
                    </div>
                  </button>
                </div>
              </mat-dialog-content>
            </form>
          </mat-step>

          <mat-step *ngIf="selectedPaymentSource === 'CC'" [stepControl]="ccForm" label="Credit Card">
            <form autocomplete="off" [formGroup]="ccForm">
              <mat-dialog-content class="flex flex-col">
                <mat-form-field @fadeInRight class="w-full">
                  <mat-label>Company Name</mat-label>
                  <input formControlName="companyName" matInput autofocus [readonly]="spin" #ccCompanyName />
                  <fa-icon [icon]="['fad', 'building']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                </mat-form-field>
                <mat-form-field @fadeInRight class="w-full">
                  <mat-label>Phone</mat-label>
                  <input formControlName="phone" matInput [readonly]="spin" />
                  <fa-icon [icon]="['fad', 'phone']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                </mat-form-field>
                <div class="grid grid-cols-2 gap-2">
                  <mat-form-field @fadeInRight>
                    <mat-label>First Name</mat-label>
                    <input formControlName="firstName" matInput [readonly]="spin" #ccFirstName />
                    <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Last Name</mat-label>
                    <input formControlName="lastName" matInput [readonly]="spin" />
                  </mat-form-field>
                </div>
                <div @fadeInRight>
                  <mon-address [formGroup]="ccForm" type="credit-card" [readonly]="spin"></mon-address>
                </div>
                <div class="grid grid-cols-12 gap-2">
                  <mat-form-field @fadeInRight class="col-span-10 w-full">
                    <mat-label>Number</mat-label>
                    <input formControlName="number" type="text" creditCard matInput autofocus [readonly]="spin" #ccNumber />
                    <fa-icon [icon]="['fad', 'credit-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                  </mat-form-field>
                  <mat-form-field @fadeInRight class="col-span-2 w-full">
                    <mat-label>CVV</mat-label>
                    <input formControlName="cvv" type="text" maxlength="4" matInput autofocus [readonly]="spin" />
                  </mat-form-field>
                </div>
                <div class="grid grid-cols-2 gap-2">
                  <mat-form-field @fadeInRight>
                    <mat-label>Expiry Month</mat-label>
                    <mat-select formControlName="expiryMonth">
                      <mat-option *ngFor="let n of ccMonths" [value]="n">{{ n | number: '2.0' }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Expiry Year</mat-label>
                    <mat-select formControlName="expiryYear">
                      <mat-option *ngFor="let n of ccYears" [value]="n">{{ n }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-dialog-content>
              <mat-dialog-actions>
                <div class="w-full flex justify-end">
                  <button mat-button color="primary" matStepperPrevious>Back</button>
                  <button mat-button color="primary" mat-raised-button [disabled]="ccForm.invalid" (click)="submit()">Submit</button>
                </div>
              </mat-dialog-actions>
            </form>
          </mat-step>

          <mat-step *ngIf="selectedPaymentSource === 'ACH'" [stepControl]="achForm" label="Bank Account">
            <form autocomplete="off" [formGroup]="achForm">
              <mat-dialog-content class="flex flex-col">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <mat-form-field @fadeInRight>
                    <mat-label>First Name</mat-label>
                    <input formControlName="firstName" matInput [readonly]="spin" #achFirstName />
                    <fa-icon [icon]="['fad', 'address-card']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Last Name</mat-label>
                    <input formControlName="lastName" matInput [readonly]="spin" />
                  </mat-form-field>
                  <mat-form-field @fadeInRight class="md:col-span-2">
                    <mat-label>Bank Name</mat-label>
                    <input formControlName="bankName" matInput [readonly]="spin" />
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Account Number</mat-label>
                    <input formControlName="accountNumber" matInput [readonly]="spin" />
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Routing Number</mat-label>
                    <input formControlName="routingNumber" matInput [readonly]="spin" />
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Account Type</mat-label>
                    <mat-select formControlName="accountType">
                      <mat-option value="checking">Checking</mat-option>
                      <mat-option value="savings">Savings</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field @fadeInRight>
                    <mat-label>Account Holder Type</mat-label>
                    <mat-select formControlName="accountHolderType">
                      <mat-option value="individual">Individual</mat-option>
                      <mat-option value="company">Company</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-dialog-content>
              <mat-dialog-actions>
                <div class="w-full flex justify-end">
                  <button mat-button color="primary" matStepperPrevious>Back</button>
                  <button mat-button color="primary" mat-raised-button matStepperNext [disabled]="achForm.invalid" (click)="submit()"
                    >Submit</button
                  >
                </div>
              </mat-dialog-actions>
            </form>
          </mat-step>
        </mat-stepper>
      </ng-container>
    </ng-container>
    <ng-template #loader>
      <div class="h-40"></div>
    </ng-template>
  </div>
</mon-spinner>
