import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexOptions,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { scaleInOutAnimation } from 'src/@vex/animations/scale-in-out.animation';
import { defaultChartOptions } from 'src/@vex/utils/default-chart-options';
import {
  ComponentDisplayConfig,
  DisplayField
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { deepCopy } from 'src/app/utils/clone';
import { tailwindCssColorToRGB } from 'src/app/utils/colors';

export const LiveGraphDisplayConfig = (
  id: string,
  name: string,
  properties: string[] = []
) =>
  new ComponentDisplayConfig({
    id: id,
    name: name,
    show: true,
    fields: [new DisplayField('Graph', true)].concat(
      properties.map((p) => new DisplayField(p, true))
    )
  });

@Component({
  selector: 'mon-live-graph',
  templateUrl: './live-graph.component.html',
  styleUrls: ['./live-graph.component.scss'],
  animations: [scaleInOutAnimation]
})
export class LiveGraphComponent implements OnDestroy {
  private _chart = new BehaviorSubject<ChartComponent>(undefined);
  chart$ = this._chart.asObservable();

  objectKeys = Object.keys;

  apexOptions: ApexOptions = defaultChartOptions({
    tooltip: {
      x: {
        show: false
      }
    },
    chart: {
      type: 'area',
      height: '100'
    }
  });

  _displayConfig: ComponentDisplayConfig;
  @Input() set config(config: ComponentDisplayConfig) {
    this._displayConfig = config;
  }
  @Input() id: unknown;

  _options: GraphOptions;
  get options(): GraphOptions {
    return this._options;
  }
  @Input() set options(options: GraphOptions) {
    this._options = options;
    if (this._displayConfig) {
      if (options.focusProperties) {
        this._displayConfig.addFields([...options.focusProperties.map((p) => p.label)]);
      }
      if (options.properties) {
        this._displayConfig.addFields([...Object.keys(options.properties)]);
      }
    }
  }

  @Output() headerClicked = new EventEmitter<GraphOptions>();

  _checkIfRendered: NodeJS.Timeout;
  _chartObject: ChartComponent;
  get chartObj(): ChartComponent {
    return this._chartObject;
  }
  @ViewChild('chartObj') set chartObj(value: ChartComponent) {
    this._chartObject = value;

    if (this._chartObject && !this._chartObject.colors) {
      (this._chartObject.yaxis as ApexYAxis).max = this.options.maxY[0];

      const colors = [];
      const widths = [];
      const dashes = [];

      if ((this.options.seriesColors || []).length > 0) {
        const seriesColors = this.options.seriesColors[0];
        for (let i = 0; i < seriesColors.length; i++) {
          colors.push(tailwindCssColorToRGB(seriesColors[i]));
          widths.push(2.5);
          dashes.push(
            !!this.options.seriesLineStyle &&
              this.options.seriesLineStyle[0][i] === 'dashed'
              ? 5
              : 0
          );
        }
      }

      this._chartObject.colors = colors;
      this._chartObject.stroke = {
        curve: 'smooth',
        width: widths,
        dashArray: dashes
      };
    }

    this._checkIfRendered = setInterval(() => {
      const ready = (c: ChartComponent): boolean => {
        try {
          c.clearAnnotations();
          return true;
        } catch (exception) {
          return false; // we are not ready because chartObj hasn't been created.
        }
      };

      if (ready(this._chartObject)) {
        clearInterval(this._checkIfRendered);
        this.init();
        this._chart.next(this._chartObject);
      }
    }, 250);
  }

  ngOnDestroy(): void {
    clearInterval(this._checkIfRendered);
  }

  appendData(newValues: number[]): void {
    newValues = newValues || [];
    this.chart$
      .pipe(
        filter((chart) => !!chart),
        take(1)
      )
      .subscribe({
        next: (chart) => {
          const data = [];
          const chartData = newValues;
          for (let i = 0; i < chartData.length; i++) {
            data.push({
              name: this.options.focusProperties
                ? this.options.focusProperties[i].label
                : '',
              data: [chartData[i]]
            });
          }

          chart.appendData(data);
        }
      });
  }

  hasHighlight(): boolean {
    return this._options.focusProperties?.findIndex((p) => p.highlight) >= 0;
  }

  private init(): void {
    const newSeries: ApexAxisChartSeries = [];
    const initialSeries = this.options.initialSeries[0] || [];

    if (initialSeries.length > 0) {
      initialSeries.forEach((s) => {
        newSeries.push(deepCopy(s)); // make a deep copy so we don't reference the original array
      });
    }

    const largestRange = Math.max(...initialSeries.map((s) => s.data.length)) - 1;

    this.chart$
      .pipe(
        filter((chart) => !!chart),
        take(1),
        tap((chart) => {
          chart.updateOptions({
            series: newSeries,
            xaxis: {
              range: largestRange
            }
          });
        })
      )
      .subscribe();
  }

  removeProperty(key: string): void {
    delete this._options.properties[key];
  }

  headerSelected() {
    this.headerClicked.emit(this._options);
  }

  clearData(): void {
    const newSeries: ApexAxisChartSeries = [];
    const initialSeries = this.options.initialSeries[0] || [];
    const largestRange = Math.max(...initialSeries.map((s) => s.data.length)) - 1;

    if (initialSeries.length > 0) {
      initialSeries.forEach((s) => {
        s.data = Array.from({ length: largestRange }, () => 0);
        newSeries.push(deepCopy(s)); // make a deep copy so we don't reference the original array
      });
    }

    this.chart$
      .pipe(
        filter((chart) => !!chart),
        take(1)
      )
      .subscribe({
        next: (chart) => {
          chart.updateOptions({
            series: newSeries,
            xaxis: {
              range: largestRange
            }
          });
        }
      });
  }
}
