import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AgentBlock } from 'src/app/shared/models/agent-block.model';
import { Agreement } from 'src/app/shared/models/agreement.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { AgentUserSession } from 'src/app/shared/models/session.model';
import { Address, Tenant } from 'src/app/shared/models/tenant.model';
import { User } from 'src/app/shared/models/user.model';
import { ApiService, NoContent, Response, TagResponse } from './api.service';
import { Invite } from './user.service';
import { AuthService } from '@auth0/auth0-angular';

export interface TenantPatch {
  id?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  ownerID?: string;
  phone?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export interface TenantNameValidationTag {
  error?: string;
  original: string;
  scrubbed: string;
  valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TenantService extends ApiService {
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) public document: Document,
    private authSvc: AuthService
  ) {
    super();
  }

  get(): Observable<Tenant> {
    const url = `${this.apiUrl}/tenant`;
    const params = {};
    const start = performance.now();
    return this.http.get<Tenant>(url, { params }).pipe(
      tap(() => {
        this.log(`fetched TenantService.get response in ${performance.now() - start}ms`);
      }),
      map((response) => (response ? new Tenant(response) : undefined)),
      catchError((err) => {
        this.handleError<Tenant>('TenantService.get');
        return throwError(() => err);
      })
    );
  }

  getUserSessions(
    filter: string,
    limit: number,
    page: number,
    orderBy: OrderBy[]
  ): Observable<PagedResult<AgentUserSession>> {
    const url = `${this.apiUrl}/tenant/agent/user-session`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    return this.http
      .get<Array<AgentUserSession>>(url, { observe: 'response', params })
      .pipe(
        map((response) =>
          !!response
            ? new PagedResult<AgentUserSession>(AgentUserSession, {
                items: response.body,
                page: page,
                limit: limit
              })
            : undefined
        ),
        catchError((err) => {
          this.handleError<string[]>('TenantService.getUserSessions');
          return throwError(() => err);
        })
      );
  }

  getBlockedAgents(...agentIDs: string[]): Observable<Array<AgentBlock>> {
    const url = `${this.apiUrl}/tenant/blocked/agents${
      agentIDs.length > 0 ? '?agentIDs=' + agentIDs.join(',') : ''
    }`;

    return this.http.get<Array<AgentBlock>>(url).pipe(
      map((response) => (response ? response.map((t) => new AgentBlock(t)) : undefined)),
      catchError((err) => {
        this.handleError<string[]>('TenantService.getBlockedAgents');
        return throwError(() => err);
      })
    );
  }

  addAgentBlock(
    agentID: string,
    machineID: string,
    ip: string,
    blockFor: number,
    blockUntil: Date,
    blockIndefinitely: boolean
  ): Observable<Response> {
    const url = `${this.apiUrl}/tenant/blocked/agents`;
    return this.http
      .post<Response>(url, {
        agentID,
        machineID,
        ip,
        blockFor,
        blockUntil,
        blockIndefinitely
      })
      .pipe(
        tap(() => this.log('fetched TenantService.addAgentBlock response')),
        catchError((err) => {
          this.handleError('TenantService.addAgentBlock');
          return throwError(() => err);
        })
      );
  }

  removeBlockedAgents(agentBlockIDs: string[]): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/blocked/agents/remove`;
    const start = performance.now();
    return this.http.patch(url, { agentBlockIDs }).pipe(
      tap(() => {
        this.log(
          `fetched TenantService.removeBlockedAgents response in ${
            performance.now() - start
          }ms`
        );
      }),
      catchError((err) => {
        this.handleError('TenantService.removeBlockedAgents');
        return throwError(() => err);
      })
    );
  }

  updateDetail(patch: TenantPatch): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/detail`;
    const start = performance.now();
    return this.http.patch(url, patch).pipe(
      tap(() => {
        this.log(
          `fetched TenantService.update response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError('TenantService.update');
        return throwError(() => err);
      })
    );
  }

  updateBilling(patch: TenantPatch): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/billing`;
    const start = performance.now();

    return this.http.patch(url, patch.billingAddress).pipe(
      tap(() => {
        this.log(
          `fetched TenantService.update response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError('TenantService.update');
        return throwError(() => err);
      })
    );
  }

  getOpenAgreements(): Observable<Array<Agreement>> {
    const url = `${this.apiUrl}/tenant/agreement`;
    const start = performance.now();

    return this.http.get<Array<Agreement>>(url).pipe(
      tap(() => {
        this.log(
          `fetched TenantService.getOpenAgreements response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) => (response ? response.map((a) => new Agreement(a)) : undefined)),
      catchError((err: HttpErrorResponse) => {
        this.handleError('TenantService.getOpenAgreements');
        if (err.status === 401) {
          this.authSvc.logout({
            logoutParams: { returnTo: this.document.location.origin }
          });
        }
        return throwError(() => err);
      })
    );
  }

  closeAgreement(...keys: string[]): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/agreement`;
    const start = performance.now();
    return this.http.post(url, { agreed: keys }).pipe(
      tap(() => {
        this.log(
          `fetched TenantService.update response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError('TenantService.update');
        return throwError(() => err);
      })
    );
  }

  setSetting(setting: string, value: unknown): Observable<Response> {
    const url = `${this.apiUrl}/tenant/setting`;
    return this.http.patch<Response>(url, { setting, value }).pipe(
      catchError((err) => {
        this.handleError<Response>('TenantService.setSetting');
        return throwError(() => err);
      })
    );
  }

  getSettings(...settings: string[]): Observable<Map<string, unknown>> {
    const url = `${this.apiUrl}/tenant/setting`;
    return this.http.get<Map<string, unknown>>(url, { params: { settings } }).pipe(
      map((settings) => {
        const m = new Map<string, unknown>();
        Object.keys(settings).forEach((s) => m.set(s, settings[s]));
        return m;
      }),
      catchError((err) => {
        this.handleError<Map<string, unknown>>('TenantService.getSetting');
        return throwError(() => err);
      })
    );
  }

  getPublicSettings(...settings: string[]): Observable<Map<string, unknown>> {
    const url = `${this.apiUrl}/tenant/setting/public`;
    return this.http.get<Map<string, unknown>>(url, { params: { settings } }).pipe(
      map((settings) => {
        const m = new Map<string, unknown>();
        Object.keys(settings).forEach((s) => m.set(s, settings[s]));
        return m;
      }),
      catchError((err) => {
        this.handleError<Map<string, unknown>>('TenantService.getPublicSettings');
        return throwError(() => err);
      })
    );
  }

  getAllInvites(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<Invite>> {
    const url = `${this.apiUrl}/tenant/invite`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    return this.http.get<Array<Invite>>(url, { observe: 'response', params }).pipe(
      map((response) =>
        response
          ? new PagedResult<Invite>(null, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<PagedResult<User>>('TenantService.getAllInvites');
        return throwError(() => err);
      })
    );
  }

  createInvite(email: string, roles: string[]): Observable<User> {
    const url = `${this.apiUrl}/tenant/invite`;
    const params = {
      email,
      roles
    };
    const start = performance.now();
    return this.http.post<User>(url, params).pipe(
      tap(() => {
        this.log(
          `fetched TenantService.inviteUser response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<User>('TenantService.inviteUser');
        return throwError(() => err);
      })
    );
  }

  resendInvite(inviteID: string): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/invite/${inviteID}`;
    return this.http.post<NoContent>(url, {}).pipe(
      catchError((err) => {
        this.handleError<NoContent>('TenantService.resendInvite');
        return throwError(() => err);
      })
    );
  }

  deleteInvite(inviteID: string): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/invite/${inviteID}`;
    return this.http.delete<NoContent>(url).pipe(
      catchError((err) => {
        this.handleError<NoContent>('TenantService.deleteInvite');
        return throwError(() => err);
      })
    );
  }

  getUserByID(userID: string): Observable<User> {
    const url = `${this.apiUrl}/tenant/user/${userID}`;
    const start = performance.now();
    return this.http.get<User>(url).pipe(
      tap(() => {
        this.log(
          `fetched UserService.getByID [${userID}] response in ${
            performance.now() - start
          }ms`
        );
      }),
      map((response) => (response ? new User(response) : undefined)),
      catchError((err) => {
        this.handleError<User>('UserService.getByID');
        return throwError(() => err);
      })
    );
  }

  getAllUsers(
    filter = '',
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = []
  ): Observable<PagedResult<User>> {
    const url = `${this.apiUrl}/tenant/user`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<User>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(`fetched UserService.getAll response in ${performance.now() - start}ms`);
      }),
      map((response) =>
        response
          ? new PagedResult<User>(User, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<User>>('UserService.getAll');
        return throwError(() => err);
      })
    );
  }

  deleteUser(userID: string): Observable<User> {
    const url = `${this.apiUrl}/tenant/user/${userID}`;
    const start = performance.now();
    return this.http.delete<User>(url).pipe(
      tap(() => {
        this.log(`fetched UserService.delete response in ${performance.now() - start}ms`);
      }),
      catchError((err) => {
        this.handleError<User>('UserService.delete');
        return throwError(() => err);
      })
    );
  }

  updateUser(userID: string, roles: string[]): Observable<User> {
    const url = `${this.apiUrl}/tenant/user/${userID}`;
    const params = {
      roles
    };
    const start = performance.now();
    return this.http.patch<User>(url, params).pipe(
      tap(() => {
        this.log(
          `fetched UserService.updateUser response in ${performance.now() - start}ms`
        );
      }),
      catchError((err) => {
        this.handleError<User>('UserService.updateUser');
        return throwError(() => err);
      })
    );
  }

  updateLastLoginTimestamp(): Observable<NoContent> {
    const url = `${this.apiUrl}/tenant/lastlogin`;

    return this.http.patch(url, {}).pipe(
      catchError((err) => {
        this.handleError('TenantService.update');
        return throwError(() => err);
      })
    );
  }

  validateTenantName(name: string): Observable<TagResponse<TenantNameValidationTag>> {
    const url = `${this.cfUrl}/validate-tenant-name?name=${name}`;

    return this.http.get<TagResponse<TenantNameValidationTag>>(url, {}).pipe(
      catchError((err) => {
        this.handleError('TenantService.validateTenantName');
        return throwError(() => err);
      })
    );
  }
}
