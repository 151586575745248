import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tipOnEllip]'
})
export class TipOnEllipDirective {
  @Input('tipOnEllip') title: string;

  constructor(private renderer: Renderer2, private elRef: ElementRef<HTMLElement>) {}

  @HostListener('mouseenter') onMouseEnter() {
    const e = this.elRef.nativeElement;
    const sWidth = e.scrollWidth;
    const cWidth = e.clientWidth;

    if (sWidth > cWidth) {
      this.renderer.setAttribute(e, 'title', this.title || e.innerText);
    }
  }
}
