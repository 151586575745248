import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  iif,
  map,
  mergeMap,
  of,
  tap
} from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { ScriptService } from 'src/app/core/services/script.service';
import { SearchService } from 'src/app/core/services/search.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import {
  getScriptArgDetectRegex,
  getScriptValidCharRegex
} from 'src/app/core/utils/regex';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';
import { OS } from 'src/app/shared/models/system.model';
import { Task } from 'src/app/shared/models/task.model';
import { CustomValidators } from 'src/app/utils/validators';

export class ScriptInvokeParams {
  targetMachines: string | string[];
  script: Script | string;
  onSuccess: (task: Task) => void;
  constructor(init?: Partial<ScriptInvokeParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-script-invoke',
  templateUrl: './script-invoke.component.html',
  styleUrls: ['./script-invoke.component.scss'],
  animations: [fadeInRight400ms]
})
export class ScriptInvokeComponent implements OnInit {
  objectKeys = Object.keys;
  argDetect = getScriptArgDetectRegex();
  argValidChar = getScriptValidCharRegex();
  err: string;
  spin = true;
  overlayResult = OverlayResult.Unset;

  form: UntypedFormGroup;
  machineSelectCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  _script = new BehaviorSubject<Script>(undefined);
  script$ = this._script.asObservable();

  availParameters: string[] = [];
  targets: string[] = [];

  searchTerm$ = this.machineSelectCtrl.valueChanges.pipe(
    debounceTime(300),
    map(
      () => (!!this._machineInput ? this._machineInput.nativeElement.value : '') as string
    ),
    distinctUntilChanged()
  );

  machineSearching = false;
  searchResults$ = this.searchTerm$.pipe(
    tap({ next: () => (this.machineSearching = true) }),
    mergeMap((filter) =>
      iif(
        () => !!filter,
        this.searchSvc.getAgents(filter).pipe(
          map((results) =>
            results.items.filter(
              (a) => this.form.controls.targets.value.indexOf(a.id) < 0
            )
          ),
          map(
            (agents) =>
              (this.filteredAgentList = agents.filter((a) => a.system.os === OS.WINDOWS))
          )
        ),
        of([])
      )
    ),
    tap({ next: () => (this.machineSearching = false) })
  );
  filteredAgentList = [];

  taskResult: Task;

  _machineInput: ElementRef;
  @ViewChild('machineInput', { static: false }) set machineInput(
    machineInput: ElementRef
  ) {
    this._machineInput = machineInput;
    setTimeout(() => {
      if (this._machineInput) {
        const targetMachines = this.params.targetMachines;
        if (targetMachines) {
          const targets = targetMachines as string[];
          if (targets) {
            targets.forEach((t) => this._addTarget(t));
          } else {
            this._addTarget(targetMachines as string);
          }
        }
        this._machineInput.nativeElement.focus();
      }
    }, 250);
  }
  @ViewChild('machineAuto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('paramAuto') paramAutoComplete: MatAutocomplete;
  @ViewChild('customArgInput', { static: false }) customArgInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: ScriptInvokeParams,
    private dialogRef: MatDialogRef<ScriptInvokeComponent>,
    private fb: UntypedFormBuilder,
    private searchSvc: SearchService,
    private scriptSvc: ScriptService
  ) {
    this.form = this.fb.group({
      targets: [[], [CustomValidators.minChipCount(1)]],
      argNames: [[]],
      customArgs: ['']
    });

    this.form.controls.targets.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(
          () =>
            (this.filteredAgentList = this.filteredAgentList.filter(
              (a) => this.form.controls.targets.value.indexOf(a.id) < 0
            ))
        )
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.machineSelectCtrl.setValue('');
    this.machineSelectCtrl.updateValueAndValidity();

    of(this.params.script)
      .pipe(
        first(),
        mergeMap((script) =>
          iif(
            () => typeof script === 'string',
            this.scriptSvc
              .location(ScriptLocation.Private)
              .getByInstallRefID(script as string),
            of(script as Script)
          )
        ),
        map((script) => {
          script.parameters = script.parameters || {};

          Object.keys(script.parameters).forEach((p) => {
            const c = this.fb.control('');
            c.valueChanges
              .pipe(
                untilDestroyed(this),
                tap((v) => {
                  const found = this.form.controls.argNames.value.indexOf(p);
                  if (found === -1 && v) {
                    this.form.controls.argNames.setValue([
                      ...this.form.controls.argNames.value,
                      p
                    ]);
                  } else if (found !== -1 && !v) {
                    this.form.controls.argNames.value.splice(found, 1);
                    this.form.controls.argNames.setValue(
                      this.form.controls.argNames.value
                    );
                  }
                })
              )
              .subscribe();

            this.form.addControl('p-' + p, c);
          });

          return script;
        }),
        tap(() => (this.spin = false)),
        tap((script) => this._script.next(script))
      )
      .subscribe();
  }

  addTarget(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value || '';
    // Add our target
    if (value) {
      this._addTarget(value);
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTarget(target: string): void {
    const found = this.form.controls.targets.value.findIndex((t) => t === target);
    if (found >= 0) {
      const value = this.form.controls.targets.value;
      value.splice(found, 1);
      this.form.controls.targets.setValue(value);

      this.machineSelectCtrl.setValue(null);
      this.machineSelectCtrl.updateValueAndValidity();
    }
  }

  selectedMachine(event: MatAutocompleteSelectedEvent): void {
    this._addTarget(event.option.viewValue);
  }

  selectedParameter(param: string): void {
    const pos = this.customArgInput.nativeElement.selectionStart;
    const customArgs = this.customArgInput.nativeElement.value;

    const partialCustomArgs = customArgs.substring(0, pos);
    const partialParam = this.getAutoParam(pos, customArgs);
    const partialLastIndex = partialCustomArgs.lastIndexOf(partialParam);

    const newCustomArgs = `${partialCustomArgs.substring(
      0,
      partialLastIndex
    )}-${param}${customArgs.substring(pos)} `;
    this.form.controls.customArgs.setValue(newCustomArgs);

    setTimeout(
      () =>
        (this.customArgInput.nativeElement.selectionStart =
          this.customArgInput.nativeElement.selectionEnd =
            partialLastIndex + param.length + 2),
      0
    );
  }

  private _addTarget(target: string): void {
    target = target.trim().toLowerCase();

    if (!target) {
      return;
    }

    if (this.form.controls.targets.value.findIndex((a: string) => a === target) >= 0) {
      return;
    }

    const value = this.form.controls.targets.value;
    value.push(target);
    this.form.controls.targets.setValue(value);

    this._machineInput.nativeElement.value = '';
    this.machineSelectCtrl.setValue('');
    this.machineSelectCtrl.updateValueAndValidity();
    setTimeout(() => {
      if (!this.matAutocompleteTrigger.panelOpen) {
        this.matAutocompleteTrigger.openPanel();
      }
    }, 100);
  }

  submit(installRefID: string): void {
    this.spin = true;
    this.closeError();

    const args = {};

    const argNames: string[] = this.form.controls.argNames.value;

    argNames.forEach((a) => (args[a] = this.form.controls[`p-${a}`].value));

    this.scriptSvc
      .location(ScriptLocation.Private)
      .invoke(
        installRefID,
        this.form.controls.targets.value,
        this.customArgInput.nativeElement.value,
        args
      )
      .pipe(finalize(() => (this.spin = false)))
      .subscribe({
        next: (response) => {
          this.taskResult = response.tag;
          this.overlayResult = OverlayResult.Success;
        },
        error: (err: HttpErrorResponse) => {
          this.err = parseError(err);
          this.overlayResult = OverlayResult.Error;
        }
      });
  }

  closeError(): void {
    this.err = '';
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.(this.taskResult);
      this.dialogRef.close();
    }
  }

  selectParam(event: Event): void {
    const active = this.paramAutoComplete.options.filter((o) => o.active);

    if (active.length > 0) {
      active[0].select();
      event.preventDefault();
    }
  }

  getParams(parameters: unknown, input: HTMLInputElement): void {
    const params: string[] = Object.keys(parameters);

    const pos = input.selectionStart || 0;

    const v = this.getAutoParam(pos, input.value);

    if (this.argDetect.test(v)) {
      const m = this.argDetect.exec(v);

      this.availParameters =
        m.length > 0 && m[2]
          ? (this.availParameters = params.filter((p) =>
              p.toLocaleLowerCase().includes(m[2].toLocaleLowerCase())
            ))
          : params;

      return;
    }

    this.availParameters = [];
  }

  getAutoParam(pos: number, p: string): string {
    let b: string;
    let quoteCount = 0;

    let prev = pos - 1;
    // const next = pos + 1;

    const currChar = p.charAt(pos);

    if (currChar !== '' && currChar !== ' ') {
      return undefined;
    }

    while (prev >= 0) {
      if (prev < 0) {
        break;
      }

      let c = p.charAt(prev);

      if (this.argValidChar.test(c)) {
        b = c + (b || '');
      } else {
        while (prev >= 0) {
          if (c === '"') {
            quoteCount++;
          }

          c = p.charAt(prev);
          prev--;
        }
      }

      prev--;
    }

    if (quoteCount % 2 === 1) {
      return undefined;
    }

    return b;
  }
}
