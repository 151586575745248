import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { FillHeightDirective } from './directives/fill-height.directive';
import { HasPermDirective } from './directives/has-perm.directive';
import { ImageLoadedDirective } from './directives/image-loaded.directive';
import { LoadedDirective } from './directives/loaded.directive';
import { LetDirective } from './directives/ng-let.directive';
import { OverflowClassesDirective } from './directives/overflow-classes.directive';
import { RedirectDirective } from './directives/redirect.directive';
import { TipOnEllipDirective } from './directives/tip-on-ellip.directive';
import { CallOncePipe } from './pipes/call-once.pipe';
import { DataSizeInBitsPipe, DataSizeInBytesPipe } from './pipes/data-size.pipe';
import { DateMethodPipe } from './pipes/date-method.pipe';
import { DistancePipe } from './pipes/distance.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { NumberToDatePipe } from './pipes/number-to-date.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { StrictDatePipe } from './pipes/strict-date.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TimeSpanPipe } from './pipes/time-span.pipe';
import { TabSelectedDirective } from './directives/tab-selected.directive';

@NgModule({
  declarations: [
    CallOncePipe,
    DataSizeInBitsPipe,
    DataSizeInBytesPipe,
    DistancePipe,
    FillHeightDirective,
    HasPermDirective,
    ImageLoadedDirective,
    LetDirective,
    LoadedDirective,
    NumberToDatePipe,
    OverflowClassesDirective,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    StrictDatePipe,
    TimeAgoPipe,
    TimeSpanPipe,
    TipOnEllipDirective,
    RedirectDirective,
    DateMethodPipe,
    EnumToArrayPipe,
    AutoFocusDirective,
    TabSelectedDirective
  ],
  imports: [CommonModule],
  exports: [
    CallOncePipe,
    DataSizeInBitsPipe,
    DataSizeInBytesPipe,
    DistancePipe,
    FillHeightDirective,
    HasPermDirective,
    ImageLoadedDirective,
    LetDirective,
    LoadedDirective,
    NumberToDatePipe,
    OverflowClassesDirective,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    StrictDatePipe,
    TimeAgoPipe,
    TimeSpanPipe,
    TipOnEllipDirective,
    RedirectDirective,
    DateMethodPipe,
    EnumToArrayPipe,
    AutoFocusDirective,
    TabSelectedDirective
  ]
})
export class SharedModule {}
