import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { Observable, combineLatest, filter, map } from 'rxjs';
import {
  ComponentDisplayConfig,
  DisplayConfig
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { MachineStatsDisplayConfig } from 'src/app/view/dashboard/widget/machine-stats/machine-stats.component';
import { GeoLocationDisplayConfig } from 'src/app/view/dashboard/widget/geo-location/geo-location.component';
import { CurrentUsersDisplayConfig } from 'src/app/view/dashboard/widget/current-users/current-users.component';
import { LiveGraphDisplayConfig } from 'src/app/view/dashboard/widget/live-graph/live-graph.component';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  userSettings$ = this.stateSvc.currentUserSettings$;

  constructor(private stateSvc: StateService) {}

  getMachineStatsDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = MachineStatsDisplayConfig;
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getGeoLocationDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = GeoLocationDisplayConfig;
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getCurrentUsersDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = CurrentUsersDisplayConfig;
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getTopCPUDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = new ComponentDisplayConfig({
          id: 'widget.top-cpu-table',
          name: 'Top 5 CPU Consumers',
          show: true,
          fields: []
        });
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getTopMemDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = new ComponentDisplayConfig({
          id: 'widget.top-ram-table',
          name: 'Top 5 RAM Consumers',
          show: true,
          fields: []
        });
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getTopDiskDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = new ComponentDisplayConfig({
          id: 'widget.top-disk-table',
          name: 'Top 5 Disk Consumers',
          show: true,
          fields: []
        });
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getTopNetDisplayConfig = (): Observable<ComponentDisplayConfig> => {
    return this.userSettings$.pipe(
      map((s) => {
        const config = new ComponentDisplayConfig({
          id: 'widget.top-network-table',
          name: 'Top 5 Net Consumers',
          show: true,
          fields: []
        });
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getCPUGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig('widget.cpu-graph', 'CPU Graphs', p);
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getMemGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig('widget.memory-graph', 'RAM Graphs', p);
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getDiskGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig('widget.disk-graph', 'Disk Graphs', p);
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getEthGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig(
          'widget.ethernet-graph',
          'Ethernet Graphs',
          p
        );
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getWifiGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig('widget.wifi-graph', 'Wifi Graphs', p);
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getGPUGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig('widget.gpu-graph', 'GPU Graphs', p);
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getBatteryGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig(
          'widget.battery-graph',
          'Battery Graphs',
          p
        );
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };

  getDisplayProtocolGraphConfig = (
    properties$: Observable<string[]>
  ): Observable<ComponentDisplayConfig> => {
    return combineLatest([
      this.userSettings$,
      properties$.pipe(filter((p) => p.length > 0))
    ]).pipe(
      map(([s, p]) => {
        const config = LiveGraphDisplayConfig(
          'widget.dp-graph',
          'Display Protocol Graphs',
          p
        );
        const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
        return config.merge(saved);
      })
    );
  };
}
