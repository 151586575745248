export class PagedResult<T> {
  public items: Array<T>;
  public count: number;
  public page: number;
  public limit: number;

  constructor(type: new (...args: T[]) => T, init?: Partial<PagedResult<T>>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
    if (this.items) {
      if (type) {
        this.items = this.items.map((s) => {
          return new type(s);
        });
      } else {
        this.items = this.items.map((s) => {
          return s as T;
        });
      }
    }
  }

  nextPageAvailable(): boolean {
    return this.items.length < this.count
      ? this.limit * this.page + this.items.length < this.count
      : false;
  }

  prevPageAvailable(): boolean {
    return this.page > 0;
  }
}
