import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, filter, map } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { StateService } from 'src/app/core/services/state.service';
import { SubscriptionLicenseResult } from 'src/app/core/services/subscribe.service';
import { ReactivateComponent } from 'src/app/shared/components/subscription/reactivate/reactivate.component';

import { TenantSwitchComponent, TenantSwitchParams } from '../settings/tenant/tenant-switch/tenant-switch.component';

@Component({
  selector: 'mon-expired-subscription',
  templateUrl: './expired-subscription.component.html',
  styleUrls: ['./expired-subscription.component.scss'],
  animations: [fadeInUp400ms]
})
export class ExpiredSubscriptionComponent {
  tenant$ = this.stateSvc.tenant$;
  user$ = this.stateSvc.currentUser$;
  isTenantOwner$ = combineLatest([this.tenant$, this.user$]).pipe(
    filter(([t, u]) => !!t && !!u),
    map(([t, u]) => {
      if (t.ownerEmail == u.email) {
        return true;
      }

      return false;
    })
  );

  constructor(private dialog: MatDialog, private stateSvc: StateService) {}

  switchTenant() {
    this.dialog.open(TenantSwitchComponent, {
      restoreFocus: false,
      data: new TenantSwitchParams({}),
      width: '600px'
    });
  }

  reactivateSubscription(): void {
    this.dialog.open(ReactivateComponent, {
      restoreFocus: false,
      data: {
        onConfirm: (_result: SubscriptionLicenseResult) => {
          window.location.reload();
        }
      }
    });
  }
}
