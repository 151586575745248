<div class="script-search flex flex-col items-center md:justify-center h-full w-full" mat-dialog-content>
  <div class="flex flex-col items-center justify-center w-full">
    <div class="w-full md:w-96 flex flex-col">
      <div class="flex justify-end"> </div>
      <div class="relative flex flex-col flex-1">
        <mat-form-field class="w-full">
          <input
            matInput
            #filter
            class="border-b-2 text-2xl focus:outline-none rounded-none border-r-0 border-l-0 border-t-0 bg-transparent w-full"
            placeholder="Search For Script"
            cdkFocusInitial
            (keyup)="_searchInput.next(filter.value)"
            [matAutocomplete]="auto"
            [value]="scriptName$ | async"
            data-intro-key="shell-connect-input"
          />
          <button matRipple *ngIf="filter.value" (click)="filter.value = ''; onFilter(filter.value)" class="rounded-full w-6 h-6" matSuffix>
            <fa-icon [icon]="['fad', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
          </button>
        </mat-form-field>
        <div class="relative w-full">
          <mat-progress-bar
            *ngIf="searching$ | async"
            mode="indeterminate"
            color="primary"
            class="absolute"
            [style.height]="'2px'"
            [style.top]="'-6px'"
          ></mat-progress-bar>
        </div>
        <mat-autocomplete #auto (optionSelected)="dropdownSelect($event)" [autoActiveFirstOption]="true" matAutocompleteTrigger>
          <mat-option *ngFor="let result of searchResults$ | async" [value]="result" [disabled]="searching$ | async" class="h-auto script-search-option">
            <mon-script-list-item [script]="result" [showHover]="false"></mon-script-list-item>
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
  </div>
</div>
