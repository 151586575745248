import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCog, faTelescope } from '@fortawesome/pro-duotone-svg-icons';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlarmsComponent } from '../alarms/alarms.component';

@NgModule({
  declarations: [AlarmsComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    ComponentsModule,
    SharedModule
  ]
})
export class AlarmsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCog, faTelescope);
  }
}
