import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, iif, map, of as observableOf, switchMap, take, tap } from 'rxjs';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import {
  Computer,
  Hardware,
  Sensor
} from 'src/app/shared/interfaces/open-hardware-monitor.interface';
import { Agent } from 'src/app/shared/models/agent.model';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { deepCopy } from 'src/app/utils/clone';

export interface DataNode {
  name: string;
  type: string;
  children?: DataNode[];
}

export interface TreeNode {
  name: string;
  type: string;
  level: number;
  expandable: boolean;
}

@UntilDestroy()
@Component({
  selector: 'mon-open-hardware-monitor',
  templateUrl: './open-hardware-monitor.component.html',
  styleUrls: ['./open-hardware-monitor.component.scss']
})
export class OpenHardwareMonitorComponent implements OnInit {
  constructor(
    protected dialogRef: MatDialogRef<OpenHardwareMonitorComponent>,
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private diagnosticsService: DiagnosticsService,
    private pubSubSvc: PubSubWsService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );

    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<TreeNode>;
  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<DataNode, TreeNode>;
  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<DataNode, TreeNode>;

  spin: boolean;
  overlayResult = OverlayResult.Unset;
  agent: Agent;
  ranOnce = false;
  err: string;

  icons = {
    Computer: 'computer-classic',
    CPU: 'microchip',
    Mainboard: 'engine',
    TypeTemperatures: 'temperature-high',
    GpuNvidia: 'monitor-heart-rate',
    TypeFans: 'fan',
    SuperIO: 'memory',
    TypeLoad: 'chart-line',
    TypeData: 'binary'
  };

  treeIcons = {
    true: 'chevron-down',
    false: 'chevron-right'
  };

  ngOnInit(): void {
    this.spin = true;
  }

  agent$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    tap((agent) => {
      this.agent = agent;
      this.getSystemInformation();
    }),
    take(1)
  );

  readonly channelName$ = this.agent$.pipe(
    map((a) => `$diagnostic:web.${this.stateSvc.tenant.id}.${a.id}.ohmResponse`)
  );

  readonly channelListener$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          if (packet.type === PacketType.OhmPacket) {
            const v: Computer = JSON.parse(<string>packet.content);
            this.updateTree(v);
          } else if (packet.type === PacketType.StartPacket) {
            // const p: PacketStart = JSON.parse(<string>packet.content);
            //this.timestamp = p.timestamp;
          } else if (packet.type === PacketType.CompletePacket) {
            //setTimeout(() => this._running.next(false), 2000);
          } else if (packet.type === PacketType.ErrorPacket) {
            //setTimeout(() => this._running.next(false), 2000);
            this.updateError(<string>packet.content);
          }
        })
      )
    )
  );

  updateError(error: string) {
    this.spin = false;
    this.dataSource.data = [];

    if (error === 'ohmNotAvailable') {
      this.overlayResult = OverlayResult.Error;
      this.err =
        'Open Hardware Monitor is not enabled. Please talk to your administrator.';
    }
  }

  updateTree(c: Computer) {
    this.spin = false;
    this.dataSource.data = this.toTreeNodes([c]);

    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }

  closeError() {
    this.err = '';
  }

  overlayClose(r: OverlayResult) {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.dialogRef.close();
    }
  }

  getSystemInformation() {
    if (!this.ranOnce) {
      this.ranOnce = true;
    } else {
      return;
    }

    this.diagnosticsService.runOHM(this.agent.id).subscribe({
      error: (err) => {
        console.warn(err);
      }
    });
  }

  /** Transform the data to something the tree can read. */
  transformer(node: DataNode, level: number) {
    return {
      name: node.name,
      type: node.type,
      level: level,
      expandable: node.children.length > 0
    };
  }

  /** Get the level of the node */
  getLevel(node: TreeNode) {
    return node.level;
  }

  /** Return whether the node is expanded or not. */
  isExpandable(node: TreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: DataNode) {
    return observableOf(node.children);
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: TreeNode) {
    return node.expandable;
  }

  toTreeNodes(computers: Computer[]): DataNode[] {
    return computers.map((computer) => {
      const hardwareNodes = computer.hardware.map((hardware) =>
        this.toHardwareNode(hardware)
      );
      return {
        name: computer.name,
        type: 'Computer',
        children: hardwareNodes
      };
    });
  }

  toHardwareNode(hardware: Hardware): DataNode {
    const typeNodes = this.toTypeNodes(hardware.sensors);
    const subHardwareNodes = hardware.subHardware.map((subHardware) =>
      this.toHardwareNode(subHardware)
    );
    return {
      name: hardware.name,
      type: hardware.hardwareType,
      children: [...typeNodes, ...subHardwareNodes]
    };
  }

  toTypeNodes(sensors: Sensor[]): DataNode[] {
    const sensorTypes = sensors.map((s) => s.sensorType);
    const uniqueSensorTypes = [...new Set(sensorTypes)];

    // remove sensorType from uniqueSensorTypes
    const index = uniqueSensorTypes.indexOf('Clock');
    if (index > -1) {
      uniqueSensorTypes.splice(index, 1);
    }
    const index1 = uniqueSensorTypes.indexOf('Power');
    if (index1 > -1) {
      uniqueSensorTypes.splice(index1, 1);
    }
    const index3 = uniqueSensorTypes.indexOf('Control');
    if (index3 > -1) {
      uniqueSensorTypes.splice(index3, 1);
    }
    const index4 = uniqueSensorTypes.indexOf('Voltage');
    if (index4 > -1) {
      uniqueSensorTypes.splice(index4, 1);
    }

    return uniqueSensorTypes.map((sensorType) => {
      const sensorsOfType = sensors.filter((s) => s.sensorType === sensorType);
      const sensorNodes = sensorsOfType.map((sensor) => this.toSensorNode(sensor));

      let name = sensorType;
      switch (sensorType) {
        case 'Fan':
          name = 'Fans';
          break;
        case 'Temperature':
          name = 'Temperatures';
          break;
        case 'SmallData':
          name = 'Data';
          break;
      }

      return {
        name: name,
        type: 'Type' + name,
        children: [...sensorNodes]
      };
    });
  }

  toSensorNode(sensor: Sensor): DataNode {
    let value = `${sensor.value}`;
    switch (sensor.sensorType) {
      case 'Temperature':
        value = `${value} °C`;
        break;
      case 'Fan':
        value = `${sensor.value.toFixed(0)} RPM`;
        break;
      case 'Load':
        value = `${sensor.value.toFixed(2)} %`;
        break;
      case 'SmallData':
        value = `${sensor.value.toFixed(1)} MB`;
        break;
    }

    const name = `${sensor.name}: ${value}`;

    return {
      name: name,
      type: sensor.sensorType,
      children: [] // [...sensorValues]
    };
  }
}
