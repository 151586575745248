import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { RegistrationService } from 'src/app/core/services/registration.service';
import {
  ERR_WEBCODE_WAITING_ON_CONFIRM,
  isWebErrorResponse,
  parseError
} from 'src/app/core/utils/http-reponse-error';
import { getEmailRegex } from 'src/app/core/utils/regex';
import { scaleOut400ms } from 'src/app/shared/animations/scale-out.animation';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'mon-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [scaleOut400ms, fadeInUp400ms]
})
export class RegisterComponent implements OnInit {
  spin: boolean;
  registrationForm: UntypedFormGroup;
  registrationDispatched: string;

  _emailInput: ElementRef;
  @ViewChild('email', { static: false }) set emailInput(emailInput: ElementRef) {
    this._emailInput = emailInput;
    if (this._emailInput) {
      setTimeout(() => this._emailInput.nativeElement.focus(), 100);
    }
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private registrationSvc: RegistrationService
  ) {}

  ngOnInit(): void {
    console.log('register3');
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(getEmailRegex())]]
    });
  }

  goToSignin(): void {
    this.router.navigate(['']);
  }

  registerTenant(resend = false): void {
    if (this.registrationForm.invalid) {
      return;
    }

    this.spin = true;

    this.registrationSvc
      .registerTenant(this.registrationForm.value.email, resend)
      .pipe(finalize(() => (this.spin = false)))
      .subscribe({
        next: (res) => {
          this.registrationDispatched = res?.message || 'check your email to confirm';
        },
        error: (err: HttpErrorResponse) => {
          const webErr = isWebErrorResponse(err);
          if (webErr) {
            switch (webErr.webcode) {
              case ERR_WEBCODE_WAITING_ON_CONFIRM:
                this.dialog.open(ConfirmDialogComponent, {
                  restoreFocus: false,
                  data: {
                    icon: `paper-plane`,
                    title: `This email already has an invite. Resend invite?`,
                    confirmationText: `Would you like to send another invite to this email?`,
                    onConfirm: () => {
                      this.registerTenant(true);
                    },
                    onCancel: () => {}
                  }
                });
                return;
            }
          }

          const errMsg = parseError(err) || 'could not register email';
          this.snackBar.open(errMsg.toUpperCase(), 'CLOSE', {
            duration: 10000,
            panelClass: ['error-snack'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      });
  }
}
