import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { MarkdownModule } from 'ngx-markdown';
import { VexModule } from 'src/@vex/vex.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GmInterceptor } from './core/interceptors/gm.interceptor';
import { PermissionInterceptor } from './core/interceptors/permission.interceptor';
import { SerializerInterceptor } from './core/interceptors/serializer.interceptor';
import { TenantInterceptor } from './core/interceptors/tenant.interceptor';
import { ApiService } from './core/services/api.service';
import { AuthService } from '@auth0/auth0-angular';
import { ShellModule } from './view/shell/shell.module';
import { ViewModule } from './view/view.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ClipboardModule,
    AuthModule.forRoot({
      authorizationParams: {
        audience: `https://${environment.auth0Domain}/api/v2/`,
        redirect_uri: `${window.location.origin}`,
        scope: 'openid profile email offline_access'
      },
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientID,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          {
            uriMatcher: (uri) =>
              uri.indexOf(`${ApiService.API_URL}`) === 0 ||
              uri.indexOf(`${ApiService.BASE_WS_URL}`) === 0
          }
        ]
      }
    }),
    MarkdownModule.forRoot(),
    // Vex
    VexModule,
    ViewModule,
    ShellModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SerializerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GmInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PermissionInterceptor, multi: true },
    AuthService,
    // CURRENTLY GOOGLE APP ENGINE STANDARD DOES NOT SUPPORT WEBSOCKETS
    // { provide: HTTP_INTERCEPTORS, useClass: WebSocketInterceptor, multi: true }
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
