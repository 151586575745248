import { Updatable } from '../interfaces/updatable.interface';

export class ProcessConnection implements Updatable<ProcessConnection> {
  public processID: number;
  public localPort: number;
  public remotePort: number;
  public localAddress: string;
  public remoteAddress: string;
  public localIP: string;
  public remoteIP: string;
  public receive: number;
  public send: number;
  public rxRate: number;
  public txRate: number;

  public constructor(init?: Partial<ProcessConnection>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }

  update(input: Partial<ProcessConnection>): this {
    return Object.assign(this, input);
  }

  equals(c?: Partial<ProcessConnection>): boolean {
    if (this === c) {
      return true;
    }

    if (
      this.processID === c.processID &&
      this.localPort === c.localPort &&
      this.remotePort === c.remotePort &&
      this.localAddress === c.localAddress &&
      this.remoteAddress === c.remoteAddress &&
      this.localIP === c.localIP &&
      this.remoteIP === c.remoteIP &&
      this.receive === c.receive &&
      this.send === c.send &&
      this.rxRate === c.rxRate &&
      this.txRate === c.txRate
    ) {
      return true;
    }

    return false;
  }
}
