import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { deepEqual } from 'fast-equals';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  iif,
  of,
  retry,
  throwError,
  timer
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { DeepPartial } from 'src/@vex/interfaces/deep-partial.type';
import { Agent } from 'src/app/shared/models/agent.model';
import {
  ContextUser,
  ContextUserSettings
} from 'src/app/shared/models/context-user.model';
import { Tenant } from 'src/app/shared/models/tenant.model';
import { deepCopy, deepMerge } from 'src/app/utils/clone';
import { UNDEFINED } from 'src/app/utils/rxjs';

import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { FullStoryService } from './full-story.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
import { AgentBlock } from 'src/app/shared/models/agent-block.model';

export enum SelectedAgentState {
  NotFound = 0,
  Found = 1,
  Searching = 2,
  Undefined = 3
}

export interface SelectedAgent {
  agentID: string;
  agentBlocks?: AgentBlock[];
  state: SelectedAgentState;
}

@Injectable({ providedIn: 'root' })
export class StateService extends ApiService {
  private _liveMode = new BehaviorSubject<boolean>(true);
  readonly liveMode$ = this._liveMode.asObservable();
  get liveMode(): boolean {
    return this._liveMode.value;
  }

  private _agentRequired = new BehaviorSubject<boolean>(false);
  readonly agentRequired$ = this._agentRequired.asObservable();

  private _disconnectedCalls = 0;
  private _serverDisconnected = new BehaviorSubject<boolean>(false);
  readonly serverDisconnected$ = this._serverDisconnected.asObservable();
  get serverDisconnected(): boolean {
    return this._serverDisconnected.value;
  }

  private _documentFocus = new BehaviorSubject<boolean>(
    this.document.visibilityState !== 'hidden'
  );
  readonly documentFocus$ = this._documentFocus.asObservable();
  get documentFocus(): boolean {
    return this._documentFocus.value;
  }

  private _documentIdle = new BehaviorSubject<boolean>(false);
  readonly documentIdle$ = this._documentIdle.asObservable();
  get documentIdle(): boolean {
    return this._documentIdle.value;
  }

  private _userIdle = new BehaviorSubject<boolean>(false);
  readonly userIdle$ = this._userIdle.asObservable();
  get userIdle(): boolean {
    return this._userIdle.value;
  }

  private _stopPolling = new BehaviorSubject<boolean>(false);
  readonly stopPolling$ = this._stopPolling.asObservable();
  get stopPolling(): boolean {
    return this._stopPolling.value;
  }

  private _search = new BehaviorSubject<string>('');
  readonly search$ = this._search.asObservable();
  get search(): string {
    return this._search.value;
  }

  private _tenant = new BehaviorSubject<Tenant>(undefined);
  readonly tenant$ = this._tenant.asObservable();
  get tenant(): Tenant {
    return this._tenant.value;
  }

  private _currentUser = new BehaviorSubject<ContextUser>(undefined);
  readonly currentUser$ = this._currentUser.asObservable();
  get currentUser(): ContextUser {
    return this._currentUser.value;
  }

  private _currentUserSettings = new BehaviorSubject<ContextUserSettings>(undefined);
  readonly currentUserSettings$ = this._currentUserSettings.asObservable();
  get currentUserSettings(): ContextUserSettings {
    return this._currentUserSettings.value;
  }

  private _selectedAgent = new BehaviorSubject<SelectedAgent>({
    agentID: '',
    state: SelectedAgentState.NotFound
  });
  readonly selectedAgent$ = this._selectedAgent.asObservable();
  get selectedAgent(): SelectedAgent {
    return this._selectedAgent.getValue();
  }

  private _refreshTenantInvites = new BehaviorSubject<void>(undefined);
  tenantInvites$ = this._refreshTenantInvites.asObservable().pipe(
    mergeMap(() => this.userSvc.getInvites()),
    map((result) => result.items || [])
  );

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private fullStorySvc: FullStoryService,
    private authSvc: AuthService,
    private userSvc: UserService,
    private tenantSvc: TenantService
  ) {
    super();

    this.router.events
      .pipe(filter((event) => event instanceof ActivationStart))
      .subscribe({
        next: (event: ActivationStart) => this.checkIfRouteNeedsAgent(event)
      });
  }

  start(): void {
    this.document.addEventListener(
      'visibilitychange',
      () => {
        this.setDocumentFocus(this.document.visibilityState !== 'hidden');
      },
      false
    );

    window.addEventListener('offline', () => this.setServerDisconnected(true));

    // window.addEventListener('online', () => this.setServerDisconnected(false));

    const inactivityTimer = (state: StateService) => {
      // DOM Events
      this.document.onload = resetTimer;
      this.document.onmousemove = resetTimer;
      this.document.onmousedown = resetTimer;
      this.document.ontouchstart = resetTimer;
      this.document.onclick = resetTimer;
      this.document.onkeydown = resetTimer;
      this.document.addEventListener('scroll', resetTimer, { passive: false });
      this.document.addEventListener('wheel', resetTimer, { passive: false });

      let inactivityTimeout: NodeJS.Timeout;
      function resetTimer() {
        state.setDocumentIdle(false);
        if (inactivityTimeout) {
          clearTimeout(inactivityTimeout);
        }
        const timeout = environment['idleTimeout'] || 15 * 60 * 1000;
        inactivityTimeout = setTimeout(() => state.setDocumentIdle(true), timeout);
      }
      resetTimer();
    };

    inactivityTimer(this);

    this.tenant$
      .pipe(
        distinctUntilChanged((p, c) => {
          const pID = p?.id || '';
          const cID = c?.id || '';

          if (pID !== cID) {
            // mark tenant timestamp
            this.tenantSvc.updateLastLoginTimestamp().subscribe();
          }

          return false;
        }),
        filter((tenant) => !tenant),
        tap(() => this.setSelectedAgent('', SelectedAgentState.NotFound))
      )
      .subscribe();

    this.search$
      .pipe(
        tap((agentID) => {
          this.setLiveMode(true);

          if (agentID) {
            this.setSelectedAgent(agentID, SelectedAgentState.Searching);
          }

          if (agentID && this.route.component) {
            this.currentUserSettings$
              .pipe(
                take(1),
                tap((settings) => {
                  const fragments = settings.consoleUI.config.search.goToDashboard
                    ? ['home', 'dashboard']
                    : [location.pathname];
                  this.router.navigate(fragments, {
                    relativeTo: this.route,
                    queryParams: { machineID: agentID },
                    queryParamsHandling: 'merge'
                  });
                })
              )
              .subscribe();
          }
        }),
        tap((agentID) => {
          if (!!agentID) {
            this.verifyAgentExists(agentID)
              .pipe(
                tap((agent) => {
                  this.setSelectedAgent(agent.id, SelectedAgentState.Found);
                }),
                catchError(() => {
                  this.setSelectedAgent(agentID, SelectedAgentState.NotFound);
                  return UNDEFINED;
                })
              )
              .subscribe();
          } else {
            this.setSelectedAgent(undefined, SelectedAgentState.Undefined);
          }
        })
      )
      .subscribe();

    this.serverDisconnected$
      .pipe(
        tap((disconnected) => {
          if (disconnected) {
            const waitForConnection = timer(0)
              .pipe(
                mergeMap(() => {
                  if (window.navigator.onLine) {
                    return this.http.get(ApiService.API_URL);
                  } else {
                    return throwError(() => 'offline');
                  }
                }),
                retry({
                  delay: (e, _retryCount) => {
                    if (e.error === 'login_required' || e.status === 401) {
                      this.authSvc.logout({
                        logoutParams: { returnTo: this.document.location.origin }
                      });
                    }

                    if (e === 'offline' || e.status !== 404) {
                      return timer(3000);
                    }

                    return throwError(() => 'we are back up');
                  }
                }),
                catchError(() => of('API is back up'))
              )
              .subscribe({
                next: (msg) => {
                  this.log(msg);
                  this.setServerDisconnected(false);
                  waitForConnection.unsubscribe();
                }
              });
          }
        })
      )
      .subscribe();

    this.currentUserSettings$
      .pipe(switchMap((settings) => this.userSvc.updateSettings(settings)))
      .subscribe();

    timer(0, 2 * 60 * 1000) // every 5 minutes, check for invite updates
      .pipe(
        switchMap(() => this.stopPolling$),
        filter((stopPolling) => !stopPolling),
        tap(() => this.refreshTenantInvites())
      )
      .subscribe();
  }

  setUserIdle(userIdle: boolean): void {
    if (this.userIdle === userIdle) {
      return;
    }
    this.log(`userIdle update to: ${userIdle}`);
    this._userIdle.next(userIdle);
    if (userIdle) {
      this.setStopPolling(true);
      return;
    }
    if (
      !userIdle &&
      this.documentFocus &&
      !this.documentIdle &&
      !this.serverDisconnected
    ) {
      this.setStopPolling(false);
    }
  }

  setLiveMode(liveMode: boolean): void {
    if (this.liveMode === liveMode) {
      return;
    }
    this._liveMode.next(liveMode);
  }

  setTenant(tenant: Tenant): void {
    if (this.tenant === tenant) {
      return;
    }
    if (this.tenant && tenant) {
      if (this.tenant.timestamp === tenant.timestamp) {
        return;
      }
    }

    this.userSvc // pull back user to get updated permissions based on this tenant
      .me()
      .pipe(
        tap((user) => {
          this.setCurrentUser(user);
        }),
        switchMap((user) =>
          combineLatest([
            this.tenantSvc.getPublicSettings('tenant.allowFlowTracking'),
            of(user)
          ])
        ),
        tap(([settings, user]) => {
          if (!environment.localDev) {
            const allowFullstory = settings.get('tenant.allowFlowTracking');
            if (allowFullstory) {
              this.fullStorySvc.identify(user);
              this.fullStorySvc.restart();
            } else {
              this.fullStorySvc.shutdown();
            }
          }
          this.log(`tenant update to: ${tenant ? tenant.id : 'null'}`);
          this._tenant.next(tenant);
        })
      )
      .subscribe();
  }

  setCurrentUser(currentUser: ContextUser): void {
    if (this.currentUser === currentUser) {
      return;
    }
    if (this.currentUser && currentUser) {
      if (this.currentUser.timestamp === currentUser.timestamp) {
        return;
      }
    }
    this.log(`current user update to: ${currentUser ? currentUser.email : 'null'}`);
    this._currentUser.next(currentUser);
  }

  setCurrentUserSettings(currentUserSettings: ContextUserSettings): void {
    if (this.currentUserSettings === currentUserSettings) {
      return;
    }

    if (deepEqual(this.currentUserSettings, currentUserSettings)) {
      return;
    }

    this.log(`current user settings updated`);
    const n = new ContextUserSettings(currentUserSettings);
    this._currentUserSettings.next(n);
  }

  /**
   * Updates the current settings by merging settings. If you wish to completely overwrite user settings use @function setCurrentUserSettings.
   * @param currentUserSettings settings to be merged.
   */
  updateCurrentUserSettings(currentUserSettings: DeepPartial<ContextUserSettings>): void {
    const copy = deepCopy(this.currentUserSettings);
    const merged = deepMerge([copy, currentUserSettings as ContextUserSettings]);
    this.setCurrentUserSettings(merged);
  }

  setSearch(search: string): void {
    if (this.search === search) {
      return;
    }
    this.log(`search update to: ${search}`);
    this._search.next(search);
  }

  setServerDisconnected(disconnected: boolean): void {
    if (this.serverDisconnected === disconnected) {
      return;
    }

    if (disconnected) {
      this._disconnectedCalls++;
      if (this._disconnectedCalls < 2) {
        return;
      }
    } else {
      this._disconnectedCalls = 0;
    }

    this.log(`serverDisconnected update to: ${disconnected}`);
    this._serverDisconnected.next(disconnected);
    if (disconnected) {
      this.setStopPolling(true);
      return;
    }
    if (!disconnected && this.documentFocus && !this.documentIdle && !this.userIdle) {
      this.setStopPolling(false);
    }
  }

  setTenantAndCompanyName(patch: { name?: string; companyName?: string }): void {
    const t = this._tenant.getValue();
    this._tenant.next(Object.assign(t, patch));
  }

  refreshTenantInvites(): void {
    this._refreshTenantInvites.next();
  }

  refreshSelectedAgent(
    agentID: string = undefined,
    state: SelectedAgentState = undefined
  ): void {
    if (agentID === undefined && state == undefined) {
      agentID = this.selectedAgent.agentID;
      state = this.selectedAgent.state;
    }

    iif(
      () => agentID && state === SelectedAgentState.Found,
      this.tenantSvc.getBlockedAgents(agentID),
      of([])
    )
      .pipe(
        tap((agentBlocks) => this._selectedAgent.next({ agentID, agentBlocks, state }))
      )
      .subscribe();
  }

  private setDocumentFocus(documentFocus: boolean): void {
    if (this.documentFocus === documentFocus) {
      return;
    }
    this.log(`documentFocus update to: ${documentFocus}`);
    this._documentFocus.next(documentFocus);
    if (!documentFocus) {
      this.setStopPolling(true);
      return;
    }
    if (
      documentFocus &&
      !this.documentIdle &&
      !this.userIdle &&
      !this.serverDisconnected
    ) {
      this.setStopPolling(false);
    }
  }

  private setDocumentIdle(documentIdle: boolean): void {
    if (this.documentIdle === documentIdle) {
      return;
    }
    this.log(`documentIdle update to: ${documentIdle}`);
    this._documentIdle.next(documentIdle);
    if (documentIdle) {
      this.setStopPolling(true);
      return;
    }
    if (
      !documentIdle &&
      this.documentFocus &&
      !this.userIdle &&
      !this.serverDisconnected
    ) {
      this.setStopPolling(false);
    }
  }

  private setStopPolling(stopPolling: boolean): void {
    if (this.stopPolling === stopPolling) {
      return;
    }
    this.log(`stopPolling update to: ${stopPolling}`);
    this._stopPolling.next(stopPolling);
  }

  private setSelectedAgent(agentID: string, state: SelectedAgentState): void {
    if (this.selectedAgent.agentID === agentID && this.selectedAgent.state === state) {
      return;
    }

    this.log(`selected agent updated to: ${agentID ? agentID : '<empty>'} => ${state}`);

    this.refreshSelectedAgent(agentID, state);
  }

  private checkIfRouteNeedsAgent(event: ActivationStart) {
    if (event.snapshot.data) {
      const data = event.snapshot.data;
      const requiresAgent = data.requiresAgent === undefined ? false : data.requiresAgent;
      this._agentRequired.next(requiresAgent);
    }
  }

  private verifyAgentExists(agentID: string): Observable<Agent> {
    const url = `${this.apiUrl}/agent/${agentID}`;
    const start = performance.now();
    return this.http.get<Agent>(url).pipe(
      tap(() =>
        this.log(
          `fetched StateService.verifyAgentExists [${agentID}] response in ${
            performance.now() - start
          }ms`
        )
      ),
      map((response) => new Agent(response)),
      catchError((err) => {
        this.handleError<Agent>('StateService.verifyAgentExists');
        return throwError(() => err);
      })
    );
  }
}
