<mon-spinner [spin]="spin" [overlay]="true">
  <div class="flex flex-col w-full h-full" content>
    <h4>Event Logs</h4>
    <div class="flex-1 flex">
      <div class="pl-2 flex-1 flex flex-col">
        <div class="grid grid-cols-12 auto-cols-max gap-x-2 max-h-32 overflow-auto">
          <div class="col-span-4 text-secondary" [style.font-variant]="'small-caps'">Timestamp</div>
          <div class="col-span-2 text-secondary" [style.font-variant]="'small-caps'">Type</div>
          <div class="col-span-5 text-secondary" [style.font-variant]="'small-caps'">Name</div>
          <div class="col-span-1 text-secondary" [style.font-variant]="'small-caps'"></div>

          <ng-container *ngFor="let evt of recent$ | async; trackBy: trackByID">
            <div class="col-span-4 flex items-center truncate text-xs">{{ evt.time | date: 'MM/dd/yyyy hh:mm:ss' }}</div>
            <div class="col-span-2 flex items-center truncate text-xs">{{ evt.tag }}</div>
            <div class="col-span-5 flex items-center text-xs">
              <div class="truncate">{{ evt.name | slice: 20 }}</div>
            </div>
            <div class="col-span-1 flex items-center truncate text-xs">
              <button
                (click)="downloadEventLog(evt)"
                class="w-5 h-5 leading-none flex items-center justify-center hover:bg-hover transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
                matTooltip="Download"
                mat-icon-button
              >
                <fa-icon [icon]="['fad', 'download']" size="sm" class="text-primary"></fa-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="agent$ | async as agent">
      <div class="flex w-full items-center justify-center mt-2">
        <form
          (ngSubmit)="archiveEventType(agent.id)"
          [formGroup]="form"
          autocomplete="off"
          class="flex w-full items-center justify-center gap-2"
        >
          <mat-form-field>
            <mat-label>Event Types</mat-label>
            <mat-select formControlName="scope">
              <mat-option *ngFor="let key of getEnumKeys()" [value]="eventLogTypes[key]">{{ key }}</mat-option>
            </mat-select>
          </mat-form-field>
          <button type="submit" mat-raised-button color="primary" [disabled]="spin || form.invalid || form.disabled || (running$ | async)"
            >Archive</button
          >
        </form>
      </div>
    </ng-container>
  </div>
</mon-spinner>
