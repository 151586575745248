import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Processes } from 'src/app/shared/models/process.model';

import { ApiService, Response } from './api.service';

@Injectable({ providedIn: 'root' })
export class ProcessService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAll(agentID: string): Observable<Processes> {
    const url = `${this.apiUrl}/agent/${agentID}/process`;
    return this.http.get<Processes>(url).pipe(
      tap(() => this.log('fetched ProcessService.getAll response')),
      map((response) => (response ? new Processes(response) : undefined)),
      catchError((err) => {
        this.handleError<Processes>('ProcessService.getAll');
        return throwError(() => err);
      })
    );
  }

  killByPid(agentID: string, ...pids: Array<number>): Observable<Response> {
    const url = `${this.apiUrl}/agent/${agentID}/process/kill`;
    return this.http.post<Response>(url, { pids }).pipe(
      tap(() => this.log('fetched ProcessService.killByPid response')),
      map((response) => (response ? response : undefined)),
      catchError((err) => {
        this.handleError('ProcessService.killByPid');
        return throwError(() => err);
      })
    );
  }
}
