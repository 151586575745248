import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { toJson } from 'src/app/utils/json';

@Injectable()
export class SerializerInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.body &&
      !(request.body instanceof FormData) &&
      !(request.body instanceof Blob) &&
      (typeof request.body === 'object' || Array.isArray(request.body))
    ) {
      const newRequest = request.clone({
        body: toJson(request.body),
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
      return next.handle(newRequest);
    }

    return next.handle(request);
  }
}
