import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, finalize, map, of, startWith, tap } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import {
  NewScript,
  PatchScript,
  ScriptService
} from 'src/app/core/services/script.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import {
  getScriptIDRegex,
  getScriptParameterNameRegex,
  getScriptTagRegex
} from 'src/app/core/utils/regex';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { PermissionValue, Permissions } from 'src/app/shared/models/permissions.model';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';
import { deepCopy } from 'src/app/utils/clone';
import { strings } from 'src/app/utils/strings';
import { CustomValidators } from 'src/app/utils/validators';
import { PermissionsComponent } from '../../../shared/components/permissions/permissions.component';
import { commonTags } from './tag-data';

export class ScriptEditParams {
  scriptID: Script['id'];
  scriptLocation: Script['location'];
  onSuccess: (s: NewScript | PatchScript) => void;
  constructor(init?: Partial<ScriptEditParams>) {
    Object.assign(this, init);
  }
}

export interface Parameter {
  name: string;
  description: string;
}

@Component({
  selector: 'mon-script-edit',
  templateUrl: './script-edit.component.html',
  styleUrls: ['./script-edit.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms]
})
export class ScriptEditComponent implements OnInit {
  scriptLocation = ScriptLocation;
  edit: boolean;
  err: string;
  uploadErr: string;
  spin: boolean;
  overlayResult = OverlayResult.Unset;
  idRegex = getScriptIDRegex();
  tagRegex = getScriptTagRegex();

  formUpload: UntypedFormGroup;
  formDetail: UntypedFormGroup;
  selectableCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  formParameters: FormGroup;
  formDocumentation: UntypedFormGroup;
  formPermissions: UntypedFormGroup = this.fb.group({ permissions: [new Permissions()] });
  formChangelog: UntypedFormGroup;

  markdown = true;

  filename: string;
  scriptSigned = true;
  uploadProgress: number;
  importComments = true;

  paramIndex = 0;
  parameterIndices: number[] = [];

  tags: string[] = [];
  commonTags$ = of(commonTags).pipe(tap((perms) => (this.tags = perms.sort())));
  filteredTags$: Observable<string[]>;
  showPermissions: boolean;

  script: NewScript | PatchScript = {
    id: '',
    location: ScriptLocation.Private,
    name: '',
    description: '',
    detail: '',
    parameters: new Map<string, string>(),
    tags: [],
    filename: '',
    permissions: {
      roles: new Map<string, number>(),
      users: new Map<string, number>()
    }
  };

  private _refreshPerms = new BehaviorSubject<boolean>(false);
  refreshPerms$ = this._refreshPerms.asObservable();
  permissions$ = this.refreshPerms$.pipe(
    map(() => deepCopy(this.formPermissions.controls.permissions.value as Permissions))
  );

  _changelog = new BehaviorSubject<string[]>([]);
  changelogs$ = this._changelog.asObservable();

  @ViewChild('idInput', { static: false }) idInput: ElementRef;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('detailInput', { static: true }) detailInput: ElementRef;
  @ViewChild('changelogInput', { static: false }) changelogInput: ElementRef;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef<HTMLInputElement>;
  @ViewChild('uploadButton', { static: true }) uploadButton: MatButton;
  @ViewChild('parameterInputs', { static: true }) parameterInputs: ElementRef;
  @ViewChild('permControl', { static: false }) permControl: PermissionsComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: ScriptEditParams,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ScriptEditComponent>,
    private fb: UntypedFormBuilder,
    private scriptSvc: ScriptService
  ) {
    this.edit = !!params.scriptID;

    this.formUpload = this.fb.group({
      filename: ['', [CustomValidators.noWhitespace, Validators.required]]
    });
    this.formUpload.controls.filename.valueChanges.subscribe({
      next: (v: string) => (this.script.filename = v)
    });

    this.formDetail = this.fb.group({
      id: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(getScriptIDRegex())
        ]
      ],
      name: [
        '',
        [CustomValidators.noWhitespace, Validators.required, Validators.maxLength(50)]
      ],
      description: [
        '',
        [CustomValidators.noWhitespace, Validators.required, Validators.maxLength(200)]
      ],
      tags: [[], [CustomValidators.minChipCount(3, this.tagRegex)]]
    });
    this.formDetail.controls.id.valueChanges.subscribe({
      next: (v: string) => (this.script['id'] = v)
    });
    this.formDetail.controls.name.valueChanges.subscribe({
      next: (v: string) => (this.script.name = v)
    });
    this.formDetail.controls.description.valueChanges.subscribe({
      next: (v: string) => (this.script.description = v)
    });
    this.filteredTags$ = this.selectableCtrl.valueChanges.pipe(
      startWith(''),
      map((perm: string | null) => this._filter(perm))
    );

    this.formParameters = this.fb.group({});

    this.formDocumentation = this.fb.group({
      detail: ['', [CustomValidators.noWhitespace, Validators.required]]
    });
    this.formDocumentation.controls.detail.valueChanges.subscribe({
      next: (v: string) => (this.script.detail = v)
    });

    this.formChangelog = this.fb.group({
      changelog: ['', [CustomValidators.noWhitespace, Validators.required]]
    });
    this.formChangelog.controls.changelog.valueChanges.subscribe({
      next: (v: string) => {
        const patch = <PatchScript>this.script;
        patch.changelog = v.split('\n').filter((c) => !!c);
        const logs = patch.changelog.slice(0, patch.changelog.length > 2 ? 2 : undefined);
        if (patch.changelog.length > 2) {
          logs.push('...');
        }
        this._changelog.next(logs);
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => this.uploadButton.focus(), 1000);

    if (this.params.scriptID) {
      this.spin = true;
      this.scriptSvc
        .location(this.params.scriptLocation)
        .getByID(this.params.scriptID)
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: (script) => {
            this.script = {
              id: script.id,
              location: script.location,
              name: script.name,
              description: script.description,
              detail: script.detail,
              parameters: script.parameters || {},
              tags: script.tags,
              filename: script.latestFile,
              permissions: undefined
            };
            this.formDetail.controls.id.setValue(this.script.id);
            this.formDetail.controls.name.setValue(this.script.name);
            this.formDetail.controls.description.setValue(this.script.description);
            this.formDetail.controls.tags.setValue(this.script.tags);
            this.formDocumentation.controls.detail.setValue(this.script.detail);
            this.formUpload.controls.filename.setValue(this.script.filename);

            Object.keys(this.script.parameters).forEach((p) => {
              this.addParameter(p, this.script.parameters[p]);
            });
          }
        });
    }
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file: File = files[0];
    if (!file) {
      return;
    }
    this.uploadProgress = 0;
    this.formUpload.get('filename').setValue(undefined);
    this.scriptSigned = true;
    this.uploadErr = '';

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      this.scriptSvc.uploadScript(formData).subscribe({
        next: (event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.uploadProgress = Math.round(100 * (event.loaded / event.total));
              break;
            case HttpEventType.Response:
              const upload = event.body;
              if (upload.filename) {
                this.filename = file.name;
                this.scriptSigned = upload.signed;

                if (upload.comments && this.importComments) {
                  if (!this.edit) {
                    this.formDetail.controls.id.setValue('');
                  }

                  this.formDetail.controls.name.setValue('');
                  this.formDocumentation.controls.detail.setValue('');
                  this.formDetail.controls.description.setValue('');
                  this.clearTags();

                  let id = '';
                  let name = '';
                  let synopsis = '';
                  let description = '';
                  let inputs = '';
                  let outputs = '';
                  let notes = '';
                  let detail = '';
                  let parameters;
                  const parametersString = [];

                  for (const field in upload.comments) {
                    const value = upload.comments[field] as string;
                    switch (field) {
                      case 'CC_ID':
                        id = value;

                        if (!this.edit) {
                          this.formDetail.controls.id.setValue(value);
                        }
                        break;
                      case 'CC_NAME':
                        name = value;
                        break;
                      case 'CC_DETAIL':
                        detail = value;
                        break;
                      case 'CC_TAGS':
                        value.split(' ').forEach((t) => this._addTag(t));
                        break;
                      case 'SYNOPSIS':
                        synopsis = value;
                        this.formDetail.controls.description.setValue(value);
                        break;
                      case 'DESCRIPTION':
                        description = value;
                        break;
                      case 'INPUTS':
                        inputs = value;
                        break;
                      case 'OUTPUTS':
                        outputs = value;
                        break;
                      case 'NOTES':
                        notes = value;
                        break;
                      case 'PARAMETER':
                        parameters = {};
                        Object.keys(value).forEach((key) => {
                          parameters[key] = value[key];
                          parametersString.push(`\$${key}\n# ${value[key]}`);
                        });
                        break;
                    }
                  }

                  if (!id) {
                    id = this.filename
                      .toLowerCase()
                      .replace(/(\.ps1|\.sh)$/gi, '')
                      .replace(/[ |\\.]/gi, '-');
                  }

                  if (!this.edit) {
                    this.formDetail.controls.id.setValue(id);
                  }

                  if (!name) {
                    name = strings.capitalizeWords(id.replace(/-/gi, ' '));
                  }

                  this.formDetail.controls.name.setValue(name);

                  this.formDetail.controls.description.setValue(synopsis || description);

                  if (this.parameterIndices.length === 0 && parameters) {
                    Object.keys(parameters).forEach((key) =>
                      this.addParameter(key, parameters[key])
                    );
                  }

                  if (!detail) {
                    if (name) {
                      detail = `# ${name}\n\n---\n\n`;
                    }

                    if (description || synopsis) {
                      detail += `${description || synopsis}\n\n`;
                    }

                    if (parametersString.length > 0) {
                      detail += `## Parameters\n\n\`\`\`powershell\n${parametersString.join(
                        '\n\n'
                      )}\n\`\`\`\n\n`;
                    }

                    if (inputs) {
                      detail += `## Inputs\n\n---\n\n${inputs}\n\n`;
                    }

                    if (outputs) {
                      detail += `## Outputs\n\n---\n\n${outputs}\n\n`;
                    }

                    if (notes) {
                      detail += `## Notes\n\n---\n\n${notes}\n\n`;
                    }
                  }

                  this.formDocumentation.controls.detail.setValue(detail);
                }

                this.formUpload.get('filename').setValue(upload.filename);
              }
              break;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.fileUpload.nativeElement.value = '';
          this.uploadErr = `Upload failed: ${parseError(err)}`;
        }
      });
    }
  }

  onStep(event: StepperSelectionEvent) {
    switch (event.selectedStep.label) {
      case 'Upload':
        setTimeout(() => this.uploadButton.focus(), 100);
        break;
      case 'Detail':
        setTimeout(() => {
          if (this.edit) {
            this.nameInput.nativeElement.focus();
          } else {
            this.idInput.nativeElement.focus();
          }
        }, 100);
        break;
      case 'Parameters':
        if (this.parameterIndices.length === 0) {
          this.addParameter();
        }
        setTimeout(
          () =>
            this.parameterInputs.nativeElement.querySelector('.parameter-name').focus(),
          100
        );
        break;
      case 'Documentation':
        this.markdown = true;
        setTimeout(() => this.detailInput.nativeElement.focus(), 100);
        break;
      case 'Permissions':
        this.showPermissions = true;
        setTimeout(() => {
          this.permControl.focusInput();
        }, 1000);
        break;
      case 'Changelog':
        setTimeout(() => this.changelogInput.nativeElement.focus(), 100);
        break;
    }
  }

  toggleLocation(checked: boolean): void {
    this.script.location = checked ? ScriptLocation.Public : ScriptLocation.Private;
  }

  save(): void {
    this.spin = true;
    this.err = '';

    this.script.parameters = {};

    this.parameterIndices.forEach((i) => {
      const name = this.formParameters.controls[`name${i}`].value;
      if (name) {
        const desc = this.formParameters.controls[`description${i}`].value;
        this.script.parameters[name] = desc;
      }
    });

    if (this.edit) {
      this.scriptSvc
        .location(this.script.location)
        .update(this.script['id'], this.script as PatchScript)
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: () => (this.overlayResult = OverlayResult.Success),
          error: (err: HttpErrorResponse) => {
            this.err = parseError(err);
            this.overlayResult = OverlayResult.Error;
          }
        });
    } else {
      const script = this.script as NewScript;
      const permissions = this.formPermissions.controls.permissions.value as Permissions;
      script.permissions = permissions.asRaw();

      this.scriptSvc
        .location(script.location)
        .create(script)
        .pipe(finalize(() => (this.spin = false)))
        .subscribe({
          next: () => (this.overlayResult = OverlayResult.Success),
          error: (err: HttpErrorResponse) => {
            this.err = parseError(err);
            this.overlayResult = OverlayResult.Error;
          }
        });
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = (event.value || '').trim().toLowerCase();

    if (!this.tagRegex.test(value)) {
      return;
    }

    if (this.script.tags.indexOf(value) >= 0) {
      return;
    }

    if (value) {
      // Add our tag
      this._addTag(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.selectableCtrl.setValue('');
    this.selectableCtrl.updateValueAndValidity();

    setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  clearTags(): void {
    this.script.tags = [];
    this.formDetail.get('tags').setValue(this.script.tags);

    this.selectableCtrl.setValue(null);
    this.selectableCtrl.updateValueAndValidity();
  }

  removeTag(tag: string): void {
    const found = this.script.tags.findIndex((p) => p === tag);
    if (found >= 0) {
      this.script.tags.splice(found, 1);
      this.formDetail.get('tags').setValue(this.script.tags);

      this.selectableCtrl.setValue(null);
      this.selectableCtrl.updateValueAndValidity();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._addTag(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.selectableCtrl.setValue('');
    this.selectableCtrl.updateValueAndValidity();
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.params.onSuccess?.(this.script);
      this.dialogRef.close();
    }
  }

  confirm(action: () => void): void {
    this.dialog.open(ConfirmDialogComponent, {
      restoreFocus: true,
      data: {
        icon: 'hand-paper',
        iconBgClasses: ['bg-red-light'],
        iconClasses: ['text-red'],
        title: 'Commit Action?',
        confirmationText: `Doing this may influence actions. Please confirm.`,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        onConfirm: action,
        onCancel: () => {}
      }
    });
  }

  addParameter(name = '', description = ''): void {
    const i = this.paramIndex++;
    this.parameterIndices.push(i);
    this.formParameters.addControl(
      `name${i}`,
      this.fb.control(name, [
        Validators.maxLength(50),
        Validators.pattern(getScriptParameterNameRegex())
      ])
    );
    this.formParameters.addControl(
      `description${i}`,
      this.fb.control(description, [Validators.maxLength(200)])
    );
  }

  removeParameter(index: number): void {
    this.parameterIndices = this.parameterIndices.filter((p) => p !== index);
  }

  focusOnMe(e: ElementRef) {
    e.nativeElement.focus();
  }

  getParamError(name: string, validationName: string): boolean {
    const v = this.formParameters.controls[name].errors?.[validationName];
    return !!v;
  }

  private _addTag(tag: string): void {
    this.script.tags.push(tag);
    this.formDetail.get('tags').setValue(this.script.tags);
    setTimeout(() => this.matAutocompleteTrigger.openPanel(), 100);
  }

  private _filter(value: string): string[] {
    value = value || '';

    if (value.length < 2) {
      return [];
    }

    return this.tags
      .filter((tag) => !this.script.tags.find((t) => t === tag))
      .filter((tag) => tag.toLowerCase().indexOf(value) >= 0);
  }

  permOnAdd(perm: PermissionValue): void {
    const permissions = this.formPermissions.controls.permissions.value as Permissions;
    permissions.add(perm);
    this.formPermissions.controls.permissions.setValue(permissions);
    this._refreshPerms.next(true);
    this.permControl.resetInput();
    setTimeout(() => this.permControl.focusInput(), 500);
  }

  permOnUpdate(perm: PermissionValue): void {
    const permissions = this.formPermissions.controls.permissions.value as Permissions;
    permissions.add(perm);
    this.formPermissions.controls.permissions.setValue(permissions);
    this._refreshPerms.next(true);
  }

  permOnDelete(perm: PermissionValue): void {
    const permissions = this.formPermissions.controls.permissions.value as Permissions;
    permissions.delete(perm);
    this.formPermissions.controls.permissions.setValue(permissions);
    this._refreshPerms.next(true);
  }
}
