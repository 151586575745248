import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { LayoutComponent } from 'src/@vex/layout/layout.component';

import { AgreementGuard } from './core/guards/agreement.guard';
import { DebugGuard } from './core/guards/debug.guard';
import { LicenseGuard } from './core/guards/license.guard';
import { LoginGuard } from './core/guards/login.guard';
import { PermGuard } from './core/guards/perm.guard';
import { Permission } from './shared/enums/permission.enum';
import { AgreementComponent } from './view/agreement/agreement.component';
import { AlarmsComponent } from './view/alarms/alarms.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { AgentToolsComponent } from './view/diagnostics/agent-tools/agent-tools.component';
import { DebugComponent } from './view/diagnostics/debug/debug.component';
import { ExpiredSubscriptionComponent } from './view/expired-subscription/expired-subscription.component';
import { BlockedMachinesComponent } from './view/inventory/blocked-machines/blocked-machines.component';
import { MachinesComponent } from './view/inventory/machines/machines.component';
import { UserSessionsComponent } from './view/inventory/user-sessions/user-sessions.component';
import { DownloadAgentComponent } from './view/misc/download-agent/download-agent.component';
import { ErrorComponent } from './view/page/error/error.component';
import { LoginComponent } from './view/page/login/login.component';
import { NotFoundComponent } from './view/page/not-found/not-found.component';
import { RemoteComponent } from './view/remote/remote.component';
import { ScriptResultComponent } from './view/scripts/script-result/script-result.component';
import { ScriptsComponent } from './view/scripts/scripts.component';
import { ShellComponent } from './view/shell/shell.component';
import { PerformanceComponent } from './view/task-manager/performance/performance.component';
import { ProcessesComponent } from './view/task-manager/processes/processes.component';
import { ServicesComponent } from './view/task-manager/services/services.component';
import { StartupComponent } from './view/task-manager/startup/startup.component';
import { TasksComponent } from './view/tasks/tasks.component';
import { ActionsComponent } from './view/tools/actions/actions.component';
import { TriggersComponent } from './view/tools/triggers/triggers.component';

const childrenRoutes: VexRoutes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    title: 'Dashboard - CommandCTRL',
    path: 'dashboard',
    component: DashboardComponent,
    data: { requiresAgent: true, configPanel: 'dashboard' },
    canActivate: [LicenseGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'task-manager',
    redirectTo: 'task-manager/processes',
    pathMatch: 'full'
  },
  {
    title: 'Task Manager: Processes - CommandCTRL',
    path: 'task-manager/processes',
    component: ProcessesComponent,
    data: { requiresAgent: true },
    canActivate: [LicenseGuard]
  },
  {
    title: 'Task Manager: Services - CommandCTRL',
    path: 'task-manager/services',
    component: ServicesComponent,
    data: { requiresAgent: true },
    canActivate: [LicenseGuard]
  },
  {
    title: 'Task Manager: Performance - CommandCTRL',
    path: 'task-manager/performance',
    component: PerformanceComponent,
    data: { requiresAgent: true },
    canActivate: [LicenseGuard]
  },
  {
    title: 'Task Manager: Startup - CommandCTRL',
    path: 'task-manager/startup',
    component: StartupComponent,
    data: {
      requiresAgent: true,
      requiresPerm: [
        Permission.MachineStartupApplications_List,
        Permission.MachineStartupLogin_List
      ]
    },
    canActivate: [PermGuard, LicenseGuard]
  },
  {
    title: 'Tasks - CommandCTRL',
    path: 'tasks',
    component: TasksComponent,
    data: { requiresAgent: false, requiresPerm: [Permission.Task_List] },
    canActivate: [PermGuard, LicenseGuard]
  },
  {
    path: 'tools',
    redirectTo: 'tools/actions',
    pathMatch: 'full'
  },
  {
    title: 'Tools: Actions - CommandCTRL',
    path: 'tools/actions',
    component: ActionsComponent,
    data: { requiresAgent: false, requiresPerm: [Permission.Action_List] },
    canActivate: [PermGuard, LicenseGuard]
  },
  {
    title: 'Tools: Triggers - CommandCTRL',
    path: 'tools/triggers',
    component: TriggersComponent,
    data: { requiresAgent: false, requiresPerm: [Permission.Trigger_List] },
    canActivate: [PermGuard, LicenseGuard]
  },
  {
    title: 'Tools: Alarms - CommandCTRL',
    path: 'tools/alarms',
    component: AlarmsComponent,
    data: { requiresAgent: false, requiresPerm: [Permission.Alarm_List] },
    canActivate: [PermGuard, LicenseGuard]
  },
  {
    path: 'tools/scripts',
    redirectTo: 'tools/scripts/store',
    pathMatch: 'full'
  },
  {
    title: 'Tools: Script Store - CommandCTRL',
    path: 'tools/scripts/store',
    data: { requiresAgent: false, requiresPerm: [Permission.Script_List] },
    canActivate: [PermGuard, LicenseGuard],
    children: [
      {
        matcher: scriptStoreMatcher,
        component: ScriptsComponent
      }
    ]
  },
  {
    title: 'Tools: Script Results - CommandCTRL',
    path: 'tools/scripts/results',
    data: { requiresAgent: false, requiresPerm: [Permission.Script_List] },
    canActivate: [PermGuard, LicenseGuard],
    children: [
      {
        matcher: scriptResultMatcher,
        component: ScriptResultComponent
      }
    ]
  },
  {
    title: 'Diagnostics - CommandCTRL',
    path: 'diagnostics/agent-tools',
    component: AgentToolsComponent,
    data: { requiresAgent: true },
    canActivate: [LicenseGuard]
  },
  {
    title: 'Tools: Debug - CommandCTRL',
    path: 'diagnostics/debug',
    component: DebugComponent,
    data: { requiresAgent: false },
    canActivate: [DebugGuard]
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./view/settings/settings.module').then((m) => m.SettingsModule),
    data: { requiresAgent: false }
  },
  {
    path: 'misc',
    redirectTo: 'misc/documentation',
    pathMatch: 'full'
  },
  {
    title: 'Miscellaneous: Install Agent - CommandCTRL',
    path: 'misc/download-agent',
    component: DownloadAgentComponent,
    data: { requiresAgent: false }
  },
  {
    path: 'inventory',
    redirectTo: 'inventory/machines',
    pathMatch: 'full'
  },
  {
    title: 'Inventory: Machines - CommandCTRL',
    path: 'inventory/machines',
    component: MachinesComponent,
    data: { requiresAgent: false },
    canActivate: [LicenseGuard]
  },
  {
    title: 'Inventory: Blocked - CommandCTRL',
    path: 'inventory/blocked',
    component: BlockedMachinesComponent,
    data: { requiresAgent: false },
    canActivate: [LicenseGuard]
  },
  {
    title: 'Inventory: User Sessions - CommandCTRL',
    path: 'inventory/user-sessions',
    component: UserSessionsComponent,
    data: { requiresAgent: false },
    canActivate: [LicenseGuard]
  },
  {
    path: 'me',
    loadChildren: () =>
      import('./view/profile/profile.module').then((m) => m.ProfileModule),
    data: { requiresAgent: false }
  },
  {
    path: 'expired',
    component: ExpiredSubscriptionComponent
  }
];

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./view/page/login/login.module').then((m) => m.LoginModule),
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'home',
    component: LayoutComponent,
    children: childrenRoutes,
    canActivateChild: [AuthGuard, AgreementGuard]
  },
  {
    path: 'agreement',
    component: AgreementComponent,
    canActivate: [AuthGuard]
  },
  {
    title: 'Shell: CommandCTRL',
    path: 'shell',
    component: ShellComponent,
    canActivate: [AuthGuard, AgreementGuard, LicenseGuard]
  },
  {
    path: 'remote/:roomName',
    component: RemoteComponent
  },
  {
    path: 'remote/connecting',
    component: RemoteComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: '404 - page not found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload', // this is needed for when you switch tenant and we need to re-run the guard
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export function scriptStoreMatcher(segments: UrlSegment[]): UrlMatchResult {
  if (segments.length < 2) {
    return {
      consumed: [],
      posParams: {}
    };
  }
  if (segments.length === 2) {
    return {
      consumed: segments.slice(0, 2),
      posParams: {
        location: segments[0],
        scriptID: segments[1]
      }
    };
  }
  return <UrlMatchResult>null;
}

export function scriptResultMatcher(segments: UrlSegment[]): UrlMatchResult {
  if (segments.length === 0) {
    return {
      consumed: [],
      posParams: {}
    };
  }
  if (segments.length === 1) {
    return {
      consumed: segments.slice(0, 1),
      posParams: {
        taskID: segments[0]
      }
    };
  }
  if (segments.length === 2) {
    return {
      consumed: segments.slice(0, 2),
      posParams: {
        taskID: segments[0],
        targetID: segments[1]
      }
    };
  }
  return <UrlMatchResult>null;
}
