import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, timer } from 'rxjs';
import { map, retry, switchMap, tap } from 'rxjs/operators';
import { AgreementGuard } from 'src/app/core/guards/agreement.guard';
import { StateService } from 'src/app/core/services/state.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { Agreement } from 'src/app/shared/models/agreement.model';

@Component({
  selector: 'mon-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent {
  _agreement = new BehaviorSubject<Agreement>(undefined);
  agreement$ = this._agreement.asObservable();

  getOneAgreement$ = this.tenantSvc.getOpenAgreements().pipe(
    retry({
      delay: (_error, _retryCount) => timer(1000)
    }),
    map((agreements) => {
      if (agreements.length > 0) {
        return agreements[0];
      } else {
        return null;
      }
    }),
    tap((agreement) => {
      if (agreement == null) {
        AgreementGuard.AllAgreed = true;
        this.router.navigate([''], {
          queryParamsHandling: 'merge'
        });
      }
    })
  );

  lastOne = false;
  blurOut = false;
  agreeCount = 0;

  constructor(
    private router: Router,
    private tenantSvc: TenantService,
    private stateSvc: StateService
  ) {
    this.stateSvc.setSearch(undefined);

    const currentNav = this.router.getCurrentNavigation();
    if (currentNav.extras?.state?.agreement) {
      this._agreement.next(currentNav.extras.state.agreement);
      return;
    }

    this.getOneAgreement$
      .pipe(tap((agreement) => this._agreement.next(agreement)))
      .subscribe();
  }

  toggle(checked: boolean): void {
    this.agreeCount = this.agreeCount + (checked ? 1 : -1);
  }

  agree(key: string): void {
    this.tenantSvc
      .closeAgreement(key)
      .pipe(
        tap(() => (this.blurOut = true)),
        switchMap(() => this.getOneAgreement$),
        tap(() => (this.blurOut = false)),
        tap((agreement) => this._agreement.next(agreement))
      )
      .subscribe({
        next: (agreement) => {
          this.agreeCount = 0;
          if (agreement == null) {
            AgreementGuard.AllAgreed = true;
            this.router.navigate([''], {
              queryParamsHandling: 'merge'
            });
          }
        }
      });
  }
}
