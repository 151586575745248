<vex-page-layout style="width: 100%; height: 100%" class="remote-bg">
  <div *ngIf="connecting$ | async" id="vex-splash-screen" class="splash-screen content-loader">
    <div class="wrapper">
      <div class="ball-scale-multiple">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <div *ngIf="validateTenant$ | async"></div>
  <ng-container *ngIf="active$ | async; else meetingOver">
    <div id="meet" style="width: 100%; height: 100%"></div>
  </ng-container>

  <ng-container *ngLet="active$ | async as active">
    <ng-container *ngIf="tenant$ | async as tenant">
      <div class="absolute top-0 left-0 w-full text-primary">
        <div class="flex justify-between">
          <div [ngClass]="{ 'mt-4 ml-4 text-3xl': !active, 'mt-2 ml-2': active }" class="transition-all text-opacity-50 text-gray-50">
            {{ tenant.name }}
          </div>
          <button *ngIf="!active" (click)="closeWindow()" class="hover:bg-hover text-4xl mt-4 mr-4" matTooltip="Close">
            <fa-icon [icon]="['fad', 'times']" size="lg"> </fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #meetingOver>
    <div @fadeInUp class="absolute top-0 left-0 w-full h-full text-primary text-4xl text-center table">
      <div class="relative table-cell align-middle">
        <fa-stack [size]="'2x'">
          <fa-icon
            [ngClass]="{ 'p-0 opacity-60': true }"
            [icon]="['fas', 'slash']"
            [mask]="['fad', 'plug']"
            stackItemSize="1x"
            transform="down-2.25"
          ></fa-icon>
          <fa-icon [icon]="['fas', 'slash']" stackItemSize="1x"></fa-icon>
        </fa-stack>
        <div *ngIf="error$ | async as error" class="opacity-75 text-red">
          {{ error ? error : 'The meeting has ended' }}
        </div>
        <div *ngIf="closed$ | async as closed">
          {{ closed }}
        </div>
        <ng-container *ngIf="!(tenant$ | async)">
          <div class="text-sm opacity-75">you may close this browser</div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</vex-page-layout>
