import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject, debounceTime, distinctUntilChanged, iif, map, switchMap, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { Script } from 'src/app/shared/models/script.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

import { SearchService } from '../../../core/services/search.service';
import { ScriptsModule } from '../scripts.module';

export interface ScriptSearchParams {
  onSuccess: (script: Script) => void;
}
@Component({
  selector: 'mon-script-search',
  standalone: true,
  templateUrl: './script-search.component.html',
  styleUrls: ['./script-search.component.scss'],
  animations: [fadeInUp400ms],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    ScriptsModule
  ]
})
export class ScriptSearchComponent {
  private _searching = new BehaviorSubject<boolean>(false);
  readonly searching$ = this._searching.asObservable();

  _searchInput = new BehaviorSubject<string>(undefined);
  searchInput$ = this._searchInput.asObservable().pipe(
    debounceTime(250),
    map((search) => search?.toLowerCase()),
    distinctUntilChanged()
  );

  searchResults$ = this.searchInput$.pipe(
    tap(() => this._searching.next(true)),
    switchMap((search) =>
      iif(
        () => !!search,
        this.searchSvc.getScripts(search, 1, 1).pipe(map((result) => result.items)),
        UNDEFINED
      )
    ),
    tap(() => this._searching.next(false))
  );

  _scriptName = new BehaviorSubject<string>('');
  scriptName$ = this._scriptName.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: ScriptSearchParams,
    private dialogRef: MatDialogRef<ScriptSearchComponent>,
    private searchSvc: SearchService
  ) {}

  onFilter(search: string): void {
    this._searchInput.next(search);
  }

  dropdownSelect(event: MatAutocompleteSelectedEvent): void {
    this._scriptName.next(event.option.value.name);
    this.params.onSuccess?.(event.option.value);
    this.dialogRef.close();
  }
}
