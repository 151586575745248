import { Component } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';
import { iif } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { StateService } from 'src/app/core/services/state.service';

interface formatterOptions {
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    config: ApexOptions;
  };
}

interface customOptions {
  seriesIndex: number;
  dataPointIndex: number;
  w: { config: ApexOptions };
}

@Component({
  selector: 'mon-login-metrics',
  templateUrl: './login-metrics.component.html',
  styleUrls: ['./login-metrics.component.scss']
})
export class LoginMetricsComponent {
  startupTimeSeries$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$
      )
    ),
    filter((agent) => !!agent),
    map((agent) => {
      const bootTime = agent.system.bootTime;
      const apps = agent.stats.summary.system.startupApps || [];
      const series = [];
      const data = [];
      let totalTime = 0;

      let startTime = new Date(bootTime);

      apps.forEach((app) => {
        const randMSDuration = Math.floor(Math.random() * Math.floor(10 * 1000));
        const endTime = new Date(startTime.getTime() + randMSDuration);
        totalTime += randMSDuration;

        data.push({
          x: app.name,
          y: [startTime.getTime(), endTime.getTime()]
        });

        startTime = new Date(endTime);
      });

      if (data.length > 0) {
        this.totalTime = this.timeConversion(totalTime);
        series.push({ data });
      }

      return series;
    }),
    filter((series) => series.length > 0),
    tap((series) => (this.apexOptions.chart.height = series[0].data.length * 50 + 100)),
    take(1)
  );

  apexOptions: ApexOptions = {
    dataLabels: {
      formatter: (_: number, opts: formatterOptions): string => {
        const series = opts.w.config.series[opts.seriesIndex];
        const dataPoint = series['data'][opts.dataPointIndex];
        const duration = dataPoint.y[1] - dataPoint.y[0];
        return this.timeConversion(duration);
      },
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: customOptions): string => {
        const dataPoint = w.config.series[seriesIndex]['data'][dataPointIndex];
        const msg = `${dataPoint.x}: ${this.timeConversion(
          dataPoint.y[1] - dataPoint.y[0]
        )}`;

        return `<div class="p-4 arrow_box"><span> ${msg} </span></div>`;
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: false
      },
      tooltip: {
        enabled: false
      },

      axisTicks: {
        show: true
      },
      floating: false
    },
    stroke: {
      curve: 'smooth',
      width: 5.0
    },
    labels: [],
    chart: {
      zoom: {
        enabled: false
      },
      type: 'rangeBar',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    plotOptions: {
      bar: {
        // barHeight: '5%',
        horizontal: true
      }
    },
    grid: undefined
  };

  totalTime: string;

  constructor(
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService
  ) {}

  private timeConversion(duration: number) {
    const portions: string[] = [];

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
      portions.push(hours + 'h');
      duration = duration - hours * msInHour;
    }

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    if (minutes > 0) {
      portions.push(minutes + 'm');
      duration = duration - minutes * msInMinute;
    }

    const msInSeconds = 1000;
    const seconds = Math.trunc(duration / 1000);
    if (seconds > 0) {
      portions.push(seconds + 's');
      duration = duration - seconds * msInSeconds;
    }

    const milliseconds = Math.trunc(duration);
    if (milliseconds > 0) {
      portions.push(milliseconds + 'ms');
    }

    return portions.join(' ');
  }
}
