import { Updatable } from '../interfaces/updatable.interface';
import { Trigger } from './trigger.model';

export class Alarm implements Updatable<Alarm> {
  public agentID: string;
  public description: string;
  public trigger: Trigger;
  public timestamp: Date;

  constructor(init?: Partial<Alarm>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);
    this.trigger = new Trigger(this.trigger);
  }

  update(input: Partial<Alarm>): this {
    return Object.assign(this, input);
  }
}
