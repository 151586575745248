import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { filter } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { ConfigService } from 'src/@vex/services/config.service';
import { StateService } from 'src/app/core/services/state.service';
import { ComponentDisplayConfig } from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { DisplayDensity } from 'src/app/shared/enums/table-density.enum';
import { Process } from 'src/app/shared/models/process.model';

@Component({
  selector: 'mon-live-table',
  templateUrl: './live-table.component.html',
  styleUrls: ['./live-table.component.scss'],
  animations: [fadeInUp400ms]
})
export class LiveTableComponent<T> implements OnDestroy {
  dataSource: MatTableDataSource<T> | null = new MatTableDataSource([]);
  DisplayDensity = DisplayDensity;
  config$ = this.configSvc.config$.pipe(filter((config) => !!config));

  @Input() icon: IconName;
  @Input() iconClass: string[];
  @Input() header: string;
  @Input() link: string[];
  @Input() linkTip: string;
  @Input() sortField: string;
  @Input() sortDirection: 'asc' | 'desc';
  @Input() columns: TableColumn<T>[] = [];

  _data: Array<T>;
  @Input() set data(data: Array<T>) {
    this._data = data;
    this.updateDatasource();
  }

  _idField: (t: T) => string;
  @Input() set idField(idField: (t: T) => string) {
    this._idField = idField;
    this.updateDatasource();
  }

  _displayConfig: ComponentDisplayConfig;
  @Input() set config(config: ComponentDisplayConfig) {
    this._displayConfig = config;
  }

  @ViewChild(MatTable) table: MatTable<T>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private stateSvc: StateService,
    private configSvc: ConfigService
  ) {}

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  updateDatasource(): void {
    if (!this._idField) {
      return;
    }
    const map = new Map<string, T>();
    const array = new Array<T>();

    this._data.forEach((t) => {
      map.set(this._idField(t), t);
      array.push(t);
    });

    if (this.dataSource.data.length < 1) {
      this.dataSource.data = array;
      return;
    }

    for (let i = 0; i < this.dataSource.data.length; ) {
      const oldProcess = this.dataSource.data[i];
      const oldPID = `${this._idField(oldProcess)}`;
      const newProcess = map.get(oldPID);

      if (newProcess != null) {
        if (oldProcess != null) {
          Object.assign(oldProcess, newProcess);
          map.delete(oldPID);
        }
        i++;
      } else {
        this.dataSource.data.splice(i, 1);
      }
    }

    map.forEach((t) => {
      if (t) {
        this.dataSource.data.push(t);
      }
    });

    if (this.table) {
      this.table.renderRows();
    }

    this.dataSource.sort = this.sort;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }

  get visibleColumns(): (keyof T | string)[] {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  routeTo(highlightPID: number = null): void {
    const table = {};
    table[tableNames.TASK_MANAGER_PROCESSES] = {
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
    this.stateSvc.updateCurrentUserSettings({
      consoleUI: {
        table
      }
    });

    this.router.navigate(this.link, {
      state: { highlightPID: highlightPID },
      queryParamsHandling: 'preserve'
    });
  }

  rowClick(process: Process): void {
    this.routeTo(process.processID);
  }
}
