export class Agreement {
  public key: string;
  public title: string;
  public documents: AgreementDocument[];

  constructor(init?: Partial<Agreement>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }
}

export class AgreementDocument {
  public link: string;
  public name: string;

  constructor(init?: Partial<AgreementDocument>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
  }
}
