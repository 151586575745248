import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { deepEqual } from 'fast-equals';
import { BehaviorSubject, iif } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { fadeInRight80ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { StateService } from 'src/app/core/services/state.service';
import {
  ComponentDisplayConfig,
  DisplayConfig,
  DisplayField,
} from 'src/app/shared/components/display-config-button/display-config-button.component';
import { tableNames } from 'src/app/shared/constants/tables';
import { StartupApp } from 'src/app/shared/models/system-summary.model';
import { deepCopy } from 'src/app/utils/clone';
import { isEmpty } from 'src/app/utils/condition';

@UntilDestroy()
@Component({
  selector: 'mon-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss'],
  animations: [stagger20ms, fadeInRight80ms, fadeInUp400ms]
})
export class StartupComponent {
  readonly DEFAULT_SORT_FIELD = 'name';
  readonly DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

  tableID = tableNames.TASK_MANAGER_STARTUP;
  sortField = this.DEFAULT_SORT_FIELD;
  sortDirection = this.DEFAULT_SORT_DIRECTION;
  dataSource = new MatTableDataSource<StartupApp>();

  searchCtrl = new UntypedFormControl();

  columns: TableColumn<StartupApp>[] = [
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      hideable: false,
      cellClasses: ['font-medium', 'truncate']
    },
    {
      label: 'Path',
      property: 'path',
      type: 'text',
      visible: true,
      hideable: true,
      cellClasses: ['text-secondary', 'truncate']
    }
  ];

  agentStats$ = this.stateSvc.liveMode$.pipe(
    mergeMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$
      )
    ),
    filter((agent) => !!agent),
    map((agent) => agent.stats),
    map((stats) => (!!stats && stats.summary.system.startupApps) || []),
    filter((startupApps) => startupApps.length > 0),
    tap((startupApps) => {
      this.updateDataSource(deepCopy(startupApps)); //need to clone map
    })
  );

  settings$ = this.stateSvc.currentUserSettings$.pipe(
    map((settings) => {
      const found = settings.consoleUI.table[this.tableID];

      return {
        sortField: found?.sortField || this.DEFAULT_SORT_FIELD,
        sortDirection: found?.sortDirection || this.DEFAULT_SORT_DIRECTION
      };
    }),
    distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
    tap((settings) => {
      this.sortField = isEmpty(settings.sortField)
        ? this.DEFAULT_SORT_FIELD
        : settings.sortField;
      this.sortDirection = isEmpty(settings.sortDirection)
        ? this.DEFAULT_SORT_DIRECTION
        : settings.sortDirection;
    })
  );

  private _filtering = new BehaviorSubject<boolean>(false);
  public filtering$ = this._filtering.asObservable();

  displayConfig$ = this.stateSvc.currentUserSettings$.pipe(
    map((s) => {
      const config = new ComponentDisplayConfig({
        id: `table.${this.tableID}`,
        show: true,
        fields: this.hideableColumns.map((c) => new DisplayField(c.label, c.visible))
      });
      const saved = s.consoleUI.getSettingByID<DisplayConfig>(config.id);
      return config.merge(saved);
    })
  );

  visibleColumns$ = this.displayConfig$.pipe(
    map((config) =>
      this.columns
        .filter((column) => column.hideable || column.visible)
        .filter((column) => config.fieldValue(column.label))
        .map((column) => column.property)
    )
  );

  sort: MatSort;
  @ViewChild(MatSort) set sortSetter(sort: MatSort) {
    if (!this.sort && !!sort) {
      this.sort = sort;
      this.initControls();
    }
  }

  constructor(
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService
  ) {
    this.searchCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => this._filtering.next(true))
      )
      .subscribe({
        next: (value) => {
          if (!this.dataSource) {
            return;
          }
          value = value.trim();
          value = value.toLowerCase();
          this.dataSource.filter = value;
          this._filtering.next(false);
        }
      });
  }

  initControls(): void {
    this.dataSource.sort = this.sort;

    this.sort.sortChange
      .pipe(
        untilDestroyed(this),
        tap((sort) => {
          const table = {};
          table[this.tableID] = {
            sortField: sort.active,
            sortDirection: sort.direction
          };
          this.stateSvc.updateCurrentUserSettings({
            consoleUI: {
              table
            }
          });
        })
      )
      .subscribe();
  }

  updateDataSource(agentStartupApps: Array<StartupApp>): void {
    const appMap = new Map<string, StartupApp>();

    agentStartupApps.forEach((s) => appMap.set(s.name, s));

    for (let i = 0; i < this.dataSource.data.length; ) {
      const oldApp = this.dataSource.data[i];
      const newApp = appMap.get(oldApp.name);

      if (newApp != null) {
        if (oldApp != null) {
          if (!deepEqual(oldApp, newApp)) {
            Object.assign(oldApp, newApp);
          }
          appMap.delete(oldApp.name);
        }
        i++;
      } else {
        this.dataSource.data.splice(i, 1);
      }
    }

    appMap.forEach((s) => {
      if (s) {
        this.dataSource.data.push(s);
      }
    });
  }

  get hideableColumns(): TableColumn<StartupApp>[] {
    return this.columns.filter((c) => c.hideable);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>): keyof T | string {
    return column.property;
  }
}
