import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AgentService } from 'src/app/core/services/agent.service';
import { SearchService } from 'src/app/core/services/search.service';
import { Agent } from 'src/app/shared/models/agent.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';

export class MachinesDataSource implements DataSource<Agent> {
  private _agents = new BehaviorSubject<Agent[]>(undefined);
  private _initialLoading = new BehaviorSubject<boolean>(true);
  public initialLoading$ = this._initialLoading.asObservable();
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public serverResultsLength: number;
  public filteredLength: number;
  public get data(): Agent[] {
    return this._agents.value?.slice() || [];
  }

  constructor(private searchSvc: SearchService, private agentSvc: AgentService) {}

  loadMachines(filter: string, limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    of(filter)
      .pipe(
        switchMap((f) => {
          return iif(
            () => !!f,
            this.searchSvc.getAgents(filter, limit, page, orderBy),
            this.agentSvc.getAll(limit, page, orderBy)
          );
        }),
        catchError(() => of([])),
        finalize(() => {
          this._initialLoading.next(false);
          this._loading.next(false);
        })
      )
      .subscribe({
        next: (results: PagedResult<Agent>) => {
          this.serverResultsLength = results.count;
          this.filteredLength = results.count;
          this._agents.next(results.items || []);
        }
      });

    // this.agentSvc
    //   .getAll(limit, page, orderBy)
    //   .pipe(
    //     catchError(() => of([])),
    //     finalize(() => {
    //       this._initialLoading.next(false);
    //       this._loading.next(false);
    //     })
    //   )
    //   .subscribe({
    //     next: (results: PagedResult<Agent>) => {
    //       this.serverResultsLength = results.count;
    //       this.filteredLength = results.count;
    //       this._agents.next(results.items || []);
    //     }
    //   });
  }

  connect(): Observable<Agent[]> {
    return this._agents.asObservable();
  }

  disconnect(): void {
    this._agents.complete();
    this._loading.complete();
  }
}
