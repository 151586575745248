<ng-container *ngIf="startupTimeSeries$ | async as series">
  <div>Total Time: {{ totalTime }}</div>
  <apx-chart
    [tooltip]="apexOptions.tooltip"
    [grid]="apexOptions.grid"
    [colors]="apexOptions.colors"
    [xaxis]="apexOptions.xaxis"
    [yaxis]="apexOptions.yaxis"
    [stroke]="apexOptions.stroke"
    [chart]="apexOptions.chart"
    [dataLabels]="apexOptions.dataLabels"
    [plotOptions]="apexOptions.plotOptions"
    [series]="series"
    #chartObj
  >
  </apx-chart>
</ng-container>
