import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GmInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!!window['cc_gm']) {
      const newRequest = request.clone({
        setHeaders: {
          'x-commandctrl-gm': '1'
        }
      });
      return next.handle(newRequest);
    }

    return next.handle(request);
  }
}
