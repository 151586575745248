<ng-container *ngIf="sortSettings$ | async"> </ng-container>
<ng-container *ngIf="agentStats$ | async"> </ng-container>
<div @fadeInUp class="card overflow-x-auto overflow-y-hidden h-full">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
    <h2 class="title my-0 pr-4 mr-4 border-r flex-none">
      <span>Services [{{ this.searchCtrl.value ? dataSource.filteredData.length + '/' : '' }}{{ dataSource.data.length }}] </span>
    </h2>

    <span class="flex-1"></span>

    <div class="bg-card rounded-md border px-2 relative hidden md:flex justify-start items-center content-center">
      <fa-icon [icon]="['fad', 'search']" size="lg" [fixedWidth]="true"></fa-icon>
      <input
        #filterInput
        [formControl]="searchCtrl"
        class="pl-2 py-2 border-0 outline-none w-full bg-transparent"
        placeholder="Search Services..."
      />
      <button
        mat-icon-button
        [style.visibility]="searchCtrl.value ? 'visible' : 'hidden'"
        (click)="searchCtrl.setValue(''); filterInput.focus()"
        class="flex items-center justify-center w-6 h-6"
        matSuffix
      >
        <fa-icon [icon]="['fad', 'times']" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <mon-display-config-button
      matTooltip="Filter Columns"
      *ngIf="displayConfig$ | async as config"
      persistAt="User"
      [config]="config"
      [canHide]="false"
      [icon]="['fad', 'columns']"
      class="ml-2 flex items-center justify-center flex-none"
    ></mon-display-config-button>
  </div>

  <div *ngIf="filtering$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
    <div class="absolute w-full h-full bg-gray opacity-10"></div>
    <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
  </div>

  <!-- <cdk-virtual-scroll-viewport [tvsItemSize]="29" [headerHeight]="24" class="full-virtual-scroll-table"> -->
  <table
    [@listStagger]="dataSource.data.length"
    [dataSource]="dataSource"
    mat-table
    matSort
    [matSortActive]="this.sortField"
    [matSortDirection]="this.sortDirection"
    class="w-full table-fixed"
  >
    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase w-1/4" mat-header-cell mat-sort-header>{{ column.label }}</th>
        <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
          {{ row[column.property] }}
        </td>
      </ng-container>
    </ng-container>

    <!-- ButtonArray Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container *ngIf="column.type === 'buttonArray'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
        <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
          <div class="flex items-center justify-end">
            <ng-container *ngFor="let button of column.buttons">
              <button
                *ngIf="button.showConditionFn ? button.showConditionFn(row) : true"
                [matTooltip]="button.tooltip"
                (click)="button.fn(row)"
                [ngClass]="button.buttonClasses"
                class="flex items-center justify-center w-8 h-8 leading-none transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
                mat-icon-button
              >
                <fa-icon [icon]="['fad', button.icon]" size="lg" [class]="button.iconClasses"> </fa-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
      <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
      <ng-container *matRowDef="let row; columns: visibleColumns">
        <tr @fadeInRight class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
      </ng-container>
    </ng-container>
  </table>
  <!-- </cdk-virtual-scroll-viewport> -->
</div>
