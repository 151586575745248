const replacer = function (_key, value) {
  if (value instanceof Map) {
    const v = {};
    value.forEach((value, key) => (v[key] = value));
    return v;
  }
  return value;
};

export function toJson(o: unknown): string {
  return JSON.stringify(o, replacer);
}
