import { ChangeDetectorRef, Directive, HostBinding } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ConfigService } from '../../services/config.service';

@UntilDestroy()
@Directive({
  selector: '[vexContainer]'
})
export class ContainerDirective {
  @HostBinding('class.container') enabled: boolean;

  constructor(private configService: ConfigService, private cd: ChangeDetectorRef) {
    this.configService.config$
      .pipe(
        untilDestroyed(this),
        filter((config) => !!config),
        map((config) => config.boxed),
        distinctUntilChanged()
      )
      .subscribe({
        next: (boxed) => {
          this.enabled = boxed;
          this.cd.markForCheck();
        }
      });
  }
}
