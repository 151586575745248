<ng-container *ngIf="settings$ | async as settings"></ng-container>
<div class="flex flex-col">
  <div @fadeInUp class="relative card overflow-x-auto overflow-y-hidden">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex justify-start items-center content-center">
      <h2 class="title my-0 pr-4 mr-4 border-r flex-none">
        <span>Triggers</span>
      </h2>

      <span class="flex-1"></span>

      <button class="ml-4 flex-none" mat-icon-button matTooltip="Add Trigger" type="button" (click)="editTrigger()">
        <fa-icon [icon]="['fad', 'plus']" size="lg"> </fa-icon>
      </button>

      <button
        class="ml-4 leading-none flex-none"
        mat-icon-button
        matTooltip="Remove Trigger"
        type="button"
        [disabled]="selection.isEmpty()"
        (click)="removeTrigger(selection.selected)"
      >
        <fa-icon [icon]="['fad', 'times']" size="lg"> </fa-icon>
      </button>

      <button
        class="ml-4 leading-none flex-none"
        mat-icon-button
        matTooltip="Enable Trigger"
        type="button"
        [disabled]="selection.isEmpty()"
        (click)="enableTrigger(selection.selected)"
      >
        <fa-icon [icon]="['fad', 'up']" size="lg"> </fa-icon>
      </button>

      <button
        class="ml-4 leading-none flex-none"
        mat-icon-button
        matTooltip="Disable Trigger"
        type="button"
        [disabled]="selection.isEmpty()"
        (click)="disableTrigger(selection.selected)"
      >
        <fa-icon [icon]="['fad', 'down']" size="lg"> </fa-icon>
      </button>

      <mon-display-config-button
        matTooltip="Filter Columns"
        *ngIf="displayConfig$ | async as config"
        persistAt="User"
        [config]="config"
        [canHide]="false"
        [icon]="['fad', 'columns']"
        class="ml-2 flex items-center justify-center flex-none"
      ></mon-display-config-button>
    </div>

    <!-- <div *ngIf="true" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10"> -->
    <div *ngIf="loading$ | async" class="absolute flex flex-wrap w-full h-full justify-center content-center top-0 z-10">
      <div class="absolute w-full h-full bg-gray opacity-10"></div>
      <fa-icon [icon]="['fad', 'cog']" animation="spin" size="4x" class="text-primary animate-pulse"></fa-icon>
    </div>

    <table
      [dataSource]="dataSource"
      [@listStagger]="dataSource.length"
      mat-table
      matSort
      [matSortActive]="this.sortField"
      [matSortDirection]="this.sortDirection"
      class="w-full"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            (change)="masterToggle($event)"
            [checked]="selection.hasValue() && selection.selected.length === dataSource.filteredLength"
            [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.filteredLength"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox
            (change)="$event ? selection.toggle(row) : null"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <ng-container *ngIf="column.sortable !== false; else noSort">
            <th *matHeaderCellDef class="uppercase" mat-header-cell [mat-sort-header]="column.display || column.property">
              {{ column.label }}
            </th>
          </ng-container>
          <ng-template #noSort>
            <th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label }} </th>
          </ng-template>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Date Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{ column.label }}</th>
          <td tipOnEllip *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <ng-container [ngSwitch]="column.dateFormat">
              <ng-container *ngSwitchCase="'timeAgo'">
                {{ row[column.property] | timeAgo }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ row[column.property] | date: column.dateFormat || 'medium' }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <!-- ButtonArray Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'buttonArray'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell></th>
          <td *matCellDef="let row" [ngClass]="column.cellClasses" mat-cell>
            <div class="flex items-center justify-end">
              <ng-container *ngFor="let button of column.buttons">
                <button
                  *ngIf="button.showConditionFn ? button.showConditionFn(row) : true"
                  [matTooltip]="button.tooltip"
                  (click)="$event.stopPropagation(); button.fn(row)"
                  [ngClass]="button.buttonClasses"
                  class="flex items-center justify-center w-8 h-8 leading-none transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
                  mat-icon-button
                >
                  <fa-icon [icon]="['fad', button.icon]" size="lg" [class]="button.iconClasses"> </fa-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="visibleColumns$ | async as visibleColumns">
        <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: visibleColumns"
          @fadeInRight
          (click)="editTrigger(row)"
          class="hover:bg-hover trans-ease-out cursor-pointer"
          [ngClass]="{ 'disabled-trigger': row.disabled }"
        ></tr>
      </ng-container>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="dataSource ? dataSource.total : 0"> </mat-paginator>
  </div>
</div>
