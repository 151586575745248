import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Agent } from 'src/app/shared/models/agent.model';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Script } from 'src/app/shared/models/script.model';

import { ApiService } from './api.service';

export interface SearchPerm {
  id: string;
  name: string;
  type: 'role' | 'user';
}

@Injectable({
  providedIn: 'root'
})
export class SearchService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getAgents(
    filter: string,
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = null
  ): Observable<PagedResult<Agent>> {
    const url = `${this.apiUrl}/search/agents`;
    const params = {};
    Object.assign(params, {
      filter,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Agent>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(
          `fetched SearchService.getAgents response in ${performance.now() - start}ms`
        );
      }),
      map((response) =>
        response
          ? new PagedResult<Agent>(Agent, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Agent>>('SearchService.getAgents');
        return throwError(() => err);
      })
    );
  }

  getScripts(
    filter: string,
    installed = -1,
    enabled = -1,
    limit = 0,
    page = 0,
    orderBy: OrderBy[] = null
  ): Observable<PagedResult<Script>> {
    const url = `${this.apiUrl}/search/scripts`;
    const params = {};
    Object.assign(params, {
      filter,
      installed,
      enabled,
      limit,
      page,
      orderBy: OrderBy.OrderByToString(orderBy)
    });
    const start = performance.now();
    return this.http.get<Array<Script>>(url, { observe: 'response', params }).pipe(
      tap(() => {
        this.log(
          `fetched SearchService.getScripts response in ${performance.now() - start}ms`
        );
      }),
      map((response) =>
        response
          ? new PagedResult<Script>(Script, {
              count: +response.headers.get('Pagination-Count'),
              items: response.body,
              page: page,
              limit: limit
            })
          : undefined
      ),
      catchError((err) => {
        this.handleError<Array<Agent>>('SearchService.getScripts');
        return throwError(() => err);
      })
    );
  }

  getPerms(filter: string): Observable<Array<SearchPerm>> {
    const url = `${this.apiUrl}/search/perms`;
    const params = {};
    Object.assign(params, {
      filter
    });
    return this.http.get<Array<SearchPerm>>(url, { params });
  }
}
