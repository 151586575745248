import { CdkStep } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from 'src/@vex/animations/fade-in-right.animation';
import { ScriptService } from 'src/app/core/services/script.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { parseError } from 'src/app/core/utils/http-reponse-error';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import {
  ActionCauseType,
  ActionCauseTypeLabel,
  ActionEffectType,
  ActionEffectTypeLabel,
  SystemEventCauseType,
  SystemEventCauseTypeLabel,
  SystemEventEffectType,
  SystemEventEffectTypeLabel
} from 'src/app/shared/enums/action.enum';
import {
  Action,
  ActionApply,
  ActionCause,
  ActionEffect
} from 'src/app/shared/models/action.model';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';
import { Trigger } from 'src/app/shared/models/trigger.model';
import { UNDEFINED } from 'src/app/utils/rxjs';

// import { mockScripts } from 'src/app/view/scripts/mock.data';
export class AddActionParams {
  action: Action;
  onSuccess: (action: Action) => void;
  constructor(init?: Partial<AddActionParams>) {
    Object.assign(this, init);
  }
}

@UntilDestroy()
@Component({
  selector: 'mon-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss'],
  animations: [fadeInRight400ms, fadeOutRight400ms]
})
export class AddActionComponent implements OnInit {
  ActionCauseType = ActionCauseType;
  ActionEffectType = ActionEffectType;

  ActionCauseTypeLabel = ActionCauseTypeLabel;
  SystemEventCauseTypeLabel = SystemEventCauseTypeLabel;
  ActionEffectTypeLabel = ActionEffectTypeLabel;
  SystemEventEffectTypeLabel = SystemEventEffectTypeLabel;

  causeTypes$ = of(Object.values(ActionCauseType));
  systemCauses$ = of(Object.values(SystemEventCauseType));
  systemEffects$ = of(Object.values(SystemEventEffectType));
  triggers$ = this.triggerSvc.getAll().pipe(map((r) => r.items));

  effectTypes$ = of(Object.values(ActionEffectType));

  installedScripts$ = this.scriptSvc
    .location(ScriptLocation.Private)
    .getAll()
    .pipe(map((result) => result.items));

  selectedTrigger: Trigger;
  selectedScript: Script;

  overlayResult = OverlayResult.Unset;
  spin = true;
  err: string;

  baseGroup: UntypedFormGroup;
  causeGroup: UntypedFormGroup;
  effectGroup: UntypedFormGroup;
  applyGroup: UntypedFormGroup;
  confirmGroup: UntypedFormGroup;
  processed: Action;

  @ViewChild('stepper') formStepper: MatStepper;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: AddActionParams,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddActionParams>,
    private triggerSvc: TriggerService,
    private scriptSvc: ScriptService
  ) {}

  ngOnInit(): void {
    this.spin = false;

    this.baseGroup = this.fb.group({
      name: [null, Validators.required],
      description: []
    });

    this.causeGroup = this.fb.group(
      {
        type: [],
        systemCause: [],
        trigger: []
      },
      {
        validators: (group: UntypedFormGroup) => {
          // this is need if the user preemptively selects the wrong item, we then need to reset drop errors - I think this is a bug in angular forms
          group.controls.type.setErrors(null);
          group.controls.systemCause.setErrors(null);
          group.controls.trigger.setErrors(null);

          const causeType = group.controls.type.value;
          const systemCause = group.controls.systemCause.value;
          const trigger = group.controls.trigger.value;

          if (causeType === ActionCauseType.System && !!systemCause) {
            return null;
          }

          if (causeType === ActionCauseType.Trigger && !!trigger) {
            return null;
          }

          return { invalidselection: true };
        }
      }
    );

    this.effectGroup = this.fb.group(
      {
        type: [],
        systemEffect: [],
        script: []
      },
      {
        validators: (group: UntypedFormGroup) => {
          // this is need if the user preemptively selects the wrong item, we then need to reset drop errors - I think this is a bug in angular forms
          group.controls.type.setErrors(null);
          group.controls.systemEffect.setErrors(null);
          group.controls.script.setErrors(null);

          const effectType = group.controls.type.value;
          const systemEffect = group.controls.systemEffect.value;
          const script = group.controls.script.value;

          if (effectType === ActionEffectType.System && !!systemEffect) {
            return null;
          }

          if (effectType === ActionEffectType.RunScript && !!script) {
            return null;
          }

          return { invalidselection: true };
        }
      }
    );

    this.applyGroup = this.fb.group({
      machines: [],
      users: []
    });

    this.confirmGroup = this.fb.group({});

    if (this.params.action) {
      this.baseGroup.patchValue(this.params.action);
      this.applyGroup.patchValue(this.params.action.apply);

      this.triggerSvc
        .getAll()
        .pipe(
          map((triggers) =>
            triggers.items.find((t) => t.id == this.params.action.cause.triggerID)
          ),
          tap((trigger) => {
            this.causeGroup.patchValue(this.params.action.cause);
            this.causeGroup.controls.trigger.setValue(trigger);
          })
        )
        .subscribe();

      this.installedScripts$
        .pipe(
          map((scripts) =>
            scripts.find((s) => s.id == this.params.action.effect.scriptID)
          ),
          tap((script) => {
            this.effectGroup.patchValue(this.params.action.effect);
            this.effectGroup.controls.script.setValue(script);
          })
        )
        .subscribe();
    }
  }

  addAction(): void {
    this.spin = true;
    this.closeError();
    const name = this.baseGroup.controls.name.value;
    const description = this.baseGroup.controls.description.value;
    const causeType = this.causeGroup.controls.type.value;
    const systemCause = this.causeGroup.controls.systemCause.value;
    const triggerKey = this.causeGroup.controls.trigger.value?.key;
    const effectType = this.effectGroup.controls.type.value;
    const systemEffect = this.effectGroup.controls.systemEffect.value;
    const scriptID = this.effectGroup.controls.script.value?.id;
    const machines = this.applyGroup.controls.machines.value;
    const users = this.applyGroup.controls.users.value;

    UNDEFINED.pipe(finalize(() => (this.spin = false))).subscribe({
      next: () => {
        this.processed = new Action({
          name: name,
          description: description,
          cause: new ActionCause({
            type: causeType,
            systemCause: systemCause,
            triggerID: triggerKey
          }),
          effect: new ActionEffect({
            type: effectType,
            systemEffect: systemEffect,
            scriptID: scriptID
          }),
          apply: new ActionApply({
            machines: machines,
            users: users
          }),
          created: new Date()
        });
        this.overlayResult = OverlayResult.Success;
      },
      error: (err: HttpErrorResponse) => {
        this.err = parseError(err);
        this.overlayResult = OverlayResult.Error;
      }
    });
  }

  overlayClose(r: OverlayResult): void {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success && this.processed) {
      this.params.onSuccess?.(this.processed);
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  closeError(): void {
    this.err = '';
  }

  setAllMatStepsAsInteracted(): void {
    if (this.params.action) {
      this.formStepper.steps.forEach((matStep: CdkStep) => {
        matStep.interacted = true;
      });
    }
  }
}
