<mon-spinner [spin]="spin" [overlay]="true">
  <div content>
    <mon-result-overlay [state]="overlayResult" (closed)="overlayClose($event)" [duration]="500"></mon-result-overlay>
    <div class="relative">
      <form autocomplete="off" (ngSubmit)="submit()" [formGroup]="form">
        <div class="flex justify-start items-center content-center" mat-dialog-title>
          <h2 class="headline m-0 truncate flex-auto">New Tenant</h2>
        </div>
        <mat-dialog-content>
          <div class="flex flex-col">
            <mat-form-field class="flex-1">
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput />
              <mat-hint>
                <div>
                  <div>only lowercase a-z, 0-9 and hypens</div>
                  <div>cannot end or begin with a hypen</div>
                  <div>cannot have consecutive hypens</div>
                </div>
              </mat-hint>
              <mat-error *ngIf="form.controls.name.errors?.required">Name required</mat-error>
              <mat-error *ngIf="form.controls.name.errors?.minlength">Name must be at least 3 characters</mat-error>
              <mat-error *ngIf="form.controls.name.errors?.maxlength">Name must be less than or equal to 60 characters</mat-error>
              <mat-error *ngIf="form.controls.name.errors?.exp1">Name can only contain lowercase a-z, 0-9, and hypens</mat-error>
              <mat-error *ngIf="form.controls.name.errors?.exp2">Name cannot end or begin with a hypen</mat-error>
              <mat-error *ngIf="form.controls.name.errors?.exp3">Name cannot have consecutive hypens</mat-error>
            </mat-form-field>

            <div class="flex flex-col items-center justify-center mt-8 h-8" [style.font-variant]="'small-caps'">
              <div *ngIf="tenantNameValidationErr; else validating" class="text-red">❌ {{ tenantNameValidationErr }}</div>
              <ng-template #validating>
                <div *ngIf="!tenantNameValidated && !form.invalid">validating name...</div>
                <div *ngIf="tenantNameValidated && form.valid" class="text-green">✔️ tenant name available</div>
              </ng-template>
            </div>
          </div>
        </mat-dialog-content>

        <mat-dialog-actions>
          <div class="flex justify-end w-full">
            <button mat-button mat-dialog-close type="button" [disabled]="spin">CANCEL</button>
            <button color="primary" mat-button type="submit" [disabled]="spin || form.invalid || !tenantNameValidated">SUBMIT</button>
          </div>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</mon-spinner>
