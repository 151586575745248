<ng-container [ngSwitch]="sessionData.remoteType">
  <ng-container *ngSwitchCase="'msra'">
    <div class="flex flex-col items-center justify-center py-4">
      <p>Connection succeeded. Download the attached file and open it within Windows. Then copy and paste the following password:</p>
      <div class="pt-6">
        <a [href]="downloadFile | sanitizeUrl" download="{{ sessionData.id + '.msrcincident' }}">
          <div class="flex items-center justify-center">
            <fa-icon [icon]="['fad', 'cloud-download']" size="3x" class="text-primary"> </fa-icon>
          </div>
          Download
        </a>
      </div>
      <div class="relative pt-6">
        <markdown [data]="sessionData.data['password'] | language: ''"> </markdown>
        <button
          #me="matTooltip"
          matTooltipHideDelay="2000"
          matTooltipDisabled
          matTooltip="Copied"
          class="absolute right-3 bottom-3 code-button text-sm bg-gray-900 hover:bg-gray-700 bg-opacity-60 px-2 py-1 rounded-md transition-colors duration-200"
          (click)="getCopy(me, sessionData.data['password'])"
        >
          copy
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'quickassist'">
    <div class="flex flex-col items-center justify-center py-4">
      <p>
        Agent successfully opened Quick Assist. Please open Quick Assist, initiate 'Give assistance' and provide the agent with your
        security code to connect.
      </p>
      <div class="relative pt-6">
        <img class="m-auto" src="assets/img/demo/quickassist.gif" />
      </div>
    </div>
  </ng-container>
</ng-container>
