import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, iif, map, switchMap, take, tap } from 'rxjs';
import {
  fadeInRight400ms,
  fadeOutRight400ms
} from 'src/@vex/animations/fade-in-right.animation';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { OverlayResult } from 'src/app/shared/components/dialogs/result-overlay/result-overlay.component';
import { DiskVolumeData } from 'src/app/shared/interfaces/disk-volumes.interface';
import { GraphOptions } from 'src/app/shared/interfaces/graph-options.interface';
import { Agent } from 'src/app/shared/models/agent.model';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { deepCopy } from 'src/app/utils/clone';
import { friendlyDataSizeInBytes } from 'src/app/utils/data-size';

export class DiskPartitionParams {
  graphOptions: GraphOptions;
  agentID: string;
  constructor(init?: Partial<DiskPartitionParams>) {
    Object.assign(this, init);
  }
}

export interface Tile {
  text: string;
}

@UntilDestroy()
@Component({
  selector: 'mon-disk-partition',
  templateUrl: './disk-partition.component.html',
  styleUrls: ['./disk-partition.component.scss'],
  animations: [fadeInRight400ms, fadeOutRight400ms]
})
export class DiskPartitionComponent implements OnInit {
  @ViewChild('container') container: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private params: DiskPartitionParams,
    protected dialogRef: MatDialogRef<DiskPartitionComponent>,
    private stateSvc: StateService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private diagnosticsService: DiagnosticsService,
    private pubSubSvc: PubSubWsService
  ) {
    //this.view = params.view;
  }

  agent$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    tap((agent) => {
      this.agent = agent;
      this.getDiskVolumes();
    }),
    take(1)
  );

  readonly channelName$ = this.agent$.pipe(
    map((a) => `$diagnostic:web.${this.stateSvc.tenant.id}.${a.id}.diskVolumesResponse`)
  );

  readonly channelListener$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          if (packet.type === PacketType.DiskVolumesPacket) {
            const v: DiskVolumeData[] = JSON.parse(<string>packet.content);
            this.updateTables(v);
          } else if (packet.type === PacketType.StartPacket) {
            // const p: PacketStart = JSON.parse(<string>packet.content);
            //this.timestamp = p.timestamp;
          } else if (packet.type === PacketType.CompletePacket) {
            //setTimeout(() => this._running.next(false), 2000);
          } else if (packet.type === PacketType.ErrorPacket) {
            //setTimeout(() => this._running.next(false), 2000);
          }
        })
      )
    )
  );

  spin: boolean;
  overlayResult = OverlayResult.Unset;
  tiles: Tile[] = [];
  diskName: string;
  diskSize: string;
  agent: Agent;
  ranOnce = false;

  getDiskVolumes() {
    if (!this.ranOnce) {
      this.ranOnce = true;
    } else {
      return;
    }

    this.diagnosticsService.runDiskVolumes(this.agent.id).subscribe({
      error: (err) => {
        console.warn(err);
      }
    });
  }

  updateTables(volumes: DiskVolumeData[]) {
    const diskID = this.params.graphOptions.id;
    const disk = this.agent.stats.summary.disk.disks.find((d) => d.id === diskID);
    const parts = disk.partitions.filter(
      (part) => !part.type.includes('Reserved Partition')
    );
    const vols = volumes;

    this.diskName = 'Disk ' + disk.index;
    this.diskSize = friendlyDataSizeInBytes(disk.capacity);
    this.diskSize = friendlyDataSizeInBytes(disk.capacity);

    parts.forEach((part) => {
      let drive = '';
      let used = '';
      let label = '';
      const size = friendlyDataSizeInBytes(part.length, 2);
      vols.forEach((v) => {
        const s2 = friendlyDataSizeInBytes(v.total, 2);
        // if match then use the drive # from vol
        if (size == s2) {
          if (v.name) {
            drive = `(${v.name})`;
          }
          label = v.volName;
          used = friendlyDataSizeInBytes(v.used, 2);
        }
      });

      const displayText = `${label} ${drive} ${used ? '[' + used + ' used]' : ''}
${size}
(${part.type})`;

      this.tiles.push({
        text: displayText
      });
    });

    this.spin = false;
  }

  ngOnInit(): void {
    this.spin = true;
  }

  overlayClose(r: OverlayResult) {
    this.overlayResult = OverlayResult.Unset;
    if (r === OverlayResult.Success) {
      this.dialogRef.close();
    }
  }
}
