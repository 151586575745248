import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { Script, ScriptLocation } from 'src/app/shared/models/script.model';

@Component({
  selector: 'mon-script-list-item',
  templateUrl: './script-list-item.component.html',
  styleUrls: ['./script-list-item.component.scss']
})
export class ScriptListItemComponent {
  scriptLocation = ScriptLocation;

  @Input() script: Script;
  @Input() menu: MatMenuPanel;
  @Input() showHover = true;

  _selected = false;
  @Input() get selected(): boolean {
    return this._selected;
  }
  set selected(selected: boolean) {
    this._selected = selected;
    if (this._selected) {
      this.scrollTo();
    }
  }
  @ViewChild('item', { static: true }) item: ElementRef;

  scrollTo() {
    this.item.nativeElement.parentElement.scrollIntoViewIfNeeded(true);
  }
}
