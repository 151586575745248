import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';

export interface Options {
  header?: string;
  loading?: boolean;
  closeAfterTimeout?: number;
  closeButtonHidden?: boolean;
  closeButtonText?: string;
  content?: string;
  component?: Type<unknown>;
  componentData?: unknown;
}

type loaderState = 'opened' | 'closed';

@Injectable({ providedIn: 'root' })
export class LoaderService extends ApiService {
  private state: loaderState = 'closed';
  private timeout: NodeJS.Timeout;

  private _defaultOpenOptions: Options = {
    loading: true,
    closeButtonHidden: false,
    closeButtonText: '',
    content: ''
  };

  private _defaultCloseOptions: Options = {
    loading: false,
    closeButtonHidden: true,
    closeButtonText: 'Close',
    content: ''
  };

  private _options = new BehaviorSubject<Options>(this._defaultCloseOptions);
  options$ = this._options.asObservable();
  get options(): Options {
    return this._options.value;
  }

  constructor() {
    super();
  }

  open(message = '', timeout = 60000): void {
    if (this.state === 'opened') {
      return;
    }

    this.state = 'opened';
    this._options.next({ ...this._defaultOpenOptions, ...{ header: message } });
    this.timeout = setTimeout(() => {
      if (this.state === 'opened') {
        this.close({
          header: 'Timeout',
          content: 'Timeout waiting for response',
          closeButtonHidden: false
        });
      }
    }, timeout);
  }

  close(options: Options = {}): void {
    if (this.state === 'closed') {
      return;
    }

    clearTimeout(this.timeout);
    this.state = 'closed';
    this._options.next({
      ...this._defaultCloseOptions,
      ...(options || {}),
      ...{ loading: false }
    });
  }
}
