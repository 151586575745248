export class Role {
  public id: string;
  public name: string;
  public description: string;
  public permissions: string[] = [];
  public system: boolean;
  public timestamp: Date;
  public created: Date;

  get permissionsD(): string {
    return this.permissions?.join(', ') || '';
  }

  constructor(init?: Partial<Role>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    this.permissions = init.permissions || [];
    this.timestamp = new Date(init.timestamp);
    this.created = new Date(init.created);
  }
}
