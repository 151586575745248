import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { RoleService } from 'src/app/core/services/role.service';
import { OrderBy } from 'src/app/shared/models/order-by.model';
import { PagedResult } from 'src/app/shared/models/paged-result.model';
import { Role } from 'src/app/shared/models/role.model';

export class RolesDataSource implements DataSource<Role> {
  private _roles = new BehaviorSubject<Role[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();
  public total: number;
  public get length(): number {
    return this._roles.value ? this._roles.value.length : -1;
  }
  public get data(): Role[] {
    return this._roles.value?.slice() || [];
  }

  constructor(private roleSvc: RoleService) {}

  loadRoles(filter: string, limit: number, page: number, orderBy: OrderBy[]): void {
    this._loading.next(true);

    this.roleSvc
      .getAll(filter, limit, page, orderBy)
      .pipe(
        catchError(() => of([])),
        finalize(() => this._loading.next(false))
      )
      .subscribe({
        next: (results: PagedResult<Role>) => {
          this.total = results.count;
          this._roles.next(results.items || []);
        }
      });
  }

  connect(): Observable<Role[]> {
    return this._roles.asObservable();
  }

  disconnect(): void {
    this._roles.complete();
    this._loading.complete();
  }
}
