import { TriggerCategory } from '../enums/trigger-category.enum';

export type AlarmCounts = Map<TriggerCategory, Map<string, number>>;
export type AlarmTimes = Map<TriggerCategory, Map<string, Date[]>>;

export function getCategoryAlarmTotals(
  alarmCounts: AlarmCounts
): Map<TriggerCategory, number> {
  const map = new Map<TriggerCategory, number>();

  if (alarmCounts) {
    alarmCounts.forEach((alarms, category) => {
      let result = 0;
      alarms.forEach((count) => (result += count));
      map.set(category, result);
    });
  }

  return map;
}

export class HourlyAlarmCounts {
  public id: string;
  public dateID: string;
  public alarms: AlarmCounts = new Map<TriggerCategory, Map<string, number>>();

  constructor(init?: Partial<HourlyAlarmCounts>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);
  }

  public totalProcessesIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsProcesses) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalCPUIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryCPU) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalRAMIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryMemory) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalDiskIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryDisk) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalNetworkIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryNetwork) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalSystemIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummarySystem) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalGPUIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryGPU) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }
}

export class HistoryDate {
  public id: string;
  public timestamp: Date;
  public alarms: AlarmCounts;
  public hourlySummaries: Array<HistoryHourSummary> = new Array<HistoryHourSummary>();

  constructor(init?: Partial<HistoryDate>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    if (!(init.alarms instanceof Map) && init.alarms) {
      this.alarms = new Map<TriggerCategory, Map<string, number>>();
      Object.keys(init.alarms).forEach((categoryName) => {
        const alarmKeys = new Map<string, number>();
        const category = init.alarms[categoryName];
        Object.keys(category).forEach((alarmKey) => {
          alarmKeys.set(alarmKey, category[alarmKey]);
        });
        this.alarms.set(<TriggerCategory>(<unknown>categoryName), alarmKeys);
      });
    }
  }

  public totalProcessesIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsProcesses) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalCPUIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryCPU) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalRAMIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryMemory) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalDiskIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryDisk) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalNetworkIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryNetwork) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalGPUIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryGPU) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalSystemIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummarySystem) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }
}

export class HistoryHourSummary {
  public refHourID = '';
  public refDateID: string;
  public id = '';
  public dateID: string;
  public timestamp: Date;
  public alarms: AlarmCounts;

  constructor(init?: Partial<HistoryHourSummary>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    if (!(init.alarms instanceof Map) && init.alarms) {
      this.alarms = new Map<TriggerCategory, Map<string, number>>();
      Object.keys(init.alarms).forEach((categoryName) => {
        const alarmKeys = new Map<string, number>();
        const category = init.alarms[categoryName];
        Object.keys(category).forEach((alarmKey) => {
          alarmKeys.set(alarmKey, category[alarmKey]);
        });
        this.alarms.set(<TriggerCategory>(<unknown>categoryName), alarmKeys);
      });
    }
  }

  public totalProcessesIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsProcesses) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalCPUIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryCPU) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalRAMIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryMemory) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalDiskIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryDisk) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalNetworkIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryNetwork) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalGPUIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummaryGPU) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }

  public totalSystemIncidents(): number {
    let result = 0;
    if (this.alarms) {
      this.alarms.forEach((alarms, category) => {
        if (category !== TriggerCategory.AgentStatsSummarySystem) {
          return;
        }
        alarms.forEach((count) => (result += count));
      });
    }
    return result;
  }
}

export class HistoryHourDetail {
  public id = '';
  public dateID = '';
  public agentID = '';
  public alarms: AlarmTimes;
  public collectionTimestamps: Array<Date>;

  constructor(init?: Partial<HistoryHourDetail>) {
    if (!init) {
      return;
    }

    Object.assign(this, init);

    if (!(init.alarms instanceof Map) && init.alarms) {
      this.alarms = new Map<TriggerCategory, Map<string, Date[]>>();
      Object.keys(init.alarms).forEach((categoryName) => {
        const alarmKeys = new Map<string, Date[]>();
        const initAlarmKeys = init.alarms[categoryName] as Map<string, Date[]>;
        Object.keys(initAlarmKeys).forEach((alarmKey) => {
          const series = initAlarmKeys[alarmKey] as Date[];
          alarmKeys.set(alarmKey, series.map((t) => new Date(t)).sort());
        });
        this.alarms.set(<TriggerCategory>(<unknown>categoryName), alarmKeys);
      });
    }

    this.collectionTimestamps = init.collectionTimestamps.map((t) => new Date(t)).sort();
  }
}
