import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import Centrifuge from 'centrifuge';
import { BehaviorSubject, iif, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap as switchMap,
  retry,
  take,
  tap
} from 'rxjs/operators';
import { AgentHistoryPlayerService } from 'src/app/core/services/agent-history-player.service';
import { AgentLivePollerService } from 'src/app/core/services/agent-live-poller.service';
import { DiagnosticsService } from 'src/app/core/services/diagnostics.service';
import { PubSubWsService } from 'src/app/core/services/pubsubws.service';
import { StateService } from 'src/app/core/services/state.service';
import { TaskService } from 'src/app/core/services/task.service';
import { getDnsOrIpRegex, getIPAddressRegex } from 'src/app/core/utils/regex';
import { PacketComplete, PacketStart } from 'src/app/shared/interfaces/packet.interface';
import {
  PingPacket,
  PingStatistics,
  PingTestData
} from 'src/app/shared/interfaces/ping-test.interface';
import { Packet, PacketType } from 'src/app/shared/models/live-ws.model';
import { deepCopy } from 'src/app/utils/clone';
import { UNDEFINED } from 'src/app/utils/rxjs';

@Component({
  selector: 'mon-ping-test',
  templateUrl: './ping-test.component.html',
  styleUrls: ['./ping-test.component.scss']
})
export class PingTestComponent {
  ipRegex = getIPAddressRegex();

  private _pingResult = new BehaviorSubject<string>(undefined);
  readonly pingResult$ = this._pingResult.asObservable();

  private _running = new BehaviorSubject<boolean>(false);
  readonly running$ = this._running.asObservable().pipe(
    tap((running) => {
      if (running) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    })
  );

  agent$ = this.stateSvc.liveMode$.pipe(
    switchMap((liveMode) =>
      iif(
        () => liveMode,
        this.agentLivePollerSvc.liveAgent$,
        this.agentHistoryPlayerSvc.historyAgent$.pipe(map((agent) => deepCopy(agent)))
      )
    ),
    filter((agent) => !!agent),
    take(1)
  );

  readonly channelName$ = this.agent$.pipe(
    map((a) => `$diagnostic:web.${this.stateSvc.tenant.id}.${a.id}.pingResponse`)
  );

  readonly channelHistory$ = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.history(channel, {
        limit: 20,
        reverse: true
      })
    ),
    catchError(() => {
      return of(<Centrifuge.HistoryResult>{
        publications: [],
        offset: -1,
        epoch: ''
      });
    }),
    map((history) => {
      let result = undefined;
      let start: Date;
      let complete: Date;

      for (let i = history.publications.length - 1; i >= 0; i--) {
        const packet = history.publications[i].data as Packet;

        if (packet.type === PacketType.StartPacket) {
          const s: PacketStart = JSON.parse(<string>packet.content);
          result = '';
          complete = undefined;
          start = s.timestamp;
        } else if (start) {
          if (!result && packet.type === PacketType.PingPacket) {
            const p: PingPacket = JSON.parse(<string>packet.content);
            result += this.formatPingHeader(p.IPAddr.IP, p.Addr);
          } else if (packet.type === PacketType.PingPacket) {
            const p: PingPacket = JSON.parse(<string>packet.content);
            result += this.formatPingPacket(p);
          } else if (packet.type === PacketType.PingStatPacket) {
            const s: PingStatistics = JSON.parse(<string>packet.content);
            result += this.formatPingStat(s);
          } else if (packet.type === PacketType.CompletePacket) {
            const s: PacketComplete = JSON.parse(<string>packet.content);
            complete = s.timestamp;
          }
        } else {
          continue;
        }
      }

      if (start) {
        this.lastRan = start;
        if (!complete) {
          this._running.next(true);
        }
      }

      return result;
    }),
    switchMap((result) => {
      return iif(
        () => !!result,
        of(result),
        this.agent$.pipe(
          switchMap((agent) => this.diagnosticsService.getLastPingTestResult(agent.id)),
          map((response) => {
            if (response.tag) {
              this.lastRan = response.tag.timestamp;
              return this.formatPing(response.tag);
            }

            return undefined;
          })
        )
      );
    }),
    tap((result) => this._pingResult.next(result))
  );

  readonly channelListener = this.channelName$.pipe(
    switchMap((channel) =>
      this.pubSubSvc.listen<Packet>(channel).pipe(
        tap((packet) => {
          let result = this._pingResult.value;
          if (!result && packet.type === PacketType.PingPacket) {
            const p: PingPacket = JSON.parse(<string>packet.content);
            result += this.formatPingHeader(p.IPAddr.IP, p.Addr);
          } else if (packet.type === PacketType.PingPacket) {
            const p: PingPacket = JSON.parse(<string>packet.content);
            result += this.formatPingPacket(p);
          } else if (packet.type === PacketType.PingStatPacket) {
            const s: PingStatistics = JSON.parse(<string>packet.content);
            result += this.formatPingStat(s);
          } else if (packet.type === PacketType.StartPacket) {
            this._running.next(true);
            this._pingResult.next('');
            const s: PacketStart = JSON.parse(<string>packet.content);
            this.lastRan = s.timestamp;
            return;
          } else if (packet.type === PacketType.CompletePacket) {
            this._running.next(false);
            return;
          } else if (packet.type === PacketType.ErrorPacket) {
            this._running.next(false);
            return;
          }

          this._pingResult.next(result);
        }),
        tap(() =>
          setTimeout(
            () =>
              (this.output.nativeElement.scrollTop =
                this.output.nativeElement.scrollHeight),
            100
          )
        )
      )
    )
  );

  readonly latestResult$ = UNDEFINED.pipe(
    tap(() => {
      this.form.disable();
      this.spin = true;
    }),
    switchMap(() => this.channelHistory$),
    tap((result) => this._pingResult.next(result)),
    finalize(() => {
      this.form.enable();
      this.spin = false;
    })
  );

  spin = true;
  lastRan: Date;

  form: UntypedFormGroup = new UntypedFormGroup({
    ipToPing: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(getDnsOrIpRegex())
    ])
  });

  @ViewChild('output', { static: false }) output: ElementRef;

  constructor(
    private clipboard: Clipboard,
    private stateSvc: StateService,
    private diagnosticsService: DiagnosticsService,
    private agentLivePollerSvc: AgentLivePollerService,
    private agentHistoryPlayerSvc: AgentHistoryPlayerService,
    private taskSvc: TaskService,
    private pubSubSvc: PubSubWsService
  ) {}

  public run(agentID: string): void {
    const ipToPing = this.form.controls.ipToPing.value;
    this._running.next(true);
    this._pingResult.next('');
    this.lastRan = undefined;

    this.diagnosticsService
      .runPing(agentID, ipToPing)
      .pipe(
        switchMap((response) => {
          const taskID = response.tag;
          let taskUpdateUnixNano = 0;

          return this.taskSvc.getTask(taskID).pipe(
            tap((task) => (taskUpdateUnixNano = task.lastUpdateUnixNano)),
            switchMap(() =>
              UNDEFINED.pipe(
                map(() => taskUpdateUnixNano),
                // on retries, we make sure to use the updated time
                switchMap((t) => this.taskSvc.getTaskListen(taskID, t)),
                tap((task) => {
                  if (!task) {
                    throw 'still waiting...';
                  } else if (!task.isDone) {
                    taskUpdateUnixNano = task.lastUpdateUnixNano;
                    throw 'still waiting...';
                  }
                })
              )
            ),
            retry({
              delay: (_error, _retryCount) => of(true)
            }),
            finalize(() => this._running.next(false))
          );
        })
      )
      .subscribe({
        error: (err) => {
          console.warn(err);
        }
      });
  }

  private formatPing(ping: PingTestData): string {
    if (!ping || ping.pingPackets.length < 1) {
      return '';
    }

    let result = this.formatPingHeader(
      ping.pingPackets[0].IPAddr.IP,
      ping.pingPackets[0].Addr
    );

    result += ping.pingPackets.map((packet) => this.formatPingPacket(packet)).join('');

    result += this.formatPingStat(ping.statistics);

    return result;
  }

  private formatPingHeader(ip: string, hostname = ''): string {
    if (hostname) {
      return `Pinging ${hostname} [${ip}] with 32 bytes of data:\n`;
    }
    return `Pinging ${ip} with 32 bytes of data:\n`;
  }

  private formatPingPacket(packet: PingPacket): string {
    return `${packet.Nbytes} bytes from ${packet.IPAddr.IP}: icmp_seq=${
      packet.Seq
    } time=${packet.Rtt / 1000000}\n`;
  }

  private formatPingStat(stat: PingStatistics): string {
    return `
Ping statistics for ${stat.IPAddr.IP}:
    Packets: Sent = ${stat.PacketsSent} , Received = ${stat.PacketsRecv}, Lost = ${
      stat.PacketLoss
    } (0% loss),
Approximate round trip times in milli-seconds:
    Minimum = ${stat.MinRtt / 1000000}ms, Maximum = ${
      stat.MaxRtt / 1000000
    }ms, Average = ${stat.AvgRtt / 1000000}ms`;
  }

  getCopy(tooltip: MatTooltip, text: string): void {
    tooltip.hide();
    setTimeout(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
    }, 50);
    setTimeout(() => {
      tooltip.hide();
    }, 1250);
    setTimeout(() => {
      tooltip.hide();
      tooltip.message = '';
    }, 1500);
    this.clipboard.copy(text);
  }
}
