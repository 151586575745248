import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { LayoutService } from 'src/@vex/services/layout.service';
import { SearchService } from 'src/app/core/services/search.service';
import { Agent } from 'src/app/shared/models/agent.model';

export interface SearchState {
  state: 'open' | 'close';
  result: string;
  cancelled: boolean;
}

@UntilDestroy()
@Component({
  selector: 'vex-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleIn400ms]
})
export class SearchComponent implements OnInit {
  searchTerm$ = new Subject<KeyboardEvent>();
  show$ = this.layoutService.searchOpen$;
  searchCtrl = new UntypedFormControl();
  searchResults: Array<Agent> = [];

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(private layoutService: LayoutService, private searchSvc: SearchService) {}

  ngOnInit(): void {
    this.searchTerm$
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        map(() => this.searchCtrl.value as string),
        distinctUntilChanged()
      )
      .subscribe({
        next: (value) => {
          if (value) {
            this.searchSvc
              .getAgents(this.searchCtrl.value)
              .pipe(map((r) => r.items))
              .subscribe({
                next: (results) => {
                  this.searchResults = results.map((a) => {
                    const index = this.searchResults.findIndex((sr) => sr.id === a.id);
                    return index >= 0 ? this.searchResults[index] : a;
                  });
                }
              });
          } else {
            this.searchResults = [];
          }
        }
      });

    this.show$
      .pipe(
        untilDestroyed(this),
        filter(() => true)
      )
      .subscribe({
        next: (state) => {
          if (state && state.state === 'open') {
            this.searchInput.nativeElement.focus();
          }
        }
      });
  }

  close(cancelled: boolean, result: string = null): void {
    if (!result) {
      result = this.searchCtrl.value;
    }
    this.searchResults = [];
    this.searchInput.nativeElement.focus();
    this.searchInput.nativeElement.blur();
    this.layoutService.closeSearch({ cancelled, result, state: 'close' });
    this.searchCtrl.setValue(undefined);
    this.searchTerm$.next(null);
  }

  search(): void {
    this.close(false);
  }

  setSearch(agent: Agent): void {
    this.close(false, agent.id);
  }

  getUsers(agent: Agent): string {
    if (agent.stats.summary.system.sessions) {
      return agent.stats.summary.system.sessions.map((s) => s.username).join(', ');
    }
    return '';
  }
}
