import { Component, Input, OnInit } from '@angular/core';
import { HistoryDate } from 'src/app/shared/models/history.model';
import { tailwindCssColorToRGB } from 'src/app/utils/colors';

@Component({
  selector: 'mon-date-summary',
  templateUrl: './date-summary.component.html',
  styleUrls: ['./date-summary.component.scss']
})
export class DateSummaryComponent implements OnInit {
  @Input() date: HistoryDate;

  data: number[] = [];
  colors: string[] = [];
  tooltips: string[] = [];

  ngOnInit(): void {
    let n = this.date.totalProcessesIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`Process Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-blue-500'));
    }

    n = this.date.totalCPUIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`CPU Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-blue-400'));
    }

    n = this.date.totalRAMIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`RAM Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-purple-400'));
    }

    n = this.date.totalDiskIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`Disk Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-green-400'));
    }

    n = this.date.totalNetworkIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`Network Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-orange'));
    }

    n = this.date.totalGPUIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`GPU Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-blue-400'));
    }

    n = this.date.totalSystemIncidents();
    if (n > 0) {
      this.data.push(n);
      this.tooltips.push(`System Incidents: ${n}`);
      this.colors.push(tailwindCssColorToRGB('text-yellow'));
    }
  }
}
