<div class="flex flex-col">
  <ng-container *ngIf="live$ | async; else inPlayback">
    <div [@listStagger]="5" class="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-2">
      <!-- *ngIf is needed for animations without a basic condition -->
      <div *ngIf="true" @fadeInUp class="card p-2 relative min-h-[16rem]">
        <mon-speed-test></mon-speed-test>
      </div>
      <div *ngIf="true" @fadeInUp class="card p-2 relative min-h-[16rem]">
        <mon-ping-test></mon-ping-test>
      </div>
      <div *ngIf="true" @fadeInUp class="card p-2 relative min-h-[16rem]">
        <mon-trace-route></mon-trace-route>
      </div>
      <div *ngIf="true" @fadeInUp class="card p-2 relative min-h-[16rem]">
        <mon-ipconfig></mon-ipconfig>
      </div>
      <ng-container *ngIf="agent$ | async as agent">
        <div *ngIf="agent.system.os === OS.WINDOWS" @fadeInUp class="card p-2 relative">
          <mon-event-log></mon-event-log>
        </div>
        <div *ngIf="agent.system.os === OS.WINDOWS" @fadeInUp class="card p-2 relative">
          <mon-group-policy-report></mon-group-policy-report>
        </div>
      </ng-container>
      <div *ngIf="true" @fadeInUp class="card p-2 relative min-h-[16rem]">
        <mon-log-report></mon-log-report>
      </div>
    </div>
  </ng-container>

  <ng-template #inPlayback>
    <div @fadeInUp class="card overflow-x-auto overflow-y-hidden p-4 flex flex-col text-center sm:text-left sm:flex-row items-center gap-4">
      <div class="text-secondary">Diagnostics is only available in live mode</div>
    </div>
  </ng-template>
</div>
