<table class="table-auto w-full pt-4">
  <thead>
    <tr>
      <th></th>
      <th></th>
      <th class="w-16">Read</th>
      <th class="w-16">Write</th>
      <th class="w-16">Invoke</th>
      <th class="w-16"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="!readonly" class="">
      <td class="bg-slate-500 bg-opacity-25 py-2 pl-2 rounded-l" colspan="2">
        <mat-form-field data-intro-key="perms-item-search" class="w-full">
          <mat-label>Search For A Role Or User</mat-label>
          <input #permInput type="text" matInput [formControl]="permSelectCtrl" [matAutocomplete]="perm" />
          <mat-autocomplete
            autoActiveFirstOption
            #perm="matAutocomplete"
            [displayWith]="displayNameOfPerm"
            (optionSelected)="onPermSelected($event)"
          >
            <mat-option *ngFor="let permObject of searchResults$ | async" [value]="permObject">
              <div class="flex text-secondary text-sm gap-2">
                <fa-icon [icon]="['fad', permObject.type == 'role' ? 'shield-alt' : 'user']" size="lg"></fa-icon>
                <div>{{ permObject.name }}</div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <fa-icon [icon]="['fad', 'search']" size="lg" [fixedWidth]="true" matPrefix></fa-icon>
        </mat-form-field>
      </td>
      <td class="bg-slate-500 bg-opacity-25 text-center"><mat-checkbox #read></mat-checkbox></td>
      <td class="bg-slate-500 bg-opacity-25 text-center"><mat-checkbox #write></mat-checkbox></td>
      <td class="bg-slate-500 bg-opacity-25 text-center"><mat-checkbox #invoke></mat-checkbox></td>
      <td class="bg-slate-500 bg-opacity-25 text-right rounded-r">
        <button
          class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover transition duration-200 ease-in-out sm:ml-2 lg:ml-1"
          matTooltip="Add Permission"
          mat-icon-button
          (click)="addPermission()"
        >
          <fa-icon [icon]="['fad', 'plus']" size="lg"></fa-icon>
        </button>
      </td>
    </tr>
    <ng-container *ngIf="newPermissions">
      <ng-container *ngFor="let perm of newPermissions; index as i; trackBy: trackByPerm">
        <tr class="hover:bg-hover trans-ease-out mat-checkbox-kill-hover">
          <td class="text-secondary pl-2 w-4">
            <fa-icon [icon]="['fad', perm.type == 'role' ? 'shield-alt' : 'user']" size="lg"></fa-icon>
          </td>
          <td class="text-secondary pl-2">{{ perm.name }}</td>
          <td class="text-center p-2">
            <mat-checkbox [disabled]="readonly" class="" (change)="toggleRead(perm)" [checked]="perm.read"></mat-checkbox>
          </td>
          <td class="text-center">
            <mat-checkbox [disabled]="readonly" class="" (change)="toggleWrite(perm)" [checked]="perm.write"></mat-checkbox>
          </td>
          <td class="text-center">
            <mat-checkbox [disabled]="readonly" class="" (change)="toggleInvoke(perm)" [checked]="perm.invoke"></mat-checkbox>
          </td>
          <td class="text-right">
            <div class="flex w-full h-full">
              <ng-container *ngIf="!readonly">
                <button
                  *ngIf="!autoCommit"
                  [ngClass]="perm.value !== origPermissions.get(perm.uniqueID)?.value ? 'opacity-100' : 'opacity-0 invisible'"
                  class="w-8 h-8 leading-none flex items-center justify-center hover:bg-green-light transition-all duration-200 ease-in-out sm:ml-2 lg:ml-1"
                  matTooltip="Update Permission"
                  mat-icon-button
                  (click)="updatePermission(perm)"
                >
                  <fa-icon [icon]="['fad', 'check']" size="lg" class="text-green"></fa-icon>
                </button>
                <button
                  class="w-8 h-8 leading-none flex items-center justify-center hover:bg-red-light transition-all duration-200 ease-in-out sm:ml-2 lg:ml-1"
                  matTooltip="Remove Permission"
                  mat-icon-button
                  (click)="deletePermission(perm)"
                >
                  <fa-icon [icon]="['fad', 'times']" size="lg" class="text-red"></fa-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </tr> </ng-container
    ></ng-container>
  </tbody>
</table>
