import { Updatable } from '../interfaces/updatable.interface';
import { AgentSummary } from './agent-summary.model';
import { Process } from './process.model';
import { Service } from './service.model';

export class AgentStats implements Updatable<AgentStats> {
  public processes = new Map<string, Process>();
  public services = new Map<string, Array<Service>>();
  public summary: AgentSummary;
  public collectionTimestamp: Date;

  private _processUsers: Map<number, string> = new Map<number, string>();
  get processUsers(): Map<number, string> {
    return this._processUsers;
  }

  constructor(init?: Partial<AgentStats>) {
    if (!init) {
      return;
    }

    this.collectionTimestamp = new Date(init.collectionTimestamp);

    if (init.processes) {
      Object.keys(init.processes).map((k) => {
        this._processUsers.set(+init.processes[k].sessionID, init.processes[k].username);
        this.processes.set(k, new Process(init.processes[k]));
      });
    }

    if (init.services) {
      Object.keys(init.services).map((k) => {
        const innerServices = init.services[k]
          .map((s: Service) => new Service(s))
          .sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

        const process = this.processes.get(k);
        if (process) {
          process.services = innerServices;
        }
        this.services.set(k, innerServices);
      });
    }

    this.summary = new AgentSummary(init.summary);
  }

  update(input: Partial<AgentStats>): this {
    return Object.assign(this, input);
  }
}
