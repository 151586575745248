import { Updatable } from '../interfaces/updatable.interface';

export enum AgentResponseType {
  Info = 0,
  Error = 1,
  Success = 2,
  Alert = 3,
  InvalidForm = 4
}

export class AgentResponse implements Updatable<AgentResponse> {
  public id: string;
  public agentID: string;
  public taskID: string;
  public requestID: string;
  public type: AgentResponseType;
  public timestamp: Date;
  public tags: Map<string, string>;
  public message: string;
  public payload: string;

  constructor(init?: Partial<AgentResponse>) {
    if (!init) {
      return;
    }
    Object.assign(this, init);
    this.timestamp = new Date(init.timestamp);
    const tags = new Map<string, string>();
    if (this.tags) {
      Object.keys(this.tags).map((k) => {
        tags.set(k, this.tags[k]);
      });
      this.tags = tags;
    }
  }

  update(input: Partial<AgentResponse>): this {
    return Object.assign(this, input);
  }
}
