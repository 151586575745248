<div
  @fadeOut
  *ngIf="spin"
  class="spinner top-0 right-0 bottom-0 left-0 absolute"
  [ngClass]="{ contents: !overlay, flex: overlay, 'items-center': overlay }"
>
  <div class="absolute w-full bg-white dark:bg-black z-10" [style.opacity]="opacity" [ngClass]="{ 'top-0': overlay, 'bottom-0': overlay }"></div>
  <div class="relative h-full w-full z-20">
    <h2 *ngIf="header" class="title m-0 text-secondary text-center pt-12 text-2xl pb-4">
      {{ header }}
    </h2>
    <div class="flex items-center justify-center h-full w-full">
      <div class="flex items-center justify-center h-full w-full fill-current text-center">
        <div class="flex items-center justify-center h-full w-full wrapper">
          <div class="boxes">
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="footer" class="m-0 text-secondary text-center pt-12 bottom-6 text-xl pb-4">
      {{ footer }}
    </div>
  </div>
</div>
<ng-content select="[content]"></ng-content>
